import React, { useEffect, useState, Fragment } from "react";
import {
    Grid,
    Button,
    TextField,
    Tooltip,
    InputAdornment,
    OutlinedInput,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { ToastContainer, toast } from "react-toastify";
import userService from "../../services/userService";
import { store } from "../../index.js";
import ErrorIcon from "@material-ui/icons/Error";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useParams,
    useRouteMatch,
} from "react-router-dom";
import TaxListing from "./Tax/TaxListing";

import SubscriptionView from "./Subscription/SubscriptionView";
import SubscriptionCreate from "./Subscription/SubscriptionCreate";
import SubscriptionListing from "./Subscription/SubscriptionListing";
import PricingModelListing from "./PricingModel/PricingModelListing";
import PricingModelEditCreate from "./PricingModel/TieredPricingModelEditCreate";
import PromotionListing from "./Promotion/PromotionListing";
import PromotionEditCreate from "./Promotion/PromotionEditCreate";
import AddonListing from "./Addon/AddonListing";
import AddonEditCreate from "./Addon/AddonEditCreate";
import Homepage from "../Homepage/homepage";
import TaxCreation from "./Tax/TaxCreation";
import Profile from "./Profile";
import PlanListing from "./Plan/PlanListing";
import ProductForm from "./Plan/PlanForm";
import Sidebar from "../../Components/Sidebar/sidebar";

const Admin = ({ history }) => {
    // The `path` lets us build <Route> paths that are
    // relative to the parent route, while the `url` lets
    // us build relative links.
    let { path, url } = useRouteMatch();

    const hasAdminRight = () => {
        return (
            userService.isLoggedIn &&
            store.getState().loggedInUserReducer.userData?.userRole ==
                "merchant"
        );
    };

    if (!hasAdminRight()) {
        history.replace("/not-found");
        toast.error(
            <div>
                <ErrorIcon /> Not Authorized
            </div>,
            {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            }
        );
    }

    return (
        <>
            <Switch>
                {/* <Route path={`${path}/tax`} exact component={TaxListing} /> */}
                {/* <Route path={`${path}/tax/:id`} component={TaxCreation} /> */}
                <Route path={`${path}/addon`} exact component={AddonListing} />
                <Route path={`${path}/addon/:id`} component={AddonEditCreate} />
                <Route
                    path={`${path}/subscription`}
                    exact
                    component={SubscriptionListing}
                />
                <Route
                    path={`${path}/subscription/new`}
                    exact
                    component={SubscriptionCreate}
                />
                <Route
                    path={`${path}/subscription/:id`}
                    component={SubscriptionView}
                />
                <Route
                    path={`${path}/pricingmodel`}
                    exact
                    component={PricingModelListing}
                />
                <Route
                    path={`${path}/tieredmodel/:id`}
                    component={PricingModelEditCreate}
                />
                <Route
                    path={`${path}/promotion`}
                    exact
                    component={PromotionListing}
                ></Route>
                <Route
                    path={`${path}/promotion/:id`}
                    component={PromotionEditCreate}
                ></Route>
                <Route path={`${path}/profile`} component={Profile}></Route>
                <Route
                    path={`${path}/plan`}
                    exact
                    component={PlanListing}
                ></Route>
                <Route
                    path={`${path}/plan/:id`}
                    component={ProductForm}
                ></Route>
            </Switch>
        </>
    );
};

export default Admin;
