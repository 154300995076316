import React, { useState, useEffect, useRef } from "react";
import "./style.css";
import {
    Grid,
    TextField,
    InputLabel,
    Button,
    IconButton,
} from "@material-ui/core";

import Form from "react-bootstrap/Form";
import taxService from "../../../../services/taxService";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { ToastContainer, toast } from "react-toastify";
import moment from "moment";
import ClearIcon from "@material-ui/icons/Clear";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import ErrorIcon from "@material-ui/icons/Error";
import { urlAction } from "../../../../action";
import merchantService from "../../../../services/merchantService";
const MySwal = withReactContent(Swal);

const TaxCreation = ({
    history,
    match,
    cleanBackground = false,
    callback = null,
}) => {
    const [taxId, setTaxId] = useState("");
    const [merchantInfo, setMerchantInfo] = useState();

    const [taxName, setTaxName] = useState({ value: "" });
    const [taxRate, setTaxRate] = useState({ value: "" });
    let date = new Date();
    let day = date.getDate().toString();
    let month = (date.getMonth() + 1).toString();
    let year = date.getFullYear().toString();
    let today =
        year + "-" + month.padStart(2, "0") + "-" + day.padStart(2, "0");

    const [effectiveDate, setEffectiveDate] = useState({ value: today });
    const [terminationDate, setTerminationDate] = useState({ value: null });

    const createNewTaxInServer = () => {
        if (validateForm()) {
            var dataBody = {
                tax_name: taxName.value,
                tax_rate: parseFloat(taxRate.value) / 100,
                merchant_id: merchantInfo[0],
                effective_date: moment(effectiveDate.value).toISOString(),
            };

            if (terminationDate.value != "") {
                dataBody = {
                    ...dataBody,
                    expiry_date: moment(terminationDate.value).toISOString(),
                };
            }

            taxService
                .createTax(dataBody)
                .then((res) => {
                    if (!!callback) {
                        //when callback is exist, usually this is a pop up modal
                        callback(true); // call with success
                    } else {
                        Swal.fire(
                            "Tax Successfully created",
                            "You will now redirect to the tax listing page",
                            "success"
                        ).then((e) => {
                            history.push("/admin/tax");
                        });
                    }
                })
                .catch((res) => {
                    toast.error(
                        <div>
                            {JSON.stringify(res.message, null, 2)}
                            <br />
                        </div>,
                        {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        }
                    );
                });
        } else {
            toast.error(
                <div>
                    <ErrorIcon /> Please check the error message and ensure that
                    all inputs are correct.
                </div>,
                {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                }
            );
        }
    };

    const editTaxInServer = () => {
        if (validateForm()) {
            var dataBody = {
                tax_name: taxName.value,
                tax_rate: parseFloat(taxRate.value) / 100,
                merchant_id: merchantInfo[0],
                effective_date: moment(effectiveDate.value).toISOString(),
            };

            if (terminationDate.value != "") {
                dataBody = {
                    ...dataBody,
                    expiry_date: moment(terminationDate.value).toISOString(),
                };
            } else {
                dataBody = {
                    ...dataBody,
                    expiry_date: null,
                };
            }

            taxService
                .updateTax(taxId, dataBody)
                .then((res) => {
                    if (!!callback) {
                        //when callback is exist, usually this is a pop up modal
                        callback(true); // call with success
                    } else {
                        Swal.fire(
                            "Tax Successfully updated",
                            "You will now redirect to the tax listing page",
                            "success"
                        ).then((e) => {
                            history.push("/admin/tax");
                        });
                    }
                })
                .catch((res) => {
                    toast.error(
                        <div>
                            {JSON.stringify(res.message, null, 2)}
                            <br />
                        </div>,
                        {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        }
                    );
                });
        } else {
            toast.error(
                <div>
                    <ErrorIcon /> Please check the error message and ensure that
                    all inputs are correct.
                </div>,
                {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                }
            );
        }
    };

    const redirectToTaxListingPage = () => {
        history.push("/admin/tax");
    };
    useEffect(() => {
        const splitURL = urlAction("get_merchant");
        // setHostname(splitURL);
        merchantService.getMerchantInfo().then((e) => {
            let merchantInfo = e;

            let merchantId = merchantInfo
                .filter((obj) => obj.merch_name == splitURL)
                .map((obj) => {
                    return obj.id;
                });
            setMerchantInfo(merchantId);
        });
    }, []);
    useEffect(() => {
        if (!!match.params.id && match.params.id != "new")
            taxService.getSingleTax(match.params.id).then((res) => {
                setTaxId(res.taxes.tax_id);
                setTaxName({ value: res.taxes.tax_name });
                setTaxRate({ value: (res.taxes.tax_rate * 100).toString() });

                setEffectiveDate({
                    value: moment(res.taxes.effective_date).format(
                        "yyyy-MM-DD"
                    ),
                });

                if (!!res.taxes.expiry_date) {
                    setTerminationDate({
                        value: moment(res.taxes.expiry_date).format(
                            "yyyy-MM-DD"
                        ),
                    });
                } else {
                    setTerminationDate({ value: "" });
                }
            });
    }, []);

    const validateForm = () => {
        var isValid = true;

        isValid = validateTaxName(taxName.value) && isValid;
        isValid = validateTaxRate(taxRate.value) && isValid;
        isValid = validateEffectiveDate(effectiveDate.value) && isValid;
        isValid = validateTerminationDate(terminationDate.value) && isValid;

        return isValid;
    };

    const validateTaxName = (newValue) => {
        var isValid = true;

        //Reset the Error message
        var errorMessage = "";

        //Check empty
        if (newValue == "") {
            if (!!errorMessage) errorMessage += "\n";
            errorMessage += "Tax Name Cannot be empty";
            isValid = false;
        }

        //Check max length 30
        if (newValue.length > 30) {
            if (!!errorMessage) errorMessage += "\n";
            errorMessage += "Tax Name must be less than 31 character";
            isValid = false;
        }

        setTaxName({
            ...taxName,
            error: errorMessage,
        }); //Set Error message

        return isValid;
    };

    const validateTaxRate = (newValue) => {
        var isValid = true;

        //Reset the Error message
        var errorMessage = "";

        //Check empty
        if (newValue == "") {
            if (!!errorMessage) errorMessage += "\n";
            errorMessage += "Tax Rate Cannot be empty";
            isValid = false;
        }

        //Check if Tax rate only contain number or number with decimal
        var positiveNumberWithDecimalRegex =
            "^[+]?([0-9][0-9]*(?:[.][0-9]*)?|0*.0*[1-9][0-9]*)$";
        if (!newValue.match(positiveNumberWithDecimalRegex)) {
            //newValue contain alphabet or negative value
            if (!!errorMessage) errorMessage += "\n";
            errorMessage +=
                "Tax Rate can only be positive number with or without decimal";
            isValid = false;
        } else {
            if (!(parseFloat(newValue) > 0)) {
                //newValue is less than equal zero
                if (!!errorMessage) errorMessage += "\n";
                errorMessage += "Tax Rate cannot be zero";
                isValid = false;
            }

            if (parseFloat(newValue) > 100) {
                //newValue is less than equal zero
                if (!!errorMessage) errorMessage += "\n";
                errorMessage += "Tax Rate cannot be more than 100%";
                isValid = false;
            }
        }

        setTaxRate({
            ...taxRate,
            error: errorMessage,
        }); //Set Error message

        return isValid;
    };

    const validateEffectiveDate = (newValue) => {
        var isValid = true;
        var errorMessage = "";

        if (newValue == "") {
            if (!!errorMessage) errorMessage += "\n";
            errorMessage += "Effective Date cannot be empty";
            isValid = false;
        }
        if (
            moment(newValue).isSameOrAfter(
                terminationDate.value && terminationDate.value != null
            )
        ) {
            if (!!errorMessage) errorMessage += "\n";
            errorMessage +=
                "Effective Date cannot be a date after tax termination date";
            isValid = false;
        }

        setEffectiveDate({ ...effectiveDate, error: errorMessage });
        return isValid;
    };

    const validateTerminationDate = (newValue) => {
        var isValid = true;
        var errorMessage = "";

        if (moment(newValue).isSameOrBefore(moment())) {
            if (!!errorMessage) errorMessage += "\n";
            errorMessage += "Termination date cannot be today or a past date";
            isValid = false;
        }

        setEffectiveDate({ ...effectiveDate, error: errorMessage });
        return isValid;
    };
    return (
        <div
            className={
                "container-fluid " + (cleanBackground ? "" : "addon-container")
            }
        >
            <div
                className={
                    "container " +
                    (cleanBackground ? "" : "addon-form-container")
                }
            >
                <h2
                    className="text-left color-contrast-dark p-4"
                    style={{ color: "black" }}
                >
                    {match.params.id == "new" ? "New" : "Edit"} Tax
                </h2>
                <Grid container className="p-4" spacing={3}>
                    <Grid item xs={12} style={{ textAlign: "left" }}>
                        <h4>Tax Details</h4>
                    </Grid>
                    {/* TODO: connect to backend for plan id */}
                    <Grid item xs={12}>
                        <TextField
                            label="Tax Id"
                            fullWidth
                            value={taxId}
                            disabled={true}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            required
                            label="Tax Name"
                            value={taxName.value}
                            error={!!taxName.error}
                            helperText={taxName.error || ""}
                            fullWidth
                            onChange={(e) => {
                                const newValue = e.target.value;

                                if (validateTaxName(newValue) || newValue == "")
                                    setTaxName({ value: newValue });
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        {/* should be calculated in percentage */}
                        <TextField
                            required
                            label="Tax Rate (%)"
                            value={taxRate.value}
                            error={!!taxRate.error}
                            helperText={taxRate.error || ""}
                            onChange={(e) => {
                                const newValue = e.target.value;

                                if (validateTaxRate(newValue) || newValue == "")
                                    setTaxRate({ value: newValue });
                            }}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <TextField
                            required
                            fullWidth
                            disabled={match.params.id != "new"}
                            id="date"
                            label="Effective Date"
                            type="date"
                            value={effectiveDate.value}
                            error={!!effectiveDate.error}
                            helperText={effectiveDate.error || ""}
                            onChange={(e) => {
                                const newValue = e.target.value;

                                if (validateEffectiveDate(newValue)) {
                                    setEffectiveDate({ value: newValue });
                                }
                            }}
                            inputProps={{
                                min: new moment().format("yyyy-MM-DD"),
                            }}
                            error={!!effectiveDate.error}
                            helperText={effectiveDate.error}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <TextField
                            required
                            fullWidth
                            id="date"
                            label="Termination Date"
                            type="date"
                            value={terminationDate.value}
                            error={!!terminationDate.error}
                            helperText={terminationDate.error || ""}
                            onChange={(e) => {
                                const newValue = e.target.value;

                                if (validateTerminationDate(newValue)) {
                                    setTerminationDate({ value: newValue });
                                }
                            }}
                            error={!!terminationDate.error}
                            helperText={terminationDate.error}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            InputProps={{
                                endAdornment: (
                                    <IconButton
                                        className="p-0"
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            setTerminationDate({ value: "" });
                                        }}
                                    >
                                        <ClearIcon />
                                    </IconButton>
                                ),
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}></Grid>
                    {match.params.id == "new" ? (
                        <Button
                            className="p-2 m-2 mt-3"
                            variant="contained"
                            onClick={createNewTaxInServer}
                            style={{
                                backgroundColor: "grey",
                                color: "white",
                            }}
                            endIcon={<AddIcon></AddIcon>}
                        >
                            Create Tax
                        </Button>
                    ) : (
                        <Button
                            className="p-2 m-2 mt-3"
                            variant="contained"
                            onClick={editTaxInServer}
                            style={{
                                backgroundColor: "#7A53EA",
                                color: "white",
                            }}
                            endIcon={<EditIcon></EditIcon>}
                        >
                            Edit Tax
                        </Button>
                    )}
                    <Button
                        className="p-2 m-2 mt-3"
                        onClick={
                            !!callback
                                ? callback.bind(this, false)
                                : redirectToTaxListingPage
                        }
                        variant="contained"
                    >
                        Cancel
                    </Button>
                </Grid>
            </div>
            <ToastContainer />
        </div>
    );
};

export default TaxCreation;
