import { useState } from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import { useDispatch } from "react-redux";
import userService from "../../services/userService/index.js";
import Swal from "sweetalert2";
import LoginModal from "../LoginModal/index.js";
import {
    toggleLoginRegisterModalState,
    toggleLoginState,
} from "../../action.js";
import { Grid } from "@mui/material";
import Button from "@mui/material/Button";
import "./style.css";
import { store } from "../../index.js";
import session from "redux-persist/lib/storage/session";
import ga4Service from "../../services/analyticService/ga4";
const SignInCard = ({ stepper, deliveryInfo }) => {
    const dispatch = useDispatch();
    const [usernameInput, setUsernameInput] = useState();
    const [passwordInput, setPasswordInput] = useState();
    const [showLoginModal, setShowLoginModal] = useState(false);
    const forgotPasswordLink =
        String(window.location.origin).split("/")[0] +
        "/backend/api/password_reset/";
    const toggleLoggedIn = () => {
        dispatch(toggleLoginState());
    };
    const login = () => {
        userService
            .login(usernameInput, passwordInput)
            .then(() => {
                toggleLoggedIn();
                sessionStorage.setItem("stepper", 3);
                window.location.reload();
            })
            .catch((res) => {
                Swal.fire({
                    icon: "error",
                    title: "Username or password is wrong.",
                    text: "Username or password is wrong. Please try again later.",
                    confirmButtonColor: store.getState().colorState.color,
                }).then((result) => {
                    if (result.isConfirmed) {
                    }
                });
            });
    };

    return (
        <Box>
            <Card
                style={{
                    // height: "100%",
                    borderRadius: "8px",
                    boxShadow: "3px 5px #DCDCDC",
                }}
                // style={{ height: "100%" }}
                variant="outlined"
            >
                <Grid container align="left" style={{ marginLeft: "30px" }}>
                    <Grid item className=" mt-3 mb-2" xs={12}>
                        <h4>Sign in</h4>
                    </Grid>
                    <Grid item className="" xs={12}>
                        <div className="signup-caption">
                            <p>Not a member?</p>

                            <p
                                onClick={() => {
                                    setShowLoginModal(true);
                                    ga4Service.trackSignUp("Checkout Page");
                                }}
                                style={{ marginLeft: "5px" }}
                                className="ml-2 signup-link"
                            >
                                Sign up now
                            </p>
                        </div>
                    </Grid>
                    <LoginModal
                        show={showLoginModal}
                        handleClose={() => {
                            setShowLoginModal(false);
                        }}
                    />
                    <Grid item className="ml-3 " xs={12} lg={12} md={12}>
                        <input
                            className="credential-input p-2"
                            type="text"
                            style={{ width: "85%", marginTop: "20px" }}
                            class="form-control"
                            placeholder="Username"
                            onChange={(e) => {
                                setUsernameInput(e.target.value);
                            }}
                            onKeyUp={(e) => {
                                if (e.key === "Enter") {
                                    login();
                                } else {
                                    setUsernameInput(e.target.value);
                                }
                            }}
                        />
                    </Grid>
                    <Grid item className="mt-3 ml-3" xs={12} lg={12} md={12}>
                        <input
                            className="credential-input p-2"
                            type="password"
                            style={{ width: "85%", marginTop: "10px" }}
                            class="form-control"
                            placeholder="Password"
                            onChange={(e) => {
                                setPasswordInput(e.target.value);
                            }}
                            onKeyUp={(e) => {
                                if (e.key === "Enter") {
                                    login();
                                } else {
                                    setPasswordInput(e.target.value);
                                }
                            }}
                        />
                    </Grid>

                    <Grid item className="ml-2 mt-3" xs={12}>
                        <p className="ml-2" style={{ marginTop: "20px" }}>
                            <a
                                style={{ color: "none" }}
                                href={forgotPasswordLink}
                                target="_blank"
                                onClick={() => {
                                    ga4Service.trackForgotPassword("Checkout Page");
                                }}
                            >
                                Forgot password?
                            </a>
                        </p>
                    </Grid>
                    <Grid container style={{ marginBottom: "35px" }}>
                        <Grid item xs={5} md={5} lg={5} align="center">
                            <Button
                                onClick={() => {
                                    if (deliveryInfo == null) {
                                        stepper(-2);
                                    } else {
                                        stepper(-1);
                                    }
				    ga4Service.trackBackButton("Checkout Page");		
                                }}
                                // style={{ marginRight: "100px" }}
                                className="option-button back"
                                variant="outlined"
                            >
                                Back
                            </Button>
                        </Grid>
                        <Grid item xs={5} md={5} lg={5} align="left">
                            <Button
                                className="option-button sign"
                                variant="outlined"
                                onClick={() => {
                                    ga4Service.trackSignIn("Checkout Page");
                                    login();
                                }}
                                style={{
                                    color: store.getState().colorState
                                        .font_color,
                                    backgroundColor:
                                        store.getState().colorState.color,
                                    border: "transparent",
                                }}
                            >
                                Sign In
                            </Button>
                        </Grid>
                    </Grid>
                    {/* TODO: single sign on after ui revamp */}
                </Grid>
            </Card>
        </Box>
    );
};
export default SignInCard;
