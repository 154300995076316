import {
    CHANGE_LOGIN_MODAL_STATE,
    CHANGE_LOGIN_STATE,
    SAVE_LOGGED_IN_USER_STATE,
    CLEAR_LOGGED_IN_USER_STATE,
} from "./constant.js";

export const toggleLoginRegisterModalState = (z) => (dispatch, getState) => {
    dispatch({
        type: CHANGE_LOGIN_MODAL_STATE,
        payload:
            !getState().toggleLoginRegisterModalReducer
                .isLoginRegisterModalOpened,
    });
};

export const toggleLoginState = () => (dispatch, getState) => {
    if (localStorage.getItem("user") == 1) {
        localStorage.setItem("user", 0);
    } else {
        localStorage.setItem("user", 1);
    }

    dispatch({
        type: CHANGE_LOGIN_STATE,
        payload: !getState().logInStateReducer.isLoggedIn,
    });
};

export const saveLoggedInUserState = (userData) => {
    return {
        type: SAVE_LOGGED_IN_USER_STATE,
        payload: userData,
    };
};

export const clearLoggedInUserState = () => {
    return {
        type: CLEAR_LOGGED_IN_USER_STATE,
    };
};

export const toggleColor = (color) => {
    return {
        type: "CHANGE_COLOR",
        payload: color,
    };
};

export const urlAction = (data) => {
    //function pass in what option of the merchant customer wants
    switch (data) {
        case "get_backend":
            var port;
            var splitURL = window.location.hostname.split(".");
            if (splitURL[0] == "demo" || splitURL[0] == "uat") {
                splitURL = splitURL[1];
                port = "";
            } else {
                splitURL = splitURL[0];
            }
            // if protocol if https or http
            // protocol + :// + domain + .com (if not local) + / backend
            const backend =
                window.location.protocol +
                "//" +
                window.location.hostname +
                "/backend/";

            return backend;
        case "get_merchant":
            var splitURL = window.location.hostname.split(".");
            if (splitURL[0] == "demo" || splitURL[0] == "uat") {
                splitURL = splitURL[1];
            } else {
                splitURL = splitURL[0];
            }
            return splitURL;
        default:
            return window.location.origin;
    }
};
