import React, { useEffect, useState } from "react";
import "./style.css";
import Form from "react-bootstrap/Form";
import icon from "../../Images/directDebitImage.svg";
import { useHistory } from "react-router-dom";
import promotionService from "../../services/promotionService";
import {
    Radio,
    RadioGroup,
    Grid,
    FormLabel,
    TextField,
    FormControlLabel,
    IconButton,
    FormControl,
    InputLabel,
    FormHelperText,
    Select,
    MenuItem,
} from "@material-ui/core";
import Button from "react-bootstrap/Button";
import Swal from "sweetalert2";
import { ToastContainer, toast } from "react-toastify";

const Confirmation = (props) => {
    //need states for Base Fee, AddOn(Recurring) fees, AddOn(non-recurring) Fees, one off fees, tax
    //each state will be an array of objects with keys: title,item,price(to calculate),price description(to display)

    //need states for main and detail
    //array of objects with keys: title, subtitle
    const [paymentMethodChecked, setPaymentMethodChecked] = useState("");
    const [promoCode, setPromoCode] = useState("");
    const [promoId, setPromoId] = useState("");
    const [promoSummary, setPromoSummary] = useState({
        title: "Promotion",
        item: [],
        price: [],
    });

    let data = props.location.state.displayData;
    let planFee = {
        title: "",
        item: [],
        price: [],
    };

    let addOnRecur = {
        title: "Add-on (Recurring)",
        item: [],
        price: [],
    };

    let addOnNonRecur = {
        title: "Add-on (Non-Recurring)",
        item: [],
        price: [],
    };

    let tax = {
        title: "Tax",
        item: [],
        price: [],
    };

    let promo = {
        item: [],
    };

    let baseFee = [];

    //PLAN FEE SUMMARY
    if (
        data["pricing_model"] === "flat_fee" ||
        data["pricing_model"] === "per_unit"
    ) {
        planFee.title = data.plan_type_ + " Fee (Recurring)";
        let temp = data.bill_amt.plan_price;
        if (data.bill_amt.min) {
            temp += data.bill_amt.min;
            temp += data.bill_amt.max;
        } else {
            temp = data.bill_amt.plan_price;
        }

        baseFee.push(temp);
        planFee.item.push(data.bill_amt.desc);
        planFee.price = baseFee;
    } else {
        //tiered
        planFee.title = data.plan_type_ + " Fee (Recurring)";
        for (let i = 0; i < data.bill_amt.length; i++) {
            planFee.item.push(data.bill_amt[i].tier_description);
            planFee.price.push(data.bill_amt[i].price_per_unit_);
        }
    }

    //ADD ON SUMMARY
    for (let i = 0; i < data.addons.length; i++) {
        if (
            data.addons[i].addon_applicability === "mandatory" ||
            data.addons[i].isChecked
        ) {
            if (data.addons[i].addon_recurrence === "recurring") {
                addOnRecur.item.push(data.addons[i].addon_name);
                addOnRecur.price.push(data.addons[i].addon_price_);
            } else {
                addOnNonRecur.item.push(data.addons[i].addon_name);
                addOnNonRecur.price.push(data.addons[i].addon_price_);
            }
        }
    }

    //TAX SUMMARY
    //TODO: loop through data.tax
    if (data.tax) {
        tax.item.push(data.tax.tax_name);
        tax.price.push(data.tax.tax_rate_);
    }

    //TODO: get all main from db
    let main = [
        {
            title: data["plan_name"],
        },
    ];

    let detail = [];
    let today = new Date();
    let date =
        today.getDate() +
        "/" +
        today.getMonth() +
        1 +
        "/" +
        today.getFullYear();
    //TODO: get all details from db
    detail = [
        {
            title: "Subscription Start Date",
            subtitle: date,
        },
        {
            title: "Billing Frequency",
            subtitle: data.delivery_frequency,
        },
    ];

    if (data["min_fee"] != null) {
        detail.push({
            title: "Minimum Fee per Month",
            subtitle: data.min_fee,
        });
    }
    if (data["setup_fee"]) {
        detail.push({
            title: "Setup Fee",
            subtitle: data.setup_fee,
        });
    }

    //TODO: get all fee descriptions from db
    let summary = [planFee, addOnRecur, addOnNonRecur, tax, promoSummary];

    //TODO: get all recurring fee descriptions from db
    let total = [
        "+ Add-on Recurring RM80",
        "+ Direct Debit Transaction Fee(Monthly Usage)",
        "+ Credit Card Transaction Fee(Monthly Usage)",
        "+ Service Tax (6%)",
    ];

    let paymentMethod = [
        {
            title: "Debit Card",
            value: "direct_debit",
        },
        {
            title: "FPX",
            value: "instant_pay",
        },
    ];

    const renderMain = (main, index) => {
        return (
            <div key={index}>
                <h2 className="main-text">{main.title}</h2>
                <h3 className="main-sub">
                    <b>{main.subtitle}</b>
                </h3>
            </div>
        );
    };

    const renderDetails = (detail, index) => {
        return (
            <div key={index}>
                <p className="detail-text">
                    <b>{detail.title}</b>
                </p>
                <p className="detail-sub">{detail.subtitle}</p>
            </div>
        );
    };

    const renderSummary = (summary, index) => {
        if (summary.item.length !== 0) {
            return (
                <div key={index}>
                    <p className="summary-text">
                        <b>{summary.title}</b>
                    </p>
                    <div className="d-flex justify-content-between">
                        <p className="item">
                            {renderSummaryDetails(summary.item)}
                        </p>
                        <p className="price">
                            {renderSummaryDetails(summary.price)}
                        </p>
                    </div>
                </div>
            );
        } else {
            return null;
        }
    };

    const renderSummaryDetails = (summary) => {
        const newText = summary.map((str) => (
            <p>
                {str}
                <br />
            </p>
        ));
        return newText;
    };

    const renderOneTimeTotal = (oneTimeFees) => {
        //TODO: calculate total non recurring fees
        return <b>RM1113</b>;
    };
    const renderMonthlyTotal = (total) => {
        //TODO: display all monthly totals
        const newText = total.map((str) => (
            <p>
                <b>{str}</b>
                <br />
            </p>
        ));
        return newText;
    };

    const renderOutStanding = (amounts) => {
        //TODO: calculate onetime + monthly
        return "RM9999";
    };

    const renderPaymentMethod = (method) => {
        return (
            <Form.Check
                inline
                label={method.title}
                type="radio"
                onChange={paymentMethodRadioHandler}
                value={method.value}
                checked={paymentMethodChecked === method.value}
            />
        );
    };

    const paymentMethodRadioHandler = (e) => {
        setPaymentMethodChecked(e.target.value);
    };

    const promoSubmitHandler = () => {
        promotionService
            .validatePromo({
                merchant_id: data.merchant_id,
                promo_code: promoCode,
                plan_id: data.plan_id,
            })
            .then((res) => {
                Swal.fire("Promotion Code valid", "success").then((e) => {
                    let amt;
                    if (res.discount_type == "percentage") {
                        amt = res.discount_amount + "%";
                    } else {
                        amt = "RM" + res.discount_amount;
                    }
                    setPromoId(res.promo_id);
                    setPromoSummary({
                        title: "Promotion",
                        item: [promoCode],
                        price: [amt],
                    });
                });
            })
            .catch((res) => {
                toast.error(
                    <div>
                        Some Error Occur, Please try again later.
                        <br />
                        {JSON.stringify(res, null, 2)}
                        <br />
                    </div>,
                    {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    }
                );
            });
    };

    const renderPromotion = () => {
        return (
            <div className="promotion">
                <Grid container spacing={3}>
                    <Grid item lg={6}>
                        <TextField
                            label="Promo Id"
                            fullWidth
                            value={promoCode}
                            onChange={(e) => {
                                setPromoCode(e.target.value);
                            }}
                        />
                    </Grid>
                    <Grid item lg={6}>
                        <Button onClick={promoSubmitHandler} variant="primary">
                            Submit
                        </Button>
                    </Grid>
                </Grid>
            </div>
        );
    };

    const history = useHistory();
    let sub = {};

    const subscribeHandler = async () => {
        sub.merchant_id = data.merchant_id;
        sub.customer_id = "merchant"; //HARDCODED FOR NOW
        sub.plan_id = data.plan_id;
        sub.add_ons = [];
        for (let i = 0; i < data.addons.length; i++) {
            if (
                data.addons[i].isChecked ||
                data.addons[i].addon_applicability === "mandatory"
            ) {
                sub.add_ons.push(data.addons[i].addon_id);
            }
        }
        sub.payment_cycles = 0;
        sub.subs_type = paymentMethodChecked;
        if (promoSummary.item[0]) {
            sub.promo_id = promoId;
        }
        //TODO  subscription
        history.push({
            pathname: "/admin/subscription",
        });
    };

    return (
        <div>
            <div className="container">
                <div className="row justify-content-center">
                    <img src={icon} className="icon" width="250"></img>
                </div>
                <div className="main">
                    {main.map(renderMain)}
                    {/* <h2 className="main-text">-Direct Debit System-</h2>
                    <h3 className="main-sub"><b>Percentage Based Subscription</b></h3> */}
                </div>
                <div className="detail">
                    {detail.map(renderDetails)}
                    {/* <p className="detail-text"><b>Subscription Duration</b></p>
                    <p className="detail-sub">1 Year</p>
                    <p className="detail-text"><b>Billing Frequency</b></p>
                    <p className="detail-sub">Monthly</p>
                    <p className="detail-text"><b>Minimum Fee Per Month</b></p>
                    <p className="detail-sub">RM100</p> */}
                </div>
            </div>
            <div className="summary">
                <h2 className="summary-header">Payment Summary</h2>
                <p className="summary-text">
                    <b>Plan description</b>
                </p>
                <p className="summary-sub">
                    Usage based fee will be calculated at the end of the month
                    and added to the invoice
                </p>
                {summary.map(renderSummary)}
                <hr className="summary-end"></hr>
                <h2 className="summary-header">Promotion</h2>
                {renderPromotion()}
                <h2 className="summary-header">Payment Method</h2>
                <div className="d-flex justify-content-left">
                    <Form className="payment-method">
                        <div
                            key="inline-radio"
                            className="mb-5"
                            style={{
                                fontWeight: "normal",
                                color: "#525252",
                                fontSize: "20px,",
                            }}
                        >
                            {paymentMethod.map(renderPaymentMethod)}
                        </div>
                    </Form>
                </div>

                <div className="justify-content-left">
                    <button
                        className="payment-button"
                        onClick={subscribeHandler}
                    >
                        Subscribe
                    </button>
                </div>
            </div>
            <ToastContainer />
        </div>
    );
};

export default Confirmation;
