import { NonceProvider } from "react-select";
import {
    CHANGE_LOGIN_MODAL_STATE,
    CHANGE_LOGIN_STATE,
    SAVE_LOGGED_IN_USER_STATE,
    CLEAR_LOGGED_IN_USER_STATE,
} from "./constant.js";
import { useLocation } from "react-router-dom";

const loginRegisterModalInitialState = {
    isLoginRegisterModalOpened: false,
};
export const toggleLoginRegisterModalReducer = (
    state = loginRegisterModalInitialState,
    action
) => {
    switch (action.type) {
        case CHANGE_LOGIN_MODAL_STATE:
            return { ...state, isLoginRegisterModalOpened: action.payload };
        default:
            return state;
    }
};

const logInInitialState = {
    isLoggedIn: false,
};

export const logInStateReducer = (state = logInInitialState, action) => {
    switch (action.type) {
        case CHANGE_LOGIN_STATE:
            return { ...state, isLoggedIn: action.payload };
        default:
            return state;
    }
};

const loggedInUserInitialState = null;

export const loggedInUserReducer = (
    state = loggedInUserInitialState,
    action
) => {
    switch (action.type) {
        case SAVE_LOGGED_IN_USER_STATE:
            return { ...state, userData: action.payload };
        case CLEAR_LOGGED_IN_USER_STATE:
            return { ...state, userData: null };
        default:
            return state;
    }
};

export const colorState = (state = {}, action) => {
    switch (action.type) {
        case "CHANGE_COLOR":
            let hex = ("" + action.payload).trim().replace(/#/g, ""); //trim and remove any leading # if there (supports number values as well)

            if (hex.length == 3) {
                hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
            } //support short form
            var b_int = parseInt(hex, 16);
            var red_code = (b_int >> 16) & 255;
            var green_code = (b_int >> 8) & 255;
            var blue_code = b_int & 255;

            const brightness = Math.round(
                (parseInt(red_code) * 299 +
                    parseInt(green_code) * 587 +
                    parseInt(blue_code) * 114) /
                    1000
            );
            console.log(brightness, "this is brightness");

            return {
                ...state,
                color: action.payload,
                font_color: brightness > 145 ? "black" : "white",
            };
        default:
            return state;
    }
};
