import { Grid } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import moment from "moment";
import { useEffect } from "react";
import { Table } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import KebabMenu from "../KebabMenu";
import ListingKebabOptions from "../ListingKebabOptions";
import "./style.css";
// need to pass in the parents data for table,
//
const MerchantTable = ({
    search,
    headers,
    tableData,
    filterValue,
    updateTableData,
    fetchData,
    fetchNewData,
    page,
}) => {
    useEffect(() => {
        if (page === "subscription") {
            fetchNewData();
        }
    }, [search]);

    const history = useHistory();
    const getHeaderNumber = (currentHeader) => {
        if (currentHeader === Object.keys(headers).length - 1) {
            return "0px 10px 0px 0px";
        } else if (currentHeader === 0) {
            return "10px 0px 0px 0px";
        }
    };
    const getFooterNumber = (currentFooter) => {
        if (currentFooter === Object.keys(headers).length - 1) {
            return "0px 0px 10px 0px";
        } else if (currentFooter === 0) {
            return "0px 0px 0px 10px";
        }
    };
    const renderTableHeader = () => {
        const tempHeaderArr = Object.keys(headers).map((header, index) => {
            if (headers[header].checked) {
                return (
                    <th
                        key={index}
                        style={{
                            borderRadius: getHeaderNumber(index),
                        }}
                    >
                        {headers[header].value}
                    </th>
                );
            } else {
                return null;
            }
        });
        return tempHeaderArr;
    };

    const navigateToViewSubscriptionPage = (sub_id) => {
        history.push(`/admin/subscription/${sub_id}`);
    };

    const renderTableBody = () => {
        // filter tableData with filterValue.plan_name
        if (tableData !== undefined) {
            var renderData = tableData;
            if (filterValue !== undefined) {
                var tempTableData = tableData;
                if (filterValue.plan_name !== undefined) {
                    tempTableData = tempTableData.filter((data) => {
                        return data.plan_name
                            .toLowerCase()
                            .includes(filterValue.plan_name.toLowerCase());
                    });
                }
                if (filterValue.subs_status !== undefined) {
                    // filter tableData with filterValue.subs_status
                    tempTableData = tempTableData.filter((data) => {
                        return data.subs_status
                            .toLowerCase()
                            .includes(filterValue.subs_status.toLowerCase());
                    });
                }

                if (filterValue.delivery_freq !== undefined) {
                    // filter temptabledata with filterValue.delivery_freq
                    tempTableData = tempTableData.filter((data) => {
                        return data.delivery_frequency
                            .toLowerCase()
                            .includes(filterValue.delivery_freq.toLowerCase());
                    });
                }

                if (filterValue.subs_type !== undefined) {
                    // filter temptabledata with filterValue.subs_type
                    tempTableData = tempTableData.filter((data) => {
                        return data.subs_type
                            .toLowerCase()
                            .includes(filterValue.subs_type.toLowerCase());
                    });
                }
                if (
                    filterValue.subs_start_date !== undefined ||
                    filterValue.subs_end_date !== undefined
                ) {
                    if (
                        filterValue.subs_start_date !== undefined &&
                        filterValue.subs_end_date !== undefined
                    ) {
                        // filter temptabledata data.delivery_date in between filterValue.subs_start_date and filterValue.subs_end_date
                        tempTableData = tempTableData.filter((data) => {
                            return (
                                new Date(data.delivery_date) >=
                                    new Date(filterValue.subs_start_date) &&
                                new Date(data.delivery_date) <=
                                    new Date(filterValue.subs_end_date)
                            );
                        });
                    } else if (
                        filterValue.subs_start_date !== undefined &&
                        filterValue.subs_end_date === undefined
                    ) {
                        tempTableData = tempTableData.filter((data) => {
                            return (
                                new Date(data.delivery_date) >=
                                new Date(filterValue.subs_start_date)
                            );
                        });
                    } else if (
                        filterValue.subs_start_date === undefined &&
                        filterValue.subs_end_date !== undefined
                    ) {
                        tempTableData = tempTableData.filter((data) => {
                            return (
                                new Date(data.delivery_date) <=
                                new Date(filterValue.subs_end_date)
                            );
                        });
                    }
                }
                renderData = tempTableData;
            }
            if (search !== undefined) {
                // filter render data with search value
                renderData = renderData.filter((data) => {
                    return data.customer_fname
                        .toLowerCase()
                        .includes(search.toLowerCase());
                });
            }

            const editActionButton = (data) => {
                switch (page) {
                    case "plan":
                        return (
                            <EditIcon
                                className="view-icon action-button sub-list-icon"
                                onClick={() => {
                                    history.push(`/admin/plan/${data.plan_id}`);
                                }}
                            />
                        );
                    case "promotion":
                        return (
                            <EditIcon
                                className="view-icon  action-button sub-list-icon"
                                onClick={() => {
                                    history.push(
                                        `/admin/promotion/${data.promo_id}`
                                    );
                                }}
                            />
                        );
                    case "addon":
                        return (
                            <EditIcon
                                className="view-icon  action-button sub-list-icon"
                                onClick={() => {
                                    history.push(
                                        `/admin/addon/${data.addon_id}`
                                    );
                                }}
                            />
                        );
                    case "subscription":
                        return (
                            <EditIcon
                                className="view-icon action-button sub-list-icon"
                                onClick={navigateToViewSubscriptionPage.bind(
                                    this,
                                    data.subs_id
                                )}
                            />
                        );
                    default:
                        return null;
                }
            };

            const tempDataBody = renderData.map((data, index) => {
                const getKebabOptions = ListingKebabOptions(
                    data,
                    page,
                    fetchNewData
                );
                const kebabOptions = getKebabOptions;

                const tempData = Object.keys(headers).map(
                    (planData, planIndex) => {
                        if (headers[planData].checked) {
                            switch (Object.keys(headers)[planIndex]) {
                                case "effective_date":
                                case "delivery_date":
                                case "date_created":
                                case "date_end":
                                    return (
                                        <td>
                                            {window.location.href.includes(
                                                "vajrasecrets"
                                            )
                                                ? moment(
                                                      data[
                                                          Object.keys(headers)[
                                                              planIndex
                                                          ]
                                                      ]
                                                  )
                                                      .format("DD/MM/YYYY")
                                                      .slice(3)
                                                : moment(
                                                      data[
                                                          Object.keys(headers)[
                                                              planIndex
                                                          ]
                                                      ]
                                                  ).format("DD/MM/YYYY")}
                                            {/* <Moment format="DD/MM/YYYY">
                                                {
                                                    data[
                                                        Object.keys(headers)[
                                                            planIndex
                                                        ]
                                                    ]
                                                }
                                            </Moment> */}
                                        </td>
                                    );
                                case "customer_fname":
                                    return (
                                        <td>
                                            {data[
                                                Object.keys(headers)[planIndex]
                                            ]
                                                .charAt(0)
                                                .toUpperCase() +
                                                data[
                                                    Object.keys(headers)[
                                                        planIndex
                                                    ]
                                                ].slice(1) +
                                                " " +
                                                data["customer_lname"]}
                                        </td>
                                    );
                                case "property_value":
                                    // return all property_name value in data
                                    return (
                                        <td>
                                            {data.property.map(
                                                (
                                                    propertyData,
                                                    propertyIndex
                                                ) => {
                                                    return (
                                                        <div
                                                            key={propertyIndex}
                                                        >
                                                            {
                                                                propertyData.property_name
                                                            }

                                                            {
                                                                propertyData.property_value
                                                            }
                                                        </div>
                                                    );
                                                }
                                            )}
                                        </td>
                                    );
                                case "property_price":
                                    return (
                                        <td>
                                            {data.property.map(
                                                (
                                                    propertyData,
                                                    propertyIndex
                                                ) => {
                                                    return (
                                                        <div
                                                            key={propertyIndex}
                                                        >
                                                            {
                                                                propertyData.property_price
                                                            }
                                                        </div>
                                                    );
                                                }
                                            )}
                                        </td>
                                    );
                                case "delivery_frequency":
                                    if (
                                        data[
                                            Object.keys(headers)[planIndex]
                                        ] !== null
                                    ) {
                                        if (
                                            data[
                                                Object.keys(headers)[planIndex]
                                            ] === "twenty_days"
                                        ) {
                                            return <td>20 Days</td>;
                                        }
                                        return (
                                            <td>
                                                {data[
                                                    Object.keys(headers)[
                                                        planIndex
                                                    ]
                                                ]
                                                    .charAt(0)
                                                    .toUpperCase() +
                                                    data[
                                                        Object.keys(headers)[
                                                            planIndex
                                                        ]
                                                    ].slice(1)}
                                            </td>
                                        );
                                    }

                                case "property_name":
                                case "property_status":
                                case "plan_status":
                                case "plan_name":
                                case "subs_status":
                                    if (data[Object.keys(headers)[planIndex]])
                                        return (
                                            <td>
                                                {data[
                                                    Object.keys(headers)[
                                                        planIndex
                                                    ]
                                                ]
                                                    .charAt(0)
                                                    .toUpperCase() +
                                                    data[
                                                        Object.keys(headers)[
                                                            planIndex
                                                        ]
                                                    ].slice(1)}
                                            </td>
                                        );
                                case "subs_type":
                                    if (
                                        data[
                                            Object.keys(headers)[planIndex]
                                        ] === "direct_debit"
                                    ) {
                                        return <td>Recurring</td>;
                                    } else {
                                        return <td>One off</td>;
                                    }
                                case "action":
                                    return (
                                        <td>
                                            {editActionButton(data)}
                                            {kebabOptions.length > 0 ? (
                                                <KebabMenu
                                                    className="action-button"
                                                    options={kebabOptions}
                                                ></KebabMenu>
                                            ) : (
                                                <div
                                                    className="d-inline-block"
                                                    style={{
                                                        width: "24px",
                                                        height: "24px",
                                                    }}
                                                ></div>
                                            )}
                                        </td>
                                    );
                                default:
                                    return (
                                        <td>
                                            {
                                                data[
                                                    Object.keys(headers)[
                                                        planIndex
                                                    ]
                                                ]
                                            }
                                        </td>
                                    );
                            }
                        }
                    }
                );
                return <tr>{tempData}</tr>;
            });
            return { tempDataBody, renderData };
        }
    };

    useEffect(() => {
        const fetchTableData = renderTableBody().renderData;
        updateTableData(fetchTableData);
    }, [filterValue, search, fetchData]);

    const renderTableFooter = () => {
        const footerData = Object.keys(headers).map((header, index) => {
            if (headers[header].checked) {
                return (
                    <th
                        key={index}
                        style={{
                            borderRadius: getFooterNumber(index),
                        }}
                    >
                        {renderFooter(index)}
                    </th>
                );
            }
        });
        return footerData;
    };
    const renderFooter = (index) => {
        // if (index == Object.keys(headers).length - 1) {
        //     return (
        //         <p>
        //             1 - {rowsPerPage == 0 ? tableData.length : rowsPerPage} of{" "}
        //             {tableData.length}
        //         </p>
        //     );
        // }
    };
    return (
        <>
            <Grid item xs={12} lg={12}>
                <Table hover className="mt-4" responsive size="sm">
                    <thead>
                        <tr className="table-header">{renderTableHeader()}</tr>
                    </thead>
                    <tbody className="table-body">
                        {renderTableBody().tempDataBody}
                    </tbody>
                    <thead>
                        <tr className="table-header">{renderTableFooter()}</tr>
                    </thead>
                </Table>
            </Grid>
        </>
    );
};

export default MerchantTable;
