import SideNav, {
    Toggle,
    Nav,
    NavItem,
    NavIcon,
    NavText,
} from "@trendmicro/react-sidenav";
import "@trendmicro/react-sidenav/dist/react-sidenav.css";
import ".//sidebar.css";
import pichaLogo from "../../Images/picha-logo.png";
import { Image } from "react-bootstrap";
import StyledLink from "../StyledLink/nav-link";
import SyncIcon from "@mui/icons-material/Sync";
import DescriptionIcon from "@mui/icons-material/Description";
import SettingsIcon from "@mui/icons-material/Settings";
import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { store } from "../../index.js";
import { Grid } from "@material-ui/core";
import LibraryAddIcon from "@mui/icons-material/LibraryAdd";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import PaidIcon from "@mui/icons-material/Paid";
import SupervisedUserCircleIcon from "@mui/icons-material/SupervisedUserCircle";
import profileService from "../../services/profileService";
import DehazeIcon from "@mui/icons-material/Dehaze";
import HomeIcon from "@mui/icons-material/Home";
import ReceiptIcon from "@mui/icons-material/Receipt";
import { useSelector } from "react-redux";
import getCroppedImgInFileObj, {
    readImageFileIntoDataUrl,
} from "../../utils/imageUtils";
const Sidebar = ({ toggleState, handleToggle }) => {
    const history = useHistory();
    const [logo, setLogo] = useState();
    const [selectedNav, setSelectedNav] = useState(
        localStorage.getItem("sidebar-state")
    );
    const isLoggedIn = useSelector(
        (state) => state.loggedInUserReducer.userData
    );

    const getLogo = () => {
        profileService
            .getProfile()
            .then(async (res) => {
                const profileData = res.settings;
                const existingPlanImageInFileObj = await getCroppedImgInFileObj(
                    `${
                        window.location.protocol +
                        "//" +
                        window.location.hostname
                    }${process.env.REACT_APP_SITE_PORT_BACKEND}${
                        profileData.company_logo
                    }`
                );

                const existingPlanImageFileInDataUrl =
                    await readImageFileIntoDataUrl(existingPlanImageInFileObj);

                setLogo(existingPlanImageFileInDataUrl);
                return existingPlanImageFileInDataUrl;
            })
            .catch((res) => {});
    };
    useEffect(() => {
        getLogo();
    }, []);

    const renderCustomerSideBar = () => {
        return (
            <SideNav
                // expanded={toggleState}
                onSelect={(selected) => {
                    localStorage.setItem("sidebar-state", selected);
                    setSelectedNav(selected);
                }}
                className="sidebar-design"
            >
                <Nav>
                    {toggleState === true ? (
                        <Image
                            src={logo}
                            onClick={() => {
                                history.push("/");
                            }}
                            className="sidebar-logo"
                        />
                    ) : null}
                </Nav>{" "}
                <SideNav.Toggle
                    style={{ float: "right" }}
                    onClick={(e) => {
                        handleToggle();
                    }}
                />
                <SideNav.Nav defaultSelected={selectedNav}>
                    <NavItem
                        className="mt-4"
                        eventKey="home"
                        onClick={(e) => {
                            history.replace("/");
                        }}
                    >
                        <NavIcon>
                            <HomeIcon className="fa sidebar-icon" />
                        </NavIcon>
                        <NavText style={{ color: "black" }}>Home</NavText>
                    </NavItem>

                    <NavItem
                        onClick={(e) => {
                            history.replace("/customer/subscription");
                        }}
                        eventKey="subscription"
                    >
                        <NavIcon>
                            <SyncIcon className="fa sidebar-icon" />
                        </NavIcon>
                        <NavText style={{ color: "black" }}>
                            My Subscriptions
                        </NavText>
                    </NavItem>
                    <NavItem
                        eventKey="invoice"
                        onClick={(e) => {
                            history.replace("/customer/invoice");
                        }}
                    >
                        <NavIcon>
                            <DescriptionIcon className="fa sidebar-icon" />
                        </NavIcon>
                        <NavText style={{ color: "black" }}>
                            My Invoices
                        </NavText>
                    </NavItem>
                    <NavItem
                        eventKey="profile"
                        onClick={(e) => {
                            history.replace("/customer/profile");
                        }}
                    >
                        <NavIcon>
                            <i className="fa fa-user sidebar-icon" />
                        </NavIcon>
                        <NavText style={{ color: "black" }}>My Profile</NavText>
                    </NavItem>
                    <NavItem
                        eventKey="settings"
                        onClick={(e) => {
                            history.replace("/customer/settings");
                        }}
                    >
                        <NavIcon>
                            <SettingsIcon className="fa sidebar-icon" />
                        </NavIcon>
                        <NavText style={{ color: "black" }}>Settings</NavText>
                    </NavItem>
                </SideNav.Nav>
            </SideNav>
        );
    };
    const renderMerchantSideBar = () => {
        return (
            <SideNav
                expanded={toggleState}
                onSelect={(selected) => {
                    localStorage.setItem("sidebar-state", selected);
                    setSelectedNav(selected);
                }}
                className="sidebar-design"
            >
                <Nav>
                    {toggleState === true ? (
                        <Image
                            src={logo}
                            onClick={() => {
                                // localStorage.setItem("sidebar-state", "home");
                                history.push("/");
                            }}
                            className="sidebar-logo"
                        />
                    ) : null}
                </Nav>{" "}
                <SideNav.Toggle
                    style={{ float: "right" }}
                    onClick={(e) => {
                        handleToggle();
                    }}
                />
                <SideNav.Nav defaultSelected={selectedNav}>
                    <NavItem
                        className="mt-4"
                        // active={selectedNav === "home"}
                        eventKey="home"
                        onClick={(e) => {
                            history.replace("/");
                        }}
                    >
                        <NavIcon>
                            <HomeIcon className="fa sidebar-icon" />
                        </NavIcon>
                        <NavText style={{ color: "black" }}>Home</NavText>
                    </NavItem>
                    <NavItem
                        // active={selectedNav === "subscription"}
                        onClick={(e) => {
                            history.replace("/admin/subscription");
                        }}
                        eventKey="subscription"
                    >
                        <NavIcon>
                            <SyncIcon className="fa sidebar-icon" />
                        </NavIcon>
                        <NavText style={{ color: "black" }}>
                            Subscriptions
                        </NavText>
                    </NavItem>
                    <NavItem
                        // active={selectedNav === "plan"}
                        eventKey="plan"
                        onClick={(e) => {
                            history.replace("/admin/plan");
                        }}
                    >
                        <NavIcon>
                            <DescriptionIcon className="fa sidebar-icon" />
                        </NavIcon>
                        <NavText style={{ color: "black" }}>Plans</NavText>
                    </NavItem>
                    <NavItem
                        eventKey="addon"
                        onClick={(e) => {
                            history.replace("/admin/addon");
                        }}
                    >
                        <NavIcon>
                            <LibraryAddIcon className="fa sidebar-icon" />
                        </NavIcon>
                        <NavText style={{ color: "black" }}>Item</NavText>
                    </NavItem>
                    <NavItem
                        eventKey="promotion"
                        onClick={(e) => {
                            history.replace("/admin/promotion");
                        }}
                    >
                        <NavIcon>
                            <LocalOfferIcon className="fa sidebar-icon" />
                        </NavIcon>
                        <NavText style={{ color: "black" }}>Promotion</NavText>
                    </NavItem>
                    {/* <NavItem
                        eventKey="tax"
                        onClick={(e) => {
                            history.push("/admin/tax");
                        }}
                        style={{
                            backgroundColor:
                                localStorage.getItem("sidebar-state") === "tax"
                                    ? "#e5e5e5"
                                    : "",
                        }}
                    >
                        <NavIcon>
                            <ReceiptIcon
                                className="fa"
                                style={{ fontSize: "1.75em", color: "black" }}
                            />
                        </NavIcon>
                        <NavText style={{ color: "black" }}>Tax</NavText>
                    </NavItem> */}
                    <NavItem
                        eventKey="pricingmodel"
                        onClick={(e) => {
                            history.replace("/admin/pricingmodel");
                        }}
                        style={{
                            backgroundColor:
                                localStorage.getItem("sidebar-state") ===
                                "pricingmodel"
                                    ? "#e5e5e5"
                                    : "",
                        }}
                    >
                        <NavIcon>
                            <PaidIcon
                                className="fa"
                                style={{ fontSize: "1.75em", color: "black" }}
                            />
                        </NavIcon>
                        <NavText style={{ color: "black" }}>
                            Pricing Model
                        </NavText>
                    </NavItem>
                    {/* <NavItem
                        eventKey="usermanagement"
                        style={{
                            backgroundColor:
                                localStorage.getItem("sidebar-state") ===
                                "usermanagement"
                                    ? "#e5e5e5"
                                    : "",
                        }}
                        onClick={(e) => {
                            history.push("/admin/profile");
                        }}
                    >
                        <NavIcon>
                            <SupervisedUserCircleIcon
                                className="fa"
                                style={{ fontSize: "1.75em", color: "black" }}
                            />
                        </NavIcon>
                        <NavText style={{ color: "black" }}>
                            User Management
                        </NavText>
                    </NavItem> */}
                    <NavItem
                        eventKey="settings"
                        onClick={(e) => {
                            history.replace("/admin/profile");
                        }}
                    >
                        <NavIcon>
                            <SettingsIcon className="fa sidebar-icon" />
                        </NavIcon>
                        <NavText style={{ color: "black" }}>Settings</NavText>
                    </NavItem>
                </SideNav.Nav>
            </SideNav>
        );
    };
    return (
        // if user role is customer render customer sidebar
        // else render merchant sidebar
        <div className="w-100 sidebar-mobile">
            {store.getState().loggedInUserReducer.userData?.userRole ===
            "customer"
                ? renderCustomerSideBar()
                : renderMerchantSideBar()}
            {toggleState !== true ? (
                <div className="sidebar-overlay">
                    <Image
                        src={logo}
                        onClick={() => {
                            // localStorage.setItem("sidebar-state", "home");
                            history.replace("/");
                        }}
                        className="sidebar-close-logo"
                    />
                </div>
            ) : (
                ""
            )}
        </div>
    );
};

export default Sidebar;
