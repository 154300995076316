import "@fortawesome/fontawesome-free/css/all.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";

import "mdbreact/dist/css/mdb.css";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import { applyMiddleware, combineReducers, createStore } from "redux";
import { createLogger } from "redux-logger";
import { persistReducer, persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import thunkMiddleware from "redux-thunk";
import App from "./App";
import {
    colorState,
    loggedInUserReducer,
    logInStateReducer,
    toggleLoginRegisterModalReducer,
} from "./reducer.js";
import reportWebVitals from "./reportWebVitals";

const persistConfig = {
    key: "root",
    storage,
};
const loginRegisterPersistConfig = {
    key: "toggleLoginRegisterModalReducer",
    storage: storage,
    whitelist: ["isLoginRegisterModalOpened"],
};

const loggedInUserPersistConfig = {
    key: "loggedInUserReducer",
    storage: storage,
};

const logger = createLogger();
const rootReducer = combineReducers({
    toggleLoginRegisterModalReducer: persistReducer(
        loginRegisterPersistConfig,
        toggleLoginRegisterModalReducer
    ),
    logInStateReducer,
    loggedInUserReducer: persistReducer(
        loggedInUserPersistConfig,
        loggedInUserReducer
    ),
    colorState,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = createStore(
    persistedReducer,
    applyMiddleware(thunkMiddleware, logger)
);
const persistor = persistStore(store);

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <App />
            </PersistGate>
        </Provider>
    </React.StrictMode>,
    document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals())
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
