import Grid from "@material-ui/core/Grid";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import { Tooltip } from "@mui/material";
import TextField from "@mui/material/TextField";
import axios from "axios";
import { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import LoginBar from "../../../Components/LoginBar/loginbar";
import { store } from "../../../index.js";
import invoiceService from "../../../services/invoiceService";
import "./style.css";
const Invoice = ({ history, match }) => {
    const [invoiceData, setInvoiceData] = useState("");
    const [rowsPerPage, setRowsPerPage] = useState(0);

    const invoiceResolved = (invoice_resolved, invoice_collection_status, inv_collection_batch, dueDateStr) => {
        var today = new Date();
        var todayDate = new Date(
           today.getFullYear() + "-" + (today.getMonth()) + "-" + today.getDate() + 5);
        var dueDate = new Date(dueDateStr.slice(0, 10));
        
        if ((invoice_resolved == "true" || invoice_resolved == true) && (parseInt(invoice_collection_status) == 0)){
          return "Paid";
        } else if ((invoice_collection_status != null && (parseInt(invoice_collection_status) != 0)) || (todayDate > dueDate))
         {
          return "Overdue";
        } else {
          return "Awaiting Payment";
        }
    };

    const byInvID = (a, b) => {
        if (a.inv_id > b.inv_id) {
            return -1;
        }
        if (a.inv_id < b.inv_id) {
            return 1;
        }
        return 0;
    };

    const downloadInvoice = (invId) => {
        axios({
            method: "POST",
            url: `${
                window.location.protocol +
                "//" +
                window.location.hostname +
                process.env.REACT_APP_SITE_PORT_BACKEND
            }/api/invoice_pdf/`,
            data: { inv_id: invId },
            responseType: "blob",
        })
            .then((res) => {
                var a = document.createElement("a");
                document.body.appendChild(a);
                a.style = "display: none";
                var url = window.URL.createObjectURL(res.data);
                a.href = url;
                a.download = `${invId}.pdf`;
                a.click();
                window.URL.revokeObjectURL(url);
            })
            .catch((err) => {
                console.error(err, "error");
                Swal.fire({
                    icon: "error",
                    title: "Could not download the invoice now",
                    text: "Please try again later.",
                    confirmButtonColor: store.getState().colorState.color,
                });
            });
    };

    const navigateToViewSubscriptionPage = (sub_id) => {
        history.push(`/customer/subscription/${sub_id}`);
    };
    const parseDate = (date) => {
        let newDate = date.toString().slice(0, 10);
        newDate =
            newDate.slice(8, 10) +
            "/" +
            newDate.slice(5, 7) +
            "/" +
            newDate.slice(0, 4);
        return newDate;
    };
    const renderInvoiceData = () => {
        let tableData = invoiceData.map((data, index) => {
            return (
                <tr>
                    <td
                        style={{
                            fontSize: window.innerWidth < 1000 ? "0px" : "",
                        }}
                    >
                        {data.inv_id}
                    </td>
                    <td>{parseDate(data.inv_issue_date)}</td>
                    {/* <td>{}</td> */}
                    <td>{parseDate(data.inv_due_date)}</td>
                    <td>RM{data.inv_total_amt.toFixed(2)}</td>
                    <td>{invoiceResolved(data.inv_resolved.toString(), data.inv_collection_status, data.inv_collection_batch, data.inv_due_date)}</td>            	    <td>
                        <Tooltip title="Download Invoice as pdf">
                            <a
                                onClick={downloadInvoice.bind(
                                    this,
                                    data.inv_id
                                )}
                            >
                                <PictureAsPdfIcon className="invoice-action-icon" />
                            </a>
                        </Tooltip>
                    </td>
                </tr>
            );
        });
        if (rowsPerPage != 0) {
            tableData = tableData.slice(0, rowsPerPage);
        }
        return tableData;
    };

    useEffect(() => {
        invoiceService
            .customerGetAllInvoice()
            .then((res) => {
                var sortedInvArray = res.sort(byInvID);
                setInvoiceData(res);
            })
            .catch((err) => {
                toast.error(
                    <div>
                        Some Error Occur, Please try again later.
                        <br />
                        {JSON.stringify(err, null, 2)}
                        <br />
                    </div>,
                    {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    }
                );
            });
    }, []);
    // loggedin
    return (
        <Grid container>
            <Grid item style={{ marginLeft: "20px" }} xs={12} md={12} lg={12}>
                {/* profile icon */}
                <Grid container style={{ marginLeft: "20px" }} className="mt-3">
                    {/* profile icon and username dropdown */}
                    <Grid
                        item
                        align="right"
                        xs={12}
                        className="login-bar-customer "
                        lg={12}
                    >
                        <LoginBar />
                    </Grid>
                    <Grid item className="ml-3" align="left" xs={12} lg={12}>
                        <strong style={{ fontSize: "33px" }}>
                            My Invoices
                        </strong>
                    </Grid>
                    <Grid item className="customer-table" xs={12} lg={12}>
                        <Table
                            // style={{ width: "100%" }}
                            hover
                            // className="mt-4"
                            responsive
                            size="sm"
                        >
                            <thead>
                                <tr
                                    style={{
                                        backgroundColor: "black",
                                        color: "white",
                                        borderRadius: "10px",
                                    }}
                                >
                                    <th
                                        style={{
                                            borderRadius: "10px 0px 0px 0px",
                                            fontSize:
                                                window.innerWidth < 1000
                                                    ? "0px"
                                                    : "",
                                        }}
                                    >
                                        INVOICE ID
                                    </th>
                                    <th
                                        style={{
                                            fontSize:
                                                window.innerWidth < 1000
                                                    ? "0px"
                                                    : "",
                                        }}
                                    >
                                        DATE GENERATED
                                    </th>
                                    {/* <th>Plan Type</th> */}
                                    <th>PAYMENT DATE</th>
                                    <th>INVOICE TOTAL</th>
                                    <th>STATUS</th>

                                    <th
                                        style={{
                                            borderRadius: "0px 10px 0px 0px",
                                        }}
                                    >
                                        ACTION
                                    </th>
                                </tr>
                            </thead>
                            <tbody style={{ backgroundColor: "white" }}>
                                {/* get current user's subscriptions */}
                                {invoiceData !== "" ? renderInvoiceData() : ""}
                            </tbody>
                            <thead>
                                <tr
                                    style={{
                                        backgroundColor: "black",
                                        color: "white",
                                        borderRadius: "10px",
                                    }}
                                >
                                    <th
                                        style={{
                                            borderRadius: "0px 0px 0px 10px",
                                        }}
                                    ></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>

                                    <th
                                        style={{
                                            fontSize:
                                                window.innerWidth < 1000
                                                    ? "0px"
                                                    : "",
                                        }}
                                    >
                                        ROWS PER PAGE:
                                        <TextField
                                            className="ml-2"
                                            style={{
                                                width: "15%",
                                                backgroundColor: "black",
                                                borderRadius: "5px",
                                                border: "1px solid white",
                                                display:
                                                    window.innerWidth < 1000
                                                        ? "none"
                                                        : "",
                                            }}
                                            InputProps={{
                                                style: {
                                                    height: "20px",
                                                    color: "white",
                                                },
                                            }}
                                            onChange={(e) => {
                                                setRowsPerPage(e.target.value);
                                            }}
                                            size="small"
                                            type="number"
                                        />
                                    </th>
                                    <th
                                        style={{
                                            borderRadius: "0px 0px 10px 0px",
                                            fontSize:
                                                window.innerWidth < 1000
                                                    ? "0px"
                                                    : "",
                                        }}
                                    >
                                        {invoiceData.length == 0 ? 0 : 1}-
                                        {rowsPerPage == 0
                                            ? invoiceData.length
                                            : rowsPerPage}{" "}
                                        of {invoiceData.length}
                                    </th>
                                </tr>
                            </thead>
                        </Table>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default Invoice;
