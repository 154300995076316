import LockIcon from "@mui/icons-material/Lock";
import { Grid } from "@mui/material";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import { useEffect, useState } from "react";
import { Image, Modal } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import { useHistory } from "react-router-dom";
import { urlAction } from "../../action";
import FpxLogo from "../../Images/fpx-logo.png";
import cardIcon from "../../Images/visa-master-logo.png";
import { store } from "../../index.js";
import customerService from "../../services/customerService";
import merchantService from "../../services/merchantService";
import profileService from "../../services/profileService";
import subscriptionService from "../../services/subscriptionService";
import googleAnalyticService from "../../services/analyticService/googleAnalytic";
import pixelService from "../../services/analyticService/pixel";

import "./style.css";
import ga4Service from "../../services/analyticService/ga4";
const PaymentMethod = ({
    planData,
    subData,
    subPrice,
    custId,
    deliveryInfo,
    stepper,
    custMandateRef,
}) => {
    const history = useHistory();
    const hostname = urlAction("get_merchant");
    const [paymentMethodExist, setPaymentMethodExist] = useState(false);
    const [merchantInfo, setMerchantInfo] = useState();
    const [paymentMethod, setPaymentMethod] = useState(1);
    const [bankList, setBankList] = useState();
    const [selectedBank, setSelectedBank] = useState();
    const [bank, setBank] = useState();
    const [curlecMerchantId, setCurlecMerchantId] = useState();
    const [curlecEmployeeId, setCurlecEmployeeId] = useState();
    const merchantName = urlAction("get_merchant");
    const [creditCardEnabled, setCreditCardEnabled] = useState(false);
    const [showMylipanModal, setShowMylipanModal] = useState(false);
    const [mylipanDataBody, setMylipanDataBody] = useState();
    const [showVajraModal, setShowVajraModal] = useState(false);
    const [vajraDataBody, setVajraDataBody] = useState();
    const [sellerId, setSellerId] = useState();
    const [dedicationNote, setDedicationNote] = useState();
    const [maxMandateCC, setMaxMandateCC] = useState();
    const [maxMandateFPX, setMaxMandateFPX] = useState();
    const isLoggedIn = useSelector(
        (state) => state.loggedInUserReducer.userData
    );

    const getMerchantSettings = async () => {
        await profileService.getProfile().then((e) => {
            console.log(e.settings);
            setMaxMandateCC(e.settings.max_mandate_cc);
            setMaxMandateFPX(e.settings.max_mandate_fpx);
        });
    };

    useEffect(() => {
        const splitURL = urlAction("get_merchant");
        const getMerchantId = async () => {
            await merchantService.getMerchantInfo().then((e) => {
                console.log(e, "this is the merchant info");
                let merchantInfo = e;
                setCurlecMerchantId({
                    value: parseInt(e[0].merch_curlec_merchant_id),
                });
                setCurlecEmployeeId({
                    value: parseInt(e[0].merch_curlec_employee_id),
                });
                let merchantId = merchantInfo
                    .filter((obj) => obj.merch_name == splitURL)
                    .map((obj) => {
                        return obj.id;
                    });
                setMerchantInfo(e[0].id);
            });
        };
        // get credit card settings for merchant
        const creditCardDetails = async () => {
            await merchantService
                .getCreditCardSettings()
                .then((e) => {
                    // console.log("credit card settings", e);
                    setCreditCardEnabled(e.credit_card);
                })
                .catch((e) => {
                    console.log("Error while getting credit card settings", e);
                });
            // if user logged in
            // get payment details here
            // need previous cust mandate ref and cust id
            // get user bank details
            // if exist, set bank details
            // else, set empty bank details
            if (isLoggedIn) {
                if (custMandateRef !== null) {
                    await customerService
                        .getPaymentDetails(
                            { cust_mandate_ref: custMandateRef },
                            custId
                        )
                        .then((e) => {
                            if (
                                JSON.parse(
                                    sessionStorage.getItem("paymentOption")
                                ) == "instant_pay"
                            ) {
                                setPaymentMethodExist(false);
                            } else {
                                setPaymentMethodExist(true);
                                setSelectedBank(e);
                            }
                        })
                        .catch((e) => {
                            console.log(e, "this error");
                        });
                }
            }
        };
        getMerchantId();
        creditCardDetails();
        getMerchantSettings();
    }, []);

    const mylipanModal = (dataBody) => {
        return (
            <Modal
                size="lg"
                dialogClassName="custom-modal"
                centered
                show={showMylipanModal}
                onHide={() => setShowMylipanModal(false)}
            >
                <Modal.Header closeButton>
                    <h5>
                        <strong>Input Seller ID</strong>
                    </h5>
                </Modal.Header>
                <Modal.Body>
                    <Grid
                        align="center"
                        style={{ marginBottom: "30px" }}
                        container
                    >
                        <Grid item xs={12} md={12} lg={12}>
                            <Form.Group className="mb-3">
                                <Form.Label
                                    style={{ textAlign: "left" }}
                                    className="text-left"
                                >
                                    Please input your seller ID if applicable,
                                    else just click "Proceed"
                                </Form.Label>
                                <Form.Control
                                    className="w-75"
                                    value={sellerId}
                                    onChange={(e) => {
                                        setSellerId(e.target.value);
                                    }}
                                />
                            </Form.Group>
                        </Grid>
                        <Grid container xs={12} lg={12} md={12}>
                            <Grid item align="right" xs={6} md={6} lg={6}>
                                {/* cancel button */}
                                <Button
                                    style={{
                                        marginRight: "10px",
                                        borderRadius: "10px",
                                    }}
                                    variant="contained"
                                    // className="cancel-button"

                                    onClick={() => setShowMylipanModal(false)}
                                >
                                    Cancel
                                </Button>
                            </Grid>
                            <Grid item align="left" xs={6} md={6} lg={6}>
                                {/* proceed button */}
                                <Button
                                    variant="contained"
                                    style={{
                                        marginLeft: "10px",
                                        background: "black",
                                        color: "white",
                                        borderRadius: "10px",
                                    }}
                                    onClick={() => {
                                        dataBody = {
                                            ...dataBody,
                                            seller_id: sellerId,
                                        };
                                        checkMandate(dataBody);
                                    }}
                                >
                                    Proceed
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Modal.Body>
            </Modal>
        );
    };

    const vajraModal = (dataBody) => {
        return (
            <Modal
                size="lg"
                dialogClassName="custom-modal"
                centered
                show={showVajraModal}
                onHide={() => setShowVajraModal(false)}
            >
                <Modal.Header closeButton>
                    <h5>
                        <strong>Dedication Note</strong>
                    </h5>
                </Modal.Header>
                <Modal.Body>
                    <Grid
                        align="center"
                        style={{ marginBottom: "30px" }}
                        container
                    >
                        <Grid item xs={12} md={12} lg={12}>
                            <Form.Group className="mb-3">
                                <Form.Control
                                    as="textarea"
                                    // className="w-75"
                                    value={dedicationNote}
                                    onChange={(e) => {
                                        setDedicationNote(e.target.value);
                                    }}
                                />
                            </Form.Group>
                        </Grid>
                        <Grid container xs={12} lg={12} md={12}>
                            <Grid item align="right" xs={6} md={6} lg={6}>
                                {/* cancel button */}
                                <Button
                                    style={{
                                        marginRight: "10px",
                                        borderRadius: "10px",
                                    }}
                                    variant="contained"
                                    // className="cancel-button"

                                    onClick={() => setShowVajraModal(false)}
                                >
                                    Cancel
                                </Button>
                            </Grid>
                            <Grid item align="left" xs={6} md={6} lg={6}>
                                {/* proceed button */}
                                <Button
                                    variant="contained"
                                    style={{
                                        marginLeft: "10px",
                                        background: "black",
                                        color: "white",
                                        borderRadius: "10px",
                                    }}
                                    onClick={() => {
                                        dataBody = {
                                            ...dataBody,
                                            dedication_note: dedicationNote,
                                        };
                                        checkMandate(dataBody);
                                    }}
                                >
                                    Proceed
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Modal.Body>
            </Modal>
        );
    };

    const paymentOption = (method) => {
        switch (method) {
            case 1:
                return (
                    <Grid container className="mt-3" xs={12}>
                        <Grid item xs={12} md={12} lg={12}>
                            <p className="mt-2">Select your bank</p>
                        </Grid>
                        <Grid item xs={12} md={12} lg={12}>
                            <select
                                class="form-select mb-3 "
                                style={{ width: "95%" }}
                                aria-label="Default select example"
                                onChange={(e) => {
                                    setSelectedBank(e.target.value);
                                }}
                                disabled={paymentMethodExist}
                                value={selectedBank}
                            >
                                <option value={undefined}>Select a bank</option>
                                {bankList !== undefined
                                    ? bankList.map((bank, index) => {
                                          return (
                                              <option value={bank.id}>
                                                  {bank.display_name}
                                              </option>
                                          );
                                      })
                                    : ""}
                            </select>
                        </Grid>
                    </Grid>
                );
            case 2:
                return;
                <></>;
        }
    };

    const filterSideOrder = () => {
        if (JSON.parse(sessionStorage.getItem("addon")) !== null) {
            const checkedOrder = JSON.parse(
                sessionStorage.getItem("addon")
            ).filter((data) => data.checked == true);
            return checkedOrder;
        }
    };

    const subscribeToPlan = () => {
        // set databody
        // check payment method
        // remove delivery_frequency key in planData

        // console.log(subData, "sub data");
        // console.log(curlecMerchantId, "merch id", curlecEmployeeId);
        planData.delivery_frequency = undefined;

        var dataBody = {
            ...planData,
            ...subData,
            subs_price: subPrice,
            subs_status: "inactive",
            delivery_date: JSON.parse(sessionStorage.getItem("deliveryDate")),
            delivery_time: JSON.parse(sessionStorage.getItem("deliveryTime")),
            delivery_frequency: JSON.parse(
                sessionStorage.getItem("deliveryFrequency")
            ),
            subs_type:
                JSON.parse(sessionStorage.getItem("paymentOption")) == null
                    ? ""
                    : JSON.parse(sessionStorage.getItem("paymentOption")),
            default_menu:
                JSON.parse(sessionStorage.getItem("defaultMenu")) == null
                    ? []
                    : JSON.parse(sessionStorage.getItem("defaultMenu")),
            non_default_menu:
                JSON.parse(sessionStorage.getItem("nonDefaultMenu")) == null
                    ? []
                    : JSON.parse(sessionStorage.getItem("nonDefaultMenu")),
            side_order: filterSideOrder(),
            cust_mandate_ref:
                store.getState().loggedInUserReducer.userData?.ar_system
                    ?.cust_mandate_ref,
            delivery_address:
                store.getState().loggedInUserReducer.userData?.ar_system
                    ?.cust_addr_line_1,
            customer_id:
                store.getState().loggedInUserReducer.userData?.ar_system
                    ?.cust_id,
            customer_fname:
                store.getState().loggedInUserReducer.userData?.ar_system
                    ?.cust_fname,
            customer_lname:
                store.getState().loggedInUserReducer.userData?.ar_system
                    ?.cust_lname,
            customer_email:
                store.getState().loggedInUserReducer.userData?.ar_system
                    ?.cust_email,
            customer_delivery_instructions:
                store.getState().loggedInUserReducer.userData?.ar_system
                    ?.cust_delivery_instructions,
            customer_contact_no:
                store.getState().loggedInUserReducer.userData?.ar_system
                    ?.cust_contact_no,
            customer_postcode:
                store.getState().loggedInUserReducer.userData?.ar_system
                    ?.cust_addr_postcode,
            merchant_id: merchantInfo,
            cust_mandate_type: paymentMethod,
        };
        if (subData.promo_id != undefined) {
            dataBody.promo_status = "active";
        }
        if (window.location.href.includes("vajrasecrets")) {
            dataBody = {
                ...dataBody,
                vajra_dedication_note: JSON.parse(
                    sessionStorage.getItem("vajraDedicationNote")
                ),
                vajra_ref_code: JSON.parse(
                    sessionStorage.getItem("vajraRefCode")
                ),
                vajra_gift_message: JSON.parse(
                    sessionStorage.getItem("vajraGiftMessage")
                ),
                vajra_recipient_name: JSON.parse(
                    sessionStorage.getItem("vajraRecipientName")
                ),
                vajra_recipient_email: JSON.parse(
                    sessionStorage.getItem("vajraRecipientEmail")
                ),
                vajra_this_a_gift: JSON.parse(
                    sessionStorage.getItem("vajraGift")
                ),
                vajra_language:
                    store.getState().loggedInUserReducer.userData?.ar_system
                        ?.vajra_language,
                vajra_kechara_membership:
                    store.getState().loggedInUserReducer.userData?.ar_system
                        ?.vajra_kechara_membership,
            };
        }
        if (window.location.href.includes("ketoccino")) {
            let ketoccinoData = JSON.parse(
                sessionStorage.getItem("ketoccino_coffee")
            );
	    if (ketoccinoData !== null) {
	    	if (JSON.parse(sessionStorage.getItem("paymentOption")) !==
                                "instant_pay") {
		ketoccinoData[0].quantity = ketoccinoData[0].quantity * 2;
		}
            	dataBody = {
                	...dataBody,
                	default_menu: ketoccinoData,
            	};
	    }

        }

        if (isLoggedIn.userData == "merchant") {
            Swal.fire({
                title: "Subscribe to plan error",
                text: "Please try again.",
                icon: "error",
                confirmButtonColor: store.getState().colorState.color,
            });
        } else {
            // if mylipan, show modal
            if (hostname == "mylipan") {
                setMylipanDataBody(dataBody);
                setShowMylipanModal(true);
            } else if (window.location.href.includes("vajrasecrets")) {
                setVajraDataBody(dataBody);
                checkMandate(dataBody);
            } else {
                checkMandate(dataBody);
            }
        }
    };

    const createOneOffSub = (dataBody) => {
        subscriptionService
            .createSubscription(dataBody)
            .then((res) => {
                pixelService.trackSubscribe(dataBody.plan_name);
                googleAnalyticService.trackSubscribe(
                    dataBody.subs_price,
                    dataBody.plan_name,
                    store.getState().loggedInUserReducer.userData.ar_system
                        .cust_id
                );
                ga4Service.trackInitiateCheckout(
                    dataBody.plan_name,
                    dataBody.subs_price
                );
                var bankCode = bank == null || bank == "" ? "" : bank.code[0];
                const oneOffLink = `${
                    process.env.REACT_APP_CURLEC_ENDPOINT
                }/new-instant-pay?effectiveDate=&method=03&merchantId=${
                    curlecMerchantId.value
                }&employeeId=${curlecEmployeeId.value}&orderNo=${
                    res.subs_id
                }&email=${
                    store.getState().loggedInUserReducer.userData.ar_system
                        .cust_email
                }&description=${dataBody.plan_name.slice(0, 18)}&note=${
                    res.subs_id
                }&amount=${dataBody.subs_price.toFixed(
                    2
                )}&paymentMethod=${paymentMethod}&bankCode=${bankCode}`;
                window.open(oneOffLink, "_self");
            })
            .catch((res) => {
                console.log(res, "error");
            });
    };

    const createRecurringSub = (dataBody) => {
        subscriptionService
            .createSubscription(dataBody)
            .then((res) => {
                var subsId = res["subs_id"];
                var data_body = { subs_id: subsId };
                subscriptionService
                    .newActiveSubForInvoice(data_body)
                    .then((response) => {
                        pixelService.trackPurchase(subsId);
                        googleAnalyticService.trackPurchase(subsId);
                        ga4Service.trackCheckoutCompleted(subsId);
                        Swal.fire({
                            title: "Congratulations!",
                            text: "You have subscribed to the plan! You will be redirected to your subscription page",
                            icon: "success",
                            confirmButtonText: "OK",
                            confirmButtonColor:
                                store.getState().colorState.color,
                        }).then(() => {
                            history.replace("/customer/subscription");
                        });
                    });
            })
            .catch((err) => {
                console.log(err, "error");
            });
    };

    const checkMandate = (dataBody) => {
        customerService
            .getSpecificCustomerFromAR(
                store.getState().loggedInUserReducer.userData.ar_system.cust_id
            )
            .then((res) => {
                if (
                    JSON.parse(sessionStorage.getItem("paymentOption")) ==
                    "instant_pay"
                ) {
                    createOneOffSub(dataBody);
                } else if (
                    JSON.parse(sessionStorage.getItem("paymentOption")) ==
                    "direct_debit"
                ) {
                    pixelService.trackSubscribe(dataBody.plan_name);
                    googleAnalyticService.trackSubscribe(
                        dataBody.subs_price,
                        dataBody.plan_name,
                        store.getState().loggedInUserReducer.userData.ar_system
                            .cust_id
                    );
                    ga4Service.trackInitiateCheckout(
                        dataBody.plan_name,
                        dataBody.subs_price
                    );
                    if (res["cust_mandate_ref"] !== null) {
                        // cust_mandate_ref == cust id
                        if (!res["cust_mandate_status"]) {
                            // boolean
                            // create sub with pending status
                            // check bank selected before

                            dataBody.subs_status = "pending";
                            createRecurringSub(dataBody);
                            // create sub with active status
                        } else if (res["cust_mandate_status"]) {
                            dataBody.subs_status = "active";
                            createRecurringSub(dataBody);
                        }
                    } else {
                        // link for new mandate
                        subscriptionService
                            .createSubscription(dataBody)
                            .then((e) => {
                                // var bankId = bank == null || bank == "" ? "" : bank.id[0];
                                var bankId =
                                    bank == null || bank == ""
                                        ? ""
                                        : bank.id[0];
                                var amount = maxMandateFPX;
                                if (paymentMethod == 2) {
                                    amount = maxMandateCC;
                                }
                                var subsId = e["subs_id"];
                                var data_body = { subs_id: subsId };
                                var planName = planData.plan_name.substring(
                                    0,
                                    27
                                );
                                window.open(
                                    `${
                                        process.env.REACT_APP_CURLEC_ENDPOINT
                                    }/new-mandate?effectiveDate=&method=03&amount=${amount}&frequency=MONTHLY&maximumFrequency=99&merchantId=${
                                        curlecMerchantId.value
                                    }&employeeId=${
                                        curlecEmployeeId.value
                                    }&emailAddress=${
                                        store.getState().loggedInUserReducer
                                            .userData.ar_system.cust_email
                                    }&name=${
                                        store.getState().loggedInUserReducer
                                            .userData.ar_system.cust_fname
                                    }%20${
                                        store.getState().loggedInUserReducer
                                            .userData.ar_system.cust_lname
                                    }&referenceNumber=${
                                        store.getState().loggedInUserReducer
                                            .userData.ar_system.cust_id
                                    }&purposeOfPayment=${
                                        planData.plan_id
                                    }&paymentMethod=${paymentMethod}&bankId=${selectedBank}&idType=OTHERS&idValue=12345&linkId=${subsId}`,
                                    "_self"
                                );
                            })
                            .catch((e) => {
                                console.log(
                                    e.message,
                                    "this is the error message"
                                );
                                if ("vajra_dedication_note" in e.message) {
                                    Swal.fire({
                                        title: "Error",
                                        text: "Dedication note cannot have more than 500 characters.",
                                        icon: "error",
                                        confirmButtonText: "OK",
                                        confirmButtonColor:
                                            store.getState().colorState.color,
                                    });
                                } else if ("vajra_gift_message" in e.message) {
                                    Swal.fire({
                                        title: "Error",
                                        text: "Gift message cannot have more than 500 characters.",
                                        icon: "error",
                                        confirmButtonText: "OK",
                                        confirmButtonColor:
                                            store.getState().colorState.color,
                                    });
                                } else if (
                                    "vajra_recipient_name" in e.message
                                ) {
                                    Swal.fire({
                                        title: "Error",
                                        text: "Recipient name cannot have more than 50 characters.",
                                        icon: "error",
                                        confirmButtonText: "OK",
                                        confirmButtonColor:
                                            store.getState().colorState.color,
                                    });
                                }
                            });
                    }
                } else if (planData.payment_option == "recurring_one_off") {
                    console.log(
                        "this is recurring_one_off and ure not supposed to be here because filtration is done in planDetail, chestnut"
                    );
                }
                // if mandate exists
            })
            .catch(() => {
                Swal.fire({
                    title: "Subscribe to plan error",
                    text: "Please try again.",
                    icon: "error",
                    confirmButtonColor: store.getState().colorState.color,
                });
            });
    };

    useEffect(() => {
        var method;
        if (paymentMethod == 1) {
            method = { method: "00" };
        } else {
            method = { method: "01" };
        }
        subscriptionService
            .getBanksForSubscription(method)
            .then((res) => {
                var result = res.replace(/'/g, '"');
                var banks = JSON.parse(result);
                setBankList(banks);
                console.log(res, "list of banks");
                if (selectedBank != "-") {
                    for (var i = 0; i < banks.length; i++) {
                        // console.log(selectedBank, "this is selected bank");
                        if (selectedBank == banks[i].id[0]) {
                            // console.log(banks[i], "selected bank");
                            setBank(banks[i]);

                            setSelectedBank(banks[i].id[0]);
                        }
                    }
                }
            })
            .catch((err) => {
                Swal.fire({
                    title: "Error",
                    text: "Could not retrieve FPX bank list",
                    icon: "error",
                    confirmButtonColor: store.getState().colorState.color,
                    confirmButtonText: "ok",
                });
            });
    }, [selectedBank]);

    return (
        <Box>
            <Card
                style={{
                    borderRadius: "8px",
                    boxShadow: "3px 5px #DCDCDC",
                }}
                className="p-2"
                variant="outlined"
            >
                <Grid
                    container
                    align="left"
                    className="mt-3 payment-method-card text-left"
                >
                    <Grid item xs={12}>
                        <h4>Payment Method</h4>
                    </Grid>

                    <Grid item xs={creditCardEnabled === false ? 12 : 6}>
                        <Button
                            value="1"
                            disabled={paymentMethodExist}
                            className={`${
                                creditCardEnabled === false
                                    ? "credit-card-enabled"
                                    : "credit-card-disabled"
                            } ${
                                paymentMethod == 1
                                    ? "payment-clicked "
                                    : "payment-not-clicked"
                            }${
                                paymentMethodExist == true && paymentMethod == 1
                                    ? "payment-exist"
                                    : ""
                            } fpx-payment-method`}
                            onClick={(e) => {
                                setPaymentMethod(1);
                            }}
                        >
                            <Image src={FpxLogo} className="paymentImg" />
                        </Button>
                    </Grid>

                    {creditCardEnabled === false ? null : (
                        <Grid item align="right" xs={6}>
                            <Button
                                // className="mr-4"
                                value="2"
                                style={{
                                    height: "80%",
                                    width: "90%",
                                    marginRight: "30px",
                                    borderRadius: "5px",
                                    border: "1px solid black",
                                }}
                                disabled={paymentMethodExist}
                                className={`${
                                    paymentMethod == 2
                                        ? "payment-clicked"
                                        : "payment-logo"
                                }  ${
                                    paymentMethodExist == true &&
                                    paymentMethod == 2
                                        ? "payment-exist"
                                        : ""
                                }`}
                                onClick={(e) => {
                                    // console.log(e.target.value);
                                    setPaymentMethod(2);
                                }}
                            >
                                <Image src={cardIcon} className="paymentImg" />
                            </Button>
                        </Grid>
                    )}

                    {paymentOption(paymentMethod)}
                    {/* switch case here */}
                </Grid>
            </Card>

            <Grid container>
                {window.innerWidth < 1200 ? (
                    <Grid item align="left" xs={12}>
                        <p className="ml-2 mt-2">
                            By purchasing this product, I agree to the{" "}
                            <a
                                href="https://go.curlec.com/terms-and-condition"
                                target="_blank"
                                style={{ color: "blue" }}
                            >
                                Terms & Conditions.
                            </a>
                        </p>
                    </Grid>
                ) : (
                    <Grid item xs={12}>
                        <p className="mt-2 ml-1 mt-4">
                            By purchasing this product, I agree to the{" "}
                            <a
                                href="https://go.curlec.com/terms-and-condition"
                                target="_blank"
                                style={{ color: "blue" }}
                            >
                                {" "}
                                Terms & Conditions.
                            </a>
                        </p>
                    </Grid>
                )}
            </Grid>
            <Grid container>
                <Grid item className="mb-3 p-1" xs={6} md={6} lg={6}>
                    <Button
                        className="payment-method-back-btn"
                        fullWidth
                        onClick={(e) => {
                            // subscribe to plan
                            if (deliveryInfo === undefined) {
                                stepper(-3);
                            } else {
                                stepper(-2);
                            }
                            ga4Service.trackBackButton("Checkout Page");
                        }}
                    >
                        Back
                    </Button>
                </Grid>
                <Grid item className="mb-3 p-1" xs={6} md={6} lg={6}>
                    <Button
                        fullWidth
                        // color="primary"
                        variant="contained"
                        className="payment-method-checkout-btn"
                        style={{
                            backgroundColor: store.getState().colorState.color,
                            color: store.getState().colorState.font_color,
                        }}
                        onClick={(e) => {
                            // subscribe to plan
                            console.log(
                                sessionStorage,
                                "this is the get state"
                            );
                            if (
                                selectedBank == undefined &&
                                paymentMethod == 1
                            ) {
                                Swal.fire({
                                    icon: "error",
                                    title: "Please select a bank",
                                    confirmButtonColor:
                                        store.getState().colorState.color,
                                });
                            } else {
                                if (isLoggedIn.userRole == "merchant") {
                                    Swal.fire({
                                        icon: "error",
                                        title: "You can't subscribe with a merchant account.",
                                        text: "error",
                                        confirmButtonColor:
                                            store.getState().colorState.color,
                                    });
                                } else {
                                    if (
                                        window.location.href.includes(
                                            "vajrasecrets"
                                        )
                                    ) {
                                        let isValid = true;
                                        // check email format here
                                        if (
                                            JSON.parse(
                                                sessionStorage.getItem(
                                                    "vajraDedicationNote"
                                                )
                                            ) === ""
                                        ) {
                                            isValid = false;
                                            Swal.fire({
                                                icon: "error",
                                                title: "Error occurred",
                                                text: `Please enter a dedication note`,
                                                confirmButtonColor:
                                                    store.getState().colorState
                                                        .color,
                                            });
                                        }
                                        if (
                                            JSON.parse(
                                                sessionStorage.getItem(
                                                    "vajraGift"
                                                )
                                            )
                                        ) {
                                            console.log(
                                                JSON.parse(
                                                    sessionStorage.getItem(
                                                        "vajraGiftMessage"
                                                    )
                                                ),
                                                "in parsed vajra gift"
                                            );
                                            if (
                                                JSON.parse(
                                                    sessionStorage.getItem(
                                                        "vajraGiftMessage"
                                                    )
                                                ) === ""
                                            ) {
                                                isValid = false;
                                                Swal.fire({
                                                    icon: "error",
                                                    title: "Error occurred",
                                                    text: `Please enter a gift message`,
                                                    confirmButtonColor:
                                                        store.getState()
                                                            .colorState.color,
                                                });
                                            }
                                            if (
                                                JSON.parse(
                                                    sessionStorage.getItem(
                                                        "vajraRecipientName"
                                                    )
                                                ) === ""
                                            ) {
                                                isValid = false;
                                                Swal.fire({
                                                    icon: "error",
                                                    title: "Error occurred",
                                                    text: `Please enter a recipient name`,
                                                    confirmButtonColor:
                                                        store.getState()
                                                            .colorState.color,
                                                });
                                            }
                                            if (
                                                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(
                                                    JSON.parse(
                                                        sessionStorage.getItem(
                                                            "vajraRecipientEmail"
                                                        )
                                                    )
                                                )
                                            ) {
                                                // swal fire wrong email format
                                                isValid = false;
                                                Swal.fire({
                                                    icon: "error",
                                                    title: "Error occurred",
                                                    text: `Please check recipient email format`,
                                                    confirmButtonColor:
                                                        store.getState()
                                                            .colorState.color,
                                                });
                                            } else if (
                                                JSON.parse(
                                                    sessionStorage.getItem(
                                                        "vajraRecipientEmail"
                                                    )
                                                ) === ""
                                            ) {
                                                isValid = false;
                                                Swal.fire({
                                                    icon: "error",
                                                    title: "Error occurred",
                                                    text: `Please enter a recipient email`,
                                                    confirmButtonColor:
                                                        store.getState()
                                                            .colorState.color,
                                                });
                                            }
                                        }

                                        if (isValid) {
                                            // subscribe to plan
                                            subscribeToPlan();
                                        }
                                    } else {
                                        subscribeToPlan();
                                    }
                                }
                            }
                        }}
                    >
                        <Grid
                            style={{
                                backgroundColor:
                                    store.getState().colorState.color,
                                color: store.getState().colorState.font_color,
                            }}
                            container
                        >
                            <Grid
                                item
                                style={{
                                    backgroundColor:
                                        store.getState().colorState.color,
                                    color: store.getState().colorState
                                        .font_color,
                                }}
                                align="center"
                                xs={12}
                                md={12}
                                lg={12}
                            >
                                <LockIcon
                                    style={{
                                        marginRight: "5px",
                                        fontSize: "1.2rem",
                                        backgroundColor:
                                            store.getState().colorState.color,
                                        color: store.getState().colorState
                                            .font_color,
                                    }}
                                />
                                Checkout
                            </Grid>
                        </Grid>
                    </Button>
                </Grid>
            </Grid>
            {mylipanModal(mylipanDataBody)}
            {vajraModal(vajraDataBody)}
        </Box>
    );
};
export default PaymentMethod;
