import { ListItemIcon } from "@material-ui/core";
import BlockIcon from "@mui/icons-material/Block";
import DeleteIcon from "@material-ui/icons/Delete";
import PauseIcon from "@material-ui/icons/Pause";
import ArchiveIcon from "@material-ui/icons/Archive";
import planService from "../../services/planService";
import addOnService from "../../services/addOnService";
import subscriptionService from "../../services/subscriptionService";
import promotionService from "../../services/promotionService";
import PlaylistAddCheckIcon from "@material-ui/icons/PlaylistAddCheck";
import RefreshIcon from "@mui/icons-material/Refresh";
import Swal from "sweetalert2";
import { store } from "../../index.js";

import tierPricingModelService from "../../services/tierPricingModelService";
const ListingKebabOptions = (RowData, Page, fetchNewData) => {
    const archivePlanOption = {
        name: "Archive Plan",
        icon: (
            <ListItemIcon>
                <ArchiveIcon fontSize="small" />
            </ListItemIcon>
        ),
        optionCallback: () => {
            Swal.fire({
                title: "Are you sure you want to archive this plan?",
                text: "You won't be able to revert this!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: store.getState().colorState.color,
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes, archive it!",
            }).then((result) => {
                if (result.isConfirmed) {
                    planService
                        .archivePlan(RowData.plan_id)
                        .then((res) => {
                            Swal.fire({
                                title: "Archived!",
                                text: "Your plan has been archived.",
                                confirmButtonColor:
                                    store.getState().colorState.color,

                                icon: "success",
                            }).then(() => {
                                window.location.reload();
                            });
                        })
                        .catch((res) => {
                            Swal.fire({
                                title: "Error Occurs!",
                                text: res.message,
                                icon: "error",
                                confirmButtonColor:
                                    store.getState().colorState.color,

                                confirmButtonText: "OK",
                            });
                        });
                }
            });
        },
    };
    const deletePlanOption = {
        name: "Delete Plan",

        icon: (
            <ListItemIcon>
                <DeleteIcon fontSize="small" />
            </ListItemIcon>
        ),
        optionCallback: () => {
            Swal.fire({
                title: "Are you sure you want to delete this plan?",
                text: "You won't be able to revert this!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: store.getState().colorState.color,
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes, delete it!",
            }).then((result) => {
                if (result.isConfirmed) {
                    planService
                        .deletePlan(RowData.plan_id)
                        .then((res) => {
                            Swal.fire({
                                title: "Deleted!",
                                text: "Your Plan has been deleted.",
                                icon: "success",
                                confirmButtonColor:
                                    store.getState().colorState.color,
                            }).then(() => {
                                window.location.reload();
                            });
                        })
                        .catch((res) => {
                            Swal.fire({
                                title: "Error Occurs!",
                                text: res.message,
                                icon: "error",
                                confirmButtonColor:
                                    store.getState().colorState.color,
                                confirmButtonText: "OK",
                            });
                        });
                }
            });
        },
    };
    const cancelSubscriptionOption = {
        name: "Cancel Subscription",
        icon: (
            <ListItemIcon>
                <BlockIcon fontSize="small" />
            </ListItemIcon>
        ),
        optionCallback: () => {
            Swal.fire({
                title: "Are you sure you want to cancel this subscription?",
                text: "You won't be able to revert this!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: store.getState().colorState.color,
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes, cancel it!",
            }).then((res) => {
                var cancelledSubscription = {
                    ...RowData,
                    subs_status: "cancelled",
                };
                if (res.isConfirmed) {
                    subscriptionService
                        .editSubscription(
                            RowData.subs_id,
                            cancelledSubscription
                        )
                        .then((res) => {
                            var data_body = {
                                subs_id: RowData.subs_id,
                            };
                            subscriptionService
                                .cancelSubEmail(data_body)
                                .then((res2) => {
                                    console.log("Sent email");
                                })
                                .catch((err) => {
                                    Swal.fire({
                                        title: "Error Occurs!",
                                        text: err.message,
                                        icon: "error",
                                        confirmButtonColor:
                                            store.getState().colorState.color,
                                        confirmButtonText: "OK",
                                    });
                                });
                            fetchNewData();
                        })
                        .catch((err) => {
                            Swal.fire({
                                title: "Error Occurs!",
                                text: err.message,
                                icon: "error",
                                confirmButtonColor:
                                    store.getState().colorState.color,
                                confirmButtonText: "OK",
                            });
                        });
                }
            });
        },
    };
    const deleteSubscriptionOption = {
        name: "Delete Subscription",
        icon: (
            <ListItemIcon>
                <DeleteIcon fontSize="small" />
            </ListItemIcon>
        ),
        optionCallback: () => {
            Swal.fire({
                title: "Are you sure you want to delete this subscription?",
                text: "You won't be able to revert this!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: store.getState().colorState.color,
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes, cancel it!",
            }).then((res) => {
                var deletedSubscription = {
                    ...RowData,
                    subs_status: "deleted",
                };
                if (res.isConfirmed) {
                    subscriptionService
                        .deleteSubscription(
                            RowData.subs_id,
                            deletedSubscription
                        )
                        .then((res) => {
                            // window.location.reload();
                        })
                        .catch((err) => {
                            Swal.fire({
                                title: "Error Occurs!",
                                text: err.message,
                                icon: "error",
                                confirmButtonColor:
                                    store.getState().colorState.color,
                                confirmButtonText: "OK",
                            });
                        });
                }
            });
        },
    };

    const pauseSubscriptionOption = {
        name: "Pause Subscription",
        icon: (
            <ListItemIcon>
                <PauseIcon fontSize="small" />
            </ListItemIcon>
        ),
        optionCallback: () => {
            Swal.fire({
                title: "Are you sure you want to pause this subscription?",
                text: "You won't be able to revert this!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: store.getState().colorState.color,
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes, pause it!",
            }).then((res) => {
                var pausedSubscription = {
                    ...RowData,
                    subs_status: "paused",
                };
                if (res.isConfirmed) {
                    subscriptionService
                        .editSubscription(RowData.subs_id, pausedSubscription)
                        .then(() => {
                            Swal.fire({
                                title: "Success",
                                text: "The subscription has been modified",
                                icon: "success",
                                confirmButtonColor:
                                    store.getState().colorState.color,
                            });
                            fetchNewData();
                            // window.location.reload();
                        })
                        .catch((err) => {
                            Swal.fire({
                                title: "Error Occurs!",
                                text: err.message,
                                icon: "error",
                                confirmButtonColor:
                                    store.getState().colorState.color,
                                confirmButtonText: "OK",
                            });
                        });
                }
            });
        },
    };

    const reactivatePlanOption = {
        name: "Reactivate Plan",
        icon: (
            <ListItemIcon>
                <ArchiveIcon fontSize="small" />
            </ListItemIcon>
        ),
        optionCallback: () => {
            Swal.fire({
                title: "Are you sure you want to reactivate this plan?",
                showCancelButton: true,
                confirmButtonColor: store.getState().colorState.color,
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes, reactivate it!",
            }).then((result) => {
                if (result.isConfirmed) {
                    planService
                        .reactivatePlan(RowData.plan_id)
                        .then((res) => {
                            Swal.fire({
                                title: "Reactivated!",
                                text: "Your plan has been reactivated.",
                                icon: "success",
                                confirmButtonColor:
                                    store.getState().colorState.color,
                            }).then(() => {
                                window.location.reload();
                            });
                        })
                        .catch((res) => {
                            Swal.fire({
                                title: "Error Occurs!",
                                text: res.message,
                                icon: "error",
                                confirmButtonColor:
                                    store.getState().colorState.color,
                                confirmButtonText: "OK",
                            });
                        });
                }
            });
        },
    };

    const reactivateSubscriptionOption = {
        name: "Reactivate Subscription",
        icon: (
            <ListItemIcon>
                <PlaylistAddCheckIcon fontSize="small" />
            </ListItemIcon>
        ),
        optionCallback: () => {
            Swal.fire({
                title: "Are you sure you want to reactivate this subscription?",
                text: "You won't be able to revert this!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: store.getState().colorState.color,
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes, reactivate it!",
            }).then((res) => {
                var reactivatedSubscription = {
                    ...RowData,
                    subs_status: "active",
                };
                if (res.isConfirmed) {
                    subscriptionService
                        .reactivateSubscription(
                            RowData.subs_id,
                            reactivatedSubscription
                        )
                        .then((res) => {
                            Swal.fire({
                                title: "Success",
                                text: "The subscription has been reactivated",
                                icon: "success",
                                // showCancelButton: true,
                                confirmButtonColor:
                                    store.getState().colorState.color,
                                // cancelButtonColor: "#d33",
                                confirmButtonText: "Close",
                            });
                            fetchNewData();

                            // window.location.reload();
                        })
                        .catch((err) => {
                            Swal.fire({
                                title: "Error Occurs!",
                                text: err.message,
                                icon: "error",
                                confirmButtonColor:
                                    store.getState().colorState.color,
                                confirmButtonText: "OK",
                            });
                        });
                }
            });
        },
    };

    const deleteAddonOption = {
        name: "Delete Addon",
        icon: (
            <ListItemIcon>
                <DeleteIcon fontSize="small" />
            </ListItemIcon>
        ),
        optionCallback: () => {
            Swal.fire({
                title: "Are you sure you want to delete this addon?",
                text: "You won't be able to revert this!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: store.getState().colorState.color,
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes, delete it!",
            }).then((result) => {
                if (result.isConfirmed) {
                    addOnService
                        .deleteAddon(RowData.addon_id)
                        .then((res) => {
                            Swal.fire({
                                title: "Deleted!",
                                text: "Your Addon has been deleted.",
                                icon: "success",
                                confirmButtonColor:
                                    store.getState().colorState.color,
                            }).then(() => {
                                window.location.reload();
                            });
                        })
                        .catch((res) => {
                            Swal.fire({
                                title: "Error Occurs!",
                                text: res.message,
                                icon: "error",
                                confirmButtonText: "OK",
                            });
                        });
                }
            });
        },
    };

    const deletePromotionOption = {
        name: "Delete Promotion",
        icon: (
            <ListItemIcon>
                <DeleteIcon fontSize="small" />
            </ListItemIcon>
        ),
        optionCallback: () => {
            Swal.fire({
                title: "Are you sure you want to delete this promotion?",
                text: "You won't be able to revert this!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: store.getState().colorState.color,
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes, delete it!",
            }).then((result) => {
                if (result.isConfirmed) {
                    promotionService
                        .deletePromotion(RowData.promo_id)
                        .then((res) => {
                            Swal.fire({
                                title: "Deleted!",
                                text: "Your Promotion has been deleted.",
                                icon: "success",
                                confirmButtonColor:
                                    store.getState().colorState.color,
                            }).then(() => {
                                window.location.reload();
                            });
                        })
                        .catch((res) => {
                            Swal.fire({
                                title: "Error Occurs!",
                                text: res.message,
                                icon: "error",
                                confirmButtonText: "OK",
                                confirmButtonColor:
                                    store.getState().colorState.color,
                            });
                        });
                }
            });
        },
    };

    const reactivatePromotionOption = {
        name: "Reactivate Promotion",
        icon: (
            <ListItemIcon>
                <PlaylistAddCheckIcon fontSize="small" />
            </ListItemIcon>
        ),
        optionCallback: () => {
            Swal.fire({
                title: "Are you sure you want to reactivate this promotion?",
                text: "You won't be able to revert this!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: store.getState().colorState.color,
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes, reactivate it!",
            }).then((result) => {
                if (result.isConfirmed) {
                    promotionService
                        .reactivatePromotion(RowData.promo_id)
                        .then((res) => {
                            Swal.fire({
                                title: "Reactivated!",
                                text: "Your Addon has been reactivated.",
                                icon: "success",
                            }).then(() => {
                                window.location.reload();
                            });
                        })
                        .catch((res) => {
                            Swal.fire({
                                title: "Error Occurs!",
                                text: res.message,
                                icon: "error",
                                confirmButtonColor:
                                    store.getState().colorState.color,
                                confirmButtonText: "OK",
                            });
                        });
                }
            });
        },
    };

    const pausePromotionOption = {
        name: "Pause Promotion",
        icon: (
            <ListItemIcon>
                <PauseIcon fontSize="small" />
            </ListItemIcon>
        ),
        optionCallback: () => {
            Swal.fire({
                title: "Are you sure you want to reactivate this promotion?",
                text: "You won't be able to revert this!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: store.getState().colorState.color,
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes, reactivate it!",
            }).then((result) => {
                if (result.isConfirmed) {
                    promotionService
                        .reactivatePromotion(RowData.promo_id)
                        .then((res) => {
                            Swal.fire({
                                title: "Reactivated!",
                                text: "Your Addon has been reactivated.",
                                icon: "success",
                                confirmButtonColor:
                                    store.getState().colorState.color,
                            }).then(() => {
                                window.location.reload();
                            });
                        })
                        .catch((res) => {
                            Swal.fire({
                                title: "Error Occurs!",
                                text: res.message,
                                icon: "error",
                                confirmButtonText: "OK",
                                confirmButtonColor:
                                    store.getState().colorState.color,
                            });
                        });
                }
            });
        },
    };

    const updateUserMenuOption = {
        name: "Update User Menu",
        icon: (
            <ListItemIcon>
                <RefreshIcon fontSize="small" />
            </ListItemIcon>
        ),
        optionCallback: () => {
            Swal.fire({
                title: "Confirmation?",
                text: "Are you sure you would like to overwrite the existing customers' menu to the new default menu items?",
                showCancelButton: true,
                confirmButtonColor: store.getState().colorState.color,
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes",
            }).then((result) => {
                if (result.isConfirmed) {
                    // create update menu API, need to pass in plan id
                    // in backend, update all customers that has this plan id
                    // TEST CASE: update all customers menu that has plan id 1

                    subscriptionService
                        .updateCustomerSubscription(RowData.plan_id)
                        .then((e) => {
                            Swal.fire({
                                title: "",
                                text: "Subscriptions has been updated.",
                                icon: "success",
                                confirmButtonColor:
                                    store.getState().colorState.color,
                            });
                        })
                        .catch((error) => {
                            console.log("error u bool, ", error);
                        });
                }
            });
        },
    };
    const deletePricingModelOption = {
        name: "Delete Pricing Model",
        icon: (
            <ListItemIcon>
                <DeleteIcon fontSize="small" />
            </ListItemIcon>
        ),
        optionCallback: () => {
            Swal.fire({
                title: "Confirmation",
                text: "Are you sure you would like to delete this pricing model?",
                showCancelButton: true,
                confirmButtonColor: store.getState().colorState.color,
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes",
            }).then((result) => {
                if (result.isConfirmed) {
                    // create update menu API, need to pass in plan id
                    // in backend, update all customers that has this plan id
                    // TEST CASE: update all customers menu that has plan id 1

                    tierPricingModelService
                        .deletePropertyModel(RowData.property_model_id)
                        .then((e) => {
                            Swal.fire({
                                title: "",
                                text: "Pricing Model has been deleted.",
                                icon: "success",
                                confirmButtonColor:
                                    store.getState().colorState.color,
                            }).then(() => {
                                window.location.reload();
                            });
                        });
                }
            });
        },
    };

    const archivePricingModelOption = {
        name: "Archive Pricing Model",
        icon: (
            <ListItemIcon>
                <ArchiveIcon fontSize="small" />
            </ListItemIcon>
        ),
        optionCallback: () => {
            Swal.fire({
                title: "Confirmation",
                text: "Are you sure you would like to archive this pricing model?",
                showCancelButton: true,
                confirmButtonColor: store.getState().colorState.color,
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes",
            }).then((result) => {
                if (result.isConfirmed) {
                    // create update menu API, need to pass in plan id
                    // in backend, update all customers that has this plan id
                    // TEST CASE: update all customers menu that has plan id 1

                    tierPricingModelService
                        .archivePropertyModel(RowData.property_model_id)
                        .then((e) => {
                            Swal.fire({
                                title: "",
                                text: "Pricing Model has been archived.",
                                icon: "success",
                                confirmButtonColor:
                                    store.getState().colorState.color,
                            }).then(() => {
                                window.location.reload();
                            });
                        })
                        .catch((e) => {
                            console.log("error u bool, ", e);
                        });
                }
            });
        },
    };
    let kebabOptions = [];
    switch (Page) {
        case "plan":
            kebabOptions.push(archivePlanOption);
            kebabOptions.push(deletePlanOption);
            kebabOptions.push(updateUserMenuOption);
            kebabOptions.push(reactivatePlanOption);
            return kebabOptions;
        case "subscription":
            kebabOptions.push(cancelSubscriptionOption);
            kebabOptions.push(deleteSubscriptionOption);
            kebabOptions.push(pauseSubscriptionOption);
            kebabOptions.push(reactivateSubscriptionOption);
            return kebabOptions;
        case "addon":
            kebabOptions.push(deleteAddonOption);
            return kebabOptions;
        case "promotion":
            kebabOptions.push(deletePromotionOption);
            kebabOptions.push(pausePromotionOption);
            kebabOptions.push(reactivatePromotionOption);
            return kebabOptions;
        case "pricingModel":
            kebabOptions.push(deletePricingModelOption);
            kebabOptions.push(archivePricingModelOption);
            return kebabOptions;
        default:
            return null;
    }
};
export default ListingKebabOptions;
