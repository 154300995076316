import { Grid } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import * as React from "react";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { urlAction } from "../../action";
import { store } from "../../index.js";
import profileService from "../../services/profileService";
import promotionService from "../../services/promotionService";
import "./style.css";

const PaymentSummaryCard = ({
    plan,
    sideOrder,
    handleSubData,
    handleSubPrice,
    subsType,
}) => {
    const [promotionApplied, setPromotionApplied] = useState(
        JSON.parse(sessionStorage.getItem("promotionApplied")) === null
            ? false
            : JSON.parse(sessionStorage.getItem("promotionApplied"))
    );
    const [promoCode, setPromoCode] = useState(
        JSON.parse(sessionStorage.getItem("promoCode")) === null
            ? ""
            : JSON.parse(sessionStorage.getItem("promoCode"))
    );
    const [promoCodeInput, setPromoCodeInput] = useState(
        JSON.parse(sessionStorage.getItem("promoCodeInput")) === null
            ? ""
            : JSON.parse(sessionStorage.getItem("promoCodeInput"))
    );
    const [deliveryFee, setDeliveryFee] = useState(0);
    const [deliveryArea, setDeliveryArea] = useState();
    const isLoggedIn = useSelector(
        (state) => state.loggedInUserReducer.userData
    );
    const ketoccinoMenu =
        sessionStorage.getItem("ketoccino_coffee") !== null
            ? JSON.parse(sessionStorage.getItem("ketoccino_coffee"))[0]
            : null;
    const hostname = urlAction("get_merchant");
    useEffect(() => {
        // need to add users as well
        if (
            isLoggedIn !== undefined &&
            deliveryArea === undefined &&
            isLoggedIn?.userRole === "customer"
        ) {
            profileService
                .getPostcode(parseInt(isLoggedIn.ar_system.cust_addr_postcode))
                .then((e) => {
                    // console.log(e.postcode, "e postcode");
                    setDeliveryArea(e.postcode.area);
                    setDeliveryFee(e.postcode.price);
                    handleSubData("delivery_fee", e);
                })
                .catch((e) => {
                    return 0;
                });
        }
    }, []);
    const renderTotalSideOrderPrice = () => {
        let total = 0;
        sideOrder.map((e) => {
            if (e.checked) {
                total += e.addon_price * e.quantity;
            }
        });
        return total;
    };

    const renderPromoCodeDiscount = () => {
        if (promoCode.discount_type == "percentage") {
            return promoCode.discount_amount.toFixed(2) + "%";
        } else if (
            promoCode.discount_type == "fixed_amount" &&
            promoCode.discount_applies_on !== "delivery_fee"
        ) {
            return "RM" + promoCode.discount_amount.toFixed(2);
        }
        if (promoCode.discount_applies_on == "delivery_fee") {
            return "RM" + deliveryFee.toFixed(2);
        }
    };

    const excludeDeliveryText = () => {
        if (
            isLoggedIn === undefined &&
            !window.location.href.includes("vajrasecrets")
        ) {
            return <i>Excluding Delivery Fee**</i>;
        } else {
            return "";
        }
    };

    const renderTotal = () => {
        const planPrice = renderPlanPrice();
        var total = 0;
        const nonDefaultMenuPrice = renderTotalNonDefaultMenuPrice();
        const defaultMenuPrice = renderTotalDefaultMenuPrice();
        const sideOrderPrice = renderTotalSideOrderPrice();
        const paymentOption = plan.payment_option;
        if (
            window.location.href.includes("ketoccino") &&
            ketoccinoMenu !== null
        ) {
            const ketoccinoPrice =
                paymentOption === "recurring"
                    ? 2 * ketoccinoMenu.quantity * ketoccinoMenu.addon_price
                    : ketoccinoMenu.quantity * ketoccinoMenu.addon_price;
            total += ketoccinoPrice;
        }
        // total +=
        //     defaultMenuPrice + planPrice + nonDefaultMenuPrice + sideOrderPrice;
        if (hostname == "wildkombucha") {
            total +=
                planPrice +
                defaultMenuPrice +
                nonDefaultMenuPrice +
                sideOrderPrice;
            if (deliveryArea !== undefined) {
                // If delivery area is klang valley and more than RM50, then delivery fee is RM0
                // else if delivery area not klang valley and more than RM150, then delivery fee is RM0
                // else total add delivery fee
                if (deliveryArea == "Klang Valley") {
                    if (total >= 55) {
                        total += 0;
                    } else {
                        total += deliveryFee;
                    }
                } else if (deliveryArea != "Klang Valley") {
                    if (total >= 150) {
                        total += 0;
                    } else {
                        total += deliveryFee;
                    }
                }
            }
        } else {
            total +=
                planPrice +
                defaultMenuPrice +
                nonDefaultMenuPrice +
                sideOrderPrice;
            if (plan.is_shippable === true) {
                if (
                    window.location.href.includes("ketoccino") &&
                    ketoccinoMenu !== null
                ) {
                    if (
                        ketoccinoMenu.quantity >= 3 &&
                        paymentOption === "recurring"
                    ) {
                        total += 0;
                    } else {
                        total += deliveryFee;
                    }
                } else {
                    total += deliveryFee;
                }
            }
        }
        if (promoCode !== undefined) {
            // check where it applies to
            // check discount type
            if (promoCode.discount_applies_on == "total_price") {
                if (promoCode.discount_type == "fixed_amount") {
                    total -= promoCode.discount_amount;
                } else if (promoCode.discount_type == "percentage") {
                    total -= (planPrice * promoCode.discount_amount) / 100;
                }
            } else if (promoCode.discount_applies_on == "plan_price") {
                if (promoCode.discount_type == "fixed_amount") {
                    total -= promoCode.discount_amount;
                } else if (promoCode.discount_type == "percentage") {
                    total -=
                        planPrice +
                        (planPrice -
                            (planPrice * promoCode.discount_amount) / 100);
                }
            } else if (promoCode.discount_applies_on == "delivery_fee") {
                total -= deliveryFee;
            }
        }

        handleSubPrice(total);

        return total;
    };
    const renderKombuchaDeliveryFee = () => {
        var total = 0;
        const planPrice = renderPlanPrice();
        const nonDefaultMenuPrice = renderTotalNonDefaultMenuPrice();
        const defaultMenuPrice = renderTotalDefaultMenuPrice();
        const sideOrderPrice = renderTotalSideOrderPrice();
        total =
            defaultMenuPrice + planPrice + nonDefaultMenuPrice + sideOrderPrice;

        if (
            window.location.href.includes("ketoccino") &&
            ketoccinoMenu !== null
        ) {
            if (
                JSON.parse(sessionStorage.getItem("ketoccino_coffee"))[0]
                    .quantity >= 3 &&
                plan.payment_option === "recurring"
            ) {
                console.log("returning 0");
                return 0;
            }
        }
        if (deliveryArea == "Klang Valley" && total > 55) {
            return 0;
        } else if (deliveryArea != "Klang Valley" && total > 150) {
            return 0;
        } else {
            return deliveryFee;
        }
    };
    const renderSubtotal = () => {
        var subtotal = 0;
        var planPrice = renderPlanPrice();
        const defaultMenuPrice = renderTotalDefaultMenuPrice();
        const sideOrderPrice = renderTotalSideOrderPrice();
        const nonDefaultMenuPrice = renderTotalNonDefaultMenuPrice();
        if (
            window.location.href.includes("ketoccino") &&
            ketoccinoMenu !== null
        ) {
            const ketoccinoPrice =
                plan.payment_option === "recurring"
                    ? ketoccinoMenu.addon_price * ketoccinoMenu.quantity * 2
                    : ketoccinoMenu.addon_price * ketoccinoMenu.quantity;
            subtotal += ketoccinoPrice;
        }
        subtotal +=
            planPrice + defaultMenuPrice + nonDefaultMenuPrice + sideOrderPrice;
        return subtotal;
    };

    const renderSingleDefaultMenuPrice = (data, property, quantity) => {
        var menuPrice = 0;
        if (plan.pricing_model == "flat_fee") {
            menuPrice = 0;
        } else if (plan.pricing_model == "property") {
            const propertyArr = plan.property_model.property;
            const propertyPrice = propertyArr
                .filter((e) => e.property_name == property)
                .map((obj) => {
                    return obj.property_price;
                });
            if (quantity !== undefined) {
                menuPrice += (data.addon_price + propertyPrice[0]) * quantity;
            } else {
                menuPrice += data.addon_price + propertyPrice[0];
            }
        }
        return menuPrice;
    };
    const renderSingleSideOrder = () => {
        var sideOrderPrice = 0;
        if (plan.side_order.length > 0) {
            plan.side_order.map((data) => {
                sideOrderPrice += data.addon_price * data.quantity;
            });
        }
        return sideOrderPrice;
    };

    const renderTotalDefaultMenuPrice = () => {
        var menuPrice = 0;
        if (plan.pricing_model == "flat_fee") {
            menuPrice = 0;
        } else if (plan.pricing_model == "property") {
            const propertyArr = plan.property_model.property;

            sessionStorage.getItem("defaultMenu") !== null
                ? JSON.parse(sessionStorage.getItem("defaultMenu")).map(
                      (menu) => {
                          const propertyPrice = propertyArr
                              .filter((e) => e.property_name == menu.property)
                              .map((obj) => {
                                  return obj.property_price;
                              });
                          if (menu.quantity !== undefined) {
                              menuPrice +=
                                  (menu.addon_price + propertyPrice[0]) *
                                  menu.quantity;
                          } else {
                              menuPrice += propertyPrice[0] + menu.addon_price;
                          }
                      }
                  )
                : (menuPrice = 0);
        }
        return menuPrice;
    };

    const renderSingleNonDefaultMenuPrice = () => {
        var menuPrice = 0;
        if (plan.pricing_model == "flat_fee") {
            sessionStorage.getItem("nonDefaultMenu") !== null
                ? JSON.parse(sessionStorage.getItem("nonDefaultMenu")).map(
                      (e) => {
                          if (e.quantity !== undefined) {
                              menuPrice = e.addon_price * e.quantity;
                          } else {
                              menuPrice = e.addon_price;
                          }
                      }
                  )
                : (menuPrice = 0);
        } else if (plan.pricing_model == "property") {
            menuPrice = 0;
        }
        return menuPrice;
    };
    const renderTotalNonDefaultMenuPrice = () => {
        var menuPrice = 0;
        // if kombucha need take account their size as well
        // ::: map non default menu, get quantity and property
        //     with property, get property price then multiply by quantity
        //     get new price

        if (plan.pricing_model == "flat_fee") {
            sessionStorage.getItem("nonDefaultMenu") !== null
                ? JSON.parse(sessionStorage.getItem("nonDefaultMenu")).map(
                      (e) => {
                          if (e.quantity !== undefined) {
                              menuPrice += e.addon_price * e.quantity;
                          }
                      }
                  )
                : (menuPrice = 0);
        }

        return menuPrice;
    };

    const applyPromotion = (value) => {
        // pass promotion value to backend and verify
        // return id and pass it when subscribed to a plan
        promotionService
            .validatePromo({
                promo_code: value,
                plan_id: plan.plan_id,
                subs_type: JSON.parse(sessionStorage.getItem("paymentOption")),
            })
            .then((res) => {
                /* EXAMPLE RESPONSE
                 *
                 *  {
                 *      "promo_id": 1,
                 *      "promo_code": "sad23423",
                 *      "promo_cap": 12,
                 *      "discount_amount": 123.0,
                 *      "promo_by_percentage": false
                 *  }
                 *
                 */
                setPromoCode(res);
                setPromotionApplied(true);
                // set session storage for promo code and promotion applied
                sessionStorage.setItem("promoCode", JSON.stringify(res));
                sessionStorage.setItem(
                    "promotionApplied",

                    JSON.stringify(true)
                );
                sessionStorage.setItem(
                    "promoCodeInput",
                    JSON.stringify(promoCodeInput)
                );
                handleSubData("promo_id", res.promo_id);
                Swal.fire({
                    title: "Promotion code has been applied sucessfully",
                    text: "Promotion will be active when you make payment",
                    confirmButtonColor: store.getState().colorState.color,
                    icon: "success",
                });
            })
            .catch((res) => {
                console.log(res, "error message for promo");
                toast.error(
                    <div>
                        Promotional code entered does it exist. Please enter a
                        valid promotional code and try again.
                    </div>,
                    {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    }
                );
            });
    };

    const renderPlanPrice = () => {
        // check pricing model
        // merchant cant set any of these together in a plan,
        // having checker based on each variable

        var planPrice = 0;
        if (plan.pricing_model == "flat_fee") {
            planPrice = plan.plan_price;
        } else if (plan.pricing_model == "property") {
            planPrice = 0;
        }
        return planPrice;
    };

    return (
        <Box sx={{ maxWidth: "100%" }}>
            <Card
                style={{
                    borderRadius: "8px",
                    boxShadow: "3px 5px #DCDCDC",
                }}
                variant="outlined"
            >
                <Grid container align="left" className="text-left p-3">
                    <Grid item xs={12} md={12} lg={12}>
                        <h4>Payment Summary</h4>
                    </Grid>
                    <Grid item className="ml-3 mr-3" xs={12} md={12} lg={12}>
                        {/* based on pricing model */}

                        <div className="menu-and-price mr-3">
                            <p className="text-left">1 x {plan?.plan_name}</p>
                            {renderPlanPrice() > 0 ? (
                                <p>RM{renderPlanPrice().toFixed(2)}</p>
                            ) : (
                                "-"
                            )}
                        </div>
                        <Divider />

                        {window.location.href.includes("ketoccino") &&
                        sessionStorage.getItem("ketoccino_coffee") !== null ? (
                            <div className="menu-and-price mt-3 mr-3">
                                <p className="text-left">
                                    {plan.payment_option === "recurring"
                                        ? JSON.parse(
                                              sessionStorage.getItem(
                                                  "ketoccino_coffee"
                                              )
                                          )[0].quantity * 2
                                        : JSON.parse(
                                              sessionStorage.getItem(
                                                  "ketoccino_coffee"
                                              )
                                          )[0].quantity}{" "}
                                    x {ketoccinoMenu.addon_name}{" "}
                                </p>
                                <p className="text-right">
                                    RM
                                    {plan.payment_option === "recurring"
                                        ? (
                                              JSON.parse(
                                                  sessionStorage.getItem(
                                                      "ketoccino_coffee"
                                                  )
                                              )[0].quantity *
                                              2 *
                                              JSON.parse(
                                                  sessionStorage.getItem(
                                                      "ketoccino_coffee"
                                                  )
                                              )[0].addon_price
                                          ).toFixed(2)
                                        : (
                                              JSON.parse(
                                                  sessionStorage.getItem(
                                                      "ketoccino_coffee"
                                                  )
                                              )[0].quantity *
                                              JSON.parse(
                                                  sessionStorage.getItem(
                                                      "ketoccino_coffee"
                                                  )
                                              )[0].addon_price
                                          ).toFixed(2)}
                                </p>
                            </div>
                        ) : null}
                        {sessionStorage.getItem("defaultMenu") !== null &&
                        !window.location.href.includes("ketoccino")
                            ? JSON.parse(
                                  sessionStorage.getItem("defaultMenu")
                              ).map((e) => {
                                  return (
                                      <div className="menu-and-price mt-3 mr-3">
                                          <p className="text-left">
                                              {e.quantity} x {e.addon_name}{" "}
                                              {e.property !== undefined ? (
                                                  <>({e.property})</>
                                              ) : (
                                                  ""
                                              )}
                                          </p>
                                          <p className="text-right">
                                              {renderSingleDefaultMenuPrice(
                                                  e,
                                                  e.property,
                                                  e.quantity
                                              ).toFixed(2) > 0
                                                  ? `RM${renderSingleDefaultMenuPrice(
                                                        e,
                                                        e.property,
                                                        e.quantity
                                                    ).toFixed(2)}`
                                                  : "-"}
                                          </p>
                                      </div>
                                  );
                              })
                            : ""}
                        <Divider />
                        {sessionStorage.getItem("nonDefaultMenu") !== null
                            ? JSON.parse(
                                  sessionStorage.getItem("nonDefaultMenu")
                              ).map((e) => {
                                  return (
                                      <div className="menu-and-price mt-3 mr-3">
                                          <p className="text-left">
                                              {" "}
                                              {e.quantity} x {e.addon_name}
                                          </p>
                                          <p className="text-right">
                                              RM
                                              {renderSingleNonDefaultMenuPrice().toFixed(
                                                  2
                                              )}
                                          </p>
                                      </div>
                                  );
                              })
                            : ""}
                        <Divider />
                        {/* {console.log(sideOrder, "this is side order")} */}
                        {/* if addon data checked == true then render */}
                        {console.log(sideOrder, "this is side order")}
                        {sessionStorage.getItem("addon") !== null
                            ? sideOrder.map((order) => {
                                  if (order.checked) {
                                      return (
                                          <div className="menu-and-price mt-3 mr-3">
                                              <p className="text-left">
                                                  {" "}
                                                  {order.quantity} x{" "}
                                                  {order.addon_name}
                                              </p>
                                              <p className="text-right">
                                                  RM
                                                  {(
                                                      order.addon_price *
                                                      order.quantity
                                                  ).toFixed(2)}
                                              </p>
                                          </div>
                                      );
                                  }
                              })
                            : null}
                        {/* {sessionStorage.getItem("addon") !== null
                            ? JSON.parse(sessionStorage.getItem("addon")).map(
                                  (e) => {
                                      if (e.checked) {
                                          return (
                                              <div className="menu-and-price mt-3 mr-3">
                                                  <p className="text-left">
                                                      {" "}
                                                      {e.quantity} x{" "}
                                                      {e.addon_name}
                                                  </p>
                                                  <p className="text-right">
                                                      RM
                                                      {(
                                                          e.quantity *
                                                          e.addon_price
                                                      ).toFixed(2)}
                                                  </p>
                                              </div>
                                          );
                                      }
                                  }
                              )
                            : ""} */}
                        {/* Tax here */}
                        {/* Delivery Fee */}
                    </Grid>{" "}
                    <Grid
                        item
                        className="payment-sum-div mt-4"
                        xs={12}
                        md={12}
                        lg={12}
                    >
                        <Divider className="payment-sum-div" />
                    </Grid>
                    <Grid item className="m-2 mt-3" xs={12}>
                        <h4>Promotional Code</h4>
                    </Grid>
                    <Grid container>
                        <Grid item className="ml-2" lg={6} xs={6} md={6}>
                            <input
                                className=" p-2"
                                style={{
                                    width: "90%",
                                    border: "1px solid black",
                                    borderRadius: "5px",
                                }}
                                value={promoCodeInput}
                                onChange={(e) => {
                                    setPromoCodeInput(e.target.value);
                                }}
                                align="left"
                                type="text"
                                class="form-control"
                                placeholder=""
                            />{" "}
                        </Grid>
                        <Grid item className="" xs={6} md={6} lg={6}>
                            <Button
                                className="promo-apply-button"
                                style={{
                                    width: "100%",
                                    height: "100%",
                                    backgroundColor:
                                        store.getState().colorState.color,
                                    color: store.getState().colorState
                                        .font_color,
                                }}
                                variant="outlined"
                                onClick={() => {
                                    applyPromotion(promoCodeInput);
                                }}
                            >
                                Apply
                            </Button>
                        </Grid>
                    </Grid>
                    {promotionApplied == true ? (
                        <Grid
                            item
                            className="promo-apply-success mt-3"
                            xs={12}
                            md={12}
                            lg={12}
                        >
                            <div className="w-100 menu-and-price mt-3">
                                <p className="text-left ml-4">
                                    Promotion applied
                                </p>
                                {renderPromoCodeDiscount()}
                            </div>
                        </Grid>
                    ) : (
                        ""
                    )}
                    <Grid item className="payment-sum-div mt-3" xs={12}>
                        <Divider className="payment-sum-divider" />
                    </Grid>
                    <Grid item xs={12}>
                        <Grid item className="m-2  mt-3" xs={12}>
                            <div className="menu-and-price">
                                <p className="text-left ml-2">Subtotal</p>

                                <p className="text-right mr-4">
                                    RM{renderSubtotal().toFixed(2)}
                                </p>
                            </div>
                        </Grid>

                        {/* {plan.tax !== undefined ? (
                            <Grid item className="m-2  mt-3" xs={12}>
                                <div className="menu-and-price">
                                    <p className="text-left ml-2">
                                        Tax {plan.tax.tax_name}
                                    </p>

                                    <p className="text-right mr-4">
                                        RM{renderTax().toFixed(2)}
                                    </p>
                                </div>
                            </Grid>
                        ) : (
                            ""
                        )} */}
                        {isLoggedIn !== undefined &&
                        isLoggedIn?.userRole == "customer" &&
                        !window.location.href.includes("vajrasecrets") &&
                        plan.is_shippable === true ? (
                            <Grid item className="m-2 mt-3" xs={12}>
                                <div className="menu-and-price">
                                    <p className="text-left ml-2">
                                        Delivery Fee
                                    </p>

                                    <p className="text-right mr-4">
                                        {hostname == "wildkombucha" ||
                                        window.location.href.includes(
                                            "ketoccino"
                                        )
                                            ? `RM${renderKombuchaDeliveryFee().toFixed(
                                                  2
                                              )}`
                                            : `RM${deliveryFee.toFixed(2)}`}
                                    </p>
                                </div>
                            </Grid>
                        ) : (
                            ""
                        )}
                        {/* <Grid item className="m-2" xs={12}>
                            <div className="menu-and-price">
                                <p className="text-left ml-2">Tax (6% SST)</p>
                                <p className="text-right mr-4">RM3.15</p>
                            </div>
                        </Grid> */}
                        {promotionApplied == true ? (
                            <Grid item className="m-2 " xs={12}>
                                <div className=" menu-and-price promotion-deduction">
                                    <p className="text-left ml-2">Discount</p>
                                    <p className="text-right mr-4">
                                        {renderPromoCodeDiscount()}
                                    </p>
                                </div>
                            </Grid>
                        ) : (
                            ""
                        )}
                    </Grid>
                    <Grid item className="payment-sum-div mt-3" xs={12}>
                        <Divider className="payment-sum-divider" />
                    </Grid>
                    <Grid item xs={12}>
                        <Grid item className="m-2  mt-4" xs={12}>
                            <div className="menu-and-price">
                                <p className="text-left ml-2">
                                    Total Price
                                    <br />
                                    {plan.is_shippable === false
                                        ? ""
                                        : excludeDeliveryText()}
                                </p>
                                <p className="text-right mr-4">
                                    {" "}
                                    RM{renderTotal().toFixed(2)}
                                </p>
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
            </Card>

            {/* {addon.value.length == 0 ? null : (
                <SideOrder
                    data={
                        sessionStorage.getItem("addon") !== null
                            ? JSON.parse(sessionStorage.getItem("addon"))
                            : addon.value
                    }
                    checkerHandler={handleMenuChecker}
                    quantityHandler={quantityHandler}
                />
            )} */}
        </Box>
    );
};
export default PaymentSummaryCard;
