import {
    AccordionDetails,
    Button,
    Grid,
    Paper,
    Table,
    TableBody,
    RadioGroup,
    TableCell,
    TableContainer,
    TableHead,
    InputLabel,
    TableRow,
    TextField,
    Radio,
} from "@material-ui/core";

import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import { withStyles } from "@material-ui/core/styles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import InfoIcon from "@material-ui/icons/Info";
import MoneyOffIcon from "@material-ui/icons/MoneyOff";
import React, { Fragment, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import Swal from "sweetalert2";
import { toggleLoginRegisterModalState } from "../../action";
import { store } from "../../index.js";
import planService from "../../services/planService";
import promotionService from "../../services/promotionService";
import subscriptionService from "../../services/subscriptionService";
import userService from "../../services/userService";
import moment from "moment";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Dialog from "@material-ui/core/Dialog";
import List from "@material-ui/core/List";
import { Col, Row, Form } from "react-bootstrap";

import "bootstrap/dist/css/bootstrap.min.css";

import "./style.css";
import "react-day-picker/lib/style.css";
import DayPicker from "react-day-picker";
import { urlAction } from "../../action";

import merchantService from "../../services/merchantService";
import customerService from "../../services/customerService";
import PaymentForm from "../../Components/PaymentForm/index";
import Carousel from "../../Components/Carousel";
import profileService from "../../services/profileService";

const AccordionSummary = withStyles({
    root: {
        minHeight: 20,
        "&$expanded": {
            minHeight: 20,
        },
    },
    content: {
        "&$expanded": {
            margin: "6px 0",
        },
        margin: "6px 0",
    },
    expanded: {},
})(MuiAccordionSummary);

const CatalogDetail = ({ history, match }) => {
    const [openEdit2, setOpenEdit2] = useState(false);
    const [merchantInfo, setMerchantInfo] = useState();

    const handleClose_2 = () => {
        setOpenEdit2(false);
    };
    const [restrictedPostcode, setRestrictedPostcode] = useState([]);
    const [contactNum, setContactNum] = useState({ value: "" });
    const [altContactNum, setAltContactNum] = useState({ value: "" });
    const [email, setEmail] = useState({ value: "" });
    const [companyName, setCompanyName] = useState({ value: "" });
    const [deliveryInstructions, setDeliveryInstructions] = useState({
        value: "",
    });
    const [billIntervalChecked, setBillIntervalChecked] = useState("");
    const [addressLine1, setAddressLine1] = useState({ value: "" });
    const [addressLine2, setAddressLine2] = useState({ value: "" });
    const [postcode, setPostcode] = useState({ value: "" });
    const [district, setDistrict] = useState({ value: "" });
    const [currentPostcode, setCurrentPostcode] = useState({ value: "" });
    const [planData, setPlanData] = useState({});
    const [planDataBody, setPlanDataBody] = useState({});
    const [billFrequency, setBillFrequency] = useState([]);
    const [billFrequencyChecked, setBillFrequencyChecked] = useState({
        value: [],
    });
    const [maxMenuSelection, setMaxMenuSelection] = useState();
    const [quantitySelection, setQuantitySelection] = useState({ value: "" });
    // const [quantitySelectionInput,
    const [billFreqPicked, setBillFreqPicked] = useState(
        JSON.parse(sessionStorage.getItem("bill_freq_picked")) || ""
    );
    const [addons, setAddons] = useState([]);
    const [defaultMenu, setDefaultMenu] = useState(
        JSON.parse(sessionStorage.getItem("default_menu")) || []
    );
    const [nonDefaultMenu, setNonDefaultMenu] = useState(
        JSON.parse(sessionStorage.getItem("non_default_menu")) || []
    );
    const [specifiedPlanQuantity, setSpecifiedPlanQuantity] = useState({
        value: "1",
    });
    const [priceTableRow, setPriceTableRow] = useState({});
    const [deliveryFee, setDeliveryFee] = useState(0);
    const [promoInput, setPromoInput] = useState(
        JSON.parse(sessionStorage.getItem("promo_input")) || { value: "" }
    );
    const [promoApplied, setPromoApplied] = useState(
        JSON.parse(sessionStorage.getItem("promo_applied")) || { value: null }
    );
    const [planPrice, setPlanPrice] = useState(0);
    const [extraCharges, setExtraCharges] = useState(0);
    const [planAmount, setPlanAmount] = useState(0);
    const [paymentMethod, setPaymentMethod] = useState({ value: "fpx" });
    const [deliveryTime, setDeliveryTime] = useState(
        JSON.parse(sessionStorage.getItem("delivery_time")) || ""
    );
    const [deliveryDate, setDeliveryDate] = useState(
        JSON.parse(sessionStorage.getItem("delivery_date")) || ""
    );
    const [totalPlanPrice, setTotalPlanPrice] = useState({ value: "" });
    const [totalNonRecurringValue, setTotalNonRecurringValue] = useState(0);
    const [totalRecurringValue, setTotalRecurringValue] = useState(0);
    const [defaultMenuChecked, setDefaultMenuChecked] = useState(0);
    const [nonDefaultMenuChecked, setNonDefaultMenuChecked] = useState(
        JSON.parse(sessionStorage.getItem("non_default_menu_checked")) || {
            value: 0,
        }
    );
    const [totalPrice, setTotalPrice] = useState(0);
    const [subTotal, setSubTotal] = useState(0);
    const [allowChooseNonDefault, setAllowChooseNonDefault] = useState({
        value: "",
    });
    const [taxRate, setTaxRate] = useState({
        effective_date: "",
        expiry_date: null,
        merchant_id: "",
        tax_id: 0,
        tax_name: "",
        tax_rate: 0,
        tax_status: "",
    });
    const [exceededValue, setExceededValue] = useState(0);
    const [extraCharge, setExtraCharge] = useState(0);
    const [showForm, setShowForm] = useState(false);
    const [isRecurring, setIsRecurring] = useState(true);
    const handleCloseForm = () => setShowForm(false);
    const handleShowForm = () => setShowForm(true);
    const dispatch = useDispatch();
    //check number of ticked default menu
    const checkNonDefaultMenu = (menuName) => {
        const newValue = Object.values(nonDefaultMenu).reduce(
            (a, item) => a + (item.isChecked === true ? 1 : 0),
            0
        );
        const price = Object.values(nonDefaultMenu).reduce(
            (a, item) =>
                a + (item.isChecked === true ? item.addonData.addon_price : 0),
            0
        );
        setNonDefaultMenuChecked({
            ...nonDefaultMenuChecked,
            value: newValue,
            price: price,
            name: menuName,
        });

        return newValue;
    };

    const defaultMenuCheckedValue = () => {
        const newValue = Object.values(defaultMenu).reduce(
            (a, item) => a + (item.isChecked === true ? 1 : 0),
            0
        );
        setDefaultMenuChecked({ value: newValue });
    };
    const nonDefaultMenuCheckedValue = () => {
        const newValue = Object.values(nonDefaultMenu).reduce(
            (a, item) => a + (item.isChecked === true ? 1 : 0),
            0
        );
        setNonDefaultMenuChecked({ ...nonDefaultMenuChecked, value: newValue });
    };

    useEffect(() => {
        if (store.getState().loggedInUserReducer.userData?.ar_system?.cust_id) {
            customerService
                .getSpecificCustomerFromAR(
                    store.getState().loggedInUserReducer.userData?.ar_system
                        ?.cust_id
                )
                .then((res) => {
                    setContactNum({ value: res.cust_contact_no });
                    setAltContactNum({ value: res.cust_alternative_contact });
                    setEmail({ value: res.cust_email });
                    setCompanyName({ value: res.cust_company_name });
                    setAddressLine1({ value: res.cust_addr_line_1 });
                    setAddressLine2({ value: res.cust_addr_line_2 });
                    setPostcode({ value: res.cust_addr_postcode });
                    setDeliveryInstructions({
                        value: res.cust_delivery_instructions,
                    });
                    // setDistrict({ value: res.cust_addr_district });
                });
        }
    }, []);

    useEffect(() => {
        const splitURL = urlAction("get_merchant");
        merchantService.getMerchantInfo().then((e) => {
            let merchantInfo = e;

            let merchantId = merchantInfo
                .filter((obj) => obj.merch_name == splitURL)
                .map((obj) => {
                    return obj.id;
                });
            setMerchantInfo(merchantId);
        });
    }, []);

    useEffect(() => {
        if (!!match.params.id) fetchPlanDetailWithId(match.params.id);
        // checkDefaultMenu();
        // checkNonDefaultMenu();
        return () => {
            //clear function
            window.removeEventListener("resize", () => {});
        };
    }, []);

    useEffect(() => {
        var nonDefaultMenuPrice = nonDefaultMenuChecked.price;
        const extraCharges = calculateTotalNonRecurringValue();
        if (nonDefaultMenuPrice == undefined) {
            nonDefaultMenuPrice = 0;
        }
        var plan_price = planPrice.value;
        if (
            promoApplied?.value?.discount_amount != undefined &&
            promoApplied?.value?.discount_type != "percentage"
        ) {
            plan_price =
                plan_price - parseInt(promoApplied.value.discount_amount);
        } else if (
            promoApplied?.value?.discount_amount != undefined &&
            promoApplied?.value?.discount_type == "percentage"
        ) {
            var discountedVal =
                (plan_price * parseInt(promoApplied.value.discount_amount)) /
                100;
            if (
                discountedVal > promoApplied?.value?.promo_cap &&
                promoApplied.value.promo_cap != undefined
            ) {
                discountedVal = promoApplied?.value?.promo_cap;
            }

            plan_price = plan_price - discountedVal;
        }
        setPlanAmount(plan_price);
        var newValue =
            parseFloat(plan_price) +
            parseFloat(extraCharges) +
            parseFloat(deliveryFee);

        // setSubTotal(
        //     parseInt(plan_price) +
        //     parseInt(nonDefaultMenuPrice) +
        //     parseInt(deliveryFee)
        // );
        setTotalPrice(newValue.toFixed(2));
    });
    const Accordion = withStyles({
        root: {
            boxShadow: "none",
            "&:before": {
                display: "none",
            },
        },
        expanded: {},
    })(MuiAccordion);

    const renderAddonInfo = (addon) => {
        return (
            <div className="w-100">
                <p className="mt-3 ">{addon.addon_desc}</p>
            </div>
        );
    };

    const [validated, setValidated] = useState(false);

    const setSessionStorage = (key, data) => {
        sessionStorage.setItem(key, JSON.stringify(data));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // get our new errors
        const newErrors = findFormErrors();

        // Conditional logic:
        if (Object.keys(newErrors).length > 0) {
            // We got errors!
            setErrors(newErrors);
        } else {
            var dataBody = {
                cust_addr_line_1: form.deliveryAdd,
                cust_addr_postcode: form.postcode,
                cust_delivery_instructions: form.deliveryRemarks,
            };
            editCustomer(dataBody);
            window.location.reload();
            // No errors! Put any logic here for the form submission!
            //edit customer
            //save state here
        }
    };

    // save state with use effect :)
    useEffect(() => {
        if (billFreqPicked != "") {
            setSessionStorage("bill_freq_picked", billFreqPicked);
        }
        if (deliveryDate != "") {
            setSessionStorage("delivery_date", deliveryDate);
        }

        if (deliveryTime != "") {
            setSessionStorage("delivery_time", deliveryTime);
        }
        if (nonDefaultMenuChecked.price != null) {
            setSessionStorage(
                "non_default_menu_checked",
                nonDefaultMenuChecked
            );
        }
        if (promoApplied.value != "") {
            setSessionStorage("promo_applied", promoApplied);
            setSessionStorage("promo_input", promoInput);
        }
    }, [deliveryDate, deliveryTime, nonDefaultMenuChecked, promoApplied]);

    //Listener When SelectedPlan and selectedAddons changes
    useEffect(() => {
        var pricingForSelectedPlan = { one_off: [], recurring: [] };
        var pricingForSelectedAddons = { one_off: [], recurring: [] };
        var tax = {
            effective_date: "",
            expiry_date: null,
            merchant_id: "",
            tax_id: 0,
            tax_name: "",
            tax_rate: 0,
            tax_status: "",
        };

        //Update the pricing info
        if (!!planData) {
            var itemInfo = {
                itemName: planData.plan_name,
                pricingType: planData.pricing_model,
                itemType: planData.plan_type,
            };
            switch (planData.plan_type) {
                case "usage_based":
                    pricingForSelectedPlan = {
                        ...pricingForSelectedPlan,
                        recurring: [
                            ...pricingForSelectedPlan.recurring,
                            {
                                ...itemInfo,
                                quantity: "To Be Defined",
                                price: "-",
                            },
                        ],
                    };
                    break;
                case "fixed_quantity":
                    switch (planData.pricing_model) {
                        case "flat_fee":
                            itemInfo = {
                                ...itemInfo,
                                quantity: 1,
                                price: planData.flat_fee_amt.plan_price,
                            };
                            setPlanPrice({
                                value: itemInfo.price.toFixed(2),
                            });
                            break;
                        case "per_unit":
                            itemInfo = {
                                ...itemInfo,
                                quantity: specifiedPlanQuantity.value,
                                price:
                                    planData.per_unit_amt.plan_price *
                                    specifiedPlanQuantity.value,
                            };
                            break;
                        case "tiered":
                            var remainingQuantity = parseFloat(
                                specifiedPlanQuantity.value
                            );
                            var tieredModel = planData.tiered_model;
                            var totalPrice = 0;

                            tieredModel.tier.forEach((tier, i) => {
                                //Price for each Step
                                var priceForThisTier = 0;

                                if (tier.ending_unit != null) {
                                    var endingUnit = parseFloat(
                                        tier.ending_unit
                                    );
                                    var unitInThisTier =
                                        i == 0
                                            ? endingUnit
                                            : endingUnit -
                                              parseFloat(
                                                  tieredModel.tier[i - 1]
                                                      .ending_unit
                                              );
                                    if (remainingQuantity > unitInThisTier) {
                                        priceForThisTier =
                                            unitInThisTier *
                                            parseFloat(tier.price_per_unit);
                                        remainingQuantity -= unitInThisTier;
                                    } else {
                                        unitInThisTier = remainingQuantity;

                                        priceForThisTier =
                                            unitInThisTier *
                                            parseFloat(tier.price_per_unit);
                                        remainingQuantity -= unitInThisTier;
                                    }
                                } else {
                                    var unitInThisTier = remainingQuantity;

                                    priceForThisTier =
                                        unitInThisTier *
                                        parseFloat(tier.price_per_unit);
                                    remainingQuantity -= unitInThisTier;
                                }

                                totalPrice += priceForThisTier;
                                if (remainingQuantity == 0) {
                                    return;
                                }
                            });

                            itemInfo = {
                                ...itemInfo,
                                quantity: specifiedPlanQuantity.value,
                                price: totalPrice,
                            };
                            break;
                        case "volume":
                            var tieredModel = planData.tiered_model;
                            var quantity = parseFloat(
                                specifiedPlanQuantity.value
                            );
                            var totalPrice = 0;

                            tieredModel.tier.forEach((tier) => {
                                var startingUnit = parseFloat(
                                    tier.starting_unit
                                );

                                if (quantity >= startingUnit) {
                                    //if quantity is in this tier
                                    totalPrice =
                                        quantity *
                                        parseFloat(tier.price_per_unit); //replace the price
                                }
                            });

                            itemInfo = {
                                ...itemInfo,
                                quantity: specifiedPlanQuantity.value,
                                price: totalPrice,
                            };

                            break;
                        case "stairstep":
                            var tieredModel = planData.tiered_model;
                            var quantity = parseFloat(
                                specifiedPlanQuantity.value
                            );
                            var totalPrice = 0;

                            tieredModel.tier.forEach((tier) => {
                                //Price for each Step
                                var startingUnit = parseFloat(
                                    tier.starting_unit
                                );

                                if (quantity >= startingUnit) {
                                    totalPrice = parseFloat(
                                        tier.price_per_unit
                                    );
                                }
                            });

                            itemInfo = {
                                ...itemInfo,
                                quantity: quantity.value,
                                price: totalPrice,
                            };

                            break;
                    }

                    pricingForSelectedPlan = {
                        ...pricingForSelectedPlan,
                        recurring: [
                            ...pricingForSelectedPlan.recurring,
                            {
                                ...itemInfo,
                            },
                        ],
                    };
                    break;
                default:
            }

            if (planData.setup_fee > 0) {
                pricingForSelectedPlan = {
                    ...pricingForSelectedPlan,
                    one_off: [
                        ...pricingForSelectedPlan.one_off,
                        {
                            itemName: "Setup Fee",
                            itemType: "fixed_quantity",
                            pricingType: "flat_fee",
                            quantity: 1,
                            price: planData.setup_fee,
                        },
                    ],
                };
            }

            if (!!planData.tax) {
                tax = planData.tax;
            }
        }

        if (defaultMenu.length > 0) {
            defaultMenuCheckedValue();
            //setSubscriptionPricingInfo
            defaultMenu
                .filter((addon) => addon.isChecked)
                .forEach((selectedAddon) => {
                    const addon = selectedAddon.addonData;
                    var itemInfo = {
                        itemName: addon.addon_name,
                        pricingType: addon.pricing_model,
                        itemType: addon.addon_type,
                    };
                    switch (addon.addon_type) {
                        case "usage_based":
                            itemInfo = {
                                ...itemInfo,
                                quantity: "To Be Defined",
                                price: "-",
                            };
                            break;
                        case "fixed_quantity":
                            switch (addon.pricing_model) {
                                case "flat_fee":
                                    itemInfo = {
                                        ...itemInfo,
                                        quantity: 1,
                                        price: addon.addon_price,
                                    };
                                    break;
                                case "per_unit":
                                    itemInfo = {
                                        ...itemInfo,
                                        quantity:
                                            selectedAddon.specifiedQuantity
                                                .value,
                                        price:
                                            addon.addon_price *
                                            selectedAddon.specifiedQuantity
                                                .value,
                                    };
                                    break;
                                case "tiered":
                                    var remainingQuantity = parseFloat(
                                        selectedAddon.specifiedQuantity.value
                                    );
                                    var tieredModel = addon.tiered_model;
                                    var totalPrice = 0;

                                    tieredModel.tier.forEach((tier, i) => {
                                        //Price for each Step
                                        var priceForThisTier = 0;

                                        if (tier.ending_unit != null) {
                                            var endingUnit = parseFloat(
                                                tier.ending_unit
                                            );
                                            var unitInThisTier =
                                                i == 0
                                                    ? endingUnit
                                                    : endingUnit -
                                                      parseFloat(
                                                          tieredModel.tier[
                                                              i - 1
                                                          ].ending_unit
                                                      );
                                            if (
                                                remainingQuantity >
                                                unitInThisTier
                                            ) {
                                                priceForThisTier =
                                                    unitInThisTier *
                                                    parseFloat(
                                                        tier.price_per_unit
                                                    );
                                                remainingQuantity -=
                                                    unitInThisTier;
                                            } else {
                                                unitInThisTier =
                                                    remainingQuantity;

                                                priceForThisTier =
                                                    unitInThisTier *
                                                    parseFloat(
                                                        tier.price_per_unit
                                                    );
                                                remainingQuantity -=
                                                    unitInThisTier;
                                            }
                                        } else {
                                            var unitInThisTier =
                                                remainingQuantity;

                                            priceForThisTier =
                                                unitInThisTier *
                                                parseFloat(tier.price_per_unit);
                                            remainingQuantity -= unitInThisTier;
                                        }

                                        totalPrice += priceForThisTier;
                                        if (remainingQuantity == 0) {
                                            return;
                                        }
                                    });

                                    itemInfo = {
                                        ...itemInfo,
                                        quantity:
                                            selectedAddon.specifiedQuantity
                                                .value,
                                        price: totalPrice,
                                    };
                                    break;
                                case "volume":
                                    var tieredModel = addon.tiered_model;
                                    var addonQuantity = parseFloat(
                                        selectedAddon.specifiedQuantity.value
                                    );
                                    var totalPrice = 0;

                                    tieredModel.tier.forEach((tier) => {
                                        var startingUnit = parseFloat(
                                            tier.starting_unit
                                        );

                                        if (addonQuantity >= startingUnit) {
                                            //if quantity is in this tier
                                            totalPrice =
                                                addonQuantity *
                                                parseFloat(tier.price_per_unit); //replace the price
                                        }
                                    });

                                    itemInfo = {
                                        ...itemInfo,
                                        quantity:
                                            selectedAddon.specifiedQuantity
                                                .value,
                                        price: totalPrice,
                                    };

                                    break;
                                case "stairstep":
                                    var tieredModel = addon.tiered_model;
                                    var addonQuantity = parseFloat(
                                        selectedAddon.specifiedQuantity.value
                                    );
                                    var totalPrice = 0;

                                    tieredModel.tier.forEach((tier) => {
                                        //Price for each Step
                                        var startingUnit = parseFloat(
                                            tier.starting_unit
                                        );

                                        if (addonQuantity >= startingUnit) {
                                            totalPrice = parseFloat(
                                                tier.price_per_unit
                                            );
                                        }
                                    });

                                    itemInfo = {
                                        ...itemInfo,
                                        quantity:
                                            selectedAddon.specifiedQuantity
                                                .value,
                                        price: totalPrice,
                                    };

                                    break;
                            }
                            break;
                        default:
                    }

                    if (addon.addon_recurrence == "recurring") {
                        pricingForSelectedAddons = {
                            ...pricingForSelectedAddons,
                            recurring: [
                                ...pricingForSelectedAddons.recurring,
                                itemInfo,
                            ],
                        };
                    } else {
                        pricingForSelectedAddons = {
                            ...pricingForSelectedAddons,
                            one_off: [
                                ...pricingForSelectedAddons.one_off,
                                itemInfo,
                            ],
                        };
                    }

                    return itemInfo;
                });
        }

        if (nonDefaultMenu.length > 0) {
            nonDefaultMenuCheckedValue();

            //setSubscriptionPricingInfo
            nonDefaultMenu
                .filter((addon) => addon.isChecked)
                .forEach((selectedAddon) => {
                    const addon = selectedAddon.addonData;
                    var itemInfo = {
                        itemName: addon.addon_name,
                        pricingType: addon.pricing_model,
                        itemType: addon.addon_type,
                    };
                    switch (addon.addon_type) {
                        case "usage_based":
                            itemInfo = {
                                ...itemInfo,
                                quantity: "To Be Defined",
                                price: "-",
                            };
                            break;
                        case "fixed_quantity":
                            switch (addon.pricing_model) {
                                case "flat_fee":
                                    itemInfo = {
                                        ...itemInfo,
                                        quantity: 1,
                                        price: addon.addon_price,
                                    };
                                    break;
                                case "per_unit":
                                    itemInfo = {
                                        ...itemInfo,
                                        quantity:
                                            selectedAddon.specifiedQuantity
                                                .value,
                                        price:
                                            addon.addon_price *
                                            selectedAddon.specifiedQuantity
                                                .value,
                                    };
                                    break;
                                case "tiered":
                                    var remainingQuantity = parseFloat(
                                        selectedAddon.specifiedQuantity.value
                                    );
                                    var tieredModel = addon.tiered_model;
                                    var totalPrice = 0;

                                    tieredModel.tier.forEach((tier, i) => {
                                        //Price for each Step
                                        var priceForThisTier = 0;

                                        if (tier.ending_unit != null) {
                                            var endingUnit = parseFloat(
                                                tier.ending_unit
                                            );
                                            var unitInThisTier =
                                                i == 0
                                                    ? endingUnit
                                                    : endingUnit -
                                                      parseFloat(
                                                          tieredModel.tier[
                                                              i - 1
                                                          ].ending_unit
                                                      );
                                            if (
                                                remainingQuantity >
                                                unitInThisTier
                                            ) {
                                                priceForThisTier =
                                                    unitInThisTier *
                                                    parseFloat(
                                                        tier.price_per_unit
                                                    );
                                                remainingQuantity -=
                                                    unitInThisTier;
                                            } else {
                                                unitInThisTier =
                                                    remainingQuantity;

                                                priceForThisTier =
                                                    unitInThisTier *
                                                    parseFloat(
                                                        tier.price_per_unit
                                                    );
                                                remainingQuantity -=
                                                    unitInThisTier;
                                            }
                                        } else {
                                            var unitInThisTier =
                                                remainingQuantity;

                                            priceForThisTier =
                                                unitInThisTier *
                                                parseFloat(tier.price_per_unit);
                                            remainingQuantity -= unitInThisTier;
                                        }

                                        totalPrice += priceForThisTier;
                                        if (remainingQuantity == 0) {
                                            return;
                                        }
                                    });

                                    itemInfo = {
                                        ...itemInfo,
                                        quantity:
                                            selectedAddon.specifiedQuantity
                                                .value,
                                        price: totalPrice,
                                    };
                                    break;
                                case "volume":
                                    var tieredModel = addon.tiered_model;
                                    var addonQuantity = parseFloat(
                                        selectedAddon.specifiedQuantity.value
                                    );
                                    var totalPrice = 0;

                                    tieredModel.tier.forEach((tier) => {
                                        var startingUnit = parseFloat(
                                            tier.starting_unit
                                        );

                                        if (addonQuantity >= startingUnit) {
                                            //if quantity is in this tier
                                            totalPrice =
                                                addonQuantity *
                                                parseFloat(tier.price_per_unit); //replace the price
                                        }
                                    });

                                    itemInfo = {
                                        ...itemInfo,
                                        quantity:
                                            selectedAddon.specifiedQuantity
                                                .value,
                                        price: totalPrice,
                                    };

                                    break;
                                case "stairstep":
                                    var tieredModel = addon.tiered_model;
                                    var addonQuantity = parseFloat(
                                        selectedAddon.specifiedQuantity.value
                                    );
                                    var totalPrice = 0;

                                    tieredModel.tier.forEach((tier) => {
                                        //Price for each Step
                                        var startingUnit = parseFloat(
                                            tier.starting_unit
                                        );

                                        if (addonQuantity >= startingUnit) {
                                            totalPrice = parseFloat(
                                                tier.price_per_unit
                                            );
                                        }
                                    });

                                    itemInfo = {
                                        ...itemInfo,
                                        quantity:
                                            selectedAddon.specifiedQuantity
                                                .value,
                                        price: totalPrice,
                                    };

                                    break;
                            }
                            break;
                        default:
                    }

                    if (addon.addon_recurrence == "recurring") {
                        pricingForSelectedAddons = {
                            ...pricingForSelectedAddons,
                            recurring: [
                                ...pricingForSelectedAddons.recurring,
                                itemInfo,
                            ],
                        };
                    } else {
                        pricingForSelectedAddons = {
                            ...pricingForSelectedAddons,
                            one_off: [
                                ...pricingForSelectedAddons.one_off,
                                itemInfo,
                            ],
                        };
                    }

                    return itemInfo;
                });
        }

        const finalPricingInfo = {
            plan: pricingForSelectedPlan,
            default_menu: pricingForSelectedAddons,
            tax: tax,
        };

        setPriceTableRow(finalPricingInfo);
        if (planData.tax == undefined) {
        } else {
            setTaxRate(planData.tax);
        }
    }, [planData, defaultMenu, specifiedPlanQuantity]);

    const [deliveryDaysInfo, setDeliveryDaysInfo] = useState({});
    const [deliveryTimeInfo, setDeliveryTimeInfo] = useState({});
    const fetchPlanDetailWithId = (planId) => {
        profileService.getPostcode().then((e) => {
            setRestrictedPostcode(e["available_postcode"]);
        });
        planService
            .getSinglePlan(planId)
            .then((res) => {
                const planData = res.plans;
                setPlanData(planData);
                var tempDict = {};
                setQuantitySelection({ value: planData.quantity_selection });
                setDeliveryTimeInfo({ value: planData.delivery_window.time });
                setMaxMenuSelection(planData.max_menu_selection);
                setBillFrequency(planData.delivery_frequency);
                planData.delivery_frequency.forEach((e) => {
                    tempDict[Object.keys(tempDict).length] = [false, e];
                });
                setBillFrequencyChecked(tempDict);
                var deliveryWindowArr = [];
                Object.keys(planData.delivery_window.day).map((data, index) => {
                    if (planData.delivery_window.day[data] == true) {
                        // console.log("true");
                    } else {
                        deliveryWindowArr.push(index);
                    }
                });
                const sortedDefaultMenu = planData.default_menu.sort(function (
                    a,
                    b
                ) {
                    var keyA = a.addon_id;
                    var keyB = b.addon_id;
                    if (keyA < keyB) return -1;
                    if (keyA > keyB) return 1;
                    return 0;
                });
                const sortedNonDefaultMenu = planData.non_default_menu.sort(
                    function (a, b) {
                        var keyA = a.addon_id;
                        var keyB = b.addon_id;
                        if (keyA < keyB) return -1;
                        if (keyA > keyB) return 1;
                        return 0;
                    }
                );

                if (defaultMenu.length == 0) {
                    setDefaultMenu(
                        sortedDefaultMenu.map((addon) => {
                            var addonObj = {
                                addonData: { ...addon },
                                isChecked: true,
                                specifiedQuantity: { value: 1 },
                            };

                            return addonObj;
                        })
                    );
                }

                if (nonDefaultMenu.length == 0) {
                    setNonDefaultMenu(
                        sortedNonDefaultMenu.map((addon) => {
                            var addonObj = {
                                addonData: { ...addon },
                                isChecked: false,
                                specifiedQuantity: { value: 0 },
                            };

                            return addonObj;
                        })
                    );
                }

                // }
            })
            .catch(() => {
                history.push("/");
            });
    };
    //   const secondsToHours
    const applyPromoCode = () => {
        if (validatePromoInput(promoInput.value)) {
            promotionService
                .validatePromo({
                    merchant_id: merchantInfo[0],
                    promo_code: promoInput.value,
                    plan_id: planData.plan_id,
                })
                .then((res) => {
                    /* EXAMPLE RESPONSE
                     *
                     *  {
                     *      "promo_id": 1,
                     *      "promo_code": "sad23423",
                     *      "promo_cap": 12,
                     *      "discount_amount": 123.0,
                     *      "promo_by_percentage": false
                     *  }
                     *
                     */
                    setPromoApplied({ value: res });
                    Swal.fire(
                        "Promotion code has been applied sucessfully",
                        "Promotion will be active when you make payment",
                        "success"
                    );
                })
                .catch((res) => {
                    toast.error(<div>{res.message}</div>, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                });
        }
    };
    const renderTimeText = (delivery, frequency) => {
        return (
            <div style={{ float: "left" }}>
                <InputLabel
                    style={{ lineHeight: "1.5", textAlign: "left" }}
                    className="mt-3"
                >
                    Your first order will be delivered on: {"\n"}
                    <b style={{ color: "black" }}>
                        {" "}
                        {delivery.format("DD/MM/YYYY")}
                    </b>{" "}
                    at <b style={{ color: "black" }}>{deliveryTime.value}</b>
                </InputLabel>
                <InputLabel
                    style={{ lineHeight: "1.5", textAlign: "left" }}
                    className="mt-4"
                >
                    Your second order will be delivered on: {"\n"}
                    <b style={{ color: "black" }}>
                        {" "}
                        {frequency.format("DD/MM/YYYY")}
                    </b>{" "}
                    at <b style={{ color: "black" }}>{deliveryTime.value}</b>
                </InputLabel>
            </div>
        );
    };

    const renderDeliveryDate = () => {
        //do the cases here
        //check if all 3 inputs are put in before rendering this part of the code
        var firstDelivery = moment(deliveryDate.value);
        var dailyDelivery = moment(deliveryDate.value).add(1, "days");

        var weekDelivery = moment(deliveryDate.value).add(7, "days");
        var biweekDelivery = moment(deliveryDate.value).add(14, "days");
        var monthDelivery = moment(deliveryDate.value).add(28, "days");
        var halfyearDelivery = moment(deliveryDate.value).add(183, "days");
        var quarterDelivery = moment(deliveryDate.value).add(92, "days");
        var annumDelivery = moment(deliveryDate.value).add(365, "days");
        var biannumDelivery = moment(deliveryDate.value).add(730, "days");

        if (monthDelivery.isSame(firstDelivery, "month")) {
            monthDelivery = monthDelivery.add(7, "days");
        }
        switch (billFreqPicked) {
            case "day":
                return renderTimeText(firstDelivery, dailyDelivery);
            case "week":
                return renderTimeText(firstDelivery, weekDelivery);

            case "biweek":
                return renderTimeText(firstDelivery, biweekDelivery);

            case "month":
                return renderTimeText(firstDelivery, monthDelivery);

            case "half-year":
                return renderTimeText(firstDelivery, halfyearDelivery);

            case "quarter":
                return renderTimeText(firstDelivery, quarterDelivery);

            case "annum":
                return renderTimeText(firstDelivery, annumDelivery);

            case "biannum":
                return renderTimeText(firstDelivery, biannumDelivery);
        }
    };

    const renderEstimatedPriceForSubscription = () => {
        return (
            <Grid item xs={12} className="mt-3">
                <TableContainer component={Paper}>
                    <Table aria-label="spanning table">
                        <TableHead>
                            <TableRow>
                                <TableCell align="left" colSpan={12}>
                                    Estimated Subscription Pricing
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell colSpan={6}>Item Name</TableCell>
                                <TableCell align="right" colSpan={3}>
                                    Quantity
                                </TableCell>
                                <TableCell align="right" colSpan={3}>
                                    Price
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {priceTableRow.plan?.recurring.length > 0 ? (
                                <TableRow>
                                    <TableCell
                                        colSpan={6}
                                        style={{ verticalAlign: "top" }}
                                    >
                                        {priceTableRow.plan.recurring.map(
                                            (item, i) => (
                                                <span key={i}>
                                                    {item.itemName}
                                                </span>
                                            )
                                        )}
                                    </TableCell>
                                    <TableCell
                                        colSpan={3}
                                        align="right"
                                        style={{ verticalAlign: "top" }}
                                    >
                                        {priceTableRow.plan.recurring.map(
                                            (item, i) => (
                                                <span key={i}>
                                                    {item.quantity}
                                                </span>
                                            )
                                        )}
                                    </TableCell>
                                    <TableCell
                                        colSpan={3}
                                        align="right"
                                        style={{ verticalAlign: "top" }}
                                    >
                                        {!!promoApplied.value
                                            ? priceTableRow.plan.recurring.map(
                                                  (item, i) => (
                                                      <span
                                                          style={{
                                                              textDecorationLine:
                                                                  "line-through",
                                                          }}
                                                          key={i}
                                                      >
                                                          {"RM " +
                                                              (
                                                                  item.price /
                                                                  (1 +
                                                                      taxRate.tax_rate)
                                                              ).toFixed(2)}
                                                          <br />
                                                      </span>
                                                  )
                                              )
                                            : ""}
                                        {priceTableRow.plan.recurring.map(
                                            (item, i) => (
                                                <span key={i}>
                                                    {"RM " +
                                                        (
                                                            planAmount /
                                                            (1 +
                                                                taxRate.tax_rate)
                                                        ).toFixed(2)}
                                                </span>
                                            )
                                        )}
                                    </TableCell>
                                </TableRow>
                            ) : (
                                ""
                            )}

                            <TableRow>
                                <TableCell
                                    colSpan={6}
                                    style={{ verticalAlign: "top" }}
                                >
                                    Total Non Recurring
                                </TableCell>
                                <TableCell
                                    colSpan={3}
                                    align="right"
                                    style={{ verticalAlign: "top" }}
                                >
                                    -
                                </TableCell>
                                <TableCell
                                    colSpan={3}
                                    align="right"
                                    style={{
                                        verticalAlign: "top",
                                    }}
                                >
                                    {renderTotalNonRecurringValue()}
                                </TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell
                                    colSpan={6}
                                    style={{ verticalAlign: "top" }}
                                >
                                    Delivery Fee
                                </TableCell>
                                <TableCell
                                    colSpan={3}
                                    align="right"
                                    style={{ verticalAlign: "top" }}
                                >
                                    -
                                </TableCell>
                                <TableCell
                                    colSpan={3}
                                    align="right"
                                    style={{ verticalAlign: "top" }}
                                >
                                    {renderDeliveryFee()}
                                </TableCell>
                            </TableRow>

                            <TableRow>
                                {!!priceTableRow.tax ? (
                                    <Fragment>
                                        <TableCell
                                            rowSpan={10}
                                            colSpan={8}
                                            style={{}}
                                        ></TableCell>
                                        <TableCell
                                            className="font-weight-bold"
                                            style={{
                                                borderRadius:
                                                    "2px solid rgba(0, 0, 0, 2)",
                                            }}
                                        >
                                            Subtotal
                                        </TableCell>
                                        <TableCell
                                            colSpan={2}
                                            align="right"
                                            style={{}}
                                        >
                                            <span className="font-weight-bold">
                                                RM{" "}
                                                {(
                                                    totalPrice /
                                                    (1 +
                                                        priceTableRow.tax
                                                            .tax_rate)
                                                ).toFixed(2)}
                                            </span>
                                        </TableCell>
                                    </Fragment>
                                ) : (
                                    <Fragment>
                                        <TableCell
                                            className="font-weight-bold"
                                            style={{
                                                borderTop:
                                                    "2px solid rgba(0, 0, 0, 2)",
                                            }}
                                        >
                                            Subtotal
                                        </TableCell>
                                        <TableCell
                                            align="right"
                                            style={{
                                                borderTop:
                                                    "2px solid rgba(0, 0, 0, 2)",
                                            }}
                                        >
                                            {" "}
                                        </TableCell>
                                    </Fragment>
                                )}
                            </TableRow>

                            <TableRow>
                                {!!priceTableRow.tax ? (
                                    <Fragment>
                                        <TableCell
                                            className="font-weight-bold"
                                            style={{}}
                                        >
                                            Tax{" "}
                                            {priceTableRow.tax.tax_rate != 0
                                                ? `${
                                                      priceTableRow.tax
                                                          .tax_rate * 100
                                                  }% (${priceTableRow.tax.tax_name.toUpperCase()})`
                                                : ""}
                                        </TableCell>
                                        <TableCell
                                            colSpan={2}
                                            align="right"
                                            style={{}}
                                        >
                                            <span className="font-weight-bold">
                                                RM{" "}
                                                {(
                                                    (totalPrice /
                                                        (1 +
                                                            priceTableRow.tax
                                                                .tax_rate)) *
                                                    priceTableRow.tax.tax_rate
                                                ).toFixed(2)}
                                            </span>
                                        </TableCell>
                                    </Fragment>
                                ) : (
                                    <Fragment>
                                        <TableCell
                                            style={{
                                                borderLeft:
                                                    "2px solid rgba(0, 0, 0, 2)",
                                            }}
                                        >
                                            Tax
                                        </TableCell>
                                        <TableCell
                                            colSpan={2}
                                            align="right"
                                            style={{
                                                borderRight:
                                                    "2px solid rgba(0, 0, 0, 2)",
                                            }}
                                        >
                                            -
                                        </TableCell>
                                    </Fragment>
                                )}
                            </TableRow>

                            <TableRow>
                                <TableCell
                                    className="font-weight-bold"
                                    style={{
                                        borderTop: "2px solid rgba(0,0,0,2)",
                                    }}
                                >
                                    Total Price&nbsp;
                                </TableCell>
                                <TableCell
                                    colSpan={2}
                                    align="right"
                                    style={{
                                        borderTop: "2px solid rgba(0,0,0,2)",
                                    }}
                                >
                                    <span className="font-weight-bold">
                                        RM {totalPrice} {"\n"}
                                    </span>
                                </TableCell>
                            </TableRow>
                            {!!promoApplied.value ? (
                                <TableRow className="bg-success">
                                    <TableCell className="font-weight-bold text-white">
                                        Promotion applied
                                    </TableCell>
                                    <TableCell
                                        colSpan={2}
                                        align="right"
                                        className="bg-success"
                                    >
                                        {renderAppliedPromoInfo()}
                                    </TableCell>
                                </TableRow>
                            ) : (
                                <TableRow></TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        );
    };

    useEffect(() => {
        customerService
            .getSpecificCustomerFromAR(
                store.getState().loggedInUserReducer.userData?.ar_system
                    ?.cust_id
            )
            .then((res) => {
                setCurrentPostcode(res.cust_addr_postcode);
            });
    });
    const renderDeliveryFee = () => {
        profileService.getPostcode().then((e) => {
            Object.keys(e).map((index) => {
                var newValue = index;
                //if postcode is in e[index]
                if (
                    e[index].includes(parseInt(currentPostcode)) &&
                    newValue != "available_postcode"
                ) {
                    setDeliveryFee(newValue);
                }
            });
        });

        return (
            <span>RM {(deliveryFee / (1 + taxRate.tax_rate)).toFixed(2)}</span>
        );
    };
    const calculateTotalNonRecurringValue = () => {
        var total = 0;

        const extraChargeDefaultMenu = defaultMenu
            .filter(
                (column) =>
                    column.isChecked == true && column.addonData.quantity > 1
            )
            .map((column) => {
                return (
                    (column.addonData.quantity - 1) *
                    column.addonData.addon_price
                );
            });
        const extraChargeNonDefaultMenu = nonDefaultMenu
            .filter(
                (column) =>
                    column.isChecked == true && column.addonData.quantity > 0
            )
            .map((column) => {
                return column.addonData.quantity * column.addonData.addon_price;
            });
        const extraChargeArr = extraChargeDefaultMenu.concat(
            extraChargeNonDefaultMenu
        );
        extraChargeArr.forEach((price) => {
            total += price;
        });
        return total;
    };
    const renderTotalNonRecurringValue = () => {
        // filter default menu and find quantity more than 1
        // filter non default menun and find quantity more than 0
        const extraCharges = calculateTotalNonRecurringValue();

        return (
            <span>
                {extraCharges > 0
                    ? `RM${(
                          parseFloat(extraCharges) /
                          (1 + taxRate.tax_rate)
                      ).toFixed(2)}`
                    : "-"}
                <br />
            </span>
        );
    };

    const renderPromoDurationInfo = () => {
        switch (promoApplied.value.discount_duration_type) {
            case "forever":
                return "Promo valid throughout the subscriptions";
                break;
            case "one_time":
                return "Promo valid for the first subscription cycle only";
                break;
            case "limited":
                return `Promo valid for ${promoApplied.value.limited_period_cycles} subscription cycle once the subscription begin`;
                break;
        }
    };

    const renderAppliedPromoInfo = () => {
        return (
            <span className="text-white">
                <span>Promo code({promoApplied.value.promo_code})</span>
                <br />
                {!!promoApplied.value.discount_type == "percentage" ? (
                    <span>
                        {promoApplied.value.discount_amount}% off{" "}
                        {!!promoApplied.value.promo_cap ? (
                            <span>
                                (Capped At RM{promoApplied.value.promo_cap})
                            </span>
                        ) : (
                            ""
                        )}
                    </span>
                ) : (
                    <span>RM{promoApplied.value.discount_amount} off</span>
                )}
                <br />
                <br />
                <span style={{ fontSize: "12px" }}>
                    ** {renderPromoDurationInfo()}
                </span>
            </span>
        );
    };

    const validatePromoInput = (newValue) => {
        var isValid = true;

        //Reset the Error message
        var errorMessage = "";

        //Check empty
        if (!(newValue.length > 0)) {
            if (!!errorMessage) errorMessage += "\n";
            errorMessage += "Promo Code cannot be empty";
            isValid = false;
        }

        //Check if contain space character
        if (/\s/.test(newValue)) {
            if (!!errorMessage) errorMessage += "\n";
            errorMessage += "Promo Code cannot contain any space chracter";
            isValid = false;
        }

        //check if promo already exist in customer promo
        //if starts with ref only check customer promotion code
        //

        if (!!errorMessage)
            setPromoInput({
                ...promoInput,
                error: errorMessage,
            }); //Set Error message

        return isValid;
    };

    const createRecurringSub = (dataBody) => {
        // one-off
        //create subscription and check in database
        Swal.fire({
            title: "Are you sure you want to subscribe to this plan?",
            icon: "question",
            showCancelButton: true,
            confirmButtonColor: store.getState().colorState.color,
            cancelButtonColor: "#d33",
            confirmButtonText: "Confirm",
        }).then((result) => {
            if (result.isConfirmed) {
                subscriptionService
                    .createSubscription(dataBody)
                    .then((res) => {
                        var subsId = res["subs_id"];
                        var data_body = { subs_id: subsId };
                        subscriptionService
                            .newActiveSubForInvoice(data_body)
                            .then((response) => {
                                Swal.fire({
                                    title: "Congratulations!",
                                    text: "You have subscribed to the plan! Click on the My Subscriptions Tab to view your Subscription",
                                    icon: "success",
                                    confirmButtonText: "OK",
                                });
                                history.push("/customer/profile");
                            });
                    })
                    .catch((res) => {
                        if (
                            res.message ==
                            "The promo code entered has exceeded the maximum limit for usage."
                        ) {
                            Swal.fire(
                                "The promo code entered has exceeded the maximum limit for usage.",
                                "",
                                "info"
                            );
                            setPromoApplied({ value: null });
                            setPromoInput({
                                // value: "",
                                error: "Promo code has exceeded the maximum limit for usage.",
                            });
                        } else {
                            Swal.fire(
                                "Subscribe to plan error. Please try again later",
                                "",
                                "info"
                            );
                        }
                    });
            }
        });
    };

    const checkMandate = (dataBody) => {
        customerService
            .getSpecificCustomerFromAR(
                store.getState().loggedInUserReducer.userData.ar_system.cust_id
            )
            .then((res) => {
                // if mandate exists
                if (res["cust_mandate_ref"] !== null) {
                    // create sub with pending status
                    if (!res["cust_mandate_status"]) {
                        dataBody.subs_status = "pending";
                        createRecurringSub(dataBody);
                        // create sub with active status
                    } else if (res["cust_mandate_status"]) {
                        dataBody.subs_status = "active";
                        createRecurringSub(dataBody);
                    }
                } else {
                    setPlanDataBody(dataBody);
                    handleShowForm();
                }
            })
            .catch(() => {
                Swal.fire(
                    "Subscribe to plan error",
                    "Please try again.",
                    "error"
                );
            });
    };

    const paymentType = () => {
        //switch for case type
        //create subscription
        var dataBody = {
            customer_id:
                store.getState().loggedInUserReducer.userData?.ar_system
                    ?.cust_id,
            customer_fname:
                store.getState().loggedInUserReducer.userData?.ar_system
                    ?.cust_fname,
            customer_lname:
                store.getState().loggedInUserReducer.userData?.ar_system
                    ?.cust_lname,
            customer_email:
                store.getState().loggedInUserReducer.userData?.ar_system
                    ?.cust_email,
            customer_delivery_instructions: deliveryInstructions.value,
            customer_contact_no:
                store.getState().loggedInUserReducer.userData?.ar_system
                    ?.cust_contact_no,
            customer_postcode: postcode.value,
            default_menu: defaultMenu
                .filter((a) => a.isChecked)
                .map((addon) => {
                    return {
                        addon_id: addon.addonData.addon_id.toString(),
                        addon_name: addon.addonData.addon_name,
                        quantity: parseInt(addon.addonData.quantity),
                        addon_price: addon.addonData.addon_price,
                    };
                }),
            non_default_menu: nonDefaultMenu
                .filter((a) => a.isChecked)
                .map((addon) => {
                    return {
                        addon_id: addon.addonData.addon_id.toString(),
                        addon_name: addon.addonData.addon_name,
                        quantity: parseInt(addon.addonData.quantity),
                        addon_price: addon.addonData.addon_price,
                    };
                }),
            merchant_id: merchantInfo[0],
            delivery_frequency: billFreqPicked,
            subs_price: totalPrice,
            delivery_fee: parseFloat(deliveryFee),
            plan_id: planData.plan_id,
            plan_name: planData.plan_name,
            plan_price: parseFloat(planPrice.value),
            delivery_date: deliveryDate.value,
            delivery_time: deliveryTime.value,
            subs_status: "inactive",
            cust_mandate_ref2:
                store.getState().loggedInUserReducer.userData?.ar_system
                    ?.cust_mandate_ref,
            delivery_address: addressLine1.value + ", " + postcode.value,
            // ", " +
            // district.value,
            plan_type: planData.is_recurring,
        };
        if (defaultMenu.length > 0) {
            dataBody = {
                ...dataBody,
                plan_quantity: defaultMenu.length,
            };
        }
        // const extraCharges = calculateTotalNonRecurringValue();
        // if (extraCharges > 0) {
        //     dataBody = {
        //         ...dataBody,
        //         // extra_charges: extraCharges,
        //     };
        // }

        if (!!promoApplied.value) {
            dataBody = {
                ...dataBody,
                promo_id: promoApplied.value.promo_id,
                promo_code: promoApplied.value.promo_code,
            };
        }
        setPlanDataBody(dataBody);
        switch (planData.is_recurring) {
            case "recurring":
                setIsRecurring(true);
                checkMandate(dataBody);
                break;
            case "one_off":
                //create subscription and check in database
                setIsRecurring(false);
                handleShowForm();
                break;
            case "both_recur_oneoff":
                Swal.fire({
                    title: "Please choose your payment option for this plan.",
                    icon: "question",
                    showCancelButton: false,
                    input: "radio",
                    inputOptions: {
                        recurring: "Recurring",
                        one_off: "One-off",
                    },
                    inputValidator: function (result) {
                        if (!result) {
                            return "You need to select something!";
                        }
                    },
                    confirmButtonColor: store.getState().colorState.color,
                    confirmButtonText: "Ok",
                })
                    .then((e) => {
                        const newValue = e.value;
                        planData.is_recurring = newValue;
                        dataBody = {
                            ...dataBody,
                            plan_type: newValue,
                        };
                        setPlanDataBody(dataBody);
                        if (newValue == "recurring") {
                            setIsRecurring(true);
                            checkMandate(dataBody);
                        } else {
                            setIsRecurring(false);
                            handleShowForm();
                        }
                    })
                    .catch(() => {
                        Swal.fire(
                            "Payment selection error",
                            "Please try again.",
                            "error"
                        );
                    });
                break;
        }
    };

    const editCustomer = (dataBody) => {
        customerService
            .editCustomerFromAR(
                dataBody,
                store.getState().loggedInUserReducer.userData?.ar_system
                    ?.cust_id
            )
            .then(() => {
                window.location.reload();
            });
    };
    const validateSelection = () => {
        var isValid = true;
        var quantityBased = 0;

        //check the quantity of the checked one
        var checkBased = 0;

        // if quantity field is true!
        // else, use checked value and check against maxMenuSelection
        if (quantitySelection.value == true) {
            defaultMenu
                .filter((menu) => menu.isChecked)
                .map((checkedMenu) => {
                    return (quantityBased += parseInt(
                        checkedMenu.addonData.quantity
                    ));
                });
            nonDefaultMenu
                .filter((menu) => menu.isChecked)
                .map((checkedMenu) => {
                    return (quantityBased += parseInt(
                        checkedMenu.addonData.quantity
                    ));
                });
            if (quantityBased != maxMenuSelection) {
                Swal.fire("Please check selected menu quantity.", "", "info");
                isValid = false;
            }
        } else {
            const nonDefCheck = Object.values(nonDefaultMenu).reduce(
                (a, item) => a + (item.isChecked === true ? 1 : 0),
                0
            );
            const defCheck = Object.values(defaultMenu).reduce(
                (a, item) => a + (item.isChecked === true ? 1 : 0),
                0
            );
            checkBased = defCheck + nonDefCheck;
            setNonDefaultMenuChecked({
                ...nonDefaultMenuChecked,
                value: nonDefCheck,
            });
        }

        if (
            deliveryDate.value == "" ||
            deliveryTime.value == "" ||
            billFreqPicked == ""
        ) {
            Swal.fire(
                "Please check your menu and delivery window again.",
                "",
                "info"
            );
            isValid = false;
        }
        return isValid;
    };
    const [form, setForm] = useState({});
    const [errors, setErrors] = useState({});
    const setField = (field, value) => {
        setForm({
            ...form,
            [field]: value,
        });
        if (!!errors[field])
            setErrors({
                ...errors,
                [field]: null,
            });
    };
    const findFormErrors = () => {
        // const { name, food, rating, comment } = form
        // const newErrors = {}
        // // name errors
        // if ( !name || name === '' ) newErrors.name = 'cannot be blank!'
        // else if ( name.length > 30 ) newErrors.name = 'name is too long!'
        // // food errors
        // if ( !food || food === '' ) newErrors.food = 'select a food!'
        // // rating errors
        // if ( !rating || rating > 5 || rating < 1 ) newErrors.rating = 'must assign a rating between 1 and 5!'
        // // comment errors
        // if ( !comment || comment === '' ) newErrors.comment = 'cannot be blank!'
        // else if ( comment.length > 100 ) newErrors.comment = 'comment is too long!'
        const { deliveryAdd, postcode } = form;
        const newErrors = {};
        const postcodeRegex = "[0-9]{5}";

        if (!deliveryAdd || deliveryAdd === "")
            newErrors.deliveryAdd = "Address cannot be blank";
        if (!postcode || postcode === "")
            newErrors.postcode = "Postcode cannot be blank";
        if (postcode !== undefined) {
            if (!postcode.match(postcodeRegex))
                newErrors.postcode = "Postcode can only have 5 digits";
        }
        // if (!restrictedPostcode.includes(parseInt(postcode)))
        //     newErrors.postcode = "Postcode field is restricted";

        return newErrors;
    };

    useEffect(() => {
        customerService
            .getSpecificCustomerFromAR(
                store.getState().loggedInUserReducer.userData?.ar_system
                    ?.cust_id
            )
            .then((res) => {
                setAddressLine1({ value: res.cust_addr_line_1 });
                setPostcode({ value: res.cust_addr_postcode });
                setDeliveryInstructions({
                    value: res.cust_delivery_instructions,
                });
            });
    }, [defaultMenu]);

    const subscribeToPlan = () => {
        //check selection
        //1. check delivery date
        //2. check menu checked

        //check login
        //1. check user type
        //check delivery address
        //1. true: proceed to payment
        //check payment
        //1. one off
        //2. recurring
        //2. false: prompt delivery address input and go to payment
        //check payment
        //1. one off
        //2. recurring
        if (userService.isLoggedIn() && validateSelection()) {
            if (
                store.getState().loggedInUserReducer.userData?.userRole ==
                "merchant"
            ) {
                Swal.fire({
                    title: "Error, you cannot subscribe using an merchant account",
                    text: "Error, you cannot subscribe using an merchant account",
                    icon: "error",
                    confirmButtonText: "ok",
                });
            } else {
                //check delivery address
                customerService
                    .getSpecificCustomerFromAR(
                        store.getState().loggedInUserReducer.userData?.ar_system
                            ?.cust_id
                    )
                    .then(() => {
                        if (
                            addressLine1.value == "" ||
                            addressLine1.value == null ||
                            postcode.value == "" ||
                            postcode.value == null
                        ) {
                            setOpenEdit2(true);
                        } else {
                            paymentType();
                        }
                    });
            }
        } else if (!userService.isLoggedIn() && validateSelection()) {
            dispatch(toggleLoginRegisterModalState());
        }
    };

    const renderDeliveryTime = () => {
        // planData
        var tempDeliveryTime = [];
        if (deliveryTimeInfo.value !== undefined) {
            deliveryTimeInfo.value.map((e) => {
                const startTime = new Date(e.start_time).toLocaleString(
                    "en-US",
                    {
                        hour: "numeric",
                        minute: "numeric",
                        hour12: true,
                    }
                );
                const endTime = new Date(e.end_time).toLocaleString("en-US", {
                    hour: "numeric",
                    minute: "numeric",
                    hour12: true,
                });
                // start time to end time
                // append to tempdeliverytime
                const timeDisplay = startTime + " - " + endTime;
                tempDeliveryTime.push(
                    <FormControlLabel
                        control={<Radio name="checkedB" color="primary" />}
                        value={timeDisplay}
                        onChange={(e) => {
                            if (e.target.checked) {
                                setDeliveryTime({
                                    value: e.target.value,
                                    date: deliveryDate.value,
                                });
                            }
                        }}
                        label={timeDisplay}
                    ></FormControlLabel>
                );
            });
        }

        return (
            <>
                <div style={{ float: "left" }}>
                    <RadioGroup value={deliveryTime.value}>
                        {tempDeliveryTime}
                        {/* render time here */}
                        {renderDeliveryDate()}
                    </RadioGroup>
                </div>
            </>
        );
    };

    function linkify(inputText) {
        var replacedText, replacePattern1, replacePattern2, replacePattern3;

        if (inputText == undefined) {
            inputText = "";
        }

        //URLs starting with http://, https://, or ftp://
        replacePattern1 =
            /(\b(https?|ftp):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gim;
        replacedText = inputText.replace(
            replacePattern1,
            '<a href="$1" target="_blank">$1</a>'
        );

        //URLs starting with "www." (without // before it, or it'd re-link the ones done above).
        replacePattern2 = /(^|[^\/])(www\.[\S]+(\b|$))/gim;
        replacedText = replacedText.replace(
            replacePattern2,
            '$1<a href="http://$2" target="_blank">$2</a>'
        );

        //Change email addresses to mailto:: links.
        replacePattern3 =
            /(([a-zA-Z0-9\-\_\.])+@[a-zA-Z\_]+?(\.[a-zA-Z]{2,6})+)/gim;
        replacedText = replacedText.replace(
            replacePattern3,
            '<a href="mailto:$1">$1</a>'
        );

        return replacedText;
    }

    return (
        <div className="container-fluid catalog-center">
            <div className="row mt-3 catalog-detail-container">
                <div className="col-md-6 color-primary col-centered">
                    <div className="product-detail-container">
                        <div className="mt-3">
                            <h3 className="text-center mt-4">
                                {planData.plan_name}
                            </h3>
                            <h5 className="text-center">
                                {planData.plan_type == "fixed_quantity"
                                    ? "Fixed Quantity Plan"
                                    : "Usage Based Plan"}
                            </h5>
                            {planData.plan_image !== undefined ? (
                                <Carousel
                                    images={planData.plan_image}
                                ></Carousel>
                            ) : (
                                ""
                            )}
                            <div> </div>
                        </div>

                        {planData.trial_period > 0 ? (
                            <Accordion>
                                <AccordionSummary
                                    className="px-1 my-1"
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <span>Plan trial info</span>
                                </AccordionSummary>
                                <AccordionDetails className="px-1 my-1">
                                    <div className="product-info-wrapper plan-description-wrapper">
                                        {planData.is_shippable ? (
                                            <span>
                                                This plan contains trial period
                                                of {planData.trial_period}{" "}
                                                {planData.trial_period_unit}{" "}
                                                with {planData.free_quantity}{" "}
                                                free quantity every billing
                                                cycle.
                                            </span>
                                        ) : (
                                            <span>
                                                This plan contains trial period
                                                of {planData.trial_period}{" "}
                                                {planData.trial_period_unit}.
                                            </span>
                                        )}
                                    </div>
                                </AccordionDetails>
                            </Accordion>
                        ) : (
                            ""
                        )}
                    </div>
                </div>
                <div className="col-md-6 color-primary col-centered">
                    <div className="mt-5">
                        {/* <Accordion className="">
                            <AccordionSummary
                                className="px-1 my-1 "
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            > */}
                        <h3 className="text-left">Plan description</h3>
                        {/* </AccordionSummary> */}
                        {/* <AccordionDetails className="px-1 my-1"> */}
                        {}
                        <div
                            className="product-info-wrapper plan-description-wrapper"
                            dangerouslySetInnerHTML={{
                                __html: linkify(planData.plan_desc),
                            }}
                        ></div>
                        {/* </AccordionDetails> */}
                        {/* </Accordion> */}
                        {/* <h5 className="center ml-2 mb-2">Plan Description</h5>
                        <div className="center ml-2 ">{planData.plan_desc}</div> */}
                    </div>
                    {defaultMenu.length > 0 ? (
                        <div className="product-info-wrapper addon-wrapper mt-4">
                            <div className="mb-2">
                                <Grid container>
                                    <Grid item xs={8}>
                                        <h5
                                            className="ml-2"
                                            style={{ display: "inline" }}
                                        >
                                            Menu
                                        </h5>
                                        <h6
                                            style={{
                                                color: "grey",
                                                marginLeft: "5px",
                                                display: "inline",
                                            }}
                                        >
                                            (Choose any {maxMenuSelection})
                                        </h6>
                                    </Grid>
                                    {/* if quantity is selected here */}

                                    {planData.quantity_selection == true ? (
                                        <Grid item xs={2}>
                                            <h6
                                                style={{
                                                    textAlign: "center",
                                                }}
                                            >
                                                Quantity
                                            </h6>
                                        </Grid>
                                    ) : (
                                        <Grid item xs={2}></Grid>
                                    )}

                                    <Grid item xs={2}>
                                        <h6
                                            className="ml-2 "
                                            style={{
                                                textAlign: "center",
                                            }}
                                        >
                                            Price
                                        </h6>
                                    </Grid>
                                </Grid>

                                <span></span>
                            </div>
                            <div className="product-info-wrapper addon-wrapper mt-2 ">
                                {defaultMenu.map((addon, index) => {
                                    return (
                                        <Grid
                                            container
                                            onClick={(event) =>
                                                event.stopPropagation()
                                            }
                                            onFocus={(event) =>
                                                event.stopPropagation()
                                            }
                                        >
                                            <Grid item xs={8}>
                                                <div className="d-flex h-100 align-items-center">
                                                    <FormControlLabel
                                                        className="m-0"
                                                        aria-label="Acknowledge"
                                                        onClick={(event) => {
                                                            var newAddonData = [
                                                                ...defaultMenu,
                                                            ];
                                                            newAddonData[
                                                                index
                                                            ].isChecked =
                                                                !newAddonData[
                                                                    index
                                                                ].isChecked;
                                                            sessionStorage.setItem(
                                                                "default_menu",
                                                                JSON.stringify(
                                                                    defaultMenu
                                                                )
                                                            );
                                                            setDefaultMenu(
                                                                newAddonData
                                                            );

                                                            event.stopPropagation();
                                                        }}
                                                        onFocus={(event) =>
                                                            event.stopPropagation()
                                                        }
                                                        control={
                                                            <Checkbox
                                                                color="primary"
                                                                checked={
                                                                    addon.isChecked
                                                                }
                                                            />
                                                        }
                                                        label={
                                                            <div>
                                                                <span>
                                                                    {
                                                                        addon
                                                                            .addonData
                                                                            .addon_name
                                                                    }
                                                                    &nbsp;
                                                                </span>
                                                            </div>
                                                        }
                                                    />
                                                </div>
                                            </Grid>
                                            {planData.quantity_selection ==
                                            true ? (
                                                <Grid item xs={2}>
                                                    <TextField
                                                        className="ml-2 mt-2"
                                                        type="number"
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        value={
                                                            addon.addonData
                                                                .quantity
                                                        }
                                                        onChange={(e) => {
                                                            var newAddonData = [
                                                                ...defaultMenu,
                                                            ];
                                                            newAddonData[
                                                                index
                                                            ].addonData.quantity =
                                                                e.target.value;
                                                            setDefaultMenu(
                                                                newAddonData
                                                            );
                                                        }}
                                                        variant="outlined"
                                                        inputProps={{
                                                            style: {
                                                                textAlign:
                                                                    "center",
                                                                height: "0rem",
                                                            },
                                                            min: 0,
                                                        }}
                                                    />
                                                </Grid>
                                            ) : (
                                                <Grid item xs={2}></Grid>
                                            )}
                                            <Grid
                                                item
                                                xs={2}
                                                style={{ textAlign: "center" }}
                                            >
                                                <h6
                                                    className="ml-4 mt-3"
                                                    style={{
                                                        textAlign: "center",
                                                        alignContent: "center",
                                                    }}
                                                >
                                                    <span
                                                        className="mt-4"
                                                        style={{
                                                            color: "green",
                                                        }}
                                                    >
                                                        -
                                                    </span>
                                                </h6>
                                            </Grid>{" "}
                                            <Grid item xs={12} className="ml-3">
                                                {renderAddonInfo(
                                                    addon.addonData
                                                )}
                                            </Grid>{" "}
                                        </Grid>
                                    );
                                })}
                            </div>
                            <h5 className="ml-2 mt-2">Non-Default Menu</h5>
                            {/* <MenuCard
                                menuType={"nonDefault"}
                                menuData={nonDefaultMenu}
                                // output={nonDefaultMenuSelection}
                            /> */}
                            <div className="product-info-wrapper addon-wrapper mt-2">
                                {nonDefaultMenu.map((addon, index) => {
                                    return (
                                        <Grid
                                            container
                                            onClick={(event) =>
                                                event.stopPropagation()
                                            }
                                            onFocus={(event) =>
                                                event.stopPropagation()
                                            }
                                        >
                                            <Grid item xs={8}>
                                                <div className="d-flex h-100 align-items-center">
                                                    <FormControlLabel
                                                        className="m-0"
                                                        aria-label="Acknowledge"
                                                        onClick={(event) => {
                                                            var newAddonData = [
                                                                ...nonDefaultMenu,
                                                            ];
                                                            newAddonData[
                                                                index
                                                            ].isChecked =
                                                                !newAddonData[
                                                                    index
                                                                ].isChecked;
                                                            sessionStorage.setItem(
                                                                "non_default_menu",
                                                                JSON.stringify(
                                                                    nonDefaultMenu
                                                                )
                                                            );
                                                            setNonDefaultMenu(
                                                                newAddonData
                                                            );
                                                            const menuName =
                                                                newAddonData[
                                                                    index
                                                                ].addonData
                                                                    .addon_desc;
                                                            checkNonDefaultMenu(
                                                                menuName
                                                            );
                                                            event.stopPropagation();
                                                        }}
                                                        onFocus={(event) =>
                                                            event.stopPropagation()
                                                        }
                                                        control={
                                                            <Checkbox
                                                                color="primary"
                                                                checked={
                                                                    addon.isChecked
                                                                }
                                                            />
                                                        }
                                                        label={
                                                            <div>
                                                                <span>
                                                                    {
                                                                        addon
                                                                            .addonData
                                                                            .addon_name
                                                                    }
                                                                    &nbsp;
                                                                </span>
                                                            </div>
                                                        }
                                                    />
                                                </div>
                                            </Grid>
                                            {planData.quantity_selection ==
                                            true ? (
                                                <Grid item xs={2}>
                                                    <TextField
                                                        className="ml-2 mt-2"
                                                        type="number"
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        value={
                                                            addon.addonData
                                                                .quantity
                                                        }
                                                        onChange={(e) => {
                                                            var newAddonData = [
                                                                ...nonDefaultMenu,
                                                            ];
                                                            newAddonData[
                                                                index
                                                            ].addonData.quantity =
                                                                e.target.value;
                                                            setNonDefaultMenu(
                                                                newAddonData
                                                            );
                                                        }}
                                                        variant="outlined"
                                                        inputProps={{
                                                            style: {
                                                                textAlign:
                                                                    "center",
                                                                height: "0rem",
                                                            },
                                                            min: 0,
                                                        }}
                                                    />
                                                </Grid>
                                            ) : (
                                                <Grid item xs={2}></Grid>
                                            )}
                                            <Grid
                                                item
                                                xs={2}
                                                style={{ textAlign: "center" }}
                                            >
                                                <h6
                                                    className="ml-4 mt-3"
                                                    style={{
                                                        textAlign: "center",
                                                        alignContent: "center",
                                                    }}
                                                >
                                                    <span
                                                        className="mt-4"
                                                        style={{
                                                            color: "green",
                                                        }}
                                                    >
                                                        {" "}
                                                        {/* default menu price */}
                                                        +RM{" "}
                                                        {(
                                                            addon.addonData
                                                                .addon_price *
                                                            parseInt(
                                                                addon.addonData
                                                                    .quantity
                                                            )
                                                        ).toFixed(2)}
                                                    </span>
                                                </h6>
                                            </Grid>
                                            <Grid item xs={12} className="ml-3">
                                                {renderAddonInfo(
                                                    addon.addonData
                                                )}
                                            </Grid>{" "}
                                        </Grid>
                                    );
                                })}
                            </div>
                        </div>
                    ) : (
                        ""
                    )}
                    <div
                        className="row row-no-gutters mt-3 delivery-date-wrapper"
                        style={{}}
                    >
                        <div className="col-sm-12">
                            <h4 className="text-left mt-3 ">Delivery Date</h4>
                        </div>
                        <div className="col-sm-12">
                            <div className="mt-2 border recurring-cycle-info-wrapper">
                                <InfoIcon fontSize="small" />
                                <span className="text-left ml-2">
                                    This subscription bills according to the
                                    delivery frequency chosen and will not
                                    expire until it is cancelled.
                                </span>
                            </div>
                        </div>
                        <div className="col-sm-12 text-left mt-3">
                            <Form.Label className="text-left">
                                Please choose your delivery frequency and time:
                            </Form.Label>
                            <div>
                                <Form.Group as={Row}>
                                    <Col className="mt-3" xs={11} md={9} lg={7}>
                                        <Form.Control
                                            defaultValue={""}
                                            as="select"
                                            onChange={(e) => {
                                                const newVal = e.target.value;
                                                setBillFreqPicked(newVal);
                                                sessionStorage.setItem(
                                                    "bill_freq_picked",
                                                    JSON.stringify(
                                                        billFreqPicked
                                                    )
                                                );
                                            }}
                                            value={billFreqPicked}
                                        >
                                            <option disabled value="">
                                                Select a delivery frequency
                                            </option>
                                            {billFrequency.map(
                                                (data, index) => {
                                                    var newVal = data;
                                                    if (data == "day") {
                                                        newVal = "dai";
                                                    }
                                                    if (data == "annum") {
                                                        newVal = "annual";
                                                    }
                                                    if (data == "biannum") {
                                                        newVal = "biannual";
                                                    }
                                                    return (
                                                        <option
                                                            key={index}
                                                            value={data}
                                                        >
                                                            {/* {data.toUpperCase()}
                                                            {"ly"} */}
                                                            {newVal
                                                                .charAt(0)
                                                                .toUpperCase() +
                                                                newVal.slice(1)}
                                                            {"ly"}
                                                        </option>
                                                    );
                                                }
                                            )}
                                        </Form.Control>
                                    </Col>
                                </Form.Group>
                            </div>
                        </div>
                        <div className="col-sm-12 mt-4">
                            <h5>
                                Orders must be placed 4 days before delivery
                                date
                            </h5>
                        </div>
                        <div className="col-xs-7 col-sm-12 col-lg-7 mt-3 mb-3 calendarSection">
                            <div center style={{ backgroundColor: "white" }}>
                                <DayPicker
                                    selectedDays={new Date(deliveryDate.value)}
                                    onDayClick={(e) => {
                                        const newValue = e.getDay();
                                        setDeliveryDate({
                                            value: e,
                                            day: newValue,
                                            display: JSON.stringify(e),
                                        });
                                    }}
                                    //if initial month no more choices
                                    initialMonth={new Date()}
                                    disabledDays={[
                                        //Set a limit to the delivery range made available for the customer (e.g: can only choose within this month)

                                        // //To make sure that the mandate is processed within one week
                                        {
                                            before: new Date(
                                                Date.now() + 345600000
                                            ),
                                        },
                                        new Date(2021, 7, 27),

                                        //hardcoded for pichaets, wednesday friday requirements
                                        { daysOfWeek: deliveryDaysInfo.value },
                                    ]}
                                />
                            </div>
                        </div>
                        <div className="col-sm-5 col-sm-12 col-lg-5 mt-3 deliveryTimeSection">
                            {renderDeliveryTime()}
                        </div>
                    </div>
                </div>
            </div>
            <div className="plan-customization-container ">
                <div className="plan-summary-wrapper">
                    <div>
                        <h4 className="text-left col-centered">
                            Payment Summary
                        </h4>
                        <div
                            className="text-left "
                            style={{ width: "100%", fontSize: ".8rem" }}
                        >
                            This is an estimated pricing for this subscription.
                            Please note that it may or may not be exactly the
                            same price as usage-based item are not calculated.
                        </div>
                    </div>
                    <div className="row mt-2">
                        <div className="col-md-12">
                            {renderEstimatedPriceForSubscription()}
                        </div>
                        <div className="col-md-12 text-left">
                            <Grid container>
                                <Grid
                                    item
                                    xs={12}
                                    className="mt-3 py-4 px-3 shadow-sm"
                                    style={{ backgroundColor: "white" }}
                                >
                                    <h6 className="text-left">
                                        Apply promotion code to this
                                        subscription?{" "}
                                    </h6>
                                    <p
                                        className="text-left m-0 mb-1"
                                        style={{ fontSize: "12px" }}
                                    >
                                        **Note: Please note that promotion code
                                        is case-sensitive.
                                    </p>
                                    <Grid container>
                                        <Grid item xs={9}>
                                            <TextField
                                                fullWidth
                                                required
                                                id="promo-code-apply"
                                                name="promo-code-apply"
                                                label={
                                                    !!promoInput.value ? "" : ""
                                                }
                                                error={!!promoInput.error}
                                                helperText={
                                                    promoInput.error || ""
                                                }
                                                value={promoInput.value}
                                                onChange={(e) => {
                                                    const newValue =
                                                        e.target.value;

                                                    if (
                                                        validatePromoInput(
                                                            newValue
                                                        ) ||
                                                        newValue == ""
                                                    )
                                                        setPromoInput({
                                                            value: newValue,
                                                        });
                                                }}
                                                inputProps={{
                                                    className: "bg-white",
                                                }}
                                                InputLabelProps={{
                                                    shrink: false,
                                                }}
                                                fullWidth
                                                variant="outlined"
                                            />
                                        </Grid>
                                        <Grid item className=" pl-2" xs={3}>
                                            <Button
                                                className="h-100 bg-success"
                                                fullWidth
                                                onClick={applyPromoCode}
                                                variant="contained"
                                                style={{
                                                    color: "white",
                                                }}
                                                endIcon={
                                                    <MoneyOffIcon></MoneyOffIcon>
                                                }
                                            >
                                                Apply
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </div>
                    </div>
                </div>
                <div className="row mt-2">
                    <div className="col-md-12 ">
                        <Grid
                            container
                            className="catalog-detail-action-button"
                            xs={12}
                            row
                        >
                            {userService.isLoggedIn() ? (
                                <Grid item xs={12}>
                                    <Button
                                        variant="contained"
                                        //   className=""
                                        alignItems="center"
                                        style={{
                                            backgroundColor: "black",
                                            color: "white",
                                        }}
                                        onClick={() => {
                                            //save state
                                            subscribeToPlan();
                                        }}
                                    >
                                        Subscribe To Plan
                                    </Button>
                                </Grid>
                            ) : (
                                <Grid item xs={12}>
                                    <Button
                                        variant="contained"
                                        //   className=""
                                        alignItems="center"
                                        style={{
                                            backgroundColor: "black",
                                            color: "white",
                                        }}
                                        onClick={() => {
                                            dispatch(
                                                toggleLoginRegisterModalState()
                                            );
                                        }}
                                    >
                                        Login/Register To Subscribe
                                    </Button>
                                </Grid>
                            )}
                            {showForm && (
                                <PaymentForm
                                    history={history}
                                    showForm={showForm}
                                    handleCloseForm={handleCloseForm}
                                    closeButton={true}
                                    isRecurring={isRecurring}
                                    planDataBody={planDataBody}
                                />
                            )}
                            <Dialog
                                onClose={handleClose_2}
                                open={openEdit2}
                                maxWidth
                            >
                                <List
                                    style={{
                                        width: "450px",
                                        // height: "500px",
                                        overflow: "hidden",
                                    }}
                                >
                                    <div
                                        style={{
                                            padding: "20px",
                                        }}
                                    >
                                        <h3 className="mb-3">
                                            Delivery Information
                                        </h3>

                                        <Grid container className="" style={{}}>
                                            <div
                                                style={{
                                                    width: "100%",
                                                }}
                                            >
                                                <Form
                                                    noValidate
                                                    validated={validated}
                                                    onSubmit={handleSubmit}
                                                >
                                                    <Form.Row>
                                                        <Form.Group
                                                            as={Col}
                                                            md="12"
                                                            controlId="validationCustom03"
                                                        >
                                                            <Form.Label>
                                                                Address
                                                            </Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                onChange={(e) =>
                                                                    setField(
                                                                        "deliveryAdd",
                                                                        e.target
                                                                            .value
                                                                    )
                                                                }
                                                                isInvalid={
                                                                    !!errors.deliveryAdd
                                                                }
                                                                placeholder="No.123, Jalan123"
                                                            />
                                                            <Form.Control.Feedback type="invalid">
                                                                {
                                                                    errors.deliveryAdd
                                                                }
                                                            </Form.Control.Feedback>
                                                        </Form.Group>

                                                        <Form.Group
                                                            as={Col}
                                                            md="5"
                                                            controlId="validationCustom05"
                                                        >
                                                            <Form.Label>
                                                                Postcode
                                                            </Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                onChange={(e) =>
                                                                    setField(
                                                                        "postcode",
                                                                        e.target
                                                                            .value
                                                                    )
                                                                }
                                                                isInvalid={
                                                                    !!errors.postcode
                                                                }
                                                            />
                                                            <Form.Control.Feedback type="invalid">
                                                                {
                                                                    errors.postcode
                                                                }
                                                            </Form.Control.Feedback>
                                                        </Form.Group>

                                                        <Form.Group controlId="exampleForm.ControlTextarea1">
                                                            <Form.Label>
                                                                In the case
                                                                where our
                                                                delivery team
                                                                couldn't reach
                                                                out to you
                                                                during delivery,
                                                                we should:
                                                            </Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                onChange={(e) =>
                                                                    setField(
                                                                        "deliveryRemarks",
                                                                        e.target
                                                                            .value
                                                                    )
                                                                }
                                                                isInvalid={
                                                                    !!errors.deliveryRemarks
                                                                }
                                                                rows={3}
                                                                spacing={3}
                                                                placeholder="E.g : Call 012-3456789 upon reaching. "
                                                            />
                                                            <Form.Control.Feedback type="invalid">
                                                                {
                                                                    errors.deliveryRemarks
                                                                }
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                    </Form.Row>
                                                    <Button
                                                        className="mr-3"
                                                        style={{
                                                            backgroundColor:
                                                                "black",
                                                            color: "white",
                                                        }}
                                                        onClick={handleClose_2}
                                                    >
                                                        Cancel
                                                    </Button>
                                                    <Button
                                                        style={{
                                                            backgroundColor:
                                                                "black",
                                                            color: "white",
                                                        }}
                                                        type="submit"
                                                    >
                                                        Submit information
                                                    </Button>
                                                </Form>
                                                <Form></Form>
                                                {/* <Grid item xs={12}>
                                                    <TextField
                                                        className="mt-2"
                                                        label={"Address"}
                                                        // value={
                                                        //     addressLine1.value
                                                        // }
                                                        onChange={(e) => {
                                                            const newValue =
                                                                e.target.value;
                                                            if (
                                                                validateAddress(
                                                                    newValue
                                                                ) ||
                                                                newValue === ""
                                                            ) {
                                                                setAddressLine1(
                                                                    {
                                                                        value: newValue,
                                                                    }
                                                                );
                                                            }
                                                        }}
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        error={
                                                            !!addressLine1.error
                                                        }
                                                        helperText={
                                                            addressLine1.error
                                                        }
                                                    />
                                                </Grid> */}
                                                {/* <Grid item xs={12}>
                                                    <TextField
                                                        className="mt-3 mb-2"
                                                        label={"Postcode"}
                                                        // value={postcode.value}
                                                        onChange={(e) => {
                                                            const newValue =
                                                                e.target.value;
                                                            if (
                                                                validatePostcode(
                                                                    newValue
                                                                ) ||
                                                                newValue === ""
                                                            ) {
                                                                setPostcode({
                                                                    value: newValue,
                                                                });
                                                            }
                                                        }}
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        error={!!postcode.error}
                                                        helperText={
                                                            postcode.error
                                                        }
                                                    />
                                                </Grid> */}
                                                {/* <Grid item xs={12}>
                                                    <TextField
                                                        className="mt-3 mb-2"
                                                        label={"District"}
                                                        value={district.value}
                                                        onChange={(e) => {
                                                            const newValue =
                                                                e.target.value;
                                                            if (
                                                                validateDistrict(
                                                                    newValue
                                                                ) ||
                                                                newValue === ""
                                                            ) {
                                                                setDistrict({
                                                                    value: newValue,
                                                                });
                                                            }
                                                        }}
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        error={!!district.error}
                                                        helperText={
                                                            district.error
                                                        }
                                                    />
                                                </Grid> */}
                                                {/* <Grid item xs={12}>
                                                    <TextField
                                                        className="mt-3 mb-2"
                                                        label={
                                                            "Delivery Remarks"
                                                        }
                                                        value={
                                                            deliveryInstructions.value
                                                        }
                                                        onChange={(e) => {
                                                            const newValue =
                                                                e.target.value;
                                                            // if (
                                                            //     validateDistrict(
                                                            //         newValue
                                                            //     ) ||
                                                            //     newValue === ""
                                                            // ) {

                                                            setDeliveryInstructions(
                                                                {
                                                                    value: newValue,
                                                                }
                                                            );
                                                            // }
                                                        }}
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        // error={!!district.error}
                                                        // helperText={
                                                        //     district.error
                                                        // }
                                                    />
                                                </Grid> */}
                                            </div>
                                        </Grid>
                                        <div className="mt-3">
                                            {/* <Button
                                                style={{
                                                    backgroundColor: "black",
                                                    color: "white",
                                                }}
                                                onClick={
                                                    () => {
                                                        var dataBody = {
                                                            cust_addr_line_1:
                                                                addressLine1.value,
                                                            cust_addr_postcode:
                                                                postcode.value,
                                                            cust_delivery_instructions:
                                                                deliveryInstructions.value,
                                                            // cust_addr_district:
                                                            //     district.value,
                                                        };
                                                        if (
                                                            validateDeliveryAddress()
                                                        ) {
                                                            setOpenEdit2(false);

                                                            editCustomer(
                                                                dataBody
                                                            );
                                                            //save state here
                                                            window.location.reload();
                                                        }
                                                        //proceed to payment
                                                    }
                                                    // (setOpenEdit(
                                                    //     false
                                                    // ),

                                                    // editAddressinServer
                                                }
                                            >
                                                Confirm
                                            </Button> */}
                                        </div>
                                    </div>
                                </List>
                            </Dialog>
                        </Grid>
                    </div>
                </div>
            </div>
            <ToastContainer />
        </div>
    );
};

export default CatalogDetail;
