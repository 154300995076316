import React, { useEffect, useState, Fragment } from "react";
import {
    Grid,
    Button,
    TextField,
    Tooltip,
    InputAdornment,
    OutlinedInput,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { ToastContainer, toast } from "react-toastify";
import userService from "../../services/userService";
import { store } from "../../index.js";
import ErrorIcon from "@material-ui/icons/Error";
import SubscriptionView from "../Admin/Subscription/SubscriptionView";
import Homepage from "../Homepage/homepage";
import Subscription from "./Subscription";
import Invoice from "./Invoice";
import Settings from "./Settings";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useParams,
    useRouteMatch,
} from "react-router-dom";
import Profile from "./Profile";
import CustomerSubscriptionView from "./CustomerSubscriptionView";

const Customer = ({ history }) => {
    // The `path` lets us build <Route> paths that are
    // relative to the parent route, while the `url` lets
    // us build relative links.
    let { path, url } = useRouteMatch();

    const hasCustomerRight = () => {
        return (
            userService.isLoggedIn &&
            store.getState().loggedInUserReducer.userData?.userRole ==
                "customer"
        );
    };

    if (!hasCustomerRight()) {
        history.replace("/not-found");
        toast.error(
            <div>
                <ErrorIcon /> Not Authorized
            </div>,
            {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            }
        );
    }

    return (
        <Switch>
            <Route path="/" exact component={Homepage} />

            <Route path={`${path}/profile`} exact component={Profile} />
            <Route
                path={`${path}/subscription`}
                exact
                component={Subscription}
            />
            <Route
                path={`${path}/subscription/:id`}
                component={SubscriptionView}
            />

            <Route path={`${path}/invoice`} exact component={Invoice} />

            <Route path={`${path}/settings/`} component={Settings} />
        </Switch>
    );
};

export default Customer;
