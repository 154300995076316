import { Button } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import AddIcon from "@mui/icons-material/Add";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import FilterListIcon from "@mui/icons-material/FilterList";
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import Cropper from "react-easy-crop";

import withReactContent from "sweetalert2-react-content";
import LoginBar from "../../../../Components/LoginBar/loginbar";
import MerchantTable from "../../../../Components/MerchantTable/";
import Sidebar from "../../../../Components/Sidebar/sidebar";
import taxService from "../../../../services/taxService";
import "./style.css";

const MySwal = withReactContent(Swal);

const TaxListing = ({ history }) => {
    useEffect(() => {
        getTaxes();
    }, []);
    const taxHeaders = {
        promo_code: { value: "Promotion name", checked: true },
        promo_status: { value: "Promotion status", checked: true },
        discount_amount: { value: "Discount amount", checked: true },
        created_at: { value: "Created At", checked: true },
        action: { value: "Action", checked: true },
    };
    const getTaxes = () => {
        taxService
            .getAllTaxWithoutPagination()
            .then((res) => {
                setTaxData(res.taxes);
            })
            .catch((e) => {});
    };
    const [taxData, setTaxData] = useState([]);
    const [rowsPerPage, setRowsPerPage] = useState();
    const [searchInput, setSearchInput] = useState("");
    const navigateToNewPlanPage = () => {
        history.push("/admin/plan/new");
    };

    const navigateToEditPlanPage = (planId) => {
        history.push(`/admin/plan/${planId}`);
    };

    return (
        <Grid container>
            <Grid item xs={2} lg={2}>
                {/* left side navbar here */}
                {/* <Sidebar /> */}
            </Grid>
            <Grid item className="p-3" xs={10} lg={10} md={10}>
                {/* profile icon */}
                <Grid item xs={12} md={12} lg={12}>
                    <Grid container className="mt-4">
                        {/* profile icon and username dropdown */}
                        <Grid align="left" item xs={6} md={6} lg={6}>
                            <h2
                                className="text-left color-contrast-dark"
                                style={{ color: "black" }}
                            >
                                Tax Management
                            </h2>
                        </Grid>
                        <Grid item xs={6} md={6} lg={6} align="right">
                            {" "}
                            <LoginBar />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item className="mt-1" xs={12} lg={12}>
                    {/* create 2 button, create new plan and export button */}
                    <Grid container>
                        <Grid item align="left" xs={3} md={3} lg={3}>
                            <Button
                                variant="contained"
                                color="primary"
                                className="mt-2 plan-option-button"
                                endIcon={<AddIcon></AddIcon>}
                                // style={{ minHeight: "44.8px" }}
                                onClick={navigateToNewPlanPage}
                            >
                                Create New Tax
                            </Button>
                        </Grid>

                        {/* <Grid item align="left" xs={2} md={2} lg={2}>
                            <Button
                                style={{ minHeight: "44.8px" }}
                                className="mt-2 ml-3 plan-option-button"
                                variant="contained"
                                // onClick={navigateToNewPlanPage}
                                endIcon={<FilterListIcon></FilterListIcon>}
                            >
                                Filter
                            </Button>
                        </Grid> */}
                    </Grid>
                </Grid>
                {/* catalog page */}
                <MerchantTable
                    headers={taxHeaders}
                    tableData={taxData}
                    updateTableData={() => {
                        return;
                    }}
                />
            </Grid>
        </Grid>
    );
};

export default TaxListing;
