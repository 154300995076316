import { Paper } from "@material-ui/core";
import * as React from "react";
import Carousel from "react-material-ui-carousel";
import "./style.css";
const ImageCarousel = ({ history, match, data }) => {
    function Item(props) {
        const imageUrl =
            window.location.protocol +
            "//" +
            window.location.hostname +
            process.env.REACT_APP_SITE_PORT_BACKEND +
            props.item.image_name;
        return (
            <Paper className="plan-image-carousel-container">
                <img
                    alt="Plan"
                    className="plan-image-carousel"
                    src={imageUrl}
                ></img>
            </Paper>
        );
    }
    return (
        <Carousel>
            {data !== undefined ?data.map((item, i) => (
                <Item key={i} item={item} />
            )): null}
        </Carousel>
    );
};
export default ImageCarousel;
