import { Checkbox, FormControlLabel, FormGroup } from "@material-ui/core";
import { Button, Grid } from "@mui/material";
import moment from "moment";
import { useEffect, useState } from "react";
import { ButtonGroup, Form, InputGroup, Modal } from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";
import { useDispatch } from "react-redux";
import Swal from "sweetalert2";
import { urlAction } from "../../action";
import { toggleLoginState } from "../../action.js";
import { store } from "../../index.js";
import customerService from "../../services/customerService";
import merchantService from "../../services/merchantService";
import planService from "../../services/planService";
import profileService from "../../services/profileService";
import userService from "../../services/userService";
import "./style.css";
const LoginModal = ({ show, handleClose }) => {
    const forgotPasswordLink =
        String(window.location.origin).split("/")[0] +
        "/backend/api/password_reset/";
    const [merchant, setMerchant] = useState({});
    const [tabState, setTabState] = useState("sign in");
    const [vajraCountry, setVajraCountry] = useState();
    const [vajraLanguage, setVajraLanguage] = useState();
    const [vajraKecharaMembership, setVajraKecharaMembership] = useState();
    const [signInUsername, setSignInUsername] = useState({
        value: "",
        valid: true,
    });
    const [signInPassword, setSignInPassword] = useState({
        value: "",
        valid: true,
    });
    const [registerNRIC, setRegisterNRIC] = useState({
        value: "",
        valid: true,
    });

    const [registerEmail, setRegisterEmail] = useState({
        value: "",
        valid: true,
    });
    const [registerPassword, setRegisterPassword] = useState({
        value: "",
        valid: true,
    });
    const [registerCPassword, setRegisterCPassword] = useState({
        value: "",
        valid: true,
    });
    const [registerFName, setRegisterFName] = useState({
        value: "",
        valid: true,
    });
    const [registerLName, setRegisterLName] = useState({
        value: "",
        valid: true,
    });
    const [registerBday, setRegisterBday] = useState({
        value: "",
        valid: true,
    });
    const [registerPhoneNum, setRegisterPhoneNum] = useState({
        value: "",
        valid: true,
    });
    const [registerGender, setRegisterGender] = useState({
        value: "",
        valid: true,
    });
    const [registerFestival, setRegisterFestival] = useState({
        value: "",
        valid: true,
    });
    const [registerDeliveryAddr, setRegisterDeliveryAddr] = useState({
        value: "",
        valid: true,
    });
    const [registerPostcode, setRegisterPostcode] = useState({
        value: "",
        valid: true,
    });
    const [registerDistrict, setRegisterDistrict] = useState({
        value: "",
        valid: true,
    });
    const [registerConsent, setRegisterConsent] = useState({ value: true });
    const [registerDiscovery, setRegisterDiscovery] = useState({ value: "" });
    const [registerDeliveryInstruction, setRegisterDeliveryInstruction] =
        useState({ value: "" });
    const [deliverableSection, setDeliverableSection] = useState(false);
    const dispatch = useDispatch();

    const [loadingBar, setLoadingBar] = useState(false);
    const [countryData, setCountryData] = useState({});
    useEffect(() => {
        fetch("https://restcountries.com/v3.1/all")
            .then((res) => {
                res.json().then((data) => {
                    const sortCountryName = data.sort((a, b) =>
                        a.name.official > b.name.official ? 1 : -1
                    );
                    const countryInfo = sortCountryName.map((country) => {
                        return [country.name.official, country.flags.png];
                    });
                    setCountryData({ value: countryInfo });
                });
            })
            .catch((data, status) => {
                console.log("Request failed:", data, status);
            });
    }, []);

    useEffect(() => {
        const splitURL = urlAction("get_merchant");
        merchantService.getMerchantInfo().then((e) => {
            let merchantInfo = e;
            let merchantName = merchantInfo
                .filter((obj) => obj.merch_name === splitURL)
                .map((obj) => {
                    return obj.merch_name;
                });
            setMerchant(merchantName);
        });
        planService.getAllPlansWithoutPagination(["active"]).then((e) => {
            let allPlan = e.plans;
            // filter through allPlan's is_shippable field, if there is a true return true, if no true, return false
            let isShippable = allPlan.filter(
                (obj) => obj.is_shippable === true
            );
            if (isShippable.length > 0) {
                setDeliverableSection(true);
            }
        });
        planService.getAllPlansWithoutPagination(["active"]).then((e) => {
            let allPlan = e.plans;
            // filter through allPlan's is_shippable field, if there is a true return true, if no true, return false
            let isShippable = allPlan.filter(
                (obj) => obj.is_shippable === true
            );
            if (isShippable.length > 0) {
                setDeliverableSection(true);
            }
        });
    }, []);
    const toggleLoggedIn = () => {
        dispatch(toggleLoginState());
    };
    const login = async () => {
        setLoadingBar(true);

        if (validateLoginForm()) {
            await userService
                .login(signInUsername.value, signInPassword.value)
                .then((userData) => {
                    // if window location href has plan in it
                    // if (window.location.href.indexOf("plan") > -1) {
                    //     sessionStorage.setItem("stepper", 3);
                    // }

                    window.location.reload();
                    setLoadingBar(false);
                })
                .catch((res) => {
                    setLoadingBar(false);

                    Swal.fire({
                        icon: "error",
                        confirmButtonColor: store.getState().colorState.color,
                        title: "Username or password is wrong.",
                        text: "Username or password is wrong. Please try again later.",
                    });
                });
        }
    };

    const validateLoginForm = () => {
        var isValid = true;

        isValid = isValid && validateUsernameLogin(signInUsername.value);
        isValid = isValid && validatePasswordLogin(signInPassword.value);

        return isValid;
    };

    const validateUsernameLogin = (newValue) => {
        var isValid = true;

        //Reset the Error message
        var errorMessage = "";

        //Check empty
        if (newValue === "") {
            if (!!errorMessage) errorMessage += "\n";
            errorMessage += "Username cannot be empty";
            isValid = false;
        }

        setSignInUsername({ ...signInUsername, error: errorMessage }); //Set Error message

        return isValid;
    };

    const validatePasswordLogin = (newValue) => {
        var isValid = true;

        //Reset the Error message
        var errorMessage = "";

        //Check empty
        if (newValue === "") {
            if (!!errorMessage) errorMessage += "\n";
            errorMessage += "Password cannot be empty";
            isValid = false;
        }

        setSignInPassword({ ...signInPassword, error: errorMessage }); //Set Error message

        return isValid;
    };
    const register = () => {
        var dataBody = {
            username: registerEmail.value,
            password: registerPassword.value,
            cust_fname: registerFName.value,
            cust_lname: registerLName.value,
            cust_contact_no: registerPhoneNum.value,
            cust_email: registerEmail.value,
        };
        // not required
        if (registerDeliveryAddr.value !== undefined) {
            dataBody = {
                ...dataBody,
                cust_addr_line_1: registerDeliveryAddr.value,
            };
        }
        if (registerPostcode.value !== undefined) {
            dataBody = {
                ...dataBody,
                cust_addr_postcode: registerPostcode.value,
            };
        }
        if (registerDeliveryInstruction.value !== undefined) {
            dataBody = {
                ...dataBody,
                cust_delivery_instructions: registerDeliveryInstruction.value,
            };
        }
        if (registerBday.value !== undefined) {
            dataBody = {
                ...dataBody,
                cust_birthday: new moment(registerBday.value).toISOString(),
            };
        }
        if (registerConsent.value !== undefined) {
            dataBody = {
                ...dataBody,
                cust_consent: registerConsent.value,
            };
        }
        if (registerGender.value !== undefined) {
            dataBody = {
                ...dataBody,
                cust_gender: registerGender.value,
            };
        }
        if (registerFestival.value !== undefined) {
            dataBody = {
                ...dataBody,
                cust_festival: registerFestival.value,
            };
        }
        if (registerDiscovery.value !== undefined) {
            dataBody = {
                ...dataBody,
                cust_discovery: registerDiscovery.value,
            };
        }
        if (window.location.href.includes("vajrasecrets")) {
            dataBody = {
                ...dataBody,
                vajra_kechara_membership: vajraKecharaMembership,
                vajra_language: vajraLanguage,
                vajra_country: vajraCountry.value,
            };
        }
        if (registerPostcode.value === "" || registerPostcode.value === null) {
            // console.log(validateForm());
            if (validateForm()) {
                customerService
                    .createCustomer(dataBody)
                    .then((res) => {
                        userService
                            .login(dataBody.username, dataBody.password)
                            .then((userData) => {
                                Swal.fire({
                                    icon: "success",
                                    confirmButtonColor:
                                        store.getState().colorState.color,
                                    title: "Successfully created account",
                                    timer: 3000,
                                }).then((result) => {
                                    toggleLoggedIn();
                                    handleClose();
                                    if (
                                        window.location.href.indexOf("plan") >
                                        -1
                                    ) {
                                        sessionStorage.setItem("stepper", 3);
                                    }

                                    window.location.reload();
                                });
                            })
                            .catch((res) => {
                                Swal.fire({
                                    icon: "error",
                                    title: "Login Error",
                                    text: "Please try again later.",
                                    confirmButtonColor:
                                        store.getState().colorState.color,
                                }).then((result) => {});
                            });
                    })
                    .catch((res) => {
			console.log(res,'this is the first one')
                        Swal.fire({
                            icon: "error",
                            title: "Account creation error",
                            confirmButtonColor:
                                store.getState().colorState.color,
                            text: "A user with that username already exists, please try with a different email, if the problem still persists, please do not hesitate to contact us.",
                        }).then((result) => {
                            if (result.isConfirmed) {
                            }
                        });
                    });
            }
        } else {
            profileService
                .getPostcode(registerPostcode.value)
                .then((e) => {
                    if (validateForm()) {
                        customerService
                            .createCustomer(dataBody)
                            .then((res) => {
                                userService
                                    .login(dataBody.username, dataBody.password)
                                    .then((userData) => {
                                        Swal.fire({
                                            icon: "success",
                                            title: "Successfully created account",
                                            timer: 3000,
                                            confirmButtonColor:
                                                store.getState().colorState
                                                    .color,
                                        }).then((result) => {
                                            toggleLoggedIn();
                                            handleClose();
                                            if (
                                                window.location.href.indexOf(
                                                    "plan"
                                                ) > -1
                                            ) {
                                                sessionStorage.setItem(
                                                    "stepper",
                                                    3
                                                );
                                            }
                                            window.location.reload();
                                        });
                                    })
                                    .catch((res) => {
                                        Swal.fire({
                                            icon: "error",
                                            title: "Login Error",
                                            confirmButtonColor:
                                                store.getState().colorState
                                                    .color,
                                            text: "Please try again later.",
                                        }).then((result) => {});
                                    });
                            })
                            .catch((res) => {
				console.log(res,'this is the second one')
                                Swal.fire({
                                    icon: "error",
                                    title: "Account creation error",
                                    confirmButtonColor:
                                        store.getState().colorState.color,
                                    text: "Please check input and try again later or contact us if the problem persists.",
                                }).then((result) => {
                                    if (result.isConfirmed) {
                                    }
                                });
                            });
                    }
                    // set user data here
                })
                .catch((e) => {
                    var errorMessage =
                        "Sorry, we do not deliver to this postcode";
                    var isValid = false;
                    setRegisterPostcode({
                        ...registerPostcode,
                        error: errorMessage,
                        valid: isValid,
                    });
                });
        }
    };

    const validateForm = () => {
        var isValid = true;

        isValid = validateRegisterEmail(registerEmail.value) && isValid;
        isValid = validateRegisterPassword(registerPassword.value) && isValid;
        isValid = validateRegisterCPassword(registerCPassword.value) && isValid;
        isValid = validateRegisterFName(registerFName.value) && isValid;
        isValid = validateRegisterLName(registerLName.value) && isValid;
        isValid = validateRegisterPhoneNum(registerPhoneNum.value) && isValid;
        if (registerDeliveryAddr.value !== "") {
            isValid =
                validateRegisterDeliveryAddr(registerDeliveryAddr.value) &&
                isValid;
        }

        if (registerBday.value !== "") {
            isValid = validateRegisterBday(registerBday.value) && isValid;
        }

        if (registerNRIC.value === "" && merchant === "farcapital") {
            isValid = validateRegisterNRIC(registerNRIC.value) && isValid;
        }

        return isValid;
    };

    const validateRegisterNRIC = (nric) => {
        var isValid = true;
        var errorMessage = "";
        // regex malaysian ic and passport
        var regex = /^[A-Z]{1}[0-9]{7}[A-Z]{1}$/;
        if (!regex.test(nric)) {
            errorMessage = "Invalid NRIC/Passport No.";
            isValid = false;
        }
        setRegisterNRIC({
            ...registerNRIC,
            error: errorMessage,
            valid: isValid,
        });
        return isValid;
    };

    const validateRegisterEmail = (newValue) => {
        var isValid = true;
        var errorMessage = "";
        var emailRegex = ".+@.+..+";
        if (newValue === "") {
            if (!!errorMessage) errorMessage += "\n";
            errorMessage += "Email field cannot be empty";
            isValid = false;
        }

        if (!newValue.match(emailRegex) && newValue !== "") {
            if (errorMessage) errorMessage += "\n";
            errorMessage += "\nEmail format is incorrect";
            isValid = false;
        }
        //TODO: email format
        setRegisterEmail({
            ...registerEmail,
            error: errorMessage,
            valid: isValid,
        }); //Set Error message

        return isValid;
    };

    const validateRegisterPassword = (newValue) => {
        var isValid = true;
        var errorMessage = "";
        if (newValue === "") {
            if (!!errorMessage) errorMessage += "\n";
            errorMessage += "Password field cannot be empty";
            isValid = false;
        }

        setRegisterPassword({
            ...registerPassword,
            error: errorMessage,
            valid: isValid,
        }); //Set Error message

        return isValid;
    };

    const validateRegisterCPassword = (newValue) => {
        var isValid = true;
        var errorMessage = "";
        if (newValue === "") {
            if (!!errorMessage) errorMessage += "\n";
            errorMessage += "Password field cannot be empty";
            isValid = false;
        }
        if (newValue !== registerPassword.value) {
            if (!!errorMessage) errorMessage += "\n";
            errorMessage += "Password must be the same";
            isValid = false;
        }

        setRegisterCPassword({
            ...registerCPassword,
            error: errorMessage,
            valid: isValid,
        }); //Set Error message

        return isValid;
    };

    const validateRegisterFName = (newValue) => {
        var isValid = true;
        var errorMessage = "";
        if (newValue === "") {
            if (!!errorMessage) errorMessage += "\n";
            errorMessage += "First name field cannot be empty";
            isValid = false;
        }

        setRegisterFName({
            ...registerFName,
            error: errorMessage,
            valid: isValid,
        }); //Set Error message

        return isValid;
    };

    const validateRegisterLName = (newValue) => {
        var isValid = true;
        var errorMessage = "";

        if (newValue === "") {
            if (!!errorMessage) errorMessage += "\n";
            errorMessage += "Last name field cannot be empty";
            isValid = false;
        }

        setRegisterLName({
            ...registerLName,
            error: errorMessage,
            valid: isValid,
        }); //Set Error message

        return isValid;
    };

    const validateRegisterBday = (newValue) => {
        var isValid = true;
        var errorMessage = "";
        if (newValue === "") {
            if (!!errorMessage) errorMessage += "\n";
            errorMessage += "Birthday field cannot be empty";
            isValid = false;
        }
        if (newValue > moment().format("YYYY-MM-DD")) {
            if (!!errorMessage) errorMessage += "\n";
            errorMessage += "Birth date cannot exceed current date";
            isValid = false;
        }

        setRegisterBday({
            ...registerBday,
            error: errorMessage,
            valid: isValid,
        }); //Set Error message

        return isValid;
    };

    const validateRegisterPhoneNum = (newValue) => {
        var isValid = true;
        var errorMessage = "";
        if (newValue === "") {
            if (!!errorMessage) errorMessage = "\n";
            errorMessage = "Contact number field cannot be empty";
            isValid = false;
        }

        //TODO: regex and 10 numbers
        var phoneNumberRegex = "^(601)[0-46-9]*[0-9]{8,9}$";
        if (
            (!newValue.match(phoneNumberRegex) && newValue.length < 11) ||
            newValue.length > 12
        ) {
            if (!!errorMessage) errorMessage = "\n";
            errorMessage =
                "Phone number must have at least 10 or 11 digits and starts off with '60' \n E.g: 60123456789";
            isValid = false;
        }
        setRegisterPhoneNum({
            ...registerPhoneNum,
            error: errorMessage,
            valid: isValid,
        }); //Set Error message

        return isValid;
    };

    const validateRegisterDeliveryAddr = (newValue) => {
        var isValid = true;
        var errorMessage = "";
        if (newValue === "") {
            if (!!errorMessage) errorMessage += "\n";
            errorMessage += "Address field cannot be empty";
            isValid = false;
        }

        setRegisterDeliveryAddr({
            ...registerDeliveryAddr,
            error: errorMessage,
            valid: isValid,
        }); //Set Error message

        return isValid;
    };
    if (loadingBar) {
        return <Spinner className="mt-5" animation="border" />;
    } else {
        return (
            <Modal centered show={show} onHide={handleClose}>
                <Grid container>
                    <Grid item xs={1} lg={1} />
                    <Grid container>
                        <Grid item xs={12} lg={12} align="center">
                            <Modal.Title className=" mx-4 mt-4">
                                <ButtonGroup
                                    style={{
                                        backgroundColor: "white",
                                    }}
                                >
                                    <Button
                                        style={{
                                            borderTopLeftRadius: "25px",
                                            borderBottomLeftRadius: "25px",
                                            width: "95%",
                                            border: "transparent",
                                            color:
                                                tabState === "sign in"
                                                    ? store.getState()
                                                          .colorState.font_color
                                                    : "black",
                                            backgroundColor:
                                                tabState === "sign in"
                                                    ? store.getState()
                                                          .colorState.color
                                                    : "",
                                        }}
                                        onClick={() => {
                                            setTabState("sign in");
                                        }}
                                        variant="outlined"
                                    >
                                        Sign in
                                    </Button>
                                    <Button
                                        style={{
                                            borderTopRightRadius: "25px",
                                            borderBottomRightRadius: "25px",
                                            width: "100%",
                                            // height: "100%",
                                            border: "transparent",
                                            color:
                                                tabState === "register"
                                                    ? store.getState()
                                                          .colorState.font_color
                                                    : "black",
                                            backgroundColor:
                                                tabState === "register"
                                                    ? store.getState()
                                                          .colorState.color
                                                    : "",
                                        }}
                                        onClick={() => {
                                            setTabState("register");
                                        }}
                                        variant="outlined"
                                    >
                                        Register
                                    </Button>
                                </ButtonGroup>
                            </Modal.Title>
                        </Grid>
                        {tabState === "sign in" ? (
                            <>
                                <Grid item xs={12} lg={12} align="center">
                                    <Modal.Title className="text-center mx-4 mt-3">
                                        Sign in
                                    </Modal.Title>{" "}
                                </Grid>
                                <Grid item xs={12} lg={12} align="center">
                                    <Modal.Body
                                        style={{ width: "90%" }}
                                        className="mb-4"
                                    >
                                        <Grid container>
                                            <Grid item xs={12} lg={12}>
                                                <input
                                                    className="p-2 w-75"
                                                    style={{
                                                        border: "1px solid black",
                                                        borderRadius: "5px",
                                                    }}
                                                    type="text"
                                                    class="form-control"
                                                    placeholder="Email"
                                                    onKeyUp={(e) => {
                                                        if (e.key === "Enter") {
                                                            login();
                                                        } else {
                                                            setSignInUsername({
                                                                ...signInUsername,
                                                                value: e.target
                                                                    .value,
                                                            });
                                                        }
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item className="mt-3" xs={12}>
                                                <input
                                                    className="w-100 p-2"
                                                    style={{
                                                        border: "1px solid black",
                                                        borderRadius: "5px",
                                                    }}
                                                    type="password"
                                                    class="form-control"
                                                    placeholder="Password"
                                                    onKeyUp={(e) => {
                                                        if (e.key === "Enter") {
                                                            login();
                                                        } else {
                                                            setSignInPassword({
                                                                ...signInPassword,
                                                                value: e.target
                                                                    .value,
                                                            });
                                                        }
                                                    }}
                                                />
                                            </Grid>

                                            <Grid
                                                item
                                                align="left"
                                                xs={12}
                                                lg={12}
                                            >
                                                <p className="mt-3">
                                                    <a
                                                        href={
                                                            forgotPasswordLink
                                                        }
                                                        target="_blank"
                                                    >
                                                        Forgot password?
                                                    </a>
                                                </p>{" "}
                                            </Grid>
                                            <Grid container>
                                                <Grid
                                                    align="left"
                                                    item
                                                    xs={6}
                                                    lg={6}
                                                >
                                                    <Button
                                                        style={{
                                                            width: "95%",
                                                            borderRadius: "5px",
                                                            backgroundColor:
                                                                "grey",
                                                            color: "black",
                                                            border: "1px solid grey",
                                                            // height: "5vh",
                                                        }}
                                                        onClick={() => {
                                                            handleClose();
                                                        }}
                                                        variant="outlined"
                                                    >
                                                        Cancel
                                                    </Button>
                                                </Grid>
                                                <Grid
                                                    align="right"
                                                    item
                                                    xs={6}
                                                    lg={6}
                                                >
                                                    <Button
                                                        variant="outlined"
                                                        style={{
                                                            width: "95%",
                                                            color: store.getState()
                                                                .colorState
                                                                .font_color,
                                                            backgroundColor:
                                                                store.getState()
                                                                    .colorState
                                                                    .color,
                                                            borderRadius: "5px",
                                                            border: "1px solid transparent",
                                                            // height: "5vh",
                                                        }}
                                                        onClick={(e) => {
                                                            login();
                                                        }}
                                                    >
                                                        Sign in
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Modal.Body>{" "}
                                </Grid>
                            </>
                        ) : (
                            <>
                                <Grid item xs={12} lg={12} align="center">
                                    <Modal.Title className="text-center mx-4 mt-3">
                                        Register
                                    </Modal.Title>{" "}
                                </Grid>
                                <Modal.Body className="mb-4">
                                    <Grid item xs={12} lg={12}>
                                        Login information
                                    </Grid>
                                    <Grid item xs={12} className="mt-2" lg={12}>
                                        <InputGroup hasValidation>
                                            <Form.Control
                                                class="form-control"
                                                style={{
                                                    border: "1px solid black",
                                                    borderRadius: "5px",
                                                }}
                                                placeholder="Email"
                                                type="text"
                                                required
                                                isInvalid={
                                                    registerEmail.valid ===
                                                    false
                                                        ? true
                                                        : false
                                                }
                                                onKeyUp={(e) => {
                                                    if (e.key === "Enter") {
                                                        // log databody
                                                        register();
                                                    } else {
                                                        setRegisterEmail({
                                                            ...registerEmail,
                                                            value: e.target
                                                                .value,
                                                        });
                                                    }
                                                }}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {registerEmail.error}
                                            </Form.Control.Feedback>
                                        </InputGroup>
                                    </Grid>
                                    <Grid container>
                                        <Grid
                                            item
                                            className="mt-3"
                                            xs={12}
                                            lg={6}
                                        >
                                            <InputGroup
                                                className="input-2"
                                                hasValidation
                                            >
                                                <Form.Control
                                                    class="form-control"
                                                    style={{
                                                        border: "1px solid black",
                                                        borderRadius: "5px",
                                                    }}
                                                    placeholder="Password"
                                                    type="password"
                                                    required
                                                    isInvalid={
                                                        registerPassword.valid ===
                                                        false
                                                            ? true
                                                            : false
                                                    }
                                                    onKeyUp={(e) => {
                                                        if (e.key === "Enter") {
                                                            // log databody
                                                            register();
                                                        } else {
                                                            setRegisterPassword(
                                                                {
                                                                    ...registerPassword,
                                                                    value: e
                                                                        .target
                                                                        .value,
                                                                }
                                                            );
                                                        }
                                                    }}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {registerPassword.error}
                                                </Form.Control.Feedback>
                                            </InputGroup>
                                        </Grid>
                                        <Grid
                                            item
                                            align={
                                                window.innerWidth < 1200
                                                    ? "left"
                                                    : "right"
                                            }
                                            className="mt-3"
                                            xs={12}
                                            lg={6}
                                        >
                                            <InputGroup
                                                className="input-2"
                                                hasValidation
                                            >
                                                <Form.Control
                                                    class="form-control"
                                                    style={{
                                                        border: "1px solid black",
                                                        borderRadius: "5px",
                                                    }}
                                                    placeholder="Confirm password"
                                                    type="password"
                                                    required
                                                    isInvalid={
                                                        registerCPassword.valid ===
                                                        false
                                                            ? true
                                                            : false
                                                    }
                                                    onKeyUp={(e) => {
                                                        if (e.key === "Enter") {
                                                            // log databody
                                                            register();
                                                        } else {
                                                            setRegisterCPassword(
                                                                {
                                                                    ...registerCPassword,
                                                                    value: e
                                                                        .target
                                                                        .value,
                                                                }
                                                            );
                                                        }
                                                    }}
                                                />
                                                <Form.Control.Feedback
                                                    className="text-left"
                                                    type="invalid"
                                                >
                                                    {registerCPassword.error}
                                                </Form.Control.Feedback>
                                            </InputGroup>
                                        </Grid>
                                    </Grid>
                                    <Grid item className="mt-3" xs={12} lg={12}>
                                        Account information
                                    </Grid>
                                    <Grid container className="mt-2">
                                        <Grid item xs={12} lg={6}>
                                            <InputGroup
                                                className="input-2"
                                                hasValidation
                                            >
                                                <Form.Control
                                                    class="form-control"
                                                    style={{
                                                        border: "1px solid black",
                                                        borderRadius: "5px",
                                                    }}
                                                    placeholder="First name"
                                                    type="text"
                                                    required
                                                    isInvalid={
                                                        registerFName.valid ===
                                                        false
                                                            ? true
                                                            : false
                                                    }
                                                    onKeyUp={(e) => {
                                                        if (e.key === "Enter") {
                                                            // log databody
                                                            register();
                                                        } else {
                                                            setRegisterFName({
                                                                ...registerFName,
                                                                value: e.target
                                                                    .value,
                                                            });
                                                        }
                                                    }}
                                                />
                                                <Form.Control.Feedback
                                                    className="text-left"
                                                    type="invalid"
                                                >
                                                    {registerFName.error}
                                                </Form.Control.Feedback>
                                            </InputGroup>
                                        </Grid>
                                        <Grid
                                            align={
                                                window.innerWidth < 1200
                                                    ? "left"
                                                    : "right"
                                            }
                                            item
                                            xs={12}
                                            lg={6}
                                        >
                                            <InputGroup
                                                className="input-2"
                                                hasValidation
                                            >
                                                <Form.Control
                                                    class="form-control"
                                                    style={{
                                                        border: "1px solid black",
                                                        borderRadius: "5px",
                                                    }}
                                                    placeholder="Last name"
                                                    type="text"
                                                    required
                                                    isInvalid={
                                                        registerLName.valid ===
                                                        false
                                                            ? true
                                                            : false
                                                    }
                                                    onKeyUp={(e) => {
                                                        if (e.key === "Enter") {
                                                            // log databody
                                                            register();
                                                        } else {
                                                            setRegisterLName({
                                                                ...registerCPassword,
                                                                value: e.target
                                                                    .value,
                                                            });
                                                        }
                                                    }}
                                                />
                                                <Form.Control.Feedback
                                                    className="text-left"
                                                    type="invalid"
                                                >
                                                    {registerLName.error}
                                                </Form.Control.Feedback>
                                            </InputGroup>
                                        </Grid>
                                    </Grid>
                                    <Grid container className="mt-3">
                                        {merchant === "pichaeats" ? (
                                            <Grid item xs={12} lg={6}>
                                                <InputGroup
                                                    className="input-2"
                                                    hasValidation
                                                >
                                                    <Form.Control
                                                        class="form-control"
                                                        style={{
                                                            border: "1px solid black",
                                                            borderRadius: "5px",
                                                        }}
                                                        placeholder="Last name"
                                                        type="date"
                                                        required
                                                        isInvalid={
                                                            registerBday.valid ===
                                                            false
                                                                ? true
                                                                : false
                                                        }
                                                        onKeyUp={(e) => {
                                                            if (
                                                                e.key ===
                                                                "Enter"
                                                            ) {
                                                                // log databody
                                                                register();
                                                            } else {
                                                                setRegisterBday(
                                                                    {
                                                                        ...registerBday,
                                                                        value: e
                                                                            .target
                                                                            .value,
                                                                    }
                                                                );
                                                            }
                                                        }}
                                                    />
                                                    <Form.Control.Feedback
                                                        className="text-left"
                                                        type="invalid"
                                                    >
                                                        {registerBday.error}
                                                    </Form.Control.Feedback>
                                                </InputGroup>
                                            </Grid>
                                        ) : (
                                            ""
                                        )}
                                        {merchant === "farcapital" ? (
                                            <Grid item xs={12} lg={6}>
                                                <InputGroup
                                                    className="input-2"
                                                    hasValidation
                                                >
                                                    <Form.Control
                                                        class="form-control"
                                                        style={{
                                                            border: "1px solid black",
                                                            borderRadius: "5px",
                                                        }}
                                                        placeholder="NRIC/Passport No."
                                                        required
                                                        isInvalid={
                                                            registerNRIC.valid ===
                                                            false
                                                                ? true
                                                                : false
                                                        }
                                                        onKeyUp={(e) => {
                                                            if (
                                                                e.key ===
                                                                "Enter"
                                                            ) {
                                                                // log databody
                                                                register();
                                                            } else {
                                                                setRegisterNRIC(
                                                                    {
                                                                        ...registerNRIC,
                                                                        value: e
                                                                            .target
                                                                            .value,
                                                                    }
                                                                );
                                                            }
                                                        }}
                                                    />
                                                    <Form.Control.Feedback
                                                        className="text-left"
                                                        type="invalid"
                                                    >
                                                        {registerNRIC.error}
                                                    </Form.Control.Feedback>
                                                </InputGroup>
                                            </Grid>
                                        ) : (
                                            ""
                                        )}

                                        <Grid align="left" item xs={12} lg={6}>
                                            <InputGroup
                                                // className={" "}
                                                className="input-2"
                                                hasValidation
                                            >
                                                <Form.Control
                                                    class="form-control"
                                                    style={{
                                                        border: "1px solid black",
                                                        borderRadius: "5px",
                                                    }}
                                                    placeholder="Mobile Phone"
                                                    type="text"
                                                    required
                                                    isInvalid={
                                                        registerPhoneNum.valid ===
                                                        false
                                                            ? true
                                                            : false
                                                    }
                                                    onKeyUp={(e) => {
                                                        if (e.key === "Enter") {
                                                            // log databody
                                                            register();
                                                        } else {
                                                            setRegisterPhoneNum(
                                                                {
                                                                    ...registerPhoneNum,
                                                                    value: e
                                                                        .target
                                                                        .value,
                                                                }
                                                            );
                                                        }
                                                    }}
                                                />
                                                <Form.Control.Feedback
                                                    className="text-left"
                                                    type="invalid"
                                                >
                                                    {registerPhoneNum.error}
                                                </Form.Control.Feedback>
                                            </InputGroup>
                                        </Grid>
                                    </Grid>
                                    {merchant === "pichaeats" ? (
                                        <>
                                            <Grid container className="mt-3">
                                                <Grid item xs={12} lg={6}>
                                                    <InputGroup
                                                        className="input-2"
                                                        hasValidation
                                                    >
                                                        <Form.Control
                                                            class="form-control"
                                                            style={{
                                                                border: "1px solid black",
                                                                borderRadius:
                                                                    "5px",
                                                            }}
                                                            placeholder="Gender"
                                                            as="select"
                                                            onChange={(e) => {
                                                                setRegisterGender(
                                                                    {
                                                                        ...registerGender,
                                                                        value: e
                                                                            .target
                                                                            .value,
                                                                    }
                                                                );
                                                            }}
                                                        >
                                                            <option
                                                                disabled
                                                                selected
                                                                value=""
                                                            >
                                                                Gender
                                                            </option>
                                                            <option value="MALE">
                                                                Male
                                                            </option>
                                                            <option value="MALE">
                                                                Female
                                                            </option>
                                                            <option value="OTHERS">
                                                                Prefer not to
                                                                say
                                                            </option>
                                                        </Form.Control>
                                                    </InputGroup>
                                                </Grid>
                                                <Grid
                                                    align="right"
                                                    item
                                                    xs={12}
                                                    lg={6}
                                                >
                                                    <InputGroup
                                                        className="input-2"
                                                        hasValidation
                                                    >
                                                        <Form.Control
                                                            class="form-control"
                                                            style={{
                                                                border: "1px solid black",
                                                                borderRadius:
                                                                    "5px",
                                                            }}
                                                            placeholder="Festival celebration"
                                                            as="select"
                                                            onChange={(e) => {
                                                                setRegisterFestival(
                                                                    {
                                                                        ...registerFestival,
                                                                        value: e
                                                                            .target
                                                                            .value,
                                                                    }
                                                                );
                                                            }}
                                                            value={
                                                                registerFestival.value
                                                            }
                                                        >
                                                            <option
                                                                disabled
                                                                selected
                                                                value=""
                                                            >
                                                                Festival
                                                                celebration
                                                            </option>
                                                            <option value="RAYA">
                                                                Eid al-Fitr
                                                                (Hari Raya
                                                                Aidilfitri)
                                                            </option>
                                                            <option value="CNY">
                                                                Chinese New Year
                                                            </option>
                                                            <option value="DEEPAVALI">
                                                                Deepavali
                                                            </option>
                                                            <option value="WESAK">
                                                                Wesak
                                                            </option>
                                                            <option value="XMAS">
                                                                Christmas
                                                            </option>
                                                            <option value="NOSAY">
                                                                Prefer not to
                                                                say
                                                            </option>
                                                        </Form.Control>
                                                    </InputGroup>
                                                </Grid>
                                            </Grid>
                                            <Grid
                                                item
                                                className="mt-3"
                                                xs={12}
                                                lg={12}
                                            >
                                                <InputGroup hasValidation>
                                                    <Form.Control
                                                        class="form-control"
                                                        style={{
                                                            border: "1px solid black",
                                                            borderRadius: "5px",
                                                        }}
                                                        placeholder="Festival celebration"
                                                        as="select"
                                                        onChange={(e) => {
                                                            setRegisterDiscovery(
                                                                {
                                                                    ...registerDiscovery,
                                                                    value: e
                                                                        .target
                                                                        .value,
                                                                }
                                                            );
                                                        }}
                                                    >
                                                        <option
                                                            disabled
                                                            selected
                                                            value=""
                                                        >
                                                            How did you hear
                                                            about us?
                                                        </option>
                                                        <option value="Facebook">
                                                            Facebook
                                                        </option>
                                                        <option value="Instagram">
                                                            Instagram
                                                        </option>
                                                        <option value="Others">
                                                            Other Social Media
                                                            Platforms (LinkedIn,
                                                            Twitter, etc)
                                                        </option>
                                                        <option value="Friends &amp; Family">
                                                            Friends & Family
                                                        </option>
                                                        <option value="Google Search">
                                                            Google Search
                                                        </option>
                                                        <option value="Media">
                                                            Media and
                                                            Influencers
                                                        </option>
                                                        <option value="Email">
                                                            Email Newsletter
                                                        </option>
                                                    </Form.Control>
                                                </InputGroup>{" "}
                                            </Grid>
                                        </>
                                    ) : (
                                        ""
                                    )}
                                    {/* console.log if window url has vajrasecrets in it */}
                                    {deliverableSection === false ||
                                    window.location.href.includes(
                                        "vajrasecrets"
                                    ) ? (
                                        ""
                                    ) : (
                                        <>
                                            <Grid
                                                item
                                                className="mt-3"
                                                xs={12}
                                                lg={12}
                                            >
                                                Delivery information
                                            </Grid>
                                            <Grid
                                                item
                                                xs={12}
                                                className="mt-2"
                                                lg={12}
                                            >
                                                <InputGroup hasValidation>
                                                    <Form.Control
                                                        class="form-control"
                                                        style={{
                                                            border: "1px solid black",
                                                            borderRadius: "5px",
                                                        }}
                                                        placeholder="Delivery address"
                                                        type="text"
                                                        required
                                                        isInvalid={
                                                            registerDeliveryAddr.valid ===
                                                            false
                                                                ? true
                                                                : false
                                                        }
                                                        onKeyUp={(e) => {
                                                            if (
                                                                e.key ===
                                                                "Enter"
                                                            ) {
                                                                // log databody
                                                                register();
                                                            } else {
                                                                setRegisterDeliveryAddr(
                                                                    {
                                                                        ...registerDeliveryAddr,
                                                                        value: e
                                                                            .target
                                                                            .value,
                                                                    }
                                                                );
                                                            }
                                                        }}
                                                    />
                                                    <Form.Control.Feedback
                                                        className="text-left"
                                                        type="invalid"
                                                    >
                                                        {
                                                            registerDeliveryAddr.error
                                                        }
                                                    </Form.Control.Feedback>
                                                </InputGroup>
                                            </Grid>
                                            <Grid container className="mt-3">
                                                <Grid item xs={12} lg={6}>
                                                    <InputGroup
                                                        className="input-2"
                                                        hasValidation
                                                    >
                                                        <Form.Control
                                                            class="form-control"
                                                            style={{
                                                                border: "1px solid black",
                                                                borderRadius:
                                                                    "5px",
                                                            }}
                                                            placeholder="Postcode"
                                                            type="text"
                                                            required
                                                            isInvalid={
                                                                registerPostcode.valid ===
                                                                false
                                                                    ? true
                                                                    : false
                                                            }
                                                            onKeyUp={(e) => {
                                                                if (
                                                                    e.key ===
                                                                    "Enter"
                                                                ) {
                                                                    // log databody
                                                                    register();
                                                                } else {
                                                                    setRegisterPostcode(
                                                                        {
                                                                            ...registerPostcode,
                                                                            value: e
                                                                                .target
                                                                                .value,
                                                                        }
                                                                    );
                                                                }
                                                            }}
                                                        />
                                                        <Form.Control.Feedback
                                                            className="text-left"
                                                            type="invalid"
                                                        >
                                                            {
                                                                registerPostcode.error
                                                            }
                                                        </Form.Control.Feedback>
                                                    </InputGroup>
                                                </Grid>
                                                <Grid
                                                    align="right"
                                                    item
                                                    xs={12}
                                                    lg={6}
                                                >
                                                    <InputGroup
                                                        className="input-2"
                                                        hasValidation
                                                    >
                                                        <Form.Control
                                                            class="form-control"
                                                            style={{
                                                                border: "1px solid black",
                                                                borderRadius:
                                                                    "5px",
                                                            }}
                                                            placeholder="Area/District"
                                                            type="text"
                                                            required
                                                            onKeyUp={(e) => {
                                                                if (
                                                                    e.key ===
                                                                    "Enter"
                                                                ) {
                                                                    // log databody
                                                                    register();
                                                                } else {
                                                                    setRegisterDistrict(
                                                                        {
                                                                            ...registerDistrict,
                                                                            value: e
                                                                                .target
                                                                                .value,
                                                                        }
                                                                    );
                                                                }
                                                            }}
                                                        />
                                                        <Form.Control.Feedback
                                                            className="text-left"
                                                            type="invalid"
                                                        >
                                                            {
                                                                registerDistrict.error
                                                            }
                                                        </Form.Control.Feedback>
                                                    </InputGroup>
                                                </Grid>
                                            </Grid>

                                            <Grid
                                                className=" mt-3"
                                                item
                                                xs={12}
                                                lg={12}
                                            >
                                                <InputGroup hasValidation>
                                                    <Form.Control
                                                        class="form-control"
                                                        style={{
                                                            border: "1px solid black",
                                                            borderRadius: "5px",
                                                        }}
                                                        placeholder={
                                                            window.location.href.includes(
                                                                "ketoccino"
                                                            )
                                                                ? "Note to merchant"
                                                                : "Delivery instructions"
                                                        }
                                                        type="text"
                                                        required
                                                        onKeyUp={(e) => {
                                                            if (
                                                                e.key ===
                                                                "Enter"
                                                            ) {
                                                                // log databody
                                                                register();
                                                            } else {
                                                                setRegisterDeliveryInstruction(
                                                                    {
                                                                        ...registerDeliveryInstruction,
                                                                        value: e
                                                                            .target
                                                                            .value,
                                                                    }
                                                                );
                                                            }
                                                        }}
                                                    />
                                                    <Form.Control.Feedback
                                                        className="text-left"
                                                        type="invalid"
                                                    >
                                                        {registerDistrict.error}
                                                    </Form.Control.Feedback>
                                                </InputGroup>
                                            </Grid>
                                        </>
                                    )}
                                    {window.location.href.includes(
                                        "vajrasecrets"
                                    ) ? (
                                        <Grid container className="mt-3">
                                            <Grid item xs={12} md={12} lg={12}>
                                                <InputGroup
                                                    className="input-2"
                                                    hasValidation
                                                >
                                                    <Form.Control
                                                        class="form-control"
                                                        style={{
                                                            border: "1px solid black",
                                                            borderRadius: "5px",
                                                        }}
                                                        value={
                                                            vajraKecharaMembership
                                                        }
                                                        placeholder="Kechara membership #"
                                                        type="text"
                                                        required
                                                        // isInvalid={
                                                        //     registerPostcode.valid ==
                                                        //     false
                                                        //         ? true
                                                        //         : false
                                                        // }
                                                        onChange={(e) => {
                                                            setVajraKecharaMembership(
                                                                e.target.value
                                                            );
                                                        }}
                                                        onKeyUp={(e) => {
                                                            if (
                                                                e.key ===
                                                                "Enter"
                                                            ) {
                                                                // log databody
                                                                register();
                                                            }
                                                        }}
                                                    />
                                                    {/* <Form.Control.Feedback
                                                        className="text-left"
                                                        type="invalid"
                                                    >
                                                        {registerPostcode.error}
                                                    </Form.Control.Feedback> */}
                                                </InputGroup>
                                            </Grid>
                                            <Grid item xs={12} lg={6}>
                                                <InputGroup
                                                    className="input-2 mt-3"
                                                    hasValidation
                                                >
                                                    <Form.Control
                                                        class="form-control"
                                                        style={{
                                                            border: "1px solid black",
                                                            borderRadius: "5px",
                                                            color: "grey",
                                                        }}
                                                        value={vajraLanguage}
                                                        placeholder="Preferred language"
                                                        as="select"
                                                        onChange={(e) => {
                                                            setVajraLanguage(
                                                                e.target.value
                                                            );
                                                        }}
                                                    >
                                                        <option
                                                            disabled
                                                            selected
                                                            value=""
                                                        >
                                                            Preferred Language
                                                        </option>
                                                        <option value="English">
                                                            English
                                                        </option>
                                                        <option value="Chinese">
                                                            Chinese
                                                        </option>
                                                    </Form.Control>
                                                </InputGroup>
                                            </Grid>
                                            <Grid
                                                align="right"
                                                item
                                                className="mt-3"
                                                xs={12}
                                                lg={6}
                                            >
                                                <Form.Control
                                                    class="form-control"
                                                    style={{
                                                        border: "1px solid black",
                                                        borderRadius: "5px",
                                                        color: "grey",
                                                    }}
                                                    placeholder="Country"
                                                    as="select"
                                                    onChange={(e) => {
                                                        setVajraCountry({
                                                            value: e.target
                                                                .value,
                                                        });
                                                    }}
                                                >
                                                    <option
                                                        disabled
                                                        selected
                                                        value=""
                                                    >
                                                        Country
                                                    </option>
                                                    {countryData.value.map(
                                                        (b, index) => (
                                                            <option
                                                            // style={{
                                                            //     verticalAlign:
                                                            //         "middle",
                                                            // }}
                                                            // className="bankLabel"
                                                            >
                                                                {b[0]}
                                                            </option>
                                                        )
                                                    )}
                                                </Form.Control>
                                                {/* <InputGroup
                                                    className="input-2"
                                                    hasValidation
                                                >
                                                    <Form.Control
                                                        class="form-control"
                                                        style={{
                                                            border: "1px solid black",
                                                            borderRadius: "5px",
                                                        }}
                                                        placeholder="Country"
                                                        type="text"
                                                        required
                                                        onKeyUp={(e) => {
                                                            if (
                                                                e.key ===
                                                                "Enter"
                                                            ) {
                                                                // log databody
                                                                register();
                                                            } else {
                                                                setRegisterDistrict(
                                                                    {
                                                                        ...registerDistrict,
                                                                        value: e
                                                                            .target
                                                                            .value,
                                                                    }
                                                                );
                                                            }
                                                        }}
                                                    />
                                                    <Form.Control.Feedback
                                                        className="text-left"
                                                        type="invalid"
                                                    >
                                                        {registerDistrict.error}
                                                    </Form.Control.Feedback>
                                                </InputGroup> */}
                                            </Grid>
                                        </Grid>
                                    ) : null}

                                    {merchant === "pichaeats" ? (
                                        <Grid item className="mt-3" xs={12}>
                                            <FormGroup>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            color="black"
                                                            checked={
                                                                registerConsent.value
                                                            }
                                                            onChange={(e) => {
                                                                setRegisterConsent(
                                                                    {
                                                                        value: e
                                                                            .target
                                                                            .checked,
                                                                    }
                                                                );
                                                            }}
                                                        />
                                                    }
                                                    label="Please untick this box if you do not want to receive future promotion, offers and communication from us"
                                                />
                                            </FormGroup>
                                        </Grid>
                                    ) : (
                                        ""
                                    )}

                                    <Grid
                                        container
                                        className="mt-3"
                                        xs={12}
                                        lg={12}
                                    >
                                        <Grid align="left" item xs={6} lg={6}>
                                            <Button
                                                style={{
                                                    width: "95%",
                                                    borderRadius: "5px",
                                                    // height: "5vh",
                                                    backgroundColor: "grey",
                                                    color: "black",
                                                    border: "1px solid grey",
                                                }}
                                                onClick={() => {
                                                    handleClose();
                                                }}
                                                variant="outlined"
                                            >
                                                Cancel
                                            </Button>
                                        </Grid>
                                        <Grid align="right" item xs={6} lg={6}>
                                            <Button
                                                variant="outlined"
                                                style={{
                                                    width: "95%",
                                                    color: store.getState()
                                                        .colorState.font_color,
                                                    backgroundColor:
                                                        store.getState()
                                                            .colorState.color,
                                                    borderRadius: "5px",
                                                    // height: "5vh",

                                                    border: "1px solid transparent ",
                                                }}
                                                onClick={() => {
                                                    // login();
                                                    register();
                                                }}
                                            >
                                                Register
                                            </Button>
                                        </Grid>
                                    </Grid>
                                    <Grid
                                        container
                                        className="mt-3 "
                                        align="center"
                                    >
                                        <Grid item align="right" xs={6} lg={6}>
                                            <p>Already a member? </p>
                                        </Grid>
                                        <Grid item align="left" xs={6} lg={6}>
                                            <p
                                                onClick={() => {
                                                    setTabState("sign in");
                                                }}
                                                style={{
                                                    color: "blue",
                                                    cursor: "pointer",
                                                }}
                                            >
                                                {"\xa0\xa0"}
                                                Sign in now
                                            </p>
                                        </Grid>
                                        {/* create "sign in now" text and if clicked change tabstate to login */}
                                    </Grid>
                                </Modal.Body>
                            </>
                        )}
                    </Grid>

                    <Grid item xs={1} lg={1} />
                </Grid>
            </Modal>
        );
    }
};

export default LoginModal;
