import { Button } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import AddIcon from "@mui/icons-material/Add";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import FilterListIcon from "@mui/icons-material/FilterList";
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import Cropper from "react-easy-crop";

import withReactContent from "sweetalert2-react-content";
import LoginBar from "../../../../Components/LoginBar/loginbar";
import MerchantTable from "../../../../Components/MerchantTable";
import Sidebar from "../../../../Components/Sidebar/sidebar";
import tierPricingModelService from "../../../../services/tierPricingModelService";
import "./style.css";

const MySwal = withReactContent(Swal);

const PricingModelListing = ({ history }) => {
    useEffect(() => {
        getPricingModels();
    }, []);
    const pricingModelHeaders = {
        property_model_name: { value: "Pricing Model Name", checked: true },
        property_value: { value: "Pricing Model Attribute", checked: true },
        property_price: { value: "Pricing Model Price", checked: true },
        property_status: { value: "Pricing Model Status", checked: true },
        action: { value: "Action", checked: true },
    };
    const getPricingModels = () => {
        tierPricingModelService
            .getAllPropertyPricing()
            .then((res) => {
                setPricingModelData(res.model);
            })
            .catch((e) => {});
    };
    const [pricingModelData, setPricingModelData] = useState([]);
    const [rowsPerPage, setRowsPerPage] = useState();
    const [searchInput, setSearchInput] = useState("");
    const navigateToNewPlanPage = () => {
        history.push("/admin/plan/new");
    };

    const navigateToEditPlanPage = (planId) => {
        history.push(`/admin/plan/${planId}`);
    };

    return (
        <Grid container>
            <Grid
                item
                style={{ marginLeft: "20px" }}
                className="p-3"
                xs={12}
                lg={12}
                md={12}
            >
                {/* profile icon */}
                <Grid item xs={12} md={12} lg={12}>
                    <Grid container className="mt-4 admin-listing">
                        {/* profile icon and username dropdown */}
                        <Grid align="left" item xs={6} md={6} lg={6}>
                            <h2
                                className="text-left color-contrast-dark"
                                style={{ color: "black" }}
                            >
                                Pricing Model Management
                            </h2>
                        </Grid>
                        <Grid item xs={6} md={6} lg={6} align="right">
                            {" "}
                            <LoginBar />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item className="mt-1" xs={12} lg={12}>
                    {/* create 2 button, create new plan and export button */}
                    <Grid container>
                        {/* <Grid item align="left" xs={3} md={3} lg={3}>
                            <Button
                                variant="contained"
                                color="primary"
                                className="mt-2 plan-option-button"
                                endIcon={<AddIcon></AddIcon>}
                                // style={{ minHeight: "44.8px" }}
                                onClick={navigateToNewPlanPage}
                            >
                                New Pricing Model
                            </Button>
                        </Grid> */}

                        {/* <Grid item align="left" xs={2} md={2} lg={2}>
                            <Button
                                style={{ minHeight: "44.8px" }}
                                className="mt-2 ml-3 plan-option-button"
                                variant="contained"
                                // onClick={navigateToNewPlanPage}
                                endIcon={<FilterListIcon></FilterListIcon>}
                            >
                                Filter
                            </Button>
                        </Grid> */}
                    </Grid>
                </Grid>
                {/* catalog page */}
                <MerchantTable
                    page={"pricingModel"}
                    headers={pricingModelHeaders}
                    tableData={pricingModelData}
                    updateTableData={() => {
                        return;
                    }}
                />
            </Grid>
        </Grid>
    );
};

export default PricingModelListing;
