import ImageCropper from "../../../../Components/ImageCropper";
import Image from "react-bootstrap/Image";
import CloseButton from "react-bootstrap/CloseButton";
import { readImageFileIntoDataUrl } from "../../../../utils/imageUtils";
import PhotoLibraryIcon from "@material-ui/icons/PhotoLibrary";
import { Form } from "react-bootstrap";
import { Grid, Button } from "@material-ui/core";
import { useState } from "react";
import { useEffect } from "react";
import profileService from "../../../../services/profileService";
import merchantService from "../../../../services/merchantService";
import planImagesService from "../../../../services/planImagesService";
import { store } from "../../../../index.js";
import Swal from "sweetalert2";
const MerchantSettings = ({ pcData = undefined, arData = undefined }) => {
    const [previewImg, setPreviewImg] = useState();
    const [merchantName, setMerchantName] = useState("");
    const [SSTNumber, setSSTNumber] = useState("");
    const [curlecMerchantId, setCurlecMerchantId] = useState();
    const [curlecEmployeeId, setCurlecEmployeeId] = useState();
    const [settingsId, setSettingsId] = useState();
    const [imageCropModel, setImageCropModel] = useState({
        isOpened: false,
        selectedImageDataUrl: null,
        crop: { x: 0, y: 0 },
        rotation: 0,
        zoom: 0.4,
        croppedAreaPixels: null,
        croppedImage: null,
        aspect: 1 / 2,
    });

    useEffect(() => {
        if (pcData !== undefined) {
            const imagePreview = `${
                window.location.protocol + "//" + window.location.hostname
            }${process.env.REACT_APP_SITE_PORT_BACKEND}${pcData.company_logo}`;
            setPreviewImg({ imagePreview: imagePreview });
            setSettingsId(pcData.settings_id);
        }

        if (arData !== undefined) {
            setCurlecEmployeeId(arData.merch_curlec_employee_id);
            setCurlecMerchantId(arData.merch_curlec_merchant_id);
            setMerchantName(arData.merch_name);
            setSSTNumber(arData.merch_sst_no);
        }
    }, [pcData, arData]);

    const uploadImage = async (e) => {
        if (!!previewImg.imageFile) {
            var dataBody = {
                ...dataBody,
                company_logo: previewImg.imageFile,
            };
        } else if (!!previewImg.imagePreview && !previewImg.imageFile) {
            Swal.fire({
                title: "Profile updated",
                icon: "success",
                confirmButtonColor: store.getState().colorState.color,
            })
                .then((result) => {
                    window.location.reload();
                })
                .catch((e) => {
                    console.error(e);
                });
            return;
        }

        if (settingsId === undefined) {
            await profileService
                .createProfile(dataBody)
                .then((res) => {
                    Swal.fire({
                        title: "Profile created",
                        icon: "success",
                        confirmButtonColor: store.getState().colorState.color,
                    })
                        .then((result) => {
                            window.location.reload();
                        })
                        .catch((e) => {
                            console.error(e);
                        });
                })
                .catch((response) => console.error(response));
        } else {
            // patch settings

            await profileService
                .updateProfile(settingsId, dataBody)
                .then((res) => {
                    Swal.fire({
                        title: "Profile updated",
                        icon: "success",
                        confirmButtonColor: store.getState().colorState.color,
                    })
                        .then((result) => {
                            window.location.reload();
                        })
                        .catch((e) => {
                            console.error(e);
                        });
                })
                .catch((e) => {
                    console.error(e, "error");
                });
        }
    };

    return (
        <Grid item xs={12} md={10} lg={10}>
            <div
                style={{ width: "100%" }}
                className=" pt-2 container-fluid addon-form-container"
            >
                <Grid align="left" className="ml-4" container spacing={1}>
                    <Grid item xs={12} md={12} lg={12}>
                        <h4
                            className=" color-contrast-dark mt-3"
                            style={{ color: "black" }}
                        >
                            <strong>Merchant Settings</strong>
                        </h4>
                    </Grid>
                    <Grid item xs={12} md={12} lg={12}>
                        <Form.Label>Curlec Merchant ID</Form.Label>
                        <Form.Control
                            disabled
                            value={curlecMerchantId}
                            className="w-75"
                        />
                    </Grid>
                    <Grid item xs={12} md={12} lg={12}>
                        <Form.Label>Curlec Employee ID</Form.Label>
                        <Form.Control
                            disabled
                            value={curlecEmployeeId}
                            className="w-75"
                        />
                    </Grid>
                    <Grid item xs={12} md={12} lg={12}>
                        <Form.Label>Company Name</Form.Label>
                        <Form.Control
                            className="w-75"
                            value={merchantName}
                            disabled
                            onChange={(e) => {
                                setMerchantName(e.target.value);
                            }}
                        />
                    </Grid>
                    {/* <Grid item xs={12} md={12} lg={12}>
                        <Form.Label>Company Address</Form.Label>
                        <Form.Control className="w-75" />
                    </Grid>
                    <Grid item xs={12} md={12} lg={12}>
                        <Form.Label>City</Form.Label>
                        <Form.Control className="w-75" />
                    </Grid>
                    <Grid item xs={12} md={12} lg={12}>
                        <Form.Label>State</Form.Label>
                        <Form.Control className="w-75" />
                    </Grid>
                    <Grid item xs={12} md={12} lg={12}>
                        <Form.Label>Post Code</Form.Label>
                        <Form.Control className="w-75" />
                    </Grid>
                    <Grid item xs={12} md={12} lg={12}>
                        <Form.Label>Company Contact Number</Form.Label>
                        <Form.Control className="w-75" />
                    </Grid>
                    <Grid item xs={12} md={12} lg={12}>
                        <Form.Label>Currency</Form.Label>
                        <Form.Control className="w-75" />
                    </Grid> */}
                    <Grid item xs={12} md={12} lg={12}>
                        <Form.Label>SST Number</Form.Label>
                        <Form.Control
                            className="w-75"
                            value={SSTNumber}
                            onChange={(e) => {
                                setSSTNumber(e.target.value);
                            }}
                            disabled
                        />
                    </Grid>
                    <Grid item xs={12} md={12} lg={12}>
                        <Form.Label>Logo Image</Form.Label>
                    </Grid>
                    <Grid item xs={12} md={12} lg={12}>
                        <Button
                            startIcon={<PhotoLibraryIcon />}
                            style={{
                                backgroundColor: "black",
                            }}
                            variant="contained"
                            color="primary"
                            component="label"
                        >
                            Upload Images
                            <input
                                accept="image/*"
                                type="file"
                                onChange={async (e) => {
                                    if (
                                        !!e.target.files &&
                                        e.target.files.length > 0
                                    ) {
                                        const file = e.target.files[0];

                                        let imageDataUrl =
                                            await readImageFileIntoDataUrl(
                                                file
                                            );

                                        //after an image file is selected open the cropping model
                                        setImageCropModel({
                                            ...imageCropModel,
                                            isOpened: true,
                                            selectedImageDataUrl: imageDataUrl,
                                        });
                                    }
                                }}
                                hidden
                            />
                        </Button>
                    </Grid>

                    <Grid item xs={12} md={12} lg={12}>
                        <Form.Label>Image Preview</Form.Label>
                    </Grid>
                    <Grid
                        className="settings-image-container mb-4"
                        item
                        xs={12}
                        md={6}
                        lg={6}
                    >
                        {previewImg !== undefined ? (
                            <Grid item xs={12} md={12} lg={12}>
                                <Image
                                    className="settings-image-centered"
                                    src={previewImg.imagePreview}
                                ></Image>
                            </Grid>
                        ) : (
                            <Grid item xs={12} md={12} lg={12}>
                                <PhotoLibraryIcon className="default-settings-image" />
                            </Grid>
                        )}
                    </Grid>
                </Grid>

                <ImageCropper
                    imgCrop={imageCropModel}
                    handleImgCrop={(imgData) => {
                        setImageCropModel(imgData);
                    }}
                    imgPreview={previewImg}
                    handleImgPreview={(img) => {
                        setPreviewImg({
                            imageFile: img.imageFile,
                            imagePreview: img.imagePreview,
                        });
                    }}
                />
            </div>
            <Grid container className="mt-3" xs={12} md={12} lg={12}>
                {/* create two button cancel and save */}
                <Grid item xs={12} md={12} lg={12}>
                    <Button className="mr-3 " variant="contained">
                        Cancel
                    </Button>
                    <Button
                        className="settings-save-button"
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            uploadImage();
                        }}
                    >
                        Save
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    );
};
export default MerchantSettings;
