import { callAPI } from "../../utils/apiUtils";
import axios from "axios";
import generalFunctionService from "../generalFunctionService";

const profileService = {
    getProfile: () => {
        return new Promise((resolve, reject) => {
            callAPI(`/settings`)
                .then((response) => {
                    resolve(response);
                })
                .catch((response) => reject(response));
        });
    },
    getPostcode: (postcode) => {
        return new Promise((resolve, reject) => {
            callAPI(`/catalog/postcode/${postcode}`)
                .then((response) => {
                    resolve(response);
                })
                .catch((response) => reject(response));
        });
    },
    getPostcodePrice: (dataBody) => {
        return new Promise((resolve, reject) => {
            callAPI(`/catalog/postcode_price/${dataBody}/`)
                .then((response) => {
                    resolve(response);
                })
                .catch((response) => reject(response));
        });
    },
    createProfile: (dataBody) => {
        var formData = generalFunctionService.convertJsonToFormData(dataBody);

        return new Promise((resolve, reject) => {
            axios
                .post(`/settings/`, formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                })
                .then((response) => {
                    resolve(response);
                })
                .catch((response) => reject(response));
        });
    },
    updateProfile: (profileId, dataBody) => {
        var formData = generalFunctionService.convertJsonToFormData(dataBody);

        return new Promise((resolve, reject) => {
            axios
                .put(`/settings/${profileId}`, formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                })
                .then((response) => {
                    resolve(response);
                })
                .catch((response) => reject(response));
        });
    },
};

export default profileService;
