import ErrorIcon from "@material-ui/icons/Error";
import { createTheme } from "@mui/material/styles";
import { React, useEffect, useState } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import userService from "./services/userService";
import LoginRegisterModal from "./Components/modal/loginRegisterModal";
import Sidebar from "./Components/Sidebar/sidebar";
import Admin from "./Containers/Admin";
import CatalogDetail from "./Containers/catalogDetail/catalogDetail.js";
import Confirmation from "./Containers/Confirmation/index";
import Customer from "./Containers/Customer";
import Homepage from "./Containers/Homepage/homepage";
import NotFoundPage from "./Containers/NotFoundPage";
import PlanDetail from "./Containers/PlanDetail/index.js";
import googleAnalyticService from "./services/analyticService/googleAnalytic";
import pixelService from "./services/analyticService/pixel";
import ga4Service from "./services/analyticService/ga4";
import { store } from "./index.js";
import "./App.css";

// grab merchant color code from backend
// set store here

function Routes() {
    const [loggedIn, setLoggedIn] = useState(false);
    const [toggleSidebar, setToggleSidebar] = useState(false);
    useEffect(() => {
        setLoggedIn(userService.isLoggedIn());

        // enable pixel and google analytics for picheats
        var hostname = window.location.hostname;
        hostname = hostname.split(".");
        if (hostname[0] == "pichaeats") {
            localStorage.setItem("pixel-enabled", "true");
            localStorage.setItem("google-analytics-enabled", "true");
            pixelService.init();
            googleAnalyticService.init();
        } else if (hostname[0] == "subscribe" && hostname[1] == "ketoccino") {
            localStorage.setItem("google-analytics-4-enabled", "true");
            ga4Service.init();
        }
    }, []);
    const toggleSidebarHandler = () => {
        if (
            (toggleSidebar == false || toggleSidebar == true) &&
            window.innerWidth < 768
        ) {
            return "0px";
        } else if (toggleSidebar == true && window.innerWidth > 768) {
            return "250px";
        } else if (
            toggleSidebar == false &&
            window.innerWidth > 768 &&
            loggedIn == false
        ) {
            return "0px";
        } else if (
            toggleSidebar == false &&
            loggedIn &&
            window.innerWidth > 768
        ) {
            return "50px";
        }
    };
    return (
        <BrowserRouter>
            {/* <ThemeProvider theme={}> */}
            <div className="App">
                {loggedIn ? (
                    <Sidebar
                        toggleState={toggleSidebar}
                        handleToggle={() => {
                            setToggleSidebar(!toggleSidebar);
                        }}
                    />
                ) : null}
                <div
                    style={{
                        marginLeft: toggleSidebarHandler(),
                    }}
                >
                    <Switch>
                        <Route path="/" exact component={Homepage} />
                        <Route path="/confirmation" component={Confirmation} />
                        <Route
                            path="/product/:id"
                            exact
                            component={CatalogDetail}
                        />
                        <Route path="/plan/:id/" exact component={PlanDetail} />
                        {/* <Route path="/plan/:id/selection" exact component={PlanDetail} /> */}

                        <Route path="/admin" component={Admin} />
                        <Route path="/customer" component={Customer} />
                        <Route
                            path="/not-found"
                            exact
                            component={NotFoundPage}
                        />
                        <Route path="*" exact component={NotFoundPage} />
                    </Switch>
                </div>

                <LoginRegisterModal></LoginRegisterModal>
                <ToastContainer />
            </div>
            {/* </ThemeProvider> */}
        </BrowserRouter>
    );
}

export default Routes;
