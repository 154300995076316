import Grid from "@material-ui/core/Grid";
import { Card } from "@mui/material";
import Step from "@mui/material/Step";
import StepButton from "@mui/material/StepButton";
import Stepper from "@mui/material/Stepper";
import { useState } from "react";
import { RadioGroup } from "@mui/material";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import "./style.css";
import { Slider } from "@mui/material";
import { store } from "../..";
// pass in menu data

const KetoccinoMenu = ({
    coffeeAmount,
    coffeeAmountHandler,
    coffeeDetails,
}) => {
    console.log(coffeeDetails, "this is the coffee amount");
    const steps = [
        <FormControlLabel
            value={1}
            control={<Radio className=" mt-4" />}
            checked={coffeeAmount === 1}
            onChange={() => {
                coffeeAmountHandler(1);
            }}
            labelPlacement="bottom"
            label="1 serving"
        />,
        <FormControlLabel
            value={2}
            control={<Radio className=" mt-4" />}
            checked={coffeeAmount === 2}
            onChange={() => {
                coffeeAmountHandler(2);
            }}
            labelPlacement="bottom"
            label="2 servings"
        />,
        <FormControlLabel
            value={3}
            control={<Radio className=" mt-4" />}
            checked={coffeeAmount === 3}
            onChange={() => {
                coffeeAmountHandler(3);
            }}
            labelPlacement="bottom"
            label="3 servings"
        />,
        <FormControlLabel
            value={4}
            control={<Radio className=" mt-4" />}
            checked={coffeeAmount === 4}
            onChange={() => {
                coffeeAmountHandler(4);
            }}
            labelPlacement="bottom"
            label="4 servings"
        />,
        <FormControlLabel
            value={5}
            control={<Radio className=" mt-4" />}
            checked={coffeeAmount === 5}
            labelPlacement="bottom"
            onChange={() => {
                coffeeAmountHandler(5);
            }}
            label="5 servings"
        />,
    ];

    const marks = [
        {
            value: 1,
            label: "1 ",
        },
        {
            value: 2,
            label: "2 ",
        },
        {
            value: 3,
            label: "3 ",
        },
        {
            value: 4,
            label: "4 ",
        },
        {
            value: 5,
            label: "5 ",
        },
    ];

    return (
        <Grid item align="center" xs={12} md={12} lg={12}>
            <Card className="addon-card-container" variant="outlined">
                <Grid item xs={12} md={12} lg={12} className="mt-4">
                    <h3>
                        <strong>
                            We send Ketoccino to your doorstep every 20 days
                        </strong>
                    </h3>
                    <h4>How many cups of coffee do you drink daily?</h4>
                </Grid>
                <Grid item className="mt-5" xs={12} md={12} lg={12}>
                    {window.innerWidth < 400 ? (
                        <Slider
                            defaultValue={1}
                            // getAriaValueText={valuetext}
                            value={coffeeAmount}
                            style={{ width: "80%", color: "#f50057" }}
                            step={null}
                            min={1}
                            max={5}
                            valueLabelDisplay="auto"
                            // value={coffeeAmount}
                            onChange={(e) => {
                                console.log(
                                    e.target.value,
                                    "this is the value"
                                );
                                coffeeAmountHandler(e.target.value);
                            }}
                            marks={marks}
                        />
                    ) : (
                        <>
                            <Stepper>
                                {steps.map((label, index) => (
                                    <Step key={index}>
                                        <RadioGroup value={coffeeAmount} row>
                                            {label}
                                        </RadioGroup>
                                    </Step>
                                ))}
                            </Stepper>
                        </>
                    )}
                </Grid>
                <Grid className="mt-5" item xs={12} md={12} lg={12}>
                    {coffeeAmount == 0 ? null : (
                        <h5>
                            <strong>
                                That would be {coffeeAmount * 2} bottles of
                                Ketoccino for only RM
                                {coffeeDetails?.addon_price *
                                    (coffeeAmount * 2)}{" "}
                                !{" "}
                                {coffeeAmount <= 2
                                    ? "(+ RM5 Delivery Charge)"
                                    : "(Hooray! Free Delivery)"}
                            </strong>
                        </h5>
                    )}
                </Grid>
            </Card>
        </Grid>
    );
};
export default KetoccinoMenu;
