import DateFnsUtils from "@date-io/date-fns";
import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormLabel,
    Grid,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography,
} from "@material-ui/core";
import { timeFromInt } from "time-number";
import { InputGroup } from "react-bootstrap";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import { withStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import AddIcon from "@material-ui/icons/Add";
import CloseIcon from "@material-ui/icons/Close";
import EditIcon from "@material-ui/icons/Edit";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import RemoveIcon from "@mui/icons-material/Remove";
import { Avatar } from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Col, Form, Modal, Row, Tab, Tabs } from "react-bootstrap";
import { useSelector } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { urlAction } from "../../../../action";
import InvoiceGeneratedFromSubscription from "../../../../Components/InvoiceGeneratedFromSubscription";
import { store } from "../../../../index.js";
import invoiceService from "../../../../services/invoiceService";
import addOnService from "../../../../services/addOnService";
import customerService from "../../../../services/customerService";
import planService from "../../../../services/planService";
import profileService from "../../../../services/profileService";
import subscriptionService from "../../../../services/subscriptionService";
import AddonEditCreate from "../../Addon/AddonEditCreate";
import PlanForm from "../../Plan/PlanForm";
import "./style.css";
import { data } from "jquery";

const MySwal = withReactContent(Swal);
const DialogTitle = (props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle
            disableTypography
            className="m-0"
            style={{ padding: "16px 24px" }}
            {...other}
        >
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton
                    aria-label="close"
                    style={{ position: "absolute", top: "8px", right: "8px" }}
                    onClick={onClose}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
};

const ErrorTooltip = withStyles({
    tooltip: {
        color: "white",
        backgroundColor: "red",
    },
})(Tooltip);

const SubscriptionView = ({ history, match, customBackUrl = null }) => {
    const [subscriptionData, setSubscriptionData] = useState();
    const [deliveryTime, setDeliveryTime] = useState();
    const [custPostcodePrice, setCustPostcodePrice] = useState(0);
    const [priceList, setPriceList] = useState([]);
    const [newSubsPrice, setNewSubsPrice] = useState(0);
    const [defaultMenuOption, setDefaultMenuOption] = useState([]);
    const [nonDefaultMenuOption, setNonDefaultMenuOption] = useState([]);
    const [planPrice, setPlanPrice] = useState(0);
    const [tabState, setTabState] = useState("plan");
    const [changeDeliveryTime, setChangeDeliveryTime] = useState();
    const [newDeliveryDate, setNewDeliveryDate] = useState(null);
    const [isShippable, setIsShippable] = useState(false);
    const [subId, setSubId] = useState({ value: "" });
    const [nextDeliveryDate, setNextDeliveryDate] = useState();
    const [chosenDefaultMenu, setChosenDefaultMenu] = useState({ value: [] });
    const [chosenNonDefaultMenu, setChosenNonDefaultMenu] = useState({
        value: [],
    });
    const [newPlanDeliveryDate, setNewPlanDeliveryDate] = useState();
    const [deliveryLeadTime, setDeliveryLeadTime] = useState();
    const [pricingModel, setPricingModel] = useState();
    const [planId, setPlanId] = useState();
    const [changePlan, setChangePlan] = useState({ editable: true });
    const [subStatus, setSubStatus] = useState({ value: "" });
    const [subType, setSubType] = useState({ value: "" });
    const [dateCreated, setDateCreated] = useState({ value: "" });
    const [planQuantity, setPlanQuantity] = useState({ value: "1" });
    const [defaultMenu, setDefaultMenu] = useState([]);
    const [nonDefaultMenu, setNonDefaultMenu] = useState([]);
    const [dateEnd, setDateEnd] = useState({ value: "" });
    const [trialStart, setTrialStart] = useState({ value: "" });
    const [trialEnd, setTrialEnd] = useState({ value: "" });
    const [nextRenew, setNextRenew] = useState({ value: "" });
    const [extraCharge, setExtraCharge] = useState();
    const [planName, setPlanName] = useState();
    const [menus, setMenus] = useState({ value: [] });
    const [newDate, setNewDate] = useState();
    const [additionalPrice, setAdditionalPrice] = useState(0);
    const [allAddon, setAllAddon] = useState({});
    var [selectedAddons, setSelectedAddons] = useState({ value: [] }); //default selectfrontend/src/Containers/catalogDetail/catalogDetail.jsed
    const [remainingPaymentCycle, setRemainingPaymentCycle] = useState({
        value: "recommended",
    });
    const [planDeliveryDate, setPlanDeliveryDate] = useState("");
    const [promoApplied, setPromoApplied] = useState({ value: null });
    const [selectedPlan, setSelectedPlan] = useState({}); //default selected
    const [priceTableRow, setPriceTableRow] = useState({});
    const [planMenus, setPlanMenus] = useState({
        default_menu: [],
        non_default_menu: [],
    });
    const [planSelection, setPlanSelection] = useState({ value: [] });
    const [custId, setCustId] = useState({ value: "" });
    const [custName, setCustName] = useState({ value: "" });
    const [subscriptionPricingInfo, setSubscriptionPricingInfo] = useState({});
    const [infoModal, setInfoModal] = useState({ isOpened: false });
    const [subsPrice, setSubsPrice] = useState();
    const [deliveryFee, setDeliveryFee] = useState();
    // const [extraCharges, setExtraCharges] = useState();
    const [custDeliveryArea, setCustDeliveryArea] = useState();
    const [promoStatus, setPromoStatus] = useState();
    const [planDeliveryTime, setPlanDeliveryTime] = useState();
    const [propertyModel, setPropertyModel] = useState();
    const [sideOrderOption, setSideOrderOption] = useState([]);
    const [selectedSideOrder, setSelectedSideOrder] = useState({ value: [] });
    const [vajraKecharaMembership, setVajraKecharaMembership] = useState({});
    const [vajraDedicationNote, setVajraDedicationNote] = useState({
        value: "",
    });
    const [vajraRefCode, setVajraRefCode] = useState();
    const [vajraGiftMessage, setVajraGiftMessage] = useState({value:""});
    const [vajraIsGift, setVajraIsGift] = useState();
    const [vajraRecipientName, setVajraRecipientName] = useState();
    const [vajraRecipientEmail, setVajraRecipientEmail] = useState({
        value: "",
    });
    const [vajraPreferredLanguage, setVajraPreferredLanguage] = useState({});
    const [vajraCountry, setVajraCountry] = useState({});
    const monthNames = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
    ];
    const fetchSubscriptionWithId = (subId) => {
        const splitURL = urlAction("get_merchant");

        subscriptionService
            .getSingleSubscription(subId)
            .then((res) => {
                var subscriptionData = res.subs;
                console.log(subscriptionData, "this is the subscription data");
                var dict = {};
                var tempPriceList = [];
                subscriptionData.default_menu.forEach((e) => {
                    dict[Object.keys(dict).length] = ["default", e];
                });
                subscriptionData.non_default_menu.forEach((e) => {
                    dict[Object.keys(dict).length] = ["non_default", e];
                });
                // subscriptionData.side_order.forEach((e) => {
                //     dict[Object.keys(dict).length] = ["side_order", e];
                // });
                setSelectedSideOrder({ value: subscriptionData.side_order });
                setPricingModel(subscriptionData.pricing_model);
                setPromoStatus(subscriptionData.promo_status);
                setSubscriptionData(subscriptionData);
                setSubType({ value: subscriptionData.subs_type });
                setDeliveryTime(subscriptionData.delivery_information_time);

                if (window.location.href.includes("vajrasecrets")) {
                    subscriptionData.vajra_dedication_note !== null
                        ? setVajraDedicationNote({
                              value: subscriptionData.vajra_dedication_note,
                          })
                        : setVajraDedicationNote({ value: "" });
                    setVajraRefCode(subscriptionData.vajra_ref_code);
                    subscriptionData.vajra_gift_message !== null
                        ? setVajraGiftMessage({
                              value: subscriptionData.vajra_gift_message,
                          })
                        : setVajraGiftMessage({ value: "" });
                    setVajraIsGift(subscriptionData.vajra_this_a_gift);
                    setVajraRecipientName(
                        subscriptionData.vajra_recipient_name
                    );
                    setVajraRecipientEmail({
                        value: subscriptionData.vajra_recipient_email,
                    });
                }
                customerService
                    .getSpecificCustomerFromAR(subscriptionData.customer_id)
                    .then((res) => {
                        const newVal = res.cust_addr_postcode;
                        if (window.location.href.includes("vajrasecrets")) {
                            setVajraKecharaMembership({
                                value: res.vajra_kechara_membership,
                            });
                            setVajraPreferredLanguage({
                                value: res.vajra_language,
                            });
                            setVajraCountry({ value: res.vajra_country });
                        }
                        profileService.getPostcode(newVal).then((e) => {
                            // if pricing model is property and merchant is kombucha
                            if (
                                subscriptionData.pricing_model === "property" &&
                                splitURL === "wildkombucha"
                            ) {
                                // if e.area is klang valley and subscriptionData.subs_price is more than 50
                                // then set custpostcodeprice to 0
                                setCustDeliveryArea(e.postcode.area)
				if (
                                    e.postcode.area === "Klang Valley" &&
                                    subscriptionData.subs_price > 50
                                ) {
                                    setCustPostcodePrice(0);
                                }
                                // else if e.postcode.area is not Klang Valley and subscriptionData.subs_price is more than 150
                                // setCustPostcodePrice to 0
                                else if (
                                    e.postcode.area !== "Klang Valley" &&
                                    subscriptionData.subs_price > 150
                                ) {
                                    setCustPostcodePrice(0);
                                } else {
                                    setCustPostcodePrice(e.postcode.price);
                                }
                            } else {
                                console.log(
                                    subscriptionData,
                                    "this is in ketoccino"
                                );
                                if (
                                    window.location.href.includes(
                                        "ketoccino"
                                    ) &&
                                    subscriptionData.default_menu.length > 0
                                ) {
                                    if (
                                        subscriptionData.default_menu[0]
                                            .quantity >= 6
                                    ) {
                                        setCustPostcodePrice(0);
                                    } else {
                                        setCustPostcodePrice(e.postcode.price);
                                    }
                                } else {
                                    setCustPostcodePrice(e.postcode.price);
                                    setCustDeliveryArea(e.postcode.area);
                                }
                            }
                        });
                    });
                if (subscriptionData.property_model !== null) {
                    setPropertyModel(subscriptionData.property_model);
                }
                setSubsPrice(subscriptionData.subs_price);
                setPlanPrice(subscriptionData.plan_price);
                setPlanName(subscriptionData.plan_name);
                setPlanId(subscriptionData.plan_id);

                setDefaultMenu(subscriptionData.default_menu);

                if (subscriptionData.delivery_frequency == "annum") {
                    setNextDeliveryDate(
                        moment(subscriptionData.delivery_date)
                            .add(12, "months")
                            .format("YYYY-MM-DD")
                    );
                } else if (subscriptionData.delivery_frequency == "bi-annum") {
                    setNextDeliveryDate(
                        moment(subscriptionData.delivery_date).add(6, "months")
                    );
                } else if (subscriptionData.delivery_frequency == "quarter") {
                    setNextDeliveryDate(
                        moment(subscriptionData.delivery_date).add(3, "months")
                    );
                } else if (subscriptionData.delivery_frequency == "month") {
                    setNextDeliveryDate(
                        moment(subscriptionData.delivery_date)
                            .add(28, "days")
                            .format("DD-MM-YYYY")
                    );
                } else if (
                    subscriptionData.delivery_frequency === "twenty_days"
                ) {
                    setNextDeliveryDate(
                        moment(subscriptionData.delivery_date)
                            .add(20, "days")
                            .format("DD-MM-YYYY")
                    );
                } else if (subscriptionData.delivery_frequency == "biweek") {
                    setNextDeliveryDate(
                        moment(subscriptionData.delivery_date)
                            .add(14, "days")
                            .format("DD-MM-YYYY")
                    );
                } else if (subscriptionData.delivery_frequency == "week") {
                    setNextDeliveryDate(
                        moment(subscriptionData.delivery_date)
                            .add(7, "days")
                            .format("DD-MM-YYYY")
                    );
                } else if (subscriptionData.delivery_frequency == "day") {
                    setNextDeliveryDate(
                        moment(subscriptionData.delivery_date)
                            .add(1, "days")
                            .format("DD-MM-YYYY")
                    );
                }
                console.log(
                    new Date(subscriptionData.delivery_date).getMonth(),
                    "this is the delivery date"
                );
                window.location.href.includes("vajrasecrets")
                    ? setPlanDeliveryDate(
                          " 1st " +
                              monthNames[
                                  new Date(
                                      subscriptionData.delivery_date
                                  ).getMonth()
                              ] +
                              " " +
                              new Date(
                                  subscriptionData.delivery_date
                              ).getUTCFullYear()
                      )
                    : setPlanDeliveryDate(
                          new Date(subscriptionData.delivery_date)
                      );
                setNewPlanDeliveryDate(
                    new Date(subscriptionData.delivery_date)
                );
                setPlanDeliveryTime(subscriptionData.delivery_time);
                setSubId({ value: subscriptionData.subs_id });
                setNonDefaultMenu(subscriptionData.non_default_menu);
                setCustId({ value: subscriptionData.customer_id });
                setCustName({
                    value:
                        subscriptionData.customer_fname +
                        " " +
                        subscriptionData.customer_lname,
                });
                setSubStatus({ value: subscriptionData.subs_status });
                setDateCreated({
                    value: moment(subscriptionData.date_created).isValid()
                        ? moment(subscriptionData.date_created).format(
                              "yyyy-MM-DDTHH:mm"
                          )
                        : "",
                });
                setTrialStart({
                    value: moment(subscriptionData.trial_start).isValid()
                        ? moment(subscriptionData.trial_start).format(
                              "yyyy-MM-DDTHH:mm"
                          )
                        : "",
                });
                setTrialEnd({
                    value: moment(subscriptionData.trial_end).isValid()
                        ? moment(subscriptionData.trial_end).format(
                              "yyyy-MM-DDTHH:mm"
                          )
                        : "",
                });
                setNextRenew({
                    value: moment(subscriptionData.delivery_date).isValid()
                        ? moment(subscriptionData.delivery_date)
                              .add(28, "days")
                              .format("DD-MM-YYYY")
                        : "",
                });
                setDateEnd({
                    value: moment(subscriptionData.date_end).isValid()
                        ? moment(subscriptionData.date_end).format(
                              "yyyy-MM-DDTHH:mm"
                          )
                        : "",
                });
                setRemainingPaymentCycle({
                    value: subscriptionData.payment_cycles,
                });

                if (!!subscriptionData.promo) {
                    setPromoApplied({ value: subscriptionData.promo });
                }

                planService.getAllPlansWithoutPagination().then((res) => {
                    const filtered = res.plans.filter(
                        (i) => i.plan_status != "deleted"
                    );
                    setPlanSelection({ ...planSelection, value: filtered });
                });

                planService
                    .getSinglePlan(subscriptionData.plan_id)
                    .then((res) => {
                        setSelectedPlan(res.plans);
                        setDeliveryLeadTime(res.plans.delivery_lead_time);
                        setIsShippable(res.plans.is_shippable);
                        setDefaultMenuOption(res.plans.default_menu);
                        setNonDefaultMenuOption(res.plans.non_default_menu);
                        setSideOrderOption(res.plans.side_order);
                        // setPlanPrice(res.plans.)
                        setPlanPrice(res.plans.plan_price);
                        //set initial price list here
                        console.log(res, "this is the single plan");
                        Object.keys(dict).map((data, index) => {
                            if (dict[data][0] == "default") {
                                tempPriceList.push(0);
                            } else if (dict[data][0] == "non_default") {
                                const newVal = res.plans.non_default_menu
                                    .filter(
                                        (menu) =>
                                            menu.addon_id ==
                                            dict[data][1].addon_id
                                    )
                                    .map((obj) => {
                                        return obj.addon_price;
                                    });
                                dict[data][1]["addon_price"] = newVal[0];
                                tempPriceList.push(newVal[0]);
                                // tempPriceList.push(dict[data][1].addon_price);
                            }
                        });
                        setMenus({ value: dict });
                        setPriceList(tempPriceList);
                        if (res.plans.plan_type == "fixed_quantity") {
                            setPlanQuantity({
                                value: subscriptionData.plan_quantity,
                            });
                        }
                    })
                    .catch((err) => {
                        console.log(
                            err,
                            "fetch subscription error, please try again later"
                        );
                    });

                var addonInformationPromises =
                    subscriptionData.default_menu.map((addon) => {
                        return new Promise((resolve, reject) => {
                            addOnService
                                .getSingleAddon(addon.addon_id)
                                .then((res) => {
                                    resolve(res.addons);
                                })
                                .catch((err) => {
                                    reject(err);
                                });
                        });
                    });

                Promise.all(addonInformationPromises)
                    .then((allAddonData) => {
                        const addonDatas = subscriptionData.default_menu.map(
                            (addon) => {
                                const currentAddonInfo = allAddonData.find(
                                    (a) => a.addon_id == addon.addon_id
                                );
                                return {
                                    selectedAddonId: addon.addon_id,
                                    quantity: addon.quantity,
                                    ...currentAddonInfo,
                                };
                            }
                        );

                        setSelectedAddons({
                            ...selectedAddons,
                            value: addonDatas,
                        });
                    })
                    .catch((err) => {
                        console.error(err);
                    });
            })
            .catch((res) => {
                redirectToSubscriptionListingPage();
            });
    };

    //check price here
    //upon changing menu,
    // check if in default or non default
    //if default
    //change default menu list
    //if non default
    //change non default menu
    const renderPromoPrice = () => {
        var tempPromoPrice = 0;
        //take created at column add duration if limited
        if (promoApplied.value != undefined && promoStatus == "active") {
            const duration = promoApplied.value.duration;
            var beginDate = new Date(promoApplied.value.begin_date);
            var expiryDate = new Date(promoApplied.value.begin_date);
            //need to insert percentage or not
            const durationType = promoApplied.value.duration_type;
            if (durationType == "limited") {
                expiryDate = expiryDate.setMonth(
                    expiryDate.getMonth() + duration
                );
                var time = moment.duration(
                    new Date(expiryDate) - new Date(),
                    "milliseconds"
                );
                if (Math.round(time.asMonths()) >= 0) {
                    if (promoApplied.value.promo_by_percentage) {
                        tempPromoPrice =
                            (promoApplied.value.discount_amount * planPrice) /
                            100;
                    } else {
                        tempPromoPrice = promoApplied.value.discount_amount;
                    }
                }
            } else if (durationType == "one_time") {
                var time = moment.duration(
                    new Date() - new Date(beginDate),
                    "milliseconds"
                );
                if (time.asMonths() >= 0) {
                    if (promoApplied.value.promo_by_percentage) {
                        tempPromoPrice =
                            (promoApplied.value.discount_amount * planPrice) /
                            100;
                    } else {
                        tempPromoPrice = promoApplied.value.discount_amount;
                    }
                }

                //check if current date has past one billing cycle or not
                //if in range, add promo
            } else {
                if (promoApplied.value.promo_by_percentage) {
                    tempPromoPrice =
                        (promoApplied.value.discount_amount * planPrice) / 100;
                } else {
                    tempPromoPrice = promoApplied.value.discount_amount;
                }
            }
        }
        return tempPromoPrice;
    };

    const renderNewMenuPrice = () => {
        // need to render property price based on quantity
        var total = 0;

        if (subscriptionData !== undefined) {
            if (subscriptionData.pricing_model == "property") {
                // get each default menu and calculate property price

                Object.keys(menus.value).map((menu, index) => {
                    const addonPrice = menus.value[menu][1].addon_price;
                    const addonQuantity = menus.value[menu][1].quantity;
                    const propertyPrice = menus.value[menu][1].property_price;
                    total += addonQuantity * (addonPrice + propertyPrice);
                });
            }
        }
        return total;
    };
    const changePlanTo = (newPlanId) => {
        var tempDict = {};

        planService
            .getSinglePlan(newPlanId)
            .then((res) => {
                const newMenu = res.plans.default_menu;
                const newSubsPrice = res.plans.plan_price;
                const newPlanName = res.plans.plan_name;
                setSelectedPlan(res.plans);

                setPlanId(res.plans.plan_id);
                setPlanName(newPlanName);
                setSubsPrice(newSubsPrice);
                setPlanPrice(newSubsPrice);
                setDefaultMenuOption(res.plans.default_menu);
                setNonDefaultMenuOption(res.plans.non_default_menu);

                setPriceList(Array(newMenu.length).fill(0));
                newMenu.forEach((e) => {
                    tempDict[Object.keys(tempDict).length] = ["default", e];
                });
            })
            .then((e) => {
                setMenus({ value: tempDict });
            });
    };

    const changeChosenDefaultMenu = (index, data) => {
        var newVal = defaultMenuOption
            .filter((e) => e.addon_id == data)
            .map((obj) => {
                return ["default", obj];
            });
        //push defeault or non default menu
        if (newVal.length == 0) {
            newVal = nonDefaultMenuOption
                .filter((e) => e.addon_id == data)
                .map((obj) => {
                    return ["non_default", obj];
                });
        }
        var originalMenu = menus.value;
        if (pricingModel == "property") {
            newVal[0][1].property_price = originalMenu[index][1].property_price;
            newVal[0][1].quantity = originalMenu[index][1].quantity;
            newVal[0][1].property = originalMenu[index][1].property;
        }
        originalMenu[index] = newVal[0];
        var tempArr1 = [];
        Object.keys(originalMenu).map((data, index) => {
            const addonState = originalMenu[data][0];
            const addonData = originalMenu[data][1];
            var price = 0;

            if (addonState == "non_default") {
                price = addonData.addon_price;
            }
            tempArr1.push(price);
        });
        setPriceList(tempArr1);
        return originalMenu;
    };

    const changeProperty = (index, property) => {
        const propertyPrice = propertyModel.property
            .filter((data) => data.property_name == property)
            .map((obj) => {
                return obj.property_price;
            });

        var allMenu = menus.value;
        var currentRow = [...menus.value[index]];
        currentRow[1].property = property;
        currentRow[1].addon_price = propertyPrice[0];
        allMenu[index] = currentRow;
        return allMenu;
    };

    const renderSubsPrice = () => {
        var total = 0;
        const nonRecurringVal = renderNewNonRecurring();
        const existingPromo = renderPromoPrice();
	const menuPrice = renderNewMenuPrice();
        if (pricingModel == "property") {
	    if (custDeliveryArea === "Klang Valley" && renderNewMenuPrice() > 50) {
                 total += 0
             } else if (custDeliveryArea !== "Klang Valley" && renderNewMenuPrice() > 150) {
                 total += 0
             } else {
                 total += custPostcodePrice
             }
            total += nonRecurringVal;
            total -= existingPromo;
            // calculate all default menu quantity times addon_price
        } else {
            total = nonRecurringVal + custPostcodePrice + planPrice;
            total -= existingPromo;
        }

        return total.toFixed(2);
    };

    const currenDate = () => {
        // get difference between current date and that date then only
        // take current delivery date and plus 4, if more than they should, min date is current date

        var date = new Date();
        var deliveryDate = new Date(planDeliveryDate);
        date.setDate(date.getDate() + 4);
        const editWindowPassed = deliveryDate.getDate() - date.getDate();

        // if edit window
        if (editWindowPassed < 0) {
            return new Date(deliveryDate);
        } else {
            return new Date(date);
        }
    };

    const renderNewNonRecurring = () => {
        var totalNonRecurring = 0;
        Object.keys(menus.value).map((data) => {
            if (pricingModel == "property") {
                const addonPrice = menus.value[data][1].addon_price;
                const addonQuantity = menus.value[data][1].quantity;
                const propertyPrice = menus.value[data][1].property_price;
                totalNonRecurring +=
                    addonQuantity * (addonPrice + propertyPrice);
            } else {
                if (menus.value[data][0] == "default") {
                    totalNonRecurring += 0;
                } else {
                    const addonPrice = menus.value[data][1].addon_price;
                    const addonQuantity = menus.value[data][1].quantity;
                    const calculateNewPrice = addonPrice * addonQuantity;
                    totalNonRecurring += calculateNewPrice;
                }
            }
        });
        if (window.location.href.includes("ketoccino")) {
            defaultMenu.map((menu, index) => {
                totalNonRecurring += menu.addon_price * menu.quantity;
            });
        }
        if (selectedSideOrder.value !== undefined) {
            if (selectedSideOrder.value.length > 0) {
                selectedSideOrder.value.map((data) => {
                    totalNonRecurring += data.addon_price * data.quantity;
                });
            }
        }

        return totalNonRecurring;
    };

    const reactivateSubscription = () => {
        Swal.fire({
            title: "Are you sure you want to reactivate this subscription?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: store.getState().colorState.color,
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, reactivate it!",
        }).then((res) => {
            if (res.isConfirmed) {
                var reactivatedSubscription = {
                    ...subscriptionData,
                    subs_status: "active",
                };

                subscriptionService
                    .reactivateSubscription(
                        subId.value,
                        reactivatedSubscription
                    )
                    .then((res) => {
                        window.location.reload();
                    })
                    .catch((err) => {
                        Swal.fire({
                            title: "Error Occured!",
                            text: err.message,
                            icon: "error",
                            confirmButtonText: "OK",
                            confirmButtonColor:
                                store.getState().colorState.color,
                        });
                    });
            }
        });
    };

    const cancelSubscription = () => {
        Swal.fire({
            title: "Are you sure you want to cancel this subscription?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: store.getState().colorState.color,
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, cancel it!",
        }).then((res) => {
            if (res.isConfirmed) {
                var cancelledSubscription = {
                    ...subscriptionData,
                    subs_status: "cancelled",
                };

                subscriptionService
                    .cancelSubscription(subId.value, cancelledSubscription)
                    .then((res) => {
                        window.location.reload();
                    })
                    .catch((err) => {
                        Swal.fire({
                            title: "Error Occurs!",
                            text: err.message,
                            icon: "error",
                            confirmButtonText: "OK",
                            confirmButtonColor:
                                store.getState().colorState.color,
                        });
                    });
            }
        });
    };
    const hostname = urlAction("get_merchant");

    const [openSubsEdit, setOpenSubsEdit] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [latestInvoice, setLatestInvoice] = useState();
    const handleClickOpen = () => {
        const blockedByInvoice = latestInvoice.length > 0 ? true : false;
        // WildKombucha: check if this subscription has been billed
        // Check if this click is in between inv issued date and inv due date or not
        // if yes, then show error message
        // if no, then show edit window
        console.log(blockedByInvoice, "this is blocked by invocie");
        console.log(isShippable, "this is shippablle");
        // if (isShippable) {
        //     if (
        //         store?.getState().loggedInUserReducer.userData?.userRole !=
        //             "merchant" &&
        //         hostname == "wildkombucha" &&
        //         isShippable == true &&
        //         !blockedByInvoice
        //     ) {
        //         // check delivery date and return how many days
        //         const currentDate = new Date().getTime();
        //         // 3600 * 24 = 86400 seconds per day, * 1000 because getTime() == milliseconds

        //         const dayDiff =
        //             (new Date(planDeliveryDate).getTime() - currentDate) /
        //             (1000 * 3600 * 24);

        //         if (
        //             subscriptionData.latest_inv_status == "paid" ||
        //             subscriptionData.latest_inv_status == null
        //         ) {
        //             if (custDeliveryArea == "Klang Valley") {
        //                 // 5 days before delivery date
        //                 if (dayDiff > 5) {
        //                     setOpenSubsEdit(true);
        //                 } else {
        //                     Swal.fire({
        //                         text: "Subscription cannot be edited 5 days before delivery",
        //                         icon: "error",
        //                         confirmButtonText: "ok",
        //                         confirmButtonColor:
        //                             store.getState().colorState.color,
        //                     });
        //                 }
        //             } else {
        //                 // 10 days before delivery date
        //                 if (dayDiff > 10) {
        //                     setOpenSubsEdit(true);
        //                 } else {
        //                     Swal.fire({
        //                         text: "Subscription cannot be edited 10 days before delivery",
        //                         icon: "error",
        //                         confirmButtonText: "ok",
        //                         confirmButtonColor:
        //                             store.getState().colorState.color,
        //                     });
        //                 }
        //             }
        //         } else if (
        //             subscriptionData.latest_inv_status == "awaiting payment"
        //         ) {
        //             Swal.fire({
        //                 text: "Latest invoice is still under payment process, kindly wait 2-3 days before editing this subscription again.",
        //                 icon: "error",
        //                 confirmButtonText: "ok",
        //                 confirmButtonColor: store.getState().colorState.color,
        //             });
        //         } else if (subscriptionData.latest_inv_status == "overdue") {
        //             Swal.fire({
        //                 text: "Latest invoice has not been resolved yet, please resolve before proceed.",
        //                 icon: "error",
        //                 confirmButtonText: "ok",
        //                 confirmButtonColor: store.getState().colorState.color,
        //             });
        //         } else {
        //             if (dayDiff > 10) {
        //                 setOpenSubsEdit(true);
        //             } else {
        //                 Swal.fire({
        //                     text: "Subscription cannot be edited 10 days before delivery",
        //                     icon: "error",
        //                     confirmButtonText: "ok",
        //                     confirmButtonColor:
        //                         store.getState().colorState.color,
        //                 });
        //             }
        //         }
        //         // check postcode if its outside/inside KV, 5/10 days respectively
        //         // check if subs latest invoice has been resolved
        //     } else if (
        //         store?.getState().loggedInUserReducer.userData?.userRole !=
        //             "merchant" &&
        //         hostname == "pichaeats"
        //     ) {
        //         var ind = -1;
        //         var dDate = planDeliveryDate;
        //         ind = dDate.indexOf(" ");
        //         if (ind != -1) {
        //             dDate = dDate.substr(0, ind) + "T" + dDate.substr(ind + 1);
        //         }
        //         ind = dDate.indexOf("+");
        //         if (ind != -1) {
        //             dDate = dDate.substr(0, ind);
        //         }
        //         var diffTime = new Date(dDate) - new Date();
        //         var dayDiff = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        //         if (dayDiff < 0 || dayDiff > 4) {
        //             setOpenSubsEdit(true);
        //         } else {
        //             Swal.fire({
        //                 text: "Subscription cannot be edited 4 days before delivery date, please contact PichaEats at 018-2894353",
        //                 icon: "error",
        //                 confirmButtonText: "OK",
        //                 confirmButtonColor: store.getState().colorState.color,
        //             });
        //         }
        //     } else if (
        //         store?.getState().loggedInUserReducer.userData?.userRole ===
        //         "merchant"
        //     ) {
        //         setOpenSubsEdit(true);
        //     } else {
        //         setOpenSubsEdit(true);
        //     }
        // } else if (isShippable == false) {
        //     setOpenSubsEdit(true);
        // } else {
        //     setOpenSubsEdit(true);
        // }
        setOpenSubsEdit(true);
    };

    const [editVajraAdditionalDetails, setEditVajraAdditionalDetails] =
        useState(false);

    const editAdditionalDetails = () => {
        setEditVajraAdditionalDetails(!editVajraAdditionalDetails);
    };

    const handleClose = () => {
        setOpenSubsEdit(false);
    };

    const updateVajraAdditionalDetails = () => {
        const vajraEditDatabody = {
            vajra_dedication_note: vajraDedicationNote.value,
            vajra_ref_code: vajraRefCode,
            vajra_gift_message: vajraGiftMessage.value,
            vajra_recipient_name: vajraRecipientName,
            vajra_recipient_email: vajraRecipientEmail.value,
            vajra_this_a_gift: vajraIsGift,
        };

        if (vajraIsGift == false) {
            vajraEditDatabody.vajra_gift_message = "";
            vajraEditDatabody.vajra_recipient_email = "";
            vajraEditDatabody.vajra_recipient_name = "";
        }
        subscriptionService
            .editSubscription(subscriptionData.subs_id, vajraEditDatabody)
            .then((e) => {
                Swal.fire({
                    title: "Success!",
                    icon: "success",
                    text: "Subscription has been changed. The page will refresh.",
                    confirmButtonText: "Ok",
                    confirmButtonColor: store.getState().colorState.color,
                })
                    .then((res) => {
                        window.location.reload();
                    })
                    .catch((err) => {
                        console.error("update listing error");
                    });
            })
            .catch((e) => {
                console.error(e, "erro");
            });
    };

    const renderDatabody = () => {
        var dataBody = {};
        var finalPrice = renderSubsPrice();
        var tempDefaultMenu = [];
        var tempNonDefaultMenu = [];
        Object.keys(menus.value).map((data, index) => {
            if (menus.value[index][0] == "default") {
                tempDefaultMenu.push(menus.value[index][1]);
            } else if (menus.value[index][0] == "non_default") {
                tempNonDefaultMenu.push(menus.value[index][1]);
            }
        });
        // check quantity using both checked value

        if (
            store?.getState().loggedInUserReducer.userData?.userRole ==
            "merchant"
        ) {
            if (tabState == "plan") {
                dataBody = {
                    ...dataBody,
                    plan_id: planId,
                    plan_name: planName,
                    subs_price: finalPrice,
                    //TODO: need to set the new prices here
                    delivery_fee: deliveryFee,
                    // extra_charge: extraCharge,
                    plan_price: planPrice,
                    default_menu: tempDefaultMenu,
                    non_default_menu: tempNonDefaultMenu,
                };
            }
        } else {
            dataBody = {
                ...dataBody,
                plan_name: planName,
                subs_price: finalPrice,
                delivery_fee: deliveryFee,
                // extra_charge: extraCharge,
                plan_price: planPrice,
                default_menu: tempDefaultMenu,
                non_default_menu: tempNonDefaultMenu,
            };
        }
        if (selectedSideOrder !== undefined) {
            if (selectedSideOrder.value.length > 0) {
                // add to databody
                dataBody = {
                    ...dataBody,
                    side_order: selectedSideOrder.value,
                };
            }
        }
        return dataBody;
    };

    const changeSubscription = () => {
        //pass plan name
        //charges
        //deliverdate
        var dataBody = {};
        dataBody = renderDatabody();
        if (tabState == "plan") {
            console.log(selectedPlan, "this is the selected plan");
            if (window.location.href.includes("ketoccino")) {
                console.log(defaultMenu, "this is the default menu");
                dataBody = {
                    ...dataBody,
                    default_menu: defaultMenu,
                };
                subscriptionService
                    .editSubscription(subId.value, dataBody)
                    .then((e) => {
                        Swal.fire({
                            title: "Success!",
                            icon: "success",
                            text: "Subscription has been changed. The page will refresh.",
                            confirmButtonText: "OK",
                            confirmButtonColor:
                                store.getState().colorState.color,
                        })
                            .then((res) => {
                                window.location.reload();
                            })
                            .catch((err) => {
                                console.error("update listing error");
                            });
                    })
                    .catch((e) => {
                        console.error(e, "erro");
                    });
            }
            // need check menu restrictions here instead of max_menu_selection
            if (selectedPlan.menu_restrictions.restriction_type === "all") {
                var menuQuantity = 0;
                // check quantity here
                dataBody.default_menu.map((e) => {
                    menuQuantity += e.quantity;
                });
                dataBody.non_default_menu.map((e) => {
                    menuQuantity += e.quantity;
                });
                subscriptionService
                    .editSubscription(subId.value, dataBody)
                    .then((e) => {
                        Swal.fire({
                            title: "Success!",
                            icon: "success",
                            text: "Subscription has been changed. The page will refresh.",
                            confirmButtonText: "OK",
                            confirmButtonColor:
                                store.getState().colorState.color,
                        })
                            .then((res) => {
                                window.location.reload();
                            })
                            .catch((err) => {
                                console.error("update listing error");
                            });
                    })
                    .catch((e) => {
                        console.error(e, "erro");
                    });
                // if (
                //     menuQuantity !==
                //     selectedPlan.menu_restrictions.restriction_value.parseInt(
                //         default_non_menu
                //     )
                // ) {
                //     console.log("not equal to menu restrctions");
                // } else if (
                //     menuQuantity ===
                //     selectedPlan.menu_restrictions.restriction_value.parseInt(
                //         default_non_menu
                //     )
                // ) {
                //     console.log("equal to menu restrctions");
                // }

                // if (menuQuantity == selectedPlan.max_menu_selection) {
                //     dataBody.default_menu = dataBody.default_menu.filter(
                //         (e) => e.quantity != 0
                //     );
                //     dataBody.non_default_menu =
                //         dataBody.non_default_menu.filter(
                //             (e) => e.quantity != 0
                //         );
                //     subscriptionService
                //         .editSubscription(subId.value, dataBody)
                //         .then((e) => {
                //             Swal.fire({
                //                 title: "Success!",
                //                 icon: "success",
                //                 text: "Subscription has been changed. The page will refresh.",
                //                 confirmButtonText: "Ok",
                //                 confirmButtonColor:
                //                     store.getState().colorState.color,
                //             })
                //                 .then((res) => {
                //                     window.location.reload();
                //                 })
                //                 .catch((err) => {
                //                     console.error("update listing error");
                //                 });
                //         })
                //         .catch((e) => {
                //             console.error(e, "erro");
                //         });
                // }
                // no max _menu
            } else {
                subscriptionService
                    .editSubscription(subId.value, dataBody)
                    .then((e) => {
                        Swal.fire({
                            title: "Success!",
                            icon: "success",
                            text: "Subscription has been changed. The page will refresh.",
                            confirmButtonText: "OK",
                            confirmButtonColor:
                                store.getState().colorState.color,
                        })
                            .then((res) => {
                                window.location.reload();
                            })
                            .catch((err) => {
                                console.error("update listing error");
                            });
                    })
                    .catch((e) => {
                        console.error(e, "erro");
                    });
            }
        } else if (tabState == "delivery") {
            dataBody = {
                ...dataBody,
                delivery_time: changeDeliveryTime,
                delivery_date: newPlanDeliveryDate,
            };
            subscriptionService
                .editSubscription(subId.value, dataBody)
                .then((e) => {
                    Swal.fire({
                        title: "Success!",
                        icon: "success",
                        text: "Subscription has been changed. The page will refresh.",
                        confirmButtonText: "Ok",
                        confirmButtonColor: store.getState().colorState.color,
                    })
                        .then((res) => {
                            window.location.reload();
                        })
                        .catch((err) => {
                            console.error("update listing error");
                        });
                })
                .catch((e) => {
                    console.error(e, "erro");
                });
        }

        //catch
    };

    const renderSelectedPlanInformation = () => {
        return (
            <Grid className="mt-4" item xs={12}>
                <FormControl component="fieldset" fullWidth>
                    <FormLabel className="text-left" component="legend">
                        Plans
                    </FormLabel>
                    <FormControlLabel
                        disabled
                        control={
                            <Checkbox
                                color="primary"
                                checked={true}
                                name="plan"
                            />
                        }
                        label={
                            <div
                                className="justify-content-between"
                                style={{ display: "flex", minWidth: "550px" }}
                            >
                                <div
                                    className="d-flex align-items-center justify-content-between"
                                    style={{ minWidth: "468px" }}
                                >
                                    <div
                                        className="text-left"
                                        style={{ width: "350px" }}
                                    >{`${selectedPlan.plan_name}`}</div>
                                    {/* {selectedPlan.plan_type ==
                                    "fixed_quantity" ? (
                                        <ErrorTooltip
                                            open={!!planQuantity.error}
                                            title={planQuantity.error}
                                        >
                                            <TextField
                                                disabled
                                                className="plan-quantity-input"
                                                label="Quantitys"
                                                type="text"
                                                value={planQuantity.value}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                inputProps={{
                                                    className:
                                                        "addon-quantity-input",
                                                }}
                                                variant="outlined"
                                            />
                                        </ErrorTooltip>
                                    ) : (
                                        <div
                                            style={{
                                                width: "88px",
                                                height: "100%",
                                            }}
                                        ></div>
                                    )} */}
                                </div>

                                <Button
                                    onClick={showPlanPopupModal.bind(
                                        this,
                                        selectedPlan.plan_id
                                    )}
                                    style={{
                                        fontSize: "10px",
                                        backgroundColor: "black",
                                        color: "white",
                                    }}
                                >
                                    View Plan Details &nbsp;
                                </Button>
                            </div>
                        }
                    />
                </FormControl>
            </Grid>
        );
    };
    // const removeMenuDuplicates = () =>{
    //     menus.map((
    // }
    const renderSelectedAddonInformation = () => {
        return (
            <Grid item xs={12}>
                <FormControl component="fieldset" fullWidth>
                    <FormLabel className="text-left" component="legend">
                        Menu
                    </FormLabel>
                    {/* print selectedaddons */}
                    {selectedAddons.value.length > 0 ? (
                        selectedAddons.value.map((addonInfo, i) => (
                            <FormControlLabel
                                key={i}
                                disabled
                                control={
                                    <Checkbox
                                        color="primary"
                                        checked={true}
                                        name="plan"
                                    />
                                }
                                label={
                                    <div
                                        className="justify-content-between"
                                        style={{
                                            display: "flex",
                                            minWidth: "550px",
                                        }}
                                    >
                                        <div
                                            className="d-flex align-items-center justify-content-between"
                                            style={{ minWidth: "468px" }}
                                        >
                                            <div
                                                className="text-left"
                                                style={{ width: "350px" }}
                                            >{`${addonInfo.addon_name}`}</div>
                                            {/* {addonInfo.addon_type ==
                                            "fixed_quantity" ? (
                                                <TextField
                                                    disabled
                                                    className="addon-quantity-wrapper"
                                                    label="Quantity"
                                                    type="text"
                                                    value={addonInfo.quantity}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    inputProps={{
                                                        className:
                                                            "addon-quantity-input",
                                                    }}
                                                    variant="outlined"
                                                />
                                            ) : (
                                                ""
                                            )} */}
                                        </div>
                                        <Button
                                            style={{
                                                fontSize: "10px",
                                                backgroundColor: "black",
                                                color: "white",
                                            }}
                                            onClick={showAddonPopupModal.bind(
                                                this,
                                                addonInfo.addon_id
                                            )}
                                        >
                                            View Menu Detail
                                        </Button>
                                    </div>
                                }
                            />
                        ))
                    ) : (
                        <FormLabel
                            className="text-left mt-2"
                            style={{ fontSize: "12px" }}
                        >
                            No Addon Selected
                        </FormLabel>
                    )}
                </FormControl>
            </Grid>
        );
    };

    const renderMenuPrice = (index) => {
        // Conditions
        // 1. if menu default and quantity > than one, then multiple that amount minus 1
        // 2. if menu default and quantity = 1, price should be 0.00
        // 3. else, multiple as usual
        // all return an integer value
        const currentPrice = menus.value[index][1].addon_price;
        const menuType = menus.value[index][0];
        const menuQuantity = menus.value[index][1].quantity;
        var newPrice = 0;
        if (subscriptionData.pricing_model == "property") {
            const propertyPrice = menus.value[index][1].property_price;
            newPrice = (propertyPrice + currentPrice) * menuQuantity;
            return newPrice.toFixed(2);
        } else {
            if (window.location.href.includes("ketoccino")) {
                newPrice = currentPrice * menuQuantity;
                return newPrice.toFixed(2);
            }
            if (menuType == "default" && parseInt(menuQuantity) > 1) {
                return newPrice.toFixed(2);
            } else if (menuType == "default" && parseInt(menuQuantity) == 1) {
                return newPrice.toFixed(2);
            } else {
                newPrice = currentPrice * menuQuantity;
                return newPrice.toFixed(2);
            }
        }
    };

    const renderFlatFee = () => {
        //  map through default menu and return "-"
        //  map through non default menu and add price
        var tempMenu = [];
        if (window.location.href.includes("ketoccino")) {
            defaultMenu.map((menu, index) => {
                tempMenu.push(
                    `RM${(menu.addon_price * menu.quantity).toFixed(2)}`
                );
            });
        } else {
            defaultMenu.map((menu, i) => {
                tempMenu.push("-");
            });
        }

        nonDefaultMenu.map((menu, i) => {
            tempMenu.push(`RM${(menu.addon_price * menu.quantity).toFixed(2)}`);
        });

        return tempMenu;
    };

    const renderPostcode = () =>{
    	if (window.location.href.includes("wildkombucha")){
	     if (custDeliveryArea === "Klang Valley" && renderNewMenuPrice() > 50) {
		 return "0.00";
             } else if (custDeliveryArea !== "Klang Valley" && renderNewMenuPrice() > 150) {
                 return "0.00";
             } else {
                 return custPostcodePrice.toFixed(2)
             }
	} else {
	    return custPostcodePrice.toFixed(2);
	}
    }

    const renderEstimatedPriceForSubscription = () => {
        return (
            <Grid item xs={12} className="mt-5">
                <TableContainer component={Paper}>
                    <Table aria-label="spanning table">
                        <TableHead>
                            <TableRow></TableRow>
                            <TableRow>
                                <TableCell> Subscription Pricing</TableCell>
                                <TableCell></TableCell>
                                <TableCell></TableCell>

                                <TableCell align="right"> </TableCell>
                                <TableCell align="right">Price</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {priceTableRow.plan?.recurring.length > 0 ? (
                                <TableRow>
                                    <TableCell style={{ verticalAlign: "top" }}>
                                        <span className="font-weight-bold">
                                            Plan Name
                                        </span>
                                        <br />
                                        {planName}
                                    </TableCell>
                                    <TableCell></TableCell>

                                    <TableCell></TableCell>

                                    <TableCell
                                        align="right"
                                        style={{ verticalAlign: "top" }}
                                    >
                                        <br />
                                    </TableCell>
                                    <TableCell
                                        align="right"
                                        style={{ verticalAlign: "top" }}
                                    >
                                        <br />
                                        {/* render plan price based on pricing model */}
                                        {subscriptionData.pricing_model ==
                                            "property" || planPrice == undefined
                                            ? "-"
                                            : `RM${planPrice.toFixed(2)}`}
                                    </TableCell>
                                </TableRow>
                            ) : (
                                <TableRow></TableRow>
                            )}

                            {defaultMenu.length > 0 ? (
                                <TableRow>
                                    <TableCell style={{ verticalAlign: "top" }}>
                                        <span className="font-weight-bold">
                                            Monthly subscription plan includes:
                                        </span>
                                        <br />
                                        {defaultMenu.map((index, i) => (
                                            <span>
                                                {index.addon_name
                                                    ? index.addon_name
                                                    : index.addon_desc}{" "}
                                                x {index.quantity}
                                                <br />
                                            </span>
                                        ))}

                                        {nonDefaultMenu
                                            ? nonDefaultMenu.map((index, i) => (
                                                  <span>
                                                      {index.addon_name
                                                          ? index.addon_name
                                                          : index.addon_desc}

                                                      <br />
                                                  </span>
                                              ))
                                            : ""}
                                    </TableCell>

                                    <TableCell></TableCell>

                                    <TableCell></TableCell>
                                    <TableCell
                                        align="right"
                                        style={{ verticalAlign: "top" }}
                                    >
                                        <br />
                                    </TableCell>
                                    <TableCell
                                        align="right"
                                        style={{ verticalAlign: "top" }}
                                    >
                                        <br />
                                        {/* render plan price based on pricing model */}
                                        {/* TODO: use switch case */}
                                        {pricingModel == "property"
                                            ? defaultMenu.map((index, i) => (
                                                  <span>
                                                      RM
                                                      {(
                                                          index.quantity *
                                                          (index.addon_price +
                                                              index.property_price)
                                                      ).toFixed(2)}
                                                      <br />
                                                  </span>
                                              ))
                                            : renderFlatFee().map(
                                                  (index, i) => (
                                                      <span>
                                                          {index}
                                                          <br />
                                                      </span>
                                                  )
                                              )}
                                    </TableCell>
                                </TableRow>
                            ) : null}
                            <TableRow></TableRow>

                            {!!selectedPlan.is_shippable &&
                            !window.location.href.includes("vajrasecrets") ? (
                                <TableRow>
                                    <TableCell>
                                        <span className="font-weight-bold">
                                            Delivery
                                        </span>{" "}
                                        <br />
                                        Delivery Fee
                                    </TableCell>
                                    <TableCell></TableCell>

                                    <TableCell></TableCell>
                                    <TableCell></TableCell>
                                    {subscriptionData !== undefined ? (
                                        <TableCell align="right">
                                            <br /> RM
                                            {renderPostcode()}
                                        </TableCell>
                                    ) : (
                                        ""
                                    )}
                                </TableRow>
                            ) : null}
                            {selectedPlan.pricing_model === "flat_fee" ? (
                                <>
                                    <TableRow>
                                        <TableCell>Plan Price</TableCell>
                                        <TableCell></TableCell>

                                        <TableCell></TableCell>
                                        <TableCell></TableCell>
                                        {subscriptionData !== undefined ? (
                                            <TableCell align="right">
                                                RM
                                                {planPrice !== undefined
                                                    ? planPrice.toFixed(2)
                                                    : ""}
                                            </TableCell>
                                        ) : (
                                            ""
                                        )}
                                    </TableRow>
                                </>
                            ) : null}
                            {subscriptionData?.side_order.length > 0 ? (
                                <TableRow>
                                    <TableCell style={{ verticalAlign: "top" }}>
                                        <span className="font-weight-bold">
                                            Add-on items (monthly)
                                        </span>
                                        <br />
                                        {subscriptionData.side_order
                                            ? selectedSideOrder.value.map(
                                                  (index, i) => (
                                                      <span>
                                                          {index.addon_name
                                                              ? index.addon_name
                                                              : index.addon_desc}{" "}
                                                          x {index.quantity}
                                                          <br />
                                                      </span>
                                                  )
                                              )
                                            : ""}
                                    </TableCell>

                                    <TableCell></TableCell>

                                    <TableCell></TableCell>
                                    <TableCell
                                        align="right"
                                        style={{ verticalAlign: "top" }}
                                    >
                                        <br />
                                    </TableCell>
                                    <TableCell
                                        align="right"
                                        style={{ verticalAlign: "top" }}
                                    >
                                        <br />
                                        {selectedSideOrder.value.map(
                                            (price, index) => {
                                                return (
                                                    <span>
                                                        RM
                                                        {(
                                                            price.addon_price *
                                                            price.quantity
                                                        ).toFixed(2)}
                                                        <br />
                                                    </span>
                                                );
                                            }
                                        )}
                                    </TableCell>
                                </TableRow>
                            ) : (
                                ""
                            )}
                            <TableRow>
                                <TableCell>Total</TableCell>
                                <TableCell></TableCell>

                                <TableCell></TableCell>
                                <TableCell></TableCell>
                                {subscriptionData !== undefined ? (
                                    <TableCell align="right">
                                        RM
                                        {planPrice !== undefined
                                            ? renderSubsPrice()
                                            : ""}
                                    </TableCell>
                                ) : (
                                    ""
                                )}
                            </TableRow>

                            {promoApplied.value !== null ? (
                                renderPromoInfo()
                            ) : (
                                <TableRow></TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        );
    };
    const renderPromoInfo = () => {
        if (promoApplied.value.promo_status == "active") {
            return (
                <TableRow className="bg-success">
                    <TableCell className="font-weight-bold text-white">
                        Promotion applied
                    </TableCell>
                    <TableCell></TableCell>

                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell colSpan={2} align="right" className="bg-success">
                        {renderAppliedPromoInfo()}
                    </TableCell>
                </TableRow>
            );
        }
    };

    const renderTotalRecurringValue = () => {
        var totalRecurringValue = 0;
        var containUsageBasedItems = false;

        priceTableRow.plan?.recurring.forEach((item) => {
            if (item.itemType == "fixed_quantity") {
                totalRecurringValue += item.price;
            } else {
                containUsageBasedItems = true;
            }
        });

        priceTableRow.addons?.recurring.forEach((item) => {
            if (item.itemType == "fixed_quantity") {
                totalRecurringValue += item.price;
            } else {
                containUsageBasedItems = true;
            }
        });

        return (
            <span className="font-weight-bold">
                RM {totalRecurringValue.toFixed(2)}
                <br />
                {containUsageBasedItems ? (
                    <span>
                        + Usage-Based Item
                        <br />
                    </span>
                ) : (
                    ""
                )}
                {!!priceTableRow.tax ? (
                    <span>
                        +{" "}
                        {`${priceTableRow.tax.tax_name.toUpperCase()} (${
                            priceTableRow.tax.tax_rate * 100
                        }%)`}
                    </span>
                ) : (
                    ""
                )}
            </span>
        );
    };

    const renderTotalNonRecurringValue = () => {
        var menuPrice = 0;

        // pricing model
        // 1. flat_fee: display non default price
        // 2. property: display default menu price
        if (subscriptionData.pricing_model == "flat_fee") {
            // render non default menu
            menuPrice = 0;
        } else if (subscriptionData.pricing_model == "property") {
            const propertyArr = subscriptionData.property_model.property;

            defaultMenu.map((menu) => {
                const propertyPrice = propertyArr
                    .filter((e) => e.property_name == menu.property)
                    .map((obj) => {
                        return obj.property_price;
                    });

                if (menu.quantity !== undefined) {
                    menuPrice += propertyPrice * menu.quantity;
                } else {
                    menuPrice += propertyPrice;
                }
            });
        }

        return (
            <span className="font-weight-bold">
                RM{menuPrice.toFixed(2)}
                <br />
            </span>
        );
    };

    const renderPromoDurationInfo = () => {
        switch (promoApplied.value.duration_type) {
            case "forever":
                return "Promo valid throughout the subscriptions";
                break;
            case "one_time":
                return "Promo valid for the first subscription cycle only";
                break;
            case "limited":
                return `Promo valid for ${promoApplied.value.duration} subscription cycle once the subscription begin`;
                break;
        }
    };

    const renderAppliedPromoInfo = () => {
        return (
            <span className="text-white">
                <span>Promo code({promoApplied.value.promo_code})</span>
                <br />
                {!!promoApplied.value.promo_by_percentage ? (
                    <span>
                        {promoApplied.value.discount_amount}% off{" "}
                        {!!promoApplied.value.promo_cap ? (
                            <span>
                                (Capped At RM{promoApplied.value.promo_cap})
                            </span>
                        ) : (
                            ""
                        )}
                    </span>
                ) : (
                    <span>RM{promoApplied.value.discount_amount} off</span>
                )}
                <br />
                <br />
                <span style={{ fontSize: "12px" }}>
                    ** {renderPromoDurationInfo()}
                </span>
            </span>
        );
    };

    const showAddonPopupModal = (addonId) => {
        setInfoModal({
            isOpened: true,
            infoType: "addon-view",
            infoId: addonId,
        });
    };

    const showPlanPopupModal = (planId) => {
        setInfoModal({ isOpened: true, infoType: "plan-view", infoId: planId });
    };

    //First time initialization
    useEffect(() => {
        if (!!match.params.id) fetchSubscriptionWithId(match.params.id);
        // get invoice with subs id here
        if (!!match.params.id) fetchInvoiceWithSubsId(match.params.id);
    }, []);
    const fetchInvoiceWithSubsId = (subsId) => {
        setIsLoading(true);
        invoiceService
            .getInvoiceBySubsId(subsId)
            .then((res) => {
                setIsLoading(false);
                // filter date that is after issue date and before due date
                const filteredInvoice = res.filter((invoice) => {
                    const issueDate = moment(invoice.inv_issue_date);
                    const dueDate = moment(invoice.inv_delivery_date);
                    const now = moment();
                    return now.isAfter(issueDate) && now.isBefore(dueDate);
                });
                setLatestInvoice(filteredInvoice);
            })
            .catch((err) => {
                setIsLoading(false);
                console.error(err);
            });
    };

    //Listener When SelectedPlan and selectedAddons changes
    useEffect(() => {
        var pricingForSelectedPlan = { one_off: [], recurring: [] };
        var pricingForSelectedAddons = { one_off: [], recurring: [] };
        var tax = null;

        //Update the pricing info
        if (!!selectedPlan) {
            var itemInfo = {
                itemName: selectedPlan.plan_name,
                pricingType: selectedPlan.pricing_model,
                itemType: selectedPlan.plan_type,
            };
            switch (selectedPlan.plan_type) {
                case "usage_based":
                    pricingForSelectedPlan = {
                        ...pricingForSelectedPlan,
                        recurring: [
                            ...pricingForSelectedPlan.recurring,
                            {
                                ...itemInfo,
                                quantity: "To Be Defined",
                                price: "-",
                            },
                        ],
                    };
                    break;
                case "fixed_quantity":
                    switch (selectedPlan.pricing_model) {
                        case "flat_fee":
                            itemInfo = {
                                ...itemInfo,
                                quantity: 1,
                                price: selectedPlan.plan_price,
                            };
                            break;
                        case "per_unit":
                            itemInfo = {
                                ...itemInfo,
                                quantity: planQuantity.value,
                                price:
                                    selectedPlan.per_unit_amt.base *
                                    planQuantity.value,
                            };
                            break;
                        case "tiered":
                            var remainingQuantity = parseFloat(
                                planQuantity.value
                            );
                            var tieredModel = selectedPlan.tiered_model;
                            var totalPrice = 0;

                            tieredModel.tier.forEach((tier, i) => {
                                //Price for each Step
                                var priceForThisTier = 0;

                                if (tier.ending_unit != null) {
                                    var endingUnit = parseFloat(
                                        tier.ending_unit
                                    );
                                    var unitInThisTier =
                                        i == 0
                                            ? endingUnit
                                            : endingUnit -
                                              parseFloat(
                                                  tieredModel.tier[i - 1]
                                                      .ending_unit
                                              );
                                    if (remainingQuantity > unitInThisTier) {
                                        priceForThisTier =
                                            unitInThisTier *
                                            parseFloat(tier.price_per_unit);
                                        remainingQuantity -= unitInThisTier;
                                    } else {
                                        unitInThisTier = remainingQuantity;

                                        priceForThisTier =
                                            unitInThisTier *
                                            parseFloat(tier.price_per_unit);
                                        remainingQuantity -= unitInThisTier;
                                    }
                                } else {
                                    var unitInThisTier = remainingQuantity;

                                    priceForThisTier =
                                        unitInThisTier *
                                        parseFloat(tier.price_per_unit);
                                    remainingQuantity -= unitInThisTier;
                                }

                                totalPrice += priceForThisTier;
                                if (remainingQuantity == 0) {
                                    return;
                                }
                            });

                            itemInfo = {
                                ...itemInfo,
                                quantity: planQuantity.value,
                                price: totalPrice,
                            };
                            break;
                        case "volume":
                            var tieredModel = selectedPlan.tiered_model;
                            var quantity = parseFloat(planQuantity.value);
                            var totalPrice = 0;

                            tieredModel.tier.forEach((tier, i) => {
                                var startingUnit = parseFloat(
                                    tier.starting_unit
                                );

                                if (quantity >= startingUnit) {
                                    //if quantity is in this tier
                                    totalPrice =
                                        quantity *
                                        parseFloat(tier.price_per_unit); //replace the price
                                }
                            });

                            itemInfo = {
                                ...itemInfo,
                                quantity: planQuantity.value,
                                price: totalPrice,
                            };

                            break;
                        case "stairstep":
                            var tieredModel = selectedPlan.tiered_model;
                            var quantity = parseFloat(planQuantity.value);
                            var totalPrice = 0;

                            tieredModel.tier.forEach((tier, i) => {
                                //Price for each Step
                                var startingUnit = parseFloat(
                                    tier.starting_unit
                                );

                                if (quantity >= startingUnit) {
                                    totalPrice = parseFloat(
                                        tier.price_per_unit
                                    );
                                }
                            });

                            itemInfo = {
                                ...itemInfo,
                                quantity: quantity.value,
                                price: totalPrice,
                            };

                            break;
                    }

                    pricingForSelectedPlan = {
                        ...pricingForSelectedPlan,
                        recurring: [
                            ...pricingForSelectedPlan.recurring,
                            {
                                ...itemInfo,
                            },
                        ],
                    };
                    break;
                default:
            }

            if (selectedPlan.setup_fee > 0) {
                pricingForSelectedPlan = {
                    ...pricingForSelectedPlan,
                    one_off: [
                        ...pricingForSelectedPlan.one_off,
                        {
                            itemName: "Setup Fee",
                            itemType: "fixed_quantity",
                            pricingType: "flat_fee",
                            quantity: 1,
                            price: selectedPlan.setup_fee,
                        },
                    ],
                };
            }

            if (!!selectedPlan.tax) {
                tax = selectedPlan.tax;
            }
        }

        if (selectedAddons.value.length > 0) {
            //setSubscriptionPricingInfo
            selectedAddons.value.forEach((addon) => {
                var itemInfo = {
                    itemName: addon.addon_name,
                    pricingType: addon.pricing_model,
                    itemType: addon.addon_type,
                };
                switch (addon.addon_type) {
                    case "usage_based":
                        itemInfo = {
                            ...itemInfo,
                            quantity: "To Be Defined",
                            price: "-",
                        };
                        break;
                    case "fixed_quantity":
                        switch (addon.pricing_model) {
                            case "flat_fee":
                                itemInfo = {
                                    ...itemInfo,
                                    quantity: 1,
                                    price: addon.addon_price,
                                };
                                break;
                            case "per_unit":
                                itemInfo = {
                                    ...itemInfo,
                                    quantity: addon.quantity,
                                    price: addon.addon_price * addon.quantity,
                                };
                                break;
                            case "tiered":
                                var remainingQuantity = parseFloat(
                                    addon.quantity
                                );
                                var tieredModel = addon.tiered_model;
                                var totalPrice = 0;

                                tieredModel.tier.forEach((tier, i) => {
                                    //Price for each Step
                                    var priceForThisTier = 0;

                                    if (tier.ending_unit != null) {
                                        var endingUnit = parseFloat(
                                            tier.ending_unit
                                        );
                                        var unitInThisTier =
                                            i == 0
                                                ? endingUnit
                                                : endingUnit -
                                                  parseFloat(
                                                      tieredModel.tier[i - 1]
                                                          .ending_unit
                                                  );
                                        if (
                                            remainingQuantity > unitInThisTier
                                        ) {
                                            priceForThisTier =
                                                unitInThisTier *
                                                parseFloat(tier.price_per_unit);
                                            remainingQuantity -= unitInThisTier;
                                        } else {
                                            unitInThisTier = remainingQuantity;

                                            priceForThisTier =
                                                unitInThisTier *
                                                parseFloat(tier.price_per_unit);
                                            remainingQuantity -= unitInThisTier;
                                        }
                                    } else {
                                        var unitInThisTier = remainingQuantity;

                                        priceForThisTier =
                                            unitInThisTier *
                                            parseFloat(tier.price_per_unit);
                                        remainingQuantity -= unitInThisTier;
                                    }

                                    totalPrice += priceForThisTier;
                                    if (remainingQuantity == 0) {
                                        return;
                                    }
                                });

                                itemInfo = {
                                    ...itemInfo,
                                    quantity: addon.quantity,
                                    price: totalPrice,
                                };
                                break;
                            case "volume":
                                var tieredModel = addon.tiered_model;
                                var addonQuantity = parseFloat(addon.quantity);
                                var totalPrice = 0;

                                tieredModel.tier.forEach((tier, i) => {
                                    var startingUnit = parseFloat(
                                        tier.starting_unit
                                    );

                                    if (addonQuantity >= startingUnit) {
                                        //if quantity is in this tier
                                        totalPrice =
                                            addonQuantity *
                                            parseFloat(tier.price_per_unit); //replace the price
                                    }
                                });

                                itemInfo = {
                                    ...itemInfo,
                                    quantity: addon.quantity,
                                    price: totalPrice,
                                };

                                break;
                            case "stairstep":
                                var tieredModel = addon.tiered_model;
                                var addonQuantity = parseFloat(addon.quantity);
                                var totalPrice = 0;

                                tieredModel.tier.forEach((tier, i) => {
                                    //Price for each Step
                                    var startingUnit = parseFloat(
                                        tier.starting_unit
                                    );

                                    if (addonQuantity >= startingUnit) {
                                        totalPrice = parseFloat(
                                            tier.price_per_unit
                                        );
                                    }
                                });

                                itemInfo = {
                                    ...itemInfo,
                                    quantity: addon.quantity,
                                    price: totalPrice,
                                };

                                break;
                        }
                        break;
                    default:
                }

                if (addon.addon_recurrence == "recurring") {
                    pricingForSelectedAddons = {
                        ...pricingForSelectedAddons,
                        recurring: [
                            ...pricingForSelectedAddons.recurring,
                            itemInfo,
                        ],
                    };
                } else {
                    pricingForSelectedAddons = {
                        ...pricingForSelectedAddons,
                        one_off: [
                            ...pricingForSelectedAddons.one_off,
                            itemInfo,
                        ],
                    };
                }

                return itemInfo;
            });
        }

        const finalPricingInfo = {
            plan: pricingForSelectedPlan,
            addons: pricingForSelectedAddons,
            tax: tax,
        };

        setPriceTableRow(finalPricingInfo);
    }, [selectedPlan, selectedAddons, planQuantity]);

    const changeQuantity = (newQuantity, index) => {
        var allMenu = menus.value;
        var currentRow = [...menus.value[index]];
        console.log(currentRow[1].quantity, "current row quantity");
        if (window.location.href.includes("ketoccino")) {
            if (newQuantity < currentRow[1].quantity) {
                currentRow[1].quantity = parseInt(currentRow[1].quantity) - 2;
            } else {
                currentRow[1].quantity = parseInt(currentRow[1].quantity) + 2;
            }
        } else {
            currentRow[1].quantity = parseInt(newQuantity);
        }

        allMenu[index] = currentRow;

        return allMenu;
    };

    const isLoggedIn = useSelector(
        (state) => state.loggedInUserReducer.userData
    );

    const redirectToSubscriptionListingPage = () => {
        if (isLoggedIn.userRole == "merchant") {
            history.push("/admin/subscription");
        } else {
            history.push("/customer/subscription");
        }
    };

    const renderInfoModalTitle = () => {
        switch (infoModal.infoType) {
            case "addon-view":
                return "View Addon Details";
            case "plan-view":
                return "View Plan Details";
            default:
                return "Modal Dialog";
        }
    };
    const formatTime = (time) => {
        const currentDate = new Date(time);
        const currentTimeOffset = currentDate.getTimezoneOffset();
        const timeOffsetToHours = Math.abs(currentTimeOffset / 60);
        currentDate.setHours(currentDate.getHours() - timeOffsetToHours + 8);

        var hours = currentDate.getHours();
        var minutes = currentDate.getMinutes();
        var ampm = hours >= 12 ? "pm" : "am";
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        minutes = minutes < 10 ? "0" + minutes : minutes;
        var strTime = hours + ":" + minutes + " " + ampm;
        return strTime;
    };

    const renderInfoModalContent = () => {
        switch (infoModal.infoType) {
            case "addon-view":
                return (
                    <AddonEditCreate
                        cleanBackground={true}
                        readonly={true}
                        match={{ params: { id: infoModal.infoId } }}
                    ></AddonEditCreate>
                );
            case "plan-view":
                return (
                    <PlanForm
                        cleanBackground={true}
                        readonly={true}
                        match={{ params: { id: infoModal.infoId } }}
                    ></PlanForm>
                );
            default:
                return <div></div>;
        }
    };

    return (
        <Grid container>
            <Grid
                item
                className="p-3 mt-5"
                align="left"
                xs={12}
                md={12}
                lg={12}
            >
                <div className="container-fluid addon-container">
                    <div className="container addon-form-container">
                        <h2 className="text-left mt-3 p-4">
                            Subscription Details
                        </h2>
                        <Grid item xs={12} md={12} lg={12}>
                            <Button
                                className=" ml-4"
                                style={{
                                    marginLeft: "20px",
                                    backgroundColor:
                                        store.getState().colorState.color,
                                    color: store.getState().colorState
                                        .font_color,
                                }}
                                onClick={redirectToSubscriptionListingPage}
                            >
                                Back To Listing
                            </Button>
                        </Grid>

                        <Grid container className="p-4" spacing={3}>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    disabled
                                    id="custName"
                                    name="cutsName"
                                    label="Customer Name"
                                    fullWidth
                                    value={custName.value}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    disabled
                                    id="custName"
                                    name="cutsName"
                                    label="Plan Subscribed"
                                    fullWidth
                                    value={planName || ""}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    disabled
                                    id="subId"
                                    name="subId"
                                    label={
                                        window.location.href.includes(
                                            "vajrasecrets"
                                        )
                                            ? "Subscription Start Date"
                                            : "Delivery Date"
                                    }
                                    fullWidth
                                    value={planDeliveryDate}
                                />
                            </Grid>
                            {window.location.href.includes("vajrasecrets") ? (
                                <Grid item xs={12}>
                                    <TextField
                                        required
                                        disabled
                                        id="subId"
                                        name="subId"
                                        label={"Kechara Membership"}
                                        fullWidth
                                        value={
                                            vajraKecharaMembership.value ||
                                            "N/A"
                                        }
                                    />
                                </Grid>
                            ) : null}
                            {window.location.href.includes("vajrasecrets") ? (
                                <Grid item xs={12}>
                                    <TextField
                                        required
                                        disabled
                                        id="subId"
                                        name="subId"
                                        label={"Preferred Language"}
                                        fullWidth
                                        value={
                                            vajraPreferredLanguage.value || ""
                                        }
                                    />
                                </Grid>
                            ) : null}
                            {window.location.href.includes("vajrasecrets") ? (
                                <Grid item xs={12}>
                                    <TextField
                                        required
                                        disabled
                                        id="subId"
                                        name="subId"
                                        label={"Country"}
                                        fullWidth
                                        value={vajraCountry.value || ""}
                                    />
                                </Grid>
                            ) : null}
                            {subType.value == "instant_pay" ||
                            window.location.href.includes(
                                "vajrasecrets"
                            ) ? null : (
                                <Grid item xs={12}>
                                    <TextField
                                        required
                                        disabled
                                        id="subId"
                                        name="subId"
                                        label="Next Delivery Date"
                                        fullWidth
                                        value={
                                            nextDeliveryDate == undefined
                                                ? ""
                                                : nextDeliveryDate
                                        }
                                    />
                                </Grid>
                            )}

                            {window.location.href.includes("vajrasecrets") ||
                            window.location.href.includes(
                                "ketoccino"
                            ) ? null : (
                                <Grid item xs={12}>
                                    <TextField
                                        required
                                        disabled
                                        label="Delivery Time"
                                        fullWidth
                                        value={planDeliveryTime}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </Grid>
                            )}

                            <Grid
                                item
                                style={{
                                    background: "#FBFBFB",
                                    margin: "12px",
                                    borderRadius: "12px",
                                }}
                                xs={12}
                            >
                                <div>
                                    <h4 className="text-left ">
                                        Subscription Details
                                        <Button
                                            startIcon={
                                                <EditIcon
                                                    className="icon-mobile"
                                                    style={{ fontSize: "15px" }}
                                                ></EditIcon>
                                            }
                                            style={{
                                                marginLeft: "20px",
                                                // padding: "0px",
                                                fontSize: "10px",
                                                // marginBottom: "5px",
                                                backgroundColor:
                                                    "rgba(0, 0, 0, 0.54)",
                                                color: "white",
                                            }}
                                            onClick={handleClickOpen}
                                        >
                                            Edit
                                        </Button>
                                    </h4>
                                </div>

                                <Modal
                                    show={openSubsEdit}
                                    onHide={handleClose}
                                    animation={false}
                                    centered
                                    dialogClassName="edit-subs-modal"
                                >
                                    <Modal.Header closeButton>
                                        <Modal.Title>
                                            Edit Subscription
                                        </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <Tabs
                                            onSelect={(e) => {
                                                setTabState(e);
                                            }}
                                            defaultActiveKey="plan"
                                            id="uncontrolled-tab-example"
                                        >
                                            <Tab
                                                eventKey="plan"
                                                title="Plan"
                                                className="plan-tab"
                                            >
                                                {store?.getState()
                                                    .loggedInUserReducer
                                                    .userData?.userRole ==
                                                "merchant" ? (
                                                    <React.Fragment>
                                                        <Row className="mt-3">
                                                            <Col xs={12} md={5}>
                                                                <FormGroup>
                                                                    <FormLabel>
                                                                        Current
                                                                        Plan:
                                                                    </FormLabel>
                                                                    <Form.Control
                                                                        disabled
                                                                        readOnly
                                                                        as="select"
                                                                    >
                                                                        <option>
                                                                            {
                                                                                planName
                                                                            }
                                                                        </option>
                                                                    </Form.Control>
                                                                </FormGroup>
                                                            </Col>
                                                            <Col
                                                                xs={12}
                                                                md={1}
                                                                style={{
                                                                    alignSelf:
                                                                        "center",
                                                                }}
                                                            >
                                                                <svg
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    width="16"
                                                                    height="16"
                                                                    fill="currentColor"
                                                                    class="bi bi-arrow-right"
                                                                    viewBox="0 0 16 16"
                                                                >
                                                                    <path
                                                                        fill-rule="evenodd"
                                                                        d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"
                                                                    />
                                                                </svg>{" "}
                                                            </Col>
                                                            <Col xs={12} md={5}>
                                                                <FormGroup>
                                                                    <FormLabel>
                                                                        Change
                                                                        Plan To:
                                                                    </FormLabel>

                                                                    <Form.Control
                                                                        onChange={(
                                                                            e
                                                                        ) => {
                                                                            const newVal =
                                                                                e
                                                                                    .target
                                                                                    .value;
                                                                            changePlanTo(
                                                                                newVal
                                                                            );
                                                                        }}
                                                                        defaultValue={
                                                                            ""
                                                                        }
                                                                        as="select"
                                                                    >
                                                                        <option
                                                                            disabled
                                                                            value=""
                                                                        >
                                                                            Select
                                                                            option
                                                                        </option>
                                                                        {planSelection.value.map(
                                                                            (
                                                                                data,
                                                                                i
                                                                            ) => {
                                                                                return (
                                                                                    <option
                                                                                        key={
                                                                                            i
                                                                                        }
                                                                                        value={
                                                                                            data.plan_id
                                                                                        }
                                                                                    >
                                                                                        {
                                                                                            data.plan_name
                                                                                        }
                                                                                    </option>
                                                                                );
                                                                            }
                                                                        )}
                                                                    </Form.Control>
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                        <Modal.Footer
                                                            style={{
                                                                marginTop:
                                                                    "20px",
                                                            }}
                                                        />
                                                    </React.Fragment>
                                                ) : (
                                                    ""
                                                )}
                                                <Row>
                                                    <Col
                                                        xs={12}
                                                        md={12}
                                                        className="mt-2"
                                                    >
                                                        <FormGroup>
                                                            <Grid container>
                                                                <Grid
                                                                    item
                                                                    xs={6}
                                                                    md={6}
                                                                    lg={6}
                                                                >
                                                                    <Form.Label>
                                                                        Menu:
                                                                    </Form.Label>
                                                                </Grid>
                                                                {/* if property existed */}
                                                                {pricingModel ==
                                                                "property" ? (
                                                                    <Grid
                                                                        item
                                                                        xs={2}
                                                                        md={2}
                                                                        lg={2}
                                                                    >
                                                                        <Form.Label className="text-center w-100">
                                                                            Property:
                                                                        </Form.Label>
                                                                    </Grid>
                                                                ) : (
                                                                    <Grid
                                                                        item
                                                                        xs={2}
                                                                        md={2}
                                                                        lg={2}
                                                                    ></Grid>
                                                                )}

                                                                <Grid
                                                                    item
                                                                    xs={2}
                                                                    md={2}
                                                                    lg={2}
                                                                >
                                                                    {/* <Form.Label className="text-center w-100">
                                                                        Quantity:
                                                                    </Form.Label> */}
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    xs={2}
                                                                    md={2}
                                                                    lg={2}
                                                                >
                                                                    <Form.Label className="text-center w-100">
                                                                        Price:
                                                                    </Form.Label>
                                                                </Grid>
                                                            </Grid>

                                                            {Object.keys(
                                                                menus.value
                                                            ).map((menu, i) => {
                                                                return (
                                                                    <React.Fragment>
                                                                        <Grid
                                                                            container
                                                                        >
                                                                            <Grid
                                                                                item
                                                                                xs={
                                                                                    6
                                                                                }
                                                                                md={
                                                                                    6
                                                                                }
                                                                                lg={
                                                                                    6
                                                                                }
                                                                            >
                                                                                <Form.Control
                                                                                    className="mt-2"
                                                                                    as="select"
                                                                                    // value={`${menus[0].addon_id}`}
                                                                                    onChange={(
                                                                                        e
                                                                                    ) => {
                                                                                        const newVal =
                                                                                            changeChosenDefaultMenu(
                                                                                                i,
                                                                                                e
                                                                                                    .target
                                                                                                    .value
                                                                                            );
                                                                                        setMenus(
                                                                                            {
                                                                                                ...menus,
                                                                                                value: newVal,
                                                                                            }
                                                                                        );
                                                                                        // //change value in menus
                                                                                        // var originalMenu = menus;
                                                                                        // originalMenu[index] = newVal[0];
                                                                                        // setMenus(originalMenu);
                                                                                    }}
                                                                                >
                                                                                    <option
                                                                                        key={
                                                                                            i
                                                                                        }
                                                                                        value={
                                                                                            menus
                                                                                                .value[
                                                                                                i
                                                                                            ][1]
                                                                                                .addon_id
                                                                                        }
                                                                                    >
                                                                                        {
                                                                                            menus
                                                                                                .value[
                                                                                                i
                                                                                            ][1]
                                                                                                .addon_name
                                                                                        }
                                                                                    </option>
                                                                                    {defaultMenuOption.map(
                                                                                        (
                                                                                            menu,
                                                                                            i
                                                                                        ) => {
                                                                                            return (
                                                                                                <option
                                                                                                    key={
                                                                                                        i
                                                                                                    }
                                                                                                    value={
                                                                                                        menu.addon_id
                                                                                                    }
                                                                                                >
                                                                                                    {
                                                                                                        menu.addon_name
                                                                                                    }
                                                                                                </option>
                                                                                            );
                                                                                        }
                                                                                    )}
                                                                                    {nonDefaultMenuOption.map(
                                                                                        (
                                                                                            menu,
                                                                                            i
                                                                                        ) => {
                                                                                            return (
                                                                                                <option
                                                                                                    key={
                                                                                                        i
                                                                                                    }
                                                                                                    value={
                                                                                                        menu.addon_id
                                                                                                    }
                                                                                                >
                                                                                                    {
                                                                                                        menu.addon_name
                                                                                                    }
                                                                                                </option>
                                                                                            );
                                                                                        }
                                                                                    )}
                                                                                </Form.Control>
                                                                            </Grid>

                                                                            {pricingModel ==
                                                                            "property" ? (
                                                                                <Grid
                                                                                    item
                                                                                    xs={
                                                                                        2
                                                                                    }
                                                                                    md={
                                                                                        2
                                                                                    }
                                                                                    lg={
                                                                                        2
                                                                                    }
                                                                                >
                                                                                    {/* have conditional rendering outside of grid */}
                                                                                    {/* property rendering here  */}
                                                                                    <Form.Control
                                                                                        as="select"
                                                                                        className="ml-1 mt-2"
                                                                                        defaultValue={
                                                                                            menus
                                                                                                .value[
                                                                                                menu
                                                                                            ][1]
                                                                                                .property
                                                                                        }
                                                                                        disabled={
                                                                                            hostname ===
                                                                                            "wildkombucha"
                                                                                        }
                                                                                        onChange={(
                                                                                            e
                                                                                        ) => {
                                                                                            // // filter property

                                                                                            const changeMenu =
                                                                                                changeProperty(
                                                                                                    i,
                                                                                                    e
                                                                                                        .target
                                                                                                        .value
                                                                                                );

                                                                                            setMenus(
                                                                                                {
                                                                                                    ...menus,
                                                                                                    value: changeMenu,
                                                                                                }
                                                                                            );
                                                                                        }}
                                                                                    >
                                                                                        {propertyModel.property.map(
                                                                                            (
                                                                                                menu,
                                                                                                i
                                                                                            ) => {
                                                                                                return (
                                                                                                    <option
                                                                                                        className="text-center"
                                                                                                        key={
                                                                                                            menu.property_name
                                                                                                        }
                                                                                                        value={
                                                                                                            menu.property_name
                                                                                                        }
                                                                                                        id={
                                                                                                            menu.property_price
                                                                                                        }
                                                                                                    >
                                                                                                        {
                                                                                                            menu.property_name
                                                                                                        }
                                                                                                    </option>
                                                                                                );
                                                                                            }
                                                                                        )}
                                                                                    </Form.Control>
                                                                                </Grid>
                                                                            ) : (
                                                                                <Grid
                                                                                    item
                                                                                    xs={
                                                                                        2
                                                                                    }
                                                                                    md={
                                                                                        2
                                                                                    }
                                                                                    lg={
                                                                                        2
                                                                                    }
                                                                                ></Grid>
                                                                            )}

                                                                            <Grid
                                                                                item
                                                                                style={{
                                                                                    marginTop:
                                                                                        "5px",
                                                                                }}
                                                                                xs={
                                                                                    2
                                                                                }
                                                                                md={
                                                                                    2
                                                                                }
                                                                                lg={
                                                                                    2
                                                                                }
                                                                            >
                                                                                {/* <TextField
                                                                                    className="ml-1 mt-2 mr-1 ml-md-2 mr-md-0"
                                                                                    type="number"
                                                                                    InputLabelProps={{
                                                                                        shrink: true,
                                                                                    }}
                                                                                    value={
                                                                                        // add quantity list here, use the index
                                                                                        menus
                                                                                            .value[
                                                                                            menu
                                                                                        ][1]
                                                                                            .quantity
                                                                                    }
                                                                                    onChange={(
                                                                                        e
                                                                                    ) => {
                                                                                        //update quantity list here, need to set quantity list too

                                                                                        const newVal =
                                                                                            changeQuantity(
                                                                                                e
                                                                                                    .target
                                                                                                    .value,
                                                                                                i
                                                                                            );
                                                                                        setMenus(
                                                                                            {
                                                                                                ...menus,
                                                                                                value: newVal,
                                                                                            }
                                                                                        );
                                                                                    }}
                                                                                    disabled={
                                                                                        hostname ===
                                                                                        "wildkombucha"
                                                                                    }
                                                                                    variant="outlined"
                                                                                    inputProps={{
                                                                                        style: {
                                                                                            textAlign:
                                                                                                "center",
                                                                                            maxHeight:
                                                                                                "1px",
                                                                                            minHeight:
                                                                                                "1px",
                                                                                            paddingTop:
                                                                                                "13px",
                                                                                        },
                                                                                        min: 0,
                                                                                    }}
                                                                                /> */}
                                                                            </Grid>
                                                                            <Grid
                                                                                item
                                                                                xs={
                                                                                    2
                                                                                }
                                                                                md={
                                                                                    2
                                                                                }
                                                                            >
                                                                                <div
                                                                                    style={{
                                                                                        textAlign:
                                                                                            "center",
                                                                                    }}
                                                                                    className="mt-3 price-col"
                                                                                >
                                                                                    {/* if quantity mmore than 1 for default menu and non default menu */}
                                                                                    {
                                                                                        <p
                                                                                            style={{
                                                                                                color: "green",
                                                                                            }}
                                                                                        >
                                                                                            {" "}
                                                                                            +RM
                                                                                            {renderMenuPrice(
                                                                                                i
                                                                                            )}{" "}
                                                                                        </p>
                                                                                    }
                                                                                </div>

                                                                                {/* );
                                                                                }
                                                                            )} */}
                                                                            </Grid>
                                                                        </Grid>
                                                                    </React.Fragment>
                                                                );
                                                            })}
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col
                                                        xs={12}
                                                        md={12}
                                                        className="mt-2"
                                                    >
                                                        <FormGroup>
                                                            <Grid container>
                                                                <Grid
                                                                    item
                                                                    xs={6}
                                                                    md={6}
                                                                    lg={6}
                                                                >
                                                                    {sideOrderOption.length >
                                                                    0 ? (
                                                                        <Grid
                                                                            container
                                                                        >
                                                                            <Form.Label>
                                                                                Side
                                                                                order:
                                                                            </Form.Label>
                                                                            <Avatar
                                                                                className="ml-2 mt-1"
                                                                                style={{
                                                                                    width: "20px",
                                                                                    height: "20px",
                                                                                }}
                                                                            >
                                                                                <AddIcon
                                                                                    onClick={() => {
                                                                                        // add new side order
                                                                                        const newSideOrder =
                                                                                            [
                                                                                                ...selectedSideOrder.value,
                                                                                            ];
                                                                                        newSideOrder.push(
                                                                                            sideOrderOption[0]
                                                                                        );
                                                                                        setSelectedSideOrder(
                                                                                            {
                                                                                                ...selectedSideOrder,
                                                                                                value: newSideOrder,
                                                                                            }
                                                                                        );
                                                                                    }}
                                                                                />
                                                                            </Avatar>
                                                                        </Grid>
                                                                    ) : (
                                                                        ""
                                                                    )}
                                                                </Grid>
                                                                {/* if property existed */}

                                                                <Grid
                                                                    item
                                                                    xs={2}
                                                                    md={2}
                                                                    lg={2}
                                                                ></Grid>

                                                                <Grid
                                                                    item
                                                                    xs={2}
                                                                    md={2}
                                                                    lg={2}
                                                                ></Grid>
                                                                <Grid
                                                                    item
                                                                    xs={2}
                                                                    md={2}
                                                                    lg={2}
                                                                ></Grid>
                                                            </Grid>
                                                            {selectedSideOrder !==
                                                            undefined
                                                                ? selectedSideOrder.value.map(
                                                                      (
                                                                          order,
                                                                          index
                                                                      ) => {
                                                                          return (
                                                                              <Grid
                                                                                  container
                                                                                  className="mt-3"
                                                                              >
                                                                                  <Grid
                                                                                      xs={
                                                                                          6
                                                                                      }
                                                                                      lg={
                                                                                          6
                                                                                      }
                                                                                      className=""
                                                                                  >
                                                                                      <Form.Control
                                                                                          onChange={(
                                                                                              e
                                                                                          ) => {
                                                                                              // need to filter the side order here
                                                                                              // need to update the side order here

                                                                                              // filter e.target.value against side order option
                                                                                              const newSideOrder =
                                                                                                  sideOrderOption.filter(
                                                                                                      (
                                                                                                          order
                                                                                                      ) => {
                                                                                                          if (
                                                                                                              order.addon_id ==
                                                                                                              e
                                                                                                                  .target
                                                                                                                  .value
                                                                                                          ) {
                                                                                                              return order;
                                                                                                          }
                                                                                                      }
                                                                                                  );
                                                                                              var tempSideOrder =
                                                                                                  selectedSideOrder.value;
                                                                                              tempSideOrder[
                                                                                                  index
                                                                                              ] =
                                                                                                  newSideOrder[0];
                                                                                              setSelectedSideOrder(
                                                                                                  {
                                                                                                      ...selectedSideOrder,
                                                                                                      value: tempSideOrder,
                                                                                                  }
                                                                                              );
                                                                                          }}
                                                                                          as="select"
                                                                                          // value={
                                                                                          //     changeDeliveryTime ==
                                                                                          //     undefined
                                                                                          //         ? planDeliveryTime
                                                                                          //         : changeDeliveryTime
                                                                                          // }
                                                                                      >
                                                                                          <option
                                                                                              selected
                                                                                              value={
                                                                                                  order.addon_id
                                                                                              }
                                                                                          >
                                                                                              {
                                                                                                  order.addon_name
                                                                                              }
                                                                                          </option>
                                                                                          {sideOrderOption.map(
                                                                                              (
                                                                                                  option,
                                                                                                  i
                                                                                              ) => {
                                                                                                  return (
                                                                                                      <option
                                                                                                          value={
                                                                                                              option.addon_id
                                                                                                          }
                                                                                                      >
                                                                                                          {
                                                                                                              option.addon_name
                                                                                                          }
                                                                                                      </option>
                                                                                                  );
                                                                                              }
                                                                                          )}
                                                                                      </Form.Control>
                                                                                  </Grid>
                                                                                  <Grid
                                                                                      xs={
                                                                                          2
                                                                                      }
                                                                                      lg={
                                                                                          2
                                                                                      }
                                                                                  ></Grid>
                                                                                  <Grid
                                                                                      xs={
                                                                                          2
                                                                                      }
                                                                                      lg={
                                                                                          2
                                                                                      }
                                                                                  >
                                                                                      {" "}
                                                                                      <TextField
                                                                                          className="ml-1 mr-1 ml-md-2 mr-md-0"
                                                                                          type="number"
                                                                                          InputLabelProps={{
                                                                                              shrink: true,
                                                                                          }}
                                                                                          //   assign value to quantity of selectedside order

                                                                                          value={
                                                                                              selectedSideOrder
                                                                                                  .value[
                                                                                                  index
                                                                                              ]
                                                                                                  .quantity
                                                                                          }
                                                                                          onChange={(
                                                                                              e
                                                                                          ) => {
                                                                                              var newSideOrder =
                                                                                                  selectedSideOrder
                                                                                                      .value[
                                                                                                      index
                                                                                                  ]
                                                                                                      .quantity;
                                                                                              newSideOrder =
                                                                                                  e
                                                                                                      .target
                                                                                                      .value;
                                                                                              selectedSideOrder.value[
                                                                                                  index
                                                                                              ].quantity =
                                                                                                  parseInt(
                                                                                                      newSideOrder
                                                                                                  );
                                                                                              setSelectedSideOrder(
                                                                                                  {
                                                                                                      ...selectedSideOrder,
                                                                                                  }
                                                                                              );
                                                                                          }}
                                                                                          variant="outlined"
                                                                                          inputProps={{
                                                                                              style: {
                                                                                                  textAlign:
                                                                                                      "center",
                                                                                                  height: "0rem",
                                                                                              },
                                                                                              min: 0,
                                                                                          }}
                                                                                      />
                                                                                  </Grid>
                                                                                  <Grid
                                                                                      xs={
                                                                                          2
                                                                                      }
                                                                                      lg={
                                                                                          2
                                                                                      }
                                                                                      className="mt-1"
                                                                                      align="center"
                                                                                      //   display component in the same row
                                                                                      style={{
                                                                                          display:
                                                                                              "flex",
                                                                                          alignItems:
                                                                                              "center",
                                                                                          justifyContent:
                                                                                              "center",
                                                                                      }}
                                                                                  >
                                                                                      <p
                                                                                          style={{
                                                                                              color: "green",
                                                                                          }}
                                                                                      >
                                                                                          {" "}
                                                                                          +RM
                                                                                          {(
                                                                                              order.addon_price *
                                                                                              order.quantity
                                                                                          ).toFixed(
                                                                                              2
                                                                                          )}
                                                                                      </p>
                                                                                      <Avatar
                                                                                          className="ml-2"
                                                                                          style={{
                                                                                              width: "20px",
                                                                                              height: "20px",
                                                                                              marginTop:
                                                                                                  "-15px",
                                                                                          }}
                                                                                      >
                                                                                          <RemoveIcon
                                                                                              onClick={() => {
                                                                                                  // add new side order
                                                                                                  const newSideOrder =
                                                                                                      [
                                                                                                          ...selectedSideOrder.value,
                                                                                                      ];
                                                                                                  //   remove last value in newSideOrder
                                                                                                  newSideOrder.pop();
                                                                                                  setSelectedSideOrder(
                                                                                                      {
                                                                                                          ...selectedSideOrder,
                                                                                                          value: newSideOrder,
                                                                                                      }
                                                                                                  );
                                                                                              }}
                                                                                          />
                                                                                      </Avatar>
                                                                                  </Grid>
                                                                              </Grid>
                                                                          );
                                                                      }
                                                                  )
                                                                : ""}
                                                            {/* render side order details here */}
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Modal.Footer
                                                    style={{
                                                        marginTop: "20px",
                                                    }}
                                                />
                                                <Row>
                                                    {/*TODO: change extra charges here  */}
                                                    <Col xs={12} md={12}>
                                                        {pricingModel ==
                                                        "property" ? (
                                                            <p className="text-right">
                                                                Menu price: RM
                                                                {renderNewMenuPrice().toFixed(
                                                                    2
                                                                )}
                                                            </p>
                                                        ) : (
                                                            <p className="text-right">
                                                                Non-Default Menu
                                                                Charges: RM
                                                                {renderNewNonRecurring().toFixed(
                                                                    2
                                                                )}
                                                            </p>
                                                        )}
                                                    </Col>
                                                </Row>
                                                {!!selectedPlan.is_shippable ? (
                                                    <Row>
                                                        <Col xs={12} md={12}>
                                                            <p className="text-right">
                                                                Delivery Fee: RM
                                                                {custPostcodePrice.toFixed(
                                                                    2
                                                                )}
                                                            </p>
                                                        </Col>
                                                    </Row>
                                                ) : null}

                                                <Row>
                                                    <Col xs={12} md={12}>
                                                        {pricingModel ==
                                                        "property" ? (
                                                            ""
                                                        ) : (
                                                            <p className="text-right">
                                                                Plan Price: RM
                                                                {parseFloat(
                                                                    planPrice
                                                                ).toFixed(2)}
                                                            </p>
                                                        )}
                                                    </Col>
                                                </Row>
                                                {promoStatus == "active" &&
                                                promoApplied.value != null ? (
                                                    <Row>
                                                        <Col xs={12} md={12}>
                                                            <p
                                                                style={{
                                                                    color: "green",
                                                                }}
                                                                className="text-right"
                                                            >
                                                                Promotion
                                                                applied{" "}
                                                                {promoApplied.value !==
                                                                null
                                                                    ? `(${promoApplied.value.promo_code})`
                                                                    : ""}{" "}
                                                                : RM{" "}
                                                                {renderPromoPrice().toFixed(
                                                                    2
                                                                )}
                                                            </p>
                                                        </Col>
                                                    </Row>
                                                ) : (
                                                    ""
                                                )}

                                                <Row>
                                                    <Col
                                                        xs={12}
                                                        md={12}
                                                        className=" mt-2"
                                                    >
                                                        <p className="text-right">
                                                            Total: RM
                                                            {renderSubsPrice()}
                                                        </p>
                                                    </Col>
                                                </Row>
                                                {/* {store?.getState().loggedInUserReducer
                                            .userData?.userRole ==
                                        "merchant" ? (
                                            <React.Fragment>
                                                <Modal.Footer
                                                    style={{
                                                        marginTop: "20px",
                                                    }}
                                                />
                                                <Row>
                                                    <Col xs={12} md={5}>
                                                        Charges:
                                                        <NumericInput
                                                            step={0.1}
                                                            precision={2}
                                                            value={extraCharge}
                                                            onChange={(e) => {
                                                                setExtraCharge(
                                                                    e
                                                                );
                                                            }}
                                                        />
                                                    </Col>
                                                    <Row>
                                                        <Col xs={12} md={12}>
                                                            <p className="text-right">
                                                                Total Non
                                                                Recurring
                                                            </p>
                                                        </Col>
                                                    </Row>
                                                    <Col
                                                        xs={12}
                                                        md={12}
                                                        className=" mt-2"
                                                    >
                                                        <p className="text-right">
                                                            Total: RM
                                                            {renderSubsPrice()}
                                                        </p>
                                                    </Col>
                                                </Row>
                                            </React.Fragment>
                                        ) : (
                                            <React.Fragment>
                                                <Modal.Footer
                                                    style={{
                                                        marginTop: "20px",
                                                    }}
                                                />
                                                <Row>
                                                    <Col>
                                                        <p>
                                                            Total Non Recurring
                                                        </p>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col
                                                        xs={12}
                                                        // md={3}
                                                        className="mt-1 ml-1"
                                                        style={{
                                                            float: "left",
                                                        }}
                                                    >
                                                        <p>
                                                            Total: RM
                                                            {renderSubsPrice()}
                                                        </p>
                                                    </Col>
                                                </Row>
                                            </React.Fragment>
                                        )} */}
                                            </Tab>
                                            {selectedPlan.delivery_window !==
                                            null ? (
                                                <Tab
                                                    eventKey="delivery"
                                                    title="Delivery Date &amp; Time "
                                                >
                                                    <Row>
                                                        <Col
                                                            xs={12}
                                                            md={5}
                                                            style={{
                                                                marginTop:
                                                                    "10px",
                                                            }}
                                                        >
                                                            <FormGroup>
                                                                <FormLabel>
                                                                    Current
                                                                    Date:
                                                                </FormLabel>
                                                                <Grid>
                                                                    <MuiPickersUtilsProvider
                                                                        utils={
                                                                            DateFnsUtils
                                                                        }
                                                                    >
                                                                        <DatePicker
                                                                            disabled
                                                                            value={moment(
                                                                                planDeliveryDate
                                                                            ).toDate()}
                                                                        />
                                                                    </MuiPickersUtilsProvider>
                                                                </Grid>
                                                            </FormGroup>
                                                        </Col>
                                                        <Col
                                                            xs={12}
                                                            md={1}
                                                            style={{
                                                                alignSelf:
                                                                    "center",
                                                            }}
                                                        >
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                width="16"
                                                                height="16"
                                                                fill="currentColor"
                                                                class="bi bi-arrow-right"
                                                                viewBox="0 0 16 16"
                                                            >
                                                                <path
                                                                    fill-rule="evenodd"
                                                                    d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"
                                                                />
                                                            </svg>{" "}
                                                        </Col>
                                                        <Col
                                                            xs={12}
                                                            md={5}
                                                            style={{
                                                                marginTop:
                                                                    "10px",
                                                            }}
                                                        >
                                                            <FormGroup>
                                                                <FormLabel>
                                                                    Change Date
                                                                    To:
                                                                </FormLabel>
                                                                <Grid>
                                                                    <MuiPickersUtilsProvider
                                                                        utils={
                                                                            DateFnsUtils
                                                                        }
                                                                    >
                                                                        <DatePicker
                                                                            minDate={currenDate()}
                                                                            value={
                                                                                new Date(
                                                                                    newPlanDeliveryDate
                                                                                )
                                                                            }
                                                                            onChange={(
                                                                                e
                                                                            ) => {
                                                                                console.log(
                                                                                    subscriptionData,
                                                                                    "this is the subs data"
                                                                                );
                                                                                e.setUTCHours(
                                                                                    4,
                                                                                    0,
                                                                                    0,
                                                                                    0
                                                                                );
                                                                                setNewPlanDeliveryDate(
                                                                                    e.toISOString()
                                                                                );
                                                                            }}
                                                                            shouldDisableDate={(
                                                                                date
                                                                            ) => {
                                                                                var deliveryWindowArr =
                                                                                    [];

                                                                                const disabledDate =
                                                                                    deliveryWindowArr.includes(
                                                                                        date.getDay()
                                                                                    );
                                                                                return disabledDate;
                                                                            }}
                                                                        />
                                                                    </MuiPickersUtilsProvider>
                                                                </Grid>
                                                            </FormGroup>
                                                        </Col>
                                                        <Col
                                                            xs={12}
                                                            md={5}
                                                            style={{
                                                                marginTop:
                                                                    "10px",
                                                            }}
                                                        >
                                                            <FormGroup>
                                                                <FormLabel>
                                                                    Current
                                                                    Time:
                                                                </FormLabel>
                                                                <TextField
                                                                    value={
                                                                        planDeliveryTime
                                                                    }
                                                                    disabled
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                        <Col
                                                            xs={12}
                                                            md={1}
                                                            style={{
                                                                alignSelf:
                                                                    "center",
                                                            }}
                                                        >
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                width="16"
                                                                height="16"
                                                                fill="currentColor"
                                                                class="bi bi-arrow-right"
                                                                viewBox="0 0 16 16"
                                                            >
                                                                <path
                                                                    fill-rule="evenodd"
                                                                    d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"
                                                                />
                                                            </svg>{" "}
                                                        </Col>
                                                        <Col
                                                            xs={12}
                                                            md={5}
                                                            style={{
                                                                marginTop:
                                                                    "10px",
                                                            }}
                                                        >
                                                            <FormGroup>
                                                                <FormLabel>
                                                                    Change Time
                                                                    To:
                                                                </FormLabel>
                                                                <Grid>
                                                                    <Form.Control
                                                                        onChange={(
                                                                            e
                                                                        ) => {
                                                                            console.log(
                                                                                subscriptionData,
                                                                                "this is the subscription data"
                                                                            );
                                                                            setChangeDeliveryTime(
                                                                                e
                                                                                    .target
                                                                                    .value
                                                                            );
                                                                        }}
                                                                        as="select"
                                                                        value={
                                                                            changeDeliveryTime ==
                                                                            undefined
                                                                                ? planDeliveryTime
                                                                                : changeDeliveryTime
                                                                        }
                                                                    >
                                                                        <option
                                                                            disabled
                                                                            value=""
                                                                        >
                                                                            Select
                                                                            Time
                                                                        </option>
                                                                        {deliveryTime !==
                                                                        undefined
                                                                            ? deliveryTime.map(
                                                                                  (
                                                                                      e
                                                                                  ) => {
                                                                                      // get timezone offset then minus the offset to make it UTC
                                                                                      // and add 8 to comply with malaysian time

                                                                                      const startTime =
                                                                                          timeFromInt(
                                                                                              e.start_time,
                                                                                              {
                                                                                                  format: 12,
                                                                                              }
                                                                                          );
                                                                                      const endTime =
                                                                                          timeFromInt(
                                                                                              e.end_time,
                                                                                              {
                                                                                                  format: 12,
                                                                                              }
                                                                                          );

                                                                                      const timeDisplay =
                                                                                          startTime +
                                                                                          " - " +
                                                                                          endTime;

                                                                                      return (
                                                                                          <option
                                                                                              value={
                                                                                                  timeDisplay
                                                                                              }
                                                                                          >
                                                                                              {
                                                                                                  timeDisplay
                                                                                              }
                                                                                          </option>
                                                                                      );
                                                                                  }
                                                                              )
                                                                            : ""}
                                                                    </Form.Control>
                                                                </Grid>
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                </Tab>
                                            ) : null}
                                        </Tabs>
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <Button
                                            variant="secondary"
                                            onClick={handleClose}
                                        >
                                            Close
                                        </Button>
                                        <Button
                                            variant="primary"
                                            onClick={changeSubscription}
                                        >
                                            Save Changes
                                        </Button>
                                    </Modal.Footer>
                                </Modal>

                                {renderEstimatedPriceForSubscription()}
                            </Grid>
                            {window.location.href.includes("vajrasecrets") ? (
                                <Grid
                                    item
                                    style={{
                                        background: "#FBFBFB",
                                        margin: "12px",
                                        borderRadius: "12px",
                                    }}
                                    xs={12}
                                >
                                    <h4 className="text-left ">
                                        Additional Details
                                        <Button
                                            startIcon={
                                                <EditIcon
                                                    className="icon-mobile"
                                                    style={{ fontSize: "15px" }}
                                                ></EditIcon>
                                            }
                                            style={{
                                                marginLeft: "20px",
                                                // padding: "0px",
                                                fontSize: "10px",
                                                // marginBottom: "5px",
                                                backgroundColor:
                                                    "rgba(0, 0, 0, 0.54)",
                                                color: "white",
                                            }}
                                            onClick={editAdditionalDetails}
                                        >
                                            Edit
                                        </Button>
                                    </h4>
                                    <Form.Label className="control-label mt-3">
                                        Your subscription will start on
                                        {planDeliveryDate}
                                    </Form.Label>
                                    <Form.Group
                                        className="mb-3 form-group required"
                                        style={{ textAlign: "left" }}
                                        controlId="exampleForm.ControlTextarea1"
                                    >
                                        <Form.Label className="control-label">
                                            Dedication (500 characters)
                                        </Form.Label>
                                        <InputGroup hasValidation>
                                            <Form.Control
                                                as="textarea"
                                                rows={3}
                                                isInvalid={
                                                    vajraDedicationNote.valid ===
                                                    false
                                                        ? true
                                                        : false
                                                }
                                                value={
                                                    vajraDedicationNote.value
                                                }
                                                disabled={
                                                    !editVajraAdditionalDetails
                                                }
                                                onChange={(data) => {
                                                    if (
                                                        data.target.value
                                                            .length > 500
                                                    ) {
                                                        setVajraDedicationNote({
                                                            ...vajraDedicationNote,
                                                            valid: false,
                                                            error: `Dedication note cannot exceed 500 characters. Please remove ${
                                                                vajraDedicationNote
                                                                    .value
                                                                    .length -
                                                                500
                                                            } characters.`,
                                                        });
                                                    } else if (
                                                        data.target.value
                                                            .length < 500
                                                    ) {
                                                        setVajraDedicationNote({
                                                            ...vajraDedicationNote,
                                                            valid: true,
                                                            value: data.target
                                                                .value,
                                                            error: "",
                                                        });
                                                    }
                                                    // dedicationNoteHandler(data.target.value);
                                                }}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {vajraDedicationNote.error}
                                            </Form.Control.Feedback>
                                        </InputGroup>

                                        <Form.Label className="mt-3">
                                            Referral Code
                                        </Form.Label>
                                        <Form.Control
                                            value={vajraRefCode}
                                            disabled={
                                                !editVajraAdditionalDetails
                                            }
                                            onChange={(refCode) => {
                                                setVajraRefCode(
                                                    refCode.target.value
                                                );

                                                // refCodeHandler(refCode.target.value);
                                            }}
                                        />
                                        <Form.Check
                                            className="mt-3"
                                            type={"checkbox"}
                                            checked={vajraIsGift}
                                            // id={`default-${type}`}
                                            disabled={
                                                !editVajraAdditionalDetails
                                            }
                                            onChange={(e) => {
                                                setVajraIsGift(
                                                    e.target.checked
                                                );
                                                // setIsGift(e.target.checked);
                                            }}
                                            label={"This is a gift"}
                                        />
                                        <Form.Label className="mt-4">
                                            Gift Message (500 characters)
                                        </Form.Label>
                                        <InputGroup hasValidation>
                                            <Form.Control
                                                as="textarea"
                                                rows={3}
                                                isInvalid={
                                                    vajraGiftMessage.valid ===
                                                    false
                                                        ? true
                                                        : false
                                                }
                                                value={vajraGiftMessage.value}
                                                disabled={
                                                    !editVajraAdditionalDetails ||
                                                    !vajraIsGift
                                                }
                                                onChange={(message) => {
                                                    if (
                                                        message.target.value
                                                            .length > 500
                                                    ) {
                                                        setVajraGiftMessage({
                                                            ...vajraDedicationNote,
                                                            valid: false,
                                                            value: message
                                                                .target.value,
                                                            error: `Dedication note cannot exceed 500 characters. Please remove ${
                                                                vajraDedicationNote
                                                                    .value
                                                                    .length -
                                                                500
                                                            } characters.`,
                                                        });
                                                    } else if (
                                                        message.target.value
                                                            .length < 500
                                                    ) {
                                                        setVajraGiftMessage({
                                                            ...vajraDedicationNote,
                                                            valid: true,
                                                            value: message
                                                                .target.value,
                                                            error: "",
                                                        });
                                                    }
                                                    // giftMessageHandler(message.target.value);
                                                }}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {vajraGiftMessage.error}
                                            </Form.Control.Feedback>
                                        </InputGroup>
                                        <Form.Label className="mt-4">
                                            Recipient Name
                                        </Form.Label>
                                        <Form.Control
                                            disabled={
                                                !editVajraAdditionalDetails ||
                                                !vajraIsGift
                                            }
                                            value={vajraRecipientName}
                                            onChange={(name) => {
                                                setVajraRecipientName(
                                                    name.target.value
                                                );
                                                // recipientNameHandler(name.target.value);
                                            }}
                                        />
                                        <Form.Label className="mt-4">
                                            Recipient Email
                                        </Form.Label>
                                        <Form.Control
                                            // disabled={!isGift}
                                            disabled={
                                                !editVajraAdditionalDetails ||
                                                !vajraIsGift
                                            }
                                            type="email"
                                            value={vajraRecipientEmail.value}
                                            isInvalid={
                                                vajraRecipientEmail.valid ===
                                                false
                                                    ? true
                                                    : false
                                            }
                                            onChange={(email) => {
                                                if (
                                                    !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(
                                                        email.target.value
                                                    )
                                                ) {
                                                    setVajraRecipientEmail({
                                                        ...vajraRecipientEmail,
                                                        value: email.target
                                                            .value,
                                                        error: "Wrong email format",
                                                        valid: false,
                                                    });
                                                } else {
                                                    setVajraRecipientEmail({
                                                        ...vajraRecipientEmail,
                                                        valid: true,
                                                        value: email.target
                                                            .value,
                                                        error: "",
                                                    });
                                                }
                                                // recipientEmailHandler(email.target.value);
                                            }}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {vajraRecipientEmail.error}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    {editVajraAdditionalDetails === true ? (
                                        <Grid
                                            item
                                            align="right"
                                            xs={12}
                                            md={12}
                                            lg={12}
                                        >
                                            <Button
                                                style={{
                                                    backgroundColor: "white",
                                                    color: "black",
                                                }}
                                                onClick={editAdditionalDetails}
                                            >
                                                Cancel
                                            </Button>
                                            <Button
                                                style={{
                                                    backgroundColor: "black",
                                                    color: "white",
                                                }}
                                                onClick={
                                                    updateVajraAdditionalDetails
                                                }
                                            >
                                                Update
                                            </Button>
                                        </Grid>
                                    ) : null}
                                </Grid>
                            ) : null}
                            <Grid item xs={12}>
                                {subId.value != "" && (
                                    <InvoiceGeneratedFromSubscription
                                        subs_id={subId.value}
                                    ></InvoiceGeneratedFromSubscription>
                                )}
                            </Grid>
                        </Grid>
                        <Dialog
                            fullWidth
                            maxWidth="lg"
                            onClose={() => {
                                setInfoModal({ ...infoModal, isOpened: false });
                            }}
                            aria-labelledby="customized-dialog-title"
                            open={infoModal.isOpened}
                        >
                            <DialogTitle
                                id="customized-dialog-title"
                                onClose={() => {
                                    setInfoModal({
                                        ...infoModal,
                                        isOpened: false,
                                    });
                                }}
                            >
                                {renderInfoModalTitle()}
                            </DialogTitle>
                            <DialogContent dividers>
                                {renderInfoModalContent()}
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    onClick={() => {
                                        setInfoModal({
                                            ...infoModal,
                                            isOpened: false,
                                        });
                                    }}
                                    color="primary"
                                >
                                    Close
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </div>
                    <ToastContainer />
                </div>
            </Grid>
        </Grid>
    );
};

export default SubscriptionView;
