import Grid from "@mui/material/Grid";
import { useState, useEffect } from "react";
import { Card, Image } from "react-bootstrap";
import getCroppedImgInFileObj, {
    readImageFileIntoDataUrl,
} from "../../utils/imageUtils";
import "./style.css";
import Button from "@mui/material/Button";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import { store } from "../../index.js";

// pass in menu data

const SideOrder = ({ data, checkerHandler, quantityHandler, type }) => {
    const sortedSideOrder = data.sort((a, b) => {
        return a.addon_id > b.addon_id;
    });
    const [logo, setLogo] = useState({});
    const renderButton = (checker) => {
        if (checker) {
            if (window.innerWidth < 768) {
                return (
                    <Button variant="outline-success">
                        <RemoveIcon />
                    </Button>
                );
            } else {
                return <Button variant="outline-success">Remove Item</Button>;
            }
        } else {
            if (window.innerWidth < 768) {
                return (
                    <Button variant="outline-success">
                        <AddIcon />
                    </Button>
                );
            } else {
                return <Button variant="outline-success">Add Item</Button>;
            }
        }
    };

    useEffect(() => {
        // getLogo();

        const pathArr = [];
        data.map((addon) => {
            pathArr.push(
                `${window.location.protocol + "//" + window.location.hostname}${
                    process.env.REACT_APP_SITE_PORT_BACKEND
                }${addon.addon_image}`
            );
        });
        setLogo(pathArr);
        // add checked field to every data
        data.map((item) => {
            item.checked = false;
        });
    }, []);

    return (
        <Card
            style={{
                borderRadius: "8px",
                boxShadow: "3px 5px #DCDCDC",
            }}
            className="mt-1"
        >
            <Grid
                container
                align="left"
                className="mt-3 payment-method-card text-left"
            >
                {" "}
                <Grid item xs={12}>
                    <h4 style={{ marginLeft: "10px" }}>Add-Ons</h4>
                </Grid>
                {sortedSideOrder.map((item, index) => {
                    return (
                        <Grid
                            container
                            className="mt-3 p-1"
                            xs={12}
                            md={12}
                            lg={12}
                        >
                            {logo.length == 0 ? null : (
                                <Grid item xs={4} md={3} lg={3}>
                                    <Image
                                        src={logo[index]}
                                        className="side-order-image p-2"
                                    />
                                </Grid>
                            )}
                            <Grid
                                style={{
                                    marginLeft:
                                        logo.length == 0 ? "15px" : "0px",
                                }}
                                container
                                xs={8}
                                md={9}
                                lg={9}
                            >
                                <Grid item xs={12} md={12} lg={12}>
                                    {/* capitalise for letter of data[0].addon_name*/}
                                    <h6>
                                        {item.addon_name
                                            .charAt(0)
                                            .toUpperCase() +
                                            item.addon_name.slice(1)}{" "}
                                    </h6>
                                </Grid>
                                <Grid container>
                                    <Grid item xs={7} md={7} lg={7}>
                                        <Grid
                                            className="center quantity-bar mt-4 item-property"
                                            style={{
                                                // marginTop: "20px",

                                                display: "flex",
                                                border: "1px black solid",
                                                borderRadius: "5px",
                                                bottom: "10px",
                                            }}
                                            container
                                            lg={12}
                                            md={12}
                                            xs={12}
                                        >
                                            {/* Quantity (optional) */}{" "}
                                            <Grid
                                                item
                                                align="center"
                                                className="w-50"
                                                xs={4}
                                                md={4}
                                                lg={4}
                                            >
                                                {/* <Button
                                                    className="w-50 h-100 "
                                                    
                                                > */}
                                                <RemoveIcon
                                                    onClick={() => {
                                                        if (
                                                            item.quantity != 0
                                                        ) {
                                                            quantityHandler(
                                                                item.quantity -
                                                                    1,
                                                                index,
                                                                type
                                                            );
                                                        }
                                                    }}
                                                    className="remove-icon-container h-100"
                                                    style={{
                                                        // width: "50%",
                                                        color: "black",
                                                    }}
                                                ></RemoveIcon>
                                                {/* </Button> */}
                                            </Grid>
                                            <Grid
                                                item
                                                align="center"
                                                xs={5}
                                                md={4}
                                                lg={4}
                                            >
                                                <input
                                                    value={item.quantity}
                                                    class="text-center w-50 border-0  m-1 "
                                                />{" "}
                                            </Grid>
                                            <Grid
                                                item
                                                align="center"
                                                xs={3}
                                                md={4}
                                                lg={4}
                                            >
                                                <AddIcon
                                                    className=" h-100 remove-icon-container"
                                                    onClick={(e) => {
                                                        quantityHandler(
                                                            item.quantity + 1,
                                                            index,
                                                            type
                                                        );
                                                    }}
                                                    style={{
                                                        color: "black",
                                                    }}
                                                ></AddIcon>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid xs={4} md={5} lg={5}>
                                        <Button
                                            className="quantity-bar w-100 side-order-add-button"
                                            style={{
                                                backgroundColor:
                                                    store.getState().colorState
                                                        .color,
                                                color: store.getState()
                                                    .colorState.font_color,
                                                top: "25%",
                                                // marginTop: "2px",
                                                padding: "8px",
                                                marginLeft: "5px",
                                                borderRadius: "10px",
                                                zIndex: "21474836499",
                                            }}
                                            variant="outline-success"
                                            onClick={() => {
                                                checkerHandler(
                                                    !item.checked,
                                                    index,
                                                    type
                                                );
                                            }}
                                        >
                                            {renderButton(item.checked)}
                                        </Button>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} md={12} lg={12}>
                                    <Grid className="mt-4" item>
                                        <p style={{ color: "green" }}>
                                            RM
                                            {(
                                                item.addon_price * item.quantity
                                            ).toFixed(2)}
                                        </p>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    );
                })}
            </Grid>
        </Card>
    );
};
export default SideOrder;
