import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { styled } from "@mui/material/styles";
import Check from "@mui/icons-material/Check";
import { useDispatch, useSelector } from "react-redux";

const steps = [
    "Plan",
    window.location.href.includes("vajrasecrets")
        ? "Subscription Details"
        : "Delivery",
    "Sign In",
    "Review & subscribe",
];

const CheckoutTrackerBar = ({ history, match, stepper, deliveryInfo }) => {
    // The `path` lets us build <Route> paths that are
    // relative to the parent route, while the `url` lets
    // us build relative links.
    const isLoggedIn = useSelector(
        (state) => state.loggedInUserReducer.userData
    );
    // #1 Checkout Tracker Bar
    // Grid of 3
    //
    function QontoStepIcon(props) {
        const { active, completed, className } = props;
        return (
            <QontoStepIconRoot ownerState={{ active }} className={className}>
                {completed ? (
                    <Check className="QontoStepIcon-completedIcon" />
                ) : (
                    <div className="QontoStepIcon-circle">{props.icon}</div>
                )}
            </QontoStepIconRoot>
        );
    }
    const QontoStepIconRoot = styled("div")(({ theme, ownerState }) => ({
        // color:
        //     theme.palette.mode === "black"
        //         ? theme.palette.grey[700]
        //         : "#eaeaf0",
        display: "flex",
        alignItems: "center",
        width: 30,
        height: 30,
        borderRadius: "50%",
        backgroundColor: "grey",
        color: "white",
        ...(ownerState.active && {
            color: "white",
            backgroundColor: "black !important",
            borderRadius: "50%",
        }),
        "& .QontoStepIcon-completedIcon": {
            color: "white",
            zIndex: 1,
            width: 30,
            height: 30,
            borderRadius: "50%",
            backgroundColor: "black",
        },
        "& .QontoStepIcon-circle": {
            width: 30,
            height: 30,
            borderRadius: "50%",
            // backgroundColor: "grey",
            paddingTop: "2px",
            textAlign: "center",
        },
    }));

    return (
        <Box sx={{ width: "100%" }}>
            <Stepper activeStep={stepper} alternativeLabel>
                {steps.map((label) => {
                    // if login, dont show sign in step and jump to review and subscribe
                    // if (isLoggedIn)
                    if (label == "Sign In" && isLoggedIn != undefined) {
                        return "";
                    } else if (label == "Delivery" && deliveryInfo == null) {
                        return "";
                    } else {
                        return (
                            <Step key={label}>
                                <StepLabel StepIconComponent={QontoStepIcon}>
                                    {label}
                                </StepLabel>
                            </Step>
                        );
                    }
                })}
            </Stepper>
        </Box>
    );
};
export default CheckoutTrackerBar;
