import { Grid } from "@material-ui/core";
import { React, useEffect, useState } from "react";
import Nav from "react-bootstrap/Nav";
import LoginBar from "../../../Components/LoginBar/loginbar";
import Customization from "./Customization";
import MerchantSettings from "./MerchantSettings";
import profileService from "../../../services/profileService";
import merchantService from "../../../services/merchantService";
import "./style.css";
const Profile = ({ history }) => {
    const [tabClicked, setTabClicked] = useState("merchant");
    const [arData, setArData] = useState();
    const [pcData, setPcData] = useState();
    useEffect(() => {
        getMerchantData();
        getMerchantARData();
    }, []);

    const getMerchantData = async () => {
        await profileService
            .getProfile()
            .then((res) => {
                setPcData(res.settings);
            })
            .catch((e) => {});
    };

    const getMerchantARData = async () => {
        await merchantService
            .getMerchantInfo()
            .then((res) => {
                setArData(res[0]);
            })
            .catch((e) => {});
    };
    return (
        <Grid container>
            <Grid
                item
                style={{ marginLeft: "20px" }}
                className="p-3 admin-listing"
                xs={12}
                md={12}
                lg={12}
            >
                <Grid container>
                    <Grid item xs={12} md={12} lg={12}>
                        <Grid container className="mt-4 mb-2">
                            <Grid align="left" item xs={6} md={6} lg={6}>
                                <h2
                                    className="text-left color-contrast-dark ml-3"
                                    style={{ color: "black" }}
                                >
                                    <strong>Settings</strong>
                                </h2>
                            </Grid>
                            <Grid item xs={6} md={6} lg={6} align="right">
                                {" "}
                                <LoginBar />
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={12} md={12} lg={12}>
                        <Grid className="ml-2 mb-4" container>
                            <Grid item align="left" xs={2} md={2} lg={2}>
                                <Nav variant="pills" className="flex-column">
                                    <Nav.Item>
                                        <Nav.Link
                                            style={{
                                                color: "black",
                                                textDecoration:
                                                    tabClicked === "merchant"
                                                        ? "underline"
                                                        : "none",
                                            }}
                                            onClick={() => {
                                                setTabClicked("merchant");
                                            }}
                                            eventKey="merchant"
                                        >
                                            Merchant
                                        </Nav.Link>
                                    </Nav.Item>
                                    {/* <Nav.Item>
                                        <Nav.Link
                                            style={{
                                                color: "black",
                                                textDecoration:
                                                    tabClicked === "bank"
                                                        ? "underline"
                                                        : "none",
                                            }}
                                            onClick={() => {
                                                setTabClicked("bank");
                                            }}
                                            eventKey="bank"
                                        >
                                            Bank
                                        </Nav.Link>
                                    </Nav.Item> */}
                                    <Nav.Item>
                                        <Nav.Link
                                            style={{
                                                color: "black",
                                                textDecoration:
                                                    tabClicked ===
                                                    "customization"
                                                        ? "underline"
                                                        : "none",
                                            }}
                                            onClick={() => {
                                                setTabClicked("customization");
                                            }}
                                            eventKey="customization"
                                        >
                                            Customization
                                        </Nav.Link>
                                    </Nav.Item>
                                </Nav>
                            </Grid>
                            {tabClicked === "merchant" && (
                                <MerchantSettings
                                    pcData={pcData}
                                    arData={arData}
                                />
                            )}
                            {tabClicked === "customization" && (
                                <Customization
                                    pcData={pcData}
                                    arData={arData}
                                />
                            )}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default Profile;
