import {
    Grid,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
} from "@material-ui/core";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import invoiceService from "../../services/invoiceService";
import "./style.css";

const InvoiceGeneratedFromSubscription = (props) => {
    const [invoiceListFromThisSub, setInvoiceListFromThisSub] = useState([]);

    useEffect(() => {
        invoiceService
            .getInvoiceBySubsId(props.subs_id)
            .then((res) => {
                var sortedInvArray = res.sort(byInvID);
                setInvoiceListFromThisSub(sortedInvArray);
            })
            .catch((res) => {});
    }, [props.subs_id]);

    const downloadInvoice = (invId) => {
        axios({
            method: "POST",
            url: `${
                window.location.protocol + "//" + window.location.hostname
            }/backend/api/invoice_pdf/`,
            data: { inv_id: invId },
            responseType: "blob",
        })
            .then((res) => {
                var a = document.createElement("a");
                document.body.appendChild(a);
                a.style = "display: none";
                var url = window.URL.createObjectURL(res.data);
                a.href = url;
                a.download = `${invId}.pdf`;
                a.click();
                window.URL.revokeObjectURL(url);
            })
            .catch((err) => {
                // console.log("error");
                Swal.fire({
                    icon: "error",
                    title: "Could not download the invoice now",
                    text: "Please try again later.",
                });
            });
    };

    const invoiceResolved = (invoice_resolved, invoice_collection_status, inv_collection_batch, dueDateStr) => {
        var today = new Date();
        var todayDate = new Date(
           today.getFullYear() + "-" + (today.getMonth()) + "-" + today.getDate() + 5);
        var dueDate = new Date(dueDateStr.slice(0, 10));
        
        if ((invoice_resolved == "true" || invoice_resolved == true) && (parseInt(invoice_collection_status) == 0)){
          return "Paid";
        } else if ((invoice_collection_status != null && (parseInt(invoice_collection_status) != 0)) || (todayDate > dueDate))
         {
          return "Overdue";
        } else {
          return "Awaiting Payment";
        }
    };

    const byInvID = (a, b) => {
        if (a.inv_id > b.inv_id) {
            return -1;
        }
        if (a.inv_id < b.inv_id) {
            return 1;
        }
        return 0;
    };

    return (
        <Grid item xs={12} className="mt-5 invoice-listing-container">
            <h5 className="text-left ">
                Invoice Generated From this subscription
            </h5>
            <TableContainer component={Paper} className="mt-4">
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Invoice Id</TableCell>
                            <TableCell align="right">Date Generated</TableCell>
                            <TableCell align="right">Payment Date</TableCell>
                            <TableCell align="right">Invoice Total</TableCell>
                            <TableCell align="right">Status</TableCell>
                            {/* <TableCell align="center">Actions</TableCell> */}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {invoiceListFromThisSub.length > 0 ? (
                            invoiceListFromThisSub.map((inv, i) => (
                                <TableRow hover key={i}>
                                    <TableCell component="th" scope="row">
                                        {inv.inv_id}
                                    </TableCell>
                                    <TableCell align="right">
                                        {moment(inv.inv_issue_date).format(
                                            "DD MMM yyyy hh:mm A"
                                        )}
                                    </TableCell>
                                    <TableCell align="right">
                                        {moment(inv.inv_due_date).format(
                                            "DD MMM yyyy hh:mm A"
                                        )}
                                    </TableCell>
                                    <TableCell align="right">
                                        RM {inv.inv_total_amt.toFixed(2)}
                                    </TableCell>
                                    <TableCell align="right">
                                        {invoiceResolved(
                                            inv.inv_resolved.toString(), inv.inv_collection_status, inv.inv_collection_batch, inv.inv_due_date
                                        )}
                                    </TableCell>
                                    <TableCell align="center">
                                        <div
                                            className="d-flex justify-content-center"
                                            style={{ minHeight: "24px" }}
                                        >
                                            {/* <Tooltip title="Preview Invoice">
                        <a onClick={previewInvoice.bind(this, inv.inv_id)}>
                          <VisibilityIcon className="invoice-action-icon" />
                        </a>
                      </Tooltip> */}
                                            <Tooltip title="Download Invoice as pdf">
                                                <a
                                                    onClick={downloadInvoice.bind(
                                                        this,
                                                        inv.inv_id
                                                    )}
                                                    href="#"
                                                >
                                                    <PictureAsPdfIcon className="invoice-action-icon" />
                                                </a>
                                            </Tooltip>
                                        </div>
                                    </TableCell>
                                </TableRow>
                            ))
                        ) : (
                            <TableRow>
                                <TableCell
                                    className="py-4"
                                    component="th"
                                    scope="row"
                                >
                                    There are no invoice generated from this
                                    subscription yet.
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        </Grid>
    );
};

export default InvoiceGeneratedFromSubscription;
