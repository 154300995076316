import "./style.css";
import Grid from "@material-ui/core/Grid";
import Sidebar from "../../../Components/Sidebar/sidebar";
import { FormControl } from "@material-ui/core";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { TextField } from "@material-ui/core";
import userService from "../../../services/userService";
import { MenuItem } from "@material-ui/core";
import LoginBar from "../../../Components/LoginBar/loginbar";
import { Modal, ButtonGroup, InputGroup, Form } from "react-bootstrap";

import Button from "@material-ui/core/Button";
import { Card } from "react-bootstrap";
import { useEffect, useState } from "react";
import FpxLogo from "../../../Images/fpx-logo.png";
import cardIcon from "../../../Images/visa-master-logo.png";
import Image from "react-bootstrap/Image";
import customerService from "../../../services/customerService";
import { store } from "../../../index.js";
import profileService from "../../../services/profileService";
import Swal from "sweetalert2";
import EditIcon from "@mui/icons-material/Edit";
import { IconButton } from "@mui/material";
import { Link, Breadcrumbs, Typography } from "@material-ui/core";
import PaymentDetails from "./PaymentDetails";

const Settings = ({ history, match }) => {
    const [settingsTab, setSettingsTab] = useState("account");
    const [paymentMethod, setPaymentMethod] = useState(1);
    const forgotPasswordLink =
        String(window.location.origin).split("/")[0] +
        "/backend/api/password_reset/";
    const [customerData, setCustomerData] = useState("");
    const [customerFName, setCustomerFName] = useState("");
    const [customerLName, setCustomerLName] = useState("");
    const [customerEmail, setCustomerEmail] = useState("");
    const [customerPhone, setCustomerPhone] = useState("");
    const [customerAddress, setCustomerAddress] = useState("");
    const [customerState, setCustomerState] = useState("");
    const [customerDeliveryRemarks, setCustomerDeliveryRemarks] = useState("");
    const [customerPostcode, setCustomerPostcode] = useState("");
    const [isEdit, setIsEdit] = useState(false);
    const [countryData, setCountryData] = useState({ value: [] });
    const [vajraCountry, setVajraCountry] = useState();
    const [vajraLanguage, setVajraLanguage] = useState();
    const [vajraKecharaMembership, setVajraKecharaMembership] = useState("");
    const [custMandateRef, setCustMandateRef] = useState("");
    const [selectedBank, setSelectedBank] = useState("");
    useEffect(() => {
        // get user detail
        getCustomerData();
    }, []);

    useEffect(() => {
        fetch("https://restcountries.com/v3.1/all")
            .then((res) => {
                res.json().then((data) => {
                    // set country data sort by name
                    // setCountryData(
                    //     data
                    //         .sort((a, b) =>
                    //             a.name.official > b.name.official ? 1 : -1
                    //         )
                    //         .then((data) => {
                    //             return [
                    //                 country.name.official,
                    //                 country.flags.png,
                    //             ];
                    //         })
                    // );
                    const sortCountryName = data.sort((a, b) =>
                        a.name.official > b.name.official ? 1 : -1
                    );
                    // console.log(sortCountryName, "sortCountryName");
                    const countryInfo = sortCountryName.map((country) => {
                        return [country.name.official, country.flags.png];
                    });
                    setCountryData({ value: countryInfo });
                });
            })
            .catch((data, status) => {
                console.error("Request failed:", data, status);
            });
    }, []);
    const getCustomerData = async () =>
        await customerService
            .getSpecificCustomerFromAR(
                store.getState().loggedInUserReducer.userData.ar_system.cust_id
            )
            .then((res) => {
                setCustomerData(res);

                setCustomerFName({ value: res.cust_fname });
                setCustomerLName({ value: res.cust_lname });
                setCustomerEmail({ value: res.cust_email });
                setCustomerPhone({ value: res.cust_contact_no });
                setCustomerAddress({ value: res.cust_addr_line_1 });
                setCustomerState({ value: res.cust_addr_state });
                setCustomerPostcode({ value: res.cust_addr_postcode });
                setVajraKecharaMembership({
                    value: res.vajra_kechara_membership,
                });
                setVajraLanguage({ value: res.vajra_language });
                setVajraCountry({ value: res.vajra_country });
                setCustomerDeliveryRemarks({
                    value: res.cust_delivery_instructions,
                });
                setCustMandateRef({ value: res.cust_mandate_ref });
            });
    const validateCustEmail = (email) => {
        var isValid = true;

        //Reset the Error message
        var errorMessage = "";
        //Check empty
        if (email == "") {
            if (!!errorMessage) errorMessage += "\n";
            errorMessage += "Email cannot be empty";
            isValid = false;
        }
        var re =
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        // if email is not valid show error
        if (!re.test(String(email).toLowerCase())) {
            if (!!errorMessage) errorMessage += "\n";
            errorMessage += "Invalid email format";

            isValid = false;
        }
        setCustomerEmail({
            ...customerEmail,
            valid: isValid,
            error: errorMessage,
        }); //Set Error message

        return isValid;
    };

    const validateCustFName = (fname) => {
        var isValid = true;
        var errorMessage = "";

        //Check empty
        if (fname == "") {
            if (!!errorMessage) errorMessage += "\n";
            errorMessage += "First Name cannot be empty";
            isValid = false;
        }
        setCustomerFName({
            ...customerFName,
            valid: isValid,
            error: errorMessage,
        }); //Set Error message
        return isValid;
    };

    const validateCustLName = (fname) => {
        var isValid = true;
        var errorMessage = "";

        //Check empty
        if (fname == "") {
            if (!!errorMessage) errorMessage += "\n";
            errorMessage += "Last Name cannot be empty";
            isValid = false;
        }
        setCustomerLName({
            ...customerLName,
            valid: isValid,
            error: errorMessage,
        }); //Set Error message
        return isValid;
    };

    const validateCustPhone = (phone) => {
        var isValid = true;
        var errorMessage = "";

        //Check empty
        if (phone == "") {
            if (!!errorMessage) errorMessage += "\n";
            errorMessage += "Phone Number cannot be empty";
            isValid = false;
        }
        // if phone is not in malaysia phone number format, show error
        if (
            !/^(\+?6?01)[0-46-9]-*[0-9]{7,8}$/.test(phone) &&
            !/^(\+?6?01)[0-46-9]-*[0-9]{7,8}$/.test(phone)
        ) {
            if (!!errorMessage) errorMessage += "\n";
            errorMessage +=
                "Phone number has to start with 60 and can only have 10/11 numbers ";
            isValid = false;
        }
        setCustomerPhone({
            ...customerPhone,
            valid: isValid,
            error: errorMessage,
        }); //Set Error message
        return isValid;
    };

    const validateCustAddress = (address) => {
        var isValid = true;
        var errorMessage = "";

        //Check empty
        if (address == "") {
            if (!!errorMessage) errorMessage += "\n";
            errorMessage += "Address cannot be empty";
            isValid = false;
        }
        setCustomerAddress({
            ...customerAddress,

            valid: isValid,
            error: errorMessage,
        }); //Set Error message
        return isValid;
    };

    const validateCustPostcode = (postcode) => {
        var isValid = true;
        var errorMessage = "";

        //Check empty
        if (postcode == "") {
            if (!!errorMessage) errorMessage += "\n";
            errorMessage += "Postcode cannot be empty";
            isValid = false;
        }
        // if postcode is neither 5 or 4 digit show error

        if (postcode.length != 5 && postcode.length != 4) {
            if (!!errorMessage) errorMessage += "\n";
            errorMessage += "Postcode consists of 4 to 5 digits";
            isValid = false;
        }
        setCustomerPostcode({
            ...customerPostcode,
            valid: isValid,
            error: errorMessage,
        }); //Set Error message
        return isValid;
    };

    const validateProfile = () => {
        var isValid = true;
        isValid = isValid && validateCustEmail(customerEmail.value);
        isValid = isValid && validateCustFName(customerFName.value);
        isValid = isValid && validateCustLName(customerLName.value);
        isValid = isValid && validateCustPhone(customerPhone.value);
        isValid = isValid && validateCustAddress(customerAddress.value);
        // isValid = isValid && validateCustPostcode(customerPostcode.value);
        return isValid;
        // validate email format
        // validate postcode format
        // validate phone number format
    };
    const editProfile = () => {
        // check postcode if exist in databse
        if (
            validateCustPostcode(customerPostcode.value) &&
            !window.location.href.includes("vajrasecrets")
        ) {
            profileService
                .getPostcode(customerPostcode.value)
                .then((res) => {
                    if (validateProfile()) {
                        var data = {
                            cust_fname: customerFName.value,
                            cust_lname: customerLName.value,
                            cust_email: customerEmail.value,
                            cust_contact_no: customerPhone.value,
                            cust_addr_line_1: customerAddress.value,
                            cust_addr_state: customerState.value,
                            cust_addr_postcode: customerPostcode.value,
                            cust_delivery_instructions:
                                customerDeliveryRemarks.value,
                        };

                        customerService
                            .editCustomerFromAR(data, customerData.cust_id)
                            .then((res) => {
                                // swal fire success and refresh page
                                Swal.fire({
                                    title: "Success",
                                    text: "Profile updated",
                                    icon: "success",
                                    showCancelButton: false,
                                    confirmButtonColor:
                                        store.getState().colorState.color,
                                    confirmButtonText: "OK",
                                }).then((result) => {
                                    if (result.value) {
                                        // userService.logout().then((res) => {
                                        //     // history.push("/");
                                        //     window.location.reload();
                                        // });
                                        window.location.reload();
                                    }
                                });
                            })
                            .catch((err) => {
                                console.error(err, "err for edit profile");
                            });
                    }
                })
                .catch((err) => {
                    console.error(err);
                    setCustomerPostcode({
                        ...customerPostcode,
                        valid: false,
                        error: "We do not deliver to this postcode",
                    });
                });
        } else if (window.location.href.includes("vajrasecrets")) {
            var data = {
                vajra_kechara_membership: vajraKecharaMembership.value,
                vajra_country: vajraCountry.value,
                vajra_language: vajraLanguage.value,
                cust_fname: customerFName.value,
                cust_lname: customerLName.value,
            };
            customerService
                .editCustomerFromAR(data, customerData.cust_id)
                .then((res) => {
                    // swal fire success and refresh page
                    Swal.fire({
                        title: "Success",
                        text: "Profile updated",
                        icon: "success",
                        showCancelButton: false,
                        confirmButtonColor: store.getState().colorState.color,
                        confirmButtonText: "OK",
                    }).then((result) => {
                        if (result.value) {
                            // userService.logout().then((res) => {
                            //     history.push("/");
                            //     window.location.reload();
                            // });
                            window.location.reload();
                        }
                    });
                })
                .catch((err) => {
                    console.error(err, "err for edit profile");
                });
        }
    };
    const renderAccountSettings = () => {
        return (
            <>
                <Grid item xs={12} md={12} lg={12}>
                    <Card style={{ width: "99%" }}>
                        <Card.Body>
                            <Grid
                                item
                                className="mt-1"
                                align="left"
                                xs={12}
                                lg={12}
                            >
                                <h2
                                    className="text-left color-contrast-dark"
                                    style={{ color: "black" }}
                                >
                                    <strong>Account Settings</strong>
                                </h2>
                            </Grid>
                            <Grid
                                item
                                className="mt-2"
                                align="left"
                                xs={12}
                                lg={12}
                                style={{
                                    display: "flex",
                                    flexDirection: "row",
                                }}
                            >
                                <strong
                                    style={{
                                        fontSize:
                                            window.innerWidth < 1000
                                                ? "20px"
                                                : "25px",
                                    }}
                                >
                                    Basic Information
                                </strong>
                                <div
                                    style={{
                                        display: isEdit ? "none" : "",
                                    }}
                                    className="ml-1"
                                >
                                    <Button
                                        startIcon={
                                            <EditIcon
                                                className="icon-mobile"
                                                style={{ fontSize: "15px" }}
                                            ></EditIcon>
                                        }
                                        style={{
                                            marginLeft: "20px",
                                            // padding: "0px",
                                            fontSize: "10px",
                                            borderRadius: "5px",
                                            margin: "8px",
                                            height: "25px",
                                            // marginBottom: "5px",
                                            backgroundColor:
                                                "rgba(0, 0, 0, 0.54)",
                                            color: "white",
                                        }}
                                        onClick={() => setIsEdit(true)}
                                    >
                                        Edit
                                    </Button>
                                </div>
                            </Grid>
                            <Grid className="mt-3" container>
                                <Grid item xs={6} lg={6}>
                                    <InputGroup
                                        style={{ width: "95%" }}
                                        hasValidation
                                    >
                                        <Form.Control
                                            class="form-control"
                                            style={{
                                                border: "1px solid black",
                                                borderRadius: "5px",
                                            }}
                                            placeholder="First name"
                                            type="text"
                                            required
                                            disabled={!isEdit}
                                            value={
                                                customerFName !== ""
                                                    ? customerFName.value
                                                          .charAt(0)
                                                          .toUpperCase() +
                                                      customerFName.value.slice(
                                                          1
                                                      )
                                                    : ""
                                            }
                                            onChange={(e) => {
                                                setCustomerFName({
                                                    value: e.target.value,
                                                });
                                            }}
                                        />
                                        <Form.Control.Feedback
                                            className="text-left"
                                            type="invalid"
                                        >
                                            {customerFName.error}
                                        </Form.Control.Feedback>
                                    </InputGroup>
                                </Grid>
                                <Grid item xs={6} lg={6}>
                                    <InputGroup
                                        className={
                                            window.innerWidth < 1200
                                                ? "mt-3"
                                                : "input-2"
                                        }
                                        hasValidation
                                    >
                                        <Form.Control
                                            class="form-control"
                                            style={{
                                                border: "1px solid black",
                                                borderRadius: "5px",
                                            }}
                                            placeholder="Last name"
                                            type="text"
                                            required
                                            disabled={!isEdit}
                                            value={
                                                customerLName !== ""
                                                    ? customerLName.value
                                                          .charAt(0)
                                                          .toUpperCase() +
                                                      customerLName.value.slice(
                                                          1
                                                      )
                                                    : ""
                                            }
                                            onChange={(e) => {
                                                setCustomerLName({
                                                    value: e.target.value,
                                                });
                                            }}
                                            isInvalid={
                                                customerLName.valid == false
                                                    ? true
                                                    : false
                                            }
                                            onKeyUp={(e) => {
                                                if (e.key === "Enter") {
                                                    // log databody
                                                    editProfile();
                                                } else {
                                                    setCustomerLName({
                                                        ...customerLName,
                                                        value: e.target.value,
                                                    });
                                                }
                                            }}
                                        />
                                        <Form.Control.Feedback
                                            className="text-left"
                                            type="invalid"
                                        >
                                            {/* {registerLName.error} */}
                                        </Form.Control.Feedback>
                                    </InputGroup>
                                </Grid>
                                <Grid item xs={12} lg={12} className="mt-4">
                                    <InputGroup
                                        className={
                                            window.innerWidth < 1200
                                                ? "mt-3"
                                                : "input-2"
                                        }
                                        style={{ width: "100%" }}
                                        hasValidation
                                    >
                                        <Form.Control
                                            class="form-control"
                                            style={{
                                                border: "1px solid black",
                                                borderRadius: "5px",
                                            }}
                                            placeholder="Email"
                                            type="text"
                                            required
                                            disabled
                                            value={
                                                customerEmail !== ""
                                                    ? customerEmail.value
                                                    : ""
                                            }
                                            onChange={(e) => {
                                                setCustomerEmail({
                                                    ...customerEmail,
                                                    value: e.target.value,
                                                });
                                            }}
                                            isInvalid={
                                                customerEmail.valid == false
                                                    ? true
                                                    : false
                                            }
                                            onKeyUp={(e) => {
                                                if (e.key === "Enter") {
                                                    // log databody
                                                    editProfile();
                                                } else {
                                                    setCustomerEmail({
                                                        ...customerEmail,
                                                        value: e.target.value,
                                                    });
                                                }
                                            }}
                                        />
                                        <Form.Control.Feedback
                                            className="text-left"
                                            type="invalid"
                                        >
                                            {customerEmail.error}
                                        </Form.Control.Feedback>
                                    </InputGroup>
                                </Grid>
                            </Grid>
                        </Card.Body>
                    </Card>
                </Grid>
                {!window.location.href.includes("vajrasecrets") ? (
                    <Grid item className="mt-4" xs={12} lg={12}>
                        <Card style={{ width: "99%" }}>
                            <Card.Body>
                                <Grid
                                    item
                                    className="mt-1"
                                    align="left"
                                    xs={12}
                                    lg={12}
                                    // every component in a row
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                    }}
                                >
                                    <strong
                                        style={{
                                            fontSize:
                                                window.innerWidth < 1000
                                                    ? "25px"
                                                    : "33px",
                                        }}
                                    >
                                        Delivery Information
                                    </strong>
                                    {/* edit icon set isedit to false */}
                                    <div
                                        style={{
                                            display: isEdit ? "none" : "",
                                        }}
                                        className="ml-1 mt-2"
                                    >
                                        <Button
                                            startIcon={
                                                <EditIcon
                                                    className="icon-mobile"
                                                    style={{ fontSize: "15px" }}
                                                ></EditIcon>
                                            }
                                            style={{
                                                marginLeft: "20px",
                                                // padding: "0px",
                                                fontSize: "10px",
                                                borderRadius: "5px",
                                                margin: "8px",
                                                height: "25px",
                                                // marginBottom: "5px",
                                                backgroundColor:
                                                    "rgba(0, 0, 0, 0.54)",
                                                color: "white",
                                            }}
                                            onClick={() => setIsEdit(true)}
                                        >
                                            Edit
                                        </Button>
                                    </div>
                                </Grid>

                                <Grid className="mt-3" container>
                                    <Grid item xs={12} lg={12}>
                                        <InputGroup
                                            className={
                                                window.innerWidth < 1200
                                                    ? "mt-3"
                                                    : "input-2"
                                            }
                                            hasValidation
                                        >
                                            <Form.Control
                                                class="form-control"
                                                style={{
                                                    border: "1px solid black",
                                                    borderRadius: "5px",
                                                }}
                                                placeholder="Address Line 1"
                                                type="text"
                                                required
                                                disabled={!isEdit}
                                                value={
                                                    customerAddress !== ""
                                                        ? customerAddress.value
                                                        : ""
                                                }
                                                onChange={(e) => {
                                                    setCustomerAddress({
                                                        value: e.target.value,
                                                    });
                                                }}
                                                isInvalid={
                                                    customerAddress.valid ==
                                                    false
                                                        ? true
                                                        : false
                                                }
                                                onKeyUp={(e) => {
                                                    if (e.key === "Enter") {
                                                        // log databody
                                                        editProfile();
                                                    } else {
                                                        setCustomerAddress({
                                                            ...customerAddress,
                                                            value: e.target
                                                                .value,
                                                        });
                                                    }
                                                }}
                                            />
                                            <Form.Control.Feedback
                                                className="text-left"
                                                type="invalid"
                                            >
                                                {customerAddress.error}
                                            </Form.Control.Feedback>
                                        </InputGroup>
                                    </Grid>

                                    <Grid container>
                                        <Grid
                                            item
                                            xs={6}
                                            lg={6}
                                            className="mt-4 mb-3"
                                        >
                                            <InputGroup
                                                className={
                                                    window.innerWidth < 1200
                                                        ? "mt-3"
                                                        : "input-2"
                                                }
                                                style={{ width: "95%" }}
                                                hasValidation
                                            >
                                                <Form.Control
                                                    class="form-control"
                                                    style={{
                                                        border: "1px solid black",
                                                        borderRadius: "5px",
                                                    }}
                                                    placeholder="Postcode"
                                                    type="text"
                                                    required
                                                    value={
                                                        customerPostcode !== ""
                                                            ? customerPostcode.value
                                                            : ""
                                                    }
                                                    onChange={(e) => {
                                                        setCustomerPostcode({
                                                            value: e.target
                                                                .value,
                                                        });
                                                    }}
                                                    disabled={!isEdit}
                                                    isInvalid={
                                                        customerPostcode.valid ==
                                                        false
                                                            ? true
                                                            : false
                                                    }
                                                    onKeyUp={(e) => {
                                                        if (e.key === "Enter") {
                                                            // log databody
                                                            editProfile();
                                                        } else {
                                                            setCustomerPostcode(
                                                                {
                                                                    ...customerPostcode,
                                                                    value: e
                                                                        .target
                                                                        .value,
                                                                }
                                                            );
                                                        }
                                                    }}
                                                />
                                                <Form.Control.Feedback
                                                    className="text-left"
                                                    type="invalid"
                                                >
                                                    {customerPostcode.error}
                                                </Form.Control.Feedback>
                                            </InputGroup>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={6}
                                            lg={6}
                                            className="mt-4"
                                        >
                                            <InputGroup
                                                className={
                                                    window.innerWidth < 1200
                                                        ? "mt-3"
                                                        : "input-2"
                                                }
                                                style={{ width: "100%" }}
                                                hasValidation
                                            >
                                                <Form.Control
                                                    class="form-control"
                                                    style={{
                                                        border: "1px solid black",
                                                        borderRadius: "5px",
                                                    }}
                                                    placeholder="State"
                                                    type="text"
                                                    required
                                                    disabled={true}
                                                    value={
                                                        customerState !== ""
                                                            ? customerState.value
                                                            : ""
                                                    }
                                                    onChange={(e) => {
                                                        setCustomerState({
                                                            value: e.target
                                                                .value,
                                                        });
                                                    }}
                                                    isInvalid={
                                                        customerState.valid ==
                                                        false
                                                            ? true
                                                            : false
                                                    }
                                                    onKeyUp={(e) => {
                                                        if (e.key === "Enter") {
                                                            // log databody
                                                            editProfile();
                                                        } else {
                                                            setCustomerState({
                                                                ...customerState,
                                                                value: e.target
                                                                    .value,
                                                            });
                                                        }
                                                    }}
                                                />
                                                <Form.Control.Feedback
                                                    className="text-left"
                                                    type="invalid"
                                                >
                                                    {customerState.error}
                                                </Form.Control.Feedback>
                                            </InputGroup>
                                        </Grid>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        lg={12}
                                        className="mt-2 mb-3"
                                    >
                                        <InputGroup
                                            className={
                                                window.innerWidth < 1200
                                                    ? "mt-3"
                                                    : "input-2"
                                            }
                                            style={{ width: "100%" }}
                                            hasValidation
                                        >
                                            <Form.Control
                                                class="form-control"
                                                style={{
                                                    border: "1px solid black",
                                                    borderRadius: "5px",
                                                }}
                                                placeholder="Phone Number"
                                                type="text"
                                                required
                                                value={
                                                    customerPhone !== ""
                                                        ? customerPhone.value
                                                        : ""
                                                }
                                                onChange={(e) => {
                                                    setCustomerPhone(
                                                        e.target.value
                                                    );
                                                }}
                                                isInvalid={
                                                    customerPhone.valid == false
                                                        ? true
                                                        : false
                                                }
                                                disabled={!isEdit}
                                                onKeyUp={(e) => {
                                                    if (e.key === "Enter") {
                                                        // log databody
                                                        editProfile();
                                                    } else {
                                                        setCustomerPhone({
                                                            ...customerPhone,
                                                            value: e.target
                                                                .value,
                                                        });
                                                    }
                                                }}
                                            />
                                            <Form.Control.Feedback
                                                className="text-left"
                                                type="invalid"
                                            >
                                                {customerPhone.error}
                                            </Form.Control.Feedback>
                                        </InputGroup>
                                    </Grid>
                                    <Grid className="mt-2" item xs={12} lg={12}>
                                        <InputGroup
                                            className={
                                                window.innerWidth < 1200
                                                    ? "mt-3"
                                                    : "input-2"
                                            }
                                            hasValidation
                                        >
                                            <Form
                                                style={{
                                                    width: "100%",
                                                }}
                                            >
                                                <Form.Group className="mb-3">
                                                    <Form.Control
                                                        style={{
                                                            borderRadius: "5px",
                                                            border: "1px solid black",
                                                        }}
                                                        placeholder="Delivery Remarks"
                                                        as="textarea"
                                                        rows={4}
                                                        disabled={!isEdit}
                                                        value={
                                                            customerDeliveryRemarks !==
                                                            ""
                                                                ? customerDeliveryRemarks.value
                                                                : ""
                                                        }
                                                        onChange={(e) => {
                                                            setCustomerDeliveryRemarks(
                                                                {
                                                                    value: e
                                                                        .target
                                                                        .value,
                                                                }
                                                            );
                                                        }}
                                                    />
                                                </Form.Group>
                                            </Form>
                                        </InputGroup>
                                    </Grid>
                                </Grid>
                            </Card.Body>
                        </Card>
                        <Grid
                            item
                            className={`mb-3 ${
                                window.innerWidth < 1000 ? "ml-3" : ""
                            }`}
                            xs={12}
                            lg={12}
                        >
                            {/* create save and cancel button */}
                            {isEdit ? (
                                <Grid
                                    container
                                    className="mt-3"
                                    justify="flex-end"
                                >
                                    <Grid item align="left" xs={6} lg={6}>
                                        <Button
                                            variant="outline-primary"
                                            className="btn-block profile-cancel-btn"
                                            onClick={() => {
                                                // log databody
                                                localStorage.removeItem(
                                                    "sidebar-state"
                                                );
                                                history.push("/");
                                            }}
                                        >
                                            Cancel
                                        </Button>
                                    </Grid>
                                    <Grid item xs={6} lg={6}>
                                        <Button
                                            variant="outline-primary"
                                            style={{
                                                backgroundColor:
                                                    store.getState().colorState
                                                        .color,
                                                color: store.getState()
                                                    .colorState.font_color,
                                            }}
                                            className="btn-block mr-5 profile-save-btn"
                                            onClick={() => {
                                                editProfile();
                                            }}
                                        >
                                            Save
                                        </Button>
                                    </Grid>
                                </Grid>
                            ) : null}
                        </Grid>
                    </Grid>
                ) : (
                    <Grid item className="mt-4" xs={12} lg={12}>
                        <Card style={{ width: "99%" }}>
                            <Card.Body>
                                <Grid
                                    item
                                    className="mt-1"
                                    align="left"
                                    xs={12}
                                    lg={12}
                                    // every component in a row
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                    }}
                                >
                                    <h2
                                        className="text-left color-contrast-dark"
                                        style={{ color: "black" }}
                                    >
                                        <strong> Additional Details</strong>
                                    </h2>
                                    {/* edit icon set isedit to false */}
                                </Grid>

                                <Grid className="mt-3" container>
                                    <Grid item xs={12} lg={12}>
                                        <InputGroup
                                            className={
                                                window.innerWidth < 1200
                                                    ? "mt-3"
                                                    : "input-2"
                                            }
                                            hasValidation
                                        >
                                            <Form.Control
                                                class="form-control"
                                                style={{
                                                    border: "1px solid black",
                                                    borderRadius: "5px",
                                                }}
                                                placeholder="Kechara Membership #"
                                                type="text"
                                                required
                                                disabled={!isEdit}
                                                value={
                                                    vajraKecharaMembership !==
                                                    ""
                                                        ? vajraKecharaMembership.value
                                                        : ""
                                                }
                                                onChange={(e) => {
                                                    setVajraKecharaMembership({
                                                        value: e.target.value,
                                                    });
                                                }}
                                                onKeyUp={(e) => {
                                                    if (e.key === "Enter") {
                                                        // log databody
                                                        editProfile();
                                                    } else {
                                                        setVajraKecharaMembership(
                                                            {
                                                                value: e.target
                                                                    .value,
                                                            }
                                                        );
                                                    }
                                                }}
                                            />
                                        </InputGroup>
                                    </Grid>

                                    <Grid container>
                                        <Grid
                                            item
                                            xs={6}
                                            lg={6}
                                            className="mt-4 mb-3"
                                        >
                                            <InputGroup
                                                className={
                                                    window.innerWidth < 1200
                                                        ? "mt-3"
                                                        : "input-2"
                                                }
                                                style={{ width: "95%" }}
                                                hasValidation
                                            >
                                                <Form.Control
                                                    class="form-control"
                                                    style={{
                                                        border: "1px solid black",
                                                        borderRadius: "5px",
                                                        color: "grey",
                                                    }}
                                                    disabled={!isEdit}
                                                    value={
                                                        vajraLanguage !==
                                                        undefined
                                                            ? vajraLanguage.value
                                                            : ""
                                                    }
                                                    placeholder="Preferred language"
                                                    as="select"
                                                    onChange={(e) => {
                                                        setVajraLanguage({
                                                            value: e.target
                                                                .value,
                                                        });
                                                    }}
                                                >
                                                    <option
                                                        disabled
                                                        selected
                                                        value=""
                                                    >
                                                        Preferred Language
                                                    </option>
                                                    <option value="English">
                                                        English
                                                    </option>
                                                    <option value="Chinese">
                                                        Chinese
                                                    </option>
                                                </Form.Control>
                                            </InputGroup>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={6}
                                            lg={6}
                                            className="mt-4"
                                        >
                                            <InputGroup
                                                className={
                                                    window.innerWidth < 1200
                                                        ? "mt-3"
                                                        : "input-2"
                                                }
                                                style={{ width: "100%" }}
                                                hasValidation
                                            >
                                                <Form.Control
                                                    class="form-control"
                                                    style={{
                                                        border: "1px solid black",
                                                        borderRadius: "5px",
                                                        color: "grey",
                                                    }}
                                                    disabled={!isEdit}
                                                    placeholder="Country"
                                                    as="select"
                                                    value={
                                                        vajraCountry !==
                                                        undefined
                                                            ? vajraCountry.value
                                                            : ""
                                                    }
                                                    onChange={(e) => {
                                                        setVajraCountry({
                                                            value: e.target
                                                                .value,
                                                        });
                                                    }}
                                                >
                                                    <option
                                                        disabled
                                                        selected
                                                        value=""
                                                    >
                                                        Country
                                                    </option>
                                                    {countryData.value.map(
                                                        (b, index) => (
                                                            <option
                                                                // style={{
                                                                //     verticalAlign:
                                                                //         "middle",
                                                                // }}
                                                                // className="bankLabel"
                                                                value={b[0]}
                                                            >
                                                                {b[0]}
                                                            </option>
                                                        )
                                                    )}
                                                </Form.Control>
                                            </InputGroup>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Card.Body>
                        </Card>
                        <Grid
                            item
                            className={`mb-3 ${
                                window.innerWidth < 1000 ? "ml-3" : ""
                            }`}
                            xs={12}
                            lg={12}
                        >
                            {/* create save and cancel button */}
                            {isEdit ? (
                                <Grid
                                    container
                                    className="mt-3"
                                    justify="flex-end"
                                >
                                    <Grid item align="left" xs={6} lg={6}>
                                        <Button
                                            variant="outline-primary"
                                            className="btn-block profile-cancel-btn"
                                            onClick={() => {
                                                // log databody
                                                setIsEdit(true);
                                            }}
                                        >
                                            Cancel
                                        </Button>
                                    </Grid>
                                    <Grid item xs={6} lg={6}>
                                        <Button
                                            variant="outline-primary"
                                            style={{
                                                backgroundColor:
                                                    store.getState().colorState
                                                        .color,
                                                color: store.getState()
                                                    .colorState.font_color,
                                            }}
                                            className="btn-block mr-5 profile-save-btn"
                                            onClick={() => {
                                                editProfile();
                                            }}
                                        >
                                            Save
                                        </Button>
                                    </Grid>
                                </Grid>
                            ) : null}
                        </Grid>
                    </Grid>
                )}
            </>
        );
    };

    return (
        <Grid container>
            <Grid
                container
                style={{ marginTop: "60px", marginLeft: "10px" }}
                xs={12}
                md={12}
                lg={12}
            >
                {/* profile icon */}
                <Grid
                    container
                    style={{ marginLeft: "20px" }}
                    className="mt-4 ml-3"
                >
                    {/* profile icon and username dropdown */}
                    <Grid align="left" item xs={6} md={6} lg={6}>
                        <h2
                            className="text-left color-contrast-dark"
                            style={{ color: "black" }}
                        >
                            <strong>Settings</strong>
                        </h2>
                    </Grid>
                    <Grid item xs={6} md={6} lg={6} align="right">
                        {" "}
                        <LoginBar />
                    </Grid>
                </Grid>

                <Grid
                    item
                    style={{
                        fontSize: window.innerWidth < 1000 ? "0px" : "",
                        marginLeft: "20px",
                    }}
                    className={`mb-3 ml-4 mt-2`}
                    align="left"
                    xs={12}
                    md={12}
                    lg={12}
                >
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link
                            style={{
                                textDecoration:
                                    settingsTab == "account" ? "underline" : "",
                            }}
                            underline="hover"
                            onClick={(e) => {
                                setSettingsTab("account");
                            }}
                            color="inherit"
                        >
                            Account
                        </Link>
                        {custMandateRef.value == null ? null : (
                            <Link
                                underline="hover"
                                color="inherit"
                                style={{
                                    textDecoration:
                                        settingsTab == "payment"
                                            ? "underline"
                                            : "",
                                }}
                                onClick={(e) => {
                                    setSettingsTab("payment");
                                }}
                            >
                                Payment Details
                            </Link>
                        )}
                        <Link
                            href={forgotPasswordLink}
                            target="_blank"
                            color="inherit"
                        >
                            Change Password
                        </Link>
                    </Breadcrumbs>
                    {/* <Grid className="mt-4" item xs={12} md={12} lg={12}>
                        <p
                            style={{
                                textDecoration:
                                    settingsTab == "account" ? "underline" : "",
                            }}
                        >
                            <a
                                onClick={(e) => {
                                    setSettingsTab("account");
                                }}
                            >
                                Account
                            </a>
                        </p>
                    </Grid>
                    <Grid className="mt-4" item xs={12} lg={12}>
                            <p
                                style={{
                                    textDecoration:
                                        settingsTab == "payment"
                                            ? "underline"
                                            : "",
                                }}
                            >
                                <a
                                    onClick={(e) => {
                                        setSettingsTab("payment");
                                    }}
                                >
                                    Payment Details
                                </a>
                            </p>
                        </Grid>
                    <Grid className="mt-4" item xs={12} lg={12}>
                        <p>
                            <a
                                style={{ color: "black" }}
                                href={forgotPasswordLink}
                                target="_blank"
                            >
                                Change Password
                            </a>
                        </p>
                    </Grid> */}
                </Grid>
                <Grid container style={{ marginLeft: "20px" }} className="ml-4">
                    {settingsTab == "account" ? (
                         renderAccountSettings()
                    ) : (
                        <PaymentDetails
                            mandateRef={custMandateRef.value}
                            custId={customerData.cust_id}
                        />
                    )}
                </Grid>
            </Grid>
        </Grid>
    );
};

export default Settings;
