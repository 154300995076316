import { Modal } from "react-bootstrap";
import { Grid } from "@material-ui/core";
import { Form } from "react-bootstrap";
import Button from "@material-ui/core/Button";
import subscriptionService from "../../services/subscriptionService";
import Swal from "sweetalert2";
import { store } from "../../index.js";

import { useState } from "react";
const UnsubscribeSurvey = ({ subsId, modalState, closeModal }) => {
    const [additionalComment, setAdditionalComment] = useState("");
    const [checkedOption, setCheckedOption] = useState();

    const pichaOptions = [
        "It no longer fits my lifestyle",
        "Couldn’t finish the meals in time",
        "The product is too expensive",
        "Quality of food is unsatisfactory",
        "I found another alternative",
    ];
    const kombuchaOptions = [
        "It no longer fits my lifestyle",
        "Couldn’t finish the product in time",
        "The product is too expensive",
        "Quality of product is unsatisfactory",
        "I found another alternative",
    ];
    const vajraOptions = [
        "Change to a different package",
        "It no longer fits my lifestyle/beliefs",
        "It no longer fits my budget",
        "Results didn't meet my expectations",
        "Poor customer service",
        "I found an alternative service provider",
        "Other",
    ];
    const updateSubsStatus = () => {
        let merchantOptions;
        if (window.location.href.includes("wildkombucha")) {
            merchantOptions = kombuchaOptions;
        } else if (window.location.href.includes("pichaeats")) {
            merchantOptions = pichaOptions;
        } else if (window.location.href.includes("vajrasecrets")) {
            merchantOptions = vajraOptions;
        } else {
            merchantOptions = pichaOptions;
        }
        const unsubscribeChoice = convertCheckedOption(
            checkedOption,
            merchantOptions
        );
        console.log(unsubscribeChoice, "this is the unsubscribe choice");
        const dataBody = {
            subs_status: "awaiting cancellation",
            unsubscribe_survey: unsubscribeChoice,
            additional_comment: additionalComment,
        };

        subscriptionService
            .editSubscription(subsId, dataBody)
            .then((e) => {
                Swal.fire({
                    icon: "success",
                    text: "An email has been sent to the admin and we will contact you shortly.",
                    confirmButtonText: "OK",
                    confirmButtonColor: store.getState().colorState.color,
                })
                    .then((res) => {
                        window.location.reload();
                    })
                    .catch((err) => {});
            })
            .catch((e) => {
                console.error(e, "erro");
            });
    };
    const convertCheckedOption = (checkedOption, merchantOptions) => {
        let stringOption;
        console.log(checkedOption, "this is the checked option");
        merchantOptions.filter((option) => {
            console.log(option, "this is the options");
            if (option === merchantOptions[checkedOption]) {
                stringOption = option;
            }
        });
        // if (checkedOption == 0) {
        //     stringOption = "It no longer fits my lifestyle";
        // } else if (checkedOption == 1) {
        //     stringOption = "Couldn’t finish the meals in time";
        // } else if (checkedOption == 2) {
        //     stringOption = "The product is too expensive";
        // } else if (checkedOption == 3) {
        //     stringOption = "Quality of food is unsatisfactory";
        // } else if (checkedOption == 4) {
        //     stringOption = "I found another alternative";
        // }
        return stringOption;
    };

    return (
        <Modal
            size="lg"
            dialogClassName="custom-modal"
            centered
            show={modalState}
            onHide={closeModal}
        >
            <Modal.Body>
                <Grid container className="m-3">
                    <Grid item xs={12} lg={12}>
                        <strong style={{ fontSize: "33px" }}>
                            We're sad to see you go!
                        </strong>
                    </Grid>
                    <Grid
                        item
                        className="mt-3 form-group required"
                        xs={12}
                        lg={12}
                    >
                        <p
                            className="control-label"
                            style={{ fontSize: "20px" }}
                        >
                            Please tell us why you're unsubscribing from us.
                        </p>
                    </Grid>
                    <Grid item xs={12} lg={12}>
                        {/* create radio button with text */}
                        {window.location.href.includes("pichaeats") &&
                            pichaOptions.map((option, index) => (
                                <Form.Check
                                    key={index}
                                    type="radio"
                                    label={
                                        <p htmlFor={index} className="ml-2">
                                            {option}
                                        </p>
                                    }
                                    checked={
                                        checkedOption == index ? true : false
                                    }
                                    name="picha"
                                    id={index}
                                    value={index}
                                    onChange={(e) => {
                                        setCheckedOption(e.target.value);
                                    }}
                                />
                            ))}
                        {window.location.href.includes("wildkombucha") &&
                            kombuchaOptions.map((option, index) => (
                                <Form.Check
                                    key={index}
                                    type="radio"
                                    label={
                                        <p htmlFor={index} className="ml-2">
                                            {option}
                                        </p>
                                    }
                                    checked={
                                        checkedOption == index ? true : false
                                    }
                                    name="vajra"
                                    id={index}
                                    value={index}
                                    onChange={(e) => {
                                        setCheckedOption(e.target.value);
                                    }}
                                />
                            ))}
                        {window.location.href.includes("vajra") &&
                            vajraOptions.map((option, index) => (
                                <Form.Check
                                    key={index}
                                    type="radio"
                                    label={
                                        <p htmlFor={index} className="ml-2">
                                            {option}
                                        </p>
                                    }
                                    checked={
                                        checkedOption == index ? true : false
                                    }
                                    name="vajra"
                                    id={index}
                                    value={index}
                                    onChange={(e) => {
                                        setCheckedOption(e.target.value);
                                    }}
                                />
                            ))}
                    </Grid>
                    <Grid
                        item
                        className="mt-3 mr-5"
                        style={{ marginRight: "40px" }}
                        xs={11}
                        lg={12}
                    >
                        <p style={{ fontSize: "20px" }}>Additional comments </p>
                        <Form.Group className="mb-3">
                            <Form.Control
                                style={{
                                    borderRadius: "5px",
                                    border: "1px solid black",
                                }}
                                placeholder="I'm unsubscribing because..."
                                as="textarea"
                                onChange={(e) => {
                                    setAdditionalComment(e.target.value);
                                }}
                                rows={4}
                            />
                        </Form.Group>
                    </Grid>
                    <Grid container className="mt-3 " justify="flex-end">
                        <Grid item align="left" xs={6} lg={6}>
                            <Button
                                className="btn-block"
                                variant="outline-primary"
                                style={{
                                    width: "95%",
                                    borderRadius: "5px",
                                    backgroundColor: "grey",
                                    color: "black",
                                    border: "1px solid grey",
                                    height: "5vh",
                                }}
                                onClick={() => {
                                    closeModal();
                                }}
                            >
                                Cancel
                            </Button>
                        </Grid>
                        <Grid item xs={6} lg={6}>
                            <Button
                                className="btn-block mr-5"
                                variant="outline-primary"
                                style={{
                                    width: "90%",
                                    color: store.getState().colorState
                                        .font_color,
                                    backgroundColor:
                                        store.getState().colorState.color,
                                    borderRadius: "5px",
                                    border: "1px solid #ffd166 ",
                                    height: "5vh",
                                }}
                                onClick={() => {
                                    // need to update subs status in backend
                                    if (checkedOption === undefined) {
                                        Swal.fire({
                                            icon: "error",
                                            text: "Please select an option",
                                            confirmButtonColor:
                                                store.getState().colorState
                                                    .color,
                                        });
                                    } else {
                                        updateSubsStatus();
                                    }
                                }}
                            >
                                Unsubscribe
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Modal.Body>
        </Modal>
    );
};

export default UnsubscribeSurvey;
