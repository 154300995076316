import { callAPI } from "../../utils/apiUtils";
import axios from "axios";
import { useDispatch } from "react-redux";
import { saveLoggedInUserState, clearLoggedInUserState } from "../../action.js";
import { store } from "../../index.js";

const userService = {
    isLoggedIn: () => {
        var hasToken = !!JSON.parse(localStorage.getItem("user_token"));

        return hasToken;
    },
    login: (username, password) => {
        // return new Promise((resolve, reject) => {

        //     if (username == "merchant" && password == "merchant") {
        //         localStorage.setItem("user_token", JSON.stringify({ access_token: "MY_MERCHANT_AUTH_TOKEN", lastRefresh: new Date().getTime() }));
        //         userService.fetchUserDataWithTokenIntoRedux()
        //             .then(response => { resolve(response); })
        //             .catch(error => { reject(error); });
        //     } else if (username == "customer" && password == "customer") {
        //         localStorage.setItem("user_token", JSON.stringify({ access_token: "MY_CUSTOMER_AUTH_TOKEN", lastRefresh: new Date().getTime() }));
        //         userService.fetchUserDataWithTokenIntoRedux()
        //             .then(response => { resolve(response); })
        //             .catch(error => { reject(error); });
        //     } else {
        //         reject("Username Or Password is wrong");
        //     }
        // });

        let loginFormData = new FormData();
        loginFormData.append("username", username);
        loginFormData.append("password", password);
        return new Promise((resolve, reject) => {
            axios
                .post(
                    `${
                        window.location.protocol +
                        "//" +
                        window.location.hostname
                    }${process.env.REACT_APP_SITE_PORT_BACKEND}/api/token/`,
                    loginFormData
                )
                .then(async (response) => {
                    var tokenInfo = {
                        lastRefresh: new Date().getTime(),
                        access_token: response.data.access,
                        refresh_token: response.data.refresh,
                    };
                    localStorage.setItem(
                        "user_token",
                        JSON.stringify(tokenInfo)
                    );

                    userService
                        .fetchUserDataWithTokenIntoRedux()
                        .then((response) => {
                            resolve(response);
                        })
                        .catch((error) => {
                            reject(error);
                        });
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    logout: () => {
        localStorage.removeItem("user_token");
        store.dispatch(clearLoggedInUserState()); //clear logged in user state
        return Promise.resolve();
    },
    refreshToken: () => {
        let currUser = JSON.parse(localStorage.getItem("user_token"));
        let getUserFormData = new FormData();
        getUserFormData.append("refresh", currUser.refresh_token);
        return new Promise((resolve, reject) => {
            axios
                .post(
                    `${
                        window.location.protocol +
                        "//" +
                        window.location.hostname
                    }${
                        process.env.REACT_APP_SITE_PORT_BACKEND
                    }/api/token/refresh/`,
                    getUserFormData
                )
                .then(async (response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    fetchUserDataWithTokenIntoRedux: () => {
        let currUser = JSON.parse(localStorage.getItem("user_token"));

        if (!currUser) {
            // if no user in localStorage then the user must enter their credentials to proceed
            return Promise.reject(null);
        }

        // return new Promise((resolve, reject) => {
        //     if (currUser.access_token == "MY_MERCHANT_AUTH_TOKEN") {
        //         store.dispatch(saveLoggedInUserState({ name: "Mr. Merchant", userRole: "merchant" }));//save logged in user state
        //         resolve({ name: "Mr. Merchant", userRole: "merchant" });

        //     } else if (currUser.access_token == "MY_CUSTOMER_AUTH_TOKEN") {
        //         store.dispatch(saveLoggedInUserState({ name: "Mr. Customer", userRole: "customer" }));//save logged in user state
        //         resolve({ name: "Mr. Merchant", userRole: "merchant" });
        //     } else {
        //         userService.logout();
        //         reject("error");
        //     }

        // });

        axios.defaults.headers.common["Authorization"] =
            "Bearer " + currUser.access_token;

        return new Promise((resolve, reject) => {
            axios
                .get(
                    `${
                        window.location.protocol +
                        "//" +
                        window.location.hostname
                    }${
                        process.env.REACT_APP_SITE_PORT_BACKEND
                    }/catalog/user/info`
                )
                .then((res) => {
                    store.dispatch(saveLoggedInUserState(res.data)); //save logged in user state
                    resolve(res);
                })
                .catch((error) => {
                    userService.logout();
                    reject(error);
                });
        });
    },
};

export default userService;
