import { callAPI } from "../../utils/apiUtils";
import axios from "axios";
import { useDispatch } from "react-redux";
import { saveLoggedInUserState, clearLoggedInUserState } from "../../action.js";
import { store } from "../../index.js";
import { urlAction } from "../../action.js";

const merchantService = {
    getAllCustomerWithoutPagination: () => {
        return new Promise((resolve, reject) => {
            callAPI(`/subscription/customers`)
                .then((response) => {
                    resolve(response);
                })
                .catch((response) => reject(response));
        });
    },
    getMerchantInfo: () => {
        const arBackend = urlAction("get_backend");

        return new Promise((resolve, reject) => {
            callAPI(`/api/merchants/`, "get")
                .then((response) => {
                    resolve(response);
                })
                .catch((response) => reject(response));
        });
    },
    getCreditCardSettings: () => {
        return new Promise((resolve, reject) => {
            callAPI(`/settings/credit_card/`, "get")
                .then((response) => {
                    resolve(response);
                })
                .catch((response) => reject(response));
        });
    },
};

export default merchantService;
