import { Button, MenuItem, Paper, Select, TextField } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import FilterListIcon from "@mui/icons-material/FilterList";
import SearchIcon from "@mui/icons-material/Search";
import ViewColumnIcon from "@mui/icons-material/ViewColumn";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import DatePicker from "@mui/lab/DatePicker";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import React, { useEffect, useState } from "react";
import { ListGroup } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import { Modal } from "react-bootstrap";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import LoginBar from "../../../../Components/LoginBar/loginbar";
import MerchantTable from "../../../../Components/MerchantTable/";
import moment from "moment";
import Sidebar from "../../../../Components/Sidebar/sidebar";
import subscriptionService from "../../../../services/subscriptionService";
import "./style.css";
import Spinner from "react-bootstrap/Spinner";

import { urlAction } from "../../../../action";
const MySwal = withReactContent(Swal);
const SubscriptionListing = ({ history }) => {
    const hostname = urlAction("get_merchant");
    const [loadingBar, setLoadingBar] = useState(false);
    const [subsData, setSubsData] = useState({ value: [] });
    const [deliveryFreqFilter, setDeliveryFreqFilter] = useState({
        value: undefined,
    });
    const [showFilterPopover, setShowFilterPopover] = useState(false);
    const [planNameFilter, setPlanNameFilter] = useState({ value: "" });
    const [subsTypeFilter, setSubsTypeFilter] = useState({ value: "" });
    const [subsStatusFilter, setSubsStatusFilter] = useState({ value: "" });
    const [showExportModal, setShowExportModal] = useState(false);
    const [subsStartDateFilter, setSubsStartDateFilter] = useState({
        value: undefined,
    });
    const [subsEndDateFilter, setSubsEndDateFilter] = useState({
        value: undefined,
    });
    const [filterValue, setFilterValue] = useState({
        delivery_freq: undefined,
        plan_name: undefined,
        subs_type: undefined,
        subs_status: undefined,
        subs_start_date: undefined,
        subs_end_date: undefined,
    });
    const [searchValue, setSearchValue] = useState();
    const [exportHeaders, setExportHeaders] = useState({
        subs_id: { value: "Subscription ID", checked: true },
        customer_id: { value: "Customer ID", checked: true },
        customer_fname: { value: "Customer First Name", checked: true },
        customer_lname: { value: "Customer Last Name", checked: true },
        customer_email: { value: "Customer Email", checked: true },
        customer_contact_no: {
            value: "Customer Contact Number",
            checked: true,
        },
        plan_name: { value: "Plan Name", checked: true },
        subs_status: { value: "Subscription Status", checked: true },
        subs_type: { value: "Subscription Type", checked: true },
        subs_price: { value: "Subscription Price", checked: true },
        // add promo code to subscription backend
        promo_status: { value: "Promo Status", checked: true },
        delivery_date: { value: "Delivery Date", checked: true },
        delivery_time: { value: "Delivery Time", checked: true },
        delivery_address: { value: "Delivery Address", checked: true },
        delivery_frequency: { value: "Delivery Frequency", checked: true },
        customer_postcode: { value: "Postcode", checked: true },
        customer_delivery_instructions: {
            value: "Delivery Remarks",
            checked: true,
        },
        seller_id: {
            value: "Seller ID",
            checked: hostname == "mylipan" ? true : false,
        },
        default_menu: { value: "Default Menu", checked: true },
        non_default_menu: { value: "Non Default Menu", checked: true },
        side_order: { value: "Side Order", checked: true },
    });
    const [subsHeaders, setSubsHeaders] = useState({
        subs_status: {
            value: "Subscription Status",
            checked: true,
        },
        customer_fname: {
            value: "Customer Name",
            checked: true,
        },
        plan_name: { value: "Plan Name", checked: true },
        delivery_frequency: {
            value: "Delivery Frequency",
            checked: true,
        },
        subs_type: {
            value: "Subscription Type",
            checked: true,
        },
        delivery_frequency: {
            value: "Delivery Frequency",
            checked: window.location.href.includes("vajrasecrets")
                ? false
                : true,
        },
        delivery_date: {
            value: window.location.href.includes("vajrasecrets")
                ? "Start Month"
                : "Delivery Date",
            checked: true,
        },
        delivery_time: {
            value: "Delivery Time",
            checked: window.location.href.includes("vajrasecrets")
                ? false
                : true,
        },
        customer_contact_no: {
            value: "Customer Contact Number",
            checked: false,
        },
        customer_email: {
            value: "Customer Email",
            checked: false,
        },
        customer_postcode: {
            value: "Customer Postcode",
            checked: false,
        },
        seller_id: {
            value: "Seller ID",
            checked: hostname == "mylipan" ? true : false,
        },
        date_created: {
            value: "Date Created",
            checked: false,
        },
        date_end: { value: "Date End", checked: false },
        vajra_kechara_membership: {
            value: "Kechara Membership",
            checked: window.location.href.includes("vajrasecrets")
                ? true
                : false,
        },
        vajra_language: {
            value: "Language",
            checked: window.location.href.includes("vajrasecrets")
                ? true
                : false,
        },

        action: { value: "Action", checked: true },
    });
    const [fetchData, setFetchData] = useState(false);
    const [filterOption, setFilterOption] = useState();
    const getSubscriptions = () => {
        setLoadingBar(true);
        subscriptionService
            .getAllSubscriptionWithoutPagination()
            .then((res) => {
                setFilterOption(processFilterOption(res));
                res.map((data, index) => {
                    data["default_menu"].sort(function (a, b) {
                        var keyA = a.addon_id,
                            keyB = b.addon_id;
                        // Compare the 2 dates
                        if (keyA < keyB) return -1;
                        if (keyA > keyB) return 1;
                        return 0;
                    });
                    data["non_default_menu"].sort(function (a, b) {
                        var keyA = a.addon_id,
                            keyB = b.addon_id;
                        // Compare the 2 dates
                        if (keyA < keyB) return -1;
                        if (keyA > keyB) return 1;
                        return 0;
                    });
                    res[index] = data;
                });
                setSubsData({ value: res });
                // set loading false here
                setLoadingBar(false);
            })
            .catch((e) => {
                console.error(e, "error");
            });
    };
    const handleSubsData = (data) => {
        setSubsData({ value: data });
    };
    useEffect(() => {
        subscriptionService
            .getAllSubscriptionWithoutPagination()
            .then((res) => {
                setFilterOption(processFilterOption(res));
                setSubsData({ value: res });
            })
            .catch((e) => {});
    }, [fetchData]);
    const exportData = () => {
        const dataHeaders = [];
        const csvHeaders = [];
        const allData = [];
        Object.keys(exportHeaders).map((key) => {
            if (exportHeaders[key].checked) {
                dataHeaders.push(key);
            }
        });
        Object.keys(exportHeaders).map((key) => {
            if (exportHeaders[key].checked) {
                csvHeaders.push(exportHeaders[key].value);
            }
        });
        allData.push(csvHeaders);
        const data = subsData.value.map((subs) => {
            const obj = {};
            dataHeaders.map((header) => {
                obj[header] = subs[header];
            });
            return obj;
        });
        const exportData = {
            data: data,
        };

        exportData.data.map((data) => {
            const rowData = [];

            // follow exportHeaders key sequence, print value
            Object.keys(exportHeaders).map((key) => {
                // TODO: change to switch case

                // 1. if data[key] string has comma in it, add double quotes
                if (exportHeaders[key].checked) {
                    if (data[key] == null) {
                        rowData.push("");
                    } else if (
                        data[key].toString().includes(",") &&
                        key !== "default_menu" &&
                        key !== "non_default_menu" &&
                        key !== "side_order"
                    ) {
                        rowData.push(`"${data[key]}"`);
                    } else if (key == "delivery_date") {
                        rowData.push(
                            window.location.href.includes("vajrasecrets")
                                ? moment(data[key])
                                      .format("DD/MM/YYYY")
                                      .slice(6)
                                : moment(data[key]).format("DD/MM/YYYY")
                        );
                    } else if (
                        key == "default_menu" ||
                        key == "non_default_menu" ||
                        key == "side_order"
                    ) {
                        if (data[key].toString().includes(",")) {
                            data[key].map((menu) => {
                                rowData.push(
                                    `"${menu.addon_name}"` +
                                        " " +
                                        "(" +
                                        menu.quantity +
                                        ")"
                                );
                            });
                        } else {
                            data[key].map((menu) => {
                                rowData.push(
                                    menu.addon_name +
                                        " " +
                                        "(" +
                                        menu.quantity +
                                        ")"
                                );
                            });
                        }
                    } else if (key == "subs_type") {
                        rowData.push(
                            data[key] == "direct_debit"
                                ? "Recurring"
                                : "One Off"
                        );
                    } else if (
                        key == "subs_status" ||
                        key == "promo_status" ||
                        key == "delivery_frequency"
                    ) {
                        // push data[key] to rowData and capitalise first letter
                        rowData.push(
                            data[key].charAt(0).toUpperCase() +
                                data[key].slice(1)
                        );
                    } else if (key == "subs_price") {
                        rowData.push(data[key].toFixed(2));
                    } else if (key == "delivery_time") {
                        const date = new Date(data["delivery_date"]);
                        var weekday = new Array(7);
                        weekday[0] = "Sunday";
                        weekday[1] = "Monday";
                        weekday[2] = "Tuesday";
                        weekday[3] = "Wednesday";
                        weekday[4] = "Thursday";
                        weekday[5] = "Friday";
                        weekday[6] = "Saturday";

                        var day = weekday[date.getDay()];

                        rowData.push(day + " " + data[key]);
                    } else {
                        rowData.push(data[key]);
                    }
                }
            });
            allData.push(rowData);
        });

        // export data to xlsx format and download
        const csvString = allData.join("\n");
        var a = document.createElement("a");
        a.href = "data:attachment/csv," + encodeURI(csvString);
        a.target = "_Blank";
        // declare fileNaming as Order Information with date at the end
        const fileNaming =
            "Subscription_Info_" + new Date().toLocaleDateString() + ".csv";
        a.download = fileNaming;
        document.body.appendChild(a);
        a.click();

        // setShowExportModal(false);
    };
    const exportModal = () => (
        <Modal
            // size="md\"
            // style={{width:"95%"}}
            size="lg"
            dialogClassName="custom-modal"
            centered
            show={showExportModal}
            onHide={() => {
                setShowExportModal(false);
            }}
        >
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body className="customFormBody">
                {/* render subsheaders value with checkbox */}
                <ListGroup style={{ marginLeft: "25px" }}>
                    <Grid container>
                        {Object.keys(exportHeaders).map((key) => {
                            return (
                                <Grid
                                    style={{
                                        minHeight: "60px",
                                        maxHeight: "60px",
                                        padding: "15px",
                                    }}
                                    // align="left"
                                    item
                                    xs={4}
                                    md={4}
                                    lg={4}
                                >
                                    {/* <ListGroup.Item
                                        key={key}
                                       
                                        // className="export-modal-body-content-body-item"
                                    > */}
                                    <Form.Check
                                        type="checkbox"
                                        label={exportHeaders[key].value}
                                        checked={exportHeaders[key].checked}
                                        onClick={(e) => {
                                            setExportHeaders({
                                                ...exportHeaders,
                                                [key]: {
                                                    ...exportHeaders[key],
                                                    checked:
                                                        !exportHeaders[key]
                                                            .checked,
                                                },
                                            });
                                        }}
                                    />
                                    {/* </ListGroup.Item> */}
                                </Grid>
                            );
                        })}
                    </Grid>
                </ListGroup>
            </Modal.Body>
            <Modal.Footer
                style={{
                    display: "flex",
                    justifyContent: "center",
                }}
            >
                <Button
                    style={{
                        marginRight: "10px",
                    }}
                    // onClick={UponCloseForm}
                >
                    {/* <p
                        style={{
                            fontSize: "130%",
                            padding: "0 15px",
                            margin: "auto",
                            textAlign: "center",
                        }}
                    > */}
                    Cancel
                    {/* </p> */}
                </Button>
                <Button
                    style={{
                        color: "white",
                        backgroundColor: "black",
                    }}
                    onClick={() => {
                        exportData();
                    }}
                >
                    Export
                </Button>
            </Modal.Footer>
        </Modal>
    );

    const processFilterOption = (allSubsData) => {
        // Arrange all filter options
        var deliveryFrequencyOption = allSubsData.map((item) => {
            // return only bill period in item
            if (item["delivery_frequency"] !== null) {
                return (
                    item["delivery_frequency"].charAt(0).toUpperCase() +
                    item["delivery_frequency"].slice(1)
                );
            }
        });
        // if got duplicate in subs data, remove
        deliveryFrequencyOption = deliveryFrequencyOption.filter(
            (item, index) => deliveryFrequencyOption.indexOf(item) === index
        );
        var planNameOption = allSubsData.map((item) => {
            // return only plan name in item
            return (
                item["plan_name"].charAt(0).toUpperCase() +
                item["plan_name"].slice(1)
            );
        });
        // if got duplicate in subs data, remove
        planNameOption = planNameOption.filter(
            (item, index) => planNameOption.indexOf(item) === index
        );
        var subsTypeOption = allSubsData.map((item) => {
            // return only subs type in item
            return (
                item["subs_type"].charAt(0).toUpperCase() +
                item["subs_type"].slice(1)
            );
        });
        // if got duplicate in subs data, remove
        subsTypeOption = subsTypeOption.filter(
            (item, index) => subsTypeOption.indexOf(item) === index
        );
        var subsStatusOption = allSubsData.map((item) => {
            // return only subs status in item
            return (
                item["subs_status"].charAt(0).toUpperCase() +
                item["subs_status"].slice(1)
            );
        });
        // if got duplicate in subs data, remove
        subsStatusOption = subsStatusOption.filter(
            (item, index) => subsStatusOption.indexOf(item) === index
        );

        return {
            delivery_frequency: deliveryFrequencyOption,
            plan_name: planNameOption,
            subs_type: subsTypeOption,
            subs_status: subsStatusOption,
        };
    };
    const handleSubsHeaderCheckbox = (header) => {
        let newHeaders = { ...subsHeaders };
        newHeaders[header].checked = !newHeaders[header].checked;
        setSubsHeaders(newHeaders);
    };
    const displayPopover = (
        <Popover>
            <Paper elevation={0}>
                {Object.keys(subsHeaders).map((header, index) => {
                    return (
                        <Form.Group
                            className="m-3"
                            controlId="formBasicCheckbox"
                        >
                            <Form.Check
                                type="checkbox"
                                label={subsHeaders[header].value}
                                checked={subsHeaders[header].checked}
                                onChange={() =>
                                    handleSubsHeaderCheckbox(header)
                                }
                                id={index}
                                key={index}
                            />{" "}
                        </Form.Group>
                    );
                })}
            </Paper>
        </Popover>
    );

    const filterPopover = (
        <Popover show={showFilterPopover}>
            <Paper elevation={0}>
                {/* create header and body */}
                <Card>
                    <Card.Header />
                    <ListGroup variant="flush">
                        <ListGroup.Item>
                            <Grid className="mt-2" container>
                                <Grid item xs={4} lg={4} md={4}>
                                    Delivery frequency:
                                </Grid>
                                <Grid item xs={8} lg={8} md={8}>
                                    <Select
                                        value={deliveryFreqFilter.value}
                                        fullWidth
                                        onChange={(e) => {
                                            setDeliveryFreqFilter({
                                                value: e.target.value,
                                            });
                                        }}
                                    >
                                        {filterOption !== undefined
                                            ? filterOption.delivery_frequency.map(
                                                  (item, index) => {
                                                      return (
                                                          <MenuItem
                                                              value={item}
                                                              key={index}
                                                          >
                                                              {item}
                                                          </MenuItem>
                                                      );
                                                  }
                                              )
                                            : ""}
                                    </Select>
                                </Grid>
                            </Grid>
                        </ListGroup.Item>
                        <ListGroup.Item>
                            <Grid className="mt-2" container>
                                <Grid item xs={4} lg={4} md={4}>
                                    Plan name:
                                </Grid>
                                <Grid item xs={8} lg={8} md={8}>
                                    <Select
                                        value={planNameFilter.value}
                                        fullWidth
                                        onChange={(e) => {
                                            setPlanNameFilter({
                                                value: e.target.value,
                                            });
                                        }}
                                    >
                                        {filterOption !== undefined
                                            ? filterOption.plan_name.map(
                                                  (planName, index) => {
                                                      return (
                                                          <MenuItem
                                                              value={planName}
                                                              key={index}
                                                          >
                                                              {planName}
                                                          </MenuItem>
                                                      );
                                                  }
                                              )
                                            : ""}
                                    </Select>
                                </Grid>
                            </Grid>
                        </ListGroup.Item>
                        <ListGroup.Item>
                            <Grid className="mt-2" container>
                                <Grid item xs={4} lg={4} md={4}>
                                    Subscription type:
                                </Grid>
                                <Grid item xs={8} lg={8} md={8}>
                                    <Select
                                        value={subsTypeFilter.value}
                                        fullWidth
                                        onChange={(e) => {
                                            setSubsTypeFilter({
                                                value: e.target.value,
                                            });
                                        }}
                                    >
                                        {filterOption !== undefined
                                            ? filterOption.subs_type.map(
                                                  (item, index) => {
                                                      if (
                                                          item == "Direct_debit"
                                                      ) {
                                                          return (
                                                              <MenuItem
                                                                  value={item}
                                                                  key={index}
                                                              >
                                                                  Recurring
                                                              </MenuItem>
                                                          );
                                                      } else {
                                                          return (
                                                              <MenuItem
                                                                  value={item}
                                                                  key={index}
                                                              >
                                                                  One off
                                                              </MenuItem>
                                                          );
                                                      }
                                                  }
                                              )
                                            : ""}
                                    </Select>
                                </Grid>
                            </Grid>
                        </ListGroup.Item>
                        <ListGroup.Item>
                            <Grid className="mt-2" container>
                                <Grid item xs={4} lg={4} md={4}>
                                    Subscription status:
                                </Grid>
                                <Grid item xs={8} lg={8} md={8}>
                                    <Select
                                        value={subsStatusFilter.value}
                                        fullWidth
                                        onChange={(e) => {
                                            setSubsStatusFilter({
                                                value: e.target.value,
                                            });
                                        }}
                                    >
                                        {filterOption !== undefined
                                            ? filterOption.subs_status.map(
                                                  (item, index) => {
                                                      return (
                                                          <MenuItem
                                                              value={item}
                                                              key={index}
                                                          >
                                                              {item}
                                                          </MenuItem>
                                                      );
                                                  }
                                              )
                                            : ""}
                                    </Select>
                                </Grid>
                            </Grid>
                        </ListGroup.Item>
                        <ListGroup.Item>
                            <Grid className="mt-2" container>
                                <Grid item xs={4} lg={4} md={4}>
                                    Delivery date:
                                </Grid>
                                <Grid item xs={3} lg={3} md={3}>
                                    <LocalizationProvider
                                        dateAdapter={AdapterDateFns}
                                    >
                                        <DatePicker
                                            value={
                                                // first value is the first available date
                                                subsStartDateFilter.value ===
                                                undefined
                                                    ? ""
                                                    : new Date(
                                                          subsStartDateFilter.value
                                                      )
                                            }
                                            // if change date, update the state
                                            onChange={(e) => {
                                                setSubsStartDateFilter({
                                                    value: e,
                                                });
                                            }}
                                            defaultCalendarMonth={new Date()}
                                            renderInput={(params) => (
                                                <TextField {...params} />
                                            )}
                                        />
                                    </LocalizationProvider>
                                </Grid>
                                <Grid align="center" item xs={2} lg={2} md={2}>
                                    <p className="mt-1">till</p>
                                </Grid>
                                <Grid item xs={3} lg={3} md={3}>
                                    <LocalizationProvider
                                        dateAdapter={AdapterDateFns}
                                    >
                                        <DatePicker
                                            value={
                                                // first value is the first available date
                                                subsEndDateFilter.value ===
                                                undefined
                                                    ? ""
                                                    : new Date(
                                                          subsEndDateFilter.value
                                                      )
                                            }
                                            onChange={(e) => {
                                                setSubsEndDateFilter({
                                                    value: e,
                                                });
                                            }}
                                            defaultCalendarMonth={new Date()}
                                            renderInput={(params) => (
                                                <TextField {...params} />
                                            )}
                                        />
                                    </LocalizationProvider>
                                </Grid>
                            </Grid>
                        </ListGroup.Item>
                    </ListGroup>
                    <Card.Header>
                        <Grid container>
                            {/* <Grid item align="right" xs={4} lg={4} md={4}>
                                {" "}
                                <Button
                                    onClick={() => {
                                        setShowFilterPopover(
                                            !showFilterPopover
                                        );
                                    }}
                                    variant="contained"
                                >
                                    Cancel
                                </Button>
                            </Grid> */}
                            <Grid item align="right" xs={6} lg={6} md={6}>
                                {" "}
                                <Button
                                    onClick={() => {
                                        setDeliveryFreqFilter({
                                            value: undefined,
                                        });
                                        setPlanNameFilter({ value: undefined });
                                        setSubsTypeFilter({ value: undefined });
                                        setSubsStatusFilter({
                                            value: undefined,
                                        });
                                        setSubsStartDateFilter({
                                            value: undefined,
                                        });
                                        setSubsEndDateFilter({
                                            value: undefined,
                                        });
                                        setFetchData(!fetchData);
                                        setFilterValue({
                                            plan_name: undefined,
                                            delivery_freq: undefined,
                                            subs_type: undefined,
                                            subs_status: undefined,
                                            subs_start_date: undefined,
                                            subs_end_date: undefined,
                                        });
                                    }}
                                    // need to refresh data
                                    className="refresh-button"
                                    variant="contained"
                                >
                                    Refresh
                                </Button>
                            </Grid>
                            <Grid item align="left" xs={6} lg={6} md={6}>
                                <Button
                                    onClick={() => {
                                        setFilterValue({
                                            plan_name: planNameFilter.value,
                                            delivery_freq:
                                                deliveryFreqFilter.value,
                                            subs_type: subsTypeFilter.value,
                                            subs_status: subsStatusFilter.value,
                                            subs_start_date:
                                                subsStartDateFilter.value,
                                            subs_end_date:
                                                subsEndDateFilter.value,
                                        });
                                    }}
                                    style={{
                                        backgroundColor: "black",
                                        color: "white",
                                    }}
                                    className="ml-2"
                                    variant="contained"
                                >
                                    Filter
                                </Button>
                            </Grid>
                        </Grid>
                    </Card.Header>
                </Card>
            </Paper>
        </Popover>
    );

    useEffect(() => {
        // set loading true here
        getSubscriptions();
    }, []);
    if (loadingBar) {
        return <Spinner className="spinner-bar" animation="border" />;
    } else {
        return (
            <Grid container>
                <Grid
                    item
                    style={{ marginLeft: "20px" }}
                    className="p-3"
                    xs={12}
                    lg={12}
                >
                    {/* profile icon */}
                    <Grid item xs={12} md={12} lg={12}>
                        <Grid container className="mt-4 admin-listing">
                            {/* profile icon and username dropdown */}
                            <Grid align="left" item xs={6} md={6} lg={6}>
                                <h2 style={{ color: "black" }}>
                                    <strong>Subscription Management</strong>
                                </h2>
                            </Grid>
                            <Grid item xs={6} md={6} lg={6} align="right">
                                {" "}
                                <LoginBar />
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item className="mt-1" xs={12} md={12} lg={12}>
                        {/* create 2 button, create new plan and export button */}
                        <Grid container>
                            <Grid container align="left" xs={12} md={6} lg={6}>
                                <Grid item xs={12} md={12} lg={12}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        className="mt-2 mr-2 plan-option-button"
                                        endIcon={
                                            <FileDownloadIcon className="icon-mobile"></FileDownloadIcon>
                                        }
                                        onClick={() => {
                                            setShowExportModal(true);
                                        }}
                                    >
                                        <span className="text-icon-button">
                                            Export
                                        </span>
                                    </Button>{" "}
                                    {exportModal()}
                                    <OverlayTrigger
                                        trigger="click"
                                        placement="bottom"
                                        overlay={filterPopover}
                                    >
                                        <Button
                                            variant="contained"
                                            className="mt-2 plan-option-button"
                                            endIcon={
                                                <FilterListIcon className="icon-mobile"></FilterListIcon>
                                            }
                                            onClick={() => {
                                                setShowFilterPopover(
                                                    !showFilterPopover
                                                );
                                            }}
                                        >
                                            <span className="text-icon-button">
                                                Filter
                                            </span>
                                        </Button>
                                    </OverlayTrigger>{" "}
                                </Grid>
                            </Grid>

                            <Grid item xs={12} md={6} lg={6}>
                                <Grid container>
                                    <Grid
                                        item
                                        align={
                                            window.innerWidth < 1200
                                                ? "left"
                                                : "right"
                                        }
                                        className="mr-3"
                                        xs={6}
                                        md={6}
                                        lg={8}
                                    >
                                        <OverlayTrigger
                                            trigger="click"
                                            placement="bottom"
                                            // show={true}
                                            overlay={displayPopover}
                                        >
                                            <Button
                                                endIcon={
                                                    <ViewColumnIcon className="icon-mobile"></ViewColumnIcon>
                                                }
                                                variant="contained"
                                                className="plan-option-button display-button mt-1"
                                            >
                                                <span className="text-icon-button">
                                                    Display
                                                </span>
                                            </Button>
                                        </OverlayTrigger>
                                    </Grid>
                                    <Grid
                                        item
                                        align={
                                            window.innerWidth < 1200
                                                ? "left"
                                                : "right"
                                        }
                                        xs={12}
                                        md={6}
                                        lg={4}
                                    >
                                        <TextField
                                            style={{ width: "95%" }}
                                            id="filled-search"
                                            className="customer-name"
                                            onChange={(e) => {
                                                setSearchValue(e.target.value);
                                            }}
                                            label={
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        flexDirection: "row",
                                                    }}
                                                >
                                                    <SearchIcon></SearchIcon>{" "}
                                                    <p className="ml-2 mt-1 text-icon-button">
                                                        Search customer name
                                                    </p>
                                                </div>
                                            }
                                            type="search"
                                            variant="outlined"
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    {/* catalog page */}
                    <MerchantTable
                        search={searchValue}
                        headers={subsHeaders}
                        filterValue={filterValue}
                        page={"subscription"}
                        tableData={subsData.value}
                        updateTableData={handleSubsData}
                        fetchData={fetchData}
                        fetchNewData={() => {
                            setFetchData(!fetchData);
                        }}
                    />
                </Grid>
            </Grid>
        );
    }
};

export default SubscriptionListing;
