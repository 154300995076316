import React, { useEffect, useState, Fragment } from "react";
import { useHistory } from "react-router-dom";
import Button from "@mui/material/Button";
import Card from "react-bootstrap/Card";
import { Carousel } from "react-bootstrap";
import "./catalogCard.css";
import { store } from "../../index.js";
import { Grid } from "@material-ui/core";
import { urlAction } from "../../action";
import Image from "react-bootstrap/Image";
// import Link from "react-bootstrap/Link";
import noImage from "../../Images/no-image.svg";
import googleAnalyticService from "../../services/analyticService/googleAnalytic";
import pixelService from "../../services/analyticService/pixel";
import ga4Service from "../../services/analyticService/ga4";
import addOnService from "../../services/addOnService";
const CatalogCard = ({ planData }) => {
    const history = useHistory();
    const [addonList, setAddonList] = useState([]);
    const renderImage = (path) => {
        return (
            <Image
                src={`${
                    window.location.protocol + "//" + window.location.hostname
                }${process.env.REACT_APP_SITE_PORT_BACKEND}${path}`}
                alt="image"
                onClick={() => {
                    console.log("clicked on image");
                }}
                style={{ borderRadius: "10px 10px 0px 0px" }}
            />
        );
    };
    const redirectToPlanDetailWithId = (id) => {
        history.push(`/plan/${id}`);
    };

    const renderPriceTag = (pricing_model, plan_price) => {
        if (pricing_model === "flat_fee") {
            const ketoccinoInURL = window.location.href.includes("ketoccino");

            if (ketoccinoInURL) {
                const currentMenuId = planData.planInfo.default_menu[0];
                const getAddonPrice = addonList.filter((addon) => {
                    return addon.addon_id === currentMenuId;
                });

                const addonPrice =
                    getAddonPrice.length > 0 ? getAddonPrice[0].addon_price : 0;
                return `RM${addonPrice.toFixed(2)} per bottle`;
            } else {
                return "Starts from RM" + plan_price;
            }
        }
    };

    useEffect(() => {
        addOnService
            .getAllAddonWithoutPagination()
            .then((res) => {
                setAddonList(res.addons);
                console.log(res, "this is all addon without pagination");
            })
            .catch((err) => {
                console.log(err, "this is the error");
            });
    }, [planData]);

    return (
        // need to render based on the amount of menus that they have
        <Card style={{ borderRadius: "10px" }} className="m-2">
            <>
                <Carousel
                    // style={{ maxHeight: "300px", minHeight: "300px" }}
                    interval={null}
                >
                    {planData?.planInfo.plan_image.length > 0 ? (
                        planData?.planInfo.plan_image.map((image, index) => {
                            return (
                                <Carousel.Item
                                    onClick={() => {
                                        redirectToPlanDetailWithId(
                                            planData?.planInfo.plan_id
                                        );
                                    }}
                                    key={index}
                                >
                                    {renderImage(image.image_name)}
                                </Carousel.Item>
                            );
                        })
                    ) : (
                        <a
                            onClick={() => {
                                redirectToPlanDetailWithId(
                                    planData?.planInfo.plan_id
                                );
                            }}
                        >
                            <img
                                style={{
                                    borderRadius: "10px 10px 0px 0px",
                                }}
                                src={noImage}
                            />
                        </a>
                    )}
                </Carousel>
                <Card.Body>
                    <Card.Title className="text-left">
                        {planData?.planInfo.plan_name}
                    </Card.Title>
                    <Card.Text className="text-left">
                        {renderPriceTag(
                            planData?.planInfo.pricing_model,
                            planData?.planInfo.plan_price
                        )}
                    </Card.Text>
                </Card.Body>
            </>

            <Button
                className="w-100"
                style={{
                    backgroundColor: store.getState().colorState.color,
                    color: store.getState().colorState.font_color,
                    textTransform: "none",
                    borderRadius: "0px 0px 10px 10px",
                }}
                variant="primary"
                onClick={() => {
                    pixelService.trackViewContent(planData?.planInfo.plan_name);
                    googleAnalyticService.trackViewContent(
                        planData?.planInfo.plan_name
                    );
                    ga4Service.trackViewPlan(planData?.planInfo.plan_name);
                    sessionStorage.clear();
                    redirectToPlanDetailWithId(planData?.planInfo.plan_id);
                }}
            >
                View Plan Details
            </Button>
        </Card>
    );
};
export default CatalogCard;
