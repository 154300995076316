import { callAPI } from "../../utils/apiUtils";
import axios from "axios";

const tierPricingModelService = {
    getTierPricingModelListing: (pageIndex = 1, search = "") => {
        return new Promise((resolve, reject) => {
            callAPI(
                `/catalog/tiered_pricing_model/list?page=${pageIndex}&search=${search}&pricing_model_status__in=active,archived`
            )
                .then((response) => {
                    resolve(response);
                })
                .catch((response) => reject(response));
        });
    },
    getAllTierPricingModel: (statusFilter = [], merchantId) => {
        return new Promise((resolve, reject) => {
            callAPI(
                `/catalog/tiered_pricing_model?${statusFilter
                    .map((s) => "&status=" + s)
                    .join("")}&merchant_id=${merchantId}`,
                "get"
            )
                .then((response) => {
                    resolve(response);
                })
                .catch((response) => reject(response));
        });
    },
    createNewPropertyPricingModel: (dataBody) => {
        return new Promise((resolve, reject) => {
            callAPI(`/catalog/property_pricing_model/`, "post", dataBody)
                .then((response) => {
                    resolve(response);
                })
                .catch((response) => reject(response));
        });
    },
    getAllPropertyPricing: () => {
        return new Promise((resolve, reject) => {
            callAPI(`/catalog/property_pricing_model/`, "get")
                .then((response) => {
                    resolve(response);
                })
                .catch((response) => reject(response));
        });
    },
    getSinglePropertyPricingModel: (propertyModelId) => {
        return new Promise((resolve, reject) => {
            callAPI(`/catalog/property_pricing_model/${propertyModelId}`)
                .then((response) => {
                    resolve(response);
                })
                .catch((response) => reject(response));
        });
    },
    getSingleTierPricingModel: (tierModelId) => {
        return new Promise((resolve, reject) => {
            callAPI(`/catalog/tiered_pricing_model/${tierModelId}`)
                .then((response) => {
                    resolve(response);
                })
                .catch((response) => reject(response));
        });
    },
    createNewTierPricingModel: (dataBody) => {
        return new Promise((resolve, reject) => {
            callAPI(`/catalog/tiered_pricing_model/`, "post", dataBody)
                .then((response) => {
                    resolve(response);
                })
                .catch((response) => reject(response));
        });
    },
    editNewTierPricingModel: (tierModelId, dataBody) => {
        return new Promise((resolve, reject) => {
            callAPI(
                `/catalog/tiered_pricing_model/${tierModelId}`,
                "put",
                dataBody
            )
                .then((response) => {
                    resolve(response);
                })
                .catch((response) => reject(response));
        });
    },
    deleteTieredModel: (tierModelId) => {
        const dataBody = {
            pricing_model_status: "deleted",
        };
        return new Promise((resolve, reject) => {
            callAPI(
                `/catalog/tiered_pricing_model/${tierModelId}`,
                "patch",
                dataBody
            )
                .then((response) => {
                    resolve(response);
                })
                .catch((response) => reject(response));
        });
    },
    deletePropertyModel: (propertyModelId) => {
        const dataBody = {
            property_status: "deleted",
        };
        return new Promise((resolve, reject) => {
            callAPI(
                `/catalog/property_pricing_model/${propertyModelId}`,
                "patch",
                dataBody
            )
                .then((response) => {
                    resolve(response);
                })
                .catch((response) => reject(response));
        });
    },
    archivePropertyModel: (propertyModelId) => {
        const dataBody = {
            property_status: "archived",
        };
        return new Promise((resolve, reject) => {
            callAPI(
                `/catalog/property_pricing_model/${propertyModelId}`,
                "patch",
                dataBody
            )
                .then((response) => {
                    resolve(response);
                })
                .catch((response) => reject(response));
        });
    },

    reactivateTieredModel: (tierModelId) => {
        const dataBody = {
            pricing_model_status: "active",
        };
        return new Promise((resolve, reject) => {
            callAPI(
                `/catalog/tiered_pricing_model/${tierModelId}`,
                "patch",
                dataBody
            )
                .then((response) => {
                    resolve(response);
                })
                .catch((response) => reject(response));
        });
    },
    archiveTieredModel: (tierModelId) => {
        const dataBody = {
            pricing_model_status: "archived",
        };
        return new Promise((resolve, reject) => {
            callAPI(
                `/catalog/tiered_pricing_model/${tierModelId}`,
                "patch",
                dataBody
            )
                .then((response) => {
                    resolve(response);
                })
                .catch((response) => reject(response));
        });
    },
};

export default tierPricingModelService;
