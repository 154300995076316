import Grid from "@material-ui/core/Grid";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import EditIcon from "@material-ui/icons/Edit";
import BlockIcon from "@mui/icons-material/Block";
import TextField from "@mui/material/TextField";
import moment from "moment";
import { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { toast } from "react-toastify";
import KebabMenu from "../../../Components/KebabMenu";
import LoginBar from "../../../Components/LoginBar/loginbar";
import PaymentResponse from "../../../Components/PaymentResponse/paymentResponse";
import UnsubscribeSurvey from "../../../Components/UnsubscribeSurvey";
import { store } from "../../../index.js";
import customerService from "../../../services/customerService";
import subscriptionService from "../../../services/subscriptionService";
import googleAnalyticService from "../../../services/analyticService/googleAnalytic";
import pixelService from "../../../services/analyticService/pixel";
import ga4Service from "../../../services/analyticService/ga4";

import "./style.css";

const Subscription = ({ history, match }) => {
    const [subscriptionData, setSubscriptionData] = useState("");
    const [rowsPerPage, setRowsPerPage] = useState(0);
    const [subsId, setSubsId] = useState();
    const [showUnsubscribeSurvey, setShowUnsubscribeSurvey] = useState(false);
    const handleOpenUnsubscribeModal = () => setShowUnsubscribeSurvey(true);
    const handleCloseUnsubscribeModal = () => setShowUnsubscribeSurvey(false);
    const [showPaymentResponse, setShowPaymentResponse] = useState(false);
    const [buyerBankId, setBuyerBankId] = useState();
    const [responseSubsId, setResponseSubsId] = useState();
    const [responseInvId, setResposneInvId] = useState();
    const [buyerPaymentMethod, setBuyerPaymentMethod] = useState();
    const [paymentResponseStatus, setPaymentResponseStatus] = useState("");
    const cancelOption = (kebabArr, subsData) => {
        kebabArr.push({
            name: "Cancel Subscription",
            icon: (
                <ListItemIcon>
                    <BlockIcon fontSize="small" />
                </ListItemIcon>
            ),
            optionCallback: () => {
                // create modal for cancellation survey
                // set unsubscribe survey modal to true
                // case option
                // email sent confirmation
                setSubsId(subsData.subs_id);
                handleOpenUnsubscribeModal();
            },
        });
    };
    useEffect(() => {
        const queryData = new URLSearchParams(window.location.search);
        if (queryData.get("curlec_method") === "00") {
            setPaymentResponseStatus(queryData.get("fpx_debitAuthCode"));
            setBuyerBankId(queryData.get("fpx_buyerBankId"));
            setBuyerPaymentMethod(queryData.get("curlec_method"));
            setShowPaymentResponse(true);
        } else if (queryData.get("curlec_method") === "01") {
            // error is 1C, successful is 00
            // search for string "subsId" in query string and get the value
            // split space in invSubsQuery and assign to subsId

            setPaymentResponseStatus(queryData.get("fpx_debitAuthCode"));
            setBuyerBankId(queryData.get("fpx_buyerBankId"));
            setBuyerPaymentMethod(queryData.get("curlec_method"));
            setShowPaymentResponse(true);

            // if (
            //     queryData.get("fpx_debitAuthCode") === "00" ||
            //     queryData.get("fpx_debitAuthCode") === "1C"
            // ) {
            // setShowPaymentResponse(true);
            // }
            // else if it is not a redirection
        } else {
            var dataBody = {
                cust_id:
                    store.getState().loggedInUserReducer.userData?.ar_system
                        ?.cust_id,
            };
            subscriptionService
                .getCustomerProgress(dataBody)
                .then((res) => {
                    // prompt for mandate authorisation if user has not done it yet
                    if (res.result == 1) {
                        setPaymentResponseStatus("00");
                        setBuyerBankId(res.bank_id);
                        setBuyerPaymentMethod("01");
                        setResponseSubsId(res.subs_id);
                        setResposneInvId(res.inv_id);

                        // setShowPaymentResponse(true);
                    }
                })
                .catch((err) => {
                    console.error(err, "error");
                });
        }

        // analytics
        if ((queryData.get("curlec_method") === "00" || queryData.get("curlec_method") === "01")
            && queryData.get("fpx_debitAuthCode") === "00") {
            console.log("Successful redirection");
            var subsId = queryData.get("fpx_notes");
	    if (queryData.get("curlec_method") === "01") {
		subsId = queryData.get("fpx_sellerOrderNo");
            }
	        pixelService.trackPurchase(subsId);
            googleAnalyticService.trackPurchase(subsId);
            ga4Service.trackCheckoutCompleted(subsId);
        }
    }, []);
    const navigateToViewSubscriptionPage = (sub_id) => {
        history.push(`/customer/subscription/${sub_id}`);
    };

    const renderSubscriptionData = () => {
        let tableData = subscriptionData.map((data, index) => {
            const kebabOptions = [];

            switch (data.subs_status) {
                case "active":
                    cancelOption(kebabOptions, data);
                    break;
                case "pending":
                    break;
                case "inactive":
                    break;
                case "cancelled":
                    break;
                case "paused":
                    break;

                // case "enrolment approved":
            }
            return (
                <tr key={index}>
                    <td
                        style={{
                            fontSize: window.innerWidth < 1000 ? "0px" : "",
                        }}
                    >
                        {data.subs_id}
                    </td>
                    <td>
                        {data.plan_name.charAt(0).toUpperCase() +
                            data.plan_name.slice(1)}
                    </td>
                    {/* <td>{}</td> */}

                    <td>
                        {" "}
                        {window.location.href.includes("vajrasecrets")
                            ? moment(data.delivery_date)
                                  .format("DD/MM/YYYY")
                                  .slice(3)
                            : moment(data.delivery_date).format("DD/MM/YYYY")}
                    </td>
                    <td>
                        {data.subs_status.charAt(0).toUpperCase() +
                            data.subs_status.slice(1)}
                    </td>
                    <td>
                        <EditIcon
                            className="view-icon sub-list-icon"
                            onClick={navigateToViewSubscriptionPage.bind(
                                this,
                                data.subs_id
                            )}
                        />
                        {kebabOptions.length > 0 ? (
                            <KebabMenu options={kebabOptions}></KebabMenu>
                        ) : (
                            <div
                                className="d-inline-block"
                                style={{ width: "24px", height: "24px" }}
                            ></div>
                        )}
                    </td>
                </tr>
            );
        });
        if (rowsPerPage != 0) {
            tableData = tableData.slice(0, rowsPerPage);
        }
        return tableData;
    };

    useEffect(() => {
        customerService
            .getSubscriptionListing()
            .then((res) => {
                setSubscriptionData(res.results);
            })
            .catch((res) => {
                toast.error(
                    <div>
                        Some Error Occur, Please try again later.
                        <br />
                        {JSON.stringify(res, null, 2)}
                        <br />
                    </div>,
                    {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    }
                );
            });
    }, [showPaymentResponse]);
    // loggedin
    return (
        <Grid container>
            <PaymentResponse
                show={showPaymentResponse}
                handleClose={() => {
                    setShowPaymentResponse(false);
                }}
                status={paymentResponseStatus}
                subsId={responseSubsId}
                invId={responseInvId}
                bankId={buyerBankId}
                paymentMethod={buyerPaymentMethod}
            />
            <Grid item style={{ marginLeft: "20px" }} xs={12} md={12} lg={12}>
                {/* profile icon */}
                <Grid container style={{ marginLeft: "20px" }} className="mt-3">
                    {/* profile icon and username dropdown */}
                    <Grid
                        item
                        align="right"
                        xs={12}
                        style={{ marginRight: "30px" }}
                        className="login-bar-customer "
                        lg={12}
                    >
                        <LoginBar />
                    </Grid>
                    <Grid
                        item
                        className="ml-1 mt-2"
                        align="left"
                        xs={12}
                        lg={12}
                    >
                        <strong style={{ fontSize: "33px" }}>
                            My Subscriptions
                        </strong>
                    </Grid>
                    <Grid
                        item
                        className="customer-table"
                        xs={12}
                        md={12}
                        lg={12}
                    >
                        <Table
                            style={{ width: "100%" }}
                            hover
                            // className="mt-2"
                            responsive
                            size="sm"
                        >
                            <thead>
                                <tr
                                    style={{
                                        backgroundColor: "black",
                                        color: "white",
                                        borderRadius: "10px",
                                    }}
                                >
                                    <th
                                        style={{
                                            borderRadius: "10px 0px 0px 0px",
                                            fontSize:
                                                window.innerWidth < 1000
                                                    ? "0px"
                                                    : "",
                                        }}
                                    >
                                        Subscription ID
                                    </th>
                                    <th>Plan Name</th>
                                    {/* <th>Plan Type</th> */}
                                    <th>
                                        {window.location.href.includes(
                                            "vajrasecrets"
                                        )
                                            ? "Start Month"
                                            : "Next Delivery Date"}{" "}
                                    </th>

                                    <th>Status</th>

                                    <th
                                        style={{
                                            borderRadius: "0px 10px 0px 0px",
                                        }}
                                    >
                                        Action
                                    </th>
                                </tr>
                            </thead>
                            <tbody style={{ backgroundColor: "white" }}>
                                {subscriptionData !== ""
                                    ? renderSubscriptionData()
                                    : null}
                            </tbody>
                            <UnsubscribeSurvey
                                subsId={subsId}
                                modalState={showUnsubscribeSurvey}
                                closeModal={handleCloseUnsubscribeModal}
                            />
                            {/* modal is here */}
                            <thead>
                                <tr
                                    style={{
                                        backgroundColor: "black",
                                        color: "white",
                                        borderRadius: "10px",
                                    }}
                                >
                                    <th
                                        style={{
                                            borderRadius: "0px 0px 0px 10px",
                                        }}
                                    ></th>
                                    <th></th>
                                    <th></th>
                                    <th
                                        style={{
                                            fontSize:
                                                window.innerWidth < 1000
                                                    ? "0px"
                                                    : "",
                                        }}
                                    >
                                        ROWS PER PAGE:
                                        <TextField
                                            className="ml-2"
                                            style={{
                                                width: "15%",
                                                backgroundColor: "black",
                                                borderRadius: "5px",
                                                border: "1px solid white",
                                                display:
                                                    window.innerWidth < 1000
                                                        ? "none"
                                                        : "",
                                            }}
                                            InputProps={{
                                                style: {
                                                    height: "20px",
                                                    color: "white",
                                                },
                                            }}
                                            onChange={(e) => {
                                                setRowsPerPage(e.target.value);
                                            }}
                                            size="small"
                                            type="number"
                                        />
                                    </th>

                                    <th
                                        style={{
                                            borderRadius: "0px 0px 10px 0px",
                                            fontSize:
                                                window.innerWidth < 1000
                                                    ? "0px"
                                                    : "",
                                        }}
                                    >
                                        1-
                                        {rowsPerPage == 0
                                            ? subscriptionData.length
                                            : rowsPerPage}{" "}
                                        of {subscriptionData.length}
                                    </th>
                                </tr>
                            </thead>
                        </Table>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default Subscription;
