import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import React from "react";
import "react-alice-carousel/lib/alice-carousel.css";

const renderImage = (path) => {
    return (
        <div>
            <img
                alt=""
                src={`${window.location.href}${process.env.REACT_APP_SITE_PORT_BACKEND}${path}`}
            />
        </div>
    );
};
export default (carousel) => {
    const renderImg = () => {
        var imgArr = [];
        carousel.images.map((data, index) => {
            const image = renderImage(data.image_name);
            imgArr.push(image);
        });
        return imgArr;
    };
    return <Carousel>{renderImg()}</Carousel>;
};
