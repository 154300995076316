import { Grid } from "@mui/material";
import { Modal } from "react-bootstrap";
import "./style.css";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Button } from "react-bootstrap";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import customerService from "../../services/customerService";
import { store } from "../../index.js";
import Swal from "sweetalert2";
import { useEffect } from "react";
import merchantService from "../../services/merchantService";
import { useState } from "react";
import subscriptionService from "../../services/subscriptionService";
import pixelService from "../../services/analyticService/pixel";
import googleAnalyticService from "../../services/analyticService/googleAnalytic";

const PaymentResponse = ({
    show,
    handleClose,
    status,
    subsId,
    invId,
    bankId,
    paymentMethod,
}) => {
    const [curlecMerchantId, setCurlecMerchantId] = useState();
    const [curlecEmployeeId, setCurlecEmployeeId] = useState();
    const [buyerBankId, setBuyerBankId] = useState();
    const [bankList, setBankList] = useState();
    useEffect(() => {
        merchantService.getMerchantInfo().then((e) => {
            setCurlecMerchantId(e[0].merch_curlec_merchant_id);
            setCurlecEmployeeId(parseInt(e[0].merch_curlec_employee_id));
        });
        subscriptionService
            .getBanksForSubscription({ method: "00" })
            .then((res) => {
                var result = res.replace(/'/g, '"');
                var banks = JSON.parse(result);
                setBankList(banks);
            })
            .catch((err) => {
                Swal.fire({
                    title: "Error",
                    text: "Could not retrieve FPX bank list",
                    icon: "error",
                    confirmButtonText: "ok",
                    confirmButtonColor: store.getState().colorState.color,
                });
            });
    }, []);

    const successfulResponse = () => {
        if (paymentMethod == "01" || paymentMethod == "00") {
            return (
                <Grid container className="p-4" align="center">
                    <Grid item xs={12} className="mb-3">
                        {" "}
                        <>
                            <CheckCircleIcon
                                style={{ fontSize: "48px", fill: "#59C19F" }}
                            />
                        </>
                    </Grid>
                    <Grid item xs={12}>
                        <h5>
                            The authorisation is successful. The sign up is now
                            complete.
                        </h5>
                    </Grid>
                    <Grid item xs={12}>
                        {/* create proceed button */}
                        <Button
                            className="mt-3"
                            style={{ borderRadius: "10px", width: "40%" }}
                            onClick={handleClose}
                        >
                            Close
                        </Button>
                    </Grid>
                </Grid>
            );
        }
    };

    const failedResponse = () => {
        if (paymentMethod == "00") {
            return (
                <Grid container className="p-4" align="center">
                    <Grid item xs={12} className="mb-3">
                        {" "}
                        <>
                            <ReportProblemIcon
                                style={{ fontSize: "48px", fill: "#EB4335" }}
                            />
                        </>
                    </Grid>
                    <Grid item xs={12}>
                        <h5>
                            The payment was unsuccessful. Please try to
                            subscribe again.
                        </h5>
                    </Grid>
                    <Grid item xs={12}>
                        {/* create proceed button */}
                        <Button
                            className="mt-3"
                            style={{ borderRadius: "10px", width: "40%" }}
                            onClick={handleClose}
                        >
                            Close
                        </Button>
                    </Grid>
                </Grid>
            );
        } else if (paymentMethod == "01") {
            return (
                <Grid container className="p-4" align="center">
                    <Grid item xs={12} className="mb-3">
                        {" "}
                        <>
                            <ReportProblemIcon
                                style={{ fontSize: "48px", fill: "#EB4335" }}
                            />
                        </>
                    </Grid>
                    <Grid item xs={12}>
                        <h5>
                            The latest authorisation was unsuccessful. Please
                            try again.
                        </h5>
                    </Grid>
                    <Grid item xs={12}>
                        {/* create proceed button */}
                        <Button
                            className="mt-3"
                            style={{ borderRadius: "10px", width: "40%" }}
                            onClick={handleClose}
                        >
                            Close
                        </Button>
                    </Grid>
                </Grid>
            );
        }
    };

    return (
        <Modal
            size="lg"
            centered
            show={show}
            onHide={handleClose}
            backdrop="static"
        >
            {status === "00" ? successfulResponse() : failedResponse()}
        </Modal>
    );
};

export default PaymentResponse;
