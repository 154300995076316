import { callAPI } from "../../utils/apiUtils";
import axios from "axios";
import { useDispatch } from "react-redux";
import { saveLoggedInUserState, clearLoggedInUserState } from "../../action.js";
import { store } from "../../index.js";
import generalFunctionService from "../../services/generalFunctionService";
const customerService = {
    getSubscriptionListing: (pageIndex = 1) => {
        return new Promise((resolve, reject) => {
            callAPI(
                `/subscription/cust_list?customer_id=${
                    store.getState().loggedInUserReducer.userData?.ar_system
                        ?.cust_id
                }&page=${pageIndex}`
            )
                .then((response) => {
                    resolve(response);
                })
                .catch((response) => reject(response));
        });
    },

    //TODO: change IP to environment variable
    createCustomer: (dataBody) => {
        return new Promise((resolve, reject) => {
            callAPI(`/api/customers/`, "post", dataBody)
                .then((response) => {
                    resolve(response);
                })
                .catch((response) => reject(response));
        });
    },
    getCustomerFromAR: () => {
        return new Promise((resolve, reject) => {
            callAPI(`/api/customers/`, "get")
                .then((response) => {
                    resolve(response);
                })
                .catch((response) => reject(response));
        });
    },

    getSpecificCustomerFromAR: (customerID) => {
        return new Promise((resolve, reject) => {
            callAPI(`/api/customers/${customerID}/`, "get")
                .then((response) => {
                    resolve(response);
                })
                .catch((response) => reject(response));
        });
    },

    editCustomerFromAR: (dataBody, customerID) => {
        return new Promise((resolve, reject) => {
            callAPI(`/api/customers/${customerID}/`, "patch", dataBody)
                .then((response) => {
                    resolve(response);
                })
                .catch((response) => reject(response));
        });
    },

    getPaymentDetails: (dataBody, customerID) => {
        return new Promise((resolve, reject) => {
            callAPI(`/api/mandate/details/`, "post", dataBody)
                .then((response) => {
                    resolve(response);
                })
                .catch((response) => reject(response));
        });
    },
};

export default customerService;
