import { Button, Grid } from "@material-ui/core";
import { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import InputGroup from "react-bootstrap/InputGroup";
import Swal from "sweetalert2";
import profileService from "../../../../services/profileService";
import { store } from "../../../../index.js";
const Customization = ({ pcData, arData }) => {
    const [primaryColor, setPrimaryColor] = useState();

    useEffect(() => {
        if (pcData !== undefined) {
            setPrimaryColor(pcData.primary_colour);
        }
    }, []);
    const saveCustomization = () => {
        // patch databody
        // submit api request
        let dataBody = {};
        if (primaryColor === undefined) {
            dataBody = {
                primary_colour: "#000000",
            };
        } else {
            dataBody = {
                primary_colour: primaryColor,
            };
        }
        if (pcData === undefined) {
            profileService
                .createProfile(dataBody)
                .then((e) => {
                    Swal.fire({
                        title: "Profile Successfully Edited",
                        text: "Profile Successfully Edited",
                        icon: "success",
                        confirmButtonColor: store.getState().colorState.color,
                    }).then((e) => {
                        window.location.reload();
                    });
                })
                .catch((res) => {
                    console.error(res, "this is the res error");
                });
        } else {
            profileService
                .updateProfile(pcData.settings_id, dataBody)
                .then((e) => {
                    Swal.fire({
                        title: "Profile Successfully Edited",
                        text: "Profile Successfully Edited",
                        icon: "success",
                        confirmButtonColor: store.getState().colorState.color,
                    }).then((e) => {
                        window.location.reload();
                    });
                })
                .catch((res) => {
                    console.error(res, "this is the error");
                });
        }
    };

    return (
        <Grid item xs={10} md={10} lg={10}>
            <div className=" pt-2 container-fluid addon-form-container">
                <Grid align="left" className="ml-4 " container spacing={1}>
                    <Grid item xs={12} md={12} lg={12}>
                        <h4
                            className="color-contrast-dark mt-3"
                            style={{ color: "black" }}
                        >
                            <strong>Customization Settings</strong>
                        </h4>
                    </Grid>

                    <Grid item xs={6} md={6} lg={6}>
                        <Form.Label>Primary Color</Form.Label>
                        <InputGroup className="w-50">
                            <Form.Control
                                value={primaryColor}
                                onChange={(color) => {
                                    setPrimaryColor(color.target.value);
                                }}
                                className="w-50"
                            />
                            <Form.Control
                                type="color"
                                value={primaryColor}
                                onChange={(color) => {
                                    setPrimaryColor(color.target.value);
                                }}
                            />
                        </InputGroup>
                    </Grid>
                    <Grid item className="mb-3" xs={12} md={12} lg={12} />
                </Grid>
            </div>
            <Grid container className="mt-3" xs={12} md={12} lg={12}>
                {/* create two button cancel and save */}
                <Grid item xs={12} md={12} lg={12}>
                    <Button className="mr-3 " variant="contained">
                        Cancel
                    </Button>
                    <Button
                        className="settings-save-button"
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            saveCustomization();
                        }}
                    >
                        Save
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    );
};
export default Customization;
