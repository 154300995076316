import {
    Avatar,
    ListItem,
    ListItemAvatar,
    ListItemText,
} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import PermIdentityIcon from "@material-ui/icons/PermIdentity";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ApartmentIcon from "@mui/icons-material/Apartment";
import EmailIcon from "@mui/icons-material/Email";
import ExploreIcon from "@mui/icons-material/Explore";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PhoneIcon from "@mui/icons-material/Phone";
import { useEffect, useState } from "react";
import { Button, Card, Figure } from "react-bootstrap";
import Moment from "react-moment";
import { urlAction } from "../../../action";
import LoginBar from "../../../Components/LoginBar/loginbar";
import { store } from "../../../index.js";
import customerService from "../../../services/customerService";
import "./style.css";
const Profile = ({ history, match }) => {
    const [customer, setCustomer] = useState({});
    const [paymentMethod, setPaymentMethod] = useState("FPX (Online Banking)");
    const [mandateRef, setMandateRef] = useState("");
    const [selectedBank, setSelectedBank] = useState("");
    const merchantName = urlAction("get_merchant");
    const [custMandateRef, setCustMandateRef] = useState("");

    useEffect(() => {
        customerService
            .getCustomerFromAR(
                store.getState().loggedInUserReducer.userData?.ar_system
                    ?.cust_id
            )
            .then((res) => {
                setCustomer(res[0]);
                const customerDetails = res[0];
                setCustMandateRef(res[0].cust_mandate_ref);
            })
            .catch((err) => {
                console.error(err);
            });
    }, []);
    // loggedin
    return (
        <Grid container>
            <Grid item style={{ marginLeft: "20px" }} xs={12} md={12} lg={12}>
                {/* profile icon */}
                {/* need first grid of user icon */}
                {/* 2nd grid is for pictures */}
                <Grid
                    item
                    className="settings-login-bar-customer"
                    align="right"
                    xs={12}
                    md={12}
                    lg={12}
                >
                    <LoginBar />
                </Grid>
                <Grid
                    item
                    className="profile-background"
                    xs={12}
                    md={12}
                    lg={12}
                >
                    <Grid container>
                        <Grid
                            align="left"
                            style={{
                                backgroundColor:
                                    store.getState().colorState.color,
                            }}
                            className="customer-background"
                            item
                            xs={12}
                            md={12}
                            lg={12}
                        ></Grid>
                        <Grid container className="mt-2">
                            <Grid
                                item
                                className="mt-2"
                                align="left"
                                xs={2}
                                md={2}
                                lg={2}
                            >
                                <AccountCircleIcon className="profile-icon" />
                            </Grid>
                            <Grid item align="left" xs={10} md={6} lg={6}>
                                <Figure.Caption className="text-left mobile-customer-name">
                                    <span>
                                        <strong>
                                            {Object.keys(customer).length !== 0
                                                ? customer.cust_fname
                                                      .charAt(0)
                                                      .toUpperCase() +
                                                  customer.cust_fname.slice(1) +
                                                  " " +
                                                  customer.cust_lname
                                                      .charAt(0)
                                                      .toUpperCase() +
                                                  customer.cust_lname.slice(1)
                                                : ""}
                                        </strong>
                                        <br />
                                        <p>
                                            Joined{" "}
                                            {customer !== {} ? (
                                                <Moment format="DD/MM/YYYY">
                                                    {customer.date_joined}
                                                </Moment>
                                            ) : (
                                                ""
                                            )}
                                        </p>
                                    </span>
                                </Figure.Caption>{" "}
                            </Grid>
                            <Grid item xs={4} md={4} lg={4} align="right">
                                {/* create edit profile button */}
                                {merchantName == "farcapital" ? (
                                    ""
                                ) : (
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        style={{
                                            borderRadius: "5px",
                                            backgroundColor:
                                                store.getState().colorState
                                                    .color,
                                            color: store.getState().colorState
                                                .font_color,
                                            // marginRight: "60px",
                                            textTransform: "none",
                                            display:
                                                window.innerWidth < 800
                                                    ? "none"
                                                    : "",
                                        }}
                                        onClick={() => {
                                            localStorage.setItem(
                                                "sidebar-state",
                                                "settings"
                                            );

                                            window.location.replace(
                                                "/customer/settings"
                                            );
                                        }}
                                    >
                                        Edit Profile
                                    </Button>
                                )}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item className="ml-3" xs={12} lg={12}>
                    <Grid container>
                        <Grid
                            item
                            align="left"
                            className=""
                            xs={12}
                            md={12}
                            lg={6}
                        >
                            {" "}
                            <Card className="customer-details">
                                <Card.Body>
                                    <Grid item xs={12} lg={12}>
                                        <h4 className="ml-3">
                                            <strong>Customer Details</strong>
                                        </h4>
                                    </Grid>
                                    <Grid className="mt-3" container>
                                        <Grid item xs={12} lg={12}>
                                            <ListItem dense={true}>
                                                <ListItemAvatar>
                                                    <Avatar
                                                        style={{
                                                            backgroundColor:
                                                                "black",
                                                        }}
                                                    >
                                                        <PermIdentityIcon />
                                                    </Avatar>
                                                </ListItemAvatar>
                                                <ListItemText
                                                    primary="ID"
                                                    secondary={customer.cust_id}
                                                />
                                            </ListItem>
                                            <ListItem dense={true}>
                                                <ListItemAvatar>
                                                    <Avatar
                                                        style={{
                                                            backgroundColor:
                                                                "black",
                                                        }}
                                                    >
                                                        <PhoneIcon />
                                                    </Avatar>
                                                </ListItemAvatar>
                                                <ListItemText
                                                    primary="Contact No"
                                                    secondary={
                                                        customer.cust_contact_no
                                                    }
                                                />
                                            </ListItem>
                                            <ListItem dense={true}>
                                                <ListItemAvatar>
                                                    <Avatar
                                                        style={{
                                                            backgroundColor:
                                                                "black",
                                                        }}
                                                    >
                                                        <EmailIcon />
                                                    </Avatar>
                                                </ListItemAvatar>
                                                <ListItemText
                                                    primary="Email"
                                                    secondary={
                                                        customer.cust_email
                                                    }
                                                />
                                            </ListItem>
                                        </Grid>
                                    </Grid>
                                </Card.Body>
                            </Card>
                        </Grid>

                        <Grid
                            item
                            align="left"
                            className={
                                window.innerWidth < 1200 ? "mt-3" : "input-2"
                            }
                            xs={12}
                            md={12}
                            lg={6}
                        >
                            {" "}
                            <Card className="address-details">
                                <Card.Body>
                                    <Grid item xs={12} lg={12}>
                                        <h4 className="ml-3">
                                            <strong>Address</strong>
                                        </h4>
                                    </Grid>
                                    <Grid className="mt-3" container>
                                        <Grid item xs={12} lg={12}>
                                            <ListItem dense={true}>
                                                <ListItemAvatar>
                                                    <Avatar
                                                        style={{
                                                            backgroundColor:
                                                                "black",
                                                        }}
                                                    >
                                                        <LocationOnIcon />
                                                    </Avatar>
                                                </ListItemAvatar>
                                                <ListItemText
                                                    primary="Address"
                                                    secondary={
                                                        customer.cust_addr_line_1
                                                    }
                                                />
                                            </ListItem>
                                            <ListItem dense={true}>
                                                <ListItemAvatar>
                                                    <Avatar
                                                        style={{
                                                            backgroundColor:
                                                                "black",
                                                        }}
                                                    >
                                                        <ExploreIcon />
                                                    </Avatar>
                                                </ListItemAvatar>
                                                <ListItemText
                                                    primary="Postcode"
                                                    secondary={
                                                        customer.cust_addr_postcode
                                                    }
                                                />
                                            </ListItem>
                                            {window.location.href.includes(
                                                "vajrasecrets"
                                            ) ? null : (
                                                <ListItem dense={true}>
                                                    <ListItemAvatar>
                                                        <Avatar
                                                            style={{
                                                                backgroundColor:
                                                                    "black",
                                                            }}
                                                        >
                                                            <ApartmentIcon />
                                                        </Avatar>
                                                    </ListItemAvatar>
                                                    <ListItemText
                                                        primary="Delivery Remarks"
                                                        secondary={
                                                            customer.cust_delivery_instructions ==
                                                            ""
                                                                ? "N/A"
                                                                : customer.cust_delivery_instructions
                                                        }
                                                    />
                                                </ListItem>
                                            )}
                                        </Grid>
                                    </Grid>
                                </Card.Body>
                            </Card>
                        </Grid>
                    </Grid>
                    <Grid
                        align="left"
                        className="mt-3 profile-background"
                        item
                        xs={12}
                        lg={12}
                    >
                        <Card className="payment-details">
                            <Card.Body>
                                <Grid item xs={12} lg={12}>
                                    <h4 className="ml-3">
                                        <strong>Payment Details</strong>
                                    </h4>
                                </Grid>
                                <Grid item xs={12} lg={12}>
                                    <ListItem dense={true}>
                                        <ListItemAvatar>
                                            <Avatar
                                                style={{
                                                    backgroundColor: "black",
                                                }}
                                            >
                                                <PermIdentityIcon />
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText
                                            primary="Payment method"
                                            secondary={paymentMethod}
                                        />
                                    </ListItem>
                                    <ListItem dense={true}>
                                        <ListItemAvatar>
                                            <Avatar
                                                style={{
                                                    backgroundColor: "black",
                                                }}
                                            >
                                                <PhoneIcon />
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText
                                            primary="Bank"
                                            secondary={
                                                selectedBank == ""
                                                    ? "N/A"
                                                    : selectedBank
                                            }
                                        />
                                    </ListItem>
                                    {/* <ListItem dense={true}>
                                        <ListItemAvatar>
                                            <Avatar
                                                style={{
                                                    backgroundColor: "black",
                                                }}
                                            >
                                                <EmailIcon />
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText
                                            primary="Delivery Remarks"
                                            secondary={
                                                customer.cust_delivery_instructions ==
                                                ""
                                                    ? "N/A"
                                                    : customer.cust_delivery_instructions
                                            }
                                        />
                                    </ListItem> */}
                                </Grid>
                            </Card.Body>
                        </Card>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default Profile;
