import { Box, Card, CardContent, Grid } from "@material-ui/core";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import DatePicker from "@mui/lab/DatePicker";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { TextField } from "@mui/material";
import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import { InputGroup } from "react-bootstrap";
import "./style.css";
const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
];
const VajraDeliveryInformation = ({
    planData,
    deliveryDateHandler,
    dedicationNote,
    dedicationNoteHandler,
    refCode,
    refCodeHandler,
    giftMessage,
    giftMessageHandler,
    recipientName,
    recipientNameHandler,
    recipientEmail,
    recipientEmailHandler,
    isGift,
    isGiftHandler,
}) => {
    console.log(planData, "this is the plan data");
    const renderFirstDate = () => {
        // get one month from current date
        const firstDateMonth = new Date().getMonth() + 1;
        // get the first day of firstDateMonth
        const firstDate = new Date(new Date().getFullYear(), firstDateMonth, 1);
        console.log(firstDate, "this is the irst date in vajra");
        return firstDate;
    };
    const [date, setDate] = useState(
        sessionStorage.getItem("deliveryDate") == null
            ? renderFirstDate()
            : new Date(JSON.parse(sessionStorage.getItem("deliveryDate")))
    );

    sessionStorage.setItem("deliveryDate", JSON.stringify(date));
    return (
        <Box>
            {planData.payment_option === "recurring_one_off" ? (
                <Card className="delivery-info-card " variant="outlined">
                    <Grid container>
                        <Grid
                            md={12}
                            sm={12}
                            xs={12}
                            className="p-3"
                            item
                            align="center"
                        >
                            <CardContent>
                                <h4>
                                    <strong>Payment Option</strong>
                                </h4>
                            </CardContent>{" "}
                        </Grid>
                        <Grid align="center" xs={12} md={12} lg={12}>
                            <Form.Select
                                className="w-25 mb-5"
                                aria-label="Default select example"
                                onChange={(e) => {
                                    sessionStorage.setItem(
                                        "paymentOption",
                                        e.target.value
                                    );
                                }}
                            >
                                <option hidden>Select a payment option</option>
                                <option value="recurring">Recurring</option>
                                <option value="one_off">One off</option>
                            </Form.Select>
                        </Grid>
                    </Grid>
                </Card>
            ) : null}
            <Card className="delivery-info-card mt-4" variant="outlined">
                <Grid
                    container
                    // style={{ marginBottom: "0px" }}
                    className="mb-5"
                >
                    <Grid
                        md={12}
                        sm={12}
                        xs={12}
                        className="p-3"
                        item
                        align="center"
                    >
                        <CardContent>
                            <h4>
                                <strong>Subscription Start Month</strong>
                            </h4>
                        </CardContent>{" "}
                    </Grid>
                    <Grid item xs={12} md={12} lg={12} align="center">
                        <Box m={2}>
                            {" "}
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                    inputFormat="01-MM-yyyy"
                                    views={["month", "year"]}
                                    // label="Year and Month"
                                    minDate={renderFirstDate()}
                                    maxDate={new Date("2030-06-01")}
                                    value={date}
                                    onChange={(date) => {
                                        // if date is valid date
                                        console.log(date, "this is the date");
                                        const firstDateMonth =
                                            new Date().getMonth() + 1;
                                        const firstDate = new Date(
                                            new Date().getFullYear(),
                                            firstDateMonth,
                                            1
                                        );
                                        setDate(firstDate);
                                        deliveryDateHandler(firstDate);
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            onKeyDown={(e) =>
                                                e.preventDefault()
                                            }
                                            {...params}
                                            helperText={null}
                                        />
                                    )}
                                />{" "}
                            </LocalizationProvider>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={12} lg={12} align="center">
                        <CardContent className="mt-5">
                            <h5>
                                Your subscription will start on{" "}
                                <strong>
                                    {monthNames[date.getUTCMonth() + 1]}
                                    {" 1st "}
                                    {new Date(date).getUTCFullYear()}
                                </strong>
                            </h5>
                        </CardContent>
                    </Grid>
                </Grid>
            </Card>{" "}
            <Card className="delivery-info-card mt-5" variant="outlined">
                <Grid container className="mb-3">
                    <Grid xs={1} lg={1} md={1} />
                    <Grid
                        md={10}
                        sm={10}
                        xs={10}
                        className="p-3 ml-2 mr-2"
                        item
                        align="center"
                    >
                        <CardContent>
                            <h4>
                                <strong>Additional Details</strong>
                            </h4>
                        </CardContent>
                        <Form.Group
                            className="mb-3 form-group required"
                            style={{ textAlign: "left" }}
                            controlId="exampleForm.ControlTextarea1"
                        >
                            <Form.Label className="control-label">
                                Dedication (500 characters)
                            </Form.Label>
                            <InputGroup hasValidation>
                                <Form.Control
                                    as="textarea"
                                    value={JSON.parse(
                                        sessionStorage.getItem(
                                            "vajraDedicationNote"
                                        )
                                    )}
                                    isInvalid={
                                        dedicationNote.valid === false
                                            ? true
                                            : false
                                    }
                                    rows={3}
                                    onChange={(data) => {
                                        dedicationNoteHandler(
                                            data.target.value
                                        );
                                    }}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {dedicationNote.error}
                                </Form.Control.Feedback>
                            </InputGroup>
                            <Form.Label className="mt-3">
                                Referral Code
                            </Form.Label>
                            <Form.Control
                                value={JSON.parse(
                                    sessionStorage.getItem("vajraRefCode")
                                )}
                                onChange={(refCode) => {
                                    refCodeHandler(refCode.target.value);
                                }}
                            />
                            <Form.Check
                                className="mt-3"
                                type={"checkbox"}
                                // id={`default-${type}`}
                                onChange={(e) => {
                                    isGiftHandler(e.target.checked);
                                }}
                                checked={JSON.parse(
                                    sessionStorage.getItem("vajraGift")
                                )}
                                label={"This is a gift"}
                            />
                            <Form.Label className="mt-4">
                                Gift Message (500 characters)
                            </Form.Label>
                            <InputGroup hasValidation>
                                <Form.Control
                                    disabled={!isGift}
                                    as="textarea"
                                    rows={3}
                                    isInvalid={
                                        giftMessage.valid === false
                                            ? true
                                            : false
                                    }
                                    value={JSON.parse(
                                        sessionStorage.getItem(
                                            "vajraGiftMessage"
                                        )
                                    )}
                                    onChange={(message) => {
                                        giftMessageHandler(
                                            message.target.value
                                        );
                                    }}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {giftMessage.error}
                                </Form.Control.Feedback>
                            </InputGroup>
                            <Form.Label className="mt-4">
                                Recipient Name
                            </Form.Label>{" "}
                            <InputGroup hasValidation>
                                <Form.Control
                                    disabled={!isGift}
                                    isInvalid={
                                        recipientName.valid === false
                                            ? true
                                            : false
                                    }
                                    value={JSON.parse(
                                        sessionStorage.getItem(
                                            "vajraRecipientName"
                                        )
                                    )}
                                    onChange={(name) => {
                                        recipientNameHandler(name.target.value);
                                    }}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {recipientName.error}
                                </Form.Control.Feedback>
                            </InputGroup>
                            <Form.Label className="mt-4">
                                Recipient Email
                            </Form.Label>
                            <Form.Control
                                disabled={!isGift}
                                type="email"
                                isInvalid={
                                    recipientEmail.valid === false
                                        ? true
                                        : false
                                }
                                value={JSON.parse(
                                    sessionStorage.getItem(
                                        "vajraRecipientEmail"
                                    )
                                )}
                                onChange={(email) => {
                                    recipientEmailHandler(email.target.value);
                                }}
                            />
                            <Form.Control.Feedback type="invalid">
                                {recipientEmail.error}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Grid>
                    <Grid xs={1} lg={1} md={1} />
                </Grid>
            </Card>
        </Box>
    );
};

export default VajraDeliveryInformation;
