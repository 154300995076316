import { Button, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import { urlAction } from "../../action";
import AddonCard from "../../Components/AddonCard/index";
import CheckoutTrackerBar from "../../Components/CheckoutTrackerBar/index";
import DeliveryInformation from "../../Components/DeliveryInformation/index";
import VajraDeliveryInformation from "../../Components/VajraDeliveryInformation/index";
import LoginBar from "../../Components/LoginBar/loginbar";
import LoginModal from "../../Components/LoginModal";
import PaymentMethod from "../../Components/PaymentMethod/index";
import PaymentResponse from "../../Components/PaymentResponse/paymentResponse";
import PaymentSummaryCard from "../../Components/PaymentSummaryCard/index";
import PlanHeader from "../../Components/PlanHeader/index";
import SignInCard from "../../Components/SignInCard/index";
import { store } from "../../index.js";
import VajraReviewDetails from "../../Components/VajraReviewDetails";
import KetoccinoMenu from "../../Components/KetoccinoMenu/index";
import planService from "../../services/planService";
import SideOrder from "../../Components/SideOrder/index";
import subscriptionService from "../../services/subscriptionService";
import googleAnalyticService from "../../services/analyticService/googleAnalytic";
import pixelService from "../../services/analyticService/pixel";
import ga4Service from "../../services/analyticService/ga4";
import "./style.css";
import getCroppedImgInFileObj, {
    readImageFileIntoDataUrl,
} from "../../utils/imageUtils";
import profileService from "../../services/profileService";
import { toggleColor } from "../../action.js";
import { Image } from "react-bootstrap";

const PlanDetail = ({ history, match }) => {
    // #1 Checkout Tracker Bar
    // Three Icon (Menu Selection, Delivery Information, Checkout)
    // useState, one variable, first grid of the page
    //    a. Menu Selection
    //      -  Grids of Plan Header and Plan Menu
    //    b. Delivery information
    //      - Delivery date input section
    //    c. Checkout information
    //      - Payment Method
    //      - Payment Summary

    // need plan data here
    //    feed into each component
    //    need to feed them userdata as well

    // Separate into 3 pages
    // 1. Plan Details
    // 2. Delivery Date
    // 3. Payment Summary
    const [planData, setPlanData] = useState(
        sessionStorage.getItem("planData") == null
            ? {}
            : JSON.parse(sessionStorage.getItem("planData"))
    );
    const [currentStep, setCurrentStep] = useState(
        sessionStorage.getItem("stepper") == null
            ? 0
            : JSON.parse(sessionStorage.getItem("stepper"))
    );
    const [logo, setLogo] = useState();
    const [defaultMenu, setDefaultMenu] = useState();
    const [nonDefaultMenu, setNonDefaultMenu] = useState();
    const [propertyModel, setPropertyModel] = useState();
    const [deliveryDaysInfo, setDeliveryDaysInfo] = useState({
        billFreq: null,
    });
    const [deliveryDate, setDeliveryDate] = useState(
        sessionStorage.getItem("deliveryDate") == null
            ? { value: undefined }
            : {
                  value: new Date(
                      JSON.parse(sessionStorage.getItem("deliveryDate"))
                  ),
              }
    );
    const [deliveryTime, setDeliveryTime] = useState(
        sessionStorage.getItem("deliveryTime") == null
            ? { value: undefined }
            : { value: JSON.parse(sessionStorage.getItem("deliveryTime")) }
    );
    const [billFreqPicked, setBillFreqPicked] = useState(
        sessionStorage.getItem("deliveryFrequency") == null
            ? ""
            : JSON.parse(sessionStorage.getItem("deliveryFrequency"))
    );
    const [subData, setSubData] = useState({});
    const [subPrice, setSubPrice] = useState(0);
    const [showLoginModal, setShowLoginModal] = useState(false);
    const [isShippable, setIsShippable] = useState(false);
    const [addon, setAddon] = useState(
        sessionStorage.getItem("addon") !== null
            ? { value: JSON.parse(sessionStorage.getItem("addon")) }
            : { value: [] }
    );
    // const [addonImages, setAddonImages] = useState({ value: [] });
    const isLoggedIn = useSelector(
        (state) => state.loggedInUserReducer.userData
    );
    const [isGift, setIsGift] = useState(
        sessionStorage.getItem("vajraGift") == null
            ? false
            : JSON.parse(sessionStorage.getItem("vajraGift"))
    );
    // for farcapital customer to resume mandate authorisation
    const [showPaymentResponse, setShowPaymentResponse] = useState(false);
    const [buyerBankId, setBuyerBankId] = useState();
    const [responseSubsId, setResponseSubsId] = useState();
    const [responseInvId, setResponseInvId] = useState();
    const [buyerPaymentMethod, setBuyerPaymentMethod] = useState();
    const [paymentResponseStatus, setPaymentResponseStatus] = useState("");
    const [custId, setCustId] = useState("");
    const [subsType, setSubsType] = useState();
    const [customerSubsType, setCustomerSubsType] = useState();
    const [custMandateRef, setCustMandateRef] = useState("");
    const [ketoccinoCoffee, setKetoccinoCoffee] = useState(1);
    const [vajraDedicationNote, setVajraDedicationNote] = useState(
        sessionStorage.getItem("vajraDedicationNote") == null
            ? { value: "" }
            : {
                  value: JSON.parse(
                      sessionStorage.getItem("vajraDedicationNote")
                  ),
              }
    );
    const [vajraRefCode, setVajraRefCode] = useState(
        sessionStorage.getItem("vajraRefCode") == null
            ? ""
            : JSON.parse(sessionStorage.getItem("vajraRefCode"))
    );
    const [vajraGiftMessage, setVajraGiftMessage] = useState(
        sessionStorage.getItem("vajraGiftMessage") == null
            ? { value: "" }
            : { value: JSON.parse(sessionStorage.getItem("vajraGiftMessage")) }
    );
    const [vajraRecipientName, setVajraRecipientName] = useState(
        sessionStorage.getItem("vajraRecipientName") == null
            ? { value: "" }
            : {
                  value: JSON.parse(
                      sessionStorage.getItem("vajraRecipientName")
                  ),
              }
    );
    const [vajraRecipientEmail, setVajraRecipientEmail] = useState(
        sessionStorage.getItem("vajraRecipientEmail") == null
            ? { value: "" }
            : {
                  value: JSON.parse(
                      sessionStorage.getItem("vajraRecipientEmail")
                  ),
              }
    );
    useEffect(() => {
        profileService
            .getProfile()
            .then(async (res) => {
                store.dispatch(toggleColor(res.settings.primary_colour));

                const profileData = res.settings;
                const existingPlanImageInFileObj = await getCroppedImgInFileObj(
                    `${
                        window.location.protocol +
                        "//" +
                        window.location.hostname
                    }${process.env.REACT_APP_SITE_PORT_BACKEND}${
                        profileData.company_logo
                    }`
                );

                const existingPlanImageFileInDataUrl =
                    await readImageFileIntoDataUrl(existingPlanImageInFileObj);

                setLogo(existingPlanImageFileInDataUrl);
            })
            .catch((res) => {});
        planService.getSinglePlan(match.params.id).then((e) => {
            const planInfo = e.plans;
            setPlanData(planInfo);
            // sort planInfo.default_menu based on its ids
            const sortedDefaultMenu = planInfo.default_menu.sort((a, b) => {
                return a.addon_id - b.addon_id;
            });
            const sortedSideOrder = planInfo.side_order.sort((a, b) => {
                return a.addon_id - b.addon_id;
            });
            console.log(sortedDefaultMenu, "this is the sorted default menu");
            setDefaultMenu({ value: sortedDefaultMenu });
            if (window.location.href.includes("ketoccino")) {
                if (
                    JSON.parse(sessionStorage.getItem("ketoccino_coffee")) ===
                        null &&
                    planInfo.default_menu.length > 0
                ) {
                    // set default menu quantity to 0 and dont render "2 bottles of ketoccino" if quantity is 0?
                    // change planinfo.default_menu quantity to 0

                    planInfo.default_menu[0].quantity = 0;
                    sessionStorage.setItem(
                        "ketoccino_coffee",
                        JSON.stringify(planInfo.default_menu)
                    );
                }
                if (
                    JSON.parse(sessionStorage.getItem("deliveryFrequency")) ===
                    null
                ) {
                    setBillFreqPicked("twenty_days");
                    sessionStorage.setItem(
                        "deliveryFrequency",
                        JSON.stringify("twenty_days")
                    );
                }
            }

            setNonDefaultMenu({ value: planInfo.non_default_menu });

            setAddon(
                sessionStorage.getItem("addon") !== null
                    ? { value: JSON.parse(sessionStorage.getItem("addon")) }
                    : { value: sortedSideOrder }
            );
            setPropertyModel(planInfo.property_model);
            setSubsType(planInfo.payment_option);
            if (planInfo.payment_option === "one_off") {
                setCustomerSubsType("instant_pay");
                sessionStorage.setItem(
                    "paymentOption",
                    JSON.stringify("instant_pay")
                );
            } else if (planInfo.payment_option === "recurring") {
                setCustomerSubsType("direct_debit");
                sessionStorage.setItem(
                    "paymentOption",
                    JSON.stringify("direct_debit")
                );
            }

            setIsShippable(planInfo.is_shippable);

            if (planInfo.is_shippable !== false) {
                var deliveryWindowArr = [];
                if (planInfo.delivery_window !== null) {
                    Object.keys(planInfo.delivery_window.day).map(
                        (data, index) => {
                            if (planInfo.delivery_window.day[data] == false) {
                                deliveryWindowArr.push(index);
                            }
                        }
                    );
                    setDeliveryDaysInfo({
                        ...deliveryDaysInfo,
                        blockedDays: deliveryWindowArr,
                        billFreq: planInfo.delivery_frequency,
                        value: planInfo.delivery_window,
                    });
                } else {
                    setDeliveryDaysInfo({
                        ...deliveryDaysInfo,
                        billFreq: planInfo.delivery_frequency,
                        value: planInfo.delivery_window,
                    });
                }
            }
        });
        if (isLoggedIn && isLoggedIn?.userRole !== "merchant") {
            setCustMandateRef(isLoggedIn.ar_system.cust_mandate_ref);
            setCustId(isLoggedIn.ar_system.cust_id);
        }
    }, []);

    const handleSubData = (key, value) => {
        setSubData({ ...subData, [key]: value });
    };

    const handleSubPrice = (value) => {
        setSubPrice(value);
    };

    const handleStepper = (value) => {
        setCurrentStep(currentStep + value);
    };

    const handleCustomerSubsType = (subs_type) => {
        setCustomerSubsType(subs_type);
        sessionStorage.setItem("paymentOption", JSON.stringify(subs_type));
        handleSubData("subs_type", subs_type);
    };
    const handleDeliveryDate = (date) => {
        const newValue = new Date(date).getDay();
        setDeliveryDate({
            value: new Date(date),
            day: newValue,
            display: JSON.stringify(date),
        });
        sessionStorage.setItem("deliveryDate", JSON.stringify(date));
        handleSubData("delivery_date", new Date(date));
    };
    const handleDeliveryTime = (time) => {
        setDeliveryTime({
            value: time,
        });
        sessionStorage.setItem("deliveryTime", JSON.stringify(time));
        handleSubData("delivery_time", time);
    };

    const vajraDedicationNoteHandler = (note) => {
        setVajraDedicationNote({ ...vajraDedicationNote, value: note });
        sessionStorage.setItem("vajraDedicationNote", JSON.stringify(note));
        handleSubData("vajra_dedication_note", note);
    };

    const vajraRefCodeHandler = (code) => {
        setVajraRefCode(code);
        sessionStorage.setItem("vajraRefCode", JSON.stringify(code));
        handleSubData("vajra_ref_code", code);
    };

    const vajraGiftMessageHandler = (message) => {
        setVajraGiftMessage({ ...vajraGiftMessage, value: message });
        sessionStorage.setItem("vajraGiftMessage", JSON.stringify(message));
        handleSubData("vajra_gift_message", message);
    };

    const vajraRecipientNameHandler = (name) => {
        setVajraRecipientName({ value: name });
        sessionStorage.setItem("vajraRecipientName", JSON.stringify(name));
        handleSubData("vajra_recipient_name", name);
    };

    const vajraRecipientEmailHandler = (email) => {
        setVajraRecipientEmail({ ...vajraRecipientEmail, value: email });
        // if email is not in email format, show error message

        if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
            setVajraRecipientEmail({
                ...vajraRecipientEmail,
                value: email,
                error: "Wrong email format",
                valid: false,
            });
        } else {
            setVajraRecipientEmail({
                ...vajraRecipientEmail,
                valid: true,
                value: email,
                error: "",
            });
        }
        sessionStorage.setItem("vajraRecipientEmail", JSON.stringify(email));
        handleSubData("vajra_recipient_email", email);
    };

    const isGiftHandler = (isGift) => {
        setIsGift(isGift);
        sessionStorage.setItem("vajraGift", JSON.stringify(isGift));
        handleSubData("vajra_this_a_gift", isGift);
    };

    const handleBillFreqPicked = (data) => {
        setBillFreqPicked(data);
        handleSubData("delivery_frequency", data);
    };

    const handleMenuProperty = (value, index, type) => {
        if (type == "default") {
            var menu = defaultMenu.value;
            menu[index].property = value;
            setDefaultMenu({ ...defaultMenu, value: menu });
        } else if (type == "non_default") {
            var menu = nonDefaultMenu.value;
            menu[index].property = value;
            setNonDefaultMenu({ ...nonDefaultMenu, value: menu });
        }
    };

    const handleMenuChecker = (value, index, type) => {
        if (type == "default") {
            var menu = defaultMenu.value;
            menu[index].checked = value;
            setDefaultMenu({ ...defaultMenu, value: menu });
        } else if (type == "non_default") {
            var menu = nonDefaultMenu.value;
            menu[index].checked = value;
            setNonDefaultMenu({ ...nonDefaultMenu, value: menu });
        } else if (type == "addon") {
            var menu = addon.value;
            menu[index].checked = value;
            setAddon({ ...addon, value: menu });
            sessionStorage.setItem("addon", JSON.stringify(menu));
        }
    };

    const handleMenuQuantity = (value, index, type) => {
        if (type == "default") {
            var menu = defaultMenu.value;
            menu[index].quantity = value;
            setDefaultMenu({ ...defaultMenu, value: menu });
        } else if (type == "non_default") {
            var menu = nonDefaultMenu.value;
            menu[index].quantity = value;
            setNonDefaultMenu({ ...nonDefaultMenu, value: menu });
        } else if (type == "addon") {
            var menu = addon.value;
            menu[index].quantity = value;
            setAddon({ ...addon, value: menu });
            sessionStorage.setItem("addon", JSON.stringify(menu));
        }
        if (window.location.href.includes("ketoccino"))
            sessionStorage.setItem("ketoccino_coffee", JSON.stringify(menu));
    };

    const validateDeliveryFrequency = () => {
        if (billFreqPicked === undefined) {
            return false;
        } else {
            return true;
        }
    };

    const validateDeliveryTime = () => {
        if (deliveryTime.value === undefined) {
            return false;
        } else {
            return true;
        }
    };

    const filterMenu = (menuType) => {
        if (menuType !== undefined) {
            const filteredData = menuType.value.filter(
                (data) => data.checked == true
            );
            return filteredData;
        } else {
            return [];
        }
    };

    // if ketoccino and payment option is recurring, then render ketoccinomenu
    const ketoccinoChecker = () => {
        let ketoccino = true;
        console.log(planData,'this is the plan data')
	ketoccino = window.location.href.includes("ketoccino") &&
            planData.payment_option === "recurring" ?
            false : true;
	console.log(ketoccino,'this is the result for ketoccino')
        return ketoccino;
    };

    const renderMenuRestriction = (menu) => {
        if (!!planData?.menu_restrictions.applied) {
            if (planData?.menu_restrictions.restriction_type === "all") {
                // only return one value for all
                if (menu === "default_menu")
                    return (
                        <strong style={{ fontWeight: "bold" }}>
                            (Choose{" "}
                            <u>
                                {
                                    planData?.menu_restrictions
                                        .restriction_value.default_non_menu
                                }
                            </u>{" "}
                            items)
                        </strong>
                    );
            }
            // TODO: return two value to be printed in both sections
        }
    };

    const nextStep = (step) => {
        return (
            <>
                {/* need to pass in current page as well */}
                <Grid item align="right" xs={6}>
                    <Button
                        className="step-button mr-3 back"
                        onClick={(e) => {
                            if (currentStep == 0) {
                                ga4Service.trackBackButton("Plan Page");
                                history.push("/");
                            }
                            if (currentStep == 1) {
                                ga4Service.trackBackButton(
                                    "Delivery Option Page"
                                );
                                setCurrentStep(currentStep - 1);
                            } else {
                                setCurrentStep(currentStep - step);
                            }
                        }}
                    >
                        Back
                    </Button>
                </Grid>
                <Grid item align="left" xs={6}>
                    <Button
                        onClick={(e) => {
                            if (currentStep == 0) {
                                sessionStorage.setItem(
                                    "planData",
                                    JSON.stringify(planData)
                                );
                                // if (!window.location.href.includes("ketoccino"?))
                                if (defaultMenu !== undefined) {
                                    if (defaultMenu.value.length != 0) {
                                        const checkMenuInput = validateMenu();
                                        console.log(
                                            checkMenuInput,
                                            "check menu input"
                                        );
                                        if (checkMenuInput === true) {
                                            if (
                                                window.location.href.includes(
                                                    "ketoccino"
                                                ) &&
                                                planData.payment_option ===
                                                    "recurring"
                                            ) {
                                                // check ketoccino coffee quantity here
                                                if (
                                                    JSON.parse(
                                                        sessionStorage.getItem(
                                                            "ketoccino_coffee"
                                                        )
                                                    )[0].quantity > 0
                                                ) {
                                                    setCurrentStep(
                                                        currentStep + step
                                                    );
                                                    ga4Service.trackContinueButtonWithSelection(
                                                        "Plan Page",
                                                        JSON.parse(
                                                            sessionStorage.getItem(
                                                                "ketoccino_coffee"
                                                            )
                                                        )[0].quantity,
                                                        "Recurring"
                                                    );
                                                } else {
                                                    Swal.fire({
                                                        icon: "error",
                                                        title: "Error occurred",
                                                        confirmButtonColor:
                                                            store.getState()
                                                                .colorState
                                                                .color,
                                                        text: `Please choose a serving.`,
                                                    });
                                                }
                                            } else {
                                                if (
                                                    defaultMenu.value.length !=
                                                    0
                                                ) {
                                                    // should only store the menu that is checked
                                                    const filteredDefaultMenu =
                                                        filterMenu(defaultMenu);

                                                    sessionStorage.setItem(
                                                        "defaultMenu",
                                                        JSON.stringify(
                                                            filteredDefaultMenu
                                                        )
                                                    );
                                                }

                                                if (
                                                    nonDefaultMenu.value
                                                        .length != 0
                                                ) {
                                                    const filteredNonDefaultMenu =
                                                        filterMenu(
                                                            nonDefaultMenu
                                                        );

                                                    sessionStorage.setItem(
                                                        "nonDefaultMenu",
                                                        JSON.stringify(
                                                            filteredNonDefaultMenu
                                                        )
                                                    );
                                                }

                                                // for analytics
                                                pixelService.trackInitiateCheckout(
                                                    planData.plan_name
                                                );
                                                googleAnalyticService.trackInitiateCheckout(
                                                    planData.plan_name
                                                );
                                                var oneOffMenuQuantity =
                                                    getMenuQuantity();
                                                ga4Service.trackContinueButtonWithSelection(
                                                    "Plan Page",
                                                    oneOffMenuQuantity,
                                                    "One Off"
                                                );

                                                setCurrentStep(
                                                    currentStep + step
                                                );
                                            }
                                        } else {
                                            // check against planData.menu_restrictions.applied

                                            if (checkMenuInput > 0) {
                                                Swal.fire({
                                                    icon: "error",
                                                    title: "Error occurred",
                                                    confirmButtonColor:
                                                        store.getState()
                                                            .colorState.color,
                                                    text: `Please choose ${checkMenuInput} more item(s)`,
                                                });
                                            } else if (checkMenuInput < 0) {
                                                Swal.fire({
                                                    icon: "error",
                                                    title: "Error occurred",
                                                    confirmButtonColor:
                                                        store.getState()
                                                            .colorState.color,
                                                    text: `Please remove ${-checkMenuInput} item(s)`,
                                                });
                                            } else if (checkMenuInput == 0) {
                                                Swal.fire({
                                                    icon: "error",
                                                    title: "Error occurred",
                                                    confirmButtonColor:
                                                        store.getState()
                                                            .colorState.color,
                                                    text: `Please choose at least one item`,
                                                });
                                            }
                                        }
                                    } else if (defaultMenu.value.length == 0) {
                                        setCurrentStep(currentStep + step);
                                    }
                                } else {
                                    setCurrentStep(currentStep + step);
                                }
                            } else if (currentStep == 1) {
                                if (
                                    window.location.href.includes(
                                        "vajrasecrets"
                                    )
                                ) {
                                    if (
                                        vajraDedicationNote.value.length > 500
                                    ) {
                                        setVajraDedicationNote({
                                            ...vajraDedicationNote,
                                            valid: false,
                                            error: `Dedication note cannot exceed 500 characters. Please remove ${
                                                vajraDedicationNote.value
                                                    .length - 500
                                            } characters.`,
                                        });
                                    } else if (
                                        vajraDedicationNote.value.length < 500
                                    ) {
                                        setVajraDedicationNote({
                                            ...vajraDedicationNote,
                                            valid: true,
                                            error: "",
                                        });
                                    }

                                    if (
                                        vajraGiftMessage.value.length < 500 &&
                                        vajraDedicationNote.value.length <
                                            500 &&
                                        vajraDedicationNote.value.length > 0
                                    ) {
                                        if (isGift) {
                                            let isValid = true;

                                            if (
                                                vajraGiftMessage.value.length >
                                                500
                                            ) {
                                                isValid = false;
                                                setVajraGiftMessage({
                                                    ...vajraGiftMessage,
                                                    valid: false,
                                                    error: `Gift message cannot exceed 500 characters. Please remove ${
                                                        vajraGiftMessage.value
                                                            .length - 500
                                                    } characters.`,
                                                });
                                            } else if (
                                                vajraGiftMessage.value === ""
                                            ) {
                                                isValid = false;

                                                setVajraGiftMessage({
                                                    ...vajraGiftMessage,
                                                    valid: false,
                                                    error: `Gift message cannot be empty. Please enter a message.`,
                                                });
                                            } else if (
                                                vajraGiftMessage.value.length <
                                                500
                                            ) {
                                                isValid = true;
                                                setVajraGiftMessage({
                                                    ...vajraGiftMessage,
                                                    valid: true,
                                                    error: "",
                                                });
                                            }
                                            if (
                                                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(
                                                    vajraRecipientEmail.value
                                                )
                                            ) {
                                                isValid = false;

                                                setVajraRecipientEmail({
                                                    ...vajraRecipientEmail,
                                                    error: "Wrong email format",
                                                    valid: false,
                                                });
                                            }
                                            if (
                                                vajraRecipientEmail.value === ""
                                            ) {
                                                isValid = false;
                                                setVajraRecipientEmail({
                                                    ...vajraRecipientEmail,
                                                    valid: false,
                                                    error: `Recipient email cannot be empty. Please enter an email.`,
                                                });
                                            }
                                            if (
                                                vajraRecipientName.value === ""
                                            ) {
                                                isValid = false;
                                                setVajraRecipientName({
                                                    ...vajraRecipientName,
                                                    valid: false,
                                                    error: `Recipient name cannot be empty. Please enter a name.`,
                                                });
                                            }
                                            if (
                                                vajraRecipientName.value
                                                    .length > 50
                                            ) {
                                                isValid = false;
                                                setVajraRecipientName({
                                                    ...vajraRecipientName,
                                                    valid: false,
                                                    error: `Recipient name cannot exceed 50 characters. Please remove ${
                                                        vajraRecipientName.value
                                                            .length - 50
                                                    } characters.`,
                                                });
                                            }
                                            if (isValid) {
                                                setCurrentStep(
                                                    currentStep + step
                                                );
                                            } else {
                                                Swal.fire({
                                                    icon: "error",
                                                    title: "Error occurred",
                                                    confirmButtonColor:
                                                        store.getState()
                                                            .colorState.color,
                                                    text: `Please fill out all required fields`,
                                                });
                                            }
                                        } else {
                                            setCurrentStep(currentStep + step);
                                        }
                                    } else if (
                                        vajraDedicationNote.value.length == 0
                                    ) {
                                        Swal.fire({
                                            icon: "error",
                                            title: "Error occurred",
                                            confirmButtonColor:
                                                store.getState().colorState
                                                    .color,
                                            text: `Please enter a dedication note`,
                                        });
                                        setVajraDedicationNote({
                                            ...vajraDedicationNote,
                                            valid: false,
                                            error: `Please enter a dedication note`,
                                        });
                                    } else if (
                                        vajraRecipientEmail.valid === false
                                    ) {
                                        Swal.fire({
                                            icon: "error",
                                            title: "Error occurred",
                                            confirmButtonColor:
                                                store.getState().colorState
                                                    .color,
                                            text: `Please enter a valid email address`,
                                        });
                                        setVajraRecipientEmail({
                                            ...vajraRecipientEmail,
                                            error: "Please enter a valid email address",
                                            valid: false,
                                        });
                                    }
                                } else {
                                    // Check against all the configured product deliverables for the plan (validation)
                                    // 1. Check if delivery date has been chosen
                                    // 2. If delivery frequency exists
                                    //    - Check if delivery frequency is valid
                                    // 3. If delivery time exists
                                    //   - Check if delivery time is valid

                                    if (deliveryDate.value === undefined) {
                                        setDeliveryDate({
                                            ...deliveryDate,
                                            valid: false,
                                            error: "Please choose a delivery date",
                                        });
                                    } else {
                                        // run validation for delivery date
                                        var isValid = true;
                                        if (
                                            planData.delivery_frequency !== null
                                        ) {
                                            if (
                                                planData.delivery_frequency
                                                    .length > 0
                                            ) {
                                                isValid =
                                                    validateDeliveryFrequency() &&
                                                    isValid;
                                            }
                                        }
                                        if (planData.delivery_window !== null) {
                                            if (
                                                planData.delivery_window.time
                                                    .length > 0
                                            ) {
                                                isValid =
                                                    validateDeliveryTime() &&
                                                    isValid;
                                            }
                                        }
                                        if (isValid) {
                                            sessionStorage.setItem(
                                                "deliveryDate",
                                                JSON.stringify(
                                                    deliveryDate.value
                                                )
                                            );

                                            ga4Service.trackContinueButtonWithDeliveryOptions(
                                                "Delivery Option Page",
                                                deliveryDate.display,
                                                JSON.stringify(
                                                    deliveryTime.value
                                                )
                                            );
                                            setCurrentStep(currentStep + step);
                                        } else {
                                            Swal.fire({
                                                icon: "error",
                                                title: "Error occurred",
                                                confirmButtonColor:
                                                    store.getState().colorState
                                                        .color,
                                                text: "Please check all the inputs",
                                            });
                                        }
                                    }
                                }
                            } else {
                                sessionStorage.setItem(
                                    "stepper",
                                    currentStep + step
                                );
                                setCurrentStep(currentStep + step);
                            }
                        }}
                        className="step-button ml-3 continue"
                        style={{
                            color: store.getState().colorState.font_color,
                            backgroundColor: store.getState().colorState.color,
                        }}
                    >
                        Continue
                    </Button>
                </Grid>
            </>
        );
    };

    const getMenuQuantity = () => {
        var menuQuantity = 0;
        const checkedMenu = defaultMenu.value.filter(
            (obj) => obj.checked == true
        );
        const checkedNonMenu = nonDefaultMenu.value.filter(
            (obj) => obj.checked == true
        );

        checkedNonMenu.map((e) => {
            menuQuantity += e.quantity;
        });
        checkedMenu.map((e) => {
            menuQuantity += e.quantity;
        });

        return menuQuantity;
    };

    const validateMenu = () => {
        // filter default menu Only
        var menuQuantity = 0;
        const checkedMenu = defaultMenu.value.filter(
            (obj) => obj.checked == true
        );
        const checkedNonMenu = nonDefaultMenu.value.filter(
            (obj) => obj.checked == true
        );
        const checkedSideOrder = addon.value.filter(
            (obj) => obj.checked == true
        );

        checkedNonMenu.map((e) => {
            menuQuantity += e.quantity;
        });
        checkedMenu.map((e) => {
            menuQuantity += e.quantity;
        });
        console.log(menuQuantity, "this is the menu quanittyt");
        console.log(planData.menu_restrictions, "this is the menu restriction");
        if (!!planData?.menu_restrictions.applied && menuQuantity > 0) {
            if (planData?.menu_restrictions.restriction_type === "all") {
                const difference =
                    parseInt(
                        planData?.menu_restrictions.restriction_value
                            .default_non_menu
                    ) - menuQuantity;

                if (
                    menuQuantity !==
                    parseInt(
                        planData?.menu_restrictions.restriction_value
                            .default_non_menu
                    )
                ) {
                    // check difference between menuQuantity and planData.menu_restrictions.restriction_value.default_non_menu
                    const difference =
                        parseInt(
                            planData?.menu_restrictions.restriction_value
                                .default_non_menu
                        ) - menuQuantity;

                    return difference;
                } else {
                    setSubData({
                        ...subData,
                        default_menu: checkedMenu,
                        non_default_menu: checkedNonMenu,
                        side_order: checkedSideOrder,
                    });
                    return true;
                }
            } else if (
                planData?.menu_restrictions.restriction_type === "individual" &&
                menuQuantity > 0
            ) {
                const defaultMenuRestriction = parseInt(
                    planData?.menu_restrictions.restriction_value.default_menu
                );

                const nonDefaultMenuRestriction = parseInt(
                    planData?.menu_restrictions.restriction_value
                        .non_default_menu
                );

                const defaultMenuDiff = !window.location.href.includes(
                    "ketoccino"
                )
                    ? defaultMenuRestriction - checkedMenu.length
                    : defaultMenuRestriction - ketoccinoCoffee.length;

                const nonDefaultMenuDiff =
                    nonDefaultMenuRestriction - checkedNonMenu.length;

                if (defaultMenuDiff > 0) {
                    return defaultMenuDiff;
                }

                if (nonDefaultMenuDiff > 0 || nonDefaultMenuDiff < 0) {
                    return nonDefaultMenuDiff;
                }

                setSubData({
                    ...subData,
                    default_menu: checkedMenu,
                    non_default_menu: checkedNonMenu,
                    side_order: checkedSideOrder,
                });
                return true;
            }
        } else {
            // check at least one
            console.log(checkedMenu.length, "this is the checked menu");
            if (
                menuQuantity === 0 &&
                !window.location.href.includes("ketoccino")
            ) {
                return 0;
            } else if (
                window.location.href.includes("ketoccino") &&
                planData.payment_option === "one_off" &&
                menuQuantity === 0
            ) {
                return 0;
            }
            setSubData({
                ...subData,
                default_menu: checkedMenu,
                non_default_menu: checkedNonMenu,
                side_order: checkedSideOrder,
            });
            return true;
        }
    };
    const ketoccinoCoffeeHandler = (value) => {
        var menu = defaultMenu?.value;
        menu[0].quantity = value;
        setDefaultMenu({ ...defaultMenu, value: menu });
        sessionStorage.setItem("ketoccino_coffee", JSON.stringify(menu));
    };
    const renderGuestUser = () => {
        return (
            <>
                <Grid item align="right" xs={12} md={12} lg={12}>
                    <Grid item className="login-bar" xs={12} md={12} lg={12}>
                        <Button
                            variant="contained"
                            // color="primary"
                            className="mt-4"
                            style={{
                                borderRadius: "10px",
                                backgroundColor:
                                    store.getState().colorState.color,
                                color: store.getState().colorState.font_color,
                            }}
                            onClick={() => setShowLoginModal(true)}
                        >
                            Login
                        </Button>
                        <LoginModal
                            show={showLoginModal}
                            handleClose={() => {
                                setShowLoginModal(false);
                            }}
                        />
                    </Grid>
                </Grid>
                <Grid item xs={0} md={1} lg={1} />
                <Grid item align="center" xs={12} md={10} lg={10}>
                    <Grid container className="mt-3">
                        <Grid item align="center" xs={12} md={12} lg={12}>
                            <Image
                                src={logo}
                                onClick={() => {
                                    history.push("/");
                                }}
                                style={{ marginLeft: "150px" }}
                                className="mt-3 homepage-logo"
                            />
                        </Grid>
                        <Grid item className="mt-5" align="center" xs={12}>
                            <CheckoutTrackerBar
                                deliveryInfo={deliveryDaysInfo}
                                stepper={currentStep}
                            />
                        </Grid>

                        <Grid
                            item
                            className="p-3"
                            align="center"
                            style={{ marginRight: "20px" }}
                            xs={12}
                        >
                            {currentProcess()}
                        </Grid>
                    </Grid>
                </Grid>
            </>
        );
    };
    const renderLoggedInUser = () => {
        return (
            <>
                <Grid
                    item
                    className="login-bar-checkout"
                    style={{ marginLeft: "20px" }}
                    xs={12}
                    md={12}
                    lg={12}
                >
                    <Grid
                        item
                        align="right"
                        className="ml-3 mr-3 mt-3"
                        style={{ marginRight: "20px" }}
                        xs={12}
                        md={12}
                        lg={12}
                    >
                        <LoginBar />
                    </Grid>
                    <Grid item align="center" xs={12} md={12} lg={12}>
                        <Image
                            src={logo}
                            onClick={() => {
                                history.push("/");
                            }}
                            style={{ marginLeft: "150px" }}
                            className="mt-3 homepage-logo"
                        />
                    </Grid>
                    <Grid
                        item
                        className="mt-5 ml-5"
                        align="center"
                        xs={12}
                        md={12}
                        lg={12}
                    >
                        <CheckoutTrackerBar
                            deliveryInfo={deliveryDaysInfo}
                            stepper={currentStep}
                        />
                    </Grid>

                    <Grid
                        item
                        className="p-3"
                        align="center"
                        style={{ marginRight: "20px" }}
                        xs={12}
                        md={12}
                        lg={12}
                    >
                        {currentProcess()}
                    </Grid>
                </Grid>
                {/* <Grid item xs={} /> */}
            </>
        );
    };

    const currentProcess = () => {
        switch (currentStep) {
            default:
                history.push("/");
            case 0:
                sessionStorage.setItem("stepper", 0);

                return (
                    // pass in plan data
                    // pass in default menu and non default menu
                    <Grid container>
                        <Grid
                            xs={12}
                            md={12}
                            lg={12}
                            className="mt-4"
                            item
                            align="center"
                        >
                            {planData !== undefined ? (
                                // TODO: pass in image
                                <PlanHeader data={planData} />
                            ) : (
                                ""
                            )}
                        </Grid>
                        {ketoccinoChecker() &&
                        defaultMenu !== undefined &&
                        defaultMenu.value.length !== 0 ? (
                            // Passed in:
                            // 1. addon name
                            // 2. addon description
                            // 3. item property (optional)
                            // 4. item quantity (optional)
                            <>
                                <Grid
                                    className="mt- pl-2"
                                    item
                                    xs={12}
                                    md={12}
                                    lg={12}
                                >
                                    <h5 className="addon-title mt-5 ml-1 text-left">
                                        <p>
                                            {/* (Choose{" "}
                                                {planData.max_menu_selection} )
                                                 */}
                                            {planData?.menu_setup?.display_name
                                                ?.default_menu_name !==
                                            undefined
                                                ? `${planData?.menu_setup.display_name.default_menu_name} `
                                                : "Default menu"}
                                            {renderMenuRestriction(
                                                "default_menu"
                                            )}
                                        </p>
                                    </h5>
                                </Grid>
                                <Grid spacing={2} className="pl-3" container>
                                    {/* if pricing model is flat fee then render defaultMenu and checked value equals to true, if pricing model is property then render default menu and checked value equals to false for all value */}

                                    {defaultMenu.value.map((e, index) => {
                                        if (
                                            planData?.pricing_model ==
                                            "flat_fee"
                                        ) {
                                            if (e.checked == undefined) {
                                                e.checked = true;
                                            }
                                        } else {
                                            if (e.checked == undefined) {
                                                e.checked = false;
                                            }
                                        }

                                        return (
                                            <AddonCard
                                                quantity={
                                                    planData?.menu_setup
                                                        .length === 0
                                                        ? false
                                                        : planData?.menu_setup
                                                              .quantity_selection
                                                              .default_menu_quantity
                                                }
                                                checkerHandler={
                                                    handleMenuChecker
                                                }
                                                quantityHandler={
                                                    handleMenuQuantity
                                                }
                                                propertyHandler={
                                                    handleMenuProperty
                                                }
                                                property={
                                                    planData?.property_model
                                                }
                                                propertyData={propertyModel}
                                                type={"default"}
                                                index={index}
                                                data={e}
                                            />
                                        );
                                    })}
                                </Grid>
                            </>
                        ) : (
                            ""
                        )}
                        {!window.location.href.includes("ketoccino") &&
                        nonDefaultMenu !== undefined &&
                        nonDefaultMenu?.length !== 0 ? (
                            // Passed in:
                            // 1. addon name
                            // 2. addon description
                            // 3. item property (optional)
                            // 4. item quantity (optional)
                            <Grid className="pl-3 mt-3" container>
                                <Grid className="mt-3" item xs={12}>
                                    <h5 className="addon-title text-left">
                                        {planData?.menu_setup.display_name
                                            ?.non_default_menu_name !==
                                        undefined
                                            ? `${planData?.menu_setup.display_name.non_default_menu_name} `
                                            : ""}

                                        {renderMenuRestriction(
                                            "non_default_menu"
                                        )}
                                    </h5>
                                </Grid>
                                <Grid spacing={2} container>
                                    {nonDefaultMenu.value.map((e, index) => {
                                        if (e.checked == undefined) {
                                            e.checked = false;
                                        }
                                        return (
                                            <AddonCard
                                                className="addon-card-container p-5"
                                                quantity={
                                                    planData?.menu_setup
                                                        ?.length === 0
                                                        ? false
                                                        : planData?.menu_setup
                                                              .quantity_selection
                                                              .non_default_menu_quantity
                                                }
                                                propertyData={propertyModel}
                                                checkerHandler={
                                                    handleMenuChecker
                                                }
                                                quantityHandler={
                                                    handleMenuQuantity
                                                }
                                                propertyHandler={
                                                    handleMenuProperty
                                                }
                                                type={"non_default"}
                                                index={index}
                                                data={e}
                                            />
                                        );
                                    })}
                                </Grid>
                            </Grid>
                        ) : (
                            ""
                        )}
                        {window.location.href.includes("ketoccino") &&
                        defaultMenu !== undefined &&
                        planData.payment_option !== "one_off" ? (
                            <Grid className="mt-5" container>
                                <KetoccinoMenu
                                    coffeeAmount={
                                        defaultMenu.value[0] !== undefined
                                            ? defaultMenu.value[0].quantity
                                            : null
                                    }
                                    coffeeAmountHandler={ketoccinoCoffeeHandler}
                                    coffeeDetails={
                                        defaultMenu !== undefined
                                            ? defaultMenu.value[0]
                                            : null
                                    }
                                />
                            </Grid>
                        ) : null}

                        {/* 1. check if they have quantity selection variable
                                if quantity selection, check only quantity of default menu 
                            */}
                        <Grid container className="mt-4 mb-5" align="center">
                            {/* check delivery window here */}
                            {isShippable === true ? nextStep(1) : nextStep(2)}
                        </Grid>
                    </Grid>
                );
            case 1:
                sessionStorage.setItem("stepper", 1);
                if (window.location.href.includes("vajrasecrets")) {
                    return (
                        <>
                            <Grid item align="center" xs={12} md={12} lg={12}>
                                <VajraDeliveryInformation
                                    planData={planData}
                                    deliveryDateHandler={handleDeliveryDate}
                                    dedicationNote={vajraDedicationNote}
                                    dedicationNoteHandler={
                                        vajraDedicationNoteHandler
                                    }
                                    refCode={vajraRefCode}
                                    refCodeHandler={vajraRefCodeHandler}
                                    giftMessage={vajraGiftMessage}
                                    giftMessageHandler={vajraGiftMessageHandler}
                                    recipientName={vajraRecipientName}
                                    recipientNameHandler={
                                        vajraRecipientNameHandler
                                    }
                                    recipientEmail={vajraRecipientEmail}
                                    recipientEmailHandler={
                                        vajraRecipientEmailHandler
                                    }
                                    isGift={isGift}
                                    isGiftHandler={isGiftHandler}
                                />
                            </Grid>
                            <Grid container className="mt-4 mb-5">
                                {isLoggedIn !== undefined
                                    ? nextStep(2)
                                    : nextStep(1)}
                            </Grid>
                        </>
                    );
                } else {
                    return (
                        <>
                            <Grid
                                xs={12}
                                md={12}
                                lg={12}
                                className="mt-4"
                                item
                                align="center"
                            >
                                <DeliveryInformation
                                    deliveryInfo={deliveryDaysInfo}
                                    deliveryDate={deliveryDate}
                                    deliveryTime={deliveryTime}
                                    billFreqPicked={billFreqPicked}
                                    subsType={subsType}
                                    customerSubsType={customerSubsType}
                                    customerSubsTypeHandler={
                                        handleCustomerSubsType
                                    }
                                    deliveryDateHandler={handleDeliveryDate}
                                    deliveryTimeHandler={handleDeliveryTime}
                                    billFreqPickedHandler={handleBillFreqPicked}
                                />
                            </Grid>
                            <Grid container className="mt-4 mb-5">
                                {isLoggedIn !== undefined
                                    ? nextStep(2)
                                    : nextStep(1)}
                            </Grid>
                        </>
                    );
                }

            case 2:
                sessionStorage.setItem("stepper", 2);

                return (
                    // 2 cards side by side
                    // Subscribe card
                    // Payment summary

                    <Grid container className="mb-5 mt-3" spacing={2}>
                        <Grid item xs={12} md={12} lg={6}>
                            {isLoggedIn !== undefined ? (
                                setCurrentStep(currentStep + 1)
                            ) : (
                                <SignInCard
                                    stepper={handleStepper}
                                    deliveryInfo={deliveryDaysInfo}
                                />
                            )}
                        </Grid>
                        <Grid item xs={12} md={12} lg={6}>
                            {planData !== undefined ? (
                                <PaymentSummaryCard
                                    handleSubPrice={handleSubPrice}
                                    handleSubData={handleSubData}
                                    plan={planData}
                                    sideOrder={addon.value}
                                />
                            ) : (
                                ""
                            )}
                        </Grid>
                        <Grid item xs={12} md={12} lg={6}>
                            {addon.value && addon.value.length == 0 ? null : (
                                <SideOrder
                                    data={addon.value}
                                    checkerHandler={handleMenuChecker}
                                    quantityHandler={handleMenuQuantity}
                                    type={"addon"}
                                />
                            )}
                        </Grid>
                        <Grid item xs={12} md={12} lg={6}>
                            {window.location.href.includes("vajrasecrets") ? (
                                <VajraReviewDetails />
                            ) : null}
                        </Grid>
                    </Grid>
                );
            case 3:
                return (
                    // TDODO: payment method after signed in
                    // payment summary
                    <>
                        <PaymentResponse
                            show={showPaymentResponse}
                            handleClose={() => {
                                setShowPaymentResponse(false);
                            }}
                            status={paymentResponseStatus}
                            subsId={responseSubsId}
                            invId={responseInvId}
                            bankId={buyerBankId}
                            paymentMethod={buyerPaymentMethod}
                        ></PaymentResponse>
                        <Grid
                            container
                            className={window.innerWidth < 1200 ? "" : "mt-4"}
                            spacing={2}
                        >
                            <Grid item xs={12} md={12} lg={6}>
                                <PaymentMethod
                                    deliveryInfo={deliveryDaysInfo}
                                    stepper={handleStepper}
                                    customerSubsType={customerSubsType}
                                    billFreqPicked={billFreqPicked}
                                    subPrice={subPrice}
                                    subData={subData}
                                    planData={planData}
                                    deliveryDate={deliveryDate}
                                    custMandateRef={custMandateRef}
                                    custId={custId}
                                />
                            </Grid>
                            <Grid item xs={12} md={12} lg={6}>
                                <PaymentSummaryCard
                                    handleSubPrice={handleSubPrice}
                                    handleSubData={handleSubData}
                                    plan={planData}
                                    sideOrder={addon.value}
                                />
                            </Grid>
                            <Grid item xs={12} md={12} lg={6}>
                                {addon.value &&
                                addon.value.length == 0 ? null : (
                                    <SideOrder
                                        data={addon.value}
                                        checkerHandler={handleMenuChecker}
                                        quantityHandler={handleMenuQuantity}
                                        type={"addon"}
                                    />
                                )}
                            </Grid>
                            <Grid item xs={12} md={12} lg={6}>
                                {window.location.href.includes(
                                    "vajrasecrets"
                                ) ? (
                                    <VajraReviewDetails />
                                ) : null}
                            </Grid>
                        </Grid>
                    </>
                );
        }
    };

    return (
        <Grid container>
            {" "}
            {isLoggedIn == undefined ? renderGuestUser() : renderLoggedInUser()}
        </Grid>
    );
};

export default PlanDetail;
