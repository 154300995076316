import * as React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { Grid } from "@mui/material";
import ImageCarousel from "../ImageCarousel/index";
import { useEffect } from "react";
import draftToHtml from "draftjs-to-html";
import convertToRaw from "draft-js";
import "./style.css";
const PlanHeader = ({ data, history, match }) => {
    useEffect(() => {
        sessionStorage.removeItem("vajraDedicationNote");
        sessionStorage.removeItem("vajraGift");
        sessionStorage.removeItem("vajraRecipientEmail");
        sessionStorage.removeItem("vajraRecipientName");
        sessionStorage.removeItem("vajraGiftMessage");
        sessionStorage.removeItem("vajraRefCode");
        sessionStorage.removeItem("deliveryDate");
    }, []);
    return (
        <Box>
            <Card className="plan-header-card" variant="outlined">
                <Grid container>
                    <Grid item lg={5} md={12} xs={12}>
                        {/* Carousel */}
                        <CardContent className="mt-5">
                            <ImageCarousel data={data.plan_image} />
                        </CardContent>
                    </Grid>
                    <Grid item lg={7} md={12} xs={12}>
                        <CardContent className="plan-header-description ">
                            {" "}
                            <Typography variant="h4" gutterBottom>
                                {data.plan_name}
                            </Typography>
                            <Typography
                                dangerouslySetInnerHTML={{
                                    __html: data.plan_desc,
                                }}
                            ></Typography>
                        </CardContent>
                    </Grid>
                </Grid>
            </Card>
        </Box>
    );
};
export default PlanHeader;
