import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { Grid } from "@mui/material";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Checkbox from "@mui/material/Checkbox";
import React, { useEffect, useState } from "react";
import Image from "react-bootstrap/Image";
import getCroppedImgInFileObj, {
    readImageFileIntoDataUrl,
} from "../../utils/imageUtils";
import "./style.css";
// pass in menu data

const AddonCard = ({
    data,
    index,
    quantity,
    checkerHandler,
    quantityHandler,
    propertyHandler,
    type,
    propertyData,
}) => {
    // The `path` lets us build <Route> paths that are
    // relative to the parent route, while the `url` lets
    // us build relative links.
    const [logo, setLogo] = useState();
    const getLogo = async () => {
        if (data.addon_image !== null) {
            const existingPlanImageInFileObj = await getCroppedImgInFileObj(
                `${window.location.protocol + "//" + window.location.hostname}${
                    process.env.REACT_APP_SITE_PORT_BACKEND
                }${data.addon_image}`
            );

            const existingPlanImageFileInDataUrl =
                await readImageFileIntoDataUrl(existingPlanImageInFileObj);
            setLogo(existingPlanImageFileInDataUrl);
            return existingPlanImageFileInDataUrl;
        }
    };
    useEffect(() => {
        getLogo();
    }, []);

    const renderPropertyPrice = () => {
        // filter data.property in propertyData then get price
        var total = 0;

        const propertyPrice = propertyData.property
            .filter((property) => property.property_name == data.property)
            .map((obj) => {
                total = (data.addon_price + obj.property_price) * data.quantity;
            });

        return <p>+RM{total.toFixed(2)}</p>;
    };
    return (
        // need to render based on the amount of menus that they have
        <Grid
            item
            style={
                index % 2 == 0
                    ? { paddingRight: "10px" }
                    : { paddingLeft: "10px" }
            }
            lg={6}
            md={12}
            xs={12}
        >
            <Card className="addon-card-container h-100" variant="outlined">
                <Grid container className="mt-4  ">
                    {window.location.href.includes("vajrasecrets") ||
                    window.location.href.includes("kinabalucoffee") ||
                    (window.location.href.includes("ketoccino") &&
                        type == "default") ? null : (
                        <Grid
                            item
                            className="checker-mobile"
                            xs={1}
                            md={1}
                            lg={1}
                        >
                            <CardContent>
                                <Checkbox
                                    className="addon-card-checker"
                                    defaultChecked={data.checked}
                                    onClick={(e) => {
                                        checkerHandler(
                                            e.target.checked,
                                            index,
                                            type
                                        );
                                    }}
                                />
                            </CardContent>
                        </Grid>
                    )}

                    {data.addon_image !== null ? (
                        <Grid
                            style={{ position: "relative" }}
                            xs={4}
                            md={4}
                            lg={4}
                        >
                            <Image
                                src={logo}
                                style={{
                                    alignSelf: "center",
                                    // position: "absolute",
                                    // top: "50%",
                                    // left: "50%",
                                    // transform: "translate(-50%, -50%)",
                                }}
                                className="addon-card-image"
                            />
                        </Grid>
                    ) : (
                        ""
                    )}

                    <Grid item xs={7} md={7} lg={7} align="left">
                        <Grid
                            className="addon-details ml-2"
                            container
                            xs={12}
                            md={12}
                            lg={12}
                        >
                            <Grid
                                item
                                // className="addon-details"
                                xs={
                                    window.location.href.includes(
                                        "vajrasecrets"
                                    )
                                        ? 12
                                        : 8
                                }
                                md={
                                    window.location.href.includes(
                                        "vajrasecrets"
                                    )
                                        ? 12
                                        : 8
                                }
                                lg={
                                    window.location.href.includes(
                                        "vajrasecrets"
                                    )
                                        ? 12
                                        : 8
                                }
                            >
                                {data.addon_name}
                            </Grid>
                            {type == "non_default" || type == "addon" ? (
                                <Grid item align="center" lg={4} md={4} xs={4}>
                                    <p
                                        style={{
                                            // marginLeft: "10px",
                                            color: "#59C19F",
                                            // marginTop: "15px",
                                        }}
                                    >
                                        +RM
                                        {(
                                            data.addon_price * data.quantity
                                        ).toFixed(2)}{" "}
                                    </p>
                                </Grid>
                            ) : (
                                ""
                            )}
                            {type == "default" && propertyData !== null ? (
                                <Grid item lg={4} md={4} xs={4} align="center">
                                    <p
                                        style={{
                                            color: "#59C19F",
                                        }}
                                    >
                                        {propertyData == null
                                            ? ""
                                            : renderPropertyPrice()}
                                    </p>
                                </Grid>
                            ) : (
                                ""
                            )}
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            md={12}
                            lg={12}
                            className="ml-2 mt-3 addon-details addon-description"
                        >
                            {data.addon_desc.map((text) => {
                                return (
                                    <div className="mt-2">
                                        {text}
                                        <br />
                                    </div>
                                );
                            })}
                        </Grid>
                        <Grid
                            container
                            className="ml-2 addon-details mb-2"
                            xs={12}
                        >
                            {propertyData == null ? (
                                ""
                            ) : (
                                <Grid item lg={6} xs={12}>
                                    <select
                                        class="form-select"
                                        aria-label="Default select example"
                                        style={{
                                            width: "90%",
                                            textAlign: "center",
                                            borderRadius: "5px",
                                            height: "75%",
                                            border: "1px black solid",
                                            marginTop: "10px",
                                        }}
                                        onChange={(e) => {
                                            propertyHandler(
                                                e.target.value,
                                                index,
                                                type
                                            );
                                        }}
                                    >
                                        {propertyData == null
                                            ? ""
                                            : propertyData.property.map((e) => {
                                                  return (
                                                      // TODO: need to return value here
                                                      <option
                                                          value={
                                                              e.property_name
                                                          }
                                                      >
                                                          {e.property_name}
                                                      </option>
                                                  );
                                              })}
                                    </select>
                                </Grid>
                            )}
                            {
                                quantity == true ? (
                                    <Grid
                                        className="center w-50 h-75 item-property"
                                        style={{
                                            marginTop: "10px",
                                            // width: "50%",
                                            display: "flex",
                                            border: "1px black solid",
                                            borderRadius: "5px",
                                            // width: "100%",
                                            height: "75%",
                                            bottom: "10px",
                                        }}
                                        item
                                        lg={9}
                                        md={12}
                                        xs={12}
                                    >
                                        {/* Quantity (optional) */}{" "}
                                        <Grid
                                            item
                                            align="left"
                                            className="w-50"
                                            lg={4}
                                            xs={4}
                                        >
                                            <Button
                                                className="w-50 h-100 remove-icon-container"
                                                onClick={() => {
                                                    if (data.quantity != 0) {
                                                        quantityHandler(
                                                            data.quantity - 1,
                                                            index,
                                                            type
                                                        );
                                                    }
                                                }}
                                            >
                                                <RemoveIcon
                                                    style={{
                                                        width: "50%",
                                                        color: "black",
                                                    }}
                                                ></RemoveIcon>
                                            </Button>
                                        </Grid>
                                        <Grid item lg={4} align="center" xs={4}>
                                            <input
                                                value={data.quantity}
                                                class="text-center w-50 border-0  m-1 "
                                            />{" "}
                                        </Grid>
                                        <Grid item align="right" lg={4} xs={4}>
                                            <Button
                                                className="h-100 remove-icon-container"
                                                onClick={(e) => {
                                                    quantityHandler(
                                                        data.quantity + 1,
                                                        index,
                                                        type
                                                    );
                                                }}
                                            >
                                                <AddIcon
                                                    style={{
                                                        color: "black",
                                                    }}
                                                ></AddIcon>
                                            </Button>
                                        </Grid>
                                    </Grid>
                                ) : null
                                // <Grid item xs={7} />
                            }

                            {/* item property, item quantity */}
                        </Grid>
                        {/* addon title, addon description, item property, item quantity */}
                    </Grid>
                </Grid>
            </Card>
        </Grid>
    );
};
export default AddonCard;
