import { Card, Grid } from "@material-ui/core";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import { IconButton } from "@mui/material";
import { useEffect, useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import { Button, Image } from "react-bootstrap";
import Swal from "sweetalert2";
import { store } from "../../../..";
import { urlAction } from "../../../../action";
import FpxLogo from "../../../../Images/fpx-logo.png";
import cardIcon from "../../../../Images/visa-master-logo.png";
import customerService from "../../../../services/customerService";
import merchantService from "../../../../services/merchantService";
import subscriptionService from "../../../../services/subscriptionService";
import "./style.css";
const PaymentDetails = ({ mandateRef, custId }) => {
    const merchantName = urlAction("get_merchant");
    const [paymentMethod, setPaymentMethod] = useState(1);
    const [selectedBank, setSelectedBank] = useState();
    const [bankList, setBankList] = useState([]);
    const [paymentMethodExist, setPaymentMethodExist] = useState(false);
    const [curlecMerchantId, setCurlecMerchantId] = useState("");
    const [curlecEmployeeId, setCurlecEmployeeId] = useState("");

    useEffect(() => {
        merchantService.getMerchantInfo().then((e) => {
            setCurlecMerchantId(e[0].merch_curlec_merchant_id);
            setCurlecEmployeeId(parseInt(e[0].merch_curlec_employee_id));
        });
        // get bank list here
        subscriptionService
            .getBanksForSubscription({ method: "00" })
            .then((res) => {
                var result = res.replace(/'/g, '"');
                var banks = JSON.parse(result);
                setBankList(banks);
            })
            .catch((err) => {
                console.error(err);
                Swal.fire({
                    title: "Error",
                    text: "Could not retrieve FPX bank list",
                    icon: "error",
                    confirmButtonText: "OK",
                    confirmButtonColor: store.getState().colorState.color,
                });
            });
        if (mandateRef !== null) {
            var detailsBody = {
                cust_mandate_ref: mandateRef,
            };
            customerService
                .getPaymentDetails(detailsBody, mandateRef)
                .then((res) => {
                    var bankId = res;
                    setSelectedBank(bankId);
                    setPaymentMethodExist(true);
                })
                .catch((err) => {
                    console.error(err, "this is the error");
                    Swal.fire({
                        title: "Error",
                        text: "Could not get FPX bank",
                        icon: "error",
                        confirmButtonText: "OK",
                        confirmButtonColor: store.getState().colorState.color,
                    });
                });
        }
    }, []);

    return (
        <>
            {/* create payment details card */}
            <Grid item xs={3} lg={6}>
                <Card>
                    <Grid
                        item
                        className="ml-3 mt-3 p-1"
                        align="left"
                        style={{ marginLeft: "10px" }}
                        xs={12}
                        md={12}
                        lg={12}
                    >
                        <strong
                            style={{
                                fontSize:
                                    window.innerWidth < 1000 ? "25px" : "33px",
                            }}
                        >
                            Payment Details
                        </strong>
                        <Button
                            startIcon={
                                <EditIcon
                                    className="icon-mobile"
                                    style={{
                                        fontSize: "15px",
                                    }}
                                ></EditIcon>
                            }
                            style={{
                                borderRadius: "5px",
                                backgroundColor: "grey",
                                color: "white",
                                padding: "0.4rem 1.64rem",
                                marginBottom: "15px",
                                marginLeft: "20px",
                                display: !paymentMethodExist ? "none" : "",
                            }}
                            onClick={() => {
                                setPaymentMethodExist(false);
                            }}
                        >
                            Edit
                        </Button>
                    </Grid>
                    <Grid
                        item
                        style={{ marginLeft: "15px" }}
                        className="mt-1"
                        align="left"
                        xs={12}
                        lg={12}
                    >
                        <p
                            style={{
                                fontSize:
                                    window.innerWidth < 1000 ? "15px" : "20px",
                            }}
                        >
                            Payment Method
                        </p>
                    </Grid>
                    <Grid
                        container
                        // className="ml-3"
                        // style={{ marginLeft: "5px" }}
                    >
                        <Grid item align="center" xs={6} md={6} lg={6}>
                            <Button
                                value="1"
                                style={{
                                    height: "80%",
                                    width: "90%",
                                    borderRadius: "5px",
                                    border: "1px solid",
                                }}
                                disabled={paymentMethodExist}
                                className={`${
                                    paymentMethod == 1
                                        ? "payment-clicked"
                                        : "payment-logo"
                                } ${
                                    paymentMethodExist == true &&
                                    paymentMethod == 1
                                        ? "payment-exist"
                                        : ""
                                }`}
                                onClick={(e) => {
                                    setPaymentMethod(1);
                                }}
                            >
                                <Image src={FpxLogo} className="paymentImg" />
                            </Button>
                        </Grid>
                        <Grid item align="center" xs={6} md={6} lg={6}>
                            <Button
                                value="2"
                                style={{
                                    height: "80%",
                                    width: "90%",
                                    marginRight: "70px",
                                    borderRadius: "5px",
                                    border: "1px solid black",
                                }}
                                disabled={paymentMethodExist}
                                className={`${
                                    paymentMethod == 2
                                        ? "payment-clicked"
                                        : "payment-logo"
                                }  ${
                                    paymentMethodExist == true &&
                                    paymentMethod == 2
                                        ? "payment-exist"
                                        : ""
                                } mr-5`}
                                onClick={(e) => {
                                    setPaymentMethod(2);
                                }}
                            >
                                <Image src={cardIcon} className="paymentImg" />
                            </Button>
                        </Grid>
                    </Grid>{" "}
                    {paymentMethod == 1 ? (
                        <>
                            <Grid
                                item
                                className="mt-3 "
                                align="left"
                                style={{ marginLeft: "15px" }}
                                xs={12}
                                lg={12}
                            >
                                <p
                                    style={{
                                        fontSize:
                                            window.innerWidth < 1000
                                                ? "15px"
                                                : "20px",
                                    }}
                                >
                                    Bank
                                </p>
                            </Grid>
                            <Grid
                                className="ml-3 mb-5"
                                style={{ marginLeft: "15px" }}
                                align="left"
                                item
                                xs={12}
                            >
                                <select
                                    disabled={paymentMethodExist}
                                    class="mb-3"
                                    onChange={(e) => {
                                        setSelectedBank(e.target.value);
                                    }}
                                    value={selectedBank}
                                    className="edit-selected-bank"
                                >
                                    <option value={undefined}>
                                        Select a bank
                                    </option>
                                    {bankList !== undefined
                                        ? bankList.map((bank, index) => {
                                              return (
                                                  <option value={bank.id}>
                                                      {bank.display_name}
                                                  </option>
                                              );
                                          })
                                        : ""}
                                </select>
                            </Grid>
                        </>
                    ) : (
                        ""
                    )}
                </Card>
                <Grid className="mt-3" container>
                    {/* create cancel and submit button using bootstrap */}
                    <Grid item align="left" xs={6} md={6} lg={6}>
                        <Button
                            className="cancel-button"
                            variant="contained"
                            color="primary"
                            onClick={() => {
                                setPaymentMethodExist(false);
                            }}
                        >
                            Cancel
                        </Button>
                    </Grid>
                    <Grid item align="right" xs={6} md={6} lg={6}>
                        <Button
                            className="proceed-button"
                            style={{
                                color: store.getState().colorState.font_color,
                                backgroundColor:
                                    store.getState().colorState.color,
                            }}
                            variant="contained"
                            color="primary"
                            onClick={() => {
                                // if cust mandate ref exist,
                                // append letter to the back of mandate ref
                                // else, proceed to mandate authorisation
                                if (selectedBank === undefined) {
                                    Swal.fire({
                                        title: "Please select a bank",
                                        icon: "error",
                                        confirmButtonText: "Ok",
                                    });

                                    // alert("Please select a bank");
                                } else {
                                    var amount = 1000.0;
                                    if (paymentMethod == 2) {
                                        amount = 200.0;
                                    }
                                    if (mandateRef !== null) {
                                        var refNum = mandateRef;
                                        if (refNum.length == 15) {
                                            var lastLetter = refNum[14];
                                            lastLetter = String.fromCharCode(
                                                lastLetter.charCodeAt() + 1
                                            );
                                            refNum = refNum.substring(0, 13);
                                            refNum = `${refNum}-${lastLetter}`;
                                        } else {
                                            refNum = `${refNum}-A`;
                                        }
                                        window.open(
                                            `${
                                                process.env
                                                    .REACT_APP_CURLEC_ENDPOINT
                                            }/new-mandate?effectiveDate=&method=03&amount=${amount}&frequency=MONTHLY&maximumFrequency=99&merchantId=${curlecMerchantId}&employeeId=${curlecEmployeeId}&emailAddress=${
                                                store.getState()
                                                    .loggedInUserReducer
                                                    .userData.ar_system
                                                    .cust_email
                                            }&name=${
                                                store.getState()
                                                    .loggedInUserReducer
                                                    .userData.ar_system
                                                    .cust_fname
                                            }%20${
                                                store.getState()
                                                    .loggedInUserReducer
                                                    .userData.ar_system
                                                    .cust_lname
                                            }&referenceNumber=${refNum}&purposeOfPayment=edit%20payment%20details&paymentMethod=${paymentMethod}&bankId=${selectedBank}&idType=OTHERS&idValue=12345&linkId=${paymentMethod}`,
                                            "_self"
                                        );
                                    } else {
                                        window.open(
                                            `${
                                                process.env
                                                    .REACT_APP_CURLEC_ENDPOINT
                                            }/new-mandate?effectiveDate=&method=03&amount=${amount}&frequency=MONTHLY&maximumFrequency=99&merchantId=${curlecMerchantId}&employeeId=${curlecEmployeeId}&emailAddress=${
                                                store.getState()
                                                    .loggedInUserReducer
                                                    .userData.ar_system
                                                    .cust_email
                                            }&name=${
                                                store.getState()
                                                    .loggedInUserReducer
                                                    .userData.ar_system
                                                    .cust_fname
                                            }%20${
                                                store.getState()
                                                    .loggedInUserReducer
                                                    .userData.ar_system
                                                    .cust_lname
                                            }&referenceNumber=${
                                                store.getState()
                                                    .loggedInUserReducer
                                                    .userData.ar_system.cust_id
                                            }&purposeOfPayment=edit%20payment%20details&paymentMethod=${paymentMethod}&bankId=${selectedBank}&idType=OTHERS&idValue=12345&linkId=${paymentMethod}`,
                                            "_self"
                                        );
                                    }
                                }
                            }}
                        >
                            Proceed
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

export default PaymentDetails;
