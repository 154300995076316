import React, { useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import CatalogCard from "../CatalogCard/catalogCard";
import "./style.css";
const EditPlanLayout = ({ planData, items, setItems }) => {
    const getPlanCard = (planData) =>
        planData.map((plan, index) => ({
            id: `item-${index}`,
            content: <CatalogCard planData={plan} />,
        }));
    useEffect(() => {
        setItems(getPlanCard(planData));
    }, [planData]);

    const getItemStyle = (isDragging, draggableStyle) => ({
        // some basic styles to make the items look a bit nicer
        userSelect: "none",
        padding: grid * 2,
        margin: `0 ${grid}px 0 0`,
        borderColor: "pink",
        // change background colour if dragging
        background: isDragging ? "lightgreen" : "green",

        // styles we need to apply on draggables
        ...draggableStyle,
    });
    const getListStyle = (isDraggingOver) => ({
        background: isDraggingOver ? "lightblue" : "lightgrey",
        display: "grid",
        "grid-template-columns": window.location.href.includes("wildkombucha")
            ? "auto auto auto "
            : "auto auto auto auto",
        padding: grid,
        overflow: "auto",
    });
    const grid = 8;
    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };
    return (
        <DragDropContext
            onDragEnd={(result) => {
                // dropped outside the list

                if (!result.destination) {
                    return;
                }

                const newItems = reorder(
                    items,
                    result.source.index,
                    result.destination.index
                );

                setItems(newItems);
            }}
        >
            <Droppable droppableId="droppable" direction="horizontal">
                {(provided, snapshot) => (
                    <div
                        ref={provided.innerRef}
                        style={getListStyle(snapshot.isDraggingOver)}
                        {...provided.droppableProps}
                    >
                        {items.map((item, index) => (
                            <Draggable
                                key={item.id}
                                draggableId={item.id}
                                index={index}
                            >
                                {(provided, snapshot) => (
                                    <div
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        style={getItemStyle(
                                            snapshot.isDragging,
                                            provided.draggableProps.style
                                        )}
                                    >
                                        {item.content}
                                    </div>
                                )}
                            </Draggable>
                        ))}
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
        </DragDropContext>
    );
};

export default EditPlanLayout;
