import {
    DialogActions,
    DialogContent,
    Grid,
    Slider,
    Typography,
    Button,
} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import CropRotateIcon from "@mui/icons-material/CropRotate";
import CloseIcon from "@material-ui/icons/Close";
import { IconButton } from "@mui/material";
import { useCallback, useState } from "react";
import Cropper from "react-easy-crop";
import getCroppedImgInFileObj, {
    readImageFileIntoDataUrl,
} from "../../utils/imageUtils";
import Compressor from "compressorjs";
import imageCompression from "browser-image-compression";
import { useEffect } from "react";
const ImageCropper = ({
    imgCrop,
    handleImgCrop,
    imgPreview,
    handleImgPreview,
}) => {
    useEffect(() => {
        console.log(imgCrop, "this is imgCrop");
    });
    const DialogTitle = (props) => {
        const { children, classes, onClose, ...other } = props;
        return (
            <MuiDialogTitle
                disableTypography
                className="m-0"
                style={{ padding: "16px 24px" }}
                {...other}
            >
                <Typography variant="h6">{children}</Typography>
                {onClose ? (
                    <IconButton
                        aria-label="close"
                        style={{
                            position: "absolute",
                            top: "8px",
                            right: "8px",
                        }}
                        onClick={onClose}
                    >
                        <CloseIcon />
                    </IconButton>
                ) : null}
            </MuiDialogTitle>
        );
    };
    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
        handleImgCrop({
            ...imgCrop,
            croppedAreaPixels: croppedAreaPixels,
        });
    });
    return (
        <Dialog
            fullWidth
            maxWidth="lg"
            open={imgCrop.isOpened}
            onClose={() => {
                handleImgCrop({
                    aspect: imgCrop.aspect,
                    isOpened: false,
                    selectedImageDataUrl: null,
                    crop: { x: 0, y: 0 },
                    rotation: 0,
                    zoom: 1,
                    croppedAreaPixels: null,
                    croppedImage: null,
                });
            }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                Please Crop Your Image Before Upload.
            </DialogTitle>
            <DialogContent>
                <div className="image-crop-container">
                    <Cropper
                        image={imgCrop.selectedImageDataUrl}
                        crop={imgCrop.crop}
                        rotation={imgCrop.rotation}
                        restrictPosition={false}
                        zoom={imgCrop.zoom}
                        aspect={imgCrop.aspect}
                        onCropChange={(newCrop) => {
                            handleImgCrop({
                                ...imgCrop,
                                crop: newCrop,
                            });
                        }}
                        onRotationChange={(newRotation) => {
                            handleImgCrop({
                                ...imgCrop,
                                rotation: newRotation,
                            });
                        }}
                        onCropComplete={onCropComplete}
                        onZoomChange={(newZoom) => {
                            handleImgCrop({
                                ...imgCrop,
                                zoom: newZoom,
                            });
                        }}
                    />
                </div>
                <Grid container>
                    <Grid item xs={12} sm={4} className="slider-container">
                        <Typography
                            xs={4}
                            variant="overline"
                            className="slider-label"
                        >
                            Zoom
                        </Typography>
                        <Slider
                            xs={8}
                            className="slider"
                            value={imgCrop.zoom}
                            min={0.4}
                            max={3}
                            step={0.1}
                            aria-labelledby="Zoom"
                            onChange={(e, newZoom) => {
                                handleImgCrop({
                                    ...imgCrop,
                                    zoom: newZoom,
                                });
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4} className="slider-container">
                        <Typography
                            xs={4}
                            variant="overline"
                            className="slider-label"
                        >
                            Rotation
                        </Typography>
                        <Slider
                            xs={8}
                            className="slider"
                            value={imgCrop.rotation}
                            min={0}
                            max={360}
                            step={1}
                            aria-labelledby="Rotation"
                            onChange={(e, newRotation) => {
                                handleImgCrop({
                                    ...imgCrop,
                                    rotation: newRotation,
                                });
                            }}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions style={{ marginBottom: "15px" }}>
                {/* display button only if window url has the word settings */}
                {console.log(window.location.href)}
                {window.location.href.indexOf("profile") > -1 ? (
                    <Button
                        startIcon={<CropRotateIcon />}
                        // style={{
                        //     backgroundColor: "black",
                        // }}
                        onClick={() => {
                            var currentAspect = imgCrop.aspect;
                            if (currentAspect === 2) {
                                handleImgCrop({
                                    ...imgCrop,
                                    aspect: 1 / 2,
                                });
                            } else {
                                handleImgCrop({
                                    ...imgCrop,
                                    aspect: 3,
                                });
                            }
                        }}
                        variant="contained"
                        color="primary"
                        component="label"
                    >
                        Rotate
                    </Button>
                ) : (
                    ""
                )}

                <Button
                    onClick={async () => {
                        try {
                            const finalImageFile = await getCroppedImgInFileObj(
                                imgCrop.selectedImageDataUrl,
                                imgCrop.croppedAreaPixels,
                                imgCrop.rotation
                            );
                            var options = {
                                maxSizeMB: 0.1,
                            };

                            const compressedFile = await imageCompression(
                                finalImageFile,
                                options
                            )
                                .then(function (compressedFile) {
                                    return compressedFile;
                                })
                                .catch(function (error) {
                                    console.log(error.message);
                                });
                            // generate random name with .png at the end
                            const fileName =
                                "cropped_" +
                                Math.random().toString(36).substring(2, 8) +
                                ".png";
                            var file = new File([compressedFile], fileName);
                            const finalImageInDataUrl =
                                await readImageFileIntoDataUrl(finalImageFile);
                            handleImgPreview({
                                imageFile: file,
                                imagePreview: finalImageInDataUrl,
                            });
                        } catch (e) {}
                        //close the cropping model and reset all value
                        handleImgCrop({
                            aspect: imgCrop.aspect,
                            isOpened: false,
                            selectedImageDataUrl: null,
                            crop: { x: 0, y: 0 },
                            rotation: 0,
                            zoom: 1,
                            croppedAreaPixels: null,
                            croppedImage: null,
                        });
                    }}
                    variant="contained"
                    style={{ backgroundColor: "black", color: "white" }}
                    // color="primary"
                    // component="label"
                >
                    Crop and Use
                </Button>
                <Button
                    onClick={() => {
                        handleImgCrop({
                            aspect: imgCrop.aspect,
                            isOpened: false,
                            selectedImageDataUrl: null,
                            crop: { x: 0, y: 0 },
                            rotation: 0,
                            zoom: 1,
                            croppedAreaPixels: null,
                            croppedImage: null,
                        });
                    }}
                    variant="contained"
                    style={{ marginRight: "20px" }}
                    // color="primary"
                    // component="label"
                    // className="color-primary"
                >
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ImageCropper;
