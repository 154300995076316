import {
    Grid,
    Select,
    Input,
    MenuItem,
    Checkbox,
    ListItemText,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import InfoIcon from "@material-ui/icons/Info";
import { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import LoginBar from "../../../../Components/LoginBar/loginbar";
import planService from "../../../../services/planService";
import promotionService from "../../../../services/promotionService";
import "./style.css";
import { store } from "../../../../index.js";
import Swal from "sweetalert2";
const PromotionEditCreate = ({ history, match, readonly = false }) => {
    const [promoCode, setPromoCode] = useState("");
    const [promoConstraint, setPromoConstraint] = useState("all");
    const [startDate, setStartDate] = useState();
    const [percentageDiscountCap, setPercentageDiscountCap] = useState(0);
    const [endDate, setEndDate] = useState();
    const [allPlans, setAllPlans] = useState([]);
    const [discountType, setDiscountType] = useState("");
    const [discountAppliedOn, setDiscountAppliedOn] = useState("total_price");
    const [discountAmount, setDiscountAmount] = useState(0);
    const [discountDurationType, setDiscountDurationType] = useState();
    const [limitedCycles, setLimitedCycles] = useState();
    const [specificMenuConstraint, setSpecificMenuConstraint] = useState({
        value: [],
    });
    const getAllPlans = () => {
        // get all plans
        planService
            .getAllPlansWithoutPagination()
            .then((res) => {
                setAllPlans(res.plans);
            })
            .catch((err) => {
                console.error(err);
            });
    };

    useEffect(() => {
        getAllPlans();
        if (match.params.id !== "new") {
            getPromotionById(match.params.id);
        }
    }, []);

    const getPromotionById = (id) => {
        promotionService
            .getSinglePromotion(id)
            .then((res) => {
                setPromoCode(res.promotion.promo_code);
                setPromoConstraint(res.promotion.promo_constraint);
                setSpecificMenuConstraint({
                    value: res.promotion.promo_specific_plans,
                });
                setStartDate(
                    new Date(res.promotion.start_date)
                        .toISOString()
                        .substring(0, 10)
                );
                // setEndDate(
                //     new Date(res.end_date).toISOString().substring(0, 10)
                // );

                setDiscountType(res.promotion.discount_type);
                setDiscountAmount(res.promotion.discount_amount);
                setPercentageDiscountCap(res.promotion.percentage_amount_cap);
                setDiscountAppliedOn(res.promotion.discount_applies_on);
                setDiscountDurationType(res.promotion.discount_duration_type);
                setLimitedCycles(res.promotion.limited_period_cycles);
            })
            .catch((err) => {
                console.error(err);
            });
    };

    const createPromotion = () => {
        let data = {
            promo_code: promoCode,
            promo_constraint: promoConstraint,
            start_date: new Date(startDate),
            end_date: new Date(endDate),
            discount_applies_on: discountAppliedOn,
            discount_type: discountType,
            discount_amount: discountAmount,
            discount_duration_type: discountDurationType,
        };

        if (promoConstraint === "specific") {
            data = {
                ...data,
                promo_specific_plans: specificMenuConstraint.value,
            };
        }
        if (discountType === "percentage") {
            data = {
                ...data,
                percentage_amount_cap: percentageDiscountCap,
            };
        }
        if (discountDurationType === "limited") {
            data = {
                ...data,
                limited_period_cycles: limitedCycles,
            };
        }
        if (match.params.id === "new") {
            promotionService
                .createPromo(data)
                .then((res) => {
                    Swal.fire({
                        title: "Success",
                        text: "",
                        icon: "success",
                        confirmButtonText: "OK",
                        confirmButtonColor: store.getState().colorState.color,
                    });
                    history.push("/admin/promotion");
                })
                .catch((err) => {
                    console.error(err, "this error");
                });
        } else {
            promotionService
                .updatePromotion(match.params.id, data)
                .then((res) => {
                    Swal.fire({
                        title: "Success",
                        text: "",
                        icon: "success",
                        confirmButtonText: "OK",
                        confirmButtonColor: store.getState().colorState.color,
                    });
                    history.push("/admin/promotion");
                })
                .catch((err) => {
                    console.error(err, "this error");
                });
        }
    };

    const renderFixedAmountInput = () => {
        return (
            <Form.Group>
                <Form.Label>Discount Amount (RM)</Form.Label>
                <Form.Control
                    className="w-50"
                    type="number"
                    value={discountAmount}
                    onChange={(e) => setDiscountAmount(e.target.value)}
                />
            </Form.Group>
        );
    };
    const renderPercentageInput = () => {
        return (
            <Grid container>
                <Grid item xs={3}>
                    <Form.Group>
                        <Form.Label>Discount Amount (%)</Form.Label>
                        <Form.Control
                            type="number"
                            value={discountAmount}
                            style={{ width: "95%" }}
                            onChange={(e) => setDiscountAmount(e.target.value)}
                        />
                    </Form.Group>
                </Grid>
                <Grid item xs={3}>
                    <Form.Group>
                        <Form.Label>Discount Cap (RM)</Form.Label>
                        <Form.Control
                            type="number"
                            value={percentageDiscountCap}
                            onChange={(e) =>
                                setPercentageDiscountCap(e.target.value)
                            }
                        />
                    </Form.Group>
                </Grid>
            </Grid>
        );
    };

    return (
        <Grid container>
            <Grid item className="p-3" xs={12} md={12} lg={12}>
                <Grid container>
                    <Grid item xs={12} md={12} lg={12}>
                        <Grid container className="admin-listing mb-2">
                            {/* profile icon and username dropdown */}
                            <Grid align="left" item xs={6} md={6} lg={6}>
                                <h4
                                    className="text-left color-contrast-dark ml-4"
                                    style={{
                                        color: "black",
                                        marginLeft: "10px",
                                    }}
                                >
                                    {match.params.id !== "new" ? (
                                        <strong>Edit Promotion</strong>
                                    ) : (
                                        <strong>Create Promotion</strong>
                                    )}
                                </h4>
                            </Grid>
                            <Grid item xs={6} md={6} lg={6} align="right">
                                {" "}
                                <LoginBar />
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={12} md={12} lg={12}>
                        <div className="pt-2 container-fluid addon-container">
                            <div className=" addon-form-container">
                                <Grid
                                    align="left"
                                    className="ml-4"
                                    style={{ marginLeft: "20px" }}
                                    container
                                >
                                    <Grid
                                        item
                                        className="mt-4"
                                        xs={12}
                                        lg={12}
                                        md={12}
                                    >
                                        <h4
                                            className="color-contrast-dark "
                                            style={{ color: "black" }}
                                        >
                                            <strong>Promotion Details</strong>
                                        </h4>
                                    </Grid>
                                    <Grid
                                        item
                                        className="mt-3"
                                        xs={12}
                                        md={12}
                                        lg={12}
                                    >
                                        <Form.Group className="mb-3 w-50">
                                            <Form.Label>
                                                Promotion Code
                                            </Form.Label>
                                            <Form.Control
                                                value={promoCode}
                                                onChange={(e) => {
                                                    setPromoCode(
                                                        e.target.value
                                                    );
                                                }}
                                            />
                                        </Form.Group>
                                    </Grid>
                                    <Grid
                                        item
                                        className="mt-1"
                                        xs={12}
                                        md={12}
                                        lg={12}
                                    >
                                        <Form.Group className="mb-3 w-50">
                                            <Form.Label>
                                                Promotion Constraint
                                            </Form.Label>
                                            <Form.Control
                                                value={promoConstraint}
                                                onChange={(e) => {
                                                    setPromoConstraint(
                                                        e.target.value
                                                    );
                                                }}
                                                as="select"
                                            >
                                                {" "}
                                                <option value="all">All</option>
                                                <option value="specific">
                                                    Specific
                                                </option>
                                            </Form.Control>
                                        </Form.Group>
                                    </Grid>
                                    <Grid
                                        item
                                        className="mt-1"
                                        xs={12}
                                        md={12}
                                        lg={12}
                                    >
                                        <Form.Group className="mb-3 w-50">
                                            {promoConstraint === "specific" ? (
                                                <>
                                                    <Form.Label>
                                                        Plans Applicable
                                                    </Form.Label>

                                                    <Form.Group>
                                                        <Select
                                                            multiple
                                                            value={
                                                                specificMenuConstraint.value
                                                            }
                                                            onChange={(
                                                                event
                                                            ) => {
                                                                var selected =
                                                                    event.target
                                                                        .value;
                                                                setSpecificMenuConstraint(
                                                                    {
                                                                        ...specificMenuConstraint,
                                                                        value: selected,
                                                                    }
                                                                );
                                                            }}
                                                            input={<Input />}
                                                            renderValue={(
                                                                selectedPlanIds
                                                            ) => {
                                                                var displayText =
                                                                    "";
                                                                selectedPlanIds.forEach(
                                                                    (
                                                                        selectedPlanId
                                                                    ) => {
                                                                        displayText +=
                                                                            allPlans.find(
                                                                                (
                                                                                    plans
                                                                                ) =>
                                                                                    plans?.plan_id ==
                                                                                    selectedPlanId
                                                                            )
                                                                                ?.plan_name +
                                                                            ", ";
                                                                    }
                                                                );

                                                                return displayText;
                                                            }}
                                                            style={{
                                                                width: "100%",
                                                            }}
                                                            MenuProps={{
                                                                anchorOrigin: {
                                                                    vertical:
                                                                        "top",
                                                                    horizontal:
                                                                        "right",
                                                                },
                                                                transformOrigin:
                                                                    {
                                                                        vertical:
                                                                            "top",
                                                                        horizontal:
                                                                            "right",
                                                                    },
                                                                keepMounted: true,
                                                                getContentAnchorEl:
                                                                    null,

                                                                PaperProps: {
                                                                    style: {
                                                                        maxHeight: 200,
                                                                        minWidth:
                                                                            "200px",
                                                                    },
                                                                },
                                                            }}
                                                        >
                                                            {allPlans &&
                                                                allPlans.map(
                                                                    (item) => {
                                                                        return (
                                                                            <MenuItem
                                                                                color="primary"
                                                                                key={
                                                                                    item.plan_id
                                                                                }
                                                                                value={
                                                                                    item.plan_id
                                                                                }
                                                                            >
                                                                                <Checkbox
                                                                                    color="primary"
                                                                                    checked={
                                                                                        specificMenuConstraint.value.indexOf(
                                                                                            item.plan_id
                                                                                        ) >
                                                                                        -1
                                                                                    }
                                                                                />
                                                                                <ListItemText
                                                                                    primary={
                                                                                        item.plan_name
                                                                                    }
                                                                                />
                                                                            </MenuItem>
                                                                        );
                                                                    }
                                                                )}
                                                        </Select>
                                                    </Form.Group>
                                                </>
                                            ) : null}
                                        </Form.Group>
                                    </Grid>
                                    <Grid item xs={12} md={12} lg={12}>
                                        <Form.Group
                                            style={{
                                                padding: "15px 10px",
                                                // margin: "12px",
                                                background: "black",
                                            }}
                                            className="text-white d-flex rounded shadow align-items-center w-75"
                                        >
                                            <InfoIcon></InfoIcon>
                                            <h6 className="pb-0 mb-0">
                                                &nbsp;&nbsp;This promo code is
                                                applicable to all plans in this
                                                system.
                                            </h6>
                                        </Form.Group>
                                    </Grid>
                                    <Grid
                                        item
                                        className="mt-3"
                                        xs={3}
                                        md={3}
                                        lg={3}
                                    >
                                        <Form.Group className="mb-3">
                                            <Form.Label>Start Date</Form.Label>
                                            <Form.Control
                                                style={{ width: "95%" }}
                                                type="date"
                                                onChange={(e) => {
                                                    setStartDate(
                                                        e.target.value
                                                    );
                                                }}
                                                value={startDate}
                                            />
                                        </Form.Group>
                                    </Grid>
                                    <Grid
                                        item
                                        className="mt-3"
                                        xs={3}
                                        md={3}
                                        lg={3}
                                    >
                                        <Form.Group className="mb-3">
                                            <Form.Label>End Date</Form.Label>
                                            <Form.Control
                                                type="date"
                                                onChange={(e) => {
                                                    setEndDate(e.target.value);
                                                }}
                                                value={endDate}
                                            />
                                        </Form.Group>
                                    </Grid>
                                    <Grid
                                        item
                                        className="mt-3"
                                        xs={12}
                                        lg={12}
                                        md={12}
                                    >
                                        <h4
                                            className="color-contrast-dark "
                                            style={{ color: "black" }}
                                        >
                                            <strong>Discount Setup</strong>
                                        </h4>
                                    </Grid>
                                    <Grid
                                        item
                                        className="mt-1"
                                        xs={12}
                                        md={12}
                                        lg={12}
                                    >
                                        <Form.Group className="mb-3 w-50">
                                            <Form.Label>
                                                Discount Applies On
                                            </Form.Label>
                                            <Form.Control
                                                onChange={(e) => {
                                                    setDiscountAppliedOn(
                                                        e.target.value
                                                    );
                                                }}
                                                value={discountAppliedOn}
                                                as="select"
                                            >
                                                <option value="total_price">
                                                    Total price (Plan price +
                                                    Delivery fee)
                                                </option>
                                                <option value="plan_price">
                                                    Plan Price
                                                </option>
                                                <option value="delivery_fee">
                                                    Delivery Fee
                                                </option>
                                            </Form.Control>
                                        </Form.Group>
                                    </Grid>
                                    {discountAppliedOn === "total_price" ||
                                    discountAppliedOn === "plan_price" ? (
                                        <>
                                            <Grid item xs={12} md={12} lg={12}>
                                                <Form.Label>
                                                    Discount type
                                                </Form.Label>
                                            </Grid>
                                            <Grid container>
                                                <Form.Check
                                                    type="radio"
                                                    label="Fixed Amount"
                                                    checked={
                                                        discountType ==
                                                        "fixed_amount"
                                                            ? true
                                                            : false
                                                    }
                                                    value={"fixed_amount"}
                                                    onChange={(e) => {
                                                        setDiscountType(
                                                            e.target.value
                                                        );
                                                    }}
                                                />
                                                <Form.Check
                                                    className="ml-4"
                                                    type="radio"
                                                    label="Percentage"
                                                    checked={
                                                        discountType ===
                                                        "percentage"
                                                            ? true
                                                            : false
                                                    }
                                                    value={"percentage"}
                                                    onChange={(e) => {
                                                        setDiscountType(
                                                            e.target.value
                                                        );
                                                    }}
                                                />
                                            </Grid>
                                            <Grid
                                                item
                                                className="mt-3"
                                                xs={12}
                                                md={12}
                                                lg={12}
                                            >
                                                {discountType ===
                                                    "fixed_amount" &&
                                                    renderFixedAmountInput()}
                                                {discountType ===
                                                    "percentage" &&
                                                    renderPercentageInput()}
                                            </Grid>
                                            <Grid
                                                item
                                                className={
                                                    discountDurationType !==
                                                    "limited"
                                                        ? "mb-3 mt-3"
                                                        : "mt-3"
                                                }
                                                xs={12}
                                                md={12}
                                                lg={12}
                                            >
                                                <Form.Group>
                                                    <Form.Label>
                                                        Discount Duration Type
                                                    </Form.Label>
                                                    <Form.Control
                                                        className="w-50"
                                                        as="select"
                                                        onChange={(e) => {
                                                            setDiscountDurationType(
                                                                e.target.value
                                                            );
                                                        }}
                                                        value={
                                                            discountDurationType
                                                        }
                                                    >
                                                        <option value="forever">
                                                            Forever
                                                        </option>
                                                        <option value="limited">
                                                            Limited Period
                                                        </option>
                                                        <option value="one_off">
                                                            One Off
                                                        </option>
                                                    </Form.Control>
                                                </Form.Group>
                                            </Grid>
                                            {discountDurationType ===
                                                "limited" && (
                                                <Grid
                                                    item
                                                    className="mt-3 mb-4"
                                                    xs={12}
                                                    md={12}
                                                    lg={12}
                                                >
                                                    <Form.Group>
                                                        <Form.Label>
                                                            Number of payment
                                                            cycles
                                                        </Form.Label>
                                                        <Form.Control
                                                            className="w-50"
                                                            type="number"
                                                            onChange={(e) => {
                                                                setLimitedCycles(
                                                                    e.target
                                                                        .value
                                                                );
                                                            }}
                                                            value={
                                                                limitedCycles
                                                            }
                                                        />
                                                    </Form.Group>
                                                </Grid>
                                            )}
                                        </>
                                    ) : null}
                                </Grid>
                            </div>
                        </div>
                    </Grid>
                    <Grid container>
                        <Grid item align="right" xs={6} md={6} lg={6}>
                            <Button
                                className="mt-3 mr-2"
                                onClick={() => {
                                    history.push("/admin/promotion");
                                }}
                                variant="contained"
                            >
                                Cancel
                            </Button>
                        </Grid>
                        <Grid item align="left" xs={6} md={6} lg={6}>
                            <Button
                                className="mt-3 ml-2"
                                variant="contained"
                                style={{
                                    background: "black",
                                    color: "white",
                                    marginLeft: "10px",
                                }}
                                onClick={() => {
                                    createPromotion();
                                }}
                            >
                                Save
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default PromotionEditCreate;
