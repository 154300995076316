import {
    FormControl,
    FormControlLabel,
    FormGroup,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Checkbox,
} from "@material-ui/core";
import moment from "moment";

import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation, Link } from "react-router-dom";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import {
    toggleLoginRegisterModalState,
    toggleLoginState,
} from "../../../action.js";
import { urlAction } from "../../../action";
import merchantService from "../../../services/merchantService";

import { store } from "../../../index.js";
import customerService from "../../../services/customerService/index.js";
import subscriptionService from "../../../services/subscriptionService";
import profileService from "../../../services/profileService";
import testSubscription from "../../../Containers/catalogDetail/catalogDetail.js";
import userService from "../../../services/userService/index.js";
import "./style.css";
import ErrorIcon from "@material-ui/icons/Error";
import FormHelperText from "@material-ui/core/FormHelperText";
import { validate } from "@material-ui/pickers";

const LoginRegisterModal = (props) => {
    const forgotPasswordLink =
        String(window.location.origin).split("/")[0] +
        "/backend/api/password_reset/";
    const dispatch = useDispatch();
    const history = useHistory();
    const isLoginRegisterModalOpened = useSelector(
        (state) =>
            state.toggleLoginRegisterModalReducer.isLoginRegisterModalOpened
    );

    const [modalState, setModalState] = useState("register");
    const [merchant, setMerchant] = useState();
    const [usernameLogin, setUsernameLogin] = useState({ value: "" });
    const [passwordLogin, setPasswordLogin] = useState({ value: "" });
    const [usernameRegister, setUsernameRegister] = useState({ value: "" });
    const [passwordRegister, setPasswordRegister] = useState({ value: "" });
    const [retypePassword, setRetypePassword] = useState({ value: "" });
    const [custFirstName, setCustFirstName] = useState({ value: "" });
    const [custLastName, setCustLastName] = useState({ value: "" });
    const [custContactNo, setCustContactNo] = useState({ value: "" });
    const [custAltContactNo, setCustAltContactNo] = useState({ value: "" });
    const [custEmail, setCustEmail] = useState({ value: "" });
    const [custAddress, setCustAddress] = useState({ value: "" });
    const [custPostcode, setCustPostcode] = useState({ value: "" });
    const [custDistrict, setCustDistrict] = useState({ value: "" });
    const [custBankAccNo, setCustBankAccNo] = useState({ value: "" });
    const [custSp, setCustSp] = useState({ value: "" });
    const [custRace, setCustRace] = useState({ value: "" });
    const [custFestival, setCustFestival] = useState({ value: "" });
    const [custDiscoverPichaEats, setCustDiscoverPichaEats] = useState({
        value: "",
    });
    const [merchantInfo, setMerchantInfo] = useState();

    const [custGender, setCustGender] = useState({ value: "" });
    const [custBirthday, setCustBirthday] = useState({ value: "" });
    const [custNationality, setCustNationality] = useState({ value: "" });
    const [custDietaryPreference, setCustDietaryPreference] = useState({
        value: "",
    });
    const [custDiscovery, setCustDiscovery] = useState({ value: "" });
    const [custReferrer, setCustReferrer] = useState({ value: "" });
    const [custDeliveryInstructions, setCustDeliveryInstruction] = useState({
        value: "",
    });
    const [custEmailRetype, setCustEmailRetype] = useState({ value: "" });
    const [custEthnicityOthers, setCustEthnicityOthers] = useState({
        value: "",
    });
    const toggleLoginRegisterModal = () => {
        dispatch(toggleLoginRegisterModalState());
    };
    const [custConsent, setCustConsent] = useState({ value: true });
    const [priceArea, setPriceArea] = useState({ value: [] });
    const [postcode, setPostcode] = useState({ value: [] });
    const [checkPostagePrice] = useState({ postcode: [], areaPrice: [] });
    const [restrictedPostcode, setRestrictedPostcode] = useState([]);
    const toggleLoggedIn = () => {
        dispatch(toggleLoginState());
        dispatch(toggleLoginRegisterModalState());
    };

    const redirectBackToHomePage = () => {
        history.push("/");
    };
    useEffect(() => {
        const splitURL = urlAction("get_merchant");
        merchantService.getMerchantInfo().then((e) => {
            let merchantInfo = e;

            let merchantId = merchantInfo
                .filter((obj) => obj.merch_name == splitURL)
                .map((obj) => {
                    return obj.merch_name;
                });
            setMerchant(merchantId);
        });
    }, []);

    const login = () => {
        if (validateLoginForm()) {
            userService
                .login(usernameLogin.value, passwordLogin.value)
                .then((userData) => {
                    toggleLoggedIn();

                    if (
                        store.getState().loggedInUserReducer.userData.ar_system
                            ?.cust_existing &&
                        store.getState().loggedInUserReducer.userData.ar_system
                            ?.cust_mandate_ref == null
                    ) {
                        history.push("/customer/profile");
                    } else {
                        window.location.reload();
                    }
                })
                .catch((res) => {
                    Swal.fire({
                        icon: "error",
                        confirmButtonColor: store.getState().colorState.color,
                        title: "Username or password is wrong.",
                        text: "Username or password is wrong. Please try again later.",
                    });
                });
        }
    };

    const validateLoginForm = () => {
        var isValid = true;

        isValid = isValid && validateUsernameLogin(usernameLogin.value);
        isValid = isValid && validatePasswordLogin(passwordLogin.value);

        return isValid;
    };
    const validateUsernameLogin = (newValue) => {
        var isValid = true;

        //Reset the Error message
        var errorMessage = "";

        //Check empty
        if (newValue == "") {
            if (!!errorMessage) errorMessage += "\n";
            errorMessage += "Username cannot be empty";
            isValid = false;
        }

        setUsernameLogin({ ...usernameLogin, error: errorMessage }); //Set Error message

        return isValid;
    };

    const validatePasswordLogin = (newValue) => {
        var isValid = true;

        //Reset the Error message
        var errorMessage = "";

        //Check empty
        if (newValue == "") {
            if (!!errorMessage) errorMessage += "\n";
            errorMessage += "Password cannot be empty";
            isValid = false;
        }

        setPasswordLogin({ ...passwordLogin, error: errorMessage }); //Set Error message

        return isValid;
    };

    //TODO: check in backend for duplication
    const validateUsernameRegister = (newValue) => {
        var isValid = true;
        var errorMessage = "";
        if (newValue == "") {
            if (!!errorMessage) errorMessage += "\n";
            errorMessage += "Username field cannot be empty";
        }

        setUsernameRegister({ ...usernameRegister, error: errorMessage }); //Set Error message

        return isValid;
    };

    const validatePasswordRegister = (newValue) => {
        var isValid = true;
        var errorMessage = "";
        if (newValue == "") {
            if (!!errorMessage) errorMessage += "\n";
            errorMessage += "Password field cannot be empty";
            isValid = false;
        }

        setPasswordRegister({ ...passwordRegister, error: errorMessage }); //Set Error message

        return isValid;
    };
    const validateRetypePassword = (newValue) => {
        var isValid = true;
        var errorMessage = "";
        if (newValue == "") {
            if (!!errorMessage) errorMessage += "\n";
            errorMessage += "Password field cannot be empty";
            isValid = false;
        }
        if (newValue != passwordRegister.value) {
            if (!!errorMessage) errorMessage += "\n";
            errorMessage += "Password must be the same";
            isValid = false;
        }

        setRetypePassword({ ...retypePassword, error: errorMessage }); //Set Error message

        return isValid;
    };
    const validateFirstName = (newValue) => {
        var isValid = true;
        var errorMessage = "";
        if (newValue == "") {
            if (!!errorMessage) errorMessage += "\n";
            errorMessage += "First name field cannot be empty";
            isValid = false;
        }

        setCustFirstName({ ...custFirstName, error: errorMessage }); //Set Error message

        return isValid;
    };
    const validateLastName = (newValue) => {
        var isValid = true;
        var errorMessage = "";

        if (newValue == "") {
            if (!!errorMessage) errorMessage += "\n";
            errorMessage += "Last name field cannot be empty";
            isValid = false;
        }

        setCustLastName({ ...custLastName, error: errorMessage }); //Set Error message

        return isValid;
    };
    const validateContactNo = (newValue) => {
        var isValid = true;
        const number = parseInt(newValue);
        var errorMessage = "";
        if (newValue == "") {
            if (!!errorMessage) errorMessage = "\n";
            errorMessage = "Contact number field cannot be empty";
            isValid = false;
        }

        var phoneNumberRegex = "^(601)[0-46-9]*[0-9]{8,9}$";
        if (
            (!newValue.match(phoneNumberRegex) && newValue.length < 11) ||
            newValue.length > 12
        ) {
            if (!!errorMessage) errorMessage = "\n";
            errorMessage =
                "Phone number must have at least 10 or 11 digits and starts off with '60' \n E.g: 60123456789";
            isValid = false;
        }

        setCustContactNo({ ...custContactNo, error: errorMessage }); //Set Error message

        return isValid;
    };

    const validateAltContactNo = (newValue) => {
        var isValid = true;
        const number = parseInt(newValue);
        var errorMessage = "";
        if (newValue == "") {
            if (!!errorMessage) errorMessage = "\n";
            errorMessage = "Contact number field cannot be empty";
            isValid = false;
        }
        // if (
        //     custAltContactNo.value == custContactNo.value &&
        //     custContactNo.value != ""
        // ) {
        //     if (!!errorMessage) errorMessage = "\n";
        //     errorMessage =
        //         "Contact number and alternate contact number cannot be the same.";
        //     isValid = false;
        // }

        //TODO: regex and 10 numbers
        var phoneNumberRegex = "^(601)[0-46-9]*[0-9]{8,9}$";
        if (
            (!newValue.match(phoneNumberRegex) && newValue.length < 11) ||
            newValue.length > 12
        ) {
            if (!!errorMessage) errorMessage = "\n";
            errorMessage =
                "Phone number must have at least 10 or 11 digits and starts off with '60' \n E.g: 60123456789";
            isValid = false;
        }
        // if (newValue.length < 11 || newValue.length > 12) {
        //     if (!!errorMessage) errorMessage = "\n";
        //     errorMessage =
        //         "Phone number must have at least 10 digits and not more than 11 digits.";
        //     isValid = false;
        // }

        setCustAltContactNo({ ...custAltContactNo, error: errorMessage }); //Set Error message

        return isValid;
    };
    const validateEmail = (newValue) => {
        var isValid = true;
        var errorMessage = "";
        var emailRegex = ".+@.+..+";
        if (newValue == "") {
            if (!!errorMessage) errorMessage += "\n";
            errorMessage += "Email field cannot be empty";
            isValid = false;
        }

        if (!newValue.match(emailRegex)) {
            if (!!errorMessage) errorMessage += "\n";
            errorMessage += "Email format is incorrect";
            isValid = false;
        }
        //TODO: email format
        setCustEmail({ ...custEmail, error: errorMessage }); //Set Error message

        return isValid;
    };
    const validateBirthday = (newValue) => {
        var isValid = true;
        var errorMessage = "";
        if (newValue == "") {
            if (!!errorMessage) errorMessage += "\n";
            errorMessage += "Birthday field cannot be empty";
            isValid = false;
        }
        if (newValue > moment().format("YYYY-MM-DD")) {
            if (!!errorMessage) errorMessage += "\n";
            errorMessage += "Birth date cannot exceed current date";
            isValid = false;
        }

        setCustBirthday({ ...custBirthday, error: errorMessage }); //Set Error message

        return isValid;
    };
    const validateGender = (newValue) => {
        var isValid = true;
        var errorMessage = "";
        if (newValue == "") {
            if (!!errorMessage) errorMessage += "\n";
            errorMessage += "Gender field cannot be empty";
            isValid = false;
        }

        setCustGender({ ...custGender, error: errorMessage }); //Set Error message

        return isValid;
    };
    const validateCustDiscoverPichaEats = (newValue) => {
        var isValid = true;
        var errorMessage = "";
        if (newValue == "") {
            if (!!errorMessage) errorMessage += "\n";
            errorMessage += "This field cannot be empty";
            isValid = false;
        }
        setCustDiscoverPichaEats({
            ...custDiscoverPichaEats,
            error: errorMessage,
        });
        return isValid;
    };
    const validateAddress = (newValue) => {
        var isValid = true;
        var errorMessage = "";
        if (newValue == "") {
            if (!!errorMessage) errorMessage += "\n";
            errorMessage += "Address field cannot be empty";
            isValid = false;
        }

        setCustAddress({ ...custAddress, error: errorMessage }); //Set Error message

        return isValid;
    };
    const validatePostcode = (newValue) => {
        var isValid = true;
        var errorMessage = "";
        if (newValue == "") {
            if (!!errorMessage) errorMessage += "\n";
            errorMessage += "Postcode field cannot be empty";
            isValid = false;
        }
        var postcodeRegex = "[0-9]{5}";
        if (!newValue.match(postcodeRegex)) {
            if (!!errorMessage) errorMessage += "\n";
            errorMessage += "Postcode can only have 5 digits";
            isValid = false;
        }
        profileService
            .getPostcode(newValue)
            .then((e) => {
                console.log("postcode exists");
                // set user data here
            })
            .catch((e) => {
                if (!!errorMessage) errorMessage += "\n";
                errorMessage += "Sorry, we do not deliver to this postcode";
                isValid = false;
            });

        setCustPostcode({ ...custPostcode, error: errorMessage }); //Set Error message

        return isValid;
    };

    const validateCustFestival = (newValue) => {
        var isValid = true;
        var errorMessage = "";
        if (newValue == "") {
            if (!!errorMessage) errorMessage += "\n";
            errorMessage += "Ethnicity field cannot be empty";
            isValid = false;
        }
        setCustFestival({ ...custFestival, error: errorMessage });
        return isValid;
    };

    const validateDistrict = (newValue) => {
        var isValid = true;
        var errorMessage = "";
        if (newValue == "") {
            if (!!errorMessage) errorMessage += "\n";
            errorMessage += "District field cannot be empty";
            isValid = false;
        }

        setCustDistrict({ ...custDistrict, error: errorMessage }); //Set Error message
        return isValid;
    };

    const redirectToHomePage = () => {
        window.location.reload();
        history.push("/");
    };

    const validateForm = () => {
        var isValid = true;
        isValid = validateAddress(custAddress.value) && isValid;
        isValid = validatePostcode(custPostcode.value) && isValid;
        isValid = validateEmail(custEmail.value) && isValid;
        isValid = validatePasswordRegister(passwordRegister.value) && isValid;
        isValid = validateFirstName(custFirstName.value) && isValid;
        isValid = validateLastName(custLastName.value) && isValid;
        isValid = validateContactNo(custContactNo.value) && isValid;
        if (custAltContactNo.value != "") {
            isValid = validateAltContactNo(custAltContactNo.value) && isValid;
        }
        return isValid;
    };

    // after 401 error work
    const register_and_login = async () => {
        // if (!store.getState().loggedInUserReducer.userData.ar_system.cust_existing && store.getState().loggedInUserReducer.userData.ar_system.cust_mandate_ref == null){
        if (validateForm()) {
            var dataBody = {
                username: custEmail.value,
                password: passwordRegister.value,
                cust_fname: custFirstName.value,
                cust_lname: custLastName.value,
                cust_contact_no: custContactNo.value,
                cust_alternative_contact: custAltContactNo.value,
                cust_email: custEmail.value,
                cust_birthday: new moment(custBirthday.value).toISOString(),
                cust_festival: custFestival.value,
                cust_discovery: custDiscoverPichaEats.value,
                cust_addr_line_1: custAddress.value,
                cust_addr_postcode: custPostcode.value,
                cust_delivery_instructions: custDeliveryInstructions.value,
                cust_consent: custConsent.value,
                //Required field for ar-backend
                cust_existing: false,
            };
            if (custGender.value !== undefined) {
                dataBody = {
                    ...dataBody,
                    cust_gender: custGender.value,
                };
            }

            if (custEthnicityOthers.value !== "") {
                dataBody = {
                    ...dataBody,
                    cust_ethnicity_others: custEthnicityOthers.value,
                };
            }
            customerService
                .createCustomer(dataBody)
                .then((res) => {
                    userService
                        .login(dataBody.username, dataBody.password)
                        .then((userData) => {
                            //if
                            //create subscription
                            //redirect them
                            if (
                                window.location.href.indexOf("selection") != -1
                            ) {
                                //create subscription
                                //gather databody
                                //redirect to payment
                                toggleLoggedIn();
                                window.location.reload();
                            } else {
                                toggleLoggedIn();
                                window.location.reload();
                            }
                        })
                        .catch((res) => {
                            Swal.fire({
                                icon: "error",
                                title: "Login Error",
                                confirmButtonColor:
                                    store.getState().colorState.color,
                                text: "Please try again later.",
                            }).then((result) => {
                                if (result.isConfirmed) {
                                }
                            });
                        });
                })
                .catch((result) => {
                    if (result.status == 400) {
                        Swal.fire(
                            "Email already exist",
                            "Please contact us for further enquiries.",
                            "error"
                        );
                    } else {
                        Swal.fire(
                            "Register Error",
                            "Please try again later.",
                            "error"
                        );
                    }
                });
        } else {
            Swal.fire(
                "Register Error",
                "All required fields must be completed correctly",
                "error"
            );
        }
    };
    // after 401 error work

    const changeFormTo = (nxtFormName) => {
        // $("#register-btn").parent().children(".loading-spinner").css("display", "none");
        // $("#register-btn").css("visibility", "visible");
        // $("#login-btn").parent().children(".loading-spinner").css("display", "none");
        // $("#login-btn").css("visibility", "visible");
        // if (nxtFormName == "register" && currentFormState != "register") {
        //     $(".form-container .form-control").val(""); //reset all value
        //     $(".form-container .error-label").text(""); //reset all error
        //     clearLocation();
        //     $loginForm.css("left", "-100%");
        //     $registerForm.css("left", "0");
        //     $loginToggle.css("color", "#545454");
        //     $registerToggle.css("color", "white");
        //     $btnSelectedIndicator.css("left", "114px")
        //     currentFormState = "register";
        //  }
        // if (nxtFormName == "login" && currentFormState != "login") {
        //     $(".form-container .form-control").val(""); //reset all value
        //     $(".form-container .error-label").text(""); //reset all error
        //     $loginForm.css("left", "0");
        //     $registerForm.css("left", "100%");
        //     $loginToggle.css("color", "white");
        //     $registerToggle.css("color", "#545454");
        //     $btnSelectedIndicator.css("left", "0px")
        //     currentFormState = "login";
        //  }
    };
    return (
        <div
            className={
                " login-register-modal-container " +
                (isLoginRegisterModalOpened ? "show" : "")
            }
        >
            <div className="login-register-modal">
                <div className="form-box">
                    <div className="button-box">
                        <div
                            id="btn-selected-indicator"
                            className={
                                modalState == "login"
                                    ? "indicator-left"
                                    : "indicator-right"
                            }
                        ></div>
                        <button
                            type="button"
                            id="login-toggle"
                            className={
                                "toggle-btn " +
                                (modalState == "login" ? "active" : "")
                            }
                            style={{
                                borderRadius: "10px",
                                color: store.getState().colorState.font_color,
                                backgroundColor:
                                    store.getState().colorState.color,
                            }}
                            onClick={setModalState.bind(this, "login")}
                        >
                            Log in
                        </button>
                        <button
                            type="button"
                            id="register-toggle"
                            className={
                                "toggle-btn " +
                                (modalState == "register" ? "active" : "")
                            }
                            onClick={setModalState.bind(this, "register")}
                        >
                            Register
                        </button>
                    </div>
                    <div className="form-container">
                        <div
                            id="login-form"
                            className={
                                "input-form-wrapper " +
                                (modalState == "login" ? "active" : "")
                            }
                        >
                            <h4 className="mb-4">Log in</h4>
                            <Grid
                                container
                                className="px-auto mt-4 "
                                spacing={2}
                            >
                                <Grid item xs={12}>
                                    <TextField
                                        className="login-input  mx-1"
                                        label="Email"
                                        type="text"
                                        InputLabelProps={{
                                            className: "input-label-font",
                                        }}
                                        value={usernameLogin.value}
                                        error={!!usernameLogin.error}
                                        helperText={usernameLogin.error}
                                        variant="outlined"
                                        onChange={(e) => {
                                            const newValue = e.target.value;

                                            if (
                                                validateUsernameLogin(
                                                    newValue
                                                ) ||
                                                newValue == ""
                                            ) {
                                                setUsernameLogin({
                                                    value: newValue,
                                                });
                                            }
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        variant="outlined"
                                        className="login-input mx-1"
                                        label="Password"
                                        type="password"
                                        InputLabelProps={{
                                            className: "input-label-font",
                                            // style: { fontSize: "11px" },
                                        }}
                                        value={passwordLogin.value}
                                        error={!!passwordLogin.error}
                                        helperText={passwordLogin.error}
                                        onKeyPress={(event) => {
                                            if (event.key === "Enter") {
                                                login();
                                            }
                                        }}
                                        onChange={(e) => {
                                            const newValue = e.target.value;

                                            if (
                                                validatePasswordLogin(
                                                    newValue
                                                ) ||
                                                newValue == ""
                                            ) {
                                                setPasswordLogin({
                                                    value: newValue,
                                                });
                                            }
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <p className="font-weight-light text-left">
                                        <a
                                            style={{
                                                marginLeft: "95px  ",
                                            }}
                                            className="register-label text-primary"
                                            href={forgotPasswordLink}
                                            target="_blank"
                                        >
                                            Forgot password?
                                        </a>
                                    </p>
                                </Grid>
                            </Grid>

                            <div>
                                <div className="d-inline-block position-relative">
                                    <div
                                        className="loading-spinner spinner-border text-success"
                                        role="status"
                                    >
                                        <p className="font-weight-light mt-2">
                                            Not a member?&nbsp;
                                            <a
                                                className="register-label text-primary"
                                                onClick={setModalState.bind(
                                                    this,
                                                    "register"
                                                )}
                                            >
                                                Register Now
                                            </a>
                                        </p>
                                        <span className="sr-only">
                                            Loading.. .
                                        </span>
                                    </div>
                                    <div>
                                        <button
                                            type="button"
                                            onClick={toggleLoginRegisterModal}
                                            className="btn btn-secondary d-inline-block login-cancel-btn"
                                            aria-label=""
                                        >
                                            Cancel
                                        </button>
                                        <button
                                            type="button"
                                            id="login-btn"
                                            className="btn btn-success d-inline-block"
                                            onClick={login}
                                            style={{ backgroundColor: "black" }}
                                        >
                                            Sign In
                                        </button>
                                    </div>
                                    <p className="font-weight-light mt-5">
                                        Not a member?&nbsp;
                                        <a
                                            className="register-label text-primary"
                                            onClick={setModalState.bind(
                                                this,
                                                "register"
                                            )}
                                        >
                                            Register Now
                                        </a>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div
                            id="register-form"
                            style={{ marginLeft: "auto" }}
                            className={
                                " input-form-wrapper" +
                                (modalState == "register" ? "-active" : "")
                            }
                        >
                            <div className="mobile-div">
                                <h6 className="text-left ml-2">
                                    Login Details
                                </h6>

                                <Grid
                                    container
                                    className="m-2"
                                    alignContent="center"
                                >
                                    <Grid item xs={10} className="mt-3 mb-3">
                                        <TextField
                                            required
                                            id="emailAddress"
                                            label="Email"
                                            InputLabelProps={{
                                                className: "input-label-font",
                                                // style: { fontSize: "11px" },
                                            }}
                                            onChange={(e) => {
                                                const newValue = e.target.value;
                                                if (validateEmail(newValue)) {
                                                    setCustEmail({
                                                        value: newValue,
                                                    });
                                                }
                                            }}
                                            error={!!custEmail.error}
                                            helperText={custEmail.error}
                                            fullWidth
                                            variant="outlined"
                                        />
                                    </Grid>
                                    {/* <Grid item xs={7}></Grid> */}
                                    <Grid item xs={5}>
                                        <TextField
                                            required
                                            id="password"
                                            label="Password"
                                            InputLabelProps={{
                                                className: "input-label-font",
                                                // style: { fontSize: "11px" },
                                            }}
                                            onChange={(e) => {
                                                const newValue = e.target.value;
                                                if (
                                                    validatePasswordRegister(
                                                        newValue
                                                    ) ||
                                                    newValue === ""
                                                ) {
                                                    setPasswordRegister({
                                                        value: newValue,
                                                    });
                                                }
                                            }}
                                            error={!!passwordRegister.error}
                                            helperText={passwordRegister.error}
                                            type="password"
                                            fullWidth
                                            variant="outlined"
                                        />
                                    </Grid>
                                    <Grid item xs={5} className="">
                                        <TextField
                                            required
                                            id="retypePassword"
                                            label="Retype Password"
                                            InputLabelProps={{
                                                className: "input-label-font",
                                                // style: { fontSize: "11px" },
                                            }}
                                            //TODO: authentication with password
                                            onChange={(e) => {
                                                const newValue = e.target.value;
                                                if (
                                                    validateRetypePassword(
                                                        newValue
                                                    ) ||
                                                    newValue === ""
                                                ) {
                                                    setRetypePassword({
                                                        value: newValue,
                                                    });
                                                }
                                            }}
                                            error={!!retypePassword.error}
                                            helperText={retypePassword.error}
                                            type="password"
                                            fullWidth
                                            variant="outlined"
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <h6 className="text-left mt-5">
                                            Account Information
                                        </h6>
                                    </Grid>
                                    <Grid container className="mb-2">
                                        <Grid item xs={5} className="">
                                            <TextField
                                                required
                                                label="First Name"
                                                InputLabelProps={{
                                                    className:
                                                        "input-label-font",
                                                }}
                                                onChange={(e) => {
                                                    const newValue =
                                                        e.target.value;
                                                    if (
                                                        validateFirstName(
                                                            newValue
                                                        ) ||
                                                        newValue === ""
                                                    ) {
                                                        setCustFirstName({
                                                            value: newValue,
                                                        });
                                                    }
                                                }}
                                                error={!!custFirstName.error}
                                                helperText={custFirstName.error}
                                                variant="outlined"
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item xs={5}>
                                            <TextField
                                                required
                                                id="lastName"
                                                label="Last Name"
                                                InputLabelProps={{
                                                    className:
                                                        "input-label-font",
                                                    // style: { fontSize: "11px" },
                                                }}
                                                onChange={(e) => {
                                                    const newValue =
                                                        e.target.value;
                                                    if (
                                                        validateLastName(
                                                            newValue
                                                        ) ||
                                                        newValue === ""
                                                    ) {
                                                        setCustLastName({
                                                            value: newValue,
                                                        });
                                                    }
                                                }}
                                                error={!!custLastName.error}
                                                helperText={custLastName.error}
                                                fullWidth
                                                variant="outlined"
                                            />
                                        </Grid>
                                    </Grid>
                                    {merchant == "pichaeats" ? (
                                        <>
                                            <Grid
                                                item
                                                xs={5}
                                                className=" mt-3 "
                                            >
                                                <TextField
                                                    required
                                                    id="Birthday"
                                                    label="Birthday"
                                                    type="date"
                                                    InputProps={{
                                                        inputProps: {
                                                            // max: "2017-05-29",
                                                            max: moment().format(
                                                                "YYYY-MM-DD"
                                                            ),
                                                        },
                                                    }}
                                                    InputLabelProps={{
                                                        style: {
                                                            fontSize: "11px",
                                                        },
                                                        shrink: true,
                                                    }}
                                                    onChange={(e) => {
                                                        const newValue =
                                                            e.target.value;
                                                        if (
                                                            validateBirthday(
                                                                newValue
                                                            ) ||
                                                            newValue === ""
                                                        ) {
                                                            setCustBirthday({
                                                                value: newValue,
                                                            });
                                                        }
                                                    }}
                                                    error={!!custBirthday.error}
                                                    helperText={
                                                        custBirthday.error
                                                    }
                                                    fullWidth
                                                    variant="outlined"
                                                />
                                            </Grid>
                                            <Grid item xs={5} className="mt-3">
                                                <FormControl
                                                    fullWidth
                                                    variant="outlined"
                                                >
                                                    <InputLabel
                                                        required
                                                        className="input-label-font"
                                                        // style={{ fontSize: "12px" }}
                                                    >
                                                        Gender
                                                    </InputLabel>
                                                    <Select
                                                        required
                                                        style={{
                                                            textAlign: "left",
                                                        }}
                                                        value={custGender.value}
                                                        label="Gender"
                                                        onChange={(e) => {
                                                            const newValue =
                                                                e.target.value;
                                                            if (
                                                                validateGender(
                                                                    newValue
                                                                ) ||
                                                                newValue === ""
                                                            ) {
                                                                setCustGender({
                                                                    value: newValue,
                                                                });
                                                            }
                                                        }}
                                                        displayEmpty
                                                    >
                                                        <MenuItem value="MALE">
                                                            Male
                                                        </MenuItem>
                                                        <MenuItem value="FEMALE">
                                                            Female
                                                        </MenuItem>
                                                        <MenuItem value="OTHERS">
                                                            Prefer not to say
                                                        </MenuItem>
                                                        {/* TODO: if OTHERS should be able to type */}
                                                    </Select>
                                                    <FormHelperText
                                                        style={{ color: "red" }}
                                                    >
                                                        {custGender.error}
                                                    </FormHelperText>
                                                </FormControl>
                                            </Grid>
                                        </>
                                    ) : (
                                        ""
                                    )}

                                    <Grid container className="mb-2">
                                        <Grid item xs={5}>
                                            <TextField
                                                required
                                                className="mt-3 "
                                                id="contactNumber"
                                                label="Contact Number"
                                                InputLabelProps={{
                                                    className:
                                                        "input-label-font",
                                                    // style: { fontSize: "11px" },
                                                }}
                                                onChange={(e) => {
                                                    const newValue =
                                                        e.target.value;
                                                    if (
                                                        validateContactNo(
                                                            newValue
                                                        ) ||
                                                        newValue === ""
                                                    ) {
                                                        setCustContactNo({
                                                            value: newValue,
                                                        });
                                                    }
                                                }}
                                                error={!!custContactNo.error}
                                                helperText={custContactNo.error}
                                                fullWidth
                                                variant="outlined"
                                            />
                                        </Grid>
                                        <Grid item xs={5} className="mt-3 ">
                                            <TextField
                                                className="input-label-font"
                                                InputLabelProps={{
                                                    className:
                                                        "input-label-font",
                                                    // style: { fontSize: "11px" },
                                                }}
                                                id="altContactNumber"
                                                label="Alt Contact Number"
                                                onChange={(e) => {
                                                    const newValue =
                                                        e.target.value;
                                                    if (
                                                        validateAltContactNo(
                                                            newValue
                                                        ) ||
                                                        newValue === ""
                                                    ) {
                                                        setCustAltContactNo({
                                                            value: newValue,
                                                        });
                                                    }
                                                }}
                                                error={!!custAltContactNo.error}
                                                helperText={
                                                    custAltContactNo.error
                                                }
                                                fullWidth
                                                variant="outlined"
                                            />
                                        </Grid>

                                        {merchant == "pichaeats" ? (
                                            <>
                                                <Grid
                                                    item
                                                    xs={10}
                                                    className=" mt-3"
                                                >
                                                    <FormControl
                                                        fullWidth
                                                        variant="outlined"
                                                    >
                                                        <InputLabel className="input-label-font">
                                                            {" "}
                                                            What festival do you
                                                            celebrate?
                                                        </InputLabel>
                                                        <Select
                                                            style={{
                                                                textAlign:
                                                                    "left",
                                                            }}
                                                            displayEmpty
                                                            label="What festival do you celebrate"
                                                            onChange={(e) => {
                                                                const newValue =
                                                                    e.target
                                                                        .value;
                                                                if (
                                                                    validateCustFestival(
                                                                        newValue
                                                                    ) ||
                                                                    newValue ===
                                                                        ""
                                                                ) {
                                                                    setCustFestival(
                                                                        {
                                                                            value: newValue,
                                                                        }
                                                                    );
                                                                }
                                                            }}
                                                            error={
                                                                !!custFestival.error
                                                            }
                                                            value={
                                                                custFestival.value
                                                            }
                                                            fullWidth
                                                            variant="outlined"
                                                        >
                                                            <MenuItem value="RAYA">
                                                                Eid al-Fitr
                                                                (Hari Raya
                                                                Aidilfitri)
                                                            </MenuItem>
                                                            <MenuItem value="CNY">
                                                                Chinese New Year
                                                            </MenuItem>
                                                            <MenuItem value="DEEPAVALI">
                                                                Deepavali
                                                            </MenuItem>
                                                            <MenuItem value="WESAK">
                                                                Wesak
                                                            </MenuItem>
                                                            <MenuItem value="XMAS">
                                                                Christmas
                                                            </MenuItem>
                                                            <MenuItem value="NOSAY">
                                                                Prefer Not to
                                                                Say
                                                            </MenuItem>
                                                            {/* <MenuItem value="DAYAK">
                                                        Dayak
                                                    </MenuItem>
                                                    <MenuItem value="NOSAY">
                                                        PREFER NOT TO SAY
                                                    </MenuItem>
                                                    <MenuItem value="OTHERS">
                                                        Others
                                                    </MenuItem> */}
                                                        </Select>
                                                    </FormControl>
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={10}
                                                    className="mt-3"
                                                >
                                                    <FormControl
                                                        fullWidth
                                                        variant="outlined"
                                                    >
                                                        <InputLabel className="input-label-font">
                                                            {" "}
                                                            How did you hear
                                                            about us?
                                                        </InputLabel>
                                                        <Select
                                                            style={{
                                                                textAlign:
                                                                    "left",
                                                            }}
                                                            displayEmpty
                                                            label="How did you hear about us"
                                                            onChange={(e) => {
                                                                const newValue =
                                                                    e.target
                                                                        .value;
                                                                if (
                                                                    validateCustDiscoverPichaEats(
                                                                        newValue
                                                                    ) ||
                                                                    newValue ===
                                                                        ""
                                                                ) {
                                                                    setCustDiscoverPichaEats(
                                                                        {
                                                                            value: newValue,
                                                                        }
                                                                    );
                                                                }
                                                            }}
                                                            value={
                                                                custDiscoverPichaEats.value
                                                            }
                                                            error={
                                                                !!custDiscoverPichaEats.error
                                                            }
                                                            fullWidth
                                                            variant="outlined"
                                                        >
                                                            <MenuItem value="Facebook">
                                                                Facebook
                                                            </MenuItem>
                                                            <MenuItem value="Instagram">
                                                                Instagram
                                                            </MenuItem>
                                                            <MenuItem value="Others">
                                                                Other Social
                                                                Media Platforms
                                                                (LinkedIn,
                                                                Twitter, etc)
                                                            </MenuItem>
                                                            <MenuItem value="Friends &amp; Family">
                                                                Friends & Family
                                                            </MenuItem>
                                                            <MenuItem value="Google Search">
                                                                Google Search
                                                            </MenuItem>
                                                            <MenuItem value="Media">
                                                                Media and
                                                                Influencers
                                                            </MenuItem>
                                                            <MenuItem value="Email">
                                                                Email Newsletter
                                                            </MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </Grid>
                                            </>
                                        ) : (
                                            ""
                                        )}

                                        <Grid item xs={5}></Grid>
                                    </Grid>
                                    <React.Fragment>
                                        <h6 className="text-left mt-5">
                                            Delivery information
                                        </h6>

                                        <Grid container className="mb-3">
                                            <Grid item xs={10}>
                                                <TextField
                                                    required
                                                    id="deliveryAddress"
                                                    label="Delivery Address"
                                                    onChange={(e) => {
                                                        const newValue =
                                                            e.target.value;
                                                        if (
                                                            validateAddress(
                                                                newValue
                                                            ) ||
                                                            newValue === ""
                                                        ) {
                                                            setCustAddress({
                                                                value: newValue,
                                                            });
                                                        }
                                                    }}
                                                    error={!!custAddress.error}
                                                    helperText={
                                                        custAddress.error
                                                    }
                                                    fullWidth
                                                    variant="outlined"
                                                />
                                            </Grid>
                                        </Grid>

                                        <Grid container className="mb-3">
                                            <Grid item xs={5}>
                                                <TextField
                                                    required
                                                    id="postcode"
                                                    label="Postcode"
                                                    onChange={(e) => {
                                                        const newValue =
                                                            e.target.value;
                                                        setCustPostcode({
                                                            value: newValue,
                                                        });
                                                    }}
                                                    error={!!custPostcode.error}
                                                    helperText={
                                                        custPostcode.error
                                                    }
                                                    fullWidth
                                                    variant="outlined"
                                                />
                                            </Grid>
                                        </Grid>
                                        {/* <Grid container xs={12}>
                                                <Grid item xs={5} className="">
                                                    <TextField
                                                        required
                                                        id="district"
                                                        label="District"
                                                        onChange={(e) => {
                                                            const newValue =
                                                                e.target.value;
                                                            if (
                                                                validateDistrict(
                                                                    newValue
                                                                ) ||
                                                                newValue === ""
                                                            ) {
                                                                setCustDistrict(
                                                                    {
                                                                        value: newValue,
                                                                    }
                                                                );
                                                            }
                                                        }}
                                                        error={
                                                            !!custDistrict.error
                                                        }
                                                        helperText={
                                                            custDistrict.error
                                                        }
                                                        fullWidth
                                                        variant="outlined"
                                                    />
                                                </Grid>
                                            </Grid> */}
                                        <Grid container>
                                            <Grid item xs={5} className="mb-3">
                                                <TextField
                                                    id="deliveryInstructions"
                                                    label="Delivery Remarks"
                                                    onChange={(e) => {
                                                        const newValue =
                                                            e.target.value;
                                                        setCustDeliveryInstruction(
                                                            {
                                                                value: newValue,
                                                            }
                                                        );
                                                    }}
                                                    fullWidth
                                                    variant="outlined"
                                                />
                                            </Grid>
                                        </Grid>
                                    </React.Fragment>
                                    <Grid item xs={10}>
                                        <FormGroup>
                                            <FormControlLabel
                                                style={{
                                                    // marginLeft: "50px",
                                                    marginTop: "20px",
                                                    textAlign: "left",
                                                }}
                                                control={
                                                    <Checkbox
                                                        checked={
                                                            custConsent.value
                                                        }
                                                        onChange={(e) => {
                                                            setCustConsent({
                                                                value: e.target
                                                                    .checked,
                                                            });
                                                        }}
                                                    />
                                                }
                                                label="Please untick this box if you do not want to receive future promotion, offers and communication from us"
                                            />
                                        </FormGroup>
                                    </Grid>
                                </Grid>
                            </div>
                            <div
                            // style={{ marginLeft: "2.5rem" }}
                            >
                                <button
                                    type="button"
                                    className="btn btn-secondary register-cancel-btn"
                                    aria-label=""
                                    onClick={toggleLoginRegisterModal}
                                    style={{
                                        background: "#A0A0A0",
                                        display: "inline-block",
                                    }}
                                >
                                    Cancel
                                </button>
                                <div className="d-inline-block position-relative ">
                                    <div
                                        className="loading-spinner spinner-border text-success"
                                        role="status"
                                    >
                                        <span className="sr-only">
                                            Loading...
                                        </span>
                                    </div>
                                    <button
                                        type="button"
                                        id="register-btn"
                                        className="btn d-inline-block btn-primary"
                                        aria-label=""
                                        onClick={register_and_login}
                                    >
                                        Register
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LoginRegisterModal;
