import {
    Button,
    Checkbox,
    FormControl,
    FormGroup,
    InputLabel,
    MenuItem,
    Radio,
    RadioGroup,
    Select,
} from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { makeStyles } from "@material-ui/core/styles";
import EditIcon from "@material-ui/icons/Edit";
import React, { useEffect, useState } from "react";
import { Image, Modal } from "react-bootstrap";
import Swal from "sweetalert2";
import icon2 from "../../Images/fpx-logo.png";
import cardIcon from "../../Images/visa-master-logo.png";
import { store } from "../../index.js";
import customerService from "../../services/customerService";
import invoiceService from "../../services/invoiceService";
import subscriptionService from "../../services/subscriptionService";
import "./style.css";

const useStyles = makeStyles((theme) => ({
    menuPaper: {
        maxHeight: "50%",
        maxWidth: "80%",
    },
}));

const PaymentForm = ({
    history,
    showForm,
    handleCloseForm,
    closeButton,
    isRecurring,
    planDataBody,
    paymentMethod,
    selectedBank,
    isEdit,
    mandateRef,
}) => {
    // const [purposeOfPayment, setPurposeOfPayment] = useState("");
    const [paymentOption, setPaymentOption] = useState("1");
    const [bank, setBank] = useState("");
    const [termsAgreement, setTermsAgreement] = useState(false);
    const [bankList, setBankList] = useState([]);
    const dropDownStyles = useStyles();

    function UponCloseForm() {
        // check for payment option (FPX or credit card)
        if (paymentOption == "1" && bank == "") {
            Swal.fire({
                title: "Error",
                text: "Please select a bank to proceed",
                icon: "error",
                confirmButtonText: "ok",
            });
        } else if (!termsAgreement) {
            Swal.fire({
                title: "Error",
                text: "Please agree to the terms and condition to proceed",
                icon: "error",
                confirmButtonText: "ok",
            });
        } else {
            // mandate

            if (isEdit || isRecurring) {
                var customerId =
                    store.getState().loggedInUserReducer.userData.ar_system
                        .cust_id;
                var custDataBody = {
                    cust_mandate_type: paymentOption,
                };
                customerService
                    .editCustomerFromAR(custDataBody, customerId)
                    .then((res) => {
                        if (isEdit) {
                            var refNum = mandateRef;
                            if (refNum.length == 15) {
                                var lastLetter = refNum[14];
                                lastLetter = String.fromCharCode(
                                    lastLetter.charCodeAt() + 1
                                );
                                refNum = refNum.substring(0, 13);
                                refNum = `${refNum}-${lastLetter}`;
                            } else {
                                refNum = `${refNum}-A`;
                            }
                            var amount = 1000.0;
                            if (paymentOption == "2") {
                                amount = 200.0;
                            }
                            var bankId =
                                bank == null || bank == "" ? "" : bank.id[0];
                            window.open(
                                `${
                                    process.env.REACT_APP_CURLEC_ENDPOINT
                                }/new-mandate?effectiveDate=&method=03&amount=${amount}&frequency=MONTHLY&maximumFrequency=99&merchantId=${
                                    process.env.REACT_APP_SITE_MERCHANT_ID
                                }&employeeId=${
                                    process.env.REACT_APP_SITE_EMPLOYEE_ID
                                }&emailAddress=${
                                    store.getState().loggedInUserReducer
                                        .userData.ar_system.cust_email
                                }&name=${
                                    store.getState().loggedInUserReducer
                                        .userData.ar_system.cust_fname
                                }%20${
                                    store.getState().loggedInUserReducer
                                        .userData.ar_system.cust_lname
                                }&referenceNumber=${refNum}&purposeOfPayment=Edit%20Payment%20Details&paymentMethod=${paymentOption}&bankId=${bankId}&idType=OTHERS&idValue=12345&linkId=${paymentOption}`,
                                "_self"
                            );
                        } else {
                            if (
                                sessionStorage.getItem("delivery_date") ||
                                sessionStorage.getItem("default_menu")
                            ) {
                                sessionStorage.clear();
                            }
                            createRecurringSub();
                        }
                    })
                    .catch((res) => {
                        console.log("customer update failed " + res);
                    });
                // instant pay
            } else {
                createOneOffSub();
            }
        }
    }

    useEffect(() => {
        if (paymentMethod == "Credit Card") {
            setPaymentOption("2");
        }
        var method = { method: "00" };
        if (!isRecurring) {
            method = { method: "01" };
        }
        subscriptionService
            .getBanksForSubscription(method)
            .then((res) => {
                var result = res.replace(/'/g, '"');
                var banks = JSON.parse(result);
                setBankList(banks);
                if (selectedBank != "-") {
                    for (var i = 0; i < banks.length; i++) {
                        if (selectedBank == banks[i].name[0]) {
                            setBank(banks[i]);
                        }
                    }
                }
            })
            .catch((err) => {
                Swal.fire({
                    title: "Error",
                    text: "Could not retrieve FPX bank list",
                    icon: "error",
                    confirmButtonText: "ok",
                });
            });
    }, [isEdit, selectedBank]);

    const renderBankList = () => {
        if (paymentOption == "1") {
            return (
                <>
                    <div>
                        <FormControl fullWidth variant="outlined">
                            <InputLabel
                                shrink
                                required
                                InputLabelProps={{ shrink: false }}
                            >
                                Select your bank
                            </InputLabel>
                            <Select
                                className="text-left"
                                value={bank}
                                displayEmpty
                                style={{
                                    height: "80px",
                                    padding: "8px 0px",
                                }}
                                onChange={(e) => {
                                    const newValue = e.target.value;
                                    setBank(newValue);
                                }}
                                label="Select your bank"
                                MenuProps={{
                                    classes: {
                                        paper: dropDownStyles.menuPaper,
                                    },
                                }}
                            >
                                {bankList.map((b, index) => (
                                    <MenuItem key={b} value={b}>
                                        <div>
                                            <img
                                                className="bankLogo"
                                                src={b.logo[0]}
                                                style={{
                                                    float: "left",
                                                    maxWidth: "120px",
                                                    maxHeight: "40px",
                                                    marginRight: "15px",
                                                }}
                                            />
                                            <span
                                                style={{
                                                    verticalAlign: "middle",
                                                }}
                                                className="bankLabel"
                                            >
                                                {b.name[0]}
                                            </span>
                                        </div>
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>
                    <br />
                </>
            );
        } else {
            return <></>;
        }
    };

    const createRecurringSub = () => {
        subscriptionService
            .createSubscription(planDataBody)
            .then((res) => {
                //purpose of payment put plan name
                var subId = res["subs_id"];
                var amount = 1000.0;
                if (paymentOption == "2") {
                    amount = 200.0;
                }
                var planName = planDataBody.plan_name;
                var bankId = bank == null || bank == "" ? "" : bank.id[0];
                window.open(
                    `${
                        process.env.REACT_APP_CURLEC_ENDPOINT
                    }/new-mandate?effectiveDate=&method=03&amount=${amount}&frequency=MONTHLY&maximumFrequency=99&merchantId=${
                        process.env.REACT_APP_SITE_MERCHANT_ID
                    }&employeeId=${
                        process.env.REACT_APP_SITE_EMPLOYEE_ID
                    }&emailAddress=${
                        store.getState().loggedInUserReducer.userData.ar_system
                            .cust_email
                    }&name=${
                        store.getState().loggedInUserReducer.userData.ar_system
                            .cust_fname
                    }%20${
                        store.getState().loggedInUserReducer.userData.ar_system
                            .cust_lname
                    }&referenceNumber=${
                        store.getState().loggedInUserReducer.userData.ar_system
                            .cust_id
                    }&purposeOfPayment=${planName}&paymentMethod=${paymentOption}&bankId=${bankId}&idType=OTHERS&idValue=12345&linkId=${subId}`,
                    "_self"
                );
            })
            .catch((res) => {
                if (res.message == "subscription already exists") {
                    Swal.fire({
                        title: "You are already subscribed to this plan",
                        text: "You will be redirected back to the catalog page",
                        icon: "error",
                        confirmButtonText: "OK",
                    }).then((res) => {
                        history.push("/");
                    });
                } else {
                    Swal.fire(
                        "Please check your menu and delivery window again.",
                        "",
                        "info"
                    ).then((res) => {
                        handleCloseForm();
                    });
                }
            });
    };

    const createOneOffSub = () => {
        subscriptionService
            .createSubscription(planDataBody)
            .then((res) => {
                var invoiceBody = {
                    one_off: true,
                    inv_cust_id: planDataBody.customer_id + " ",
                    subscription_price: planDataBody.subs_price + " ,",
                    subscription_id: res.subs_id + " ,",
                    delivery_date:
                        planDataBody.delivery_date.toISOString() + "  ,",
                    description: planDataBody.plan_name + "   ,",
                    frequency: res.delivery_frequency + " ,",
                };
                invoiceService
                    .createDraftInvoice(invoiceBody)
                    .then((result) => {
                        var invoiceId = result.inv_ids[0];
                        var bankCode =
                            bank == null || bank == "" ? "" : bank.code[0];
                        window.open(
                            `${
                                process.env.REACT_APP_CURLEC_ENDPOINT
                            }/new-instant-pay?effectiveDate=&method=03&merchantId=${
                                process.env.REACT_APP_SITE_MERCHANT_ID
                            }&employeeId=${
                                process.env.REACT_APP_SITE_EMPLOYEE_ID
                            }&orderNo=${invoiceId}&email=${
                                store.getState().loggedInUserReducer.userData
                                    .ar_system.cust_email
                            }&description=${planDataBody.plan_name}&amount=${
                                planDataBody.subs_price
                            }&paymentMethod=${paymentOption}&bankCode=${bankCode}`,
                            "_self"
                        );
                    })
                    .catch((res) => {
                        Swal.fire({
                            title: "Error",
                            text: "Unable to subscribe with instant pay due to system error",
                            icon: "error",
                            confirmButtonText: "OK",
                        }).then((res) => {
                            handleCloseForm();
                        });
                    });
            })
            .catch((res) => {
                if (res.message == "subscription already exists") {
                    Swal.fire({
                        title: "You are already subscribed to this plan",
                        text: "You will be redirected back to the catalog page",
                        icon: "error",
                        confirmButtonText: "OK",
                    }).then((res) => {
                        history.push("/");
                    });
                } else {
                    Swal.fire(
                        "Please check your menu and delivery window again.",
                        "",
                        "info"
                    ).then((res) => {
                        handleCloseForm();
                    });
                }
            });
    };

    const renderWarningMsg = () => {
        if (paymentOption == "1") {
            return <></>;
        } else {
            return (
                <p
                    style={{
                        color: "#EB011C",
                        textAlign: "center",
                        marginTop: "1em",
                    }}
                >
                    &#42;Disclaimer: The{" "}
                    <strong style={{ fontWeight: "900" }}>
                        Amount (RM 200)
                    </strong>{" "}
                    that will appear on the authentication page is{" "}
                    <strong style={{ fontWeight: "900" }}>NOT</strong> the
                    amount you will be charged. It is the maximum amount
                    available for monthly collection.
                </p>
            );
        }
    };

    return (
        <Modal
            size="lg"
            dialogClassName="custom-modal"
            centered
            show={showForm}
            onHide={handleCloseForm}
        >
            <Modal.Header closeButton>
                {isEdit ? (
                    <Modal.Title as="h4">
                        <EditIcon
                            style={{
                                marginRight: "10px",
                                paddingBottom: "2px",
                            }}
                        />
                        Edit Payment Details
                    </Modal.Title>
                ) : (
                    <Modal.Title as="h3">Checkout</Modal.Title>
                )}
            </Modal.Header>
            <Modal.Body className="customFormBody">
                <RadioGroup value={paymentOption} row>
                    <FormControlLabel
                        value="1"
                        control={<Radio color="primary" />}
                        onChange={(e) => {
                            if (e.target.checked) {
                                setPaymentOption("1");
                            }
                        }}
                        label={<Image src={icon2} className="paymentImg" />}
                    />
                    <FormControlLabel
                        value="2"
                        control={<Radio color="primary" />}
                        onChange={(e) => {
                            if (e.target.checked) {
                                setPaymentOption("2");
                            }
                        }}
                        label={<Image src={cardIcon} className="paymentImg" />}
                    />
                </RadioGroup>
                {renderBankList()}
                <p>
                    <b>Terms &amp; Conditions *</b>
                </p>
                <FormGroup className="termsForm">
                    <FormControlLabel
                        style={{
                            marginLeft: "1px",
                            // marginTop: "10px",
                            // textAlign: "center",
                            textAlign: "justify",
                            verticalAlign: "center",
                        }}
                        control={
                            <Checkbox
                                color="yellow"
                                checked={termsAgreement}
                                onChange={(e) => {
                                    setTermsAgreement(e.target.checked);
                                }}
                                // style={{ marginTop: "-20px" }}
                                // className="termsCheck"
                            />
                        }
                        labelPlacement="end"
                        label="I fully understand and agree to the Terms & Conditions of Direct Debit services and I acknowledge that upon successful completion of this online application, RM1.00 shall be debited from my selected account to ensure the said account is active. Please note that PichaEats may refund the RM1.00 subject to Terms & Condition. For checkout using cards via Visa/MasterCard, there will be no RM1.00 payment required. Please contact +60182894353 for further details."
                    />
                </FormGroup>
                {renderWarningMsg()}
            </Modal.Body>
            <Modal.Footer
                style={{
                    display: "flex",
                    justifyContent: "center",
                }}
            >
                {/* {isEdit &&
                    <Button
                        variant="primary"
                        style={{
                            color: "black",
                            backgroundColor: "rgb(240, 240, 240)",
                            marginRight: "10px",
                        }}
                        onClick={handleCloseForm}
                    >
                        <p
                            style={{
                                fontSize: "130%",
                                padding: "0 15px",
                                margin: "auto",
                                textAlign: "center",
                            }}
                        >
                            Cancel
                        </p>
                    </Button>
                } */}
                <Button
                    style={{
                        color: "black",
                        backgroundColor: "rgba(255, 222, 89, 2)",
                    }}
                    onClick={UponCloseForm}
                >
                    <p
                        style={{
                            fontSize: "130%",
                            padding: "0 15px",
                            margin: "auto",
                            textAlign: "center",
                        }}
                    >
                        Proceed
                    </p>
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default PaymentForm;
