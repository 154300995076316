import React, { useState, useEffect, useRef, Fragment } from "react";
import TrendingFlatIcon from "@material-ui/icons/TrendingFlat";
import ClearIcon from "@material-ui/icons/Clear";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import tierPricingModelService from "../../../../services/tierPricingModelService";
import { ToastContainer, toast } from "react-toastify";
import {
    Button,
    Radio,
    RadioGroup,
    Grid,
    FormLabel,
    TextField,
    FormControlLabel,
    IconButton,
    FormControl,
    InputLabel,
    FormHelperText,
    Select,
    MenuItem,
} from "@material-ui/core";
import moment from "moment";
import "./style.css";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import CurrencyTextField from "@unicef/material-ui-currency-textfield";
import { urlAction } from "../../../../action";
import merchantService from "../../../../services/merchantService";

const MySwal = withReactContent(Swal);

const TieredPricingModelEditCreate = ({ history, match, readonly = false }) => {
    const isFirstRun = useRef(true);
    const [merchantInfo, setMerchantInfo] = useState();

    const [tierPricingModel, setTierPricingModel] = useState({
        tiered_model_id: { value: "" },
        tiered_model_desc: { value: "" },
        unit: { value: "" },
        effective_date: moment().format("yyyy-MM-DDTHH:mm"),
        tiers: [
            {
                starting_unit: 0,
                ending_unit: "",
                error: {},
                price_per_unit: "",
            },
            {
                starting_unit: "",
                ending_unit: "",
                error: {},
                price_per_unit: "",
            },
        ],
    });

    const generateAnotherRowForTierInput = () => {
        const newAddonTierPricingModel = {
            ...tierPricingModel,
            tiers: [
                ...tierPricingModel.tiers,
                {
                    starting_unit:
                        parseInt(
                            tierPricingModel.tiers[
                                tierPricingModel.tiers.length - 1
                            ].ending_unit
                        ) + 1 || "",
                    ending_unit: "",
                    error: {},
                    price_per_unit: "",
                },
            ],
        };

        setTierPricingModel(newAddonTierPricingModel);
    };

    const restructureAddonTierPricingModel = () => {
        //Call Back used to Fix The Gap in between tiers

        //This usually happen after a tier row is removed, which create a gap in between
        var newTiers = tierPricingModel.tiers.map((tier, i) => {
            if (!!tierPricingModel.tiers[i - 1])
                //If Previous tier does exist
                tier["starting_unit"] =
                    parseInt(tierPricingModel.tiers[i - 1]["ending_unit"]) + 1; // Update Tier's starting unit with previous tier's ending unit + 1

            return tier;
        });

        const newAddonTierPricingModel = {
            ...tierPricingModel,
            tiers: newTiers,
        };

        setTierPricingModel(newAddonTierPricingModel);
    };

    const removeSingleTierInput = (index) => {
        const newAddonTierPricingModel = {
            ...tierPricingModel,
        };

        newAddonTierPricingModel.tiers.splice(index, 1); // Remove the tier element

        setTierPricingModel(
            newAddonTierPricingModel,
            restructureAddonTierPricingModel()
        ); //after state is updated, restructureAddonTierPricingModel(TO FIX THE GAP BETWEEN TIER)
    };

    const fetchTieredModelDetailWithId = (tierModelId) => {
        tierPricingModelService
            .getSingleTierPricingModel(tierModelId)
            .then((res) => {
                const tierModelInfo = {
                    tiered_model_id: { value: res.model.tiered_model_id },
                    tiered_model_desc: { value: res.model.tiered_model_desc },
                    unit: { value: res.model.unit },
                    effective_date: moment(res.model.effective_date).format(
                        "yyyy-MM-DDTHH:mm"
                    ),
                    tiers: res.model.tier.map((tier) => {
                        if (tier.ending_unit == null) {
                            tier.ending_unit = "";
                        } else {
                            tier.ending_unit = tier.ending_unit.toString();
                        }

                        tier.starting_unit = tier.starting_unit.toString();
                        tier.price_per_unit = tier.price_per_unit.toString();

                        return { ...tier, error: {} };
                    }),
                };

                setTierPricingModel(tierModelInfo);
            })
            .catch((e) => {});
    };

    //First time initialization
    useEffect(() => {
        if (!!match.params.id && match.params.id != "new")
            fetchTieredModelDetailWithId(match.params.id);
    }, []);
    useEffect(() => {
        const splitURL = urlAction("get_merchant");
        merchantService.getMerchantInfo().then((e) => {
            let merchantInfo = e;

            let merchantId = merchantInfo
                .filter((obj) => obj.merch_name == splitURL)
                .map((obj) => {
                    return obj.id;
                });
            setMerchantInfo(merchantId);
        });
    }, []);

    //Listener when tierPricingModel state is changed.
    useEffect(() => {
        //SECTION: Auto Generate a New tier input row in the tier pricing model creation form
        if (tierPricingModel.tiers.length <= 0) return; //if the array is empty

        const isEndingUnitOfLastTierElementEmpty =
            !tierPricingModel.tiers[tierPricingModel.tiers.length - 1][
                "ending_unit"
            ];
        if (!isEndingUnitOfLastTierElementEmpty) {
            //if ending unit of last unit is NOT empty
            generateAnotherRowForTierInput(); // add a new tiers input
        }
    }, [tierPricingModel]);

    const redirectToTieredModelListingPage = () => {
        history.push("/admin/tieredmodel");
    };

    const createNewTierPricingModelInServer = () => {
        if (validateNewTierModelInputForm()) {
            const dataBody = {
                merchant_id: merchantInfo[0],
                tiered_model_desc: tierPricingModel.tiered_model_desc.value,
                effective_date: moment(
                    tierPricingModel.effective_date
                ).toISOString(),
                unit: tierPricingModel.unit.value,
                tier: tierPricingModel.tiers.map((tier, i) => {
                    if (!!tier.ending_unit) {
                        return {
                            starting_unit: parseFloat(tier.starting_unit),
                            ending_unit: parseFloat(tier.ending_unit),
                            price_per_unit: parseFloat(tier.price_per_unit),
                        };
                    } else {
                        return {
                            starting_unit: parseFloat(tier.starting_unit),
                            price_per_unit: parseFloat(tier.price_per_unit),
                        };
                    }
                }),
            };

            tierPricingModelService
                .createNewTierPricingModel(dataBody)
                .then((res) => {
                    Swal.fire(
                        "Success",
                        "Tier model created successfully!",
                        "success"
                    ).then((res) => {
                        navigateToTieredModelListingPage();
                    });
                })
                .catch((res) => {
                    toast.error(
                        <div>
                            Some Error Occur, Please try again later.
                            <br />
                            {JSON.stringify(res, null, 2)}
                            <br />
                        </div>,
                        {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        }
                    );
                });
        }
    };

    const editNewTierPricingModelInServer = () => {
        if (validateNewTierModelInputForm()) {
            const dataBody = {
                merchant_id: merchantInfo[0],
                tiered_model_desc: tierPricingModel.tiered_model_desc.value,
                effective_date: moment(
                    tierPricingModel.effective_date
                ).toISOString(),
                unit: tierPricingModel.unit.value,
                tier: tierPricingModel.tiers.map((tier, i) => {
                    if (!!tier.ending_unit) {
                        return {
                            starting_unit: parseFloat(tier.starting_unit),
                            ending_unit: parseFloat(tier.ending_unit),
                            price_per_unit: parseFloat(tier.price_per_unit),
                            parent_model: match.params.id,
                        };
                    } else {
                        return {
                            starting_unit: parseFloat(tier.starting_unit),
                            price_per_unit: parseFloat(tier.price_per_unit),
                            parent_model: match.params.id,
                        };
                    }
                }),
            };

            tierPricingModelService
                .editNewTierPricingModel(match.params.id, dataBody)
                .then((res) => {
                    Swal.fire(
                        "Success",
                        "Tier model updated successfully!",
                        "success"
                    ).then((res) => {
                        navigateToTieredModelListingPage();
                    });
                })
                .catch((res) => {
                    toast.error(
                        <div>
                            Some Error Occur, Please try again later.
                            <br />
                            {JSON.stringify(res, null, 2)}
                            <br />
                        </div>,
                        {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        }
                    );
                });
        }
    };

    const validateNewTierModelInputForm = () => {
        var isValid = true; // variable used to track if the form is valid

        isValid = validateTierEndingUnit() && isValid;
        isValid = validateTierPricePerUnit() && isValid;

        isValid =
            validateTierDesc(tierPricingModel.tiered_model_desc.value) &&
            isValid;
        isValid = validateTierUnitName(tierPricingModel.unit.value) && isValid;

        return isValid;
    };

    const validateTierDesc = (newValue) => {
        var isValid = true;

        //Reset the Error message
        var errorMessage = "";

        //Check empty
        if (newValue == "") {
            if (!!errorMessage) errorMessage += "\n";
            errorMessage += "Tier Desc Cannot be empty";
            isValid = false;
        }

        //Check max length 100
        if (newValue.length > 100) {
            if (!!errorMessage) errorMessage += "\n";
            errorMessage += "Tier Desc must be less than 101 character";
            isValid = false;
        }

        setTierPricingModel({
            ...tierPricingModel,
            tiered_model_desc: {
                ...tierPricingModel.tiered_model_desc,
                error: errorMessage,
            },
        }); //Set Error message

        return isValid;
    };

    const validateTierUnitName = (newValue) => {
        var isValid = true;

        //Reset the Error message
        var errorMessage = "";

        //Check empty
        if (newValue == "") {
            if (!!errorMessage) errorMessage += "\n";
            errorMessage += "Tier Name Cannot be empty";
            isValid = false;
        }

        //Check max length 50
        if (newValue.length > 50) {
            if (!!errorMessage) errorMessage += "\n";
            errorMessage += "Tier Unit Name must be less than 51 character";
            isValid = false;
        }

        setTierPricingModel({
            ...tierPricingModel,
            unit: {
                ...tierPricingModel.unit,
                error: errorMessage,
            },
        }); //Set Error message

        return isValid;
    };

    const validateTierEndingUnit = (newValue = "", index = -1) => {
        var isValid = true;

        //Reset the Error message
        var errorMessage = "";

        const newAddonTierPricingModel = {
            ...tierPricingModel,
        };

        if (index == -1) {
            //if index is not passed in, means validate all tiers's ending unit
            tierPricingModel.tiers.forEach((tier, i) => {
                errorMessage = "";

                //Check empty
                if (
                    tier.ending_unit == "" &&
                    i < tierPricingModel.tiers.length - 1
                ) {
                    if (!!errorMessage) errorMessage += "\n";
                    errorMessage += "Addon Ending unit cannot be empty";
                    isValid = false;
                }

                //Check if ending unit only contain number
                var positiveNumberOnlyRegex = "^[0-9]+$";
                if (
                    !tier.ending_unit.match(positiveNumberOnlyRegex) &&
                    i < tierPricingModel.tiers.length - 1
                ) {
                    //newValue contain alphabet
                    if (!!errorMessage) errorMessage += "\n";
                    errorMessage += "Addon Ending unit can only be number";
                    isValid = false;
                }

                //Check if ending unit is larger than starting unit
                if (
                    parseInt(tier.ending_unit) <=
                        parseInt(tier.starting_unit) &&
                    i < tierPricingModel.tiers.length - 1
                ) {
                    if (!!errorMessage) errorMessage += "\n";
                    errorMessage +=
                        "Tier's ending unit cannot be smaller or equal to its starting unit";
                    isValid = false;
                }

                newAddonTierPricingModel.tiers[i]["error"]["ending_unit"] =
                    errorMessage;
            });
        } else {
            //validate only the specific tier ending unit

            //Check empty
            if (newValue == "") {
                if (!!errorMessage) errorMessage += "\n";
                errorMessage += "Tier's Ending unit cannot be empty";
                isValid = false;
            }

            //Check if ending unit only contain number
            var positiveNumberOnlyRegex = "^[0-9]+$";
            if (!newValue.match(positiveNumberOnlyRegex)) {
                //newValue contain alphabet
                if (!!errorMessage) errorMessage += "\n";
                errorMessage += "Tier's Ending unit can only be number";
                isValid = false;
            }

            //Check if ending unit is larger than starting unit
            if (
                parseInt(newValue) <=
                parseInt(newAddonTierPricingModel.tiers[index]["starting_unit"])
            ) {
                if (!!errorMessage) errorMessage += "\n";
                errorMessage +=
                    "Tier's ending unit cannot be smaller or equal to its starting unit";
                isValid = false;
            }

            newAddonTierPricingModel.tiers[index]["error"]["ending_unit"] =
                errorMessage;
        }

        setTierPricingModel(newAddonTierPricingModel); //Set Error message

        return isValid;
    };

    const validateTierPricePerUnit = (newValue = "", index = -1) => {
        var isValid = true;

        //Reset the Error message
        var errorMessage = "";

        const newAddonTierPricingModel = {
            ...tierPricingModel,
        };

        if (index == -1) {
            //if index is not passed in, means validate all tiers's ending unit
            tierPricingModel.tiers.forEach((tier, i) => {
                errorMessage = "";
                //Check empty
                if (tier.price_per_unit == "") {
                    if (!!errorMessage) errorMessage += "\n";
                    errorMessage += "Tier price per unit cannot be empty";
                    isValid = false;
                }

                //Check if price per unit only contain number or number with decimal
                var positiveNumberWithDecimalRegex =
                    "^[+]?([0-9][0-9]*(?:[.][0-9]*)?|0*.0*[1-9][0-9]*)$";
                if (
                    !tier.price_per_unit.match(positiveNumberWithDecimalRegex)
                ) {
                    //newValue contain alphabet or negative value
                    if (!!errorMessage) errorMessage += "\n";
                    errorMessage +=
                        "Tier price per unit can only be positive number with or without decimal";
                    isValid = false;
                } else {
                    if (!(parseFloat(tier.price_per_unit) > 0)) {
                        //newValue is less than equal zero
                        if (!!errorMessage) errorMessage += "\n";
                        errorMessage += "Tier price per unit cannot be zero";
                        isValid = false;
                    }
                }

                newAddonTierPricingModel.tiers[i]["error"]["price_per_unit"] =
                    errorMessage;
            });
        } else {
            //validate only the specific tier price per unit

            //Check empty
            if (newValue == "") {
                if (!!errorMessage) errorMessage += "\n";
                errorMessage += "Tier price per unit cannot be empty";
                isValid = false;
            }

            //Check if price per unit only contain number or number with decimal
            var positiveNumberWithDecimalRegex =
                "^[+]?([0-9][0-9]*(?:[.][0-9]*)?|0*.0*[1-9][0-9]*)$";
            if (!newValue.match(positiveNumberWithDecimalRegex)) {
                //newValue contain alphabet or negative value
                if (!!errorMessage) errorMessage += "\n";
                errorMessage +=
                    "Tier price per unit can only be positive number with or without decimal";
                isValid = false;
            } else {
                if (!(parseFloat(newValue) > 0)) {
                    //newValue is less than equal zero
                    if (!!errorMessage) errorMessage += "\n";
                    errorMessage += "Tier price per unit cannot be zero";
                    isValid = false;
                }
            }

            newAddonTierPricingModel.tiers[index]["error"]["price_per_unit"] =
                errorMessage;
        }

        setTierPricingModel(newAddonTierPricingModel); //Set Error message

        return isValid;
    };

    const navigateToTieredModelListingPage = () => {
        history.push("/admin/tieredmodel");
    };

    return (
        <div className={"container-fluid tiermodel-container"}>
            <div className={"container tiermodel-form-container"}>
                <h2
                    className="text-left color-contrast-dark p-3 mt-4"
                    style={{ color: "black" }}
                >
                    {readonly
                        ? "View"
                        : match.params.id == "new"
                        ? "New"
                        : "Edit"}{" "}
                    Tier Pricing Model
                </h2>
                <Grid container className="p-3" spacing={3}>
                    <Grid item xs={12}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <h6 className="text-left mt-4">
                                    Tiers Pricing Models
                                </h6>
                            </Grid>
                            {/* <Grid item xs={12}>
                                <TextField
                                    disabled={readonly}
                                    required
                                    id="tier-model-id"
                                    name="tier-model-id"
                                    label="Tier Pricing Model Id"
                                    fullWidth
                                    value={tierPricingModel.tiered_model_id.value}
                                    disabled={true}
                                />
                            </Grid> */}
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    id="tier-model-desc"
                                    name="tier-model-desc"
                                    label="Tier Pricing Model Description"
                                    error={
                                        !!tierPricingModel.tiered_model_desc
                                            .error
                                    }
                                    helperText={
                                        tierPricingModel.tiered_model_desc
                                            ?.error || ""
                                    }
                                    value={
                                        tierPricingModel.tiered_model_desc.value
                                    }
                                    onChange={(e) => {
                                        const newValue = e.target.value;

                                        if (
                                            validateTierDesc(newValue) ||
                                            newValue == ""
                                        )
                                            setTierPricingModel({
                                                ...tierPricingModel,
                                                tiered_model_desc: {
                                                    value: newValue,
                                                },
                                            });
                                    }}
                                    fullWidth
                                    multiline
                                    rows="8"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    id="tier-unit-name"
                                    name="tier-model-unit-name"
                                    label="Tier Name"
                                    error={!!tierPricingModel.unit.error}
                                    helperText={
                                        tierPricingModel.unit?.error || ""
                                    }
                                    value={tierPricingModel.unit.value}
                                    onChange={(e) => {
                                        const newValue = e.target.value;

                                        if (
                                            validateTierUnitName(newValue) ||
                                            newValue == ""
                                        )
                                            setTierPricingModel({
                                                ...tierPricingModel,
                                                unit: {
                                                    value: newValue,
                                                },
                                            });
                                    }}
                                    fullWidth
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    fullWidth
                                    id="effectiveDate"
                                    label="Tiered Model Effective DateTime"
                                    type="datetime-local"
                                    value={tierPricingModel.effective_date}
                                    onChange={(e) => {
                                        const newValue = e.target.value;
                                        setTierPricingModel({
                                            ...tierPricingModel,
                                            effective_date: newValue,
                                        });
                                    }}
                                    inputProps={{
                                        min: moment().format(
                                            "yyyy-MM-DDTHH:mm"
                                        ),
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <h6 className="text-left ">
                                    Tiers Information
                                </h6>
                            </Grid>
                            <Grid item xs={6} className="text-right">
                                <Button
                                    variant="outlined"
                                    size="small"
                                    onClick={generateAnotherRowForTierInput}
                                    startIcon={<AddIcon />}
                                >
                                    Add Tiers
                                </Button>
                            </Grid>
                        </Grid>

                        {tierPricingModel.tiers.map((tier, i) => {
                            return (
                                <Grid container key={i} spacing={4}>
                                    <Grid item xs={2}>
                                        <TextField
                                            required
                                            disabled
                                            id={`tierStartingUnit${i}`}
                                            name={`tierStartingUnit${i}`}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            label={"Tier Starting Unit"}
                                            value={tier.starting_unit ?? ""}
                                            fullWidth
                                            variant="outlined"
                                        />
                                    </Grid>
                                    <Grid item xs={1}>
                                        <div
                                            className="w-100 h-100"
                                            style={{
                                                fontSize: "15px",
                                                padding: "16px",
                                            }}
                                        >
                                            TO
                                        </div>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <TextField
                                            required
                                            id={`tierEndingUnit${i}`}
                                            className="new-line"
                                            name={`tierEndingUnit${i}`}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            placeholder={
                                                i ==
                                                tierPricingModel.tiers.length -
                                                    1
                                                    ? "& Above"
                                                    : "Specify unit"
                                            }
                                            label={"Tier Ending Unit"}
                                            value={tier.ending_unit}
                                            helperText={
                                                tier.error?.ending_unit || ""
                                            }
                                            error={!!tier.error?.ending_unit}
                                            onChange={(e) => {
                                                const newValue = e.target.value;
                                                const newAddonTierPricingModel =
                                                    {
                                                        ...tierPricingModel,
                                                    };

                                                var positiveNumberOnlyRegex =
                                                    "^[0-9]+$";
                                                if (
                                                    validateTierEndingUnit(
                                                        newValue,
                                                        i
                                                    ) ||
                                                    newValue == "" ||
                                                    newValue.match(
                                                        positiveNumberOnlyRegex
                                                    )
                                                ) {
                                                    newAddonTierPricingModel.tiers[
                                                        i
                                                    ]["ending_unit"] = newValue; //Update the new value
                                                    if (
                                                        !!newAddonTierPricingModel
                                                            .tiers[i + 1]
                                                    )
                                                        //If Next tier does exist
                                                        newAddonTierPricingModel.tiers[
                                                            i + 1
                                                        ]["starting_unit"] =
                                                            parseInt(newValue) +
                                                                1 || ""; // Update Next Tiers starting unit
                                                }

                                                setTierPricingModel(
                                                    newAddonTierPricingModel
                                                );
                                            }}
                                            fullWidth
                                            variant="outlined"
                                        />
                                    </Grid>
                                    <Grid item xs={1}>
                                        <div
                                            className="w-100 h-100"
                                            style={{ padding: "16px" }}
                                        >
                                            <TrendingFlatIcon />
                                        </div>
                                    </Grid>
                                    <Grid item xs={5}>
                                        <CurrencyTextField
                                            required
                                            className="new-line"
                                            id={`tier-price-${i}`}
                                            name={`tier-price-${i}`}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            label={`Tier Price Per ${tierPricingModel.unit.value}`}
                                            helperText={
                                                tier.error?.price_per_unit || ""
                                            }
                                            error={!!tier.error?.price_per_unit}
                                            value={tier.price_per_unit}
                                            digitGroupSeparator=""
                                            outputFormat="string"
                                            //need to configure with the global currency
                                            currencySymbol="RM"
                                            onChange={(e) => {
                                                const newValue = e.target.value;
                                                const newAddonTierPricingModel =
                                                    {
                                                        ...tierPricingModel,
                                                    };

                                                const regexStartWithZero =
                                                    "^0(.)?$";
                                                if (
                                                    validateTierPricePerUnit(
                                                        newValue,
                                                        i
                                                    ) ||
                                                    newValue == "" ||
                                                    newValue.match(
                                                        regexStartWithZero
                                                    )
                                                ) {
                                                    //VALUE UPDATE
                                                    newAddonTierPricingModel.tiers[
                                                        i
                                                    ]["price_per_unit"] =
                                                        newValue;
                                                }

                                                setTierPricingModel(
                                                    newAddonTierPricingModel
                                                );
                                            }}
                                            fullWidth
                                            variant="outlined"
                                        />
                                    </Grid>
                                    <Grid item xs={1}>
                                        {
                                            //Allow Remove If it is third or after third element
                                            i > 1 ? (
                                                <IconButton
                                                    style={{ padding: "16px" }}
                                                    onClick={removeSingleTierInput.bind(
                                                        this,
                                                        i
                                                    )}
                                                    aria-label="delete"
                                                >
                                                    <ClearIcon />
                                                </IconButton>
                                            ) : (
                                                ""
                                            )
                                        }
                                    </Grid>
                                </Grid>
                            );
                        })}
                    </Grid>
                    <Grid item className="mt-3" xs={12}>
                        {match.params.id == "new" ? (
                            <Button
                                variant="contained"
                                style={{
                                    backgroundColor: "black",
                                    color: "white",
                                }}
                                onClick={createNewTierPricingModelInServer}
                                endIcon={<AddIcon></AddIcon>}
                            >
                                Create Tiered Pricing Model
                            </Button>
                        ) : (
                            <Button
                                variant="contained"
                                style={{
                                    backgroundColor: "#7A53EA",
                                    color: "white",
                                }}
                                onClick={editNewTierPricingModelInServer}
                                endIcon={<EditIcon></EditIcon>}
                            >
                                Edit Tiered Pricing Model
                            </Button>
                        )}
                        <Button
                            className="ml-4"
                            variant="contained"
                            onClick={redirectToTieredModelListingPage}
                        >
                            Cancel
                        </Button>
                    </Grid>
                </Grid>
            </div>
            <ToastContainer />
        </div>
    );
};

export default TieredPricingModelEditCreate;
