import { callAPI } from "../../utils/apiUtils";

const promotionService = {
    getPromotionListing: (pageIndex = 1, search = "") => {
        return new Promise((resolve, reject) => {
            callAPI(
                `/promotion/list?page=${pageIndex}&search=${search}&promo_status__in=active,archived,expired`
            )
                .then((response) => {
                    resolve(response);
                })
                .catch((response) => reject(response));
        });
    },
    getAllPromoWithoutPagination: () => {
        return new Promise((resolve, reject) => {
            callAPI(`/promotion/list`)
                .then((response) => {
                    resolve(response);
                })
                .catch((response) => reject(response));
        });
    },
    getSinglePromotion: (promoId) => {
        return new Promise((resolve, reject) => {
            callAPI(`/promotion/${promoId}`, "get")
                .then((response) => {
                    resolve(response);
                })
                .catch((response) => reject(response));
        });
    },
    deletePromotion: (promoId) => {
        const dataBody = {
            promo_status: "deleted",
        };
        return new Promise((resolve, reject) => {
            callAPI(`/promotion/${promoId}`, "patch", dataBody)
                .then((response) => {
                    resolve(response);
                })
                .catch((response) => reject(response));
        });
    },
    createPromo: (dataBody) => {
        return new Promise((resolve, reject) => {
            callAPI(`/promotion/`, "post", dataBody)
                .then((response) => {
                    resolve(response);
                })
                .catch((response) => reject(response));
        });
    },
    updatePromotion: (promoId, dataBody) => {
        return new Promise((resolve, reject) => {
            callAPI(`/promotion/${promoId}`, "put", dataBody)
                .then((response) => {
                    resolve(response);
                })
                .catch((response) => reject(response));
        });
    },
    archivePromotion: (promoId) => {
        const dataBody = {
            promo_status: "archived",
        };
        return new Promise((resolve, reject) => {
            callAPI(`/promotion/${promoId}`, "patch", dataBody)
                .then((response) => {
                    resolve(response);
                })
                .catch((response) => reject(response));
        });
    },
    reactivatePromotion: (promoId) => {
        const dataBody = {
            promo_status: "active",
        };
        return new Promise((resolve, reject) => {
            callAPI(`/promotion/${promoId}`, "patch", dataBody)
                .then((response) => {
                    resolve(response);
                })
                .catch((response) => reject(response));
        });
    },
    validatePromo: (dataBody) => {
        return new Promise((resolve, reject) => {
            callAPI(`/promotion/apply`, "post", dataBody)
                .then((response) => {
                    resolve(response);
                })
                .catch((response) => reject(response));
        });
    },
};

export default promotionService;
