import { Grid } from "@material-ui/core";

import Button from "@material-ui/core/Button";

import React, { useEffect, useState } from "react";

import { Image } from "react-bootstrap";

import { useSelector } from "react-redux";

import { toast } from "react-toastify";

import { toggleColor, urlAction } from "../../action.js";

import CatalogCard from "../../Components/CatalogCard/catalogCard";

import LoginBar from "../../Components/LoginBar/loginbar";

import LoginModal from "../../Components/LoginModal";

import Sidebar from "../../Components/Sidebar/sidebar";

import { store } from "../../index.js";

import EditPlanLayout from "../../Components/EditPlanLayout";

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import planService from "../../services/planService";

import profileService from "../../services/profileService";

import getCroppedImgInFileObj, {
    readImageFileIntoDataUrl,
} from "../../utils/imageUtils";

import "./homepage.css";

import { CatchingPokemonSharp } from "@mui/icons-material";

import Swal from "sweetalert2";

const Homepage = ({ history, sidebarToggle }) => {
    const [showLoginModal, setShowLoginModal] = useState(false);

    const isLoggedIn = useSelector(
        (state) => state.loggedInUserReducer.userData
    );

    const [settingsId, setSettingsId] = useState();

    const [planDetails, setPlanDetails] = useState([]);

    const [logo, setLogo] = useState();

    const [editingPlanLayout, setEditingPlanLayout] = useState(false);

    const [toggleSidebar, setToggleSidebar] = useState(
        localStorage.getItem("sidebar_toggle")
    );

    const [items, setItems] = useState([{}]);

    const [planOrder, setPlanOrder] = useState([]);

    const [merchantName, setMerchantName] = useState();

    const fetchMerchantPlans = () => {
        planService

            .getAllPlansWithoutPagination(["active"])

            .then((res) => {
                const plansData = res.plans;

                // sort plansData by its updated_at field

                plansData.sort((a, b) => {
                    return new Date(b.updated_at) - new Date(a.updated_at);
                });

                //set all specified quantity to 1

                const modifiedPlansData = plansData.map((plan) => ({
                    planInfo: { ...plan },

                    specifiedQuantity: { value: "1" },
                }));

                setPlanDetails([...planDetails, ...modifiedPlansData]);

                profileService

                    .getProfile()

                    .then(async (res) => {
                        store.dispatch(
                            toggleColor(res.settings.primary_colour)
                        );

                        const profileData = res.settings;

                        const existingPlanImageInFileObj =
                            await getCroppedImgInFileObj(
                                `${
                                    window.location.protocol +
                                    "//" +
                                    window.location.hostname
                                }${process.env.REACT_APP_SITE_PORT_BACKEND}${
                                    profileData.company_logo
                                }`
                            );

                        const existingPlanImageFileInDataUrl =
                            await readImageFileIntoDataUrl(
                                existingPlanImageInFileObj
                            );

                        setSettingsId(res.settings.settings_id);

                        setPlanOrder(res.settings.plan_order);

                        setLogo(existingPlanImageFileInDataUrl);
                    })

                    .catch((res) => {});
            })

            .catch((res) => {
                toast.error(
                    <div>
                        Some Error Occur, Please try again later.
                        <br />
                        {JSON.stringify(res, null, 2)}
                        <br />
                    </div>,

                    {
                        position: "top-right",

                        autoClose: 5000,

                        hideProgressBar: false,

                        closeOnClick: true,

                        pauseOnHover: true,

                        draggable: true,

                        progress: undefined,
                    }
                );
            });
    };

    const savePlanLayout = (planId) => {
        var dataBody = {
            plan_order: planId,
        };

        profileService

            .updateProfile(settingsId, dataBody)

            .then((res) => {
                Swal.fire({
                    title: "Plan Layout Saved",

                    text: "Page will now refresh.",

                    icon: "success",

                    confirmButtonText: "OK",

                    showConfirmButton: true,

                    confirmButtonColor: store.getState().colorState.color,
                }).then((result) => {
                    window.location.reload();
                });
            })

            .catch((res) => {
                toast.error(
                    <div>
                        Some Error Occur, Please try again later.
                        <br />
                        {JSON.stringify(res, null, 2)}
                        <br />
                    </div>,

                    {
                        position: "top-right",

                        autoClose: 5000,

                        hideProgressBar: false,

                        closeOnClick: true,

                        pauseOnHover: true,

                        draggable: true,

                        progress: undefined,
                    }
                );
            });
    };

    useEffect(() => {
        sessionStorage.removeItem("stepper");

        sessionStorage.removeItem("defaultMenu");

        sessionStorage.removeItem("nonDefaultMenu");

        sessionStorage.removeItem("addon");

        setMerchantName(urlAction("get_merchant"));
        fetchMerchantPlans();
    }, []);

    // use effect to organize plan according to order

    useEffect(() => {
        if (planOrder.length > 0 && planOrder.length === planDetails.length) {
            const orderedPlans = planOrder.map((planId) => {
                return planDetails.find(
                    (plan) => plan.planInfo.plan_id === parseInt(planId)
                );
            });

            setPlanDetails(orderedPlans);
        }
    }, [planOrder]);

    const planDetailsWithOrder = planOrder.map((planId) => {
        const plan = planDetails.find(
            (plan) => plan.planInfo.plan_id === parseInt(planId)
        );

        return plan;
    });

    const reorganizePlanDetails = () => {
        const plan =
            planOrder.length > 0 && planOrder.length !== planDetails.length
                ? planDetails
                : planDetailsWithOrder;
        console.log(planOrder, "this is plan order");
        console.log(
            planDetails.length,
            " this is the lengths",
            planOrder.length
        );
        // map through planOrder and get the planDetails with the same plan_id
        const renderCard = plan.map((plan, index) => {
            return (
                <Grid
                    item
                    xs={12}
                    md={merchantName == "wildkombucha" ? 4 : 3}
                    lg={merchantName == "wildkombucha" ? 4 : 3}
                >
                    <CatalogCard planData={plan} />{" "}
                </Grid>
            );
        });
        return renderCard;
    };

    const renderGuestUser = () => {
        return (
            <Grid container>
                <Grid item align="center" xs={12} md={12} lg={12}>
                    <Image
                        src={logo}
                        onClick={() => {
                            history.push("/");
                        }}
                        style={{ marginLeft: "150px" }}
                        className="mt-3 homepage-logo"
                    />
                </Grid>

                {/* Log In button */}

                <Grid container className="mt-2">
                    <Grid className="" align="left" item xs={8} md={4} lg={4}>
                        <strong
                            className="select-plan-text"
                            style={{ fontSize: "33px" }}
                        >
                            Select a plan
                        </strong>
                    </Grid>

                    <Grid
                        item
                        className="mt-2"
                        align={"right"}
                        xs={4}
                        md={8}
                        lg={8}
                    >
                        {/* Get an icon for logo  */}{" "}
                        <Button
                            variant="contained"
                            // color="primary"

                            className=" login-button"
                            style={{
                                borderRadius: "10px",

                                color: store.getState().colorState.font_color,

                                backgroundColor:
                                    store.getState().colorState.color,
                            }}
                            onClick={() => setShowLoginModal(true)}
                        >
                            Login
                        </Button>
                        <LoginModal
                            show={showLoginModal}
                            handleClose={() => {
                                setShowLoginModal(false);
                            }}
                        />{" "}
                    </Grid>

                    <Grid
                        style={{ marginLeft: "10px" }}
                        item
                        xs={12}
                        md={12}
                        lg={12}
                    >
                        <Grid container>
                            {planDetails.map((plan, index) => {
                                return (
                                    <Grid
                                        item
                                        xs={12}
                                        md={
                                            merchantName == "wildkombucha"
                                                ? 4
                                                : 3
                                        }
                                        lg={
                                            merchantName == "wildkombucha"
                                                ? 4
                                                : 3
                                        }
                                    >
                                        <CatalogCard planData={plan} />{" "}
                                    </Grid>
                                );
                            })}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        );
    };

    const renderLoggedInUser = () => {
        return (
            <>
                {/* <div style={{ marginLeft: "250px", width: "250px" }}></div> */}

                <Grid
                    direction={window.innerWidth < 1200 ? "column" : null}
                    container
                >
                    <Grid item align="center" xs={12} md={12} lg={12}>
                        <Image
                            src={logo}
                            onClick={() => {
                                history.push("/");
                            }}
                            style={{ marginLeft: "150px" }}
                            className="mt-3 homepage-logo"
                        />
                    </Grid>

                    <Grid item className="p-3" xs={12} md={12} lg={12}>
                        {/* profile icon */}
                        <Grid
                            item
                            style={{ marginLeft: "20px" }}
                            xs={12}
                            md={12}
                            lg={12}
                        >
                            <Grid
                                container
                                style={{ marginTop: "20px" }}
                                className="ml-2"
                            >
                                {/* profile icon and username dropdown */}

                                <Grid
                                    align="left"
                                    style={{ display: "flex" }}
                                    item
                                    xs={6}
                                    md={6}
                                    lg={6}
                                >
                                    <h2
                                        className="text-left color-contrast-dark"
                                        style={{ color: "black" }}
                                    >
                                        <strong>Select a plan</strong>
                                    </h2>

                                    {/* EDIT BUTTON */}

                                    {store.getState().loggedInUserReducer
                                        .userData.userRole === "merchant" ? (
                                        editingPlanLayout === false ? (
                                            <Button
                                                variant="contained"
                                                style={{
                                                    borderRadius: "10px",

                                                    color: store.getState()
                                                        .colorState.font_color,

                                                    backgroundColor:
                                                        store.getState()
                                                            .colorState.color,

                                                    marginLeft: "10px",
                                                }}
                                                // color="primary"

                                                onClick={() => {
                                                    setEditingPlanLayout(true);
                                                }}
                                                className="h-75 mt-1"
                                            >
                                                Edit layout
                                            </Button>
                                        ) : (
                                            <>
                                                <Button
                                                    variant="contained"
                                                    style={{
                                                        borderRadius: "10px",

                                                        // color: store.getState()

                                                        //     .colorState.font_color,

                                                        // backgroundColor:

                                                        //     store.getState()

                                                        //         .colorState.color,

                                                        marginLeft: "10px",
                                                    }}
                                                    // color="primary"

                                                    onClick={() => {
                                                        setEditingPlanLayout(
                                                            false
                                                        );
                                                    }}
                                                    className="h-75 mt-1"
                                                >
                                                    Cancel
                                                </Button>

                                                <Button
                                                    variant="contained"
                                                    style={{
                                                        borderRadius: "10px",

                                                        color: store.getState()
                                                            .colorState
                                                            .font_color,

                                                        backgroundColor:
                                                            store.getState()
                                                                .colorState
                                                                .color,

                                                        marginLeft: "10px",
                                                    }}
                                                    // color="primary"

                                                    onClick={() => {
                                                        // setEditingPlanLayout(false);

                                                        // filter through items and get the plan id

                                                        let planId = items.map(
                                                            (item) => {
                                                                return item
                                                                    .content
                                                                    .props
                                                                    .planData
                                                                    .planInfo
                                                                    .plan_id;
                                                            }
                                                        );

                                                        // save plan order to backend merchant settings

                                                        savePlanLayout(planId);

                                                        // filter through items and find the plan id

                                                        // and set the new items
                                                    }}
                                                    className="h-75 mt-1"
                                                >
                                                    Save
                                                </Button>
                                            </>
                                        )
                                    ) : null}
                                </Grid>

                                {/* if merchant, add organizing package here  */}

                                <Grid item xs={6} md={6} lg={6} align="right">
                                    <LoginBar />
                                </Grid>
                            </Grid>
                        </Grid>{" "}
                        {store.getState().loggedInUserReducer.userData
                            .userRole === "merchant" && !!editingPlanLayout ? (
                            <EditPlanLayout
                                planData={planDetails}
                                items={items}
                                setItems={(e) => {
                                    setItems(e);
                                }}
                            />
                        ) : (
                            <Grid style={{ marginLeft: "10px" }} container>
                                {/* need to print two sections, display one and the reorganizing part */}

                                {/* 1 section is for the real display of the cards and the other one is to display the grids for edit */}

                                {/* need a button to control the redirection */}

                                {reorganizePlanDetails()}
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            </>
        );
    };

    return (
        <div style={{ marginBottom: "100px" }}>
            {isLoggedIn == undefined ? renderGuestUser() : renderLoggedInUser()}
        </div>
    );
};

export default Homepage;
