import { Grid, InputLabel } from "@material-ui/core";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import moment from "moment";
import DayPicker from "react-day-picker";
import "react-day-picker/lib/style.css";
import { timeFromInt } from "time-number";
import "./style.css";

const DeliveryInformation = ({
    deliveryInfo,
    deliveryDate,
    deliveryTime,
    billFreqPicked,
    subsType,
    customerSubsType,
    customerSubsTypeHandler,
    deliveryDateHandler,
    deliveryTimeHandler,
    billFreqPickedHandler,
}) => {
    const deliveryFrequencyOptions = {
        day: "Daily",
        week: "Every week",
        biweek: "Every 2 week",
        twenty_days: "Every 20 days",
        month: "Every month",
        quarter: "Every 3 months",
        biannum: "Every 6 months",
        annum: "Every year",
        all: "All at once",
    };
    const renderTimeText = (delivery, frequency) => {
        return (
            <>
                <Grid container>
                    <Grid
                        item
                        align={
                            window.innerWidth < 1200 ||
                            window.location.href.includes("ketoccino")
                                ? "center"
                                : "left"
                        }
                        xs={12}
                    >
                        <InputLabel
                            className={`${
                                window.innerWidth < 1200
                                    ? "text-center"
                                    : "text-left"
                            } order-container mt-3`}
                        >
                            Your{" "}
                            {customerSubsType === "instant_pay" ||
                            billFreqPicked === "all"
                                ? null
                                : "first"}{" "}
                            order will be delivered on:{" "}
                            <b style={{ color: "black" }}>
                                {delivery.format("DD/MM/YYYY")}
                            </b>{" "}
                            {deliveryTime.value !== undefined ? "at " : null}
                            <b style={{ color: "black" }}>
                                {deliveryTime.value}
                            </b>
                        </InputLabel>
                    </Grid>
                    {customerSubsType === "instant_pay" ||
                    billFreqPicked === "all" ? null : (
                        <Grid
                            item
                            align={
                                window.innerWidth < 1200 ||
                                window.location.href.includes("ketoccino")
                                    ? "center"
                                    : "left"
                            }
                            xs={12}
                        >
                            <InputLabel
                                className={`${
                                    window.innerWidth < 1200
                                        ? "text-center"
                                        : "text-left"
                                } order-container mt-3`}
                            >
                                Your second order will be delivered on: {"\n"}
                                <b style={{ color: "black" }}>
                                    {" "}
                                    {frequency.format("DD/MM/YYYY")}
                                </b>{" "}
                                {deliveryTime.value !== undefined
                                    ? "at "
                                    : null}
                                <b style={{ color: "black" }}>
                                    {deliveryTime.value}
                                </b>
                            </InputLabel>
                        </Grid>
                    )}
                </Grid>
            </>
        );
    };
    const renderDelivery = () => {
        var firstDelivery = moment(deliveryDate.value);
        var dailyDelivery = moment(deliveryDate.value).add(1, "days");
        var weekDelivery = moment(deliveryDate.value).add(7, "days");
        var biweekDelivery = moment(deliveryDate.value).add(14, "days");
        var twentyDaysDelivery = moment(deliveryDate.value).add(20, "days");
        var monthDelivery = moment(deliveryDate.value).add(28, "days");
        var halfyearDelivery = moment(deliveryDate.value).add(183, "days");
        var quarterDelivery = moment(deliveryDate.value).add(92, "days");
        var annumDelivery = moment(deliveryDate.value).add(365, "days");
        var biannumDelivery = moment(deliveryDate.value).add(730, "days");
        if (monthDelivery.isSame(firstDelivery, "month")) {
            monthDelivery = monthDelivery.add(7, "days");
        }
        switch (billFreqPicked) {
            case "day":
                return renderTimeText(firstDelivery, dailyDelivery);
            case "week":
                return renderTimeText(firstDelivery, weekDelivery);
            case "biweek":
                return renderTimeText(firstDelivery, biweekDelivery);
            case "twenty_days":
                return renderTimeText(firstDelivery, twentyDaysDelivery);
            default:
            case "month":
                return renderTimeText(firstDelivery, monthDelivery);
            case "half-year":
                return renderTimeText(firstDelivery, halfyearDelivery);
            case "quarter":
                return renderTimeText(firstDelivery, quarterDelivery);
            case "annum":
                return renderTimeText(firstDelivery, annumDelivery);
            case "biannum":
                return renderTimeText(firstDelivery, biannumDelivery);
        }
    };

    const renderDisabledDays = () => {
        const merchantDisabledDates = deliveryInfo?.value?.blocked_dates.map(
            (date) => {
                return new Date(date);
            }
        );
        const defaultConfig = [
            {
                before: new Date(Date.now() + 345686400),
            },
            {
                daysOfWeek: deliveryInfo.blockedDays,
            },
        ];
        if (merchantDisabledDates === undefined) {
            console.log(defaultConfig, "this is merge config");

            return defaultConfig;
        } else {
            const mergeConfig = merchantDisabledDates.concat(defaultConfig);
            console.log(mergeConfig, "this is merge config");

            return mergeConfig;
        }
    };
    const renderFirstAvailableDate = () => {
        const availableDays = [];
        // for loop in range of 6
        for (let i = 0; i < 6; i++) {
            // if i is not in blocked days push to availableDays
            if (!deliveryInfo?.blockedDays?.includes(i)) {
                availableDays.push(i);
            }
        }

        // add 4 days to today's date
        const firstAvailableDate = new Date(Date.now() + 345686400);
        // get firstavailabledate's day value
        const firstAvailableDay = firstAvailableDate.getDay();
        // if firstavailableday is in blockeddays, find the nearest value to firstavailableday in availabledays
        if (deliveryInfo?.blockedDays?.includes(firstAvailableDay)) {
            const nearestAvailableDay = availableDays.find(
                (day) => day > firstAvailableDay
            );
            // add nearestAvailableDay to firstavailabledate
            firstAvailableDate.setDate(
                firstAvailableDate.getDate() +
                    nearestAvailableDay -
                    firstAvailableDay
            );
        }
        sessionStorage.setItem(
            "deliveryDate",
            JSON.stringify(firstAvailableDate)
        );
        deliveryDateHandler(firstAvailableDate);

        return new Date(firstAvailableDate);
    };
    const renderSubscriptionType = () => {
        if (subsType === "recurring_one_off") {
            if (window.innerWidth < 1200) {
                return (
                    <Grid item className="mb-4 mt-2" xs={12} md={12} lg={12}>
                        <select
                            select
                            label="Subscription Type"
                            className="delivery-frequency-input"
                            onChange={(e) => {
                                customerSubsTypeHandler(e.target.value);
                            }}
                            style={{ width: "35%" }}
                            value={customerSubsType}
                        >
                            <option hidden>Select a subscription Type</option>
                            <option value="direct_debit">Recurring</option>
                            <option value="instant_pay">One-Off</option>
                        </select>
                    </Grid>
                );
            } else {
                return (
                    <Grid
                        item
                        align={window.innerWidth < 1200 ? "center" : "left"}
                        className="mb-4 "
                        xs={12}
                    >
                        {" "}
                        <h6 className="text-left">Subscription Type</h6>
                        <select
                            onChange={(e) => {
                                customerSubsTypeHandler(e.target.value);
                            }}
                            value={customerSubsType}
                            className="w-75"
                            style={{
                                width: "100%",
                                borderRadius: "5px",
                                border: "1px black solid",
                                height: "100%",
                            }}
                        >
                            <option hidden>Select a subscription type</option>
                            <option value="direct_debit">Recurring</option>
                            <option value="instant_pay">One-Off</option>
                        </select>
                    </Grid>
                );
            }
        }
    };

    const renderDeliveryFreq = () => {
        if (
            window.innerWidth < 1200 &&
            !window.location.href.includes("ketoccino") &&
            customerSubsType !== "instant_pay"
        ) {
            return (
                <Grid item className="mb-4 mt-2" xs={12} md={12} lg={12}>
                    <select
                        select
                        label="Delivery Frequency"
                        className="delivery-frequency-input "
                        onChange={(e) => {
                            billFreqPickedHandler(e.target.value);
                            sessionStorage.setItem(
                                "deliveryFrequency",
                                JSON.stringify(e.target.value)
                            );
                        }}
                        style={{ width: "35%" }}
                        defaultValue={() => {
                            if (billFreqPicked !== undefined) {
                                billFreqPickedHandler(deliveryInfo.billFreq[0]);
                                return deliveryInfo.billFreq[0];
                            } else {
                                return "month";
                            }
                        }}
                    >
                        <option hidden>Select a delivery frequency</option>;
                        {deliveryInfo.billFreq.map((data, index) => {
                            var newVal = data;
                            newVal = deliveryFrequencyOptions[newVal];
                            return (
                                <option selected key={index} value={data}>
                                    {/* {data.toUpperCase()}
                                                      {"ly"} */}
                                    {newVal.charAt(0).toUpperCase() +
                                        newVal.slice(1)}
                                </option>
                            );
                        })}
                    </select>
                </Grid>
            );
        } else if (
            !window.location.href.includes("ketoccino") &&
            customerSubsType !== "instant_pay"
        ) {
            return (
                <Grid
                    item
                    align={window.innerWidth < 1200 ? "center" : "left"}
                    className="mb-4 "
                    xs={12}
                >
                    {" "}
                    <h6 className="text-left">Delivery Frequency</h6>
                    <select
                        onChange={(e) => {
                            billFreqPickedHandler(e.target.value);
                            sessionStorage.setItem(
                                "deliveryFrequency",
                                JSON.stringify(e.target.value)
                            );
                        }}
                        value={billFreqPicked}
                        className="w-75"
                        label="Delivery Frequency"
                        style={{
                            width: "100%",
                            borderRadius: "5px",
                            border: "1px black solid",
                            height: "100%",
                        }}
                    >
                        <option hidden>Select a delivery frequency</option>
                        {deliveryInfo.billFreq !== null &&
                            deliveryInfo.billFreq.map((data, index) => {
                                var newVal = data;
                                newVal = deliveryFrequencyOptions[newVal];

                                return (
                                    <option key={index} value={data}>
                                        {newVal.charAt(0).toUpperCase() +
                                            newVal.slice(1)}
                                    </option>
                                );
                            })}
                    </select>
                </Grid>
            );
        }
    };

    const renderDeliveryTime = () => {
        if (window.innerWidth < 1200) {
            return (
                <Grid item className="mb-4" align="center" xs={12}>
                    <select
                        select
                        label="Delivery Time"
                        className="delivery-frequency-input"
                        onChange={(e) => {
                            deliveryTimeHandler(e.target.value);
                        }}
                        style={{ width: "35%" }}
                        defaultValue={deliveryTime}
                    >
                        <option hidden>Select a delivery time</option>
                        {deliveryInfo.value !== undefined
                            ? deliveryInfo.value.time.map((e) => {
                                  const startTime = timeFromInt(e.start_time, {
                                      format: 12,
                                  });
                                  const endTime = timeFromInt(e.end_time, {
                                      format: 12,
                                  });

                                  // start time to end time
                                  // append to tempdeliverytime
                                  const timeDisplay =
                                      startTime + " - " + endTime;

                                  return (
                                      <option value={timeDisplay}>
                                          {timeDisplay}
                                      </option>
                                  );
                              })
                            : null}
                    </select>
                </Grid>
            );
        } else {
            return (
                <Grid
                    item
                    className="mb-4"
                    align={
                        window.location.href.includes("ketoccino")
                            ? "center"
                            : "left"
                    }
                    xs={12}
                >
                    <h6 className="text-left">Delivery Time</h6>
                    <select
                        class="form-select"
                        aria-label="Default select example"
                        className={
                            window.location.href.includes("ketoccino")
                                ? "w-25"
                                : "w-75"
                        }
                        style={{
                            borderRadius: "5px",
                            border: "1px black solid",
                            height: "100%",
                        }}
                        value={deliveryTime.value}
                        onChange={(e) => {
                            deliveryTimeHandler(e.target.value);
                        }}
                    >
                        {" "}
                        {/* <option>Select a time</option> */}
                        <option hidden>Select a delivery time</option>;
                        {deliveryInfo?.value?.time.map((e) => {
                            const startTime = timeFromInt(e.start_time, {
                                format: 12,
                            });
                            const endTime = timeFromInt(e.end_time, {
                                format: 12,
                            });
                            // start time to end time
                            // append to tempdeliverytime
                            const timeDisplay = startTime + " - " + endTime;

                            return (
                                <option value={timeDisplay}>
                                    {timeDisplay}
                                </option>
                            );
                        })}
                    </select>
                </Grid>
            );
        }
    };

    return (
        <Box>
            <Card className="delivery-info-card" variant="outlined">
                <Grid className="mb-3" container>
                    <Grid
                        md={12}
                        sm={12}
                        xs={12}
                        className="p-3"
                        item
                        align="center"
                    >
                        <CardContent>
                            <h4>Delivery Date</h4>
                            <p>
                                Orders must be placed 4 days before delivery
                                date
                            </p>
                        </CardContent>
                    </Grid>
                    {deliveryInfo !== undefined ? (
                        <Grid
                            lg={
                                window.location.href.includes("ketoccino")
                                    ? 12
                                    : 6
                            }
                            md={12}
                            xs={12}
                            item
                        >
                            <div className="delivery-date-border mb-3 ">
                                {/* if smaller than 1200px, render mui component */}
                                <DayPicker
                                    selectedDays={
                                        deliveryDate.value === undefined
                                            ? renderFirstAvailableDate()
                                            : deliveryDate.value
                                    }
                                    onDayClick={(e) => {
                                        deliveryDateHandler(e);
                                    }}
                                    initialMonth={
                                        new Date(Date.now() + 345686400)
                                    }
                                    disabledDays={renderDisabledDays()}
                                />
                            </div>
                        </Grid>
                    ) : (
                        ""
                    )}

                    <Grid
                        lg={window.location.href.includes("ketoccino") ? 12 : 6}
                        md={12}
                        xs={12}
                        align="center"
                        item
                    >
                        {/* delivery frequency */}
                        {renderSubscriptionType()}
                        {console.log(
                            customerSubsType,
                            "this is the customer subs type"
                        )}
                        {deliveryInfo.billFreq === null
                            ? null
                            : renderDeliveryFreq()}
                        {deliveryInfo.value === null
                            ? null
                            : renderDeliveryTime()}

                        <Grid item xs={12}>
                            {/* delivery rendering */}
                            {deliveryDate !== undefined &&
                            deliveryTime !== undefined &&
                            billFreqPicked !== undefined
                                ? renderDelivery()
                                : ""}
                        </Grid>
                    </Grid>
                </Grid>
            </Card>
        </Box>
    );
};
export default DeliveryInformation;
