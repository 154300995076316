import {
    Checkbox,
    FormControlLabel,
    Grid,
    Input,
    ListItemText,
    MenuItem,
    Select,
} from "@material-ui/core";
import { store } from "../../../../index.js";
import Button from "@material-ui/core/Button";
import ClearIcon from "@material-ui/icons/Clear";
import PhotoLibraryIcon from "@material-ui/icons/PhotoLibrary";
import AddIcon from "@mui/icons-material/Add";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import BlockIcon from "@mui/icons-material/Block";
import DateRangeIcon from "@mui/icons-material/DateRange";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { ContentState, convertToRaw, EditorState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import { React, useEffect, useState } from "react";
import TimePicker from "react-bootstrap-time-picker";
import Card from "react-bootstrap/Card";
import CloseButton from "react-bootstrap/CloseButton";
import Form from "react-bootstrap/Form";
import Image from "react-bootstrap/Image";
import Modal from "react-bootstrap/Modal";
import "react-datepicker/dist/react-datepicker.css";
import DayPicker from "react-day-picker";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { toast, ToastContainer } from "react-toastify";
import Swal from "sweetalert2";
import { timeFromInt } from "time-number";
import ImageCropper from "../../../../Components/ImageCropper/index";
import LoginBar from "../../../../Components/LoginBar/loginbar";
import addOnService from "../../../../services/addOnService";
import planImagesService from "../../../../services/planImagesService";
import planService from "../../../../services/planService";
import tierPricingModelService from "../../../../services/tierPricingModelService";
import { readImageFileIntoDataUrl } from "../../../../utils/imageUtils";
import "./style.css";

const ProductForm = ({
    history,
    match,
    readonly = false,
    cleanBackground = false,
}) => {
    const [planData, setPlanData] = useState();
    const [planName, setPlanName] = useState("");
    const [planDescription, setPlanDescription] = useState();
    const [planPrice, setPlanPrice] = useState(0);
    const [deliveryFrequency, setDeliveryFrequency] = useState({ value: [] });
    const [planImage, setPlanImage] = useState("");
    const [planDuration, setPlanDuration] = useState(0);
    const [deliveryLeadTime, setDeliveryLeadTime] = useState(4);
    const [deliveryWindowId, setDeliveryWindowId] = useState();

    // const html = "<p>Hey this <strong>editor</strong> rocks 😀</p>";

    // const [contentBlock, setContentBlock] = useState(htmlToDraft(html));
    // const contentState = ContentState.createFromBlockArray(contentBlock);

    const [editor, setEditor] = useState({
        // editorState: EditorState.createWithContent(contentState),
    });

    const [openBlockModal, setOpenBlockModal] = useState(false);
    const [blockedDates, setBlockedDates] = useState({ value: [] });
    const [paymentOption, setPaymentOption] = useState("recurring");
    const [fetchAddon, setFetchAddon] = useState(false);
    const [hasMenu, setHasMenu] = useState(false);
    const [menuRestrictions, setMenuRestrictions] = useState({
        applied: false,
        restriction_type: "all",
        restriction_value: {},
    });
    const [menuSetup, setMenuSetup] = useState({
        display_name: {
            default_menu_name: "",
            non_default_menu_name: "",
            side_order_name: "",
        },
        quantity_selection: {
            default_menu_quantity: false,
            non_default_menu_quantity: false,
            side_order_quantity: false,
        },
    });
    const [defaultMenuTitle, setDefaultMenuTitle] = useState(null);
    const [pricingModel, setPricingModel] = useState("flat_fee");
    const [SST, setSST] = useState(false);
    const [expanded, setExpanded] = useState(null);
    const [openMenuModal, setOpenMenuModal] = useState(false);
    const [isDeliverable, setIsDeliverable] = useState(false);
    const [openDeliveryModal, setOpenDeliveryModal] = useState(false);
    const [existingPropertyModel, setExistingPropertyModel] = useState(null);
    const [newAddon, setNewAddon] = useState();
    const [addonOptions, setAddonOptions] = useState([]);
    const [selectedDefaultMenu, setSelectedDefaultMenu] = useState({
        value: [],
    });
    const [selectedNonDefaultMenu, setSelectedNonDefaultMenu] = useState({
        value: [],
    });
    const [selectedSideOrder, setSelectedSideOrder] = useState({ value: [] });
    const [propertyPricingOptions, setPropertyPricingOptions] = useState();
    const [propertyModel, setPropertyModel] = useState({
        id: null,
        value: null,
        attribute: "",
        name: [],
        price: [],
    });
    const [deliveryWindowTime, setDeliveryWindowTime] = useState({
        show: false,
        value: [
            // ["2018-07-25 20:23:22", new Date()],
        ],
    });
    const [imageCropModel, setImageCropModel] = useState({
        isOpened: false,
        selectedImageDataUrl: null,
        crop: { x: 0, y: 0 },
        rotation: 0,
        zoom: 0.4,
        aspect: 1 / 1,
        croppedAreaPixels: null,
        croppedImage: null,
    });
    const [previewImg, setPreviewImg] = useState([]);
    const [deliveryDaySelection, setDeliveryDaySelection] = useState({
        value: {
            Sunday: false,
            Monday: false,
            Tuesday: false,
            Wednesday: false,
            Thursday: false,
            Friday: false,
            Saturday: false,
        },
    });
    const deliveryFrequencyOptions = {
        day: "Daily",
        week: "Every week",
        biweek: "Every 2 weeks",
        twenty_days: "Every 20 days",
        month: "Every month",
        quarter: "Every 3 months",
        biannum: "Every 6 months",
        annum: "Every year",
        all: "All at once",
    };
    useEffect(() => {
        tierPricingModelService
            .getAllPropertyPricing()
            .then((res) => {
                setPropertyPricingOptions(res.model);
            })
            .catch((e) => {});
    }, [propertyModel.id]);

    useEffect(() => {
        addOnService
            .getAllAddonWithoutPagination(["active"])
            .then((res) => {
                setAddonOptions(res.addons);
                setFetchAddon(false);
            })
            .catch((res) => {
                console.error(res);
            });
    }, [fetchAddon]);

    useEffect(() => {
        if (match.params.id !== "new") {
            fetchCurrentPlan(match.params.id);
        }
    }, []);

    const uploadImage = async (data) => {
        const imageFiles = data.map((e) => {
            return e.imageFile;
        });
        var dataBody = {
            image_name: imageFiles,
        };
        const sendImage = await planImagesService
            .createPlanImage(dataBody)
            .then((res) => {
                const imageId = res.data.map((data) => {
                    return data.image_id;
                });
                return imageId;
            })
            .catch((response) => console.error(response));
        return sendImage;
    };

    const fetchCurrentPlan = async () => {
        await planService.getSinglePlan(match.params.id).then((res) => {
            const planDetails = res.plans;
            const descArr = [];
            // planDetails.plan_desc.map((textBlock) => {
            //     descArr.push(`<p>${textBlock}</p>`);
            // });
            // const html = descArr.join("");
            const contentBlock = htmlToDraft(planDetails.plan_desc);
            const contentState = ContentState.createFromBlockArray(
                contentBlock.contentBlocks
            );
            setPlanDescription(EditorState.createWithContent(contentState));
            setPlanData(planDetails);
            setPlanName(planDetails.plan_name);

            const imagePreview = res.plans.plan_image.map((image) => {
                return {
                    imagePreview: `${
                        window.location.protocol +
                        "//" +
                        window.location.hostname
                    }${process.env.REACT_APP_SITE_PORT_BACKEND}${
                        image.image_name
                    }`,
                };
            });

            const imageId = res.plans.plan_image.map((image) => {
                return image.image_id;
            });
            setPlanImage(imageId);
            setPreviewImg(imagePreview);
            setPaymentOption(planDetails.payment_option);
            setDeliveryFrequency({
                value:
                    planDetails.delivery_frequency !== null
                        ? planDetails.delivery_frequency
                        : [],
            });
            setPricingModel(planDetails.pricing_model);
            if (planDetails.pricing_model === "property") {
                // filter property_name from planDetails.property_model.property
                const propertyName = planDetails.property_model.property.map(
                    (property) => {
                        return property.property_name;
                    }
                );
                const propertyPrice = planDetails.property_model.property.map(
                    (property) => {
                        return property.property_price;
                    }
                );
                setExistingPropertyModel(true);
                setPropertyModel({
                    id: planDetails.property_model.property_model_id,
                    attribute: planDetails.property_model.property_model_name,
                    value: planDetails.property_model.property_quantity,
                    name: propertyName,
                    price: propertyPrice,
                });
            }
            setSST(planDetails.sales_services_tax);
            if (planDetails.plan_price !== null) {
                setPlanPrice(planDetails.plan_price);
            }
            if (planDetails.plan_duration !== null) {
                setPlanDuration(planDetails.plan_duration);
            }
            if (planDetails.is_shippable == true) {
                setIsDeliverable(planDetails.is_shippable);
            }
            // map planDetails.delivery_window.day and assign
            if (planDetails.delivery_window !== null) {
                setDeliveryLeadTime(planDetails.delivery_lead_time);
                setDeliveryWindowId(
                    planDetails.delivery_window.delivery_window_id
                );
                setDeliveryWindowTime({
                    ...deliveryWindowTime,
                    value: planDetails.delivery_window.time,
                });
                let deliveryWindow = {};
                Object.keys(planDetails.delivery_window.day).map((key) => {
                    let currentDay;

                    if (key == 0) {
                        currentDay = "Sunday";
                    } else if (key == 1) {
                        currentDay = "Monday";
                    } else if (key == 2) {
                        currentDay = "Tuesday";
                    } else if (key == 3) {
                        currentDay = "Wednesday";
                    } else if (key == 4) {
                        currentDay = "Thursday";
                    } else if (key == 5) {
                        currentDay = "Friday";
                    } else if (key == 6) {
                        currentDay = "Saturday";
                    }

                    deliveryWindow[currentDay] =
                        planDetails.delivery_window.day[key];
                });
                setDeliveryDaySelection({ value: deliveryWindow });
            }

            let defaultMenu = [];
            let nonDefaultMenu = [];
            let sideOrder = [];
            planDetails.default_menu.map((menu) => {
                defaultMenu.push(menu.addon_id);
            });
            planDetails.non_default_menu.map((menu) => {
                nonDefaultMenu.push(menu.addon_id);
            });
            planDetails.side_order.map((menu) => {
                sideOrder.push(menu.addon_id);
            });
            if (
                planDetails.default_menu.length > 0 ||
                planDetails.non_default_menu.length > 0 ||
                planDetails.side_order.length > 0
            ) {
                setHasMenu(true);
                setSelectedDefaultMenu({ value: defaultMenu });
                setSelectedNonDefaultMenu({ value: nonDefaultMenu });
                setSelectedSideOrder({ value: sideOrder });
                setMenuSetup(planDetails.menu_setup);
                if (planDetails.menu_restrictions.length !== 0) {
                    setMenuRestrictions(planDetails.menu_restrictions);
                }
            }
            // setDeliveryDaySelection({ value: planDetails.delivery_window.day });
        });
    };
    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };
    const AccordionSummary = styled((props) => (
        <MuiAccordionSummary
            expandIcon={
                <ArrowForwardIosIcon
                    sx={{ fill: "white", fontSize: "0.9rem" }}
                />
            }
            {...props}
        />
    ))(({ theme }) => ({
        color: "white",
        borderRadius: "5px",
        backgroundColor: "black",
        marginBottom: "10px",
        "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
            transform: "rotate(90deg)",
        },
        "& .MuiAccordionSummary-content": {
            marginLeft: theme.spacing(1),
        },
    }));
    const Accordion = styled((props) => (
        <MuiAccordion
            className="mb-3"
            disableGutters
            elevation={0}
            square
            {...props}
        />
    ))(({ theme }) => ({
        border: `0px`,
        "&:not(:last-child)": {
            borderBottom: 0,
        },
        "&:before": {
            display: "none",
        },
    }));
    const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
        padding: theme.spacing(2),
        border: "0px",
    }));

    const createNewPropertyPricing = () => {
        // example json databody for creating new property pricing model
        // {
        //     "property_model_name":"Size",
        //     "property_quantity": 3,
        //     "merchant_id": 2,
        //     "property_value": ["S","M","L"],
        //     "property_price": [5.0,10.0,15.0]
        // }

        // Name 0: "S"
        // Name 1: "M"
        // Name 2: "L"
        // Price 0: "5"
        // Price 1: "10"
        // Price 2: "15"
        // attribute: "Size"

        // Name: ["S","M","L"]
        // Price: ["5","10","15"]
        var dataBody = {
            property_model_name: propertyModel.attribute,
            property_quantity: propertyModel.value,
            property_value: propertyModel.name,
            property_price: propertyModel.price,
            // merchant_id: merchantInfo[0],
        };
        tierPricingModelService
            .createNewPropertyPricingModel(dataBody)
            .then((res) => {
                setPropertyModel({
                    ...propertyModel,
                    id: res.PropertyModel.property_model_id,
                });
                setExistingPropertyModel(true);

                toast.success("Successfully created property model", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            })
            .catch((e) => {
                console.error(e);
            });
    };

    const createNewItem = () => {
        var dataBody = {
            addon_name: newAddon.name,
            addon_desc: newAddon.description,
            addon_price: newAddon.price,
        };

        addOnService
            .createAddon(dataBody)
            .then((res) => {
                toast.success("Successfully created new addon", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                setFetchAddon(true);
            })
            .catch((res) => {
                console.error(res, "this is the error");
                toast.error(
                    <div>
                        Some Error Occur, Please try again later.
                        <br />
                        {JSON.stringify(res, null, 2)}
                        <br />
                    </div>,
                    {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    }
                );
            });
    };

    const renderPricingModel = () => {
        if (pricingModel == "flat_fee") {
            return (
                <Grid item xs={9} md={9} lg={7}>
                    <Form.Label className="mt-3">Plan Price</Form.Label>
                    <Form.Control
                        type="number"
                        value={planPrice}
                        onChange={(e) => {
                            setPlanPrice(e.target.value);
                        }}
                    />
                </Grid>
            );
        } else if (pricingModel == "property") {
            return (
                <>
                    <Grid item className="mt-4" xs={12} lg={12} md={12}>
                        <h4
                            className="color-contrast-dark "
                            style={{ color: "black" }}
                        >
                            <strong>Property Pricing Setup</strong>
                        </h4>
                    </Grid>
                    <Grid item xs={12} md={12} lg={12}>
                        <Grid container>
                            <Form.Check
                                type="radio"
                                label="Select from existing property models"
                                className="mt-3"
                                checked={existingPropertyModel}
                                onChange={() => {
                                    setPropertyModel({
                                        id: "",
                                        value: "",
                                        attribute: "",
                                        name: [],
                                        price: [],
                                    });
                                    setExistingPropertyModel(true);
                                }}
                            />
                            <Form.Check
                                type="radio"
                                label="Create new property pricing model"
                                className="mt-3 ml-3"
                                style={{ marginLeft: "10px" }}
                                checked={!existingPropertyModel}
                                onChange={() => {
                                    setPropertyModel({
                                        id: "",
                                        value: "",
                                        attribute: "",
                                        name: [],
                                        price: [],
                                    });
                                    setExistingPropertyModel(false);
                                }}
                            />
                        </Grid>
                    </Grid>
                    {existingPropertyModel
                        ? renderExistingPricingModel()
                        : renderNewPricingModel()}
                </>
            );
        }
    };
    const deliveryDatePreview = () => {
        return (
            <>
                <Modal
                    show={openBlockModal}
                    size="lg"
                    onHide={() => {
                        setOpenBlockModal(false);
                    }}
                    animation={false}
                    centered
                    dialogClassName="delivery-window-modal"
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Blocked dates configuration</Modal.Title>
                    </Modal.Header>
                    <Grid container>
                        <Grid item xs={6}>
                            {" "}
                            <Modal.Body>
                                <Grid container>
                                    <Grid item xs={12} align="center">
                                        <DayPicker
                                            // selecteddays change to array and blockdate variable
                                            selectedDays={blockedDates.value}
                                            onDayClick={(e) => {
                                                // if array is empty then add days
                                                // else if already selected then remove days
                                                // else add days
                                                const tempBlockedDates =
                                                    blockedDates.value;

                                                const someTemp =
                                                    tempBlockedDates.some(
                                                        (tempDate) => {
                                                            return (
                                                                tempDate.getDate() ===
                                                                e.getDate()
                                                            );
                                                        }
                                                    );

                                                if (
                                                    tempBlockedDates.length ===
                                                    0
                                                ) {
                                                    tempBlockedDates.push(e);
                                                } else if (someTemp) {
                                                    tempBlockedDates.splice(
                                                        tempBlockedDates.indexOf(
                                                            e
                                                        ),
                                                        1
                                                    );
                                                } else {
                                                    tempBlockedDates.push(e);
                                                }
                                                setBlockedDates({
                                                    ...blockedDates,
                                                    value: tempBlockedDates,
                                                });
                                            }}
                                            initialMonth={new Date()}
                                            disabledDays={[
                                                // Set a limit to the delivery range made available for the customer
                                                // (e.g: can only choose within this month)
                                                // To make sure that the mandate is processed within one week
                                                {
                                                    before: new Date(
                                                        Date.now() + 345686400
                                                    ),
                                                },
                                                {
                                                    daysOfWeek:
                                                        calculateBlockedDays(),
                                                },
                                            ]}
                                        />
                                    </Grid>
                                </Grid>

                                {/* if date is chosen on top, render it here, based on the array */}
                            </Modal.Body>
                        </Grid>
                        <Grid item xs={6}>
                            <Modal.Body>
                                <Grid item xs={12}>
                                    Blocked dates
                                </Grid>
                                <Grid item className="mt-2" xs={12}>
                                    {/* render blocked dates with number */}
                                    {blockedDates.value.map(
                                        (template, index) => {
                                            return (
                                                <p key={index}>
                                                    {index + 1}
                                                    {". "}{" "}
                                                    {template.toLocaleDateString()}
                                                </p>
                                            );
                                        }
                                    )}
                                </Grid>
                            </Modal.Body>
                        </Grid>
                    </Grid>

                    <Modal.Footer></Modal.Footer>
                    <Modal.Body>
                        <Grid container>
                            <Grid item align="right" xs={12}>
                                {/* apply changes button and cancel button */}
                                <Button
                                    variant="contained"
                                    style={{
                                        backgroundColor: "black",
                                        color: "white",
                                    }}
                                    onClick={() => {
                                        // patch delivery window in database
                                        planService
                                            .patchDeliveryWindow(
                                                deliveryWindowId,
                                                {
                                                    blocked_dates:
                                                        blockedDates.value,
                                                }
                                            )
                                            .then((res) => {
                                                toast.success(
                                                    "Blocked dates updated"
                                                );
                                                setOpenBlockModal(false);
                                            })
                                            .catch((err) => {
                                                toast.error(
                                                    "Failed to update blocked dates"
                                                );
                                            });

                                        // setOpenDeliveryDatePreview(false);
                                    }}
                                    endIcon={<BlockIcon></BlockIcon>}
                                >
                                    Apply changes
                                </Button>
                            </Grid>
                        </Grid>
                    </Modal.Body>
                </Modal>
            </>
        );
    };
    const renderTimeSlot = () => {
        return (
            <>
                <Grid container xs={12} md={12} lg={12}>
                    {deliveryWindowTime.value.map((data, index) => {
                        return (
                            <Grid item className="mt-3" xs={12} md={12} lg={12}>
                                <Grid container>
                                    <Grid item xs={3} md={3} lg={3}>
                                        <TimePicker
                                            value={data["start_time"]}
                                            onChange={(time) => {
                                                // get current date
                                                const temp =
                                                    deliveryWindowTime.value;
                                                temp[index]["start_time"] =
                                                    time;
                                                setDeliveryWindowTime({
                                                    ...deliveryWindowTime,
                                                    value: temp,
                                                });
                                            }}
                                            start="00:00"
                                            end="24:00"
                                            step={30}
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        align="center"
                                        xs={3}
                                        md={3}
                                        lg={3}
                                    >
                                        <Form.Label
                                            className="text-center mt-2"
                                            style={{
                                                fontSize: "12px",
                                                margin: "auto",
                                            }}
                                        >
                                            To
                                        </Form.Label>
                                    </Grid>
                                    <Grid item xs={3} md={3} lg={3}>
                                        <TimePicker
                                            onChange={(date) => {
                                                const tempDelivery =
                                                    deliveryWindowTime.value;
                                                const deliverySlot = data;
                                                deliverySlot["end_time"] = date;
                                                tempDelivery[index] =
                                                    deliverySlot;
                                                setDeliveryWindowTime({
                                                    ...deliveryWindowTime,
                                                    value: tempDelivery,
                                                });
                                            }}
                                            value={data["end_time"]}
                                            start="00:00"
                                            end="24:00"
                                            step={30}
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                        }}
                                        onClick={(e) => {
                                            const tempDelivery =
                                                deliveryWindowTime.value;
                                            tempDelivery.splice(index, 1);
                                            setDeliveryWindowTime({
                                                ...deliveryWindowTime,
                                                value: tempDelivery,
                                            });
                                        }}
                                        xs={3}
                                        md={3}
                                        lg={3}
                                    >
                                        <ClearIcon className="ml-2 clear-icon"></ClearIcon>
                                    </Grid>
                                </Grid>
                            </Grid>
                        );
                    })}
                </Grid>
            </>
        );
    };

    const menuDetails = (menu, setMenu) => {
        return (
            <>
                <Form.Label className=" mt-3">Menu selection</Form.Label>
                <Form.Group>
                    <Select
                        multiple
                        value={menu.value}
                        onChange={(event) => {
                            var selected = event.target.value;
                            setMenu({
                                ...menu,
                                value: selected,
                            });
                        }}
                        input={<Input />}
                        renderValue={(selectedAddonIds) => {
                            var displayText = "";
                            selectedAddonIds.forEach((selectedAddonId) => {
                                displayText +=
                                    addonOptions.find(
                                        (addon) =>
                                            addon?.addon_id == selectedAddonId
                                    )?.addon_name + ", ";
                            });

                            return displayText;
                        }}
                        style={{ width: "50%" }}
                        MenuProps={{
                            anchorOrigin: {
                                vertical: "top",
                                horizontal: "right",
                            },
                            transformOrigin: {
                                vertical: "top",
                                horizontal: "right",
                            },
                            keepMounted: true,
                            getContentAnchorEl: null,

                            PaperProps: {
                                style: {
                                    maxHeight: 200,
                                    minWidth: "200px",
                                },
                            },
                        }}
                    >
                        {addonOptions &&
                            addonOptions.map((item) => {
                                return (
                                    <MenuItem
                                        color="primary"
                                        key={item.addon_id}
                                        value={item.addon_id}
                                    >
                                        <Checkbox
                                            color="primary"
                                            checked={
                                                menu.value.indexOf(
                                                    item.addon_id
                                                ) > -1
                                            }
                                        />
                                        <ListItemText
                                            primary={item.addon_name}
                                        />
                                    </MenuItem>
                                );
                            })}
                    </Select>
                </Form.Group>
            </>
        );
    };
    const menuCreationModal = () => {
        return (
            <Modal
                show={openMenuModal}
                onHide={() => {
                    setOpenMenuModal(false);
                }}
                animation={false}
                centered
                dialogClassName="addon-create-modal"
                size="lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title>Create New Item</Modal.Title>
                </Modal.Header>
                <Modal.Body className="ml-5 mr-5 mb-3">
                    <Grid container>
                        <Grid item xs={12} md={12} lg={12}>
                            <h5>
                                {" "}
                                <strong>New Item </strong>
                            </h5>
                        </Grid>
                        <Grid item xs={12} md={12} lg={12}>
                            <Form.Label className="mt-2">Item Name</Form.Label>
                            <Form.Control
                                onChange={(e) => {
                                    setNewAddon({
                                        ...newAddon,
                                        name: e.target.value,
                                    });
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={12} lg={12}>
                            <Form.Label className="mt-3">
                                Item Description
                            </Form.Label>
                            <Form.Control
                                onChange={(e) => {
                                    setNewAddon({
                                        ...newAddon,
                                        description: e.target.value,
                                    });
                                }}
                            />
                        </Grid>

                        <Grid item xs={12} md={12} lg={12}>
                            <Form.Label className="mt-3">Item Price</Form.Label>
                            <Form.Control
                                type="number"
                                onChange={(e) => {
                                    setNewAddon({
                                        ...newAddon,
                                        price: e.target.value,
                                    });
                                }}
                            />
                        </Grid>
                        <Grid item className="mt-5" xs={12} md={12} lg={12}>
                            <Grid container>
                                {/* create button left is cancel button and right is create addon using grid */}
                                <Grid item align="right" xs={6} md={6} lg={6}>
                                    <Button
                                        variant="contained"
                                        onClick={() => {
                                            setOpenMenuModal(false);
                                        }}
                                    >
                                        Cancel
                                    </Button>
                                </Grid>
                                <Grid item xs={6} md={6} lg={6}>
                                    <Button
                                        variant="contained"
                                        style={{
                                            backgroundColor: "black",
                                            color: "white",
                                        }}
                                        className="ml-3 "
                                        onClick={() => {
                                            createNewItem();
                                            setOpenMenuModal(false);
                                        }}
                                    >
                                        Create Item
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Modal.Body>
            </Modal>
        );
    };
    const deliveryWindowModal = () => {
        return (
            <Modal
                show={openDeliveryModal}
                onHide={() => {
                    setOpenDeliveryModal(false);
                }}
                animation={false}
                centered
                dialogClassName="delivery-window-modal"
            >
                <Modal.Header closeButton>
                    <Modal.Title>Delivery Window Configuration</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {/* 
                    1. 3 Grid here
                        a. title
                        b. radio button for selecting days
                        c. delivery times apply
                     */}
                    <Grid container>
                        <Grid item xs={12}>
                            {" "}
                            <p>Repeat every week on</p>
                        </Grid>
                        <Grid item xs={12}>
                            {Object.keys(deliveryDaySelection.value).map(
                                (day, index) => {
                                    return (
                                        <Button
                                            className={`delivery-day-config-button${
                                                deliveryDaySelection.value[
                                                    day
                                                ] == true
                                                    ? "-active"
                                                    : ""
                                            }  `}
                                            onClick={(e) => {
                                                const tempSelection =
                                                    deliveryDaySelection.value;
                                                const currentBool =
                                                    tempSelection[day];

                                                if (currentBool == true) {
                                                    tempSelection[day] = false;
                                                } else {
                                                    tempSelection[day] = true;
                                                }
                                                setDeliveryDaySelection({
                                                    ...deliveryDaySelection,
                                                    value: tempSelection,
                                                });
                                            }}
                                            value={day}
                                        >
                                            {day[0]}
                                        </Button>
                                    );
                                }
                            )}
                        </Grid>
                        <Grid item xs={12} className="mt-1">
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        onChange={(e) => {
                                            const checker = e.target.checked;
                                            // if true, set value, set value prior first
                                            // false, just hide value
                                            if (checker) {
                                                const tempDelivery =
                                                    deliveryWindowTime.value;
                                                tempDelivery.push({
                                                    start_time: 0,
                                                    end_time: 0,
                                                });
                                                setDeliveryWindowTime({
                                                    ...deliveryWindowTime,
                                                    value: tempDelivery,
                                                    show: true,
                                                });
                                            } else {
                                                setDeliveryWindowTime({
                                                    ...deliveryWindowTime,
                                                    show: false,
                                                });
                                            }
                                        }}
                                        checked={deliveryWindowTime.show}
                                        style={{ color: "black" }}
                                    />
                                }
                                className="mt-3"
                                label={
                                    <span style={{ fontSize: "1rem" }}>
                                        Delivery times apply
                                    </span>
                                }
                            />
                        </Grid>
                    </Grid>
                    {deliveryWindowTime.show == true ? renderTimeSlot() : ""}
                    {deliveryWindowTime.show == true ? (
                        <Grid item className="mt-3" xs={12}>
                            {/* add new time slot button */}
                            {/*  */}
                            <Button
                                variant="contained"
                                onClick={() => {
                                    // add time slot
                                    const tempDelivery =
                                        deliveryWindowTime.value;
                                    tempDelivery.push({
                                        start_time: 0,
                                        end_time: 0,
                                    });
                                    setDeliveryWindowTime({
                                        ...deliveryWindowTime,
                                        value: tempDelivery,
                                    });
                                }}
                                style={{
                                    backgroundColor: "black",
                                    color: "white",
                                }}
                                endIcon={<AddIcon></AddIcon>}
                            >
                                Add time slot
                            </Button>
                        </Grid>
                    ) : (
                        ""
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        className=" mr-3"
                        style={{
                            backgroundColor: "black",
                            color: "white",
                        }}
                        onClick={() => {
                            setOpenDeliveryModal(false);
                        }}
                    >
                        Close
                    </Button>
                    <Button
                        variant="primary"
                        style={{
                            backgroundColor: "black",
                            color: "white",
                        }}
                        onClick={() => {
                            // append data here to submit to server
                            const dataBody = {
                                day: deliveryDaySelection.value,
                                merchant_id: 2,
                                time: deliveryWindowTime.value,
                            };
                            planService
                                .createDeliveryWindow(dataBody)
                                .then((e) => {
                                    toast.success("Delivery window created", {
                                        position: "top-right",
                                        autoClose: 5000,
                                        hideProgressBar: false,
                                        closeOnClick: true,
                                        pauseOnHover: true,
                                        draggable: true,
                                        progress: undefined,
                                    });
                                    setDeliveryWindowId(e.delivery_window_id);
                                })
                                .catch((e) => {
                                    toast.error(
                                        "Error occured while trying to create delivery window, please try again later",
                                        {
                                            position: "top-right",
                                            autoClose: 5000,
                                            hideProgressBar: false,
                                            closeOnClick: true,
                                            pauseOnHover: true,
                                            draggable: true,
                                            progress: undefined,
                                        }
                                    );
                                });

                            setOpenDeliveryModal(false);
                        }}
                    >
                        {deliveryWindowId === null ? "Create" : "Edit"} Delivery
                        Window
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    };

    const deliveryDaysRender = () => {
        // return array of the selected days
        // if true return the days which will be rendered
        const filterSelectedDays = Object.keys(
            deliveryDaySelection.value
        ).filter((obj) => deliveryDaySelection.value[obj] == true);
        return "Every " + filterSelectedDays.join(", ");
    };
    const calculateBlockedDays = () => {
        // if days selected is false, then it is blocked
        // convert to day num
        const result = Object.keys(deliveryDaySelection.value)
            .filter((e) => deliveryDaySelection.value[e] == false)
            .map((day, index) => {
                var convertDay;
                if (day == "Sunday") {
                    convertDay = 0;
                } else if (day == "Monday") {
                    convertDay = 1;
                } else if (day == "Tuesday") {
                    convertDay = 2;
                } else if (day == "Wednesday") {
                    convertDay = 3;
                } else if (day == "Thursday") {
                    convertDay = 4;
                } else if (day == "Friday") {
                    convertDay = 5;
                } else if (day == "Saturday") {
                    convertDay = 6;
                }
                return convertDay;
            });
        return result;
    };
    const deliveryTimeRender = () => {
        // render one p tag for every value
        var tempWindowTime = [];
        if (deliveryWindowTime.value.length == 0) {
            return "No time slot selected";
        } else {
            deliveryWindowTime.value.map((e) => {
                const startTime = timeFromInt(e.start_time, { format: 12 });
                const endTime = timeFromInt(e.end_time, { format: 12 });
                const timeRender = (
                    <>
                        <p className="m-0">
                            {startTime} to {endTime}
                        </p>
                    </>
                );
                tempWindowTime.push(timeRender);
            });
            return tempWindowTime;
        }
    };
    const renderMenuSetup = () => {
        return (
            <>
                <Grid item xs={12} md={12} lg={12}>
                    <Button
                        startIcon={<AddIcon />}
                        style={{
                            backgroundColor: "black",
                        }}
                        onClick={() => {
                            setOpenMenuModal(true);
                        }}
                        className="mt-3"
                        variant="contained"
                        color="primary"
                        component="label"
                    >
                        Create Item
                    </Button>
                </Grid>
                <Grid item className="mb-4 mt-3" xs={9} md={9} lg={7}>
                    <div>
                        <Accordion
                            expanded={expanded === "panel1"}
                            onChange={handleChange("panel1")}
                            style={{
                                borderRadius: "5px",
                            }}
                        >
                            <AccordionSummary
                                aria-controls="panel1d-content"
                                id="panel1d-header"
                            >
                                <Typography>Default Menu</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Form.Label>Display name</Form.Label>
                                <Form.Control
                                    onBlur={(e) => {
                                        setMenuSetup({
                                            ...menuSetup,
                                            display_name: {
                                                ...menuSetup.display_name,
                                                default_menu_name:
                                                    e.target.value,
                                            },
                                        });
                                    }}
                                    defaultValue={
                                        menuSetup.display_name.default_menu_name
                                    }
                                    className="w-50"
                                />
                                <Form.Group
                                    className="mt-3"
                                    controlId="formBasicCheckbox"
                                >
                                    <Form.Check
                                        type="checkbox"
                                        onChange={(e) => {
                                            setMenuSetup({
                                                ...menuSetup,
                                                quantity_selection: {
                                                    ...menuSetup.quantity_selection,
                                                    default_menu_quantity:
                                                        e.target.checked,
                                                },
                                            });
                                        }}
                                        checked={
                                            menuSetup.quantity_selection
                                                .default_menu_quantity
                                        }
                                        label="Allow quantity selection"
                                    />
                                </Form.Group>
                                {menuDetails(
                                    selectedDefaultMenu,
                                    setSelectedDefaultMenu
                                )}
                            </AccordionDetails>
                        </Accordion>
                        <Accordion
                            expanded={expanded === "panel2"}
                            onChange={handleChange("panel2")}
                        >
                            <AccordionSummary
                                aria-controls="panel2d-content"
                                id="panel2d-header"
                            >
                                <Typography>Non Default Menu</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Form.Label>Display name</Form.Label>
                                <Form.Control
                                    onBlur={(e) => {
                                        setMenuSetup({
                                            ...menuSetup,
                                            display_name: {
                                                ...menuSetup.display_name,
                                                non_default_menu_name:
                                                    e.target.value,
                                            },
                                        });
                                    }}
                                    defaultValue={
                                        menuSetup.display_name
                                            .non_default_menu_name
                                    }
                                    className="w-50"
                                />
                                <Form.Group
                                    className="mt-3"
                                    controlId="formBasicCheckbox"
                                >
                                    <Form.Check
                                        type="checkbox"
                                        onChange={(e) => {
                                            setMenuSetup({
                                                ...menuSetup,
                                                quantity_selection: {
                                                    ...menuSetup.quantity_selection,
                                                    non_default_menu_quantity:
                                                        e.target.checked,
                                                },
                                            });
                                        }}
                                        checked={
                                            menuSetup.quantity_selection
                                                .non_default_menu_quantity
                                        }
                                        label="Allow quantity selection"
                                    />
                                </Form.Group>
                                {menuDetails(
                                    selectedNonDefaultMenu,
                                    setSelectedNonDefaultMenu
                                )}
                            </AccordionDetails>
                        </Accordion>
                        <Accordion
                            expanded={expanded === "panel3"}
                            onChange={handleChange("panel3")}
                        >
                            <AccordionSummary
                                aria-controls="panel3d-content"
                                id="panel3d-header"
                            >
                                <Typography>Side Order</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Form.Label>Display name</Form.Label>
                                <Form.Control
                                    onBlur={(e) => {
                                        setMenuSetup({
                                            ...menuSetup,
                                            display_name: {
                                                ...menuSetup.display_name,
                                                side_order_name: e.target.value,
                                            },
                                        });
                                    }}
                                    defaultValue={
                                        menuSetup.display_name.side_order_name
                                    }
                                    className="w-50"
                                />
                                <Form.Group
                                    className="mt-3"
                                    controlId="formBasicCheckbox"
                                >
                                    <Form.Check
                                        type="checkbox"
                                        onChange={(e) => {
                                            setMenuSetup({
                                                ...menuSetup,
                                                quantity_selection: {
                                                    ...menuSetup.quantity_selection,
                                                    side_order_quantity:
                                                        e.target.checked,
                                                },
                                            });
                                        }}
                                        checked={
                                            menuSetup.quantity_selection
                                                .side_order_quantity
                                        }
                                        label="Allow quantity selection"
                                    />
                                </Form.Group>
                                {menuDetails(
                                    selectedSideOrder,
                                    setSelectedSideOrder
                                )}
                            </AccordionDetails>
                        </Accordion>
                    </div>
                </Grid>
                <Grid item className="mb-3" xs={12} md={12} lg={12}>
                    <Grid item className="mt-2" xs={12} lg={12} md={12}>
                        <h5
                            className="color-contrast-dark "
                            style={{ color: "black" }}
                        >
                            <strong>Menu Summary</strong>
                        </h5>
                    </Grid>
                    <Grid item xs={12} md={12} lg={12}>
                        {renderMenuSummary()}
                    </Grid>
                </Grid>
                <Grid item className="mt-1" xs={12} lg={12} md={12}>
                    <h4
                        className="color-contrast-dark "
                        style={{ color: "black" }}
                    >
                        <strong>Menu Restrictions</strong>
                    </h4>
                </Grid>
                <Grid item xs={12} lg={12} md={12}>
                    <Form.Label className=" mt-3 ">
                        This plan has menu selection restrictions
                    </Form.Label>
                    <Grid
                        className={
                            menuRestrictions.applied === false ? "mb-3" : ""
                        }
                        container
                    >
                        <Form.Check
                            type="radio"
                            label="Yes"
                            value={true}
                            onChange={(e) => {
                                setMenuRestrictions({
                                    ...menuRestrictions,
                                    applied: true,
                                });
                            }}
                            checked={menuRestrictions.applied === true}
                        />
                        <Form.Check
                            className="ml-4"
                            style={{ marginLeft: "20px" }}
                            type="radio"
                            label="No"
                            value={false}
                            checked={menuRestrictions.applied === false}
                            onChange={(e) => {
                                setMenuRestrictions({
                                    ...menuRestrictions,
                                    applied: false,
                                });
                            }}
                        />
                    </Grid>
                </Grid>
                {menuRestrictions.applied === true && (
                    <Grid item xs={9} lg={9} md={7}>
                        <Form.Label className=" mt-3 ">
                            Restrictions Apply To
                        </Form.Label>
                        <Form.Control
                            onChange={(e) => {
                                setMenuRestrictions({
                                    ...menuRestrictions,
                                    restriction_type: e.target.value,
                                    restriction_value: {},
                                });
                                // setRestrictionsApplyTo(e.target.value);
                                // setMenuRestrictionsValue();
                            }}
                            className="mt-2"
                            as="select"
                        >
                            <option value="all">
                                Default & Non-Default menus collectively
                            </option>
                            <option value="individual">Individual Menus</option>
                        </Form.Control>
                    </Grid>
                )}
                {menuRestrictions.applied === true && (
                    <Grid item xs={9} lg={9} md={7}>
                        {renderRestrictionsApplyTo()}
                    </Grid>
                )}
                <Grid className="mb-4" />
            </>
        );
    };
    const renderMenuSummary = () => {
        return (
            <>
                <Grid item xs={12} md={12} lg={12}>
                    <Grid container>
                        <Grid item className="mr-3" xs={8} md={2} lg={2}>
                            <Card
                                className="mb-2 w-100 mt-4"
                                style={{
                                    borderRadius: "10px",
                                }}
                            >
                                <Card.Header
                                    style={{
                                        borderRadius: "10px 10px 0px 0px",
                                        backgroundColor: "black",
                                        color: "white",
                                    }}
                                    bg={"dark"}
                                >
                                    <Grid
                                        item
                                        align="center"
                                        xs={12}
                                        md={12}
                                        lg={12}
                                    >
                                        Default Menu
                                    </Grid>
                                </Card.Header>
                                <Card.Body>
                                    <Grid
                                        item
                                        align="center"
                                        xs={12}
                                        md={12}
                                        lg={12}
                                    >
                                        {selectedDefaultMenu &&
                                            addonOptions &&
                                            selectedDefaultMenu.value.map(
                                                (e) => {
                                                    // filter e with addonoptions
                                                    const option =
                                                        addonOptions.filter(
                                                            (option) => {
                                                                if (
                                                                    option.addon_id ===
                                                                    e
                                                                ) {
                                                                    return option;
                                                                }
                                                            }
                                                        );
                                                    return (
                                                        <div className="mt-1">
                                                            {option[0] !==
                                                            undefined
                                                                ? option[0]
                                                                      .addon_name
                                                                : null}
                                                            <br />
                                                        </div>
                                                    );
                                                }
                                            )}
                                    </Grid>
                                </Card.Body>
                            </Card>
                        </Grid>
                        <Grid
                            item
                            className="mr-3 middle-menu-summary"
                            xs={8}
                            md={3}
                            lg={3}
                        >
                            <Card
                                className="mb-2 w-100 mt-4"
                                style={{
                                    borderRadius: "10px",
                                }}
                            >
                                <Card.Header
                                    style={{
                                        borderRadius: "10px 10px 0px 0px",
                                        backgroundColor: "black",
                                        color: "white",
                                    }}
                                    bg={"dark"}
                                >
                                    <Grid
                                        item
                                        align="center"
                                        xs={12}
                                        md={12}
                                        lg={12}
                                    >
                                        Non-Default Menu
                                    </Grid>
                                </Card.Header>
                                <Card.Body>
                                    <Grid
                                        item
                                        align="center"
                                        xs={12}
                                        md={12}
                                        lg={12}
                                    >
                                        {selectedNonDefaultMenu.value.map(
                                            (e) => {
                                                // filter e with addonoptions
                                                const option =
                                                    addonOptions.filter(
                                                        (option) => {
                                                            if (
                                                                option.addon_id ===
                                                                e
                                                            ) {
                                                                return option;
                                                            }
                                                        }
                                                    );
                                                return (
                                                    <div className="mt-1">
                                                        {option[0] !== undefined
                                                            ? option[0]
                                                                  .addon_name
                                                            : null}
                                                        <br />
                                                    </div>
                                                );
                                            }
                                        )}
                                    </Grid>
                                </Card.Body>
                            </Card>
                        </Grid>
                        <Grid item className="mr-3" xs={8} md={2} lg={2}>
                            <Card
                                className="mb-2 w-100 mt-4"
                                style={{
                                    borderRadius: "10px",
                                }}
                            >
                                <Card.Header
                                    style={{
                                        borderRadius: "10px 10px 0px 0px",
                                        backgroundColor: "black",
                                        color: "white",
                                    }}
                                    bg={"dark"}
                                >
                                    <Grid
                                        item
                                        align="center"
                                        xs={12}
                                        md={12}
                                        lg={12}
                                    >
                                        Side Order
                                    </Grid>
                                </Card.Header>
                                <Card.Body>
                                    <Grid
                                        item
                                        align="center"
                                        xs={12}
                                        md={12}
                                        lg={12}
                                    >
                                        {selectedSideOrder.value.map((e) => {
                                            // filter e with addonoptions
                                            const option = addonOptions.filter(
                                                (option) => {
                                                    if (option.addon_id === e) {
                                                        return option;
                                                    }
                                                }
                                            );

                                            return (
                                                <div className="mt-1">
                                                    {option[0] !== undefined
                                                        ? option[0].addon_name
                                                        : null}{" "}
                                                    <br />
                                                </div>
                                            );
                                        })}
                                    </Grid>
                                </Card.Body>
                            </Card>
                        </Grid>
                    </Grid>
                </Grid>
            </>
        );
    };
    const renderRestrictionsApplyTo = () => {
        return (
            <Card
                className="mb-4 mt-4"
                style={{
                    borderRadius: "10px",
                }}
            >
                <Card.Header
                    style={{
                        borderRadius: "10px 10px 0px 0px",
                        backgroundColor: "black",
                        color: "white",
                    }}
                    bg={"dark"}
                >
                    <Grid container>
                        <Grid item xs={6} md={6} lg={6}>
                            Menu Type
                        </Grid>

                        <Grid item xs={6} md={6} lg={6}>
                            Number of Set Items
                        </Grid>
                    </Grid>
                </Card.Header>
                <Card.Body>
                    {menuRestrictions && renderRestrictionQuantityInput()}
                </Card.Body>
            </Card>
        );
    };
    const renderRestrictionQuantityInput = () => {
        if (menuRestrictions.restriction_type === "all") {
            return (
                <Grid container>
                    <Grid item xs={6} md={6} lg={6}>
                        <Form.Control
                            style={{ width: "90%" }}
                            disabled
                            as="select"
                        >
                            <option>Default & Non-Default</option>
                        </Form.Control>
                    </Grid>

                    <Grid item xs={6} md={6} lg={6}>
                        <Form.Control
                            type="number"
                            onChange={(e) => {
                                // setMenuRestrictionsValue({
                                //     default_non_menu: e.target.value,
                                // });
                                setMenuRestrictions({
                                    ...menuRestrictions,
                                    restriction_value: {
                                        default_non_menu: e.target.value,
                                    },
                                });
                            }}
                            value={
                                menuRestrictions.restriction_value
                                    .default_non_menu
                            }
                            className="w-75"
                        />
                    </Grid>
                </Grid>
            );
        } else {
            return (
                <>
                    <Grid container>
                        <Grid item xs={6} md={6} lg={6}>
                            <Form.Control
                                style={{ width: "90%" }}
                                disabled
                                as="select"
                            >
                                <option>Default </option>
                            </Form.Control>
                        </Grid>

                        <Grid item xs={6} md={6} lg={6}>
                            <Form.Control
                                type="number"
                                onChange={(e) => {
                                    // setMenuRestrictionsValue({
                                    //     ...menuRestrictionsValue,
                                    //     default_menu: e.target.value,
                                    // });

                                    setMenuRestrictions({
                                        ...menuRestrictions,
                                        restriction_value: {
                                            ...menuRestrictions.restriction_value,
                                            default_menu: e.target.value,
                                        },
                                    });
                                }}
                                value={
                                    menuRestrictions.restriction_value
                                        .default_menu
                                }
                                className="w-75"
                            />
                        </Grid>
                    </Grid>
                    <Grid container className="mt-3">
                        <Grid item xs={6} md={6} lg={6}>
                            <Form.Control
                                style={{ width: "90%" }}
                                disabled
                                as="select"
                            >
                                <option>Non-Default</option>
                            </Form.Control>
                        </Grid>

                        <Grid item xs={6} md={6} lg={6}>
                            <Form.Control
                                type="number"
                                onChange={(e) => {
                                    // setMenuRestrictionsValue({
                                    //     ...menuRestrictionsValue,
                                    //     non_default_menu: e.target.value,
                                    // });

                                    setMenuRestrictions({
                                        ...menuRestrictions,
                                        restriction_value: {
                                            ...menuRestrictions.restriction_value,
                                            non_default_menu: e.target.value,
                                        },
                                    });
                                }}
                                value={
                                    menuRestrictions.restriction_value
                                        .non_default_menu
                                }
                                className="w-75"
                            />
                        </Grid>
                    </Grid>
                </>
            );
        }
    };

    const renderNewPricingModel = () => {
        return (
            <>
                <Grid item xs={12} md={12} lg={12}>
                    <Grid container>
                        <Grid item xs={3} md={3} lg={3}>
                            <Form.Label className="mt-3">
                                Property Name
                            </Form.Label>
                            <Form.Control
                                value={propertyModel.attribute}
                                onChange={(e) => {
                                    setPropertyModel({
                                        ...propertyModel,
                                        attribute: e.target.value,
                                    });
                                }}
                            />
                        </Grid>
                        <Grid
                            item
                            style={{ marginLeft: "10px" }}
                            className="ml-2"
                            xs={3}
                            md={3}
                            lg={3}
                        >
                            <Form.Label className="mt-3">
                                Property Quantity
                            </Form.Label>
                            <Form.Control
                                onChange={(e) => {
                                    setPropertyModel({
                                        ...propertyModel,
                                        value: e.target.value,
                                    });
                                }}
                                value={propertyModel.value}
                                type="number"
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                    <Card
                        className="mb-2 w-50 mt-4"
                        style={{
                            borderRadius: "10px",
                        }}
                    >
                        <Card.Header
                            style={{
                                borderRadius: "10px 10px 0px 0px",
                                backgroundColor: "black",
                                color: "white",
                            }}
                            bg={"dark"}
                        >
                            <Grid container>
                                <Grid item xs={6} md={6} lg={6}>
                                    Value
                                </Grid>

                                <Grid item xs={6} md={6} lg={6}>
                                    Price (RM)
                                </Grid>
                            </Grid>
                        </Card.Header>
                        <Card.Body>
                            {propertyModel.value && renderPropertySlot()}
                        </Card.Body>
                    </Card>
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                    {/* create a button with label "create property model" */}
                    <Button
                        startIcon={<AddIcon />}
                        style={{
                            backgroundColor: "black",
                        }}
                        onClick={() => {
                            createNewPropertyPricing();
                        }}
                        className="mt-3"
                        variant="contained"
                        color="primary"
                        component="label"
                    >
                        Create Property Pricing Model
                    </Button>
                </Grid>
            </>
        );
    };
    const renderExistingPricingModel = () => {
        return (
            <>
                <Grid item xs={12} md={12} lg={12}>
                    <Grid container>
                        <Grid item xs={3} md={3} lg={3}>
                            <Form.Label className="mt-3">
                                Property Name
                            </Form.Label>
                            <Form.Control
                                as="select"
                                onChange={(e) => {
                                    // filter id from propertyPricingOptions
                                    const propertyModelChosen =
                                        propertyPricingOptions.filter(
                                            (option) =>
                                                option.property_model_id ==
                                                e.target.value
                                        );
                                    // map propertyModelChosen.property and return each property_name
                                    const propertyName =
                                        propertyModelChosen[0].property.map(
                                            (data) => {
                                                return data.property_name;
                                            }
                                        );
                                    const propertyPrice =
                                        propertyModelChosen[0].property.map(
                                            (data) => {
                                                return data.property_price;
                                            }
                                        );
                                    setPropertyModel({
                                        id: e.target.value,
                                        value: propertyModelChosen[0]
                                            .property_quantity,
                                        attribute:
                                            propertyModelChosen[0]
                                                .property_model_name,
                                        name: propertyName,
                                        price: propertyPrice,
                                    });
                                }}
                                value={propertyModel.id}
                            >
                                <option></option>
                                {propertyPricingOptions &&
                                    propertyPricingOptions.map((item) => {
                                        return (
                                            <>
                                                <option
                                                    value={
                                                        item.property_model_id
                                                    }
                                                >
                                                    {item.property_model_name}
                                                    {/* cancel button and remove property from option */}
                                                </option>
                                            </>
                                        );
                                    })}
                            </Form.Control>
                        </Grid>
                        <Grid item className="ml-2" xs={3} md={3} lg={3}>
                            <Form.Label className="mt-3">
                                Property Quantity
                            </Form.Label>
                            <Form.Control
                                value={propertyModel.value}
                                disabled
                                type="number"
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                    <Card
                        className="mb-2 w-50 mt-4"
                        style={{
                            borderRadius: "10px",
                        }}
                    >
                        <Card.Header
                            style={{
                                borderRadius: "10px 10px 0px 0px",
                                backgroundColor: "black",
                                color: "white",
                            }}
                            bg={"dark"}
                        >
                            <Grid container>
                                <Grid item xs={6} md={6} lg={6}>
                                    Value
                                </Grid>

                                <Grid item xs={6} md={6} lg={6}>
                                    Price (RM)
                                </Grid>
                            </Grid>
                        </Card.Header>
                        <Card.Body>
                            {propertyModel.value && renderPropertySlot()}
                        </Card.Body>
                    </Card>
                </Grid>
            </>
        );
    };
    const renderPropertySlot = () => {
        let tempSlot = [];
        for (let i = 0; i < propertyModel.value; i++) {
            const slot = (
                <Grid className="mt-2" container>
                    <Grid item xs={6} md={6} lg={6}>
                        <Form.Control
                            disabled={existingPropertyModel}
                            value={propertyModel.name[i]}
                            onChange={(e) => {
                                const tempObj = propertyModel;
                                const newVal = propertyModel.name;
                                newVal[i] = e.target.value;
                                tempObj["name"] = newVal;
                                setPropertyModel(tempObj);
                            }}
                        />
                    </Grid>

                    <Grid item xs={6} md={6} lg={6}>
                        <Form.Control
                            disabled={existingPropertyModel}
                            value={propertyModel.price[i]}
                            onChange={(e) => {
                                const tempObj = propertyModel;
                                const newVal = propertyModel.price;
                                newVal[i] = e.target.value;
                                tempObj["price"] = newVal;
                                setPropertyModel(tempObj);
                            }}
                            className="ml-2"
                        />
                    </Grid>
                </Grid>
            );
            tempSlot.push(slot);
        }
        return tempSlot;
    };

    const createNewPlan = async () => {
        // based on sections
        // 1. default data
        // 2. payment term
        // 3. delivery
        // 4. menu setup

        // var restructurePlanDesc = convertToRaw(
        //     planDescription.getCurrentContent()
        // ).blocks.map((block) => {
        //     return block.text;
        // });

        let data = {
            plan_name: planName,
            plan_desc: draftToHtml(
                convertToRaw(planDescription.getCurrentContent())
            ),
            plan_duration: planDuration,
            pricing_model: pricingModel,
            payment_option: paymentOption,
            sales_services_tax: SST,
            is_shippable: isDeliverable,
            has_menu: hasMenu,
        };

        if (pricingModel === "flat_fee") {
            data = {
                ...data,
                plan_price: planPrice,
            };
        } else if (pricingModel === "property") {
            data = {
                ...data,
                property_model: propertyModel.id,
            };
        }

        if (isDeliverable === true) {
            data = {
                ...data,
                delivery_lead_time: deliveryLeadTime,
                delivery_frequency: deliveryFrequency.value,
            };
            if (deliveryWindowId !== undefined) {
                data = {
                    ...data,
                    delivery_window: deliveryWindowId,
                };
            }
        }
        if (hasMenu === true) {
            data = {
                ...data,
                default_menu: selectedDefaultMenu.value,
                non_default_menu: selectedNonDefaultMenu.value,
                side_order: selectedSideOrder.value,
                menu_setup: JSON.stringify(menuSetup),
            };
        }
        if (match.params.id !== "new") {
            // need to check if theres new image or not
            // if new image, then upload new image
            // if not, add to data dictionary with plan_image key

            // if theres new image, upload the new ones
            if (previewImg.length > planImage.length) {
                // get the difference between planImage and previewImg and upload the new ones
                const newImage = previewImg.filter((item) => {
                    // return only if item has imagefile key
                    if (item.imageFile !== undefined) {
                        return item;
                    }
                });
                const newImageId = await uploadImage(newImage);
                const tempImageArr = newImageId.concat(planImage);
                data = {
                    ...data,
                    plan_image: tempImageArr,
                };
            } else {
                data = {
                    ...data,
                    plan_image: planImage,
                };
            }
        } else {
            if (previewImg.length !== 0) {
                const imageId = await uploadImage(previewImg);
                data = {
                    ...data,
                    plan_image: imageId,
                };
            }
        }

        if (menuRestrictions.applied == true) {
            data = {
                ...data,
                menu_restrictions: JSON.stringify(menuRestrictions),
            };
        }

        if (match.params.id === "new") {
            planService
                .createPlan(data)
                .then((res) => {
                    Swal.fire({
                        title: "Success",
                        text: "Plan Created",
                        icon: "success",
                        showCancelButton: false,
                        confirmButtonColor: store.getState().colorState.color,
                        confirmButtonText: "OK",
                    }).then((result) => {
                        history.push("/admin/plan");
                    });
                })
                .catch((e) => {
                    console.error(e, "error");
                });
        } else {
            planService
                .editPlan(data, match.params.id)
                .then((res) => {
                    Swal.fire({
                        title: "Edit Successful",
                        text: "",
                        confirmButtonColor: store.getState().colorState.color,
                        icon: "success",
                    }).then((e) => {
                        history.push("/admin/plan");
                    });
                })
                .catch((res) => {
                    toast.error(
                        <div>
                            Some Error Occur, Please try again later.
                            <br />
                            {JSON.stringify(res, null, 2)}
                            <br />
                        </div>,
                        {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        }
                    );
                });
        }
    };

    return (
        <>
            <Grid container>
                <Grid item className="p-3" xs={12} md={12} lg={12}>
                    <Grid container className="admin-listing">
                        <Grid item xs={12} md={12} lg={12}>
                            <Grid container className="mt-4 mb-2">
                                {/* profile icon and username dropdown */}
                                <Grid align="left" item xs={6} md={6} lg={6}>
                                    <h4
                                        className="text-left color-contrast-dark ml-4"
                                        style={{
                                            color: "black",
                                            marginLeft: "10px",
                                        }}
                                    >
                                        {match.params.id !== "new" ? (
                                            <strong>Edit plan</strong>
                                        ) : (
                                            <strong>Create plan</strong>
                                        )}
                                    </h4>
                                </Grid>
                                <Grid item xs={6} md={6} lg={6} align="right">
                                    {" "}
                                    <LoginBar />
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} md={12} lg={12}>
                            <div
                                className={
                                    " pt-2 container-fluid addon-container"
                                }
                            >
                                <div className={" addon-form-container"}>
                                    <Grid
                                        align="left"
                                        className=" "
                                        style={{ marginLeft: "30px" }}
                                        container
                                    >
                                        <Grid
                                            item
                                            className="mt-4"
                                            xs={12}
                                            lg={12}
                                            md={12}
                                        >
                                            <h4
                                                className="color-contrast-dark "
                                                style={{ color: "black" }}
                                            >
                                                <strong>Plan Details</strong>
                                            </h4>
                                        </Grid>
                                        <Grid item xs={9} md={9} lg={7}>
                                            <Form.Group className="mb-3">
                                                <Form.Label>
                                                    Plan Name
                                                </Form.Label>
                                                <Form.Control
                                                    value={planName}
                                                    onChange={(e) => {
                                                        setPlanName(
                                                            e.target.value
                                                        );
                                                    }}
                                                />
                                            </Form.Group>
                                        </Grid>
                                        <Grid item xs={9} md={9} lg={7}>
                                            <Form.Group className="mb-3">
                                                <Form.Label>
                                                    Plan Description
                                                </Form.Label>
                                                <Editor
                                                    // toolbarHidden
                                                    editorClassName="editor-class"
                                                    toolbar={{
                                                        options: [
                                                            "inline",
                                                            // "blockType",
                                                            // "fontSize",
                                                            // "list",
                                                            // "textAlign",
                                                            // "history",
                                                        ],
                                                        inline: {
                                                            inDropdown: true,
                                                        },
                                                        // textAlign: {
                                                        //     inDropdown: false,
                                                        // },
                                                        link: {
                                                            inDropdown: true,
                                                        },
                                                    }}
                                                    onEditorStateChange={(
                                                        e
                                                    ) => {
                                                        setPlanDescription(e);
                                                    }}
                                                    editorState={
                                                        planDescription
                                                    }
                                                />
                                            </Form.Group>
                                        </Grid>
                                        <Grid item xs={9} md={9} lg={7}>
                                            <Form.Label>
                                                Plan Duration (Months)
                                            </Form.Label>
                                            <Form.Control
                                                onChange={(e) => {
                                                    setPlanDuration(
                                                        e.target.value
                                                    );
                                                }}
                                                placeholder="Leave blank if duration is indefinite"
                                                value={planDuration}
                                                type="number"
                                            />
                                        </Grid>

                                        <Grid item xs={12} md={12} lg={12}>
                                            <Button
                                                startIcon={<PhotoLibraryIcon />}
                                                style={{
                                                    backgroundColor: "black",
                                                }}
                                                className="mt-3"
                                                variant="contained"
                                                color="primary"
                                                component="label"
                                            >
                                                Upload Images
                                                <input
                                                    accept="image/*"
                                                    type="file"
                                                    onChange={async (e) => {
                                                        const file =
                                                            e.target.files[0];

                                                        let imageDataUrl =
                                                            await readImageFileIntoDataUrl(
                                                                file
                                                            );

                                                        //after an image file is selected open the cropping model
                                                        setImageCropModel({
                                                            ...imageCropModel,
                                                            isOpened: true,
                                                            selectedImageDataUrl:
                                                                imageDataUrl,
                                                        });
                                                    }}
                                                    hidden
                                                />
                                            </Button>
                                        </Grid>
                                        {previewImg.length !== 0 && (
                                            <Grid
                                                item
                                                className="mt-3"
                                                xs={6}
                                                md={12}
                                                lg={12}
                                            >
                                                <div className="preview-images p-3">
                                                    <Grid container>
                                                        {previewImg.length !==
                                                            0 &&
                                                            previewImg.map(
                                                                (
                                                                    img,
                                                                    index
                                                                ) => (
                                                                    <Grid
                                                                        item
                                                                        xs={9}
                                                                        md={3}
                                                                        lg={3}
                                                                        className="mt-3"
                                                                    >
                                                                        <div
                                                                            style={{
                                                                                position:
                                                                                    "relative",
                                                                                width: "150px",
                                                                                height: "150px",
                                                                            }}
                                                                        >
                                                                            <div>
                                                                                <Image
                                                                                    style={{
                                                                                        width: "150px",
                                                                                        height: "150px",
                                                                                        marginLeft:
                                                                                            "20px",
                                                                                        marginRight:
                                                                                            "20px",
                                                                                    }}
                                                                                    src={
                                                                                        img.imagePreview
                                                                                    }
                                                                                ></Image>
                                                                            </div>

                                                                            <div class="img-cancel-button">
                                                                                <CloseButton
                                                                                    onClick={() => {
                                                                                        // remove the image from the preview using index
                                                                                        let newPreviewImg =
                                                                                            [
                                                                                                ...previewImg,
                                                                                            ];
                                                                                        let newImageId =
                                                                                            [
                                                                                                ...planImage,
                                                                                            ];

                                                                                        newPreviewImg.splice(
                                                                                            index,
                                                                                            1
                                                                                        );
                                                                                        newImageId.splice(
                                                                                            index,
                                                                                            1
                                                                                        );
                                                                                        setPreviewImg(
                                                                                            newPreviewImg
                                                                                        );
                                                                                        setPlanImage(
                                                                                            newImageId
                                                                                        );
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </Grid>
                                                                )
                                                            )}
                                                    </Grid>
                                                </div>
                                            </Grid>
                                        )}
                                        <Grid
                                            item
                                            className="mt-4"
                                            xs={12}
                                            lg={12}
                                            md={12}
                                        >
                                            <h4
                                                className="color-contrast-dark "
                                                style={{ color: "black" }}
                                            >
                                                <strong>Payment Term</strong>
                                            </h4>
                                        </Grid>
                                        <Grid item xs={12} md={12} lg={12}>
                                            <Form.Group className="mb-3 mt-2 w-50">
                                                <Form.Label>
                                                    Payment Options
                                                </Form.Label>
                                                <Grid container>
                                                    <Grid
                                                        item
                                                        xs={12}
                                                        md={6}
                                                        lg={2}
                                                    >
                                                        <Form.Check
                                                            type="radio"
                                                            label="Recurring"
                                                            value={"recurring"}
                                                            checked={
                                                                paymentOption ===
                                                                "recurring"
                                                            }
                                                            onChange={(e) => {
                                                                setPaymentOption(
                                                                    e.target
                                                                        .value
                                                                );
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        xs={12}
                                                        md={6}
                                                        lg={2}
                                                    >
                                                        <Form.Check
                                                            type="radio"
                                                            label="One-off"
                                                            value={"one_off"}
                                                            checked={
                                                                paymentOption ===
                                                                "one_off"
                                                            }
                                                            onChange={(e) => {
                                                                setPaymentOption(
                                                                    e.target
                                                                        .value
                                                                );
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        xs={12}
                                                        md={6}
                                                        lg={5}
                                                    >
                                                        <Form.Check
                                                            type="radio"
                                                            label="Recurring and one-off"
                                                            checked={
                                                                paymentOption ===
                                                                "recurring_one_off"
                                                            }
                                                            value={
                                                                "recurring_one_off"
                                                            }
                                                            onChange={(e) => {
                                                                setPaymentOption(
                                                                    e.target
                                                                        .value
                                                                );
                                                            }}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Form.Group>
                                        </Grid>

                                        <Grid
                                            item
                                            className="mt-3"
                                            xs={9}
                                            md={9}
                                            lg={7}
                                        >
                                            <Form.Label>
                                                Pricing Model
                                            </Form.Label>
                                            <Form.Control
                                                as="select"
                                                value={pricingModel}
                                                onChange={(e) => {
                                                    setPricingModel(
                                                        e.target.value
                                                    );
                                                }}
                                            >
                                                <option value="flat_fee">
                                                    Flat Fee
                                                </option>
                                                <option value="property">
                                                    Property Pricing Model
                                                </option>
                                            </Form.Control>
                                        </Grid>
                                        {renderPricingModel()}

                                        <Grid item xs={12} md={12} lg={12}>
                                            {/* checkbox with label "SST (6%) is applied" */}
                                            <Form.Check
                                                type="checkbox"
                                                label="Sales and Service Tax (6%)"
                                                className="mt-3"
                                                onChange={(e) => {
                                                    setSST(e.target.checked);
                                                }}
                                                checked={SST}
                                            />
                                        </Grid>
                                        <Grid
                                            item
                                            className="mt-4"
                                            xs={12}
                                            lg={12}
                                            md={12}
                                        >
                                            <h4
                                                className="color-contrast-dark "
                                                style={{ color: "black" }}
                                            >
                                                <strong>Delivery</strong>
                                            </h4>
                                        </Grid>

                                        <Grid item xs={12} md={12} lg={12}>
                                            <Form.Label className=" mt-2">
                                                Product deliverable
                                            </Form.Label>
                                            <Grid container>
                                                {/* create two form check yes and no, change dynamically */}
                                                <Form.Check
                                                    type="radio"
                                                    label="Yes"
                                                    inline
                                                    checked={
                                                        isDeliverable === true
                                                    }
                                                    onChange={(e) => {
                                                        setIsDeliverable(true);
                                                    }}
                                                />
                                                <Form.Check
                                                    className="ml-4"
                                                    type="radio"
                                                    inline
                                                    label="No"
                                                    checked={
                                                        isDeliverable === false
                                                    }
                                                    onChange={(e) => {
                                                        setIsDeliverable(false);
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>

                                        {/* if deliverable is true, render delivery window input */}
                                        {isDeliverable == true ? (
                                            // create two button, create delivery window and block dates for delivery
                                            <>
                                                {paymentOption !== "one_off" ? (
                                                    <Grid
                                                        className="mt-3"
                                                        item
                                                        xs={9}
                                                        md={9}
                                                        lg={7}
                                                    >
                                                        <Form.Label>
                                                            Delivery Frequency
                                                        </Form.Label>

                                                        <Form.Group>
                                                            <Select
                                                                className="w-75"
                                                                // fullWidth
                                                                multiple
                                                                value={
                                                                    deliveryFrequency.value
                                                                }
                                                                onChange={(
                                                                    event
                                                                ) => {
                                                                    var selected =
                                                                        event
                                                                            .target
                                                                            .value;
                                                                    setDeliveryFrequency(
                                                                        {
                                                                            value: selected,
                                                                        }
                                                                    );
                                                                }}
                                                                input={
                                                                    <Input />
                                                                }
                                                                renderValue={(
                                                                    selectedAddonIds
                                                                ) => {
                                                                    var displayText =
                                                                        "";
                                                                    selectedAddonIds.forEach(
                                                                        (
                                                                            selectedAddonId
                                                                        ) => {
                                                                            let deliveryFrequencyArr =
                                                                                [];
                                                                            Object.keys(
                                                                                deliveryFrequencyOptions
                                                                            ).find(
                                                                                (
                                                                                    period
                                                                                ) => {
                                                                                    if (
                                                                                        period ==
                                                                                        selectedAddonId
                                                                                    ) {
                                                                                        deliveryFrequencyArr.push(
                                                                                            deliveryFrequencyOptions[
                                                                                                period
                                                                                            ]
                                                                                        );
                                                                                    }
                                                                                }
                                                                            );
                                                                            displayText +=
                                                                                deliveryFrequencyArr +
                                                                                ", ";
                                                                        }
                                                                    );

                                                                    return displayText;
                                                                }}
                                                                MenuProps={{
                                                                    anchorOrigin:
                                                                        {
                                                                            vertical:
                                                                                "top",
                                                                            horizontal:
                                                                                "right",
                                                                        },
                                                                    transformOrigin:
                                                                        {
                                                                            vertical:
                                                                                "top",
                                                                            horizontal:
                                                                                "right",
                                                                        },
                                                                    keepMounted: true,
                                                                    getContentAnchorEl:
                                                                        null,

                                                                    PaperProps:
                                                                        {
                                                                            style: {
                                                                                marginTop:
                                                                                    "35px",
                                                                                maxHeight: 200,
                                                                                // minWidth:
                                                                                //     "200px",
                                                                            },
                                                                        },
                                                                }}
                                                            >
                                                                {Object.keys(
                                                                    deliveryFrequencyOptions
                                                                ).map(
                                                                    (item) => {
                                                                        return (
                                                                            <MenuItem
                                                                                color="primary"
                                                                                key={
                                                                                    item
                                                                                }
                                                                                value={
                                                                                    item
                                                                                }
                                                                            >
                                                                                <Checkbox
                                                                                    color="primary"
                                                                                    checked={
                                                                                        deliveryFrequency.value !==
                                                                                        null
                                                                                            ? deliveryFrequency.value.indexOf(
                                                                                                  item
                                                                                              ) >
                                                                                              -1
                                                                                            : false
                                                                                    }
                                                                                />
                                                                                <ListItemText
                                                                                    primary={
                                                                                        deliveryFrequencyOptions[
                                                                                            item
                                                                                        ]
                                                                                    }
                                                                                />
                                                                            </MenuItem>
                                                                        );
                                                                    }
                                                                )}
                                                            </Select>
                                                        </Form.Group>
                                                    </Grid>
                                                ) : null}
                                                <Grid item xs={9} md={9} lg={7}>
                                                    <Form.Label className="mt-3">
                                                        Delivery Lead Time
                                                        (Days)
                                                    </Form.Label>
                                                    <Form.Control
                                                        type="number"
                                                        value={deliveryLeadTime}
                                                        onChange={(e) => {
                                                            setDeliveryLeadTime(
                                                                e.target.value
                                                            );
                                                        }}
                                                    />
                                                </Grid>
                                                {deliveryWindowId !==
                                                undefined ? (
                                                    <Grid
                                                        item
                                                        xs={12}
                                                        md={12}
                                                        lg={12}
                                                    >
                                                        <Form.Label className="mt-3">
                                                            <strong>
                                                                Delivery Window
                                                            </strong>
                                                        </Form.Label>
                                                        <Card
                                                            className="mb-2 w-75"
                                                            style={{
                                                                borderRadius:
                                                                    "10px",
                                                            }}
                                                        >
                                                            <Card.Header
                                                                style={{
                                                                    borderRadius:
                                                                        "10px 10px 0px 0px",
                                                                    backgroundColor:
                                                                        "black",
                                                                    color: "white",
                                                                }}
                                                                bg={"dark"}
                                                            >
                                                                <Grid container>
                                                                    <Grid
                                                                        item
                                                                        xs={6}
                                                                        md={6}
                                                                        lg={6}
                                                                    >
                                                                        Day(s)
                                                                    </Grid>

                                                                    <Grid
                                                                        item
                                                                        xs={6}
                                                                        md={6}
                                                                        lg={6}
                                                                    >
                                                                        Time(s)
                                                                    </Grid>
                                                                </Grid>
                                                            </Card.Header>
                                                            <Card.Body>
                                                                <Grid container>
                                                                    <Grid
                                                                        item
                                                                        xs={6}
                                                                        md={6}
                                                                        lg={6}
                                                                    >
                                                                        {deliveryDaysRender()}
                                                                    </Grid>

                                                                    <Grid
                                                                        item
                                                                        xs={6}
                                                                        md={6}
                                                                        lg={6}
                                                                    >
                                                                        {deliveryTimeRender()}
                                                                    </Grid>
                                                                </Grid>
                                                            </Card.Body>
                                                        </Card>
                                                        {deliveryDatePreview()}
                                                    </Grid>
                                                ) : (
                                                    ""
                                                )}
                                                {blockedDates.value.length !==
                                                0 ? (
                                                    <Grid
                                                        item
                                                        xs={12}
                                                        md={12}
                                                        lg={12}
                                                    >
                                                        <Form.Label className="mt-3">
                                                            <strong>
                                                                Blocked Date(s)
                                                            </strong>
                                                        </Form.Label>
                                                        <Card
                                                            className="mb-2 w-25"
                                                            style={{
                                                                borderRadius:
                                                                    "10px",
                                                            }}
                                                        >
                                                            <Card.Header
                                                                style={{
                                                                    borderRadius:
                                                                        "10px 10px 0px 0px",
                                                                    backgroundColor:
                                                                        "black",
                                                                    color: "white",
                                                                }}
                                                                bg={"dark"}
                                                            >
                                                                <Grid
                                                                    item
                                                                    xs={12}
                                                                    md={12}
                                                                    lg={12}
                                                                >
                                                                    Date(s)
                                                                </Grid>
                                                            </Card.Header>
                                                            <Card.Body>
                                                                <Grid container>
                                                                    <Grid
                                                                        item
                                                                        xs={6}
                                                                        md={6}
                                                                        lg={6}
                                                                    >
                                                                        {blockedDates.value.map(
                                                                            (
                                                                                template,
                                                                                index
                                                                            ) => {
                                                                                return (
                                                                                    <p
                                                                                        key={
                                                                                            index
                                                                                        }
                                                                                    >
                                                                                        {index +
                                                                                            1}
                                                                                        {
                                                                                            ". "
                                                                                        }{" "}
                                                                                        {template.toLocaleDateString()}
                                                                                    </p>
                                                                                );
                                                                            }
                                                                        )}
                                                                    </Grid>
                                                                </Grid>
                                                            </Card.Body>
                                                        </Card>
                                                    </Grid>
                                                ) : null}
                                                <Grid
                                                    item
                                                    xs={12}
                                                    md={12}
                                                    lg={12}
                                                >
                                                    <Grid container>
                                                        <Button
                                                            startIcon={
                                                                <AddIcon />
                                                            }
                                                            style={{
                                                                backgroundColor:
                                                                    "black",
                                                            }}
                                                            onClick={() => {
                                                                setOpenDeliveryModal(
                                                                    true
                                                                );
                                                            }}
                                                            className="mt-3"
                                                            variant="contained"
                                                            color="primary"
                                                            component="label"
                                                        >
                                                            {deliveryWindowId ===
                                                            undefined
                                                                ? "Create "
                                                                : "Edit "}
                                                            Delivery Window
                                                        </Button>
                                                        {deliveryWindowId !==
                                                        undefined ? (
                                                            <Button
                                                                startIcon={
                                                                    <DateRangeIcon />
                                                                }
                                                                style={{
                                                                    backgroundColor:
                                                                        "black",
                                                                }}
                                                                onClick={() => {
                                                                    setOpenBlockModal(
                                                                        true
                                                                    );
                                                                }}
                                                                className="mt-3 ml-3"
                                                                variant="contained"
                                                                color="primary"
                                                                component="label"
                                                            >
                                                                Block Dates
                                                            </Button>
                                                        ) : (
                                                            ""
                                                        )}
                                                    </Grid>
                                                </Grid>
                                            </>
                                        ) : null}

                                        {deliveryWindowModal()}

                                        <Grid
                                            item
                                            className="mt-4"
                                            xs={12}
                                            lg={12}
                                            md={12}
                                        >
                                            <h4
                                                className="color-contrast-dark "
                                                style={{ color: "black" }}
                                            >
                                                <strong>Menu setup</strong>
                                            </h4>
                                        </Grid>
                                        <Grid item xs={9} md={9} lg={7}>
                                            <Form.Label className=" mt-3 ">
                                                This plan has applicable menus
                                            </Form.Label>
                                            <Grid
                                                className={
                                                    hasMenu === false
                                                        ? "mb-3"
                                                        : ""
                                                }
                                                container
                                            >
                                                <Form.Check
                                                    type="radio"
                                                    label="Yes"
                                                    value={true}
                                                    onChange={(e) => {
                                                        setHasMenu(true);
                                                    }}
                                                    checked={hasMenu === true}
                                                />
                                                <Form.Check
                                                    style={{
                                                        marginLeft: "20px",
                                                    }}
                                                    type="radio"
                                                    label="No"
                                                    value={false}
                                                    checked={hasMenu === false}
                                                    onChange={(e) => {
                                                        setHasMenu(false);
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>
                                        {hasMenu === true
                                            ? renderMenuSetup()
                                            : null}
                                    </Grid>
                                    {menuCreationModal()}
                                    <ImageCropper
                                        imgCrop={imageCropModel}
                                        handleImgCrop={(imgData) => {
                                            setImageCropModel(imgData);
                                        }}
                                        imgPreview={previewImg}
                                        handleImgPreview={(img) => {
                                            setPreviewImg([...previewImg, img]);
                                        }}
                                    />
                                </div>
                                <Grid container>
                                    <Grid
                                        item
                                        align="right"
                                        xs={6}
                                        md={6}
                                        lg={6}
                                    >
                                        <Button
                                            className="mt-3 mr-2"
                                            variant="contained"
                                            onClick={() => {
                                                history.push("/admin/plan");
                                            }}
                                        >
                                            Cancel
                                        </Button>
                                    </Grid>
                                    <Grid
                                        item
                                        align="left"
                                        xs={6}
                                        md={6}
                                        lg={6}
                                    >
                                        <Button
                                            className="mt-3 ml-2"
                                            variant="contained"
                                            style={{
                                                background: "black",
                                                color: "white",
                                                marginLeft: "10px",
                                            }}
                                            onClick={() => {
                                                createNewPlan();
                                            }}
                                        >
                                            Save
                                        </Button>
                                    </Grid>
                                </Grid>

                                <ToastContainer />
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

export default ProductForm;
