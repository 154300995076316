import { Grid, Card, CardContent } from "@mui/material";
import { Form } from "react-bootstrap";
import { useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { TextField } from "@mui/material";
import DatePicker from "@mui/lab/DatePicker";
import { InputGroup } from "react-bootstrap";
import { Button } from "@mui/material";
import "./style.css";
const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
];

const VajraReviewDetails = ({}) => {
    const [editingReview, setEditingReview] = useState(false);
    const [dedicationNote, setDedicationNote] = useState({
        value: JSON.parse(sessionStorage.getItem("vajraDedicationNote")),
    });
    const [isGiftCheck, setIsGiftCheck] = useState(
        JSON.parse(sessionStorage.getItem("vajraGift"))
    );
    const [recipientEmail, setRecipientEmail] = useState({
        value: JSON.parse(sessionStorage.getItem("vajraRecipientEmail")),
    });
    const [recipientName, setRecipientName] = useState({
        value: JSON.parse(sessionStorage.getItem("vajraRecipientName")),
    });
    const [giftMessage, setGiftMessage] = useState({
        value: JSON.parse(sessionStorage.getItem("vajraGiftMessage")),
    });
    const [refCode, setRefCode] = useState(
        JSON.parse(sessionStorage.getItem("vajraRefCode"))
    );
    const [date, setDate] = useState(
        new Date(JSON.parse(sessionStorage.getItem("deliveryDate")))
    );

    return (
        <Card
            style={{
                borderRadius: "8px",
                boxShadow: "3px 5px #DCDCDC",
            }}
            className="mt-1"
        >
            <Grid align="left" style={{ marginLeft: "20px" }} container>
                <Grid item className="mt-3" xs={10} md={11} lg={11}>
                    <Grid container>
                        <div>
                            <h4>
                                Review Details
                                <Button
                                    startIcon={
                                        <EditIcon
                                            className="icon-mobile"
                                            style={{ fontSize: "15px" }}
                                        ></EditIcon>
                                    }
                                    style={{
                                        marginLeft: "20px",
                                        fontSize: "10px",
                                        borderRadius: "5px",
                                        margin: "12px",
                                        height: "25px",
                                        backgroundColor: "rgba(0, 0, 0, 0.54)",
                                        color: "white",
                                    }}
                                    onClick={() =>
                                        setEditingReview(!editingReview)
                                    }
                                >
                                    Edit
                                </Button>
                            </h4>
                        </div>
                    </Grid>
                    {editingReview ? (
                        <Grid container>
                            <Grid item xs={12} md={12} lg={12}>
                                <Form.Label>Subscription Start Date</Form.Label>
                            </Grid>
                            <Grid item xs={12} md={12} lg={12}>
                                <LocalizationProvider
                                    dateAdapter={AdapterDateFns}
                                >
                                    <DatePicker
                                        inputFormat="01-MM-yyyy"
                                        views={["month", "year"]}
                                        // label="Year and Month"
                                        minDate={new Date().setDate(
                                            new Date().getDate() + 4
                                        )}
                                        maxDate={new Date("2030-06-01")}
                                        value={date}
                                        onChange={(date) => {
                                            // if date is valid date
                                            setDate(date);
                                            sessionStorage.setItem(
                                                "deliveryDate",
                                                JSON.stringify(date)
                                            );
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                onKeyDown={(e) =>
                                                    e.preventDefault()
                                                }
                                                {...params}
                                                helperText={null}
                                            />
                                        )}
                                    />{" "}
                                </LocalizationProvider>
                            </Grid>
                        </Grid>
                    ) : (
                        <Form.Label>
                            Your subscription will start on{" "}
                            {monthNames[new Date(date).getUTCMonth() + 1]}
                            {" 1st "}
                            {new Date(date).getUTCFullYear()}
                        </Form.Label>
                    )}
                    <Form.Group
                        className="mb-3 form-group required"
                        style={{ textAlign: "left" }}
                        controlId="exampleForm.ControlTextarea1"
                    >
                        <Form.Label className="control-label">
                            Dedication (500 characters)
                        </Form.Label>
                        <InputGroup hasValidation>
                            <Form.Control
                                disabled={!editingReview}
                                as="textarea"
                                rows={3}
                                isInvalid={
                                    dedicationNote.valid === false
                                        ? true
                                        : false
                                }
                                value={dedicationNote.value}
                                onChange={(data) => {
                                    if (data.target.value.length > 500) {
                                        setDedicationNote({
                                            ...dedicationNote,
                                            valid: false,
                                            value: data.target.value,
                                            error: `Dedication note cannot exceed 500 characters. Please remove ${
                                                data.target.value.length - 500
                                            } characters.`,
                                        });
                                    } else if (data.target.value.length < 500) {
                                        setDedicationNote({
                                            ...dedicationNote,
                                            valid: true,
                                            value: data.target.value,
                                            error: "",
                                        });
                                    }
                                    sessionStorage.setItem(
                                        "vajraDedicationNote",
                                        JSON.stringify(data.target.value)
                                    );

                                    // dedicationNoteHandler(data.target.value);
                                }}
                            />
                            <Form.Control.Feedback type="invalid">
                                {dedicationNote.error}
                            </Form.Control.Feedback>
                        </InputGroup>
                        <Form.Label className="mt-3">Referral Code</Form.Label>
                        <Form.Control
                            disabled={!editingReview}
                            value={refCode}
                            onChange={(refCode) => {
                                sessionStorage.setItem(
                                    "vajraRefCode",
                                    JSON.stringify(refCode.target.value)
                                );
                                setRefCode(refCode.target.value);
                                // refCodeHandler(refCode.target.value);
                            }}
                        />
                        <Form.Check
                            className="mt-3"
                            disabled={!editingReview}
                            type={"checkbox"}
                            checked={isGiftCheck}
                            // id={`default-${type}`}
                            onChange={(e) => {
                                sessionStorage.setItem(
                                    "vajraGift",
                                    JSON.stringify(e.target.checked)
                                );
                                setIsGiftCheck(e.target.checked);
                                // isGiftHandler(e.target.checked);
                            }}
                            label={"This is a gift"}
                        />
                        <Form.Label className="mt-4">
                            Gift Message (500 characters)
                        </Form.Label>
                        <InputGroup hasValidation>
                            <Form.Control
                                disabled={!isGiftCheck || !editingReview}
                                value={giftMessage.value}
                                as="textarea"
                                isInvalid={
                                    giftMessage.valid === false ? true : false
                                }
                                rows={3}
                                onChange={(message) => {
                                    if (message.target.value.length > 500) {
                                        setGiftMessage({
                                            ...giftMessage,
                                            valid: false,
                                            value: message.target.value,
                                            error: `Dedication note cannot exceed 500 characters. Please remove ${
                                                message.target.value.length -
                                                500
                                            } characters.`,
                                        });
                                    } else if (
                                        message.target.value.length < 500
                                    ) {
                                        setGiftMessage({
                                            ...giftMessage,
                                            valid: true,
                                            value: message.target.value,
                                            error: "",
                                        });
                                    }
                                    sessionStorage.setItem(
                                        "vajraGiftMessage",
                                        JSON.stringify(message.target.value)
                                    );
                                    // giftMessageHandler(message.target.value);
                                }}
                            />
                            <Form.Control.Feedback type="invalid">
                                {giftMessage.error}
                            </Form.Control.Feedback>
                        </InputGroup>

                        <Form.Label className="mt-4">Recipient Name</Form.Label>
                        <InputGroup>
                            <Form.Control
                                disabled={!isGiftCheck || !editingReview}
                                value={recipientName.value}
                                isInvalid={
                                    recipientName.valid === false ? true : false
                                }
                                onChange={(name) => {
                                    setRecipientName({
                                        ...recipientName,
                                        value: name.target.value,
                                    });
                                    sessionStorage.setItem(
                                        "vajraRecipientName",
                                        JSON.stringify(name.target.value)
                                    );
                                    if (name.target.value.length > 50) {
                                        setRecipientName({
                                            ...recipientName,
                                            valid: false,
                                            error: `Recipient name cannot exceed 50 characters. Please remove ${
                                                name.target.value.length - 50
                                            } characters.`,
                                        });
                                    } else if (name.target.value.length < 50) {
                                        setRecipientName({
                                            ...recipientName,
                                            valid: true,
                                            value: name.target.value,
                                            error: "",
                                        });
                                    }

                                    // recipientNameHandler(name.target.value);
                                }}
                            />
                            <Form.Control.Feedback type="invalid">
                                {recipientName.error}
                            </Form.Control.Feedback>
                        </InputGroup>
                        <Form.Label className="mt-4">
                            Recipient Email
                        </Form.Label>
                        <InputGroup>
                            <Form.Control
                                disabled={!isGiftCheck || !editingReview}
                                type="email"
                                value={recipientEmail.value}
                                isInvalid={
                                    recipientEmail.valid === false
                                        ? true
                                        : false
                                }
                                onChange={(email) => {
                                    sessionStorage.setItem(
                                        "vajraRecipientEmail",
                                        JSON.stringify(email.target.value)
                                    );
                                    setRecipientEmail({
                                        ...recipientEmail,
                                        value: email.target.value,
                                    });
                                    if (email.target.value.length > 50) {
                                        setRecipientEmail({
                                            ...recipientEmail,
                                            valid: false,
                                            error: `Recipient email cannot exceed 50 characters. Please remove ${
                                                email.target.value.length - 50
                                            } characters.`,
                                        });
                                    } else if (email.target.value.length < 50) {
                                        if (
                                            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(
                                                email.target.value
                                            )
                                        ) {
                                            setRecipientEmail({
                                                ...recipientEmail,
                                                value: email.target.value,
                                                error: "Wrong email format",
                                                valid: false,
                                            });
                                        } else {
                                            setRecipientEmail({
                                                ...recipientEmail,
                                                valid: true,
                                                value: email.target.value,
                                                error: "",
                                            });
                                        }
                                    } // recipientEmailHandler(email.target.value);
                                }}
                            />
                            <Form.Control.Feedback type="invalid">
                                {recipientEmail.error}
                            </Form.Control.Feedback>
                        </InputGroup>
                    </Form.Group>{" "}
                </Grid>
            </Grid>
        </Card>
    );
};

export default VajraReviewDetails;
